import {
  GET_SPECIALTIES_REQUEST,
  GET_SPECIALTIES_SUCCESS,
  GET_SPECIALTIES_ERROR,
  GET_SPECIALTY_REQUEST,
  GET_SPECIALTY_SUCCESS,
  GET_SPECIALTY_ERROR,
  CREATE_SPECIALTY_REQUEST,
  CREATE_SPECIALTY_SUCCESS,
  CREATE_SPECIALTY_ERROR,
  EDIT_SPECIALTY_REQUEST,
  EDIT_SPECIALTY_SUCCESS,
  EDIT_SPECIALTY_ERROR,
  DELETE_SPECIALTY_REQUEST,
  DELETE_SPECIALTY_SUCCESS,
  DELETE_SPECIALTY_ERROR
} from "@/actions"

import {
  specialtyService
} from "@/services"

const state = {
  specialties: [],
  specialty: {},
  loading: false
}

import { addNewFilter } from "@/utilities";

const getters = {
  specialties: state => state.specialties,
  specialtySelectOptions: state => state.specialties.map(s => ({
    value: s.uuid,
    label: s.name,
    attrs: {
      'data-aoqs-uuid': s.area_of_qualifications_uuid,
      'data-slug': s.slug,
      'data-license-required': s.license_required
    }
  })).sort((a, b) => a.label.localeCompare(b.label)),
  specialtyMultiSelectOptions: state => state.specialties.map(s => ({ value: s.uuid, text: s.name })),
  specialtyByUuid: state => uuid => state.specialties.find(s => s.uuid == uuid),
  specialtyBySlug: state => slug => {
    return state.specialties.find(s => s.slug == slug)
  },
  specialtyFilterByAOQUuid: state => aoqUuid => state.specialties.filter(s => s.area_of_qualifications_uuid == aoqUuid).sort((a, b) => {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0;  // names must be equal
  })
}

const actions = {
  async getSpecialties({ commit }) {
    commit("GET_SPECIALTIES_REQUEST")
    const response = await specialtyService.getSpecialties();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_SPECIALTIES_SUCCESS", data)
        break
      default:
        commit("GET_SPECIALTIES_ERROR", data)
    }
    return response
  },
  async getSpecialty({ commit }, params) {
    commit("GET_SPECIALTY_REQUEST")
    const response = await specialtyService.getSpecialty(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_SPECIALTY_SUCCESS", data)
        break
      default:
        commit("GET_SPECIALTY_ERROR", data)
        break
    }
    return response
  },
  async createSpecialty({ commit, dispatch }, params) {
    commit("CREATE_SPECIALTY_REQUEST")
    const response = await specialtyService.createSpecialty(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data?.success ? commit("CREATE_SPECIALTY_SUCCESS", data) : null;
        data.success ? dispatch('alert/createAlertSuccess', "Specialty created.", { root: true }) : null;
        data?.error ? dispatch('alert/createAlertErrors', !isEmpty(data) ? decodeLaravelValidationErrors(data.errors) : "Oops. Something went wrong.", { root: true }) : null;
        break
      default:
        commit("CREATE_SPECIALTY_ERROR", data)
    }
    return response
  },
  async editSpecialty({ commit, dispatch }, params) {
    const { uuid } = params;
    commit("EDIT_SPECIALTY_REQUEST");
    const response = await specialtyService.editSpecialty(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data?.success ? commit("EDIT_SPECIALTY_SUCCESS", data) : null;
        data.success ? dispatch('alert/createAlertSuccess', "Specialty updated.", { root: true }) : null;
        data?.error ? dispatch('alert/createAlertErrors', !isEmpty(data) ? decodeLaravelValidationErrors(data.errors) : "Oops. Something went wrong.", { root: true }) : null;
        break
      default:
        commit("EDIT_SPECIALTY_ERROR", data)
    }
    return response
  },
  async deleteSpecialty({ commit, dispatch }, params) {
    commit("DELETE_SPECIALTY_REQUEST")
    const { uuid } = params;
    const response = await specialtyService.deleteSpecialty(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_SPECIALTY_SUCCESS", { specialty: { uuid } }) : null;
        data.success ? dispatch('alert/createAlertSuccess', "Specialty deleted.", { root: true }) : null;
        data?.error ? dispatch('alert/createAlertErrors', !isEmpty(data) ? decodeLaravelValidationErrors(data.errors) : "Oops. Something went wrong.", { root: true }) : null;
        break
      default:
        commit("DELETE_SPECIALTY_DELETE", data)
    }
    return response
  }
}

const mutations = {
  [GET_SPECIALTIES_REQUEST]: state => {
    state.loading = true
  },
  [GET_SPECIALTIES_SUCCESS]: (state, data) => {
    state.specialties = addNewFilter(state.specialties, data?.specialties || [])
    state.loading = false
  },
  [GET_SPECIALTIES_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_SPECIALTY_REQUEST]: state => {
    state.loading = true
  },
  [GET_SPECIALTY_SUCCESS]: (state, data) => {
    const specialty = { ...data.specialty }
    state.specialty = specialty
    state.loading = false
  },
  [GET_SPECIALTY_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_SPECIALTY_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_SPECIALTY_SUCCESS]: (state, data) => {
    const { specialty } = data;
    state.specialties = addNewFilter(state.specialties, [specialty])
    state.loading = false
  },
  [CREATE_SPECIALTY_ERROR]: state => {
    state.loading = false
  },

  [EDIT_SPECIALTY_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_SPECIALTY_SUCCESS]: (state, data) => {
    const { specialty } = data;
    state.specialties = addNewFilter(state.specialties, [specialty])
    state.loading = false
  },
  [EDIT_SPECIALTY_ERROR]: state => {
    state.loading = false
  },
  [DELETE_SPECIALTY_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_SPECIALTY_SUCCESS]: (state, data) => {
    const { specialty } = data;
    state.specialties = state.specialties.fitler(s => s.uuid !== specialty?.uuid)
    state.loading = false
  },
  [DELETE_SPECIALTY_ERROR]: (state, data) => {
    state.loading = false
  }

}

export const specialty = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
