import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	AUTH_USER_REQUEST,
	AUTH_USER_ERROR,
	AUTH_USER_SUCCESS,
	AUTH_USER_UPDATE,
	LOGOUT_REQUEST,
	LOGOUT_SUCCESS,
	LOGOUT_ERROR,
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_ERROR,
	PASSWORD_RESET_REQUEST,
	PASSWORD_RESET_SUCCESS,
	PASSWORD_RESET_ERROR,
	GET_MAGIC_LINK_REQUEST,
	GET_MAGIC_LINK_SUCCESS,
	GET_MAGIC_LINK_ERROR,
	// UNAUTHORIZED
} from "@/actions"

import {
	authService,
	usersService
} from "@/services"

import { decodeLaravelValidationErrors, isEmpty } from "@/utilities"

// import { router } from "@/router"

const authDefaultState = () => {
	return {
		user: {},
		loading: false,
		loadedOnce: false
	}
}

const state = authDefaultState();

// const state = {
// 	user: {},
// 	loading: false,
// 	loadedOnce: false
// }

const getters = {
	authorizedUser: state => state.user,
	authorizedUserUuid: state => state.user.uuid,

	userRoles: (_state, getters) => {
		if (isEmpty(getters.authorizedUser)) return []
		return getters.authorizedUser.roles
	},

	/* Check Roles/Permission for various actions on components */
	canAccess: (state, getters, rootState, rootGetters) => allowedRoles => {
		if (typeof allowedRoles == 'string') {
			allowedRoles = [allowedRoles]
		}
		if (typeof rootGetters['coaches/coach']['uuid'] != typeof undefined && rootGetters['coaches/coach']['uuid'] == state.user?.coaches_uuid) return allowedRoles.includes('coach')
		return getters.userRoles.some(i => allowedRoles.includes(i))
	},

	hasTile: (state, getters, rootState, rootGetters) => key => {
		const hasTile = rootGetters['settings/hasTile']
		return hasTile(key)
	},

	currentRoute: (state, getters, rootState, rootGetters) => rootGetters('currentPath')
}

const actions = {
	async login({ commit }, formInput) {
		commit("LOGIN_REQUEST")
		const response = await authService.login(formInput) ?? {};
		const { status, data } = response
		switch (status) {
			case 200:
				commit("LOGIN_SUCCESS", data)
				break
			case 422:
				commit("LOGIN_ERROR", response)
				break
			default:
				commit("LOGIN_ERROR", response);
		}
		return response

	},
	async getAuthUser({ commit }) {
		commit("AUTH_USER_REQUEST")
		const response = await authService.getAuthUser()
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("AUTH_USER_SUCCESS", data) : commit("AUTH_USER_ERROR")
				break;
			default:
				commit("AUTH_USER_ERROR");
		}
		return response
	},
	async setAuthUserPassword({ commit }, formInput) {
		commit("AUTH_USER_REQUEST")
		const { uuid } = formInput
		const response = await usersService.editUser(formInput, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("AUTH_USER_UPDATE", data) : null
				break;
			default:
				commit("AUTH_USER_ERROR")
		}
		return response
	},
	async forgotPassword({ commit }, formInput) {
		commit("FORGOT_PASSWORD_REQUEST")
		const response = await authService.forgotPassword(formInput)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("FORGOT_PASSWORD_SUCCESS", data)
				break;
			default:
				commit("FORGOT_PASSWORD_ERROR", data);
		}
		return response
	},
	async resetPassword({ commit }, formInput) {
		commit("PASSWORD_RESET_REQUEST")
		const response = await authService.resetPassword(formInput)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("PASSWORD_RESET_SUCCESS", data)
				break;
			default:
				commit("PASSWORD_RESET_ERROR", data);
		}
		return response
	},
	async logout({ commit }) {
		commit("LOGOUT_REQUEST")
		const response = await authService.logout()
		const { status } = response
		switch (status) {
			case 200:
			case 302:
				commit("LOGOUT_SUCCESS")
				break;
			default:
				commit("LOGOUT_ERROR");
		}
		return response
	},
	async getMagicLink({ commit }, params) {
		commit("GET_MAGIC_LINK_REQUEST")
		const response = await authService.getMagicLink(params)
		const { status } = response
		switch (status) {
			case 200:
				commit("GET_MAGIC_LINK_SUCCESS")
				break;
			default:
				commit("GET_MAGIC_LINK_ERROR");
		}
		return response
	},
	async sendMagicLinkUniversityAdmin({ commit, dispatch }, params) {
		commit("GET_MAGIC_LINK_REQUEST")
		const response = await authService.sendMagicLinkUniversityAdmin(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? dispatch("alert/createAlertSuccess", "Magic Link sent to user", { root: true }) : null;
				data.error ? dispatch('alert/createAlertErrors', !isEmpty(data.errors) ? decodeLaravelValidationErrors(data.errors) : "Oops! Something went wrong.", { root: true }) : null;
				break;
			default:
				commit("GET_MAGIC_LINK_ERROR");
		}
		return response
	},
	async unauthorized({ commit, getters, rootGetters }) {

		// Default redirect for Admin users && Coach users who are Contracted and above (not 'Applicant' or 'Candidate')
		let redirect = 'login'

		// eslint-disable-next-line no-prototype-builtins
		if (!isEmpty(getters.authorizedUser) && Object(getters.authorizedUser).hasOwnProperty('roles')) {

			// Check if user has role Coach and if Status is 'Applicant' OR 'Candidate'
			// If true then redirect to 'coach-application auth-error'
			if (getters.authorizedUser.roles.includes('coach') && !isEmpty(rootGetters['coachStatus/coachStatus']) && !isEmpty(rootGetters['status/applicant']) && !isEmpty(rootGetters['status/candidate'])) {

				let applicant = rootGetters['status/applicant']
				let candidate = rootGetters['status/candidate']
				let coachStatus = rootGetters['coachStatus/coachStatus']

				if (coachStatus.statuses_uuid == applicant.uuid || coachStatus.statuses_uuid == candidate.uuid) {
					redirect = 'coach-application auth-error'
				}
			}
		}

		commit("UNAUTHORIZED", redirect)
	},
	resetUserState({ commit }) {
		commit("task/RESET_TASK_STATE", null, { root: true });
		commit("notification/RESET_NOTIFICATION_STATE", null, { root: true });
		commit("settings/RESET_SETTINGS_STATE", null, { root: true });

	}
}

const mutations = {
	[LOGIN_REQUEST]: state => {
		state.loading = true
	},
	[LOGIN_SUCCESS]: (state, data) => {
		const { user } = data
		state.user = user
		state.loading = false
		state.loadedOnce = true;
	},
	[LOGIN_ERROR]: state => {
		state.status = "error";
		state.loading = false
		state.loadedOnce = true;
	},


	[AUTH_USER_REQUEST]: state => {
		state.loading = true
	},
	[AUTH_USER_SUCCESS]: (state, data) => {
		const { user } = data
		if (isEmpty(state.user)) state.user = user;
		state.loading = false
	},
	[AUTH_USER_ERROR]: state => {
		state.loading = false
	},

	[AUTH_USER_UPDATE]: (state, data) => {
		const { user } = data
		state.loading = false
		state.user = user
	},


	[LOGOUT_REQUEST]: state => {
		state.loading = true
	},
	[LOGOUT_SUCCESS]: state => {
		state.loading = false
		state.user = {}
	},
	[LOGOUT_ERROR]: state => {
		state.loading = false
	},


	[FORGOT_PASSWORD_REQUEST]: state => {
		state.loading = true
	},
	[FORGOT_PASSWORD_SUCCESS]: state => {
		state.loading = false
	},
	[FORGOT_PASSWORD_ERROR]: state => {
		state.loading = false
	},


	[PASSWORD_RESET_REQUEST]: state => {
		state.loading = true
	},
	[PASSWORD_RESET_SUCCESS]: state => {
		state.loading = false
	},
	[PASSWORD_RESET_ERROR]: state => {
		state.loading = false
	},

	[GET_MAGIC_LINK_REQUEST]: state => {
		state.loading = true
	},
	[GET_MAGIC_LINK_SUCCESS]: state => {
		state.loading = false
	},
	[GET_MAGIC_LINK_ERROR]: state => {
		state.loading = false
	},

	RESET_AUTH_STATE: state => {
		Object.assign(state, authDefaultState())
	}

	// [UNAUTHORIZED]: (state, redirectTo) => {
	// 	state.user = null
	// 	router.push({ name: redirectTo })
	// }
}

export const auth = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
