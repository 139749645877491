import {
    apiRequest,
    processInput,
    processQueryParams
  } from "@/utilities"
  
  const moduleUrl = "coach-assignment-evaluations";

  export const coachEvaluationService = {
    async getCoachEvaluations(params) {
  
      const getCoachEvaluationsRequest = {
        method: "GET",
        params: processQueryParams(params)
      }
  
      return await apiRequest(`/api/${moduleUrl}`, getCoachEvaluationsRequest)
    },
    async getCoachEvaluationStatus(params) {
      let getParams = processQueryParams(params)
        const getCoachEvaluationStatusRequest = {
            method: "GET",
            params: getParams
        }
      return await apiRequest(`/api/coach-assignment-evaluation-status`, getCoachEvaluationStatusRequest)
    },
    async createCoachEvaluation(params) {
  
      const createData = processInput(params)
      const postCoachEvaluationRequest = {
        method: "POST",
        data: createData
      }
      return await apiRequest(`/api/${moduleUrl}`, postCoachEvaluationRequest)
    },
    async getCoachEvaluation(uuid) {
  
      const getCoachEvaluationRequest = {
        method: "GET"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, getCoachEvaluationRequest);
    },
    async editCoachEvaluation(data, uuid) {
  
      let editData = processInput(data)
      editData.append("_method", "PATCH")
  
      const updateCoachEvaluationRequest = {
        method: "POST",
        data: editData
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, updateCoachEvaluationRequest);
    },
    async deleteCoachEvaluation(uuid) {
  
      const deleteCoachEvaluationRequest = {
        method: "DELETE"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, deleteCoachEvaluationRequest);
    }
  }
  