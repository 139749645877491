import {
    GET_COURSE_LEVELS_REQUEST,
    GET_COURSE_LEVELS_SUCCESS,
    GET_COURSE_LEVELS_ERROR,
    GET_COURSE_LEVEL_REQUEST,
    GET_COURSE_LEVEL_SUCCESS,
    GET_COURSE_LEVEL_ERROR,
    CREATE_COURSE_LEVEL_REQUEST,
    CREATE_COURSE_LEVEL_SUCCESS,
    CREATE_COURSE_LEVEL_ERROR,
    EDIT_COURSE_LEVEL_REQUEST,
    EDIT_COURSE_LEVEL_SUCCESS,
    EDIT_COURSE_LEVEL_ERROR,
    DELETE_COURSE_LEVEL_REQUEST,
    DELETE_COURSE_LEVEL_SUCCESS,
    DELETE_COURSE_LEVEL_ERROR
} from "../../actions"

import {
    courseLevelService
} from "../../services"

const state = {
    courseLevels: [],
    courseLevel: {},
    loading: false
}

const getters = {
    courseLevelSelectOptions: state => {
        return state.courseLevels.map((courseLevel, index) => {
            return { value: courseLevel.uuid, label: courseLevel.label }
        })
    }
}

const actions = {
    async getCourseLevels({ commit }) {
        commit("GET_COURSE_LEVELS_REQUEST")
        const response = await courseLevelService.getCourseLevels();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COURSE_LEVELS_SUCCESS", data)
                break
            default:
                commit("GET_COURSE_LEVELS_ERROR", data)
        }
        return response
    },
    async getCourseLevel({ commit }, params) {
        commit("GET_COURSE_LEVEL_REQUEST")
        const response = await courseLevelService.getCourseLevel(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COURSE_LEVEL_SUCCESS", data)
                break
            default:
                commit("GET_COURSE_LEVEL_ERROR", data)
                break
        }
        return response
    },
    async createCourseLevel({ commit }, params) {
        commit("CREATE_COURSE_LEVEL_REQUEST")
        const response = await courseLevelService.createCourseLevel(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COURSE_LEVEL_SUCCESS", data)
                break
            default:
                commit("CREATE_COURSE_LEVEL_ERROR", data)
        }
        return response
    },
    async editCourseLevel({ commit }, params, uuid) {
        commit("EDIT_COURSE_LEVEL_REQUEST")
        const response = await courseLevelService.editCourseLevel(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COURSE_LEVEL_SUCCESS", data)
                break
            default:
                commit("EDIT_COURSE_LEVEL_ERROR", data)
        }
        return response
    },
    async deleteCourseLevel({ commit }, params) {
        commit("DELETE_COURSE_LEVEL_REQUEST")
        const response = await courseLevelService.deleteCourseLevel(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COURSE_LEVEL_SUCCESS", data)
                break
            default:
                commit("DELETE_COURSE_LEVEL_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COURSE_LEVELS_REQUEST]: state => {
        state.loading = true
    },
    [GET_COURSE_LEVELS_SUCCESS]: (state, data) => {
        const courseLevels = [...data.course_levels]
        state.courseLevels = courseLevels
        state.loading = false
    },
    [GET_COURSE_LEVELS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COURSE_LEVEL_REQUEST]: state => {
        state.loading = true
    },
    [GET_COURSE_LEVEL_SUCCESS]: (state, data) => {
        const courseLevel = { ...data.course_level }
        state.courseLevel = courseLevel
        state.loading = false
    },
    [GET_COURSE_LEVEL_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COURSE_LEVEL_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COURSE_LEVEL_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [CREATE_COURSE_LEVEL_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COURSE_LEVEL_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COURSE_LEVEL_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COURSE_LEVEL_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COURSE_LEVEL_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COURSE_LEVEL_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_COURSE_LEVEL_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const courseLevel = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}