import {
    apiRequest,
    processInput
} from "@/utilities"

export const nurseLicenseService = {
    async getNurseLicenses() {
        const getNurseLicensesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/nurse-licenses", getNurseLicensesRequest)
    },
    async getNurseLicense(uuid) {
        const getNurseLicenseRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/nurse-licenses/${uuid}`, getNurseLicenseRequest)
    },
    async createNurseLicense(params) {
        const createData = processInput(params)
        const createNurseLicenseRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/nurse-licenses", createNurseLicenseRequest)
    },
    async editNurseLicense(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editNurseLicenseRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/nurse-licenses/${uuid}`, editNurseLicenseRequest)
    },
    async deleteNurseLicense(uuid) {
        const deleteNurseLicenseRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/nurse-licenses/${uuid}`, deleteNurseLicenseRequest)
    }
}