import {
  GET_ASSIGNMENT_PAYMENTS_REQUEST,
  GET_ASSIGNMENT_PAYMENTS_SUCCESS,
  GET_ASSIGNMENT_PAYMENTS_ERROR,
  
  GET_ASSIGNMENT_PAYMENT_REQUEST,
  GET_ASSIGNMENT_PAYMENT_SUCCESS,
  GET_ASSIGNMENT_PAYMENT_ERROR,

  CREATE_ASSIGNMENT_PAYMENT_REQUEST,
  CREATE_ASSIGNMENT_PAYMENT_SUCCESS,
  CREATE_ASSIGNMENT_PAYMENT_ERROR,

  EDIT_ASSIGNMENT_PAYMENT_REQUEST,
  EDIT_ASSIGNMENT_PAYMENT_SUCCESS,
  EDIT_ASSIGNMENT_PAYMENT_ERROR,

  DELETE_ASSIGNMENT_PAYMENT_REQUEST,
  DELETE_ASSIGNMENT_PAYMENT_SUCCESS,
  DELETE_ASSIGNMENT_PAYMENT_ERROR,
} from "@/actions"

import {
  assignmentPaymentService
} from "@/services"

import { addNewFilter } from "@/utilities"

const state = {
  assignmentPayments: [],
  loading: false,
  loadedOnce: false
}

const getters = {
  assignmentPaymentLoading: state => state.loading,

  assignmentPaymentsLoadedOnce: state => state.loadedOnce,

  assignmentPayments: state => state.assignmentPayments,

  assignmentPaymentByUuid: state => uuid => state.assignmentPayments.find(i => i.uuid == uuid),

  assignmentPaymentByCoachAssignmentUuid: state => uuid => state.assignmentPayments.find(i => i.coach_assignments_uuid == uuid),

  assignmentPaymentByCoachAssignmentsUuid: state => caUuid => state.assignmentPayments.find(i => {
    return i.coach_assignments_uuid == caUuid;
  })
}

const actions = {
  async getAssignmentPayments({ commit }) {
    commit("GET_ASSIGNMENT_PAYMENTS_REQUEST")
    const response = await assignmentPaymentService.getAssignmentPayments();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_ASSIGNMENT_PAYMENTS_SUCCESS", data)
        break
      default:
        commit("GET_ASSIGNMENT_PAYMENTS_ERROR", data)
    }
    return response
  },
  async getAssignmentPayment({ commit }, params) {
    commit("GET_ASSIGNMENT_PAYMENT_REQUEST")
    const response = await assignmentPaymentService.getAssignmentPayment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_ASSIGNMENT_PAYMENT_SUCCESS", data)
        break
      default:
        commit("GET_ASSIGNMENT_PAYMENT_ERROR", data)
        break
    }
    return response
  },
  async createAssignmentPayment({ commit, dispatch }, params) {
    commit("CREATE_ASSIGNMENT_PAYMENT_REQUEST")
    const response = await assignmentPaymentService.createAssignmentPayment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_ASSIGNMENT_PAYMENT_SUCCESS", data) : null
        data.success ? dispatch("alert/createAlertSuccess", "Assignment Payment created.", { root: true }): null
        break
      default:
        commit("CREATE_ASSIGNMENT_PAYMENT_ERROR", data)
    }
    return response
  },
  async editAssignmentPayment({ commit }, params) {
    commit("EDIT_ASSIGNMENT_PAYMENT_REQUEST")
    const response = await assignmentPaymentService.editAssignmentPayment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_ASSIGNMENT_PAYMENT_SUCCESS", data) : null
        break
      default:
        commit("EDIT_ASSIGNMENT_PAYMENT_ERROR", data)
    }
    return response
  },
  async deleteAssignmentPayment({ commit, dispatch }, uuid) {
    commit("DELETE_ASSIGNMENT_PAYMENT_REQUEST")
    const response = await assignmentPaymentService.deleteAssignmentPayment(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_ASSIGNMENT_PAYMENT_SUCCESS", { uuid }) : null
        data.success ? dispatch("alert/createAlertSuccess", "Assignment Payment deleted.", { root: true }) : null
        break
      default:
        commit("DELETE_ASSIGNMENT_PAYMENT_ERROR", data)
    }
    return response
  },
}

const mutations = {
  [GET_ASSIGNMENT_PAYMENTS_REQUEST]: state => {
    state.loading = true
  },
  [GET_ASSIGNMENT_PAYMENTS_SUCCESS]: (state, data) => {
    const assignment_payments = data.assignment_payments
    state.assignmentPayments = addNewFilter(state.assignmentPayments, assignment_payments);
    state.loading = false
    state.loadedOnce = true
  },
  [GET_ASSIGNMENT_PAYMENTS_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_ASSIGNMENT_PAYMENT_REQUEST]: state => {
    state.loading = true
  },
  [GET_ASSIGNMENT_PAYMENT_SUCCESS]: (state, data) => {
    const { assignment_payment } = data
    state.assignmentPayments = addNewFilter(state.assignmentPayments, [assignment_payment])
    state.loading = false
  },
  [GET_ASSIGNMENT_PAYMENT_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_ASSIGNMENT_PAYMENT_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_ASSIGNMENT_PAYMENT_SUCCESS]: (state, data) => {
    const { assignment_payment } = data;
    state.assignmentPayments = addNewFilter(state.assignmentPayments, [assignment_payment])
    state.loading = false;
  },
  [CREATE_ASSIGNMENT_PAYMENT_ERROR]: state => {
    state.loading = false
  },

  [EDIT_ASSIGNMENT_PAYMENT_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_ASSIGNMENT_PAYMENT_SUCCESS]: (state, data) => {
    const { assignment_payment } = data;
    state.assignmentPayments = addNewFilter(state.assignmentPayments, [assignment_payment]);
    state.loading = false;
  },
  [EDIT_ASSIGNMENT_PAYMENT_ERROR]: state => {
    state.loading = false
  },

  [DELETE_ASSIGNMENT_PAYMENT_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_ASSIGNMENT_PAYMENT_SUCCESS]: (state, data) => {
    const { uuid } = data;
    state.assignmentPayments = state.assignmentPayments.filter(i => i.uuid !== uuid)
    state.loading = false
  },
  [DELETE_ASSIGNMENT_PAYMENT_ERROR]: (state, data) => {
    state.loading = false
  }
}

export const assignmentPayment = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}