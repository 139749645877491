import {
    apiRequest,
    processInput,
    processQueryParams
  } from "@/utilities"
  
  const moduleUrl = "perfomance-notes";

  export const perfomanceNoteService = {
    async getPerfomanceNotes(params) {
  
      const getPerfomanceNotesRequest = {
        method: "GET",
        params: processQueryParams(params)
      }
  
      return await apiRequest(`/api/${moduleUrl}`, getPerfomanceNotesRequest)
    },
    async createPerfomanceNote(params) {
  
      const createData = processInput(params)
      const postPerfomanceNoteRequest = {
        method: "POST",
        data: createData
      }
      return await apiRequest(`/api/${moduleUrl}`, postPerfomanceNoteRequest)
    },
    async getPerfomanceNote(uuid) {
  
      const getPerfomanceNoteRequest = {
        method: "GET"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, getPerfomanceNoteRequest);
    },
    async editPerfomanceNote(data, uuid) {
  
      let editData = processInput(data)
      editData.append("_method", "PATCH")
  
      const updatePerfomanceNoteRequest = {
        method: "POST",
        data: editData
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, updatePerfomanceNoteRequest);
    },
    async deletePerfomanceNote(uuid) {
  
      const deletePerfomanceNoteRequest = {
        method: "DELETE"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, deletePerfomanceNoteRequest);
    }
  }
  