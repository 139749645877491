import {
    GET_STATUS_UNIVERSITIES_REQUEST,
    GET_STATUS_UNIVERSITIES_SUCCESS,
    GET_STATUS_UNIVERSITIES_ERROR,
    GET_STATUS_UNIVERSITY_REQUEST,
    GET_STATUS_UNIVERSITY_SUCCESS,
    GET_STATUS_UNIVERSITY_ERROR,
    CREATE_STATUS_UNIVERSITY_REQUEST,
    CREATE_STATUS_UNIVERSITY_SUCCESS,
    CREATE_STATUS_UNIVERSITY_ERROR,
    EDIT_STATUS_UNIVERSITY_REQUEST,
    EDIT_STATUS_UNIVERSITY_SUCCESS,
    EDIT_STATUS_UNIVERSITY_ERROR,
    DELETE_STATUS_UNIVERSITY_REQUEST,
    DELETE_STATUS_UNIVERSITY_SUCCESS,
    DELETE_STATUS_UNIVERSITY_ERROR
} from "@/actions"

import {
    statusUniversityService
} from "@/services"

const state = {
    statusUniversities: [],
    statusUniversity: {},
    loading: false,
    onboardingSlugs: [
        'inquiry',
        'negotiation'
    ],
    contractedSlugs: [
        'contracted',
        'inactive',
        'archive'
    ],
    facultyContractedSlugs: [
        'contracted-adjunct-faculty-sourcing',
    ],
}

const getters = {
    allStatusUniversity: state => state.statusUniversities,

    statusUnivSelectOptions: state => state.statusUniversities.map(univStatus => ({ value: univStatus.uuid, label: univStatus.name })),

    statusUnivMultiSelectOptions: state => state.statusUniversities.map(su => ({ value: su.uuid, text: su.name })),

    statusUnivFilter: state => uuid => state.statusUniversities.find(statusUniv => statusUniv.uuid == uuid),

    onboardingUuids: state => state.statusUniversities.filter(su => state.onboardingSlugs.includes(su.slug)).map(s => s.uuid),

    contractedUuids: state => state.statusUniversities.filter(su => state.contractedSlugs.includes(su.slug)).map(s => s.uuid),
    
    facultyContractedUuids: state => state.statusUniversities.filter(su => state.facultyContractedSlugs.includes(su.slug)).map(s => s.uuid)
}

const actions = {
    async getStatusUniversities({ commit }) {
        commit("GET_STATUS_UNIVERSITIES_REQUEST")
        const response = await statusUniversityService.getStatusUniversities()
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("GET_STATUS_UNIVERSITIES_SUCCESS", data) : commit("GET_STATUS_UNIVERSITIES_ERROR", data)
                break
            default:
                commit("GET_STATUS_UNIVERSITIES_ERROR", data)
        }
        return response
    },
    async getStatusUniversity({ commit }, params) {
        commit("GET_STATUS_UNIVERSITY_REQUEST")
        const response = await statusUniversityService.getStatusUniversity(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_STATUS_UNIVERSITY_SUCCESS", data)
                break
            default:
                commit("GET_STATUS_UNIVERSITY_ERROR", data)
                break
        }
        return response
    },
    async createStatusUniversity({ commit }, params) {
        commit("CREATE_STATUS_UNIVERSITY_REQUEST")
        const response = await statusUniversityService.createStatusUniversity(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_STATUS_UNIVERSITY_SUCCESS", data)
                break
            default:
                commit("CREATE_STATUS_UNIVERSITY_ERROR", data)
        }
        return response
    },
    async editStatusUniversity({ commit }, params, uuid) {
        commit("EDIT_STATUS_UNIVERSITY_REQUEST")
        const response = await statusUniversityService.editStatusUniversity(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_STATUS_UNIVERSITY_SUCCESS", data)
                break
            default:
                commit("EDIT_STATUS_UNIVERSITY_ERROR", data)
        }
        return response
    },
    async deleteStatusUniversity({ commit }, params) {
        commit("DELETE_STATUS_UNIVERSITY_REQUEST")
        const response = await statusUniversityService.deleteStatusUniversity(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_STATUS_UNIVERSITY_SUCCESS", data)
                break
            default:
                commit("DELETE_STATUS_UNIVERSITY_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_STATUS_UNIVERSITIES_REQUEST]: state => {
        state.loading = true
    },
    [GET_STATUS_UNIVERSITIES_SUCCESS]: (state, data) => {
        const statusUniversities = [...data.status_universities]
        state.statusUniversities = statusUniversities
        state.loading = false
    },
    [GET_STATUS_UNIVERSITIES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_STATUS_UNIVERSITY_REQUEST]: state => {
        state.loading = true
    },
    [GET_STATUS_UNIVERSITY_SUCCESS]: (state, data) => {
        const statusUniversity = { ...data.status_university }
        state.statusUniversity = statusUniversity
        state.loading = false
    },
    [GET_STATUS_UNIVERSITY_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_STATUS_UNIVERSITY_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_STATUS_UNIVERSITY_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [CREATE_STATUS_UNIVERSITY_ERROR]: state => {
        state.loading = false
    },

    [EDIT_STATUS_UNIVERSITY_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_STATUS_UNIVERSITY_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_STATUS_UNIVERSITY_ERROR]: state => {
        state.loading = false
    },
    [DELETE_STATUS_UNIVERSITY_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_STATUS_UNIVERSITY_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_STATUS_UNIVERSITY_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const statusUniversity = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}