import {
    GET_COACH_TEACHING_COURSE_LEVELS_REQUEST,
    GET_COACH_TEACHING_COURSE_LEVELS_SUCCESS,
    GET_COACH_TEACHING_COURSE_LEVELS_ERROR,
    GET_COACH_TEACHING_COURSE_LEVEL_REQUEST,
    GET_COACH_TEACHING_COURSE_LEVEL_SUCCESS,
    GET_COACH_TEACHING_COURSE_LEVEL_ERROR,
    CREATE_COACH_TEACHING_COURSE_LEVEL_REQUEST,
    CREATE_COACH_TEACHING_COURSE_LEVEL_SUCCESS,
    CREATE_COACH_TEACHING_COURSE_LEVEL_ERROR,
    EDIT_COACH_TEACHING_COURSE_LEVEL_REQUEST,
    EDIT_COACH_TEACHING_COURSE_LEVEL_SUCCESS,
    EDIT_COACH_TEACHING_COURSE_LEVEL_ERROR,
    DELETE_COACH_TEACHING_COURSE_LEVEL_REQUEST,
    DELETE_COACH_TEACHING_COURSE_LEVEL_SUCCESS,
    DELETE_COACH_TEACHING_COURSE_LEVEL_ERROR,
    CLEAR_COACH_TEACHING_COURSE_LEVEL_STATE
} from "@/actions"

import {
    coachTeachingCourseLevelService
} from "@/services"

const state = {
    coachTeachingCourseLevels: [],
    coachTeachingCourseLevel: {},
    loading: false,
    loadedOnce: false
}

const getters = {
    ctclLoadedOnce: state => state.loadedOnce,
    coachTeachingCourseLevels: state => {
        return state.coachTeachingCourseLevels
    },
    ctclByUuid: state => state.coachTeachingCourseLevels.map(ctcl => {
        return ctcl.course_level.uuid
    }),
    findCTCLByCLUuid: state => uuid => state.coachTeachingCourseLevels.find(ctcl => {
        return ctcl.course_level.uuid == uuid
    })
}

const actions = {
    async getCoachTeachingCourseLevels({ commit }, params) {
        commit("GET_COACH_TEACHING_COURSE_LEVELS_REQUEST")
        const response = await coachTeachingCourseLevelService.getCoachTeachingCourseLevels(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_TEACHING_COURSE_LEVELS_SUCCESS", data)
                break
            default:
                commit("GET_COACH_TEACHING_COURSE_LEVELS_ERROR", data)
        }
        return response
    },
    async getCoachTeachingCourseLevel({ commit }, params) {
        commit("GET_COACH_TEACHING_COURSE_LEVEL_REQUEST")
        const response = await coachTeachingCourseLevelService.getCoachTeachingCourseLevel(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_TEACHING_COURSE_LEVEL_SUCCESS", data)
                break
            default:
                commit("GET_COACH_TEACHING_COURSE_LEVEL_ERROR", data)
                break
        }
        return response
    },
    async createCoachTeachingCourseLevel({ commit }, params) {
        commit("CREATE_COACH_TEACHING_COURSE_LEVEL_REQUEST")
        const response = await coachTeachingCourseLevelService.createCoachTeachingCourseLevel(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COACH_TEACHING_COURSE_LEVEL_SUCCESS", data)
                break
            default:
                commit("CREATE_COACH_TEACHING_COURSE_LEVEL_ERROR", data)
        }
        return response
    },
    async editCoachTeachingCourseLevel({ commit }, params) {
        commit("EDIT_COACH_TEACHING_COURSE_LEVEL_REQUEST")
        const uuid = params.uuid
        const response = await coachTeachingCourseLevelService.editCoachTeachingCourseLevel(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COACH_TEACHING_COURSE_LEVEL_SUCCESS", data)
                break
            default:
                commit("EDIT_COACH_TEACHING_COURSE_LEVEL_ERROR", data)
        }
        return response
    },
    async deleteCoachTeachingCourseLevel({ commit }, uuid) {
        commit("DELETE_COACH_TEACHING_COURSE_LEVEL_REQUEST")
        const response = await coachTeachingCourseLevelService.deleteCoachTeachingCourseLevel(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COACH_TEACHING_COURSE_LEVEL_SUCCESS", uuid)
                break
            default:
                commit("DELETE_COACH_TEACHING_COURSE_LEVEL_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_TEACHING_COURSE_LEVELS_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_TEACHING_COURSE_LEVELS_SUCCESS]: (state, data) => {
        const coachTeachingCourseLevels = [...data.coach_teaching_course_levels]
        state.coachTeachingCourseLevels = coachTeachingCourseLevels
        state.loading = false
        state.loadedOnce = true
    },
    [GET_COACH_TEACHING_COURSE_LEVELS_ERROR]: state => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [GET_COACH_TEACHING_COURSE_LEVEL_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_TEACHING_COURSE_LEVEL_SUCCESS]: (state, data) => {
        const coachTeachingCourseLevel = { ...data.coach_teaching_course_level }
        state.coachTeachingCourseLevel = coachTeachingCourseLevel
        state.loading = false
    },
    [GET_COACH_TEACHING_COURSE_LEVEL_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_TEACHING_COURSE_LEVEL_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_TEACHING_COURSE_LEVEL_SUCCESS]: (state, data) => {
        const coachTeachingCourseLevel = { ...data.coach_teaching_course_level }
        state.coachTeachingCourseLevels.push(coachTeachingCourseLevel)
        state.loading = false
    },
    [CREATE_COACH_TEACHING_COURSE_LEVEL_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_TEACHING_COURSE_LEVEL_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_TEACHING_COURSE_LEVEL_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COACH_TEACHING_COURSE_LEVEL_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_TEACHING_COURSE_LEVEL_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_TEACHING_COURSE_LEVEL_SUCCESS]: (state, uuid) => {
        state.coachTeachingCourseLevels = state.coachTeachingCourseLevels.filter(cee => cee.uuid !== uuid)
        state.loading = false
    },
    [DELETE_COACH_TEACHING_COURSE_LEVEL_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COACH_TEACHING_COURSE_LEVEL_STATE]: (state) => {
        state.coachTeachingCourseLevels = []
        state.coachTeachingCourseLevel = {}
        state.loading = false
        state.loadedOnce = false
    }

}

export const coachTeachingCourseLevel = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}