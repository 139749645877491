export const GET_FACILITATING_COURSES_REQUEST = "GET_FACILITATING_COURSES_REQUEST"
export const GET_FACILITATING_COURSES_SUCCESS = "GET_FACILITATING_COURSES_SUCCESS"
export const GET_FACILITATING_COURSES_ERROR = "GET_FACILITATING_COURSES_ERROR"
export const GET_FACILITATING_COURSE_REQUEST = "GET_FACILITATING_COURSE_REQUEST"
export const GET_FACILITATING_COURSE_SUCCESS = "GET_FACILITATING_COURSE_SUCCESS"
export const GET_FACILITATING_COURSE_ERROR = "GET_FACILITATING_COURSE_ERROR"
export const CREATE_FACILITATING_COURSE_REQUEST = "CREATE_FACILITATING_COURSE_REQUEST"
export const CREATE_FACILITATING_COURSE_SUCCESS = "CREATE_FACILITATING_COURSE_SUCCESS"
export const CREATE_FACILITATING_COURSE_ERROR = "CREATE_FACILITATING_COURSE_ERROR"
export const EDIT_FACILITATING_COURSE_REQUEST = "EDIT_FACILITATING_COURSE_REQUEST"
export const EDIT_FACILITATING_COURSE_SUCCESS = "EDIT_FACILITATING_COURSE_SUCCESS"
export const EDIT_FACILITATING_COURSE_ERROR = "EDIT_FACILITATING_COURSE_ERROR"
export const DELETE_FACILITATING_COURSE_REQUEST = "DELETE_FACILITATING_COURSE_REQUEST"
export const DELETE_FACILITATING_COURSE_SUCCESS = "DELETE_FACILITATING_COURSE_SUCCESS"
export const DELETE_FACILITATING_COURSE_ERROR = "DELETE_FACILITATING_COURSE_ERROR"