import {
	GET_EMAILS_REQUEST,
	GET_EMAILS_SUCCESS,
	GET_EMAILS_ERROR,
	GET_EMAIL_REQUEST,
	GET_EMAIL_SUCCESS,
	GET_EMAIL_ERROR,
	CREATE_EMAIL_REQUEST,
	CREATE_EMAIL_SUCCESS,
	CREATE_EMAIL_ERROR,
	EDIT_EMAIL_REQUEST,
	EDIT_EMAIL_SUCCESS,
	EDIT_EMAIL_ERROR,
	DELETE_EMAIL_REQUEST,
	DELETE_EMAIL_SUCCESS,
	DELETE_EMAIL_ERROR,
	BULK_EDIT_EMAIL_REQUEST,
	BULK_EDIT_EMAIL_SUCCESS,
	BULK_EDIT_EMAIL_ERROR,
	CLEAR_EMAIL_STATE
} from "@/actions"

import { emailService } from "@/services"
import { store } from "@/store"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
	emails: [],
	email: {},
	loading: false,
	loadedOnce: false
}

const getters = {
	emailLoadedOnce: state => state.loadedOnce,
	emailLoading: state => state.loading,
	//Assumes the context of the the Coach Application OR Coach Details. Rooted from coaches module 'coach' getter.
	coachEmailsByOwner: (state, getters, rootState, rootGetters) => state.emails.filter(email => email.emailable_uuid == rootGetters['coaches/coach']['uuid']),
	coachSecondaryEmail: (state, getters) => getters.coachEmailsByOwner.find(email => email.email_type.slug == 'secondary-email'),
	coachICEmail: (state, getters, rootState, rootGetters) => cUuid => state.emails.find(e => {
		let icEmailType = rootGetters['emailType/icEmailType'];

		if (!isEmpty(icEmailType)) {
			return icEmailType.uuid == e.email_type.uuid && e.emailable_uuid == cUuid;
		}

		return false;
	}),
	emailsByEmailableUuid: state => uuid => state.emails.filter(e => e.emailable_uuid == uuid),
	coachPwdResetEmails: state => state.emails.filter(i => i.emailable_type == 'App\\Models\\Coach' && i.reset_pwd_req == 1)
}

const actions = {
	async getEmails({ commit }, params) {
		commit("GET_EMAILS_REQUEST")
		const response = await emailService.getEmails(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if(data.success){
					commit("GET_EMAILS_SUCCESS", data)
				}
				break
			default:
				commit("GET_EMAILS_ERROR", data)
		}
		return response
	},
	async getEmail({ commit }, params) {
		commit("GET_EMAIL_REQUEST")
		const response = await emailService.getEmail(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_EMAIL_SUCCESS", data)
				break
			default:
				commit("GET_EMAIL_ERROR", data)
				break
		}
		return response
	},
	async createEmail({ commit }, params) {
		commit("CREATE_EMAIL_REQUEST")
		const response = await emailService.createEmail(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_EMAIL_SUCCESS", data) : commit("CREATE_EMAIL_ERROR", data)
				break
			default:
				commit("CREATE_EMAIL_ERROR", data)
		}
		return response
	},
	async bulkEditEmails({ commit }, params) {
		commit("BULK_EDIT_EMAIL_REQUEST")
		const response = await emailService.bulkEditEmails(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("BULK_EDIT_EMAIL_SUCCESS", data)
				break
			default:
				commit("BULK_EDIT_EMAIL_ERROR", data)
		}
		return response
	},
	async editEmail({ commit }, params) {
		commit("EDIT_EMAIL_REQUEST")
		const response = await emailService.editEmail(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_EMAIL_SUCCESS", data)
				break
			default:
				commit("EDIT_EMAIL_ERROR", data)
		}
		return response
	},
	async deleteEmail({ commit }, params) {
		commit("DELETE_EMAIL_REQUEST")
		const response = await emailService.deleteEmail(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_EMAIL_SUCCESS", data)
				break
			default:
				commit("DELETE_EMAIL_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_EMAILS_REQUEST]: state => {
		state.loading = true
	},
	[GET_EMAILS_SUCCESS]: (state, data) => {
		const emails = data.emails.map(e => unpackEmails(e))
		state.emails = addNewFilter(state.emails, emails)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_EMAILS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_EMAIL_REQUEST]: state => {
		state.loading = true
	},
	[GET_EMAIL_SUCCESS]: (state, data) => {
		const email = { ...data.email }
		state.email = email
		state.loading = false
	},
	[GET_EMAIL_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_EMAIL_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_EMAIL_SUCCESS]: (state, data) => {
		let { email } = data
		state.emails = state.emails.concat(email)
		state.loading = false
	},
	[CREATE_EMAIL_ERROR]: state => {
		state.loading = false
	},

	[EDIT_EMAIL_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_EMAIL_SUCCESS]: (state, data) => {
		let { email } = data
		if(!isEmpty(email)){
			email = unpackEmails(email)
			state.emails = state.emails.map(e => e.uuid == email.uuid ? email : e)
			state.emails = addNewFilter(state.emails, [email], true)
			state.loading = false
		}
	},
	[EDIT_EMAIL_ERROR]: state => {
		state.loading = false
	},

	[DELETE_EMAIL_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_EMAIL_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_EMAIL_ERROR]: (state, data) => {
		state.loading = false
	},

	[CLEAR_EMAIL_STATE]: (state) => {
		state.emails = []
		state.email = {}
		state.loading = false
		state.loadedOnce = false
	},

	[BULK_EDIT_EMAIL_REQUEST]: state => {
		state.loading = true
	},
	[BULK_EDIT_EMAIL_SUCCESS]: state => {
		state.loading = false
	},
	[BULK_EDIT_EMAIL_ERROR]: state => {
		state.loading = false
	}
}

export const email = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

export function unpackEmails(email){
	if(!isEmpty(email.emailable)){
		if(email.emailable_type == 'App\\Models\\Coach'){
			const findCoach = store.getters['coaches/coachByUuid']
			const coach = findCoach(email.emailable_uuid)
			if(isEmpty(coach)){
				store.commit('coaches/GET_COACHES_SUCCESS', {coaches: [email.emailable]}, { root: true })
			}
		}
		delete email.emailable
	}
	return email

}