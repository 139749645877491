import { queryBuilderService } from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

import Vue from 'vue';

const state = {
	tables: [],
	columns: [],
	loading: false,
}

const getters = {
	queryBuilderLoading: state => state.loading,
	queryBuilderTables: state => state.tables.map(t => {
		return {
			label: t,
			value: t
		}
	}),
	queryBuilderColumns: state => state.columns,
	queryBuilderTableFieldOptions: state => table => state.columns.filter(o => o.table == table)
}

const actions = {
	async getTables({ commit }, params) {
		commit("GET_TABLES_REQUEST")
		const response = await queryBuilderService.getTables(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_TABLES_SUCCESS", data)
				break
			default:
				commit("GET_TABLES_ERROR", TABLES)
		}
		return response
	},
	async getSelectOptions({ commit }, params) {
		commit("GET_COLUMNS_REQUEST")
		const response = await queryBuilderService.getSelectOptions(params);
		const { status, data } = response
		switch (status) {
			case 200:
				data.primary = params?.primary
				commit("GET_COLUMNS_SUCCESS", data, params)
				break
			default:
				commit("GET_COLUMNS_ERROR", data)
		}
		return response
	},
	async queryDatabase({ commit }, params) {
		commit("QUERY_DATABASE_REQUEST")
		const response = await queryBuilderService.queryDatabase(params);
		const { status, data } = response
		switch (status) {
			case 200:
				data.table = params?.primary
				commit("QUERY_DATABASE_SUCCESS", { ...params, ...data })
				break
			default:
				commit("QUERY_DATABASE_ERROR", data)
		}

		return response;
	}
}

const mutations = {
	GET_TABLES_REQUEST: state => {
		state.loading = true
	},
	GET_TABLES_SUCCESS: (state, data) => {
		const { tables } = data;
		state.tables = tables;
		state.loading = false;
	},
	GET_TABLES_ERROR: state => {
		state.loading = false
	},
	GET_COLUMNS_REQUEST: state => {
		state.loading = true
	},
	GET_COLUMNS_SUCCESS: (state, data) => {
		const { fields } = data
		state.columns = addNewFilter(state.columns, fields);
		// Vue.set(state.columns, table, fields);
		state.loading = false;
	},
	GET_COLUMNS_ERROR: state => {
		state.loading = false
	},
	QUERY_DATABASE_REQUEST: state => {
		state.loading = true;
	},
	QUERY_DATABASE_SUCCESS: state => {
		state.loading = false;
	},
	QUERY_DATABASE_ERROR: state => {
		state.loading = false
	}
}

export const queryBuilder = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
