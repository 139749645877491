import {
  GET_POINT_OF_CONTACT_TYPES_REQUEST,
  GET_POINT_OF_CONTACT_TYPES_SUCCESS,
  GET_POINT_OF_CONTACT_TYPES_ERROR,
  GET_POINT_OF_CONTACT_TYPE_REQUEST,
  GET_POINT_OF_CONTACT_TYPE_SUCCESS,
  GET_POINT_OF_CONTACT_TYPE_ERROR,
  CREATE_POINT_OF_CONTACT_TYPE_REQUEST,
  CREATE_POINT_OF_CONTACT_TYPE_SUCCESS,
  CREATE_POINT_OF_CONTACT_TYPE_ERROR,
  EDIT_POINT_OF_CONTACT_TYPE_REQUEST,
  EDIT_POINT_OF_CONTACT_TYPE_SUCCESS,
  EDIT_POINT_OF_CONTACT_TYPE_ERROR,
  DELETE_POINT_OF_CONTACT_TYPE_REQUEST,
  DELETE_POINT_OF_CONTACT_TYPE_SUCCESS,
  DELETE_POINT_OF_CONTACT_TYPE_ERROR
} from "@/actions"

import {
  pointOfContactTypesService
} from "@/services"

const state = {
  pointOfContactTypes: [],
  pointOfContactType: {},
  loading: false,
  loadedOnce: false
}

const getters = {
  pointOfContactTypes: state => state.pointOfContactTypes.sort((a, b) => a.label.localeCompare(b.label)),
  pocTypeLoading: state => state.loading,
  pocTypeLoadedOnce: state => state.loadedOnce,
  pocTypeSelectOptions: state => state.pointOfContactTypes.map(type => ({ value: type.uuid, label: type.label, slug: type.slug })).sort((a, b) => a.label.localeCompare(b.label)),
  pocTypeMselectOptions: state => state.pointOfContactTypes.map(type => ({ value: type.uuid, text: type.label, slug: type.slug })).sort((a, b) => a.text.localeCompare(b.text)),
  pointOfContactTypeFilter: state => uuid => state.pointOfContactTypes.find(poct => poct.uuid == uuid),
  pocTypeBySlugFilter: state => slug => state.pointOfContactTypes.find(type => type.slug == slug),
  pocTypeBySlugArrayFilter: state => slugs => state.pointOfContactTypes.filter(type => slugs.some(s => s == type.slug)),
  pocTypeByUuidArrayFilter: state => uuids => state.pointOfContactTypes.filter(type => uuids.some(s => s == type.uuid)),
  univInvoice: (state, getters, rootState, rootGetters) => uUuid => {
    let types = state.pointOfContactTypes.filter(t => t.slug == 'billing' || t.slug == 'invoice-and-billing')
    return rootGetters['pointOfContact/univTypeArray'](uUuid, types.map(t => t.uuid))
  }
}

const actions = {
  async getPointOfContactTypes({ commit }) {
    commit("GET_POINT_OF_CONTACT_TYPES_REQUEST")
    const response = await pointOfContactTypesService.getPointOfContactTypes();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_POINT_OF_CONTACT_TYPES_SUCCESS", data)
        break
      default:
        commit("GET_POINT_OF_CONTACT_TYPES_ERROR", data)
    }
    return response
  },
  async getPointOfContactType({ commit }, params) {
    commit("GET_POINT_OF_CONTACT_TYPE_REQUEST")
    const response = await pointOfContactTypesService.getPointOfContactType(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_POINT_OF_CONTACT_TYPE_SUCCESS", data)
        break
      default:
        commit("GET_POINT_OF_CONTACT_TYPE_ERROR", data)
        break
    }
    return response
  },
  async createPointOfContactType({ commit }, params) {
    commit("CREATE_POINT_OF_CONTACT_TYPE_REQUEST")
    const response = await pointOfContactTypesService.createPointOfContactType(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("CREATE_POINT_OF_CONTACT_TYPE_SUCCESS", data)
        break
      default:
        commit("CREATE_POINT_OF_CONTACT_TYPE_ERROR", data)
    }
    return response
  },
  async editPointOfContactType({ commit }, params, uuid) {
    commit("EDIT_POINT_OF_CONTACT_TYPE_REQUEST")
    const response = await pointOfContactTypesService.editPointOfContactType(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_POINT_OF_CONTACT_TYPE_SUCCESS", data)
        break
      default:
        commit("EDIT_POINT_OF_CONTACT_TYPE_ERROR", data)
    }
    return response
  },
  async deletePointOfContactType({ commit }, params) {
    commit("DELETE_POINT_OF_CONTACT_TYPE_REQUEST")
    const response = await pointOfContactTypesService.deletePointOfContactType(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_POINT_OF_CONTACT_TYPE_SUCCESS", data)
        break
      default:
        commit("DELETE_POINT_OF_CONTACT_TYPE_DELETE", data)
    }
    return response
  }
}

const mutations = {
  [GET_POINT_OF_CONTACT_TYPES_REQUEST]: state => {
    state.loading = true
  },
  [GET_POINT_OF_CONTACT_TYPES_SUCCESS]: (state, data) => {
    let pointOfContactTypes = [...data.point_of_contact_types]
    state.pointOfContactTypes = pointOfContactTypes
    state.loading = false
    state.loadedOnce = true
  },
  [GET_POINT_OF_CONTACT_TYPES_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_POINT_OF_CONTACT_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [GET_POINT_OF_CONTACT_TYPE_SUCCESS]: (state, data) => {
    let mdata = { ...data.data }
    state.pointOfContactType = mdata
    state.loading = false
  },
  [GET_POINT_OF_CONTACT_TYPE_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_POINT_OF_CONTACT_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_POINT_OF_CONTACT_TYPE_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [CREATE_POINT_OF_CONTACT_TYPE_ERROR]: state => {
    state.loading = false
  },

  [EDIT_POINT_OF_CONTACT_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_POINT_OF_CONTACT_TYPE_SUCCESS]: state => {
    state.loading = false
  },
  [EDIT_POINT_OF_CONTACT_TYPE_ERROR]: state => {
    state.loading = false
  },

  [DELETE_POINT_OF_CONTACT_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_POINT_OF_CONTACT_TYPE_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [DELETE_POINT_OF_CONTACT_TYPE_ERROR]: (state, data) => {
    state.loading = false
  }

}

export const pointOfContactType = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
