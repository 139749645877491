import {
    apiRequest,
    processInput
} from "@/utilities"

export const contractPriceTypesService = {
    async getContractPriceTypes() {
        const getContractPriceTypesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/contract-price-types", getContractPriceTypesRequest)
    },
    async createContractPriceType(params) {
        const createData = processInput(params);
        const postContractPriceTypeRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/contract-price-types", postContractPriceTypeRequest)
    },
    async getContractPriceType(uuid) {

        const getContractPriceTypeRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/contract-price-types/${uuid}`, getContractPriceTypeRequest);
    },
    async editContractPriceType(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateContractPriceTypeRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/contract-price-types/${uuid}`, updateContractPriceTypeRequest);
    },
    async deleteContractPriceType(uuid) {

        const deleteContractPriceTypeRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/contract-price-types/${uuid}`, deleteContractPriceTypeRequest);
    }
}