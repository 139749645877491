export const GET_COACH_CERTIFICATIONS_REQUEST = "GET_COACH_CERTIFICATIONS_REQUEST"
export const GET_COACH_CERTIFICATIONS_SUCCESS = "GET_COACH_CERTIFICATIONS_SUCCESS"
export const GET_COACH_CERTIFICATIONS_ERROR = "GET_COACH_CERTIFICATIONS_ERROR"
export const GET_COACH_CERTIFICATION_REQUEST = "GET_COACH_CERTIFICATION_REQUEST"
export const GET_COACH_CERTIFICATION_SUCCESS = "GET_COACH_CERTIFICATION_SUCCESS"
export const GET_COACH_CERTIFICATION_ERROR = "GET_COACH_CERTIFICATION_ERROR"
export const CREATE_COACH_CERTIFICATION_REQUEST = "CREATE_COACH_CERTIFICATION_REQUEST"
export const CREATE_COACH_CERTIFICATION_SUCCESS = "CREATE_COACH_CERTIFICATION_SUCCESS"
export const CREATE_COACH_CERTIFICATION_ERROR = "CREATE_COACH_CERTIFICATION_ERROR"
export const EDIT_COACH_CERTIFICATION_REQUEST = "EDIT_COACH_CERTIFICATION_REQUEST"
export const EDIT_COACH_CERTIFICATION_SUCCESS = "EDIT_COACH_CERTIFICATION_SUCCESS"
export const EDIT_COACH_CERTIFICATION_ERROR = "EDIT_COACH_CERTIFICATION_ERROR"
export const DELETE_COACH_CERTIFICATION_REQUEST = "DELETE_COACH_CERTIFICATION_REQUEST"
export const DELETE_COACH_CERTIFICATION_SUCCESS = "DELETE_COACH_CERTIFICATION_SUCCESS"
export const DELETE_COACH_CERTIFICATION_ERROR = "DELETE_COACH_CERTIFICATION_ERROR"
export const CLEAR_COACH_CERTIFICATION_STATE = "CLEAR_COACH_CERTIFICATION_STATE"