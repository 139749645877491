export const GET_CONTRACTS_REQUEST = "GET_CONTRACTS_REQUEST"
export const GET_CONTRACTS_SUCCESS = "GET_CONTRACTS_SUCCESS"
export const GET_CONTRACTS_ERROR = "GET_CONTRACTS_ERROR"
export const GET_CONTRACT_REQUEST = "GET_CONTRACT_REQUEST"
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS"
export const GET_CONTRACT_ERROR = "GET_CONTRACT_ERROR" 
export const CREATE_CONTRACT_REQUEST = "CREATE_CONTRACT_REQUEST"
export const CREATE_CONTRACT_SUCCESS = "CREATE_CONTRACT_SUCCESS"
export const CREATE_CONTRACT_ERROR = "CREATE_CONTRACT_ERROR"
export const EDIT_CONTRACT_REQUEST = "EDIT_CONTRACT_REQUEST"
export const EDIT_CONTRACT_SUCCESS = "EDIT_CONTRACT_SUCCESS"
export const EDIT_CONTRACT_ERROR = "EDIT_CONTRACT_ERROR"
export const DELETE_CONTRACT_REQUEST = "DELETE_CONTRACT_REQUEST"
export const DELETE_CONTRACT_SUCCESS = "DELETE_CONTRACT_SUCCESS"
export const DELETE_CONTRACT_ERROR = "DELETE_CONTRACT_ERROR"