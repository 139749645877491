import {
    apiRequest,
    processInput
} from "@/utilities"

export const subCourseProgramService = {
    async getSubCoursePrograms() {

        const getSubCourseProgramsRequest = {
            method: "GET"
        }

        return await apiRequest("/api/sub-course-programs", getSubCourseProgramsRequest)
    },
    async createSubCourseProgram(params) {
        const createData = processInput(params)
        const postSubCourseProgramRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/sub-course-programs", postSubCourseProgramRequest)
    },
    async getSubCourseProgram(uuid) {

        const getSubCourseProgramRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/sub-course-programs/${uuid}`, getSubCourseProgramRequest);
    },
    async editSubCourseProgram(data, uuid) {

        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateSubCourseProgramRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/sub-course-programs/${uuid}`, updateSubCourseProgramRequest);
    },
    async deleteSubCourseProgram(uuid) {

        const deleteSubCourseProgramRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/sub-course-programs/${uuid}`, deleteSubCourseProgramRequest);
    },
}