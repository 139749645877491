import {
	GET_WAS_RECONCILIATIONS_REQUEST,
	GET_WAS_RECONCILIATIONS_SUCCESS,
	GET_WAS_RECONCILIATIONS_ERROR,
	GET_WAS_RECONCILIATION_REQUEST,
	GET_WAS_RECONCILIATION_SUCCESS,
	GET_WAS_RECONCILIATION_ERROR,
	CREATE_WAS_RECONCILIATION_REQUEST,
	CREATE_WAS_RECONCILIATION_SUCCESS,
	CREATE_WAS_RECONCILIATION_ERROR,
	EDIT_WAS_RECONCILIATION_REQUEST,
	EDIT_WAS_RECONCILIATION_SUCCESS,
	EDIT_WAS_RECONCILIATION_ERROR,
	DELETE_WAS_RECONCILIATION_REQUEST,
	DELETE_WAS_RECONCILIATION_SUCCESS,
	DELETE_WAS_RECONCILIATION_ERROR
} from "@/actions"

import {
	wasReconciliationService
} from "@/services"
import { store } from "@/store"
const state = {
	wasReconciliations: [],
	wasReconciliation: {},
	loading: false,
	loadedOnce: false
}
import { unpackWAS } from '@/modules'
import { isEmpty, addNewFilter, decodeLaravelValidationErrors } from '@/utilities'

const getters = {
	wasRecFindByWasUuid: state => wasUuid => state.wasReconciliations.find(i => i?.working_assignment_sheets_uuid == wasUuid)
}

const actions = {
	async getWasReconciliations({ commit }, params) {
		commit("GET_WAS_RECONCILIATIONS_REQUEST")
		const response = await wasReconciliationService.getWasReconciliations(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_WAS_RECONCILIATIONS_SUCCESS", data)
				break
			default:
				commit("GET_WAS_RECONCILIATIONS_ERROR", data)
		}
		return response
	},
	async getWasReconciliation({ commit }, params) {
		commit("GET_WAS_RECONCILIATION_REQUEST")
		const response = await wasReconciliationService.getWasReconciliation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_WAS_RECONCILIATION_SUCCESS", data)
				break
			default:
				commit("GET_WAS_RECONCILIATION_ERROR", data)
				break
		}
		return response
	},
	async createWasReconciliation({ commit, dispatch }, params) {
		commit("CREATE_WAS_RECONCILIATION_REQUEST")
		const response = await wasReconciliationService.createWasReconciliation(params);
		const { status, data } = response
		switch (status) {
			case 200:

				// We rely on Channel Broadcasting to for CRUD functions
				data.success ?
					dispatch('alert/createAlertSuccess', "University roster uploaded.", { root: true }) :
					(isEmpty(params.file) ? dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true }) : null)
				break
			default:
				commit("CREATE_WAS_RECONCILIATION_ERROR", data)
		}
		return response
	},
	async editWasReconciliation({ commit, dispatch }, params) {
		commit("EDIT_WAS_RECONCILIATION_REQUEST")
		const uuid = params.uuid
		const response = await wasReconciliationService.editWasReconciliation(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				// We rely on Channel Broadcasting to for CRUD functions

				!data.success ? dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true }) : null
				break
			default:
				commit("EDIT_WAS_RECONCILIATION_ERROR", data)
		}
		return response
	},
	async deleteWasReconciliation({ commit, dispatch }, uuid) {
		commit("DELETE_WAS_RECONCILIATION_REQUEST")
		const response = await wasReconciliationService.deleteWasReconciliation(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				// We rely on Channel Broadcasting to for CRUD functions

				// commit("DELETE_WAS_RECONCILIATION_SUCCESS", data)
				break
			default:
				commit("DELETE_WAS_RECONCILIATION_ERROR", data)
		}
		return response
	}
}

const mutations = {
	[GET_WAS_RECONCILIATIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_WAS_RECONCILIATIONS_SUCCESS]: (state, data) => {
		const wasReconciliations = data.was_reconciliations
		state.wasReconciliations = addNewFilter(state.wasReconciliations, wasReconciliations)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_WAS_RECONCILIATIONS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_WAS_RECONCILIATION_REQUEST]: state => {
		state.loading = true
	},
	[GET_WAS_RECONCILIATION_SUCCESS]: (state, data) => {
		const { was_reconciliation } = data
		state.wasReconciliation = was_reconciliation
		state.wasReconciliations = addNewFilter(state.wasReconciliations, [was_reconciliation], data?.force_update)
		state.loading = false
	},
	[GET_WAS_RECONCILIATION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_WAS_RECONCILIATION_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_WAS_RECONCILIATION_SUCCESS]: (state, data) => {
		const { was_reconciliation } = data

		state.wasReconciliations = addNewFilter(state.wasReconciliations, [was_reconciliation])
		state.loading = false
	},
	[CREATE_WAS_RECONCILIATION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_WAS_RECONCILIATION_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_WAS_RECONCILIATION_SUCCESS]: (state, data) => {

		const { was_reconciliation } = data

		state.wasReconciliations = state.wasReconciliations.map(r => r.uuid == was_reconciliation.uuid ? was_reconciliation : r)

		if (!isEmpty(state.wasReconciliation) && state.wasReconciliation.uuid == was_reconciliation.uuid) {
			state.wasReconciliation = was_reconciliation
		}

		state.loading = false
	},
	[EDIT_WAS_RECONCILIATION_ERROR]: state => {
		state.loading = false
	},

	[DELETE_WAS_RECONCILIATION_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_WAS_RECONCILIATION_SUCCESS]: (state, data) => {
		const { was_reconciliation } = data
		const { reconciliation_key } = was_reconciliation
		state.wasReconciliations = state.wasReconciliations.filter(w => w.uuid != was_reconciliation.uuid)
		if(!isEmpty(reconciliation_key)){
			store.dispatch('reconciliationItem/removeReconciliationItemsByKey', reconciliation_key)
		}
		if (!isEmpty(state.wasReconciliation) && state.wasReconciliation.uuid == was_reconciliation.uuid) {
			state.wasReconciliation = {}
		}
		state.loading = false
	},
	[DELETE_WAS_RECONCILIATION_ERROR]: (state, data) => {
		state.loading = false
	}
}

export function unpackWASReconciliation(wasr, commit) {

	if (!isEmpty(wasr.students)) {
		let students = wasr.students;
    commit("students/ADD_STUDENTS", { students }, { root: true });

    delete wasr.students;
  }

	return wasr
}

export const wasReconciliation = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
