import {
    apiRequest,
    processInput
} from "@/utilities"

export const programCourseLevelService = {
    async getProgramCourseLevels() {

        const getProgramCourseLevelsRequest = {
            method: "GET"
        }

        return await apiRequest("/api/program-course-levels", getProgramCourseLevelsRequest)
    },
    async createProgramCourseLevel(params) {

        const createData = processInput(params);
        const postProgramCourseLevelRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/program-course-levels", postProgramCourseLevelRequest)
    },
    async getProgramCourseLevel(uuid) {

        const getProgramCourseLevelRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/program-course-levels/${uuid}`, getProgramCourseLevelRequest);
    },
    async editProgramCourseLevel(data, uuid) {

        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateProgramCourseLevelRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/program-course-levels/${uuid}`, updateProgramCourseLevelRequest);
    },
    async deleteProgramCourseLevel(uuid) {

        const deleteProgramCourseLevelRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/program-course-levels/${uuid}`, deleteProgramCourseLevelRequest);
    }
}