export const GET_COURSE_START_FACULTYS_REQUEST = "GET_COURSE_START_FACULTYS_REQUEST"
export const GET_COURSE_START_FACULTYS_SUCCESS = "GET_COURSE_START_FACULTYS_SUCCESS"
export const GET_COURSE_START_FACULTYS_ERROR = "GET_COURSE_START_FACULTYS_ERROR"
export const GET_COURSE_START_FACULTY_REQUEST = "GET_COURSE_START_FACULTY_REQUEST"
export const GET_COURSE_START_FACULTY_SUCCESS = "GET_COURSE_START_FACULTY_SUCCESS"
export const GET_COURSE_START_FACULTY_ERROR = "GET_COURSE_START_FACULTY_ERROR" 
export const CREATE_COURSE_START_FACULTY_REQUEST = "CREATE_COURSE_START_FACULTY_REQUEST"
export const CREATE_COURSE_START_FACULTY_SUCCESS = "CREATE_COURSE_START_FACULTY_SUCCESS"
export const CREATE_COURSE_START_FACULTY_ERROR = "CREATE_COURSE_START_FACULTY_ERROR"
export const EDIT_COURSE_START_FACULTY_REQUEST = "EDIT_COURSE_START_FACULTY_REQUEST"
export const EDIT_COURSE_START_FACULTY_SUCCESS = "EDIT_COURSE_START_FACULTY_SUCCESS"
export const EDIT_COURSE_START_FACULTY_ERROR = "EDIT_COURSE_START_FACULTY_ERROR"
export const DELETE_COURSE_START_FACULTY_REQUEST = "DELETE_COURSE_START_FACULTY_REQUEST"
export const DELETE_COURSE_START_FACULTY_SUCCESS = "DELETE_COURSE_START_FACULTY_SUCCESS"
export const DELETE_COURSE_START_FACULTY_ERROR = "DELETE_COURSE_START_FACULTY_ERROR"
export const BROADCAST_DELETE_COURSE_START_FACULTY_SUCCESS = "BROADCAST_DELETE_COURSE_START_FACULTY_SUCCESS"