import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachNurseLicenseService = {
    async getCoachNurseLicenses(params) {
        let getParams = processQueryParams(params)
        const getCoachNurseLicensesRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-nurse-licenses", getCoachNurseLicensesRequest)
    },
    async getCoachNurseLicense(uuid) {
        const getCoachNurseLicenseRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-nurse-licenses/${uuid}`, getCoachNurseLicenseRequest)
    },
    async createCoachNurseLicense(params) {
        const createData = processInput(params)
        const createCoachNurseLicenseRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-nurse-licenses", createCoachNurseLicenseRequest)
    },
    async editCoachNurseLicense(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachNurseLicenseRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-nurse-licenses/${uuid}`, editCoachNurseLicenseRequest)
    },
    async deleteCoachNurseLicense(uuid) {
        const deleteCoachNurseLicenseRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-nurse-licenses/${uuid}`, deleteCoachNurseLicenseRequest)
    }
}