import {
    GET_COACH_CERTIFICATIONS_REQUEST,
    GET_COACH_CERTIFICATIONS_SUCCESS,
    GET_COACH_CERTIFICATIONS_ERROR,
    GET_COACH_CERTIFICATION_REQUEST,
    GET_COACH_CERTIFICATION_SUCCESS,
    GET_COACH_CERTIFICATION_ERROR,
    CREATE_COACH_CERTIFICATION_REQUEST,
    CREATE_COACH_CERTIFICATION_SUCCESS,
    CREATE_COACH_CERTIFICATION_ERROR,
    EDIT_COACH_CERTIFICATION_REQUEST,
    EDIT_COACH_CERTIFICATION_SUCCESS,
    EDIT_COACH_CERTIFICATION_ERROR,
    DELETE_COACH_CERTIFICATION_REQUEST,
    DELETE_COACH_CERTIFICATION_SUCCESS,
    DELETE_COACH_CERTIFICATION_ERROR,
    CLEAR_COACH_CERTIFICATION_STATE
} from "@/actions"

import {
    coachCertificationService
} from "@/services"
import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    coachCertifications: [],
    coachCertification: {},
    loading: false,
    loadedOnce: false
}

const getters = {
    ccLoadedOnce: state => state.loadedOnce,
    coachCertifications: (state, getters, rootState, rootGetters) => {
        return state.coachCertifications.filter(cs => cs.coaches_uuid == rootGetters['coaches/coach']['uuid']).map(i => {
            let certification = rootGetters['certification/certificationByUuid'](i.certifications_uuid)
            return {
                ...i,
                certification
            }
        })
    },
    coachCertificationsUuidArray: state => {
        return state.coachCertifications.map(cCert => cCert.certifications_uuid)
    }
}

const actions = {
    async getCoachCertifications({ commit }, params) {
        commit("GET_COACH_CERTIFICATIONS_REQUEST")
        const response = await coachCertificationService.getCoachCertifications(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_CERTIFICATIONS_SUCCESS", data)
                break
            default:
                commit("GET_COACH_CERTIFICATIONS_ERROR", data)
        }
        return response
    },
    async getCoachCertification({ commit }, params) {
        commit("GET_COACH_CERTIFICATION_REQUEST")
        const response = await coachCertificationService.getCoachCertification(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_CERTIFICATION_SUCCESS", data)
                break
            default:
                commit("GET_COACH_CERTIFICATION_ERROR", data)
                break
        }
        return response
    },
    async createCoachCertification({ commit }, params) {
        commit("CREATE_COACH_CERTIFICATION_REQUEST")
        const response = await coachCertificationService.createCoachCertification(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COACH_CERTIFICATION_SUCCESS", data)
                break
            default:
                commit("CREATE_COACH_CERTIFICATION_ERROR", data)
        }
        return response
    },
    async editCoachCertification({ commit }, params) {
        commit("EDIT_COACH_CERTIFICATION_REQUEST")
        const uuid = params.uuid
        const response = await coachCertificationService.editCoachCertification(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COACH_CERTIFICATION_SUCCESS", data)
                break
            default:
                commit("EDIT_COACH_CERTIFICATION_ERROR", data)
        }
        return response
    },
    async deleteCoachCertification({ commit }, uuid) {
        commit("DELETE_COACH_CERTIFICATION_REQUEST")
        const response = await coachCertificationService.deleteCoachCertification(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("DELETE_COACH_CERTIFICATION_SUCCESS", uuid) : commit("DELETE_COACH_CERTIFICATION_ERROR", data)
                break
            default:
                commit("DELETE_COACH_CERTIFICATION_ERROR", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_CERTIFICATIONS_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_CERTIFICATIONS_SUCCESS]: (state, data) => {
        const coachCertifications = data.coach_certifications
        state.coachCertifications = addNewFilter(state.coachCertifications, coachCertifications)
        state.loading = false
        state.loadedOnce = true
    },
    [GET_COACH_CERTIFICATIONS_ERROR]: state => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [GET_COACH_CERTIFICATION_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_CERTIFICATION_SUCCESS]: (state, data) => {
        const coachCertification = { ...data.coach_certification }
        state.coachCertifications = addNewFilter(state.coachCertifications, [coachCertification])
        state.coachCertification = coachCertification
        state.loading = false
    },
    [GET_COACH_CERTIFICATION_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_CERTIFICATION_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_CERTIFICATION_SUCCESS]: (state, data) => {
        let coachCertification = { ...data.coach_certification }
        state.coachCertifications = addNewFilter(state.coachCertifications, [coachCertification])
        state.loading = false
    },
    [CREATE_COACH_CERTIFICATION_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_CERTIFICATION_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_CERTIFICATION_SUCCESS]: (state,data) => {
        let coachCertification = { ...data.coach_certification }
        state.coachCertifications = state.coachCertifications.filter(cCert => cCert.uuid !== coachCertification.uuid)
        state.coachCertifications = addNewFilter(state.coachCertifications, [coachCertification])
        state.loading = false
    },
    [EDIT_COACH_CERTIFICATION_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_CERTIFICATION_REQUEST]: state => {
        state.loading = true
    },
    BROADCAST_DELETE_COACH_CERTIFICATION_SUCCESS: (state, uuid) => {
        if(typeof uuid == 'string'){
            state.coachCertifications = state.coachCertifications.filter(cCert => cCert.uuid !== uuid)
        }else{
            state.coachCertifications = state.coachCertifications.filter(cCert => cCert.uuid !== uuid?.coach_certification.uuid)
        }
        state.loading = false
    },
    [DELETE_COACH_CERTIFICATION_SUCCESS]: (state, uuid) => {
        if(typeof uuid == 'string'){
            state.coachCertifications = state.coachCertifications.filter(cCert => cCert.uuid !== uuid)
        }else{
            state.coachCertifications = state.coachCertifications.filter(cCert => cCert.uuid !== uuid.uuid)
        }
        state.loading = false
    },
    [DELETE_COACH_CERTIFICATION_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COACH_CERTIFICATION_STATE]: (state) => {
        state.coachCertifications = [],
        state.coachCertification = { },
        state.loading = false,
        state.loadedOnce = false
    }
} 

export const coachCertifications = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}