import {
    apiRequest,
    processInput,
    processQueryParams
  } from "@/utilities"
  
  const moduleUrl = "lead-coach-evaluations";

  export const leadEvaluationService = {
    async getLeadEvaluations(params) {
  
      const getLeadEvaluationsRequest = {
        method: "GET",
        params: processQueryParams(params)
      }
  
      return await apiRequest(`/api/${moduleUrl}`, getLeadEvaluationsRequest)
    },
    async createLeadEvaluation(params) {
  
      const createData = processInput(params)
      const postLeadEvaluationRequest = {
        method: "POST",
        data: createData
      }
      return await apiRequest(`/api/${moduleUrl}`, postLeadEvaluationRequest)
    },
    async getLeadEvaluation(uuid) {
  
      const getLeadEvaluationRequest = {
        method: "GET"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, getLeadEvaluationRequest);
    },
    async editLeadEvaluation(data, uuid) {
  
      let editData = processInput(data)
      editData.append("_method", "PATCH")
  
      const updateLeadEvaluationRequest = {
        method: "POST",
        data: editData
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, updateLeadEvaluationRequest);
    },
    async deleteLeadEvaluation(uuid) {
  
      const deleteLeadEvaluationRequest = {
        method: "DELETE"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, deleteLeadEvaluationRequest);
    }
  }
  