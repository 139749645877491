import {
	GET_COACH_SPECIALTIES_REQUEST,
	GET_COACH_SPECIALTIES_SUCCESS,
	GET_COACH_SPECIALTIES_ERROR,
	GET_COACH_SPECIALTY_REQUEST,
	GET_COACH_SPECIALTY_SUCCESS,
	GET_COACH_SPECIALTY_ERROR,
	CREATE_COACH_SPECIALTY_REQUEST,
	CREATE_COACH_SPECIALTY_SUCCESS,
	CREATE_COACH_SPECIALTY_ERROR,
	EDIT_COACH_SPECIALTY_REQUEST,
	EDIT_COACH_SPECIALTY_SUCCESS,
	EDIT_COACH_SPECIALTY_ERROR,
	DELETE_COACH_SPECIALTY_REQUEST,
	DELETE_COACH_SPECIALTY_SUCCESS,
	DELETE_COACH_SPECIALTY_ERROR,
	CLEAR_COACH_SPECIALTY_STATE
} from "@/actions"

import {
	coachSpecialtyService
} from "@/services"

const state = {
	coachSpecialties: [],
	coachSpecialty: {},
	loading: false,
	loadedOnce: false
}

import { addNewFilter } from "@/utilities";

import _, { map } from 'underscore'

const getters = {
	csLoadedOnce: state => state.loadedOnce,
	coachSpecialties: (state, getters, rootState, rootGetters) => state.coachSpecialties.filter(cs => cs.coaches_uuid == rootGetters['coaches/coach']['uuid']),
	getCoachSpecialtyBySpecialtyUuid: (state, getters) => (specialtyUuid) => getters.coachSpecialties.find(cs => cs.specialties_uuid == specialtyUuid),
	coachSpecialtiesUuidArray: state => {
		return state.coachSpecialties.map((coachSpecialty) => {
			return coachSpecialty.specialties_uuid
		})
	},
	coachSpecialtiesKeyedForDelete: state => {
		return state.coachSpecialties.map((coachSpecialty) => {
			return { coachSpecialtiesUuid: coachSpecialty.uuid, specialtiesUuid: coachSpecialty.specialty.uuid }
		})
	},

	coachSpecialtiesByCoachUuid: state => cUuid => state.coachSpecialties.filter(cs => cs.coaches_uuid == cUuid)
}

const actions = {
	async getCoachSpecialties({ commit }, params) {
		commit("GET_COACH_SPECIALTIES_REQUEST")
		const response = await coachSpecialtyService.getCoachSpecialties(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COACH_SPECIALTIES_SUCCESS", data)
				break
			default:
				commit("GET_COACH_SPECIALTIES_ERROR", data)
		}
		return response
	},
	async getCoachSpecialty({ commit }, params) {
		commit("GET_COACH_SPECIALTY_REQUEST")
		const response = await coachSpecialtyService.getCoachSpecialty(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COACH_SPECIALTY_SUCCESS", data)
				break
			default:
				commit("GET_COACH_SPECIALTY_ERROR", data)
				break
		}
		return response
	},
	async createCoachSpecialty({ commit }, params) {
		commit("CREATE_COACH_SPECIALTY_REQUEST")
		const response = await coachSpecialtyService.createCoachSpecialty(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_COACH_SPECIALTY_SUCCESS", data)
				break
			default:
				commit("CREATE_COACH_SPECIALTY_ERROR", data)
		}
		return response
	},
	async editCoachSpecialty({ commit }, params, uuid) {
		commit("EDIT_COACH_SPECIALTY_REQUEST")
		const response = await coachSpecialtyService.editCoachSpecialty(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_COACH_SPECIALTY_SUCCESS", data)
				break
			default:
				commit("EDIT_COACH_SPECIALTY_ERROR", data)
		}
		return response
	},
	async deleteCoachSpecialty({ commit }, uuid) {
		commit("DELETE_COACH_SPECIALTY_REQUEST")
		const response = await coachSpecialtyService.deleteCoachSpecialty(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_COACH_SPECIALTY_SUCCESS", uuid)
				break
			default:
				commit("DELETE_COACH_SPECIALTY_ERROR", data)
		}
		return response
	},
	// async processClickedSpecialty({ commit }, params) {
	//     let hasSpecialty = state.coachSpecialties.filter((object, index) => {
	//         return object.specialty.uuid == params.specialtiesUuid
	//     })

	//     if (hasSpecialty.length !== 0) {
	//         commit("DELETE_COACH_SPECIALTY_REQUEST")
	//         let response = await coachSpecialtyService.deleteCoachSpecialty(hasSpecialty[0].uuid)
	//         let { status, data } = response
	//         switch (status) {
	//             case 200:
	//                 commit("DELETE_COACH_SPECIALTY_SUCCESS", data)
	//                 break
	//             default:
	//                 commit("DELETE_COACH_SPECIALTY_ERROR", data)
	//         }
	//         return response
	//     }

	//     // commit("CREATE_COACH_SPECIALTY_REQUEST")
	//     // let response = await coachSpecialtyService.createCoachSpecialty(params)
	//     // let { status, data } = response
	//     // switch (status) {
	//     //     case 200:
	//     //         commit("CREATE_COACH_SPECIALTY_SUCCESS", data)
	//     //         break
	//     //     default:
	//     //         commit("CREATE_COACH_SPECIALTY_ERROR", data)
	//     // }
	//     // return response
	// }
}

const mutations = {
	[GET_COACH_SPECIALTIES_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_SPECIALTIES_SUCCESS]: (state, data) => {
		let coachSpecialties = data.coach_specialties
		state.coachSpecialties = addNewFilter(state.coachSpecialties, coachSpecialties)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_COACH_SPECIALTIES_ERROR]: state => {
		state.loading = false
		state.error = true,
			state.loadedOnce = true
	},
	[GET_COACH_SPECIALTY_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_SPECIALTY_SUCCESS]: (state, data) => {
		const coachSpecialty = { ...data.coach_specialty }
		state.coachSpecialty = coachSpecialty
		state.loading = false
	},
	[GET_COACH_SPECIALTY_ERROR]: (state, data) => {
		state.loading = false
	},
	[CREATE_COACH_SPECIALTY_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COACH_SPECIALTY_SUCCESS]: (state, data) => {
		const { coach_specialty } = data
		state.coachSpecialties = addNewFilter(state.coachSpecialties, [coach_specialty])
		state.loading = false
	},
	[CREATE_COACH_SPECIALTY_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COACH_SPECIALTY_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COACH_SPECIALTY_SUCCESS]: state => {
		state.loading = false
	},
	[EDIT_COACH_SPECIALTY_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COACH_SPECIALTY_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COACH_SPECIALTY_SUCCESS]: (state, uuid) => {
		if(typeof uuid != 'string'){
			const {coach_specialty} = uuid
			uuid = coach_specialty.uuid
		}
		state.coachSpecialties = state.coachSpecialties.filter(spec => spec.uuid !== uuid)
		state.loading = false
	},
	[DELETE_COACH_SPECIALTY_ERROR]: (state, data) => {
		state.loading = false
	},

	[CLEAR_COACH_SPECIALTY_STATE]: (state) => {
		state.coachSpecialties = []
		state.coachSpecialty = {}
		state.loading = false
		state.loadedOnce = false
	}
}

export const coachSpecialties = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}