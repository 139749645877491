export const GET_COACH_FACILITATING_COURSES_REQUEST = "GET_COACH_FACILITATING_COURSES_REQUEST"
export const GET_COACH_FACILITATING_COURSES_SUCCESS = "GET_COACH_FACILITATING_COURSES_SUCCESS"
export const GET_COACH_FACILITATING_COURSES_ERROR = "GET_COACH_FACILITATING_COURSES_ERROR"
export const GET_COACH_FACILITATING_COURSE_REQUEST = "GET_COACH_FACILITATING_COURSE_REQUEST"
export const GET_COACH_FACILITATING_COURSE_SUCCESS = "GET_COACH_FACILITATING_COURSE_SUCCESS"
export const GET_COACH_FACILITATING_COURSE_ERROR = "GET_COACH_FACILITATING_COURSE_ERROR"
export const CREATE_COACH_FACILITATING_COURSE_REQUEST = "CREATE_COACH_FACILITATING_COURSE_REQUEST"
export const CREATE_COACH_FACILITATING_COURSE_SUCCESS = "CREATE_COACH_FACILITATING_COURSE_SUCCESS"
export const CREATE_COACH_FACILITATING_COURSE_ERROR = "CREATE_COACH_FACILITATING_COURSE_ERROR"
export const EDIT_COACH_FACILITATING_COURSE_REQUEST = "EDIT_COACH_FACILITATING_COURSE_REQUEST"
export const EDIT_COACH_FACILITATING_COURSE_SUCCESS = "EDIT_COACH_FACILITATING_COURSE_SUCCESS"
export const EDIT_COACH_FACILITATING_COURSE_ERROR = "EDIT_COACH_FACILITATING_COURSE_ERROR"
export const DELETE_COACH_FACILITATING_COURSE_REQUEST = "DELETE_COACH_FACILITATING_COURSE_REQUEST"
export const DELETE_COACH_FACILITATING_COURSE_SUCCESS = "DELETE_COACH_FACILITATING_COURSE_SUCCESS"
export const BROADCAST_DELETE_COACH_FACILITATING_COURSE_SUCCESS = "BROADCAST_DELETE_COACH_FACILITATING_COURSE_SUCCESS"
export const DELETE_COACH_FACILITATING_COURSE_ERROR = "DELETE_COACH_FACILITATING_COURSE_ERROR"
export const CLEAR_COACH_FACILITATING_COURSE_STATE = "CLEAR_COACH_FACILITATING_COURSE_STATE"