import {
    GET_DOCUMENT_TYPES_REQUEST,
    GET_DOCUMENT_TYPES_SUCCESS,
    GET_DOCUMENT_TYPES_ERROR,
    GET_DOCUMENT_TYPE_REQUEST,
    GET_DOCUMENT_TYPE_SUCCESS,
    GET_DOCUMENT_TYPE_ERROR,
    CREATE_DOCUMENT_TYPE_REQUEST,
    CREATE_DOCUMENT_TYPE_SUCCESS,
    CREATE_DOCUMENT_TYPE_ERROR,
    EDIT_DOCUMENT_TYPE_REQUEST,
    EDIT_DOCUMENT_TYPE_SUCCESS,
    EDIT_DOCUMENT_TYPE_ERROR,
    DELETE_DOCUMENT_TYPE_REQUEST,
    DELETE_DOCUMENT_TYPE_SUCCESS,
    DELETE_DOCUMENT_TYPE_ERROR
} from "@/actions"

import {
    documentTypeService
} from "@/services"

const state = {
    documentTypes: [],
    documentType: {},
    loading: false,
    loadedOnce: false,
}

const getters = {
    documentTypes: state => state.documentTypes.sort((a, b) => a.model.localeCompare(b.model)),

    docTypeLoading: state => state.loading,
    docTypeHasLoaded: state => state.loadedOnce,
    docTypeSelectOptions: state => {
        return state.documentTypes.map((model) => {
            return { label: model.label, value: model.uuid }
        })
    },
    getDocTypeBySlug: state => slug => state.documentTypes.find(docType => docType.slug == slug),
    getDocTypeByType: state => typeString => state.documentTypes.filter(dt => dt.type == typeString).map(docType => {
        return {
            ...docType,
            value: docType.uuid
        }
    }),
    getDocTypeByTypeArray: state => typeString => state.documentTypes.filter(dt => typeString.some(dtname => dtname == dt.type)).map(docType => {
        return {
            ...docType,
            value: docType.uuid
        }
    }),
    getDocTypeBySlugArray: state => slugArray => {
        let filtered = state.documentTypes.filter(docType => slugArray.includes(docType.slug))
        let mapped = filtered.map(docType => {
            return { value: docType.uuid, label: docType.label }
        })
        return [{
            value: "", label: "Select document type"
        }].concat(mapped)
    },
    docTypeOptionsByType: state => type => state.documentTypes.filter(docType => docType.type == type).map(docType => {
        return { value: docType.uuid, label: docType.label }
    }),
    getDocTypeByModel: state => model => state.documentTypes.filter(docType => docType.model == model).map(docType => {
        return {
            ...docType,
            value: docType.uuid
        }
    }),
    getDocTypeByModelArray: state => modelArray => {
        let filtered = state.documentTypes.filter(docType => modelArray.includes(docType.model))
        let mapped = filtered.map(docType => {
            return { 
                value: docType.uuid, 
                label: docType.label,
                model: docType.model,
                slug: docType.slug,
            }
        })
        return [{
            value: "", 
            label: "Select document type",
            model: "", 
            slug: "", 
        }].concat(mapped)
    },
    getDocTypeByModelNotInArray: state => modelArray => {
        let filtered = state.documentTypes.filter(docType => !modelArray.includes(docType.model))
        let mapped = filtered.map(docType => {
            return { value: docType.uuid, label: docType.label, model: docType.model }
        })
        return [{
            value: "", label: "Select document type"
        }].concat(mapped)
    },
    docTypeByUuid: state => uuid => state.documentTypes.find(docType => docType.uuid == uuid),

    payrollDocTypes: state => state.documentTypes.filter(dt => dt.model == 'App\\Models\\Payroll' || dt.type == 'payroll')
}

const actions = {
    async getDocumentTypes({ commit, state }) {
        if (state.loadedOnce) return false
        commit("GET_DOCUMENT_TYPES_REQUEST")
        const response = await documentTypeService.getDocumentTypes()
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("GET_DOCUMENT_TYPES_SUCCESS", data) : commit("GET_DOCUMENT_TYPES_ERROR", data)
                break
            default:
                commit("GET_DOCUMENT_TYPES_ERROR", data)
        }
        return response
    },
    async getDocumentType({ commit }, params) {
        commit("GET_DOCUMENT_TYPE_REQUEST")
        const response = await documentTypeService.getDocumentType(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_DOCUMENT_TYPE_SUCCESS", data)
                break
            default:
                commit("GET_DOCUMENT_TYPE_ERROR", data)
                break
        }
        return response
    },
    async createDocumentType({ commit }, params) {
        commit("CREATE_DOCUMENT_TYPE_REQUEST")
        const response = await documentTypeService.createDocumentType(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_DOCUMENT_TYPE_SUCCESS", data)
                break
            default:
                commit("CREAT_DOCUMENT_TYPE_ERROR");
        }
        return response
    },
    async editDocumentType({ commit }, params) {
        commit("EDIT_DOCUMENT_TYPE_REQUEST")
        const response = await documentTypeService.editDocumentType(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_DOCUMENT_TYPE_SUCCESS", data)
                break
            default:
                commit("EDIT_DOCUMENT_TYPE_ERROR", data)
        }
        return response
    },
    async deleteDocumentType({ commit }, params) {
        commit("DELETE_DOCUMENT_TYPE_REQUEST")
        const response = await documentTypeService.deleteDocumentType(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_DOCUMENT_TYPE_SUCCESS", data)
                break
            default:
                commit("DELETE_DOCUMENT_TYPE_DELETE", data)
        }
        return response
    }
}

const mutations = {

    [GET_DOCUMENT_TYPES_REQUEST]: state => {
        state.loading = true
    },
    [GET_DOCUMENT_TYPES_SUCCESS]: (state, data) => {
        const documentTypes = [...data.document_types]
        state.documentTypes = documentTypes
        state.loading = false
        state.loadedOnce = true
    },
    [GET_DOCUMENT_TYPES_ERROR]: state => {
        state.loading = false
        state.error = true
    },

    [GET_DOCUMENT_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [GET_DOCUMENT_TYPE_SUCCESS]: (state, data) => {
        const documentType = { ...data.document_type }
        state.documentType = documentType
        state.loading = false
    },
    [GET_DOCUMENT_TYPE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_DOCUMENT_TYPE_REQUEST]: state => {
        state.loading = true        
    },
    [CREATE_DOCUMENT_TYPE_SUCCESS]: state => {
        state.loading = false
    },
    [CREATE_DOCUMENT_TYPE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_DOCUMENT_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_DOCUMENT_TYPE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_DOCUMENT_TYPE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_DOCUMENT_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_DOCUMENT_TYPE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_DOCUMENT_TYPE_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const documentType = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}