import {
	GET_COURSE_STARTS_REQUEST,
	GET_COURSE_STARTS_SUCCESS,
	GET_COURSE_STARTS_ERROR,
	GET_COURSE_START_REQUEST,
	GET_COURSE_START_SUCCESS,
	GET_COURSE_START_ERROR,
	CREATE_COURSE_START_REQUEST,
	CREATE_COURSE_START_SUCCESS,
	CREATE_COURSE_START_ERROR,
	EDIT_COURSE_START_REQUEST,
	EDIT_COURSE_START_SUCCESS,
	EDIT_COURSE_START_ERROR,
	SEND_FACULTY_CAOCH_EMAIL_REQUEST,
	SEND_FACULTY_CAOCH_EMAIL_SUCCESS,
	SEND_FACULTY_CAOCH_EMAIL_ERROR,
	DELETE_COURSE_START_REQUEST,
	DELETE_COURSE_START_SUCCESS,
	DELETE_COURSE_START_ERROR,
	SOFT_DELETE_COURSE_START_SUCCESS,
	UPLOAD_COURSE_START_REQUEST,
	UPLOAD_COURSE_START_SUCCESS,
	UPLOAD_COURSE_START_ERROR,
	SEND_RESUME_REQUEST,
	SEND_RESUME_SUCCESS,
	SEND_RESUME_ERROR,
	SEND_ASSIGNMENT_LETTER_REQUEST,
	SEND_ASSIGNMENT_LETTER_SUCCESS,
	SEND_ASSIGNMENT_LETTER_ERROR,
} from "@/actions"

import {
	courseStartService
} from "@/services"
import { parseISO, isAfter } from 'date-fns'
import { addNewFilter, isEmpty, decodeLaravelValidationErrors, strToLower, } from "@/utilities"

import { unpackCoachAssignment, unpackCoachFacultyReferral, unpackCourseStartFaculty, unpackCourse, unpackCourseStartReconciliation, unpackCoach, unpackFacultyEvaluation } from "@/modules"
import { store } from "@/store"

const state = {
	courseStarts: [],
	courseStart: {},
	loading: false,
	loadedOnce: false,
	loadedCs: []
}

const getters = {
	courseStartsLoading: state => state.loading,

	courseStartsLoadedOnce: state => state.loadedOnce,

	csStatuses: (state, getters, rootState, rootGetters) => {
		return rootState['workingAssignmentSheet'].wasStatuses
	},

	courseStarts: state => state.courseStarts.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),

	csBycUuidFilter: state => cUuid => state.courseStarts.filter(cs => {
		return cs.courses_uuid == cUuid
	}),

	courseStart: state => state.courseStart,

	csBycUuidArrayFilter: state => cUuid => state.courseStarts.filter(cs => {
		return cUuid.some(c => c.uuid == cs.courses_uuid)
	}),

	csByWAS: (state, getters, rootState, rootGetters) => (cUuids, was) => {
		let css = state.courseStarts.filter(cs => cUuids.includes(cs.courses_uuid) && cs.start_date == was.start_date && cs.course_length == was.course_length)
		return css.map(cs => {
			let courseFindUuid = rootGetters['course/courseFindUuid']
			let course = courseFindUuid(cs.courses_uuid)
			return {
				...cs,
				course
			}
		}).sort((f, s) => f.course.number.localeCompare(s.course.number))
	},

	myFutureCourseStarts: (state, getters, rootState, rootGetters) => {
		return getters.myCourseStarts.filter(cs => {
			let startDateParsed = parseISO(cs.start_date)
			return isAfter(startDateParsed, new Date()) && isEmpty(cs.deleted_at)
		})
	},

	myCourseStarts: (state, getters, rootState, rootGetters) => {
		let myPrograms = rootGetters['universityProgram/myPrograms']
		let courseFilter = rootGetters['course/courseFilterArray']
		let coursesUuid = courseFilter(myPrograms.map(up => up.uuid))
		let css = getters.csBycUuidArrayFilter(coursesUuid)
		const courseStartSectionsGetter = rootGetters['courseStartSection/courseStartSections']
		css = css.map(i => {
			const sections = courseStartSectionsGetter(i.uuid)
			return {
				...i,
				sections
			}
		})
		return css
	},

	csFullFilter: state => (cUuid, startDate, courseLength) => {
		let cs = state.courseStarts.find(cs => cs.courses_uuid == cUuid && cs.start_date == startDate && cs.course_length == courseLength)
		if (!isEmpty(cs)) return cs;
		cs = state.courseStarts.find(cs => cs.cross_listings.some(c => c.courses_uuid == cUuid) && cs.start_date == startDate && cs.course_length == courseLength)
		if (!isEmpty(cs)) return cs;
		return null;
	},

	csByUuid: state => uuid => state.courseStarts.find(i => i.uuid == uuid),
}

const actions = {
	async getCourseStarts(ctx, params) {
		let { uuids } = params
		let fully_loaded = false
		if (!isEmpty(uuids)) {
			uuids = uuids.filter(uuid => !ctx.state.loadedCs.some(i => i == uuid))
			if (isEmpty(uuids)) return false;
			params.uuids = uuids
			fully_loaded = true
		}
		ctx.commit("GET_COURSE_STARTS_REQUEST")
		const response = await courseStartService.getCourseStarts(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if (data?.success) {
					const course_starts = data.course_starts.map(cs => unpackCourseStart(cs, ctx.commit))
					ctx.commit("GET_COURSE_STARTS_SUCCESS", { course_starts, fully_loaded })
				}
				break
			default:
				ctx.commit("GET_COURSE_STARTS_ERROR", data)
		}
		return response
	},
	async getCourseStart(ctx, params) {
		const { uuid } = params
		if (ctx.state.loadedCs.some(i => i == uuid)) {
			return false;
		}
		ctx.commit("GET_COURSE_START_REQUEST")
		const response = await courseStartService.getCourseStart(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if(data?.success){
					const course_start = unpackCourseStart(data.course_start, ctx.commit)
					ctx.commit("GET_COURSE_START_SUCCESS", {course_start})
				}
				break
			default:
				ctx.commit("GET_COURSE_START_ERROR", data)
				break
		}
		return response
	},
	async createCourseStart({ commit, dispatch }, params) {
		commit("CREATE_COURSE_START_REQUEST")
		const response = await courseStartService.createCourseStart(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_COURSE_START_SUCCESS", data) : commit("CREATE_COURSE_START_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "CourseStart uploaded!", { root: true }) : dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
				break
			default:
				commit("CREATE_COURSE_START_ERROR", data)
		}
		return response
	},
	async editCourseStart({ commit }, params) {
		commit("EDIT_COURSE_START_REQUEST")
		const response = await courseStartService.editCourseStart(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_COURSE_START_SUCCESS", data) : null
				break
			default:
				commit("EDIT_COURSE_START_ERROR", data)
		}
		return response
	},
	async deleteCourseStart({ commit, dispatch }, params) {
		commit("DELETE_COURSE_START_REQUEST")
		const response = await courseStartService.deleteCourseStart(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					//If the course_start is returned then we assume it was a soft-delete
					// If full delete only a success || error boolean is returned.
					if (!isEmpty(data.course_start)) {
						dispatch('alert/createAlertSuccess', "CourseStart deleted!", { root: true })
						commit("SOFT_DELETE_COURSE_START_SUCCESS", data)
					} else {
						dispatch('alert/createAlertSuccess', "CourseStart deleted!", { root: true })
						commit("DELETE_COURSE_START_SUCCESS", params.uuid)
					}
				} else {
					commit("DELETE_COURSE_START_ERROR", data)
					dispatch('alert/createAlertErrors', data.errors, { root: true })
				}

				// data.success ? dispatch('alert/createAlertSuccess', "CourseStart deleted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("DELETE_COURSE_START_ERROR", data)
		}
		return response
	},
	async uploadCourseStart({ commit, dispatch }, params) {
		commit("UPLOAD_COURSE_START_REQUEST")
		const response = await courseStartService.uploadCourseStart(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("UPLOAD_COURSE_START_SUCCESS", data) : commit("UPLOAD_COURSE_START_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "CourseStarts created!", { root: true }) : dispatch('alert/createAlertErrors', "something went wrong check errors", { root: true })
				break
			default:
				commit("UPLOAD_COURSE_START_ERROR", data)
		}
		return response
	},
	async sendFacultyCoachEmail({ commit }, uuid) {
		commit("SEND_FACULTY_CAOCH_EMAIL_REQUEST")
		const response = await courseStartService.sendFacultyCoachEmail(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("SEND_FACULTY_CAOCH_EMAIL_SUCCESS", data) : null
				break
			default:
				commit("SEND_FACULTY_CAOCH_EMAIL_ERROR", data)
		}
		return response
	},
	async sendResumes({ commit, dispatch }, params) {
		commit("SEND_RESUME_REQUEST")
		const response = await courseStartService.sendResumes(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					commit("SEND_RESUME_SUCCESS", data)
					// commit('coachAssignment/GET_COACH_ASSIGNMENTS_SUCCESS', data, { root: true })
					if (!isEmpty(data.eligible_coaches_names)) {
						dispatch('alert/createAlertSuccess', "Resume sent!", { root: true })
					}
					if (!isEmpty(data.not_eligible_coaches_names)) {
						dispatch('alert/createAlertErrors', `There is no resume on file for coach(es) ${data.not_eligible_coaches_names.join(', ')}. Please upload and then try sending again.`, { root: true })
					}
				} else {
					if (!isEmpty(data.errors)) {
						dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
					}
					commit("SEND_RESUME_ERROR", data)
				}
				break
			default:
				commit("SEND_RESUME_ERROR", uuid)
		}
		return response
	},
	async sendAssignmentLetter({ commit, dispatch }, params) {
		commit("SEND_ASSIGNMENT_LETTER_REQUEST")
		const response = await courseStartService.sendAssignmentLetter(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					commit("SEND_ASSIGNMENT_LETTER_SUCCESS", data)
					dispatch('alert/createAlertSuccess', data.message, { root: true })
				} else {
					commit("SEND_ASSIGNMENT_LETTER_ERROR", data)
				}
				break
			default:
				commit("SEND_ASSIGNMENT_LETTER_ERROR", uuid)
		}
		return response
	},
	async removeStudentsFromRoster({ commit, dispatch }, params) {
		const response = await courseStartService.removeStudentsFromRoster(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? dispatch("alert/createAlertSuccess", "Student(s) removed.", { root: true }) : null
				data.success ? commit("CREATE_COURSE_START_STUDENT_REMOVE_SUCCESS", data) : null
				data.error ? dispatch('alert/createAlertErrors', data.errors, { root: true }) : null
				break
			default:
				data.error ? dispatch('alert/createAlertErrors', "Something went wrong!", { root: true }) : null
		}
		return response
	},
	async sendFacultyEvaluationLink({ commit, dispatch }, params) {
		const response = await courseStartService.sendFacultyEvaluationLink(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? dispatch("alert/createAlertSuccess", "Evaluation links sent to faculty.", { root: true }) : null
				data.errors ? dispatch('alert/createAlertErrors', data.errors, { root: true }) : null
				break
			default:
				dispatch('alert/createAlertErrors', "Something went wrong!", { root: true });
				if (!isEmpty(data.errors)) {
					dispatch('alert/createAlertErrors', data.errors, { root: true }) 
				}
				// data.error ? dispatch('alert/createAlertErrors', "Something went wrong!", { root: true }) : null
		}
		return response
	},
	async clearCourseStart({ commit }) {
		commit("CLEAR_COURSE_START")
	},
	async removeLoadedData({ commit }, uuid) {
		commit('removeLoadedData', uuid)
	}
}

const mutations = {
	[GET_COURSE_STARTS_REQUEST]: state => {
		state.loading = true
	},
	[GET_COURSE_STARTS_SUCCESS]: (state, data) => {
		const courseStarts = data.course_starts
		state.courseStarts = addNewFilter(state.courseStarts, courseStarts)
		state.loading = false
		if (data?.fully_loaded) {
			courseStarts.forEach(course_start => {
				if (!state.loadedCs.some(i => i == course_start.uuid)) {
					state.loadedCs.push(course_start.uuid)
				}
			})
		}
		state.loadedOnce = true
	},
	[GET_COURSE_STARTS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_COURSE_START_REQUEST]: state => {
		state.loading = true
	},
	[GET_COURSE_START_SUCCESS]: (state, data) => {
		state.courseStarts = addNewFilter(state.courseStarts, [data.course_start])
		const course_start = { ...data.course_start }
		state.courseStart = course_start
		if (!state.loadedCs.some(i => i == course_start.uuid)) {
			state.loadedCs.push(course_start.uuid)
		}
		state.loading = false
	},
	[GET_COURSE_START_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COURSE_START_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COURSE_START_SUCCESS]: (state, data) => {
		state.courseStarts = addNewFilter(state.courseStarts, [data.course_start])
		state.loading = false
	},
	[CREATE_COURSE_START_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COURSE_START_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COURSE_START_SUCCESS]: (state, data) => {
		const { course_start } = data
		state.courseStarts = addNewFilter(state.courseStarts, [course_start])
		state.courseStarts = state.courseStarts.map(cs => cs.uuid == course_start.uuid ? course_start : cs)
		state.loading = false
	},
	[EDIT_COURSE_START_ERROR]: state => {
		state.loading = false
	},
	[SEND_FACULTY_CAOCH_EMAIL_REQUEST]: state => {
		state.loading = true
	},
	[SEND_FACULTY_CAOCH_EMAIL_SUCCESS]: state => {
		state.loading = false
	},
	[SEND_FACULTY_CAOCH_EMAIL_ERROR]: state => {
		state.loading = false
	},
	[SOFT_DELETE_COURSE_START_SUCCESS]: (state, data) => {
		const { course_start } = data
		state.courseStarts = state.courseStarts.map(cs => cs.uuid == course_start.uuid ? course_start : cs)
		state.loading = false
	},

	[DELETE_COURSE_START_REQUEST]: state => {
		state.loading = true
	},
	//If full delete the object is not returned, just the success boolean.
	// If success == true then pass uuid to remove from data state
	[DELETE_COURSE_START_SUCCESS]: (state, uuid) => {
		state.courseStarts = state.courseStarts.filter(cs => cs.uuid !== uuid)
		state.loading = false
	},
	[DELETE_COURSE_START_ERROR]: (state, data) => {
		state.loading = false
	},
	[UPLOAD_COURSE_START_REQUEST]: state => {
		state.loading = true
	},
	[UPLOAD_COURSE_START_SUCCESS]: state => {
		state.loading = false
	},
	[UPLOAD_COURSE_START_ERROR]: state => {
		state.loading = false
	},
	[SEND_RESUME_REQUEST]: (state) => {
		state.loading = true
	},
	[SEND_RESUME_SUCCESS]: (state) => {
		state.loading = false
	},
	[SEND_RESUME_ERROR]: (state) => {
		state.loading = false
	},
	[SEND_ASSIGNMENT_LETTER_REQUEST]: (state) => {
		state.loading = true
	},
	[SEND_ASSIGNMENT_LETTER_SUCCESS]: (state) => {
		state.loading = false
	},
	[SEND_ASSIGNMENT_LETTER_ERROR]: (state) => {
		state.loading = false
	},
	CREATE_COURSE_START_STUDENT_REMOVE_SUCCESS: (state, data) => {
		state.loading = false
	},
	CLEAR_COURSE_START: (state) => {
		if (state.loadedCs.some(i => i == state.courseStart.uuid)) {
			state.loadedCs = state.loadedCs.filter(i => i != state.courseStart.uuid)
		}
		state.courseStart = {}
	},
	removeLoadedData: (state, uuid) => {
		if (state.loadedCs.some(i => i == uuid)) {
			state.loadedCs = state.loadedCs.filter(i => i != uuid)
		}
	}
}

export const courseStart = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

export function unpackCourseStart(cs, commit) {

	if (!isEmpty(cs.comments)) {
		commit('comments/GET_COMMENTS_SUCCESS', { comments: cs.comments }, { root: true })
		delete cs.comments;
	}


	if (!isEmpty(cs.coach_assignments)) {
		let coach_assignments = cs.coach_assignments.map(ca => unpackCoachAssignment(ca, commit))
		commit('coachAssignment/GET_COACH_ASSIGNMENTS_SUCCESS', { coach_assignments }, { root: true })
		delete cs.coach_assignments;
	}
	if (!isEmpty(cs.faculty_evaluations)) {
		const faculty_evaluations = cs.faculty_evaluations.map(fe => unpackFacultyEvaluation(fe, commit))
		commit('facultyEvaluation/GET_FACULTY_EVALUATIONS_SUCCESS', { faculty_evaluations }, { root: true })
		delete cs.faculty_evaluations;
	}

	if (!isEmpty(cs.faculty)) {
		let course_start_faculties = cs.faculty.map(csf => unpackCourseStartFaculty(csf, commit))
		commit('courseStartFaculty/GET_COURSE_START_FACULTYS_SUCCESS', { course_start_facultys: course_start_faculties, force: true }, { root: true })
		delete cs.faculty;
	}

	if (!isEmpty(cs.course)) {
		let course = unpackCourse(cs.course, commit)
		commit('course/GET_COURSES_SUCCESS', { courses: [course] }, { root: true })
		delete cs.course
	}

	if (!isEmpty(cs.faculty_referrals)) {
		let coach_faculty_referrals = cs.faculty_referrals.map(cfr => unpackCoachFacultyReferral(cfr, commit))
		commit('coachFacultyReferral/GET_COACH_FACULTY_REF_SUCCESS', { coach_faculty_referrals }, { root: true })
		delete cs.faculty_referrals;
	}

	if (!isEmpty(cs.reconciliations)) {
		let course_start_reconciliations = cs.reconciliations.map(csr => unpackCourseStartReconciliation(csr, commit));
		commit('courseStartReconciliation/GET_COURSE_START_RECONCILIATIONS_SUCCESS', { course_start_reconciliations }, { root: true })
		delete cs.reconciliations;
	}

	if (!isEmpty(cs.exception_requests)) {
		let ca_exceptions = cs.exception_requests;
		commit('coachAssignmentException/GET_CA_EXCEPTIONS_SUCCESS', { ca_exceptions }, { root: true })
		delete cs.exception_requests;
	}

	if (!isEmpty(cs.sections)) {
		let course_start_sections = cs.sections;
		commit('courseStartSection/GET_COURSE_START_SECTIONS_SUCCESS', { course_start_sections }, { root: true })
		delete cs.sections;
	}

	if (!isEmpty(cs.coaches)) {
		let coaches = cs.coaches.map(c => unpackCoach(c, commit))
		commit("coaches/GET_COACHES_SUCCESS", { coaches }, { root: true })
		delete cs.coaches
	}
	if (!isEmpty(cs.coaches_resource)) {
		let coaches = cs.coaches_resource.map(c => unpackCoach(c, commit))
		commit("coaches/GET_COACHES_SUCCESS", { coaches }, { root: true })
		delete cs.coaches_resource
	}

	if (!isEmpty(cs.pre_course_meeting_minute)) {
		commit("preCourseMeetingMinute/CREATE_PRE_COURSE_MEETING_MINUTE_SUCCESS", { pre_course_meeting_minute: cs.pre_course_meeting_minute }, { root: true })
		delete cs.pre_course_meeting_minute
	}
	if (!isEmpty(cs.course_meeting_minutes)) {
		const course_meeting_minutes = cs.course_meeting_minutes
		commit("courseMeetingMinute/GET_COURSE_MEETING_MINUTES_SUCCESS", { course_meeting_minutes }, { root: true })
		delete cs.course_meeting_minutes
	}

	return cs
}
