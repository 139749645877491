import {
  GET_COURSE_START_RECONCILIATIONS_REQUEST,
  GET_COURSE_START_RECONCILIATIONS_SUCCESS,
  GET_COURSE_START_RECONCILIATIONS_ERROR,
  GET_COURSE_START_RECONCILIATION_REQUEST,
  GET_COURSE_START_RECONCILIATION_SUCCESS,
  GET_COURSE_START_RECONCILIATION_ERROR,
  CREATE_COURSE_START_RECONCILIATION_REQUEST,
  CREATE_COURSE_START_RECONCILIATION_SUCCESS,
  CREATE_COURSE_START_RECONCILIATION_ERROR,
  EDIT_COURSE_START_RECONCILIATION_REQUEST,
  EDIT_COURSE_START_RECONCILIATION_SUCCESS,
  EDIT_COURSE_START_RECONCILIATION_ERROR,
  DELETE_COURSE_START_RECONCILIATION_REQUEST,
  DELETE_COURSE_START_RECONCILIATION_SUCCESS,
  DELETE_COURSE_START_RECONCILIATION_ERROR
} from "@/actions"

import {
  courseStartReconciliationService
} from "@/services"

const state = {
  courseStartReconciliations: [],
  courseStartReconciliation: {},
  loading: false,
  loadedOnce: false
}

import { addNewFilter, isEmpty } from "@/utilities"

const getters = {
  courseStartReconciliations: state => uuid => state.courseStartReconciliations.filter(csr => csr.cs_uuid == uuid),
  courseStartReconciliation: state => state.courseStartReconciliation,
  courseStartReconByCsUuid: state => csUuid => state.courseStartReconciliations.find(i => i.course_starts_uuid == csUuid),
  CSRecByKey: state => recKey => state.courseStartReconciliations.filter(i => {
    return i.reconciliation_key == recKey
  }),
  reconByCsfUuidCsUuid: state => (csfUuid, csUuid) => state.courseStartReconciliations.find(i => {
    return i.cs_uuid == csUuid && i.faculty_uuid == csfUuid;
  }),
}

const actions = {
  async getCourseStartReconciliations({ commit }, params) {
    commit("GET_COURSE_START_RECONCILIATIONS_REQUEST")
    const response = await courseStartReconciliationService.getCourseStartReconciliations(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_COURSE_START_RECONCILIATIONS_SUCCESS", data)
        break
      default:
        commit("GET_COURSE_START_RECONCILIATIONS_ERROR", data)
    }
    return response
  },
  async getCourseStartReconciliation({ commit }, params) {
    commit("GET_COURSE_START_RECONCILIATION_REQUEST")
    const response = await courseStartReconciliationService.getCourseStartReconciliation(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_COURSE_START_RECONCILIATION_SUCCESS", data)
        break
      default:
        commit("GET_COURSE_START_RECONCILIATION_ERROR", data)
        break
    }
    return response
  },
  async createCourseStartReconciliation({ commit }, params) {
    commit("CREATE_COURSE_START_RECONCILIATION_REQUEST")
    const response = await courseStartReconciliationService.createCourseStartReconciliation(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_COURSE_START_RECONCILIATION_SUCCESS", data) : commit("CREATE_COURSE_START_RECONCILIATION_ERROR", data)
        break
      default:
        commit("CREATE_COURSE_START_RECONCILIATION_ERROR", data)
    }
    return response
  },
  async editCourseStartReconciliation({ commit }, params) {
    commit("EDIT_COURSE_START_RECONCILIATION_REQUEST")
    const uuid = params.uuid
    const response = await courseStartReconciliationService.editCourseStartReconciliation(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_COURSE_START_RECONCILIATION_SUCCESS", data) : commit("EDIT_COURSE_START_RECONCILIATION_ERROR", data)
        break
      default:
        commit("EDIT_COURSE_START_RECONCILIATION_ERROR", data)
    }
    return response
  },
  async deleteCourseStartReconciliation({ commit, dispatch }, uuid) {
    commit("DELETE_COURSE_START_RECONCILIATION_REQUEST")
    const response = await courseStartReconciliationService.deleteCourseStartReconciliation(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        if (data.success) {
          commit("DELETE_COURSE_START_RECONCILIATION_SUCCESS", uuid)
          dispatch("alert/createAlertSuccess", "Roster Deleted", { root: true })
        }
        break
      default:
        commit("DELETE_COURSE_START_RECONCILIATION_ERROR", data)
    }
    return response
  }
}

const mutations = {
  [GET_COURSE_START_RECONCILIATIONS_REQUEST]: state => {
    state.loading = true
  },
  [GET_COURSE_START_RECONCILIATIONS_SUCCESS]: (state, data) => {
    const courseStartReconciliations = data.course_start_reconciliations
    state.courseStartReconciliations = addNewFilter(state.courseStartReconciliations, courseStartReconciliations, data?.force_update)
    state.loading = false
    state.loadedOnce = true
  },
  [GET_COURSE_START_RECONCILIATIONS_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_COURSE_START_RECONCILIATION_REQUEST]: state => {
    state.loading = true
  },
  [GET_COURSE_START_RECONCILIATION_SUCCESS]: (state, data) => {
    const { course_start_reconciliation } = data
    state.courseStartReconciliation = course_start_reconciliation
    state.courseStartReconciliations = addNewFilter(state.courseStartReconciliations, [course_start_reconciliation])
    state.loading = false
  },
  [GET_COURSE_START_RECONCILIATION_ERROR]: (state) => {
    state.loading = false
  },

  [CREATE_COURSE_START_RECONCILIATION_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_COURSE_START_RECONCILIATION_SUCCESS]: (state, data) => {
    let { course_start_reconciliation } = data
    state.courseStartReconciliations = addNewFilter(state.courseStartReconciliations, [course_start_reconciliation])
    state.loading = false
  },
  [CREATE_COURSE_START_RECONCILIATION_ERROR]: state => {
    state.loading = false
  },

  [EDIT_COURSE_START_RECONCILIATION_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_COURSE_START_RECONCILIATION_SUCCESS]: (state, data) => {
    let { course_start_reconciliation } = data
    state.courseStartReconciliations = state.courseStartReconciliations.filter(p => p.uuid !== course_start_reconciliation.uuid)
    state.courseStartReconciliations = addNewFilter(state.courseStartReconciliations, [course_start_reconciliation])
    state.loading = false
  },
  [EDIT_COURSE_START_RECONCILIATION_ERROR]: state => {
    state.loading = false
  },

  [DELETE_COURSE_START_RECONCILIATION_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_COURSE_START_RECONCILIATION_SUCCESS]: (state, uuid) => {
    state.courseStartReconciliations = state.courseStartReconciliations.filter(p => p.uuid !== uuid)
    state.loading = false
  },
  [DELETE_COURSE_START_RECONCILIATION_ERROR]: (state) => {
    state.loading = false
  }
}

/**
 *
 * @param {*} csr
 * @param {*} commit
 * @returns csr
 */
export function unpackCourseStartReconciliation(csr, commit) {

  if (!isEmpty(csr.students)) {
    let students = csr.students;
    commit("students/ADD_STUDENTS", { students }, { root: true });

    delete csr.students;
  }

  return csr;
}

export const courseStartReconciliation = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
