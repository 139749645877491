import {
    GET_COACH_REQUESTS_REQUEST,
    GET_COACH_REQUESTS_SUCCESS,
    GET_COACH_REQUESTS_ERROR,
    GET_COACH_REQUEST_REQUEST,
    GET_COACH_REQUEST_SUCCESS,
    GET_COACH_REQUEST_ERROR,
    CREATE_COACH_REQUEST_REQUEST,
    CREATE_COACH_REQUEST_SUCCESS,
    CREATE_COACH_REQUEST_ERROR,
    EDIT_COACH_REQUEST_REQUEST,
    EDIT_COACH_REQUEST_SUCCESS,
    EDIT_COACH_REQUEST_ERROR,
    DELETE_COACH_REQUEST_REQUEST,
    DELETE_COACH_REQUEST_SUCCESS,
    DELETE_COACH_REQUEST_ERROR,
    CLEAR_COACH_REQUEST_STATE,
    UPLOAD_COACH_REQUEST_REQUEST,
    UPLOAD_COACH_REQUEST_SUCCESS,
    UPLOAD_COACH_REQUEST_ERROR
} from "@/actions"

import {
    coachRequestService
} from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

const state = {
    coachRequests: [],
    coachRequest: {},
    loading: false,
    loadedOnce: false,

}

const getters = {
    getCoachRequestsByProgramUuid: state => uuid => state.coachRequests.filter(i => i.university_programs_uuid == uuid),
    coachRequestByCSUuid: state => csUuid => state.coachRequests.find(cr => cr.course_starts_uuid == csUuid)
}

const actions = {
    async getCoachRequests({ commit }, params) {
        commit("GET_COACH_REQUESTS_REQUEST")
        const response = await coachRequestService.getCoachRequests(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_REQUESTS_SUCCESS", data)
                break
            default:
                commit("GET_COACH_REQUESTS_ERROR", data)
        }
        return response
    },
    async getCoachRequest({ commit }, params) {
        commit("GET_COACH_REQUEST_REQUEST")
        const response = await coachRequestService.getCoachRequest(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("GET_COACH_REQUEST_SUCCESS", data) : commit("GET_COACH_REQUEST_ERROR", data)
                break
            default:
                commit("GET_COACH_REQUEST_ERROR", data)
                break
        }
        return response
    },
    async createCoachRequest({ commit }, params) {
        commit("CREATE_COACH_REQUEST_REQUEST")
        const response = await coachRequestService.createCoachRequest(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_COACH_REQUEST_SUCCESS", data) : commit("CREATE_COACH_REQUEST_ERROR", data)
                break
            default:
                commit("CREATE_COACH_REQUEST_ERROR", data)
        }
        return response
    },
    async editCoachRequest({ commit }, params) {
        commit("EDIT_COACH_REQUEST_REQUEST")
        const uuid = params.uuid
        const response = await coachRequestService.editCoachRequest(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("EDIT_COACH_REQUEST_SUCCESS", data) : commit("EDIT_COACH_REQUEST_ERROR", data)
                break
            default:
                commit("EDIT_COACH_REQUEST_ERROR", data)
        }
        return response
    },
    async deleteCoachRequest({ commit }, params) {
        commit("DELETE_COACH_REQUEST_REQUEST")
        const response = await coachRequestService.deleteCoachRequest(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COACH_REQUEST_SUCCESS", data)
                break
            default:
                commit("DELETE_COACH_REQUEST_DELETE", data)
        }
        return response
    },
    async uploadCoachRequest({ commit, dispatch }, params) {
        commit("UPLOAD_COACH_REQUEST_REQUEST")
        const response = await coachRequestService.uploadCoachRequest(params)
        const { status, data } = response
        switch (status) {
            case 200: 
                commit("UPLOAD_COACH_REQUEST_SUCCESS", data)
                break
            default: 
                commit("UPLOAD_COACH_REQUEST_ERROR", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_REQUESTS_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_REQUESTS_SUCCESS]: (state, data) => {
        const coachRequests = [...data.coach_requests]
        state.coachRequests = addNewFilter(state.coachRequests, coachRequests)
        state.loading = false
    },
    [GET_COACH_REQUESTS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COACH_REQUEST_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_REQUEST_SUCCESS]: (state, data) => {
        const coachRequest = { ...data.coach_request }
        state.coachRequest = coachRequest
        state.loading = false
    },
    [GET_COACH_REQUEST_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_REQUEST_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_REQUEST_SUCCESS]: (state, data) => {
        let coachRequest = { ...data.coach_request }
        state.coachRequests.push(coachRequest)
        state.loading = false
    },
    [CREATE_COACH_REQUEST_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_REQUEST_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_REQUEST_SUCCESS]: (state, data) => {
        let coachRequest = { ...data.coach_request }
        let apps = state.coachRequests.map(app => {
            if (app.uuid == coachRequest.uuid) return coachRequest
            return app
        })
        state.coachRequests = apps
        state.loading = false
    },
    [EDIT_COACH_REQUEST_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_REQUEST_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_REQUEST_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_COACH_REQUEST_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COACH_REQUEST_STATE]: state => {
        state.coachRequests = []
        state.coachAplication = {}
        state.loading = false
        state.loadedOnce = false
    },

    [UPLOAD_COACH_REQUEST_REQUEST]: state => {
        state.loading = true
    },
    [UPLOAD_COACH_REQUEST_SUCCESS]: state => {
        state.loading = false
    },
    [UPLOAD_COACH_REQUEST_ERROR]: state => {
        state.loading = false
    }
}

export const coachRequest = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}