import {
	GET_PERFORMANCE_NOTES_REQUEST,
	GET_PERFORMANCE_NOTES_SUCCESS,
	GET_PERFORMANCE_NOTES_ERROR,
	GET_PERFORMANCE_NOTE_REQUEST,
	GET_PERFORMANCE_NOTE_SUCCESS,
	GET_PERFORMANCE_NOTE_ERROR,
	CREATE_PERFORMANCE_NOTE_REQUEST,
	CREATE_PERFORMANCE_NOTE_SUCCESS,
	CREATE_PERFORMANCE_NOTE_ERROR,
	EDIT_PERFORMANCE_NOTE_REQUEST,
	EDIT_PERFORMANCE_NOTE_SUCCESS,
	EDIT_PERFORMANCE_NOTE_ERROR,
	DELETE_PERFORMANCE_NOTE_REQUEST,
	DELETE_PERFORMANCE_NOTE_SUCCESS,
	DELETE_PERFORMANCE_NOTE_ERROR,
} from "@/actions"

import {
	perfomanceNoteService
} from "@/services"

import { addNewFilter, generateKey, isEmpty } from "@/utilities"

const state = {
	perfomanceNotes: [],
	perfomanceNote: {},
	loading: false,
	loadedOnce: false,
	postionNames: [
		{label: 'Lead Coach', value: 'lead-coach'},
		{label: 'Coordinating Coach', value: 'coordinating-coach'},
		{label: 'Program Assitant', value: 'program-assistant'},
	],
	evaluationQuestion: {
		issue_date: "Date of Issue Related to this Note",
		issue_notes: "Notes/Comments",
	}
}

const getters = {
	perfomanceNotesLoading: state => state.loading,
	perfomanceNotesLoadedOnce: state => state.loadedOnce,
	evaluationPostionNames: state => state.postionNames,
	evaluationQuestion: state => state.evaluationQuestion,
	perfomanceNotes: state => state.perfomanceNotes,
	perfomanceNotesByUser: state => uuid => state.perfomanceNotes.filter(c => c.user_uuid == uuid),
}

const actions = {
	async getPerfomanceNotes({ state, commit }, params) {
		commit("GET_PERFORMANCE_NOTES_REQUEST");
		const response = await perfomanceNoteService.getPerfomanceNotes(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if(!params?.skipStore){
					commit("GET_PERFORMANCE_NOTES_SUCCESS", data);
				}
				break
			default:
				commit("GET_PERFORMANCE_NOTES_ERROR", data)
		}
		return response
	},
	async getPerfomanceNote({ commit, state }, params) {
		commit("GET_PERFORMANCE_NOTE_REQUEST")
		const response = await perfomanceNoteService.getPerfomanceNote(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PERFORMANCE_NOTE_SUCCESS", data)
				break
			default:
				commit("GET_PERFORMANCE_NOTE_ERROR", data)
				break
		}
		return response
	},
	async createPerfomanceNote({ commit, dispatch }, params) {
		commit("CREATE_PERFORMANCE_NOTE_REQUEST")
		const response = await perfomanceNoteService.createPerfomanceNote(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_PERFORMANCE_NOTE_SUCCESS", data) : commit("CREATE_PERFORMANCE_NOTE_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Evaluation Submitted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("CREATE_PERFORMANCE_NOTE_ERROR", data)
		}
		return response
	},
	async editPerfomanceNote({ commit, dispatch }, params) {
		commit("EDIT_PERFORMANCE_NOTE_REQUEST")
		const response = await perfomanceNoteService.editPerfomanceNote(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data?.success ? commit("EDIT_PERFORMANCE_NOTE_SUCCESS", data) : null;
				data?.success ? dispatch('alert/createAlertSuccess', "Evaluation updated!", { root: true }) : null;
				break
			default:
				commit("EDIT_PERFORMANCE_NOTE_ERROR", data)
		}
		return response
	},
	async deletePerfomanceNote({ commit, dispatch }, uuid) {
		commit("DELETE_PERFORMANCE_NOTE_REQUEST")
		const response = await perfomanceNoteService.deletePerfomanceNote(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_PERFORMANCE_NOTE_SUCCESS", { uuid }) : commit("DELETE_PERFORMANCE_NOTE_ERROR")
				data.success ? dispatch('alert/createAlertSuccess', "Evaluation deleted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("DELETE_PERFORMANCE_NOTE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_PERFORMANCE_NOTES_REQUEST]: state => {
		state.loading = true
	},
	[GET_PERFORMANCE_NOTES_SUCCESS]: (state, data) => {
		state.perfomanceNotes = addNewFilter(state.perfomanceNotes, data.perfomance_notes)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_PERFORMANCE_NOTES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_PERFORMANCE_NOTE_REQUEST]: state => {
		state.loading = true
	},
	[GET_PERFORMANCE_NOTE_SUCCESS]: (state, data) => {
		const { perfomance_note }  = data
		state.perfomanceNotes = addNewFilter(state.perfomanceNotes, [perfomance_note])
		state.loading = false
	},
	[GET_PERFORMANCE_NOTE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_PERFORMANCE_NOTE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_PERFORMANCE_NOTE_SUCCESS]: (state, data) => {
		const { perfomance_note } = data;
		state.perfomanceNotes = addNewFilter(state.perfomanceNotes, [perfomance_note])
		state.loading = false
	},
	[CREATE_PERFORMANCE_NOTE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_PERFORMANCE_NOTE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_PERFORMANCE_NOTE_SUCCESS]: (state, data) => {
		const { perfomance_note } = data;
		state.perfomanceNotes = addNewFilter(state.perfomanceNotes, [perfomance_note])
		state.loading = false;
	},
	[EDIT_PERFORMANCE_NOTE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_PERFORMANCE_NOTE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_PERFORMANCE_NOTE_SUCCESS]: (state, data) => {
		const { uuid } = data;
		state.perfomanceNotes = state.perfomanceNotes.filter(c => c.uuid !== uuid)
		state.loading = false
	},
	[DELETE_PERFORMANCE_NOTE_ERROR]: (state) => {
		state.loading = false
	},
}

export const perfomanceNote = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
