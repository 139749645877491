export const GET_COURSE_STARTS_REQUEST = "GET_COURSE_STARTS_REQUEST"
export const GET_COURSE_STARTS_SUCCESS = "GET_COURSE_STARTS_SUCCESS"
export const GET_COURSE_STARTS_ERROR = "GET_COURSE_STARTS_ERROR"
export const GET_COURSE_START_REQUEST = "GET_COURSE_START_REQUEST"
export const GET_COURSE_START_SUCCESS = "GET_COURSE_START_SUCCESS"
export const GET_COURSE_START_ERROR = "GET_COURSE_START_ERROR"
export const CREATE_COURSE_START_REQUEST = "CREATE_COURSE_START_REQUEST"
export const CREATE_COURSE_START_SUCCESS = "CREATE_COURSE_START_SUCCESS"
export const CREATE_COURSE_START_ERROR = "CREATE_COURSE_START_ERROR"
export const EDIT_COURSE_START_REQUEST = "EDIT_COURSE_START_REQUEST"
export const EDIT_COURSE_START_SUCCESS = "EDIT_COURSE_START_SUCCESS"
export const EDIT_COURSE_START_ERROR = "EDIT_COURSE_START_ERROR"
export const SEND_FACULTY_CAOCH_EMAIL_REQUEST = "SEND_FACULTY_CAOCH_EMAIL_REQUEST"
export const SEND_FACULTY_CAOCH_EMAIL_SUCCESS = "SEND_FACULTY_CAOCH_EMAIL_SUCCESS"
export const SEND_FACULTY_CAOCH_EMAIL_ERROR = "SEND_FACULTY_CAOCH_EMAIL_ERROR"
export const DELETE_COURSE_START_REQUEST = "DELETE_COURSE_START_REQUEST"
export const DELETE_COURSE_START_SUCCESS = "DELETE_COURSE_START_SUCCESS"
export const DELETE_COURSE_START_ERROR = "DELETE_COURSE_START_ERROR"

export const SOFT_DELETE_COURSE_START_SUCCESS = "SOFT_DELETE_COURSE_START_SUCCESS"

export const UPLOAD_COURSE_START_REQUEST = "UPLOAD_COURSE_START_REQUEST"
export const UPLOAD_COURSE_START_SUCCESS = "UPLOAD_COURSE_START_SUCCESS"
export const UPLOAD_COURSE_START_ERROR = "UPLOAD_COURSE_START_ERROR"
export const SEND_RESUME_REQUEST = "SEND_RESUME_REQUEST"
export const SEND_RESUME_SUCCESS = "SEND_RESUME_SUCCESS"
export const SEND_RESUME_ERROR = "SEND_RESUME_ERROR"
export const SEND_ASSIGNMENT_LETTER_REQUEST = "SEND_ASSIGNMENT_LETTER_REQUEST"
export const SEND_ASSIGNMENT_LETTER_SUCCESS = "SEND_ASSIGNMENT_LETTER_SUCCESS"
export const SEND_ASSIGNMENT_LETTER_ERROR = "SEND_ASSIGNMENT_LETTER_ERROR"