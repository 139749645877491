import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const notificationService = {
    async getNotifications(params) {
        let getParams = processQueryParams(params)
        const getNotificationsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/notifications", getNotificationsRequest)
    },
    async getNotification(uuid) {
        const getNotificationRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/notifications/${uuid}`, getNotificationRequest)
    },
    async createNotification(params) {
        const createData = processInput(params)
        const createNotificationRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/notifications", createNotificationRequest)
    },
    async editNotification(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editNotificationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/notifications/${uuid}`, editNotificationRequest)
    },
    async deleteNotification(data) {
        let editData = processQueryParams(data)
        const deleteNotificationRequest = {
            method: "DELETE",
            params: editData
        }
        return await apiRequest(`/api/notifications/${data.uuid}`, deleteNotificationRequest)
    }
}
