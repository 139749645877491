import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachReferenceService = {
    async getCoachReferences(params) {
        let getParams = processQueryParams(params)
        const getCoachReferencesRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-references", getCoachReferencesRequest)
    },
    async getCoachReference(uuid) {
        const getCoachReferenceRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-references/${uuid}`, getCoachReferenceRequest)
    },
    async createCoachReference(params) {
        const createData = processInput(params)
        const createCoachReferenceRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-references", createCoachReferenceRequest)
    },
    async editCoachReference(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachReferenceRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-references/${uuid}`, editCoachReferenceRequest)
    },
    async deleteCoachReference(uuid) {
        const deleteCoachReferenceRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-references/${uuid}`, deleteCoachReferenceRequest)
    }
}