import {
  GET_EEO_RACE_ETHNICITIES_REQUEST,
  GET_EEO_RACE_ETHNICITIES_SUCCESS,
  GET_EEO_RACE_ETHNICITIES_ERROR,
  GET_EEO_RACE_ETHNICITY_REQUEST,
  GET_EEO_RACE_ETHNICITY_SUCCESS,
  GET_EEO_RACE_ETHNICITY_ERROR,
  CREATE_EEO_RACE_ETHNICITY_REQUEST,
  CREATE_EEO_RACE_ETHNICITY_SUCCESS,
  CREATE_EEO_RACE_ETHNICITY_ERROR,
  EDIT_EEO_RACE_ETHNICITY_REQUEST,
  EDIT_EEO_RACE_ETHNICITY_SUCCESS,
  EDIT_EEO_RACE_ETHNICITY_ERROR,
  DELETE_EEO_RACE_ETHNICITY_REQUEST,
  DELETE_EEO_RACE_ETHNICITY_SUCCESS,
  DELETE_EEO_RACE_ETHNICITY_ERROR
} from "../../actions"

import {
  eeoRaceEthnicityService
} from "../../services"

const state = {
  eeoRaceEthnicities: [],
  eeoRaceEthnicity: {},
  loading: false
}

const getters = {
  eeoRaceEthnicitySelectOptions: state => state.eeoRaceEthnicities.map((eeoRaceEthnicity, index) => ({ value: eeoRaceEthnicity.uuid, label: eeoRaceEthnicity.label }))
}

const actions = {
  async getEEORaceEthnicities({ commit }) {
    commit("GET_EEO_RACE_ETHNICITIES_REQUEST")
    const response = await eeoRaceEthnicityService.getEEORaceEthnicities();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_EEO_RACE_ETHNICITIES_SUCCESS", data)
        break
      default:
        commit("GET_EEO_RACE_ETHNICITIES_ERROR", data)
    }
    return response
  },
  async getEEORaceEthnicity({ commit }, params) {
    commit("GET_EEO_RACE_ETHNICITY_REQUEST")
    const response = await eeoRaceEthnicityService.getEEORaceEthnicity(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_EEO_RACE_ETHNICITY_SUCCESS", data)
        break
      default:
        commit("GET_EEO_RACE_ETHNICITY_ERROR", data)
        break
    }
    return response
  },
  async createEEORaceEthnicity({ commit }, params) {
    commit("CREATE_EEO_RACE_ETHNICITY_REQUEST")
    const response = await eeoRaceEthnicityService.createEEORaceEthnicity(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("CREATE_EEO_RACE_ETHNICITY_SUCCESS", data)
        break
      default:
        commit("CREATE_EEO_RACE_ETHNICITY_ERROR", data)
    }
    return response
  },
  async editEEORaceEthnicity({ commit }, params, uuid) {
    commit("EDIT_EEO_RACE_ETHNICITY_REQUEST")
    const response = await eeoRaceEthnicityService.editEEORaceEthnicity(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_EEO_RACE_ETHNICITY_SUCCESS", data)
        break
      default:
        commit("EDIT_EEO_RACE_ETHNICITY_ERROR", data)
    }
    return response
  },
  async deleteEEORaceEthnicity({ commit }, params) {
    commit("DELETE_EEO_RACE_ETHNICITY_REQUEST")
    const response = await eeoRaceEthnicityService.deleteEEORaceEthnicity(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_EEO_RACE_ETHNICITY_SUCCESS", data)
        break
      default:
        commit("DELETE_EEO_RACE_ETHNICITY_DELETE", data)
    }
    return response
  }
}

const mutations = {
  [GET_EEO_RACE_ETHNICITIES_REQUEST]: state => {
    state.loading = true
  },
  [GET_EEO_RACE_ETHNICITIES_SUCCESS]: (state, data) => {
    const eeoRaceEthnicities = data.eeo_race_ethnicities
    state.eeoRaceEthnicities = eeoRaceEthnicities
    state.loading = false
  },
  [GET_EEO_RACE_ETHNICITIES_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_EEO_RACE_ETHNICITY_REQUEST]: state => {
    state.loading = true
  },
  [GET_EEO_RACE_ETHNICITY_SUCCESS]: (state, data) => {
    const eeoRaceEthnicity = { ...data.eeo_race_ethnicity }
    state.eeoRaceEthnicity = eeoRaceEthnicity
    state.loading = false
  },
  [GET_EEO_RACE_ETHNICITY_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_EEO_RACE_ETHNICITY_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_EEO_RACE_ETHNICITY_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [CREATE_EEO_RACE_ETHNICITY_ERROR]: state => {
    state.loading = false
  },

  [EDIT_EEO_RACE_ETHNICITY_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_EEO_RACE_ETHNICITY_SUCCESS]: state => {
    state.loading = false
  },
  [EDIT_EEO_RACE_ETHNICITY_ERROR]: state => {
    state.loading = false
  },

  [DELETE_EEO_RACE_ETHNICITY_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_EEO_RACE_ETHNICITY_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [DELETE_EEO_RACE_ETHNICITY_ERROR]: (state, data) => {
    state.loading = false
  }

}

export const eeoRaceEthnicity = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
