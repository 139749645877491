import {
    apiRequest,
    processInput
} from "@/utilities"

export const opmsService = {
    async getOpms() {
        const getOpmsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/opms", getOpmsRequest)
    },
    async createOpm(params) {
        const createData = processInput(params);
        const postOpmRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/opms", postOpmRequest)
    },
    async getOpm(uuid) {

        const getOpmRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/opms/${uuid}`, getOpmRequest);
    },
    async editOpm(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateOpmRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/opms/${uuid}`, updateOpmRequest);
    },
    async deleteOpm(uuid) {

        const deleteOpmRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/opms/${uuid}`, deleteOpmRequest);
    }
}