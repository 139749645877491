import {
    apiRequest,
    processInput
} from "@/utilities"

export const universityRfpsService = {
    async getUniversityRfps() {
        const getUniversityRfpsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/rfp-details", getUniversityRfpsRequest)
    },
    async createUniversityRfp(params) {
        const createData = processInput(params);
        const postUniversityRfpRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/rfp-details", postUniversityRfpRequest)
    },
    async getUniversityRfp(uuid) {

        const getUniversityRfpRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/rfp-details/${uuid}`, getUniversityRfpRequest);
    },
    async editUniversityRfp(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityRfpRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/rfp-details/${uuid}`, updateUniversityRfpRequest);
    },
    async deleteUniversityRfp(uuid) {

        const deleteUniversityRfpRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/rfp-details/${uuid}`, deleteUniversityRfpRequest);
    }
}