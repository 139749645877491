import {
	apiRequest,
	processInput,
	addNewFilter,
	processQueryParams,
	isEmpty
} from "@/utilities"
import { unpackCourseStart } from "@/modules"

const state = {
	loading: false,
	CAExceptions: [],
}

function mapData(rootGetters, data) {
	if (isEmpty(data)) return {}
	if (!isEmpty(data.coaches_uuid)) {
		let coachByUuid = rootGetters['coaches/coachByUuid']
		data.coach = coachByUuid(data.coaches_uuid)
	}
	if (!isEmpty(data.course_starts_uuid)) {
		let csByUuid = rootGetters['courseStart/csByUuid']
		data.course_start = csByUuid(data.course_starts_uuid)
	}
	return data
}

const getters = {
	CAExceptions: state => state.CAExceptions,
	CAEByCsUuid: state => uuid => {
		return state.CAExceptions.filter(i => i.course_starts_uuid == uuid)
	},
	CAEByCaUuid: state => uuid => {
		return state.CAExceptions.filter(i => i.coach_assignments_uuid == uuid)
	},
	icEADLoading: state => state.loading
}

const actions = {
	async getCAExceptions({ commit }) {
		commit("CA_EXCEPTION_REQUEST")
		const getAllReq = {
			method: "GET"
		}
		const response = await apiRequest(`/api/ca-exceptions`, getAllReq)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_CA_EXCEPTIONS_SUCCESS", data)
				break
			default:
				commit("GET_CA_EXCEPTIONS_ERROR", data)
				break
		}
		return response
	},
	async createCAException({ commit, dispatch }, params) {
		commit("CA_EXCEPTION_REQUEST")
		let postData = processInput(params)
		const postRequest = {
			method: "POST",
			data: postData
		}
		const response = await apiRequest(`/api/ca-exceptions`, postRequest);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_CA_EXCEPTION_SUCCESS", data)
				data.success ? dispatch("alert/createAlertSuccess", "Assignment exception requested.", { root: true }) : null;
				data.error ? dispatch('alert/createAlertErrors', !isEmpty(data.errors) ? decodeLaravelValidationErrors(data.errors) : "Oops! Something went wrong.", { root: true }) : null;
				break
			default:
				commit("CREATE_CA_EXCEPTION_ERROR", data)
		}
		return response
	},
	async editCAException({ commit }, params) {
		commit("CA_EXCEPTION_REQUEST")
		let editData = processInput(params)
		editData.append("_method", "PATCH");
		const updateRequest = {
			method: "POST",
			data: editData
		}
		let uuid = params.uuid
		// eslint-disable-next-line valid-typeof
		if (typeof params.uuid == 'array') {
			uuid = uuid.find((i, idx) => idx === 0)
		}
		const response = await apiRequest(`/api/ca-exceptions/${uuid}`, updateRequest);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_CA_EXCEPTION_SUCCESS", data)
				break
			default:
				commit("EDIT_CA_EXCEPTION_ERROR", data)
		}
		return response
	},
	async deleteCAException({ commit }, uuid) {
		commit("CA_EXCEPTION_REQUEST")
		let params = processQueryParams({ uuid })
		const deleteCAE = {
			method: "DELETE",
			params
		}
		const response = await apiRequest(`/api/ca-exceptions/${uuid}`, deleteCAE);
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_CA_EXCEPTION_SUCCESS", data) : commit("DELETE_CA_EXCEPTION_ERROR", data)
				break
			default:
				commit("DELETE_CA_EXCEPTION_ERROR", data)
		}
		return response
	},
}

const mutations = {
	CA_EXCEPTION_REQUEST: state => {
		state.loading = true
	},
	GET_CA_EXCEPTIONS_SUCCESS: (state, data) => {
		const CAExceptions = data.ca_exceptions
		state.CAExceptions = addNewFilter(state.CAExceptions, CAExceptions)
		state.loading = false
	},
	GET_CA_EXCEPTIONS_ERROR: (state, data) => {
		state.loading = false
	},
	CREATE_CA_EXCEPTION_SUCCESS: (state, data) => {
		if (!isEmpty(data.ca_exception)) {
			const CAException = data.ca_exception
			state.CAExceptions = addNewFilter(state.CAExceptions, [CAException])
		}
		state.loading = false
	},
	CREATE_CA_EXCEPTION_ERROR: (state, data) => {
		state.loading = false
	},
	EDIT_CA_EXCEPTION_SUCCESS: (state, data) => {
		if (!isEmpty(data.ca_exception)) {
			const CAException = data.ca_exception
			state.CAExceptions = state.CAExceptions.map(i => i.uuid == CAException.uuid ? CAException : i)
		}
		if (!isEmpty(data.ca_exceptions)) {
			const CAExceptions = data.ca_exceptions
			state.CAExceptions = addNewFilter(state.CAExceptions, CAExceptions, true)
		}
		state.loading = false
	},
	EDIT_CA_EXCEPTION_ERROR: (state, data) => {
		state.loading = false
	},
	DELETE_CA_EXCEPTION_SUCCESS: (state, data) => {
		let uuid = null;
		if (!isEmpty(data.ca_exception)) {
			if (!isEmpty(data.ca_exception.uuid)) {
				uuid = data.ca_exception.uuid
			}
		} else if (!isEmpty(data.uuid)) {
			uuid = data.uuid
		}
		if (!isEmpty(uuid)) {
			state.CAExceptions = state.CAExceptions.filter(i => i.uuid != uuid)
		}
		state.loading = false
	},
	DELETE_CA_EXCEPTION_ERROR: (state, data) => {
		state.loading = false
	},
}

export const coachAssignmentException = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
