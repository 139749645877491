import {
    apiRequest,
    processInput,
    processQueryParams,
    generateKey
} from "@/utilities"

export const userGroupService = {

    async createUserGroup(params) {
        let createData = processInput(params);
        const postUserGroupRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/user-groups", postUserGroupRequest)
    },
    async deleteUserGroup(params) {
        // The deleteData object has all the information needed 
        let deleteParams = processQueryParams(params);
        let segment = generateKey()
        const deleteUserGroupRequest = {
            method: "DELETE",
            params: deleteParams
        }

        return await apiRequest(`/api/user-groups/${segment}`, deleteUserGroupRequest);
    }
}