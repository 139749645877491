import {
    apiRequest,
    processInput,
    processQueryParams
  } from "@/utilities"
  
  const moduleUrl = "pre-course-meeting-minutes";

  export const preCourseMeetingMinuteService = {
    async getPreCourseMeetingMinutes(params) {
  
      const getPreCourseMeetingMinutesRequest = {
        method: "GET",
        params: processQueryParams(params)
      }
  
      return await apiRequest(`/api/${moduleUrl}`, getPreCourseMeetingMinutesRequest)
    },
    async createPreCourseMeetingMinute(params) {
  
      const createData = processInput(params)
      const postPreCourseMeetingMinuteRequest = {
        method: "POST",
        data: createData
      }
      return await apiRequest(`/api/${moduleUrl}`, postPreCourseMeetingMinuteRequest)
    },
    async getPreCourseMeetingMinute(uuid) {
  
      const getPreCourseMeetingMinuteRequest = {
        method: "GET"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, getPreCourseMeetingMinuteRequest);
    },
    async editPreCourseMeetingMinute(data, uuid) {
  
      let editData = processInput(data)
      editData.append("_method", "PATCH")
  
      const updatePreCourseMeetingMinuteRequest = {
        method: "POST",
        data: editData
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, updatePreCourseMeetingMinuteRequest);
    },
    async deletePreCourseMeetingMinute(uuid) {
  
      const deletePreCourseMeetingMinuteRequest = {
        method: "DELETE"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, deletePreCourseMeetingMinuteRequest);
    }
  }
  