export const GET_LANGUAGES_REQUEST = "GET_LANGUAGES_REQUEST"
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS"
export const GET_LANGUAGES_ERROR = "GET_LANGUAGES_ERROR"
export const GET_LANGUAGE_REQUEST = "GET_LANGUAGE_REQUEST"
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS"
export const GET_LANGUAGE_ERROR = "GET_LANGUAGE_ERROR"
export const CREATE_LANGUAGE_REQUEST = "CREATE_LANGUAGE_REQUEST"
export const CREATE_LANGUAGE_SUCCESS = "CREATE_LANGUAGE_SUCCESS"
export const CREATE_LANGUAGE_ERROR = "CREATE_LANGUAGE_ERROR"
export const EDIT_LANGUAGE_REQUEST = "EDIT_LANGUAGE_REQUEST"
export const EDIT_LANGUAGE_SUCCESS = "EDIT_LANGUAGE_SUCCESS"
export const EDIT_LANGUAGE_ERROR = "EDIT_LANGUAGE_ERROR"
export const DELETE_LANGUAGE_REQUEST = "DELETE_LANGUAGE_REQUEST"
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS"
export const DELETE_LANGUAGE_ERROR = "DELETE_LANGUAGE_ERROR"