import {
    GET_UNIVERSITY_LMSS_REQUEST,
    GET_UNIVERSITY_LMSS_SUCCESS,
    GET_UNIVERSITY_LMSS_ERROR,
    GET_UNIVERSITY_LMS_REQUEST,
    GET_UNIVERSITY_LMS_SUCCESS,
    GET_UNIVERSITY_LMS_ERROR,
    CREATE_UNIVERSITY_LMS_REQUEST,
    CREATE_UNIVERSITY_LMS_SUCCESS,
    CREATE_UNIVERSITY_LMS_ERROR,
    EDIT_UNIVERSITY_LMS_REQUEST,
    EDIT_UNIVERSITY_LMS_SUCCESS,
    EDIT_UNIVERSITY_LMS_ERROR,
    DELETE_UNIVERSITY_LMS_REQUEST,
    DELETE_UNIVERSITY_LMS_SUCCESS,
    BROADCAST_DELETE_UNIVERSITY_LMS_SUCCESS,
    DELETE_UNIVERSITY_LMS_ERROR
} from "@/actions"

import {
    universityLMSsService
} from "@/services"
import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    universityLMSs: [],
    universityLMS: {},
    loading: false,
    uniLmsLoadedOnce: false,
}

const getters = {
    uniLmsLoadedOnce: state => state.uniLmsLoadedOnce,
    allUniversityLMSs: state => state.universityLMSs,
    universityLMSFilter: state => uuid => state.universityLMSs.filter(upm => upm.university_uuid == uuid).sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
}

const actions = {
    async getUniversityLMSs({ commit }) {
        commit("GET_UNIVERSITY_LMSS_REQUEST")
        const response = await universityLMSsService.getUniversityLMSs();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_LMSS_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_LMSS_ERROR", data)
        }
        return response
    },
    
    async getUniversityLMS({ commit }, params) {
        commit("GET_UNIVERSITY_LMS_REQUEST")
        const response = await universityLMSsService.getUniversityLMS(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_LMS_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_LMS_ERROR", data)
                break
        }
        return response
    },
    async createUniversityLMS({ commit }, params) {
        commit("CREATE_UNIVERSITY_LMS_REQUEST")
        const response = await universityLMSsService.createUniversityLMS(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_UNIVERSITY_LMS_SUCCESS", data)
                break
            default:
                commit("CREATE_UNIVERSITY_LMS_ERROR", data)
        }
        return response
    },
    async editUniversityLMS({ commit }, params) {
        commit("EDIT_UNIVERSITY_LMS_REQUEST")
        const response = await universityLMSsService.editUniversityLMS(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_UNIVERSITY_LMS_SUCCESS", data)
                break
            default:
                commit("EDIT_UNIVERSITY_LMS_ERROR", data)
        }
        return response
    },
    async deleteUniversityLMS({ commit }, uuid) {
        commit("DELETE_UNIVERSITY_LMS_REQUEST")
        const response = await universityLMSsService.deleteUniversityLMS(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_UNIVERSITY_LMS_SUCCESS", uuid)
                break
            default:
                commit("DELETE_UNIVERSITY_LMS_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_UNIVERSITY_LMSS_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_LMSS_SUCCESS]: (state, data) => {
        let mdata = [...data.university_lms]
        state.universityLMSs = mdata
        state.uniLmsLoadedOnce = true
        state.loading = false
    },
    [GET_UNIVERSITY_LMSS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_UNIVERSITY_LMS_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_LMS_SUCCESS]: (state, data) => {
        let mdata = { ...data.university_lms }
        state.universityLMS = mdata
        state.loading = false
    },
    [GET_UNIVERSITY_LMS_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_UNIVERSITY_LMS_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_UNIVERSITY_LMS_SUCCESS]: (state, data) => {
        const { university_lms } = data
        if(!isEmpty(university_lms)){
            if(Array.isArray(university_lms)){
                state.universityLMSs = state.universityLMSs.filter(i => !university_lms.some(j => j.university_uuid == i.university_uuid))
                state.universityLMSs = addNewFilter(state.universityLMSs, university_lms)
            }else{
                state.universityLMSs = state.universityLMSs.filter(i => i.uuid != university_lms.uuid)
                state.universityLMSs = addNewFilter(state.universityLMSs, [university_lms])
            }
        }
        state.loading = false
    },
    [CREATE_UNIVERSITY_LMS_ERROR]: state => {
        state.loading = false
    },

    [EDIT_UNIVERSITY_LMS_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_UNIVERSITY_LMS_SUCCESS]: (state, data) => {
        const { university_lms } = data
        if(!isEmpty(university_lms)){
            state.universityLMSs = state.universityLMSs.filter(i => i.uuid != university_lms.uuid)
            state.universityLMSs.push(university_lms)
        }
        state.loading = false
    },
    [EDIT_UNIVERSITY_LMS_ERROR]: state => {
        state.loading = false
    },

    [DELETE_UNIVERSITY_LMS_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_UNIVERSITY_LMS_SUCCESS]: (state, uuid) => {
        if(typeof uuid == 'string'){
            state.universityLMSs = state.universityLMSs.filter(i => i.uuid != uuid)
        }else{
            const { university_lms } = uuid
            if(!isEmpty(university_lms)){
                state.universityLMSs = state.universityLMSs.filter(i => i.uuid != university_lms.uuid)
            }
        }
        state.loading = false
    },
    [BROADCAST_DELETE_UNIVERSITY_LMS_SUCCESS]: (state, data) => {
        const { university_lms } = data
        if(!isEmpty(university_lms)){
            state.universityLMSs = state.universityLMSs.filter(i => i.uuid != university_lms.uuid)
        }
        state.loading = false
    },
    [DELETE_UNIVERSITY_LMS_ERROR]: (state, data) => {
        state.loading = false
    },

}

export const universityLMS = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}