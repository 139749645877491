export const universityPartnerRoutes = {
    path: "/university-partnership/:uuid",
    name: "university",
    redirect: {
        name: 'up-account-details'
    },
    component: () => import("@/layouts/UniversityPartnership.vue"),
    meta: {
        authRequired: true,
        allowedRoles: [
            'university-partnership'
        ],
        label: "University Partnership",
    },
    children: [
        {
            path: "account-details",
            name: "up-account-details",
            meta: {
                label: "Account Details",
            },
            component: () => import("@/pages/university/partnership/AccountDetails.vue"),
        },
        {
            path: "lms-access",
            name: "up-lms-access",
            meta: {
                label: "LMS Access",
                updateLastVisited: true
            },
            component: () => import("@/pages/university/partnership/LMSAccess.vue")
        },
        {
            path: "coach-course-procedure",
            name: "up-coach-course-procedure",
            meta: {
                label: "Coach & Couse Procedure",
                updateLastVisited: true
            },
            component: () => import("@/pages/university/partnership/CoachCourseProcedure.vue")
        },
        {
            path: "additional-poc",
            name: "up-additional-poc",
            meta: {
                label: "Additional POC",
                updateLastVisited: true
            },
            component: () => import("@/pages/university/partnership/AdditionalPOC.vue")
        },
        {
            path: "success",
            name: "up-submit-success",
            meta: {
                label: "Submission Successful",
                updateLastVisited: false
            },
            component: () => import("@/pages/university/partnership/UnivPartnerSuccess.vue")
        },
    ]
};
