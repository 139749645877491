import {
    apiRequest,
    processInput
} from "@/utilities"
const apiUrl = '/api/task-settings'
export const taskSettingService = {
    async getTaskSettings() {
        const getTaskSettingsRequest = {
            method: "GET"
        }
        return await apiRequest(apiUrl, getTaskSettingsRequest)
    },
    async getTaskSetting(uuid) {
        const getRequest = {
            method: "GET"
        }
        return await apiRequest(`${apiUrl}/${uuid}`, getRequest)
    },
    async createTaskSetting(params) {
        const createData = processInput(params)
        const createRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest(apiUrl, createRequest)
    },
    async editTaskSetting(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`${apiUrl}/${uuid}`, editRequest)
    },
    async deleteTaskSetting(uuid) {
        const deleteRequest = {
            method: "DELETE"
        }
        return await apiRequest(`${apiUrl}/${uuid}`, deleteRequest)
    }
}