import {
  wasUniversityProgramService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities";

const state = {
  loading: false,
}

const getters = {

}

const actions = {
  async updateUpcomingSheetStructure({ commit, dispatch }, params) {
    commit("UPDATE_UPCOMING_SHEET_STRUCTURE_REQUEST")
    const response = await wasUniversityProgramService.updateUpcomingSheetStructure(params);
    const { status, data } = response
    switch (status) {
      case 200:
        
        commit("UPDATE_UPCOMING_SHEET_STRUCTURE_SUCCESS", data);
        data.success ? dispatch("alert/createAlertSuccess", "Working assignment sheets updated.", { root: true }) : dispatch('alert/createAlertErrors', "Sorry! Something went wrong.", { root: true });
        break
      default:
        commit("UPDATE_UPCOMING_SHEET_STRUCTURE_ERROR", data);
        dispatch('alert/createAlertErrors', "Sorry! Something went wrong.", { root: true })
    }
    return response
  },

  async editWasUniversityProgram({ commit, dispatch }, params) {
    // commit("UPDATE_UPCOMING_SHEET_STRUCTURE_REQUEST")
    const response = await wasUniversityProgramService.editWasUniversityProgram(params);
    const { status, data } = response;
    switch (status) {
      case 200:
        data.success ? dispatch("alert/createAlertSuccess", "Working assignment sheet updated.", { root: true }) : dispatch('alert/createAlertErrors', "Sorry! Something went wrong.", { root: true })
        // commit("UPDATE_UPCOMING_SHEET_STRUCTURE_SUCCESS", data)
        break
      default:
        // commit("UPDATE_UPCOMING_SHEET_STRUCTURE_ERROR", data)
    }

    return response;
  }
}

const mutations = {
  UPDATE_UPCOMING_SHEET_STRUCTURE_REQUEST: (state) => {
    state.loading = true;
  },
  UPDATE_UPCOMING_SHEET_STRUCTURE_SUCCESS: state => {
    state.loading = false;
  },
  UPDATE_UPCOMING_SHEET_STRUCTURE_ERROR: state => {
    state.loading = false;
  }
}

export const wasUniversityProgram = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
