import {
    apiRequest,
    processInput
} from "@/utilities"

export const universityProspectMeetingsService = {
    async getUniversityProspectMeetings() {
        const getUniversityProspectMeetingsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/prospect-meetings", getUniversityProspectMeetingsRequest)
    },
    async createUniversityProspectMeeting(params) {
        const createData = processInput(params);
        const postUniversityProspectMeetingRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/prospect-meetings", postUniversityProspectMeetingRequest)
    },
    async getUniversityProspectMeeting(uuid) {

        const getUniversityProspectMeetingRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/prospect-meetings/${uuid}`, getUniversityProspectMeetingRequest);
    },
    async editUniversityProspectMeeting(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityProspectMeetingRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/prospect-meetings/${uuid}`, updateUniversityProspectMeetingRequest);
    },
    async deleteUniversityProspectMeeting(uuid) {

        const deleteUniversityProspectMeetingRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/prospect-meetings/${uuid}`, deleteUniversityProspectMeetingRequest);
    }
}