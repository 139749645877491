import {
  GET_ADDRESS_TYPES_REQUEST,
  GET_ADDRESS_TYPES_SUCCESS,
  GET_ADDRESS_TYPES_ERROR,
  GET_ADDRESS_TYPE_REQUEST,
  GET_ADDRESS_TYPE_SUCCESS,
  GET_ADDRESS_TYPE_ERROR,
  CREATE_ADDRESS_TYPE_REQUEST,
  CREATE_ADDRESS_TYPE_SUCCESS,
  CREATE_ADDRESS_TYPE_ERROR,
  EDIT_ADDRESS_TYPE_REQUEST,
  EDIT_ADDRESS_TYPE_SUCCESS,
  EDIT_ADDRESS_TYPE_ERROR,
  DELETE_ADDRESS_TYPE_REQUEST,
  DELETE_ADDRESS_TYPE_SUCCESS,
  DELETE_ADDRESS_TYPE_ERROR
} from "@/actions"

import {
  addressTypeService
} from "@/services"

const state = {
  addressTypes: [],
  addressType: {},
  loading: false
}

const getters = {
  addressTypes: state => state.addressTypes,
  addressTypeSelectOptions: state => state.addressTypes.map(at => ({ value: at.uuid, label: at.label })),
  mailingAddressType: state => state.addressTypes.find(type => type.slug == 'mailing-address'),
  universityAddressType: state => state.addressTypes.find(type => type.slug == 'university-address')
}

const actions = {
  async getAddressTypes({ commit }) {
    commit("GET_ADDRESS_TYPES_REQUEST")
    const response = await addressTypeService.getAddressTypes();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_ADDRESS_TYPES_SUCCESS", data)
        break
      default:
        commit("GET_ADDRESS_TYPES_ERROR", data)
    }
    return response
  },
  async getAddressType({ commit }, params) {
    commit("GET_ADDRESS_TYPE_REQUEST")
    const response = await addressTypeService.getAddressType(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_ADDRESS_TYPE_SUCCESS", data)
        break
      default:
        commit("GET_ADDRESS_TYPE_ERROR", data)
        break
    }
    return response
  },
  async createAddressType({ commit }, params) {
    commit("CREATE_ADDRESS_TYPE_REQUEST")
    const response = await addressTypeService.createAddressType(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("CREATE_ADDRESS_TYPE_SUCCESS", data)
        break
      default:
        commit("CREATE_ADDRESS_TYPE_ERROR", data)
    }
    return response
  },
  async editAddressType({ commit }, params, uuid) {
    commit("EDIT_ADDRESS_TYPE_REQUEST")
    const response = await addressTypeService.editAddressType(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_ADDRESS_TYPE_SUCCESS", data)
        break
      default:
        commit("EDIT_ADDRESS_TYPE_ERROR", data)
    }
    return response
  },
  async deleteAddressType({ commit }, params) {
    commit("DELETE_ADDRESS_TYPE_REQUEST")
    const response = await addressTypeService.deleteAddressType(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_ADDRESS_TYPE_SUCCESS", data)
        break
      default:
        commit("DELETE_ADDRESS_TYPE_DELETE", data)
    }
    return response
  }
}

const mutations = {
  [GET_ADDRESS_TYPES_REQUEST]: state => {
    state.loading = true
  },
  [GET_ADDRESS_TYPES_SUCCESS]: (state, data) => {
    const address_types = [...data.address_types]
    state.addressTypes = address_types
    state.loading = false
  },
  [GET_ADDRESS_TYPES_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_ADDRESS_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [GET_ADDRESS_TYPE_SUCCESS]: (state, data) => {
    const addressType = { ...data.address }
    state.addressType = addressType
    state.loading = false
  },
  [GET_ADDRESS_TYPE_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_ADDRESS_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_ADDRESS_TYPE_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [CREATE_ADDRESS_TYPE_ERROR]: state => {
    state.loading = false
  },

  [EDIT_ADDRESS_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_ADDRESS_TYPE_SUCCESS]: state => {
    state.loading = false
  },
  [EDIT_ADDRESS_TYPE_ERROR]: state => {
    state.loading = false
  },

  [DELETE_ADDRESS_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_ADDRESS_TYPE_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [DELETE_ADDRESS_TYPE_ERROR]: (state, data) => {
    state.loading = false
  }

}

export const addressType = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
