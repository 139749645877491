export const GET_EEO_GENDERS_REQUEST = "GET_EEO_GENDERS_REQUEST"
export const GET_EEO_GENDERS_SUCCESS = "GET_EEO_GENDERS_SUCCESS"
export const GET_EEO_GENDERS_ERROR = "GET_EEO_GENDERS_ERROR"
export const GET_EEO_GENDER_REQUEST = "GET_EEO_GENDER_REQUEST"
export const GET_EEO_GENDER_SUCCESS = "GET_EEO_GENDER_SUCCESS"
export const GET_EEO_GENDER_ERROR = "GET_EEO_GENDER_ERROR"
export const CREATE_EEO_GENDER_REQUEST = "CREATE_EEO_GENDER_REQUEST"
export const CREATE_EEO_GENDER_SUCCESS = "CREATE_EEO_GENDER_SUCCESS"
export const CREATE_EEO_GENDER_ERROR = "CREATE_EEO_GENDER_ERROR"
export const EDIT_EEO_GENDER_REQUEST = "EDIT_EEO_GENDER_REQUEST"
export const EDIT_EEO_GENDER_SUCCESS = "EDIT_EEO_GENDER_SUCCESS"
export const EDIT_EEO_GENDER_ERROR = "EDIT_EEO_GENDER_ERROR"
export const DELETE_EEO_GENDER_REQUEST = "DELETE_EEO_GENDER_REQUEST"
export const DELETE_EEO_GENDER_SUCCESS = "DELETE_EEO_GENDER_SUCCESS"
export const DELETE_EEO_GENDER_ERROR = "DELETE_EEO_GENDER_ERROR"