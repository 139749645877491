export const GET_WAS_RECONCILIATIONS_REQUEST = "GET_WAS_RECONCILIATIONS_REQUEST"
export const GET_WAS_RECONCILIATIONS_SUCCESS = "GET_WAS_RECONCILIATIONS_SUCCESS"
export const GET_WAS_RECONCILIATIONS_ERROR = "GET_WAS_RECONCILIATIONS_ERROR"
export const GET_WAS_RECONCILIATION_REQUEST = "GET_WAS_RECONCILIATION_REQUEST"
export const GET_WAS_RECONCILIATION_SUCCESS = "GET_WAS_RECONCILIATION_SUCCESS"
export const GET_WAS_RECONCILIATION_ERROR = "GET_WAS_RECONCILIATION_ERROR"
export const CREATE_WAS_RECONCILIATION_REQUEST = "CREATE_WAS_RECONCILIATION_REQUEST"
export const CREATE_WAS_RECONCILIATION_SUCCESS = "CREATE_WAS_RECONCILIATION_SUCCESS"
export const CREATE_WAS_RECONCILIATION_ERROR = "CREATE_WAS_RECONCILIATION_ERROR"
export const EDIT_WAS_RECONCILIATION_REQUEST = "EDIT_WAS_RECONCILIATION_REQUEST"
export const EDIT_WAS_RECONCILIATION_SUCCESS = "EDIT_WAS_RECONCILIATION_SUCCESS"
export const EDIT_WAS_RECONCILIATION_ERROR = "EDIT_WAS_RECONCILIATION_ERROR"
export const DELETE_WAS_RECONCILIATION_REQUEST = "DELETE_WAS_RECONCILIATION_REQUEST"
export const DELETE_WAS_RECONCILIATION_SUCCESS = "DELETE_WAS_RECONCILIATION_SUCCESS"
export const DELETE_WAS_RECONCILIATION_ERROR = "DELETE_WAS_RECONCILIATION_ERROR"