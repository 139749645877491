export const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST"
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS"
export const GET_COMMENTS_ERROR = "GET_COMMENTS_ERROR"
export const GET_COMMENT_REQUEST = "GET_COMMENT_REQUEST"
export const GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS"
export const GET_COMMENT_ERROR = "GET_COMMENT_ERROR"
export const CREATE_COMMENT_REQUEST = "CREATE_COMMENT_REQUEST"
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS"
export const CREATE_COMMENT_ERROR = "CREATE_COMMENT_ERROR"
export const EDIT_COMMENT_REQUEST = "EDIT_COMMENT_REQUEST"
export const EDIT_COMMENT_SUCCESS = "EDIT_COMMENT_SUCCESS"
export const EDIT_COMMENT_ERROR = "EDIT_COMMENT_ERROR"
export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST"
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS"
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR"
export const CLEAR_COMMENT_STATE = "CLEAR_COMMENT_STATE"