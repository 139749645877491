import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const combinedWASService = {
    async getCombinedWASs() {
        const getCombinedWASsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/combined-was", getCombinedWASsRequest)
    },
    async getCombinedWAS(uuid) {
        const getCombinedWASRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/combined-was/${uuid}`, getCombinedWASRequest)
    },
    async createCombinedWAS(params) {
        const createData = processInput(params)
        const createCombinedWASRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/combined-was", createCombinedWASRequest)
    },
    async editCombinedWAS(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCombinedWASRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/combined-was/${uuid}`, editCombinedWASRequest)
    },
    async deleteCombinedWAS(params) {
        const { uuid } = params
        delete params.uuid
        let deleteData = processQueryParams(params)
        const deleteCombinedWASRequest = {
            method: "DELETE",
            query: deleteData
        }
        return await apiRequest(`/api/combined-was/${uuid}`, deleteCombinedWASRequest)
    }
}