import {
    CREATE_ALERT_SUCCESS,
    CREATE_ALERT_ERRORS
} from "@/actions"

import { generateKey } from "@/utilities"

const state = {
    alerts: [],
    appLoader: false
}

const getters = {
    alerts: state => state.alerts,
    hasLoader: state => state.appLoader,
}

const actions = {
    async createAlert({ commit }, alert) {
        commit("CREATE_ALERT", alert)
        return true
    },
    createAlertSuccess({ commit }, message) {
        commit("CREATE_ALERT_SUCCESS", message)
    },
    createAlertErrors({ commit }, errors) {
        commit("CREATE_ALERT_ERRORS", errors)
    },
    hideLoader({ commit }){
        commit('HIDE_LOADER')
    },
    showLoader({ commit }){
        commit('SHOW_LOADER')
    },
    clearStoreAlerts({ commit }) {
        commit("CLEAR_ALERTS")
    },
    removeAlertFromStore({ commit }, key) {
        commit("REMOVE_ALERT_FROM_STORE", key)
    }
}

const mutations = {
    CREATE_ALERT: (state, alert) => {
        let newAlert = {
            type: alert.type,
            message: alert.message,
            key: alert.key || generateKey()
        }

        state.alerts.push(newAlert)
    },
    [CREATE_ALERT_SUCCESS]: (state, message) => {
        let alert = {
            type: "success",
            key: generateKey(),
            message
        }
        state.alerts.push(alert)
    },
    [CREATE_ALERT_ERRORS]: (state, errors) => {
        function outputAlert(message) {
            let alert = {
                type: "danger",
                key: generateKey(),
                message
            }
            
            state.alerts.push(alert)
            return true
        }

        function isVariableArray(variable) {
            if (typeof (variable) == 'object' && Array.isArray(variable)) return true
            return false
        }

        function isVariableObject(variable) {
            if (typeof (variable) == 'object' && Object.prototype.toString.call(variable) == '[object Object]') return true
            return false
        }

        if (typeof (errors) == 'string') {
            outputAlert(errors)
            return
        }

        if (isVariableArray(errors)) {
            errors.forEach(message => {
                outputAlert(message)
            })
            return
        }

        if (isVariableObject(errors)) {
            let keyArray = Object.keys(errors)

            keyArray.forEach(key => {
                let value = errors[key]
                
                if (typeof (value) == 'string') {
                    outputAlert(value)
                    return
                }
                if (isVariableArray(value)) {
                    value.forEach(v => outputAlert(v))
                    return
                }
            })

            return
        }
    },
    SHOW_LOADER: state => {
        state.appLoader  = true
    },
    HIDE_LOADER: state => {
        state.appLoader  = false
    },
    CLEAR_ALERTS: state => {
        state.alerts = []
    },
    REMOVE_ALERT_FROM_STORE: (state, key) => {
        state.alerts = state.alerts.filter(a => a.key !== key)
    }
}

export const alert = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}