import {
    GET_TASK_SETTINGS_REQUEST,
    GET_TASK_SETTINGS_SUCCESS,
    GET_TASK_SETTINGS_ERROR,
    GET_TASK_SETTING_REQUEST,
    GET_TASK_SETTING_SUCCESS,
    GET_TASK_SETTING_ERROR,
    CREATE_TASK_SETTING_REQUEST,
    CREATE_TASK_SETTING_SUCCESS,
    CREATE_TASK_SETTING_ERROR,
    EDIT_TASK_SETTING_REQUEST,
    EDIT_TASK_SETTING_SUCCESS,
    EDIT_TASK_SETTING_ERROR,
    DELETE_TASK_SETTING_REQUEST,
    DELETE_TASK_SETTING_SUCCESS,
    DELETE_TASK_SETTING_ERROR,
    BROADCAST_DELETE_TASK_SETTING_SUCCESS,
} from "@/actions"

import {
    taskSettingService
} from "@/services"

import { addNewFilter, isEmpty, onlyUnique } from "@/utilities";

const state = {
    error: null,
    loading: false,
    loadedOnce: false,
    taskSettingLoadedOnce: false,
    taskSettings: [],
    taskSetting: {},
    targetOpts: [
        {label: 'Coach', value: 'coach', group: 'Coach', group_slug: 'coach'},

        {label: 'Lead Coach', value: 'lead', group: 'Lead Coach', group_slug: 'lead-pa'},
        {label: 'PA', value: 'pa', group: 'Lead Coach', group_slug: 'lead-pa'},
        {label: 'Lead & PA', value: 'lead-and-pa', group: 'Lead Coach', group_slug: 'lead-pa'},
        {label: 'PA/Lead', value: 'pa-or-lead', group: 'Lead Coach', group_slug: 'lead-pa'},

        {label: 'Admin Groups', value: 'admin', group: 'Admin Groups', group_slug: 'admin'},

        {label: 'Setting', value: 'setting', group: 'Setting', group_slug: 'setting'},
    ],
}

const getters = {
    facultysLoading: state => state.loading,
    facultysLoadedOnce: state => state.loadedOnce,
    taskSettings: state => state.taskSettings.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),
    taskSettingByUuid: state => uuid => state.taskSettings.find(i => i.uuid == uuid),
    taskSettingsBySettingUuid: state => uuid => state.taskSettings.filter(i => i.setting_uuid == uuid),
    targetOpts: state => state.targetOpts,
    targetOptsMapped: state => {
        const tasks = state.taskSettings.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        const group_slugs = state.targetOpts.map(i => i.group_slug).filter(onlyUnique)
        return group_slugs.map(i => {
            let group = state.targetOpts.find(j => j.group_slug == i)
            let groupValues = state.targetOpts.filter(j => j.group_slug == group.group_slug).map(m => m.value)
            return {
                label: group?.group,
                group_slug: group?.group_slug,
                data: tasks.filter(j => groupValues.some(k => k == j.target)),
            }
        })
    },
}

const actions = {
    async getTaskSettings({ commit }) {
        commit("GET_TASK_SETTINGS_REQUEST")
        const response = await taskSettingService.getTaskSettings();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_TASK_SETTINGS_SUCCESS", data)
                break
            default:
                commit("GET_TASK_SETTINGS_ERROR", data)
        }
        return response
    },

    async getTaskSetting({ commit }, params) {
        commit("GET_TASK_SETTING_REQUEST")
        const response = await taskSettingService.getTaskSetting(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_TASK_SETTING_SUCCESS", data)
                break
            default:
                commit("GET_TASK_SETTING_ERROR", data)
                break
        }
        return response
    },
    async createTaskSetting({ commit, dispatch }, params) {
        commit("CREATE_TASK_SETTING_REQUEST")
        const response = await taskSettingService.createTaskSetting(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_TASK_SETTING_SUCCESS", data) : commit("CREATE_TASK_SETTING_ERROR", data)
                break
            default:
                commit("CREATE_TASK_SETTING_ERROR", data)
        }
        return response
    },
    async editTaskSetting({ commit }, params) {
        commit("EDIT_TASK_SETTING_REQUEST")
        const response = await taskSettingService.editTaskSetting(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_TASK_SETTING_SUCCESS", data)
                break
            default:
                commit("EDIT_TASK_SETTING_ERROR", data)
        }
        return response
    },
    async deleteTaskSetting({ commit }, params) {
        commit("DELETE_TASK_SETTING_REQUEST")
        const uuid = params.uuid
        const response = await taskSettingService.deleteTaskSetting(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit(DELETE_TASK_SETTING_SUCCESS, uuid) : commit("DELETE_TASK_SETTING_ERROR", data)
                break
            default:
                commit("DELETE_TASK_SETTING_ERROR", data)
        }
        return response
    },
}

const mutations = {
    [GET_TASK_SETTINGS_REQUEST]: state => {
        state.loading = true
    },
    [GET_TASK_SETTINGS_SUCCESS]: (state, data) => {
        state.taskSettings = addNewFilter(state.taskSettings, data.task_settings)
        state.taskSettingLoadedOnce = true
        state.loading = false
        state.loadedOnce = true
    },
    [GET_TASK_SETTINGS_ERROR]: state => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [GET_TASK_SETTING_REQUEST]: state => {
        state.loading = true
    },
    [GET_TASK_SETTING_SUCCESS]: (state, data) => {
        let { task_setting } = data
        state.taskSetting = task_setting
        state.loading = false
    },
    [GET_TASK_SETTING_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_TASK_SETTING_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_TASK_SETTING_SUCCESS]: (state, data) => {
        let { task_settings } = data
        state.taskSettings = addNewFilter(state.taskSettings, task_settings)
        state.loading = false
    },
    [CREATE_TASK_SETTING_ERROR]: state => {
        state.loading = false
    },

    [EDIT_TASK_SETTING_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_TASK_SETTING_SUCCESS]: (state, data) => {
        state.taskSettings = state.taskSettings.filter(i => i.uuid != data.task_setting.uuid)
        state.taskSettings.push(data.task_setting)
        state.loading = false
    },
    [EDIT_TASK_SETTING_ERROR]: state => {
        state.loading = false
    },

    [DELETE_TASK_SETTING_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_TASK_SETTING_SUCCESS]: (state, uuid) => {
        state.taskSettings = state.taskSettings.filter(i => i.uuid != uuid)
        state.loading = false
    },
    [BROADCAST_DELETE_TASK_SETTING_SUCCESS]: (state, uuid) => {
        state.taskSettings = state.taskSettings.filter(i => i.uuid != uuid)
        state.loading = false
    },
    [DELETE_TASK_SETTING_ERROR]: (state, data) => {
        state.loading = false
    },
}

export const taskSetting = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
