import {
    GET_COACH_LMS_EXPERIENCES_REQUEST,
    GET_COACH_LMS_EXPERIENCES_SUCCESS,
    GET_COACH_LMS_EXPERIENCES_ERROR,
    GET_COACH_LMS_EXPERIENCE_REQUEST,
    GET_COACH_LMS_EXPERIENCE_SUCCESS,
    GET_COACH_LMS_EXPERIENCE_ERROR,
    CREATE_COACH_LMS_EXPERIENCE_REQUEST,
    CREATE_COACH_LMS_EXPERIENCE_SUCCESS,
    CREATE_COACH_LMS_EXPERIENCE_ERROR,
    EDIT_COACH_LMS_EXPERIENCE_REQUEST,
    EDIT_COACH_LMS_EXPERIENCE_SUCCESS,
    EDIT_COACH_LMS_EXPERIENCE_ERROR,
    DELETE_COACH_LMS_EXPERIENCE_REQUEST,
    DELETE_COACH_LMS_EXPERIENCE_SUCCESS,
    BROADCAST_DELETE_COACH_LMS_EXPERIENCE_SUCCESS,
    DELETE_COACH_LMS_EXPERIENCE_ERROR,
    CLEAR_COACH_LMS_EXPERIENCE_STATE
} from "@/actions"

import {
    coachLmsExperienceService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    coachLmsExperiences: [],
    coachLmsExperience: {},
    loading: false,
    loadedOnce: false
}

const getters = {
    cLMSELoadedOnce: state => state.loadedOnce,
    coachLMSExpStudent: (state, getters, rootState, rootGetters) => state.coachLmsExperiences.filter(clmse => {
        return rootGetters['coaches/coach']['uuid'] == clmse.coaches_uuid && clmse.experience_as == 'student'
    }),
    coachLMSExpTeacher: (state, getters, rootState, rootGetters) => state.coachLmsExperiences.filter(clmse => rootGetters['coaches/coach']['uuid'] == clmse.coaches_uuid && clmse.experience_as == 'teacher'),
    clmseTeachByLMSUuid: (state, getters, rootState, rootGetters) => state.coachLmsExperiences.filter(clmse => {
        return rootGetters['coaches/coach']['uuid'] == clmse.coaches_uuid && clmse.experience_as == 'teacher'
    }).map(clmse => clmse.lms_uuid),
    clmseStudByLMSUuid: (state, getters, rootState, rootGetters) => state.coachLmsExperiences.filter(clmse => {
        return rootGetters['coaches/coach']['uuid'] == clmse.coaches_uuid && clmse.experience_as == 'student'
    }).map(clmse => clmse.lms_uuid),
    findClmsetByLMSUuid: (state, getters, rootState, rootGetters) => uuid => state.coachLmsExperiences.find(clmse => rootGetters['coaches/coach']['uuid'] == clmse.coaches_uuid && clmse.lms_uuid == uuid && clmse.experience_as == 'teacher'),
    findClmsesByLMSUuid: (state, getters, rootState, rootGetters) => uuid => state.coachLmsExperiences.find(clmse => rootGetters['coaches/coach']['uuid'] == clmse.coaches_uuid && clmse.lms_uuid == uuid && clmse.experience_as == 'student')
}

const actions = {
    async getCoachLmsExperiences({ commit }, params) {
        commit("GET_COACH_LMS_EXPERIENCES_REQUEST")
        const response = await coachLmsExperienceService.getCoachLmsExperiences(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_LMS_EXPERIENCES_SUCCESS", data)
                break
            default:
                commit("GET_COACH_LMS_EXPERIENCES_ERROR", data)
        }
        return response
    },
    async getCoachLmsExperience({ commit }, params) {
        commit("GET_COACH_LMS_EXPERIENCE_REQUEST")
        const response = await coachLmsExperienceService.getCoachLmsExperience(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_LMS_EXPERIENCE_SUCCESS", data)
                break
            default:
                commit("GET_COACH_LMS_EXPERIENCE_ERROR", data)
                break
        }
        return response
    },
    async createCoachLmsExperience({ commit }, params) {
        commit("CREATE_COACH_LMS_EXPERIENCE_REQUEST")
        const response = await coachLmsExperienceService.createCoachLmsExperience(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COACH_LMS_EXPERIENCE_SUCCESS", data)
                break
            default:
                commit("CREATE_COACH_LMS_EXPERIENCE_ERROR", data)
        }
        return response
    },
    async editCoachLmsExperience({ commit }, params, uuid) {
        commit("EDIT_COACH_LMS_EXPERIENCE_REQUEST")
        const response = await coachLmsExperienceService.editCoachLmsExperience(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COACH_LMS_EXPERIENCE_SUCCESS", data)
                break
            default:
                commit("EDIT_COACH_LMS_EXPERIENCE_ERROR", data)
        }
        return response
    },
    async deleteCoachLmsExperience({ commit }, uuid) {
        commit("DELETE_COACH_LMS_EXPERIENCE_REQUEST")
        const response = await coachLmsExperienceService.deleteCoachLmsExperience(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COACH_LMS_EXPERIENCE_SUCCESS", uuid)
                break
            default:
                commit("DELETE_COACH_LMS_EXPERIENCE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_LMS_EXPERIENCES_REQUEST]: state => {
        state.loading = true
        state.cLMSLoadedOnce = false
    },
    [GET_COACH_LMS_EXPERIENCES_SUCCESS]: (state, data) => {
        const newCoachLmsExperiences = data.coach_lms_experiences
        state.coachLmsExperiences = addNewFilter(state.coachLmsExperiences, newCoachLmsExperiences)
        state.loading = false,
            state.loadedOnce = true
    },
    [GET_COACH_LMS_EXPERIENCES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COACH_LMS_EXPERIENCE_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_LMS_EXPERIENCE_SUCCESS]: (state, data) => {
        let coachLmsExperience = { ...data.coach_lms_experience }
        state.coachLmsExperience = coachLmsExperience
        state.loading = false
    },
    [GET_COACH_LMS_EXPERIENCE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_LMS_EXPERIENCE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_LMS_EXPERIENCE_SUCCESS]: (state, data) => {
        let coachLmsExperience = { ...data.coach_lms_experience }
        state.coachLmsExperiences.push(coachLmsExperience)
        state.loading = false
    },
    [CREATE_COACH_LMS_EXPERIENCE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_LMS_EXPERIENCE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_LMS_EXPERIENCE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COACH_LMS_EXPERIENCE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_LMS_EXPERIENCE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_LMS_EXPERIENCE_SUCCESS]: (state, uuid) => {
        state.coachLmsExperiences = state.coachLmsExperiences.filter(clmse => clmse.uuid !== uuid)
        state.loading = false
    },
    [BROADCAST_DELETE_COACH_LMS_EXPERIENCE_SUCCESS]: (state, data) => {
        const { coach_lms_experience } = data
        const { uuid } = coach_lms_experience
        if(!isEmpty(uuid)){
            state.coachLmsExperiences = state.coachLmsExperiences.filter(ctp => ctp.uuid !== uuid)
        }
        state.loading = false
    },
    [DELETE_COACH_LMS_EXPERIENCE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COACH_LMS_EXPERIENCE_STATE]: (state) => {
        // state.coachLmsExperiences = []
        // state.coachLmsExperience = {}
        // state.loading = false,
        // state.loadedOnce = false
    }
}

export const coachLmsExperience = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}