export const GET_OPMS_REQUEST = "GET_OPMS_REQUEST"
export const GET_OPMS_SUCCESS = "GET_OPMS_SUCCESS"
export const GET_OPMS_ERROR = "GET_OPMS_ERROR"
export const GET_OPM_REQUEST = "GET_OPM_REQUEST"
export const GET_OPM_SUCCESS = "GET_OPM_SUCCESS"
export const GET_OPM_ERROR = "GET_OPM_ERROR" 
export const CREATE_OPM_REQUEST = "CREATE_OPM_REQUEST"
export const CREATE_OPM_SUCCESS = "CREATE_OPM_SUCCESS"
export const CREATE_OPM_ERROR = "CREATE_OPM_ERROR"
export const EDIT_OPM_REQUEST = "EDIT_OPM_REQUEST"
export const EDIT_OPM_SUCCESS = "EDIT_OPM_SUCCESS"
export const EDIT_OPM_ERROR = "EDIT_OPM_ERROR"
export const DELETE_OPM_REQUEST = "DELETE_OPM_REQUEST"
export const DELETE_OPM_SUCCESS = "DELETE_OPM_SUCCESS"
export const DELETE_OPM_ERROR = "DELETE_OPM_ERROR"