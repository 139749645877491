import {
    GET_UNIVERSITY_OPMS_REQUEST,
    GET_UNIVERSITY_OPMS_SUCCESS,
    GET_UNIVERSITY_OPMS_ERROR,
    GET_UNIVERSITY_OPM_REQUEST,
    GET_UNIVERSITY_OPM_SUCCESS,
    GET_UNIVERSITY_OPM_ERROR,
    CREATE_UNIVERSITY_OPM_REQUEST,
    CREATE_UNIVERSITY_OPM_SUCCESS,
    CREATE_UNIVERSITY_OPM_ERROR,
    EDIT_UNIVERSITY_OPM_REQUEST,
    EDIT_UNIVERSITY_OPM_SUCCESS,
    EDIT_UNIVERSITY_OPM_ERROR,
    DELETE_UNIVERSITY_OPM_REQUEST,
    DELETE_UNIVERSITY_OPM_SUCCESS,
    DELETE_UNIVERSITY_OPM_ERROR
} from "@/actions"

import {
    universityOpmsService
} from "@/services"

import { addNewFilter } from "@/utilities"

const state = {
    universityOpms: [],
    universityOpm: {},
    loading: false
}

const getters = {
    universityOpmFilterByUniversity: state => uuid => state.universityOpms.filter(upm => upm.universities_uuid == uuid),
    universityOpmFilterByUniversityUuid: state => uuid => state.universityOpms.filter(upm => upm.universities_uuid == uuid).map(uo => uo.opm.uuid),
    universityOpmFilterByUniversityLabel: state => uuid => state.universityOpms.filter(upm => upm.universities_uuid == uuid).map(uo => uo.opm.name),
    universityOpmFilter: state => uuid => state.universityOpms.filter(upm => uuid.includes(upm.uuid))
}

const actions = {
    async getUniversityOpms({ commit }) {
        commit("GET_UNIVERSITY_OPMS_REQUEST")
        const response = await universityOpmsService.getUniversityOpms();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_OPMS_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_OPMS_ERROR", data)
        }
        return response
    },
    async getUniversityOpm({ commit }, params) {
        commit("GET_UNIVERSITY_OPM_REQUEST")
        const response = await universityOpmsService.getUniversityOpm(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_OPM_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_OPM_ERROR", data)
                break
        }
        return response
    },
    async createUniversityOpm({ commit }, params) {
        commit("CREATE_UNIVERSITY_OPM_REQUEST")
        const response = await universityOpmsService.createUniversityOpm(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_UNIVERSITY_OPM_SUCCESS", data)
                break
            default:
                commit("CREATE_UNIVERSITY_OPM_ERROR", data)
        }
        return response
    },
    async editUniversityOpm({ commit }, params) {
        commit("EDIT_UNIVERSITY_OPM_REQUEST")
        const response = await universityOpmsService.editUniversityOpm(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_UNIVERSITY_OPM_SUCCESS", data)
                break
            default:
                commit("EDIT_UNIVERSITY_OPM_ERROR", data)
        }
        return response
    },
    async deleteUniversityOpm({ commit }, params) {
        commit("DELETE_UNIVERSITY_OPM_REQUEST")
        const response = await universityOpmsService.deleteUniversityOpm(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_UNIVERSITY_OPM_SUCCESS", data)
                break
            default:
                commit("DELETE_UNIVERSITY_OPM_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_UNIVERSITY_OPMS_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_OPMS_SUCCESS]: (state, data) => {
        let mdata = [...data.data]
        state.universityOpms = addNewFilter(state.universityOpms, mdata)
        state.loading = false

    },
    [GET_UNIVERSITY_OPMS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_UNIVERSITY_OPM_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_OPM_SUCCESS]: (state, data) => {
        let mdata = { ...data.data }
        state.universityOpm = mdata
        state.loading = false
    },
    [GET_UNIVERSITY_OPM_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_UNIVERSITY_OPM_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_UNIVERSITY_OPM_SUCCESS]: (state, data) => {
        state.universityOpms.push(data.data)
        state.loading = false
    },
    [CREATE_UNIVERSITY_OPM_ERROR]: state => {
        state.loading = false
    },

    [EDIT_UNIVERSITY_OPM_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_UNIVERSITY_OPM_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_UNIVERSITY_OPM_ERROR]: state => {
        state.loading = false
    },

    [DELETE_UNIVERSITY_OPM_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_UNIVERSITY_OPM_SUCCESS]: (state, data) => {
        state.loading = false
        let deletedUuid = data.success
        state.universityOpms = state.universityOpms.filter(d => d.uuid != deletedUuid)
    },
    [DELETE_UNIVERSITY_OPM_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const universityOpm = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}