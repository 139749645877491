import {
    GET_EEO_GENDERS_REQUEST,
    GET_EEO_GENDERS_SUCCESS,
    GET_EEO_GENDERS_ERROR,
    GET_EEO_GENDER_REQUEST,
    GET_EEO_GENDER_SUCCESS,
    GET_EEO_GENDER_ERROR,
    CREATE_EEO_GENDER_REQUEST,
    CREATE_EEO_GENDER_SUCCESS,
    CREATE_EEO_GENDER_ERROR,
    EDIT_EEO_GENDER_REQUEST,
    EDIT_EEO_GENDER_SUCCESS,
    EDIT_EEO_GENDER_ERROR,
    DELETE_EEO_GENDER_REQUEST,
    DELETE_EEO_GENDER_SUCCESS,
    DELETE_EEO_GENDER_ERROR
} from "../../actions"

import {
    eeoGenderService
} from "../../services"

const state = {
    eeoGenders: [],
    eeoGender: {},
    loading: false
}

const getters = {
    eeoGenderSelectOptions: state => state.eeoGenders.map((eeoGender) => ({ value: eeoGender.uuid, label: eeoGender.label }))
}

const actions = {
    async getEEOGenders({ commit }) {
        commit("GET_EEO_GENDERS_REQUEST")
        const response = await eeoGenderService.getEEOGenders();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_EEO_GENDERS_SUCCESS", data)
                break
            default:
                commit("GET_EEO_GENDERS_ERROR", data)
        }
        return response
    },
    async getEEOGender({ commit }, params) {
        commit("GET_EEO_GENDER_REQUEST")
        const response = await eeoGenderService.getEEOGender(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_EEO_GENDER_SUCCESS", data)
                break
            default:
                commit("GET_EEO_GENDER_ERROR", data)
                break
        }
        return response
    },
    async createEEOGender({ commit }, params) {
        commit("CREATE_EEO_GENDER_REQUEST")
        const response = await eeoGenderService.createEEOGender(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_EEO_GENDER_SUCCESS", data)
                break
            default:
                commit("CREATE_EEO_GENDER_ERROR", data)
        }
        return response
    },
    async editEEOGender({ commit }, params, uuid) {
        commit("EDIT_EEO_GENDER_REQUEST")
        const response = await eeoGenderService.editEEOGender(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_EEO_GENDER_SUCCESS", data)
                break
            default:
                commit("EDIT_EEO_GENDER_ERROR", data)
        }
        return response
    },
    async deleteEEOGender({ commit }, params) {
        commit("DELETE_EEO_GENDER_REQUEST")
        const response = await eeoGenderService.deleteEEOGender(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_EEO_GENDER_SUCCESS", data)
                break
            default:
                commit("DELETE_EEO_GENDER_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_EEO_GENDERS_REQUEST]: state => {
        state.loading = true
    },
    [GET_EEO_GENDERS_SUCCESS]: (state, data) => {
        const eeoGenders = data.eeo_genders
        state.eeoGenders = eeoGenders
        state.loading = false
    },
    [GET_EEO_GENDERS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_EEO_GENDER_REQUEST]: state => {
        state.loading = true
    },
    [GET_EEO_GENDER_SUCCESS]: (state, data) => {
        const eeoGender = { ...data.eeo_gender }
        state.eeoGender = eeoGender
        state.loading = false
    },
    [GET_EEO_GENDER_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_EEO_GENDER_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_EEO_GENDER_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [CREATE_EEO_GENDER_ERROR]: state => {
        state.loading = false
    },

    [EDIT_EEO_GENDER_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_EEO_GENDER_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_EEO_GENDER_ERROR]: state => {
        state.loading = false
    },

    [DELETE_EEO_GENDER_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_EEO_GENDER_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_EEO_GENDER_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const eeoGender = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}