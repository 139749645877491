import {
  sharedQueueItemService
} from "@/services"

import {
  addNewFilter
} from "@/utilities"

const state = {
  queueItems: [],
  loading: false
}

const getters = {
  queueItems: state => qUuid => state.queueItems.filter(q => q.shared_queues_uuid == qUuid)
}

const actions = {
  async getSharedQueueItems({ commit }) {
    commit("GET_SHARED_QUEUE_ITEMS_REQUEST")
    const response = await sharedQueueItemService.getSharedQueueItems();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_SHARED_QUEUE_ITEMS_SUCCESS", data)
        break
      default:
        commit("GET_SHARED_QUEUE_ITEMS_ERROR", data)
    }
    return response
  },
  async getSharedQueueItem({ commit }, params) {
    commit("GET_SHARED_QUEUE_ITEM_REQUEST")
    const response = await sharedQueueItemService.getSharedQueueItem(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("GET_SHARED_QUEUE_ITEM_SUCCESS", data) : null
        break
      default:
        commit("GET_SHARED_QUEUE_ITEM_ERROR", data)
        break
    }
    return response
  },
  async createSharedQueueItem({ commit, dispatch }, params) {
    commit("CREATE_COMBINED_WAS_GROUP_ITEM_REQUEST")
    const response = await sharedQueueItemService.createSharedQueueItem(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data?.success ? commit("CREATE_COMBINED_WAS_GROUP_ITEM_SUCCESS", data) : null;
        data?.success ? dispatch("alert/createAlertSuccess", "Lead coach added to shared queue.", { root: true }) : null
        break
      default:
        commit("CREATE_COMBINED_WAS_GROUP_ITEM_ERROR", data)
    }
    return response
  },
  async editSharedQueueItem({ commit, dispatch }, params, uuid) {
    commit("EDIT_COMBINED_WAS_GROUP_ITEM_REQUEST")
    const response = await sharedQueueItemService.editSharedQueueItem(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data?.success ? commit("EDIT_COMBINED_WAS_GROUP_ITEM_SUCCESS", data) : null;
        break
      default:
        commit("EDIT_COMBINED_WAS_GROUP_ITEM_ERROR", data)
    }
    return response
  },
  async deleteSharedQueueItem({ commit, dispatch }, params) {
    const { uuid } = params;
    commit("DELETE_COMBINED_WAS_GROUP_ITEM_REQUEST")
    const response = await sharedQueueItemService.deleteSharedQueueItem(params)
    const { status, data } = response;
    switch (status) {
      case 200:
        data?.success ? commit("DELETE_COMBINED_WAS_GROUP_ITEM_SUCCESS", { uuid }) : null;
        data?.success ? dispatch("alert/createAlertSuccess", "Lead coach removed from shared queue.", { root: true }) : null;
        break
      default:
        commit("DELETE_COMBINED_WAS_GROUP_ITEM_ERROR", data)
    }
    return response
  }
}

const mutations = {
  GET_SHARED_QUEUE_ITEMS_REQUEST: state => {
    state.loading = true;
  },
  GET_SHARED_QUEUE_ITEMS_SUCCESS: (state, data) => {
    state.queueItems = addNewFilter(state.queueItems, data?.items ?? []);
    state.loading = false;
  },
  GET_SHARED_QUEUE_ITEMS_ERROR: state => {
    state.loading = false;
    state.error = true;
  },
  GET_SHARED_QUEUE_ITEM_REQUEST: state => {
    state.loading = true;
  },
  GET_SHARED_QUEUE_ITEM_SUCCESS: (state, data) => {
    state.loading = false;
  },
  GET_SHARED_QUEUE_ITEM_ERROR: (state, data) => {
    state.loading = false;
  },
  CREATE_COMBINED_WAS_GROUP_ITEM_REQUEST: state => {
    state.loading = true;
  },
  CREATE_COMBINED_WAS_GROUP_ITEM_SUCCESS: (state, data) => {
    const { item } = data;
    state.queueItems = addNewFilter(state.queueItems, [item]);
    state.loading = false;
  },
  CREATE_COMBINED_WAS_GROUP_ITEM_ERROR: state => {
    state.loading = false;
  },
  EDIT_COMBINED_WAS_GROUP_ITEM_REQUEST: state => {
    state.loading = true;
  },
  EDIT_COMBINED_WAS_GROUP_ITEM_SUCCESS: state => {
    state.loading = false;
  },
  EDIT_COMBINED_WAS_GROUP_ITEM_ERROR: state => {
    state.loading = false;
  },
  DELETE_COMBINED_WAS_GROUP_ITEM_REQUEST: state => {
    state.loading = true;
  },
  DELETE_COMBINED_WAS_GROUP_ITEM_SUCCESS: (state, data) => {
    const { uuid } = data;
    state.queueItems = state.queueItems.filter(qi => qi.uuid != uuid);
    state.loading = false;
  },
  DELETE_COMBINED_WAS_GROUP_ITEM_ERROR: (state, data) => {
    state.loading = false;
  }
}

export const sharedQueueItem = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

