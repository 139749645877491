export const GET_COACH_EEOS_REQUEST = "GET_COACH_EEOS_REQUEST"
export const GET_COACH_EEOS_SUCCESS = "GET_COACH_EEOS_SUCCESS"
export const GET_COACH_EEOS_ERROR = "GET_COACH_EEOS_ERROR"
export const GET_COACH_EEO_REQUEST = "GET_COACH_EEO_REQUEST"
export const GET_COACH_EEO_SUCCESS = "GET_COACH_EEO_SUCCESS"
export const GET_COACH_EEO_ERROR = "GET_COACH_EEO_ERROR"
export const CREATE_COACH_EEO_REQUEST = "CREATE_COACH_EEO_REQUEST"
export const CREATE_COACH_EEO_SUCCESS = "CREATE_COACH_EEO_SUCCESS"
export const CREATE_COACH_EEO_ERROR = "CREATE_COACH_EEO_ERROR"
export const EDIT_COACH_EEO_REQUEST = "EDIT_COACH_EEO_REQUEST"
export const EDIT_COACH_EEO_SUCCESS = "EDIT_COACH_EEO_SUCCESS"
export const EDIT_COACH_EEO_ERROR = "EDIT_COACH_EEO_ERROR"
export const DELETE_COACH_EEO_REQUEST = "DELETE_COACH_EEO_REQUEST"
export const DELETE_COACH_EEO_SUCCESS = "DELETE_COACH_EEO_SUCCESS"
export const DELETE_COACH_EEO_ERROR = "DELETE_COACH_EEO_ERROR"
export const CLEAR_COACH_EEO_STATE = "CLEAR_COACH_EEO_STATE"