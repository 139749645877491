export const GET_COACH_LANGUAGES_REQUEST = "GET_COACH_LANGUAGES_REQUEST"
export const GET_COACH_LANGUAGES_SUCCESS = "GET_COACH_LANGUAGES_SUCCESS"
export const GET_COACH_LANGUAGES_ERROR = "GET_COACH_LANGUAGES_ERROR"
export const GET_COACH_LANGUAGE_REQUEST = "GET_COACH_LANGUAGE_REQUEST"
export const GET_COACH_LANGUAGE_SUCCESS = "GET_COACH_LANGUAGE_SUCCESS"
export const GET_COACH_LANGUAGE_ERROR = "GET_COACH_LANGUAGE_ERROR"
export const CREATE_COACH_LANGUAGE_REQUEST = "CREATE_COACH_LANGUAGE_REQUEST"
export const CREATE_COACH_LANGUAGE_SUCCESS = "CREATE_COACH_LANGUAGE_SUCCESS"
export const CREATE_COACH_LANGUAGE_ERROR = "CREATE_COACH_LANGUAGE_ERROR"
export const EDIT_COACH_LANGUAGE_REQUEST = "EDIT_COACH_LANGUAGE_REQUEST"
export const EDIT_COACH_LANGUAGE_SUCCESS = "EDIT_COACH_LANGUAGE_SUCCESS"
export const EDIT_COACH_LANGUAGE_ERROR = "EDIT_COACH_LANGUAGE_ERROR"
export const DELETE_COACH_LANGUAGE_REQUEST = "DELETE_COACH_LANGUAGE_REQUEST"
export const DELETE_COACH_LANGUAGE_SUCCESS = "DELETE_COACH_LANGUAGE_SUCCESS"
export const BROADCAST_DELETE_COACH_LANGUAGE_SUCCESS = "BROADCAST_DELETE_COACH_LANGUAGE_SUCCESS"
export const DELETE_COACH_LANGUAGE_ERROR = "DELETE_COACH_LANGUAGE_ERROR"
export const CLEAR_COACH_LANGUAGE_STATE = "CLEAR_COACH_LANGUAGE_STATE"