export const GET_EMPLOYMENT_TYPES_REQUEST = "GET_EMPLOYMENT_TYPES_REQUEST"
export const GET_EMPLOYMENT_TYPES_SUCCESS = "GET_EMPLOYMENT_TYPES_SUCCESS"
export const GET_EMPLOYMENT_TYPES_ERROR = "GET_EMPLOYMENT_TYPES_ERROR"
export const GET_EMPLOYMENT_TYPE_REQUEST = "GET_EMPLOYMENT_TYPE_REQUEST"
export const GET_EMPLOYMENT_TYPE_SUCCESS = "GET_EMPLOYMENT_TYPE_SUCCESS"
export const GET_EMPLOYMENT_TYPE_ERROR = "GET_EMPLOYMENT_TYPE_ERROR"
export const CREATE_EMPLOYMENT_TYPE_REQUEST = "CREATE_EMPLOYMENT_TYPE_REQUEST"
export const CREATE_EMPLOYMENT_TYPE_SUCCESS = "CREATE_EMPLOYMENT_TYPE_SUCCESS"
export const CREATE_EMPLOYMENT_TYPE_ERROR = "CREATE_EMPLOYMENT_TYPE_ERROR"
export const EDIT_EMPLOYMENT_TYPE_REQUEST = "EDIT_EMPLOYMENT_TYPE_REQUEST"
export const EDIT_EMPLOYMENT_TYPE_SUCCESS = "EDIT_EMPLOYMENT_TYPE_SUCCESS"
export const EDIT_EMPLOYMENT_TYPE_ERROR = "EDIT_EMPLOYMENT_TYPE_ERROR"
export const DELETE_EMPLOYMENT_TYPE_REQUEST = "DELETE_EMPLOYMENT_TYPE_REQUEST"
export const DELETE_EMPLOYMENT_TYPE_SUCCESS = "DELETE_EMPLOYMENT_TYPE_SUCCESS"
export const DELETE_EMPLOYMENT_TYPE_ERROR = "DELETE_EMPLOYMENT_TYPE_ERROR"