export const universityRoutes = {
    path: 'universities',
    component: () => import("@/pages/university/UniversityHome.vue"),
    children: [
        {
            path: '',
            name: 'universities',
            meta: {
                label: 'Universities'
            },
            component: () => import("@/pages/university/UniversityContractedIndex")
        },
        {
            path: ':uuid',
            name: 'university detail',
            meta: {
                label: 'University Details'
            },
            component: () => import("@/pages/university/UniversityDetail.vue"),
        },
        {
            path: ':uuid/contracts',
            name: 'univesity-contracts',
            meta: {
                label: 'University Contracts'
            },
            component: () => import("@/pages/university/UniversityContracts.vue"),
        },
        {
            path: ':uuid/contract/:sourceUuid',
            name: 'university-contract-detail',
            meta: {
                label: 'University Contract'
            },
            component: () => import("@/pages/sources/UniversitySourceDetails.vue")
        },
        {
            path: ':uuid/prospect',
            name: 'university prospect',
            meta: {
                label: 'University Prospect'
            },
            component: () => import("@/pages/university/UniversityProspect.vue")
        },
        {
            path: ':uuid/comments',
            name: 'university commets',
            meta: {
                label: 'University commets'
            },
            component: () => import("@/pages/university/admin/UniversityAllComments.vue")
        },
        {
            path: 'create/new',
            name: 'university create',
            meta: {
                label: 'Create University'
            },
            component: () => import("@/pages/university/UniversityCreate.vue"),
        },
        {
            path: '/university-programs/:uuid',
            name: 'university-program detail',
            meta: {
                label: 'University Program Details'
            },
            component: () => import("@/pages/university/UniversityProgram.vue"),
        },
        {
            path: '/working-assignment-sheets/:uuid',
            name: 'working-assignment-sheet detail',
            meta: {
                label: 'Working Assignment Sheet Details'
            },
            component: () => import("@/pages/was/WorkingAssignmentSheet.vue"),
            children: [
                {
                    path: 'reconciliation/:reconciliationKey',
                    name: 'was-reconciliation',
                    meta: {
                        label: 'Working Assignment Sheet Reconciliation'
                    },
                    component: () => import("@/pages/was/WorkingAssignmentSheetReconciliation.vue"),
                },
            ]
        },
        {
            path: '/working-assignment-sheets/:uuid',
            name: 'working-assignment-sheet detail',
            meta: {
                label: 'Working Assignment Sheet Details'
            },
            component: () => import("@/pages/was/WorkingAssignmentSheet.vue"),
            children: [
                {
                    path: 'reconciliation/:reconciliationKey',
                    name: 'was-reconciliation',
                    meta: {
                        label: 'Working Assignment Sheet Reconciliation'
                    },
                    component: () => import("@/pages/was/WorkingAssignmentSheetReconciliation.vue"),
                },
            ]
        },
        {
            path: '/working-assignment-sheets/:uuid/:caUuid/comments',
            name: 'working-assignment-sheet-ca-comments',
            meta: {
                label: 'Working Assignment Sheet Coach Assignment Comments'
            },
            component: () => import("@/pages/was/CoachAssignmentsAllComments.vue"),
        },
    ]
}

export const wasMeetingMinutes = {
    path: '/meeting-minutes/:wUuid',
    name: 'was-meeting-minutes',
    component: () => import("@/pages/was/WorkingAssignmentSheetMeetingMinutes.vue"),
    meta: {
        label: 'WAS Meeting Minutes'
    }
}
export const csMeetingMinutes = {
    path: '/meeting-minutes/:wUuid/:csUuid',
    name: 'cs-meeting-minutes',
    component: () => import("@/pages/was/WorkingAssignmentSheetMeetingMinutes.vue"),
    meta: {
        label: 'Course Meeting Minutes'
    }
}
export const coachEvaluation = {
    path: '/coach-evaluations/:csUuid',
    name: 'coach-evaluation',
    component: () => import("@/pages/was/evaluation/CoachEvaluation.vue"),
    meta: {
        label: 'Coach Evaluation'
    }
}
export const facultyEvaluation = {
    path: '/faculty-evaluations/:uuid',
    name: 'faculty-evaluation',
    component: () => import("@/pages/evaluation/FacultyEvaluation.vue"),
    meta: {
        label: 'Coach Evaluation'
    }
}