import {
	INIT_PUSHER,
	INIT_CHANNELS,
	INIT_ADMIN_EVENT_CHANNELS,
	INIT_COACH_PORTAL_EVENT_CHANNELS,
	INIT_PRIVATE_CHANNEL,
	INIT_PREFIX_CHANNELS
} from "@/actions"
import { isEmpty } from "@/utilities"
import { store } from "@/store"

const state = {
	channels: [
		{
			name: "adminPayment",
			module: "adminPayment",
			events: "*",
			mutationKey: "ADMIN_PAYMENT"
		},
		{
			name: "assignmentPayment",
			module: "assignmentPayment",
			events: "*",
			mutationKey: "ASSIGNMENT_PAYMENT"
		},
		{
			name: "comment",
			module: "comments",
			events: "*",
			mutationKey: "COMMENT"
		},
		{
			name: "facultySourceRole",
			module: "facultySourceRole",
			events: "*",
			mutationKey: "FACULTY_SOURCE_ROLE"
		},
		{
			name: "courseStart",
			module: "courseStart",
			events: "*",
			mutationKey: "COURSE_START"
		},
		{
			name: "combinedWASGroup",
			module: "combinedWASGroup",
			events: "*",
			mutationKey: "COMBINED_WAS_GROUP",
			role: []
		},
		{
			name: "combinedWASGroupItem",
			module: "combinedWASGroupItem",
			events: "*",
			mutationKey: "COMBINED_WAS_GROUP_ITEM",
			role: []
		},
		{
			name: "courseStartSection",
			module: "courseStartSection",
			events: "*",
			mutationKey: "COURSE_START_SECTION"
		},
		{
			name: "courseStartFaculty",
			module: "courseStartFaculty",
			events: "*",
			mutationKey: "COURSE_START_FACULTY"
		},
		{
			name: "contractPriceType",
			module: "contractPriceType",
			events: "*",
			mutationKey: "CONTRACT_PRICE_TYPE"
		},
		{
			name: "courseStartReconciliation",
			module: "courseStartReconciliation",
			events: "*",
			mutationKey: "COURSE_START_RECONCILIATION"
		},
		{
			name: "course",
			module: "course",
			events: "*",
			mutationKey: "COURSE"
		},
		{
			name: "taskSetting",
			module: "taskSetting",
			events: "*",
			mutationKey: "TASK_SETTING"
		},
		{
			name: "setting",
			module: "settings",
			events: "*",
			mutationKey: "SETTING"
		},
		{
			name: "coachCertification",
			module: "coachCertifications",
			events: "*",
			mutationKey: "COACH_CERTIFICATION"
		},
		{
			name: "coachApplication",
			module: "coachApplication",
			events: [
				{
					event: "updated",
					mutation: "EDIT_COACH_APPLICATION_SUCCESS"
				}
			]
		},
		{
			name: "coachAssignment",
			module: "coachAssignment",
			events: "*",
			mutationKey: "COACH_ASSIGNMENT",
		},
		{
			name: "backgroundCheck",
			module: "backgroundCheck",
			events: [
				{
					event: "created",
					mutation: "CREATE_COACH_BACKGROUND_CHECK_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_COACH_BACKGROUND_CHECK_SUCCESS"
				},
				{
					event: "deleted",
					mutation: "DELETE_COACH_BACKGROUND_CHECK_SUCCESS"
				},
			]
		},
		{
			name: "transferStudentLog",
			module: "transferStudentLog",
			mutationKey: "TRANSFER_STUDENT",
			events: [
				{
					event: "created",
					mutation: "CREATE_TRANSFER_STUDENT_SUCCESS"
				},
			]
		},
		{
			name: "finalizedStudent",
			module: "finalizedStudent",
			mutationKey: "FINALIZE_STUDENT",
			events: "*"
		},
		{
			name: "coachAssignmentException",
			module: "coachAssignmentException",
			mutationKey: "CA_EXCEPTION",
			events: [
				{
					event: "created",
					mutation: "CREATE_CA_EXCEPTION_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_CA_EXCEPTION_SUCCESS"
				},
				{
					event: "deleted",
					mutation: "DELETE_CA_EXCEPTION_SUCCESS"
				},
			]
		},
		{
			name: "coachAssignmentReconciliation",
			module: "coachAssignmentReconciliation",
			events: [
				{
					event: "created",
					mutation: "CREATE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS"
				},
				{
					event: "deleted",
					mutation: "DELETE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS"
				},
			]
		},
		{
			name: "coachFacultyReferral",
			module: "coachFacultyReferral",
			events: "*",
			mutationKey: "COACH_FACULTY_REF"
		},
		{
			name: "coachEvaluation",
			module: "coachEvaluation",
			events: "*",
			mutationKey: "COACH_EVALUATION"
		},
		{
			name: "facultyEvaluation",
			module: "facultyEvaluation",
			events: "*",
			mutationKey: "FACULTY_EVALUATION"
		},
		{
			name: "coachReference",
			module: "coachReference",
			events: "*",
			mutationKey: "COACH_REFERENCE"
		},
		{
			name: "coachScreeningSession",
			module: "coachScreeningSession",
			events: "*",
			mutationKey: "COACH_SCREENING_SESSION"
		},
		{
			name: "coachEducation",
			module: "coachEducation",
			events: "*",
			mutationKey: "COACH_EDUCATION"
		},
		{
			name: "coachStatus",
			module: "coachStatus",
			events: [
				{
					event: "created",
					mutation: "CREATE_COACH_STATUS_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_COACH_STATUS_SUCCESS"
				},
			]
		},
		{
			name: "coachSpecialty",
			module: "coachSpecialties",
			prefix: true,
			channelPrefix: 'coachesUuid',
			events: [
				{
					event: "created",
					mutation: "CREATE_COACH_SPECIALTY_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_COACH_SPECIALTY_SUCCESS"
				},
				{
					event: "deleted",
					mutation: "DELETE_COACH_SPECIALTY_SUCCESS"
				},
			]
		},
		{
			name: "combinedWAS",
			module: "combinedWAS",
			events: [
				{
					event: "created",
					mutation: "CREATE_COMBINED_WAS_SUCCESS"
				},
				{
					event: "deleting",
					mutation: "DELETE_COMBINED_WAS_SUCCESS"
				},
			],
			mutationKey: "WORKING_ASSIGNMENT_SHEET"
		},

		{
			name: "document",
			module: "document",
			events: "*",
			mutationKey: "DOCUMENT",
		},
		{
			name: "invoice",
			module: "invoice",
			events: "*",
			mutationKey: "INVOICE"
		},
		{
			name: "ia_application",
			module: "iaApplication",
			events: [
				{
					event: "created",
					mutation: "CREATE_IA_APPLICATION_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_IA_APPLICATION_SUCCESS"
				},
				{
					event: "deleted",
					mutation: "DELETE_IA_APPLICATION_SUCCESS"
				},
			],
			mutationKey: "IA_APPLICATION"
		},
		{
			name: "invoice_payment",
			module: "invoicePayment",
			events: "*",
			mutationKey: "INVOICE_PAYMENT",
			role: ["invoice"]
		},
		{
			name: "lcpaa",
			module: "lcpaa",
			events: "*",
			mutationKey: "LCPAA"
		},
		{
			name: "offer",
			module: "offer",
			events: "*",
			mutationKey: "OFFER"
		},
		{
			name: "paymentSchedule",
			module: "paymentSchedule",
			events: "*",
			mutationKey: "PAYMENT_SCHEDULE"
		},
		{
			name: "payroll",
			module: "payroll",
			events: "*",
			mutationKey: "PAYROLL",
			role: ["payroll"]
		},
		{
			name: "phone",
			module: "phone",
			events: "*",
			mutationKey: "PHONE"
		},
		{
			name: "pointOfContact",
			module: "pointOfContact",
			events: [
				{
					event: "created",
					mutation: "CREATE_POINT_OF_CONTACT_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_POINT_OF_CONTACT_SUCCESS"
				},
				{
					event: "deleted",
					mutation: "BROADCAST_DELETE_POINT_OF_CONTACT_SUCCESS"
				},
			],
		},
		{
			name: "reconciliationItem",
			module: "reconciliationItem",
			events: [
				{
					event: "updated",
					mutation: "EDIT_RECONCILIATION_ITEM_SUCCESS"
				}
			]
		},
		{
			name: "task",
			module: "task",
			events: [
				{
					event: "created",
					mutation: "CREATE_TASK_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_TASK_SUCCESS"
				}
			]
		},
		{
			name: "universityStatus",
			module: "universityStatus",
			events: [
				{
					event: "created",
					mutation: "CREATE_UNIVERSITY_STATUS_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_UNIVERSITY_STATUS_SUCCESS"
				}
			]
		},
		{
			name: "universityProspect",
			module: "universityProspect",
			events: "*",
			mutationKey: "UNIVERSITY_PROSPECT"
		},
		{
			name: "universityLMS",
			module: "universityLMS",
			events: "*",
			mutationKey: "UNIVERSITY_LMS"
		},
		{
			name: "universityProspectDocument",
			module: "universityProspectDocument",
			events: "*",
			mutationKey: "UNIVERSITY_PROSPECT_DOCUMENT"
		},
		{
			name: "universityProspectMeeting",
			module: "universityProspectMeeting",
			events: "*",
			mutationKey: "UNIVERSITY_PROSPECT_MEETING"
		},
		{
			name: "universityRfp",
			module: "universityRfp",
			events: "*",
			mutationKey: "UNIVERSITY_RFP"
		},
		{
			name: "universityOpm",
			module: "opm",
			events: "*",
			mutationKey: "OPM"
		},
		{
			name: "universityRegionalAccreditor",
			module: "universityRegionalAccreditor",
			events: "*",
			mutationKey: "UNIVERSITY_REGIONAL_ACCREDITOR"
		},
		{
			name: "universityCensus",
			module: "universityCensus",
			events: "*",
			mutationKey: "UNIVERSITY_CENSUS"
		},
		{
			name: "universityProcedure",
			module: "universityProcedure",
			events: "*",
			mutationKey: "UNIVERSITY_PROCEDURE"
		},
		{
			name: "universityProgram",
			module: "universityProgram",
			events: "*",
			mutationKey: "UNIVERSITY_PROGRAM"
		},
		// {
		// 	name: "coach",
		// 	module: "coaches",
		// 	events: "*",
		// 	mutationKey: "COACH"
		// },
		{
			name: "coach",
			module: "coaches",
			events: [
				{
					event: "dnua-updated",
					mutation: "BROADCAST_DNUA_UPDATED"
				},
			]
		},
		{
			name: "coachReferralSource",
			module: "coachReferralSource",
			events: "*",
			mutationKey: "COACH_REFERRAL_SOURCE"
		},
		{
			name: "coachScreeningSession",
			module: "coachScreeningSession",
			events: "*",
			mutationKey: "COACH_SCREENING_SESSION"
		},
		{
			name: "coachFacilitatingCourse",
			module: "coachFacilitatingCourse",
			events: "*",
			mutationKey: "COACH_FACILITATING_COURSE"
		},
		{
			name: "coachTeachingProfile",
			module: "coachTeachingProfile",
			events: "*",
			mutationKey: "COACH_TEACHING_PROFILE"
		},
		{
			name: "coachLmsExperience",
			module: "coachLmsExperience",
			events: "*",
			mutationKey: "COACH_LMS_EXPERIENCE"
		},
		{
			name: "coachLanguage",
			module: "coachLanguage",
			events: "*",
			mutationKey: "COACH_LANGUAGE"
		},
		{
			name: "userSetting",
			module: "userSetting",
			events: [
				{
					event: "created",
					mutation: "CREATE_USER_SETTING_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_USER_SETTING_SUCCESS"
				},
			],
		},
		{
			name: "workingAssignment",
			module: "workingAssignment",
			events: [
				{
					event: "created",
					mutation: "CREATE_WORKING_ASSIGNMENT_SUCCESS"
				},
				{
					event: "deleted",
					mutation: "BROADCAST_DELETE_WORKING_ASSIGNMENT_SUCCESS"
				}
			],
			mutationKey: "WORKING_ASSIGNMENT"
		},
		{
			name: "workingAssignmentSheet",
			module: "workingAssignmentSheet",
			events: "*",
			mutationKey: "WORKING_ASSIGNMENT_SHEET"
		},
		{
			name: "workingAssignmentSheetReconciliation",
			module: "wasReconciliation",
			events: [
				{
					event: "created",
					mutation: "CREATE_WAS_RECONCILIATION_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_WAS_RECONCILIATION_SUCCESS"
				},
				{
					event: "deleted",
					mutation: "DELETE_WAS_RECONCILIATION_SUCCESS"
				}
			]
		},
		{
			name: "contractIO",
			module: "contractIO",
			events: "*",
			mutationKey: "CONTRACT_IO"
		},
		{
			name: "discipline",
			module: "discipline",
			events: "*",
			mutationKey: "DISCIPLINE"
		},
		{
			name: "minor",
			module: "minor",
			events: "*",
			mutationKey: "MINOR"
		},
		{
			name: "university",
			module: "university",
			events: "*",
			mutationKey: "UNIVERSITY"
		},
		{
			name: "icEmailsActivationDeactivation",
			module: "icEmailsActivationDeactivation",
			events: [
				{
					event: "created",
					mutation: "CREATE_ACTIVATION_DEACTIVATION_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_ACTIVATION_DEACTIVATION_SUCCESS"
				},
				{
					event: "deleting",
					mutation: "DELETE_ACTIVATION_DEACTIVATION_SUCCESS"
				},
				{
					event: "deleted",
					mutation: "DELETE_ACTIVATION_DEACTIVATION_SUCCESS"
				},
			],
		},
		{
			name: "email",
			module: "email",
			events: [
				{
					event: "created",
					mutation: "CREATE_EMAIL_SUCCESS"
				},
				{
					event: "updated",
					mutation: "EDIT_EMAIL_SUCCESS"
				},
			],
			mutationKey: "EMAIL"
		},
		{
			name: "universityFacultySource",
			module: "universityFacultySource",
			events: "*",
			mutationKey: "UNI_FACULTY_SOURCE"
		},
		{
			name: "contract",
			module: "contract",
			events: "*",
			mutationKey: "CONTRACT"
		},
		{
			name: "contractAddendum",
			module: "contractAddendum",
			events: "*",
			mutationKey: "CONTRACT_ADDENDUM"
		},
		{
			name: "facultySource",
			module: "facultySource",
			events: "*",
			mutationKey: "FACULTY_SOURCE"
		},
		{
			name: "users",
			module: "users",
			events: [
				{
					event: "updated",
					mutation: "EDIT_USER_SUCCESS"
				},
			],
			mutationKey: "USER"
		},
	],
	adminEventsChannels: [
		{
			eventName: 'coach-application-submitted',
			module: 'coaches',
			action: 'getCoaches',
		},
		{
			eventName: 'coach-screening-session-response',
			module: 'coaches',
			action: 'getCoaches',
		},
		{
			eventName: 'coach-docs-updated',
			module: 'coachAssignment',
			action: 'getCoachAssignments',
		},
		{
			eventName: 'wa-created',
			module: 'coaches',
			action: 'getCoaches',
		},
		{
			eventName: 'email-password-reset-request',
			module: 'coaches',
			mutation: 'GET_COACHES_SUCCESS',
		},
		{
			eventName: 'ca-assigned',
			handle: caAssignedAdminEvent,
		},
		{
			eventName: 'dashboard-items',
			handle: dbItemsUpdatedEvent,
		},
		{
			eventName: 'invoice-updated',
			handle: invoiceUpdated,
		},
		{
			eventName: "reconciliation-finalized",
			module: 'payroll',
			action: 'getPayrolls',
			role: "payroll"
		}
	],
	coachPortalEventsChannels: [
		{
			eventName: 'ca-updated',
			module: 'coachAssignment',
			action: 'getCoachAssignment'
		},
	],
	listenersActive: false,
	allMutations: {
		created: "CREATE",
		updated: "EDIT",
		deleted: "DELETE"
	},
	adminEventChannelInit: false,
	coachPortalEventChannelInit: false,
	privateChannelInit: false,
	channelsInit: false,
	pusher: null,
	taskRoles: [
		'operations',
		'admin'
	]
}



const getters = {
	channelsInit: state => state.channelsInit,
	privateChannelInit: state => state.privateChannelInit,
	adminEventChannelInit: state => state.adminEventChannelInit,
	coachPortalEventChannelInit: state => state.coachPortalEventChannelInit,
	echo: state => state.echo,
	channels: state => state.channels
}

const actions = {
	async initPusher({ commit }, pusher) {
		commit("INIT_PUSHER", pusher)
		return true;
	},
	initPrivateChannel({ commit }, userUuid) {
		commit("INIT_PRIVATE_CHANNEL", { commit, userUuid })
	},
	initAdminEventChannel({ commit, dispatch }, user) {
		commit("INIT_ADMIN_EVENT_CHANNELS", { commit, dispatch, user })
	},
	initCoachPortalEventChannel({ commit, dispatch }, userUuid) {
		commit("INIT_COACH_PORTAL_EVENT_CHANNELS", { commit, dispatch, userUuid })
	},
	initChannels({ commit, dispatch }) {
		commit("INIT_CHANNELS", { commit, dispatch })
	},
	initPrefixChannels({ commit }, prefix) {
		commit("INIT_PREFIX_CHANNELS", { commit, prefix })
	}
}

const mutations = {
	[INIT_PUSHER]: (state, pusher) => {
		state.pusher = pusher
		state.pusherInit = true
	},
	[INIT_PRIVATE_CHANNEL]: (state, { commit, userUuid }) => {
		//userUuid is actually the full user object

		// Listen for User updates
		state.pusher.channel(`user.${userUuid.uuid}`).listen('.updated', (data) => {
			commit('auth/AUTH_USER_UPDATE', data, { root: true })
		})

		//User Notifications
		state.pusher.channel(`notifications.${userUuid.uuid}`)
			.notification((notification) => {
				commit("notification/CREATE_NOTIFICATION_SUCCESS", { notification }, { root: true })
			});

		state.pusher.channel(`user.${userUuid.uuid}`)
			.notification((notification) => {
				commit("notification/CREATE_NOTIFICATION_SUCCESS", { notification }, { root: true })
			});

		state.pusher.private(`user.${userUuid.uuid}`).notification((notification) => {
			commit("notification/CREATE_NOTIFICATION_SUCCESS", { notification }, { root: true })
		});

		//Tasks specific to the user
		state.pusher.channel(`task.${userUuid.uuid}`).listen('.created', (data) => {
			commit("task/CREATE_TASK_SUCCESS", data, { root: true })
		});
		state.pusher.channel(`task.${userUuid.uuid}`).listen('.updated', (data) => {
			commit("task/EDIT_TASK_SUCCESS", data, { root: true })
		});

		// Subscribe to tasks based on the user's roles
		userUuid.roles.forEach(r => {
			let rChannel = state.pusher.channel(`task.${r}`)
			Object.keys(state.allMutations).forEach(k => {
				rChannel.listen(`.${k}`, (data) => {
					commit(`task/${state.allMutations[k]}_TASK_SUCCESS`, data, { root: true })
				})
			})
		});


		state.privateChannelInit = true
	},

	[INIT_CHANNELS]: (state, { commit, dispatch }) => {
		const logFor = [
			// 'coaches', 'coachLanguage'
		];

		state.channels.forEach(c => {
			let channel = state.pusher.channel(`${c.name}`)

			if (Array.isArray(c.events)) {
				c.events.forEach(e => {
					channel.listen(`.${e.event}`, (data) => {
						// if(logFor.includes(c.module)){
						// 	console.log(e.event, e.mutation, data, 'br57');
						// }
						commit(`${c.module}/${e.mutation}`, data, { root: true })
					})
				})
			}

			//Added if statement to optimize for models that we want to bind all events
			if (c.events === "*") {
				Object.keys(state.allMutations).forEach(k => {
					channel.listen(`.${k}`, (data) => {
						// console.log(k, data);
						// if(logFor.includes(c.module)){
						// 	console.log(c.module, `${state.allMutations[k]}_${c.mutationKey}`, data, 'br57');
						// }
						commit(`${c.module}/${state.allMutations[k]}_${c.mutationKey}_SUCCESS`, data, { root: true })
					})
				})
			}
		});

		// Test Pusher 
		state.pusher.channel("test").listen(".test", (data) => {
			console.log(data);
		});

		state.channelsInit = true;
	},

	[INIT_ADMIN_EVENT_CHANNELS]: (state, { commit, dispatch, user }) => {
		const channel = state.pusher.channel(`admin-events`)
		state.adminEventsChannels.forEach(ec => {

			if (!isEmpty(ec?.role) && user.roles.includes(ec.role)) {
				channel.listen(`.${ec.eventName}`, (data) => {
					if (!isEmpty(data)) {

						if (ec.mutation) {
							commit(`${ec.module}/${ec.mutation}`, data, { root: true })
						}

						if (ec.action) {
							dispatch(`${ec.module}/${ec.action}`, data, { root: true })
						}
					}
				})
			} else {
				channel.listen(`.${ec.eventName}`, (data) => {
					if (!isEmpty(data)) {
						if (typeof ec.handle != typeof undefined) {
							ec.handle(commit, dispatch, data)
						} else {
							if (ec.mutation) {
								commit(`${ec.module}/${ec.mutation}`, data, { root: true })
							}
							if (ec.action) {
								dispatch(`${ec.module}/${ec.action}`, data, { root: true })
							}
						}

					}
				})
			}
		})

		state.adminEventChannelInit = true
	},

	[INIT_COACH_PORTAL_EVENT_CHANNELS]: (state, { commit, dispatch, userUuid }) => {
		const channel = state.pusher.channel(`cp-events`)
		state.coachPortalEventsChannels.forEach(ec => {
			channel.listen(`.${ec.eventName}`, (data) => {
				if (ec.mutation) {
					commit(`${ec.module}/${ec.mutation}`, data, { root: true })
				}
				if (ec.action) {
					dispatch(`${ec.module}/${ec.action}`, data, { root: true })
				}
			})
		})
		state.coachPortalEventChannelInit = true
	},

	[INIT_PREFIX_CHANNELS]: (state, { commit, prefix }) => {
		state.channels.forEach(c => {
			if (c?.prefix) {
				if (c?.channelPrefix == 'coachesUuid') {
					let channel = state.pusher.channel(`${prefix}.${c.name}`)
					c.events.forEach(e => {
						channel.listen(`.${e.event}`, (data) => {
							commit(`${c.module}/${e.mutation}`, data, { root: true })
						})
					})
				}
			}
		})
	}
}

export const channelListener = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}



function caAssignedAdminEvent(commit, dispatch, data) {
	if (!isEmpty(data?.uuid) && !isEmpty(data?.was_uuid)) {
		const findCa = store.getters['coachAssignment/coachAssignmentsByUuid']
		const ca = findCa(data.uuid)
		if (!isEmpty(ca?.course_starts_uuid)) {
			const findCs = store.getters['courseStart/csByUuid']
			const cs = findCs(ca.course_starts_uuid)
			if (isEmpty(cs)) {
				const params = {
					uuid: data.was_uuid,
					append: [
						"university_programs_uuids",
						"course_starts"
					],
					load: [
						"university_programs",
						"university_programs.courses",
					],
					skipAddForFullLoad: true
				}
				dispatch(`workingAssignmentSheet/getWorkingAssignmentSheet`, params, { root: true })
			}
		}
	}
}

function dbItemsUpdatedEvent(commit, dispatch, data){
	if(!isEmpty(data?.item_key)){
		const {item_key} = data
		let item = null;
		switch (item_key) {
			case 'coach_with_over_students':
				item = "overAssignedCoaches"
				break;
			case 'coach_with_over_students_pre_recon':
				item = "studentOverages"
				break;
		
			default:
				break;
		}
		if(!isEmpty(item)){
			const loadedDasboardItems = store.getters['settings/loadedDasboardItems']
			if(loadedDasboardItems.includes(item)){
				const params = {
					item,
					force: true
				}
				dispatch(`settings/getDashboardItem`, params, { root: true })
			}
		}

	}
}

function invoiceUpdated(commit, dispatch, data){
	if(!isEmpty(data?.invoice_uuid)){
		const {invoice_uuid} = data
		dispatch(`invoice/getInvoice`, {
			uuid: invoice_uuid,
			append: ['available_adjustments'],
			load: ['payments']
		}, { root: true })

	}
}