import {
    apiRequest,
    processInput
} from "@/utilities"

export const courseLevelService = {
    async getCourseLevels() {
        const getCourseLevelsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/course-levels", getCourseLevelsRequest)
    },
    async getCourseLevel(uuid) {
        const getCourseLevelRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/course-levels/${uuid}`, getCourseLevelRequest)
    },
    async createCourseLevel(params) {
        const createData = processInput(params)
        const createCourseLevelRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/course-levels", createCourseLevelRequest)
    },
    async editCourseLevel(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCourseLevelRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/course-levels/${uuid}`, editCourseLevelRequest)
    },
    async deleteCourseLevel(uuid) {
        const deleteCourseLevelRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/course-levels/${uuid}`, deleteCourseLevelRequest)
    }
}