const state = {
  states: [
    { value: 'AL', full_name: 'Alabama', abbreviation: 'AL' },
    { value: 'AK', full_name: 'Alaska', abbreviation: 'AK' },
    { value: 'AZ', full_name: 'Arizona', abbreviation: 'AZ' },
    { value: 'AR', full_name: 'Arkansas', abbreviation: 'AR' },
    { value: 'CA', full_name: 'California', abbreviation: 'CA' },
    { value: 'CO', full_name: 'Colorado', abbreviation: 'CO' },
    { value: 'CT', full_name: 'Connecticut', abbreviation: 'CT' },
    { value: 'DE', full_name: 'Delaware', abbreviation: 'DE' },
    { value: 'FL', full_name: 'Florida', abbreviation: 'FL' },
    { value: 'GA', full_name: 'Georgia', abbreviation: 'GA' },
    { value: 'HI', full_name: 'Hawaii', abbreviation: 'HI' },
    { value: 'ID', full_name: 'Idaho', abbreviation: 'ID' },
    { value: 'IL', full_name: 'Illinois', abbreviation: 'IL' },
    { value: 'IN', full_name: 'Indiana', abbreviation: 'IN' },
    { value: 'IA', full_name: 'Iowa', abbreviation: 'IA' },
    { value: 'KS', full_name: 'Kansas', abbreviation: 'KS' },
    { value: 'KY', full_name: 'Kentucky', abbreviation: 'KY' },
    { value: 'LA', full_name: 'Louisiana', abbreviation: 'LA' },
    { value: 'ME', full_name: 'Maine', abbreviation: 'ME' },
    { value: 'MD', full_name: 'Maryland', abbreviation: 'MD' },
    { value: 'MA', full_name: 'Massachusetts', abbreviation: 'MA' },
    { value: 'MI', full_name: 'Michigan', abbreviation: 'MI' },
    { value: 'MN', full_name: 'Minnesota', abbreviation: 'MN' },
    { value: 'MS', full_name: 'Mississippi', abbreviation: 'MS' },
    { value: 'MO', full_name: 'Missouri', abbreviation: 'MO' },
    { value: 'MT', full_name: 'Montana', abbreviation: 'MT' },
    { value: 'NE', full_name: 'Nebraska', abbreviation: 'NE' },
    { value: 'NV', full_name: 'Nevada', abbreviation: 'NV' },
    { value: 'NH', full_name: 'New Hampshire', abbreviation: 'NH' },
    { value: 'NJ', full_name: 'New Jersey', abbreviation: 'NJ' },
    { value: 'NM', full_name: 'New Mexico', abbreviation: 'NM' },
    { value: 'NY', full_name: 'New York', abbreviation: 'NY' },
    { value: 'NC', full_name: 'North Carolina', abbreviation: 'NC' },
    { value: 'ND', full_name: 'North Dakota', abbreviation: 'ND' },
    { value: 'OH', full_name: 'Ohio', abbreviation: 'OH' },
    { value: 'OK', full_name: 'Oklahoma', abbreviation: 'OK' },
    { value: 'OR', full_name: 'Oregon', abbreviation: 'OR' },
    { value: 'PA', full_name: 'Pennsylvania', abbreviation: 'PA' },
    { value: 'RI', full_name: 'Rhode Island', abbreviation: 'RI' },
    { value: 'SC', full_name: 'South Carolina', abbreviation: 'SC' },
    { value: 'SD', full_name: 'South Dakota', abbreviation: 'SD' },
    { value: 'TN', full_name: 'Tennessee', abbreviation: 'TN' },
    { value: 'TX', full_name: 'Texas', abbreviation: 'TX' },
    { value: 'UT', full_name: 'Utah', abbreviation: 'UT' },
    { value: 'VT', full_name: 'Vermont', abbreviation: 'VT' },
    { value: 'VA', full_name: 'Virginia', abbreviation: 'VA' },
    { value: 'WA', full_name: 'Washington', abbreviation: 'WA' },
    { value: 'WV', full_name: 'West Virginia', abbreviation: 'WV' },
    { value: 'WI', full_name: 'Wisconsin', abbreviation: 'WI' },
    { value: 'WY', full_name: 'Wyoming', abbreviation: 'WY' },
    { value: 'AS', full_name: 'American Samoa', abbreviation: 'AS' },
    { value: 'DC', full_name: 'District of Columbia', abbreviation: 'DC' },
    { value: 'GU', full_name: 'Guam', abbreviation: 'GU' },
    { value: 'MH', full_name: 'Marshall Islands', abbreviation: 'MH' },
    { value: 'MP', full_name: 'Northern Mariana Island', abbreviation: 'MP' },
    { value: 'PR', full_name: 'Puerto Rico', abbreviation: 'PR' },
    { value: 'VI', full_name: 'Virgin Islands', abbreviation: 'VI' },
  ],
  state: {},
  loading: false
}

const getters = {
  stateSelectOptions: state => {
    return state.states.map((state) => {
      return { label: `${state.full_name} (${state.abbreviation})`, value: state.value, }
    })
  },
  getNursingState: state => {
    return state.states.map((state) => {
      return { value: state.value, text: `${state.full_name} (${state.abbreviation})` }
    })
  },
  getState: state => abbr => {
    if (!abbr || abbr == null || abbr == 'null' || abbr == '') return null
    let stateObj = state.states.find(s => s.value == abbr)
    return stateObj.full_name
  }
}

const actions = {

}

const mutations = {

}

export const states = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
