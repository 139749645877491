import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachEducationExperienceService = {
    async getCoachEducationExperiences(params) {
        let getParams = processQueryParams(params)
        const getCoachEducationExperiencesRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-education-experiences", getCoachEducationExperiencesRequest)
    },
    async getCoachEducationExperience(uuid) {
        const getCoachEducationExperienceRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-education-experiences/${uuid}`, getCoachEducationExperienceRequest)
    },
    async createCoachEducationExperience(params) {
        const createData = processInput(params)
        const createCoachEducationExperienceRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-education-experiences", createCoachEducationExperienceRequest)
    },
    async editCoachEducationExperience(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachEducationExperienceRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-education-experiences/${uuid}`, editCoachEducationExperienceRequest)
    },
    async deleteCoachEducationExperience(uuid) {
        const deleteCoachEducationExperienceRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-education-experiences/${uuid}`, deleteCoachEducationExperienceRequest)
    }
}