import { isSaturday, isSunday } from "date-fns";
import { isBankHoliday } from "date-fns-holiday-us";

/**
 *
 * @param {Integer} month
 * @param {Integer} year
 * @returns Date
 */
export function calcEomPayDate(month, year) {
  let date = new Date(year, month + 1, 0);
  if (isSaturday(date) || isSunday(date) || isBankHoliday(date)) {
    while (isSaturday(date) || isSunday(date) || isBankHoliday(date)) {
      date.setDate(date.getDate() - 1);
    }
  }
  return date
}

/**
 *
 * @param {Integer} month
 * @param {Integer} year
 * @returns Date
 */
export function calcMmPayDate(month, year) {
  let date = new Date(year, month, 15);
  if (isSaturday(date) || isSunday(date) || isBankHoliday(date)) {
    while (isSaturday(date) || isSunday(date) || isBankHoliday(date)) {
      date.setDate(date.getDate() - 1);
    }
  }
  return date
}

/**
 *
 * @param {*} course
 * @returns
 */
export function payrollCourseAssignedStudents(course) {
  let studs = course.coaches.reduce((t, c) => {
    return (t +=
      typeof c.assigned_students == "string"
        ? parseInt(c.assigned_students)
        : 0);
  }, 0);

  return studs * course.rate ?? 0;
}

/**
 *
 * @param {*} course
 * @returns
 */
export function payrollCourseFinalStudents(course) {
  let studs = course.coaches.reduce((t, c) => {
    return (t +=
      typeof c.assigned_students == "string"
        ? parseInt(c.final_student_count)
        : 0);
  }, 0);

  return studs * course.rate ?? 0;
}
