import {
    apiRequest,
    processInput
} from "@/utilities"

export const universityProspectDocumentsService = {
    async getUniversityProspectDocuments() {
        const getUniversityProspectDocumentsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/prospect-documents", getUniversityProspectDocumentsRequest)
    },
    async createUniversityProspectDocument(params) {
        const createData = processInput(params);
        const postUniversityProspectDocumentRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/prospect-documents", postUniversityProspectDocumentRequest)
    },
    async getUniversityProspectDocument(uuid) {

        const getUniversityProspectDocumentRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/prospect-documents/${uuid}`, getUniversityProspectDocumentRequest);
    },
    async editUniversityProspectDocument(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityProspectDocumentRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/prospect-documents/${uuid}`, updateUniversityProspectDocumentRequest);
    },
    async deleteUniversityProspectDocument(uuid) {

        const deleteUniversityProspectDocumentRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/prospect-documents/${uuid}`, deleteUniversityProspectDocumentRequest);
    }
}