import {
  adminPaymentService
} from "@/services";

import {
  addNewFilter
} from "@/utilities";

const state = {
  loading: false,
  adminPayments: [],
}

const getters = {
  adminPaymentsLoading: (state) => state.loading, 
  adminPayments: (state) => state.adminPayments
}

const actions = {
  async getAdminPayments({ commit, getters }, params) {
    commit("GET_ADMIN_PAYMENTS_REQUEST")
    const response = await adminPaymentService.getAdminPayments(params);
    const { status, data } = response
    switch (status) {
      case 200:
        if (data.success) {
          commit("GET_ADMIN_PAYMENTS_SUCCESS", data)
        }
        break
      default:
        commit("GET_ADMIN_PAYMENTS_ERROR", data)
    }
    return response
  },
  async getAdminPayment({ commit }, params) {
    commit("GET_ADMIN_PAYMENT_REQUEST")
    const response = await adminPaymentService.getAdminPayment(params);
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_ADMIN_PAYMENT_SUCCESS", data)
        break
      default:
        commit("GET_ADMIN_PAYMENT_ERROR", data)
        break
    }
    return response
  },
  async createAdminPayment({ commit, dispatch }, params) {
    commit("CREATE_ADMIN_PAYMENT_REQUEST")
    const response = await adminPaymentService.createAdminPayment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_ADMIN_PAYMENT_SUCCESS", data) : null
        data.success ? dispatch("alert/createAlertSuccess", "Payment created.", { root: true }) : null
        break
      default:
        commit("CREATE_ADMIN_PAYMENT_ERROR", data)
    }
    return response
  },
  async editAdminPayment({ commit }, params) {
    commit("EDIT_ADMIN_PAYMENT_REQUEST")
    const response = await adminPaymentService.editAdminPayment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_ADMIN_PAYMENT_SUCCESS", data) : null
        break
      default:
        commit("EDIT_ADMIN_PAYMENT_ERROR", data)
    }
    return response
  },
  async deleteAdminPayment({ commit, dispatch }, uuid) {
    commit("DELETE_ADMIN_PAYMENT_REQUEST");

    const response = await adminPaymentService.deleteAdminPayment(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_ADMIN_PAYMENT_SUCCESS", { uuid }) : null
        data.success ? dispatch("alert/createAlertSuccess", "Payment deleted.", { root: true }) : null
        break
      default:
        commit("DELETE_ADMIN_PAYMENT_ERROR", data)
    }
    return response
  }
}

const mutations = {
  GET_ADMIN_PAYMENTS_REQUEST: state => {
    state.loading = true
  },
  GET_ADMIN_PAYMENTS_SUCCESS: (state, data) => {
    const admin_payments = data.admin_payments;
    state.adminPayments = addNewFilter(state.adminPayments, admin_payments)
    state.loading = false
    state.loadedOnce = true
  },
  GET_ADMIN_PAYMENTS_ERROR: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },

  GET_ADMIN_PAYMENT_REQUEST: state => {
    state.loading = true
  },
  GET_ADMIN_PAYMENT_SUCCESS: (state, data) => {
    const { admin_payment } = data;

    state.adminPayments = addNewFilter(state.adminPayments, [admin_payment]);
    state.loading = false
  },
  GET_ADMIN_PAYMENT_ERROR: (state, data) => {
    state.loading = false
  },

  CREATE_ADMIN_PAYMENT_REQUEST: state => {
    state.loading = true
  },
  CREATE_ADMIN_PAYMENT_SUCCESS: (state, data) => {
    const { admin_payment } = data
    state.adminPayments = addNewFilter(state.adminPayments, [admin_payment])
    state.loading = false
  },

  CREATE_ADMIN_PAYMENT_ERROR: state => {
    state.loading = false
  },

  EDIT_ADMIN_PAYMENT_REQUEST: state => {
    state.loading = true
  },
  EDIT_ADMIN_PAYMENT_SUCCESS: (state, data) => {
    const { admin_payment } = data;

    state.adminPayments = addNewFilter(state.adminPayments, [admin_payment])

    state.loading = false;
  },
  EDIT_ADMIN_PAYMENT_ERROR: state => {
    state.loading = false
  },

  DELETE_ADMIN_PAYMENT_REQUEST: state => {
    state.loading = true
  },
  DELETE_ADMIN_PAYMENT_SUCCESS: (state, ap) => {
    const { uuid } = ap;
    state.adminPayments = state.adminPayments.filter(i => i.uuid !== uuid)
    state.loading = false
  },
  DELETE_ADMIN_PAYMENT_ERROR: (state, data) => {
    state.loading = false
  },
}

export const adminPayment = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
