import {
	GET_DOCUMENTS_REQUEST,
	GET_DOCUMENTS_SUCCESS,
	GET_DOCUMENTS_ERROR,
	GET_DOCUMENT_REQUEST,
	GET_DOCUMENT_SUCCESS,
	GET_DOCUMENT_ERROR,
	CREATE_DOCUMENT_REQUEST,
	CREATE_DOCUMENT_SUCCESS,
	CREATE_DOCUMENT_ERROR,
	EDIT_DOCUMENT_REQUEST,
	EDIT_DOCUMENT_SUCCESS,
	EDIT_DOCUMENT_ERROR,
	DELETE_DOCUMENT_REQUEST,
	DELETE_DOCUMENT_SUCCESS,
	DELETE_DOCUMENT_ERROR,
	SEND_DOCUMENT_REQUEST,
	SEND_DOCUMENT_SUCCESS,
	SEND_DOCUMENT_ERROR,
	CLEAR_DOCUMENT_STATE,
	BROADCAST_DELETE_DOCUMENT_SUCCESS
} from "@/actions"

import {
	documentService
} from "@/services"

import { addNewFilter, isEmpty, decodeLaravelValidationErrors, onlyUnique } from "@/utilities"
import { addDays, isAfter, format, parseISO, subDays } from 'date-fns'

const state = {
	documents: [],
	document: {},
	loading: false,
	loadedOnce: false
}

const getters = {
	docLoadedOnce: state => state.loadedOnce,
	isLoadingDoc: state => state.loading,
	documents: state => state.documents,
	documentByUuid: state => uuid => state.documents.find(d => d.uuid == uuid),
	docsByOwner: (state) => uuid => state.documents.filter(doc => doc.documentable_uuid == uuid),
	docsByOwnerTypesArr: state => (ownerUuid, arrUuids) => state.documents.filter(d => d.documentable_uuid == ownerUuid && arrUuids.includes(d.document_types_uuid)),
	docByOwnerTypeUuid: state => (uuid, tUuid) => state.documents.filter(d => d.documentable_uuid == uuid && d.document_types_uuid == tUuid).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).find((i, idx) => idx === 0),
	docByOwnerTypesUuid: state => (uuid, tUuid) => state.documents.filter(d => d.documentable_uuid == uuid && d.document_types_uuid == tUuid).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
	docByOwnerTypesArr: state => (ownerUuid, arrUuids) => state.documents.find(d => d.documentable_uuid == ownerUuid && arrUuids.includes(d.document_types_uuid)),
	docsByOwnerTypesArrApprovedUnApproved: state => (ownerUuid, arrUuids) => state.documents.filter(d => d.documentable_uuid == ownerUuid && arrUuids.includes(d.document_types_uuid) && d.admin_approved != 0),
	universityDocument: state => (uniUuid, docTypeUuid) => {
		let doc = state.documents.find(ud => ud.documentable_uuid == uniUuid && ud.document_types_uuid == docTypeUuid)
		return doc
	},
	universityContractDocument: state => (contractUuid, docTypeUuid) => {
		const doc = state.documents.find(ud => ud.documentable_uuid == contractUuid && ud.document_types_uuid == docTypeUuid)
		return doc
	},
	universityPreSaleDocument: state => (uniPreSaleUuid, docTypeUuid) => state.documents.find(ud => ud.documentable_uuid == uniPreSaleUuid && ud.document_types_uuid == docTypeUuid),
	universityDocuments: state => (uniUuid, docTypeUuid) => state.documents.filter(ud => ud.documentable_uuid == uniUuid && ud.document_types_uuid == docTypeUuid),
	universityDocumentsByTypeSlugArray: state => (uniUuid, docTypeArray) => state.documents.filter(ud => {
		return ud.documentable_uuid == uniUuid && docTypeArray.includes(ud.document_types_uuid)
	}).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
	universityContractDocuments: state => (contractUuid, docTypeArray) => state.documents.filter(ud => {
		return ud.documentable_uuid == contractUuid && docTypeArray.includes(ud.document_types_uuid)
	}).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
	universityDocumentsByModelAndUuid: state => (uniUuid, docTypeModel) => state.documents.filter(ud => uniUuid.includes(ud.documentable_uuid) && docTypeModel.includes(ud.document_type.model)).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
	coachDocuments: (state, getters, rootState, rootGetters) => state.documents.filter(d => d.documentable_uuid == rootGetters['coaches/coach']['uuid']),
	coachNewHireDocumentsByCoachUuid: (state, getters, rootState, rootGetters) => (coachUuid, returnTypeBoolean, isApproved) => {
		if(isEmpty(returnTypeBoolean)){
			returnTypeBoolean = true
		}
		if(isEmpty(isApproved)){
			isApproved = false
		}
		let docs = state.documents.filter(d => d.documentable_uuid == coachUuid && d.admin_approved != 0)
		if(isApproved){
			docs = docs.filter(i => i.admin_approved == 1)
		}
		let docTypeGetter = rootGetters['documentType/getDocTypeBySlug']
		
		let agreement = docTypeGetter('contractor-agreement')
		let ferpa = docTypeGetter('ferpa-acknowledgement')
		let w9 = docTypeGetter('w-9')
		let bankLetter = docTypeGetter('direct-deposit-form')
		let vCheck = docTypeGetter('voided-check')
		let docTypeGetterByType = rootGetters['documentType/getDocTypeByType']
		let identification = docTypeGetterByType('identification')
		let identification_1 = identification.find((id, idx) => idx === 0)
		let types = [], slugTypes = []
		docs = docs.map(d => d.document_types_uuid)
		let b = new Set(docs)
		slugTypes.push(ferpa, agreement, w9, bankLetter, vCheck)
		types = slugTypes.concat(identification).filter(i => !isEmpty(i))
		if(isEmpty(types)) return false;
		let pendingTypes = types.map(t => t.type).filter(onlyUnique)
		
		types = types.map(t => !isEmpty(t) && !isEmpty(t.uuid) ? t : null).filter(t => !isEmpty(t))
		let uploadedTypes = types.filter(t => b.has(t.uuid)).map(t => t.type).filter(onlyUnique)
		uploadedTypes.forEach(ud => {
			pendingTypes = pendingTypes.filter(pt => pt != ud)
		})
		pendingTypes =  pendingTypes.map(pt => {
			if(pt == 'identification'){
				return {
					...identification_1,
					label: "Photo ID"
				}
			}
			return slugTypes.find((i, idx) => i.type == pt)
		})
		if(returnTypeBoolean){
			return !isEmpty(pendingTypes)
		}else{
			return pendingTypes
		}
	},
	
	coachNewHireDocuments: (state, getters, rootState, rootGetters) => {
		return getters.coachNewHireDocumentsByCoachUuid(rootGetters['coaches/coach']['uuid'], true)
	},

	uploadedBankingAndNewHireDocs: (state, getters, rootState, rootGetters) => {
		const docTypeGetter = rootGetters['documentType/getDocTypeBySlug']
		const docTypeByUuid = rootGetters['documentType/docTypeByUuid']
		const nhdCoachUuids = rootGetters['miscellaneous/nhdCoachUuids']
		const pdCoachUuids = rootGetters['miscellaneous/pdCoachUuids']
		const agreement = docTypeGetter('contractor-agreement')
		const ferpa = docTypeGetter('ferpa-acknowledgement')
		const w9 = docTypeGetter('w-9')
		const bankLetter = docTypeGetter('direct-deposit-form')
		const vCheck = docTypeGetter('voided-check')
		const docTypeGetterByType = rootGetters['documentType/getDocTypeByType']
		const identification = docTypeGetterByType('identification')
		let types = [], slugTypes = []
		slugTypes.push(ferpa, agreement, w9, bankLetter, vCheck)
		types = slugTypes.concat(identification)
		types = types.map(t => !isEmpty(t) && !isEmpty(t.uuid) ? t : null).filter(t => !isEmpty(t))

		return state.documents
				.filter(i => types.some(dt => dt.uuid == i.document_types_uuid))
				.map(d => d.documentable_uuid)
				.filter(onlyUnique)
				.map(cUuid => {
					let coachesDocs = state.documents.filter(d => isEmpty(d.admin_approved) && d.documentable_uuid == cUuid && types.some(dt => dt.uuid == d.document_types_uuid)).map(d => {
						let type = docTypeByUuid(d.document_types_uuid)
						return {
							...d,
							type
						}
					})
					if(isEmpty(coachesDocs)) return {}
					
					let group = null;
					if(pdCoachUuids.some(i => i == cUuid)){
						group = 'pd'
					}else if(nhdCoachUuids.some(i => i == cUuid)){
						group = 'nhd'
					}else{
						return {}
					}
					return {
						coaches_uuid: cUuid,
						documents: coachesDocs,
						group
					}
				})
	},

	unApprovedBankingDocs: (state, getters, rootState, rootGetters) => (coachUuid, docTypes) =>{
		if(isEmpty(docTypes)){
			docTypes = [
				'w-9',
				'voided-check',
				'direct-deposit-form',
			]
		}
		const docTypeGetter = rootGetters['documentType/getDocTypeBySlug']
		const docTypeByUuid = rootGetters['documentType/docTypeByUuid']
		
		let types = [];
		docTypes.forEach(dt => {
			types.push(docTypeGetter(dt))
		})
		types = types.map(t => !isEmpty(t) && !isEmpty(t.uuid) ? t : null).filter(t => !isEmpty(t))
		return state.documents
		.filter(d => d.admin_approved != 0 
			&& d.admin_approved != 1 
			&& d.documentable_uuid == coachUuid 
			&& types.some(dt => dt.uuid == d.document_types_uuid))
		.map(d => {
			let type = docTypeByUuid(d.document_types_uuid)
			return {
				...d,
				type
			}
		})
	},

	approvedNhDocs: (state, getters, rootState, rootGetters) => (coachUuid) =>{
		const docTypeGetter = rootGetters['documentType/getDocTypeBySlug']
		const docTypeByUuid = rootGetters['documentType/docTypeByUuid']
		let agreement = docTypeGetter('contractor-agreement')
		let ferpa = docTypeGetter('ferpa-acknowledgement')
		let w9 = docTypeGetter('w-9')
		let bankLetter = docTypeGetter('direct-deposit-form')
		let vCheck = docTypeGetter('voided-check')

		let docTypeGetterByType = rootGetters['documentType/getDocTypeByType']
		let identification = docTypeGetterByType('identification')
		let types = [], slugTypes = []
		slugTypes.push(ferpa, agreement, w9, bankLetter, vCheck)
		types = slugTypes.concat(identification)
		types = types.map(t => !isEmpty(t) && !isEmpty(t.uuid) ? t : null).filter(t => !isEmpty(t))

		return state.documents
		.filter(d => d.admin_approved == 1 
			&& d.documentable_uuid == coachUuid 
			&& types.some(dt => dt.uuid == d.document_types_uuid))
		.map(d => {
			let type = docTypeByUuid(d.document_types_uuid)
			if(type.type == 'identification'){
				type.label = 'Photo ID'
			}
			return {
				...d,
				type
			}
		})
	},

	pendingNhDocs: (state, getters, rootState, rootGetters) => (coachUuid) =>{
		const docTypeGetter = rootGetters['documentType/getDocTypeBySlug']
		const docTypeByUuid = rootGetters['documentType/docTypeByUuid']
		let agreement = docTypeGetter('contractor-agreement')
		let ferpa = docTypeGetter('ferpa-acknowledgement')
		let w9 = docTypeGetter('w-9')
		let bankLetter = docTypeGetter('direct-deposit-form')
		let vCheck = docTypeGetter('voided-check')

		let docTypeGetterByType = rootGetters['documentType/getDocTypeByType']
		let identification = docTypeGetterByType('identification')
		let types = [], slugTypes = []
		slugTypes.push(ferpa, agreement, w9, bankLetter, vCheck)
		types = slugTypes.concat(identification)
		types = types.map(t => !isEmpty(t) && !isEmpty(t.uuid) ? t : null).filter(t => !isEmpty(t))
		const identificationUuids = identification.map(t => !isEmpty(t) && !isEmpty(t.uuid) ? t : null).filter(t => !isEmpty(t)).map(i => i.uuid)
		const uploadedDocs = state.documents
			.filter(d => d.admin_approved != 0 
				&& d.documentable_uuid == coachUuid 
				&& types.some(dt => dt.uuid == d.document_types_uuid))
			.map(d => {
				let type = docTypeByUuid(d.document_types_uuid)
				return {
					...d,
					type
				}
			})
		const approvalPending = uploadedDocs.filter(i => isEmpty(i.admin_approved))
		const approvedDocs = uploadedDocs.filter(i => i.admin_approved == 1)
		let pendingDocs = types.filter(t => {
			return !uploadedDocs.some(ud => ud.document_types_uuid == t.uuid)
		}).map(i => i.type)
		const hasIdentityDoc = identificationUuids.some(identificationUuid => {
			return uploadedDocs.some(ud => ud.document_types_uuid == identificationUuid)
		})
		if(hasIdentityDoc){
			pendingDocs = pendingDocs.filter(i => i != 'identification')
		}
		pendingDocs = pendingDocs.map(pt => {
			if(pt == 'identification'){
				return "Photo ID"
			}
			return slugTypes.find((i, idx) => i.type == pt)?.label
		}).filter(onlyUnique)
		return {
			pendingDocs,
			uploadedDocs: approvedDocs,
			approvalPending,
		}
	},

	uploadedNormalUnapprovedDocs: (state, getters, rootState, rootGetters) => {
		const docTypeGetter = rootGetters['documentType/getDocTypeBySlug']
		const coachAssignmentsByCoachUuid = rootGetters['coachAssignment/coachAssignmentsByCoachUuid']
		let agreement = docTypeGetter('contractor-agreement')
		let ferpa = docTypeGetter('ferpa-acknowledgement')
		let w9 = docTypeGetter('w-9')
		let bankLetter = docTypeGetter('direct-deposit-form')
		let vCheck = docTypeGetter('voided-check')
		const nhd_types = []
		const bd_types = []
		let d_types = []
		nhd_types.push(ferpa, agreement)
		bd_types.push(w9, bankLetter, vCheck)
		d_types  = d_types.concat(nhd_types).concat(bd_types)
		let docTypeGetterByType = rootGetters['documentType/getDocTypeByType']
		let identification = docTypeGetterByType('identification')
		d_types  = d_types.concat(identification)

		d_types = d_types.filter(i => !isEmpty(i))
		if(isEmpty(d_types)) return []
		return state.documents
				.filter(i => {
					let isNHD = d_types.some(dt => dt.uuid == i.document_types_uuid)
					if(isNHD){
						const cas = coachAssignmentsByCoachUuid(i.documentable_uuid).filter(ca => isEmpty(ca.deleted_at) && ca.status == 'assigned')
						if(!isEmpty(cas)){
							return false
						}
					}
					return isEmpty(i.admin_approved)
				})
				
	},

	bankDocsAre30DaysOld: (state, getters, rootState, rootGetters) => (coachUuid) => {
		let docs = state.documents.filter(d => d.documentable_uuid == coachUuid && d.admin_approved != 0)
		let docTypeGetter = rootGetters['documentType/getDocTypeBySlug']
		let bankLetter = docTypeGetter('direct-deposit-form')
		let vCheck = docTypeGetter('voided-check')
		let w9 = docTypeGetter('w-9')
		let types = []
		types.push(bankLetter, vCheck, w9)
		let last30Date = subDays(new Date(), 30)
		const oldDocs = []
		types.forEach(t => {
			if(!isEmpty(t)){
				let doc = docs.find(d => t.uuid == d.document_types_uuid)
				if(!isEmpty(doc)){
					let uploadedDateParsed = parseISO(doc.created_at)
					if(isAfter(last30Date, uploadedDateParsed)){
						oldDocs.push({
							doc,
							docType: t
						})
					}
				}
			}
		})
		return oldDocs
	},

	coachBankDocsAre30DaysOld: (state, getters, rootState, rootGetters) => {
		return getters.bankDocsAre30DaysOld(rootGetters['coaches/coach']['uuid'])
	},
}

const actions = {
	async getDocuments({ commit }, params) {
		commit("GET_DOCUMENTS_REQUEST")
		const response = await documentService.getDocuments(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_DOCUMENTS_SUCCESS", data)
				break
			default:
				commit("GET_DOCUMENTS_ERROR", data)
		}
		return response
	},
	async getDocument({ commit }, params) {
		commit("GET_DOCUMENT_REQUEST")
		const response = await documentService.getDocument(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_DOCUMENT_SUCCESS", data)
				break
			default:
				commit("GET_DOCUMENT_ERROR", data)
				break
		}
		return response
	},
	async createDocument({ commit, dispatch }, params) {
		commit("CREATE_DOCUMENT_REQUEST")
		const response = await documentService.createDocument(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_DOCUMENT_SUCCESS", data) : commit("CREATE_DOCUMENT_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Document uploaded!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("CREATE_DOCUMENT_ERROR", data)
		}
		return response
	},
	async editDocument({ commit }, params) {
		commit("EDIT_DOCUMENT_REQUEST")
		const response = await documentService.editDocument(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_DOCUMENT_SUCCESS", data)
				break
			default:
				commit("EDIT_DOCUMENT_ERROR", data)
		}
		return response
	},
	async deleteDocument({ commit, dispatch }, params) {
		commit("DELETE_DOCUMENT_REQUEST")
		const response = await documentService.deleteDocument(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_DOCUMENT_SUCCESS", params.uuid) : commit("DELETE_DOCUMENT_ERROR", params.uuid)
				data.success ? dispatch('alert/createAlertSuccess', "Document deleted!", { root: true }) : null;
				if (data?.error) {
					if ((data?.errors instanceof String || typeof data?.errors == 'string') && data?.errors.includes("No query results for model")) {
						commit("DELETE_DOCUMENT_SUCCESS", params.uuid);
						dispatch('alert/createAlertSuccess', "Document deleted!", { root: true });
					} else {
						dispatch('alert/createAlertErrors', data.errors ? decodeLaravelValidationErrors(data.errors) : "Oops! Something went wrong.", { root: true })
					}
				}

				break
			default:
				commit("DELETE_DOCUMENT_ERROR", params.uuid)
		}
		return response
	},
	async sendDocument({ commit, dispatch }, params) {
		commit("SEND_DOCUMENT_REQUEST")
		const response = await documentService.sendDocument(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("SEND_DOCUMENT_SUCCESS", data) : commit("SEND_DOCUMENT_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Document sent!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("SEND_DOCUMENT_ERROR", uuid)
		}
		return response
	},

	/**
	 * Get temporary signed URL for document
	 * 
	 * 
	 * @param {*} params 
	 */
	async viewDocument({ commit, dispatch }, params) {
		return await documentService.viewDocument(params);
	}
}

const mutations = {
	[GET_DOCUMENTS_REQUEST]: state => {
		state.loading = true
	},
	[GET_DOCUMENTS_SUCCESS]: (state, data) => {
		let documents = data.documents
		state.documents = addNewFilter(state.documents, documents)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_DOCUMENTS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_DOCUMENT_REQUEST]: state => {
		state.loading = true
	},
	[GET_DOCUMENT_SUCCESS]: (state, data) => {
		const document = { ...data.document }
		state.document = document
		state.loading = false
	},
	[GET_DOCUMENT_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_DOCUMENT_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_DOCUMENT_SUCCESS]: (state, data) => {
		let document = data.document
		state.documents = addNewFilter(state.documents, [document]);
		state.loading = false
	},
	[CREATE_DOCUMENT_ERROR]: state => {
		state.loading = false
	},

	[EDIT_DOCUMENT_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_DOCUMENT_SUCCESS]: (state, data) => {
		if(!isEmpty(data.document)){
			let document = data.document
			state.documents = state.documents.filter(doc => doc.uuid !== document.uuid)
			state.documents = addNewFilter(state.documents, [document]);
		}else if(!isEmpty(data.documents)){
			let documents = data.documents
			state.documents = state.documents.filter(doc => !documents.some(_d => _d.uuid == doc.uuid))
			documents = documents.filter(i => isEmpty(i.deleted_at))
			state.documents = addNewFilter(state.documents, documents)	
		}
		state.loading = false
	},
	[EDIT_DOCUMENT_ERROR]: state => {
		state.loading = false
	},

	[DELETE_DOCUMENT_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_DOCUMENT_SUCCESS]: (state, uuid) => {
		state.documents = state.documents.filter(doc => doc.uuid !== uuid)
		state.loading = false
	},
	[DELETE_DOCUMENT_ERROR]: (state, uuid) => {
		state.documents = state.documents.filter(doc => doc.uuid !== uuid)
		state.loading = false
	},
	[SEND_DOCUMENT_REQUEST]: (state) => {
		state.loading = true
	},
	[SEND_DOCUMENT_SUCCESS]: (state) => {
		state.loading = false
	},
	[SEND_DOCUMENT_ERROR]: (state) => {
		state.loading = false
	},
	[CLEAR_DOCUMENT_STATE]: (state) => {
		state.documents = []
		state.document = {}
		state.loading = false
		state.loadedOnce = false
	},

	[BROADCAST_DELETE_DOCUMENT_SUCCESS]: (state, data) => {
		let { document } = data
		state.documents = state.documents.filter(doc => doc.uuid !== document.uuid)
		state.loading = false
	}
}


export function unpackDocument(d, commit) {
	if (!isEmpty(d.coach)) {
		commit('coaches/GET_LIMITED_COACHES_SUCCESS', { coaches: [d.coach] }, { root: true })
	}
	delete d.coach
	return d
}

export const document = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
