import {
    GET_COMMENTS_REQUEST,
    GET_COMMENTS_SUCCESS,
    GET_COMMENTS_ERROR,
    GET_COMMENT_REQUEST,
    GET_COMMENT_SUCCESS,
    GET_COMMENT_ERROR,
    CREATE_COMMENT_REQUEST,
    CREATE_COMMENT_SUCCESS,
    CREATE_COMMENT_ERROR,
    EDIT_COMMENT_REQUEST,
    EDIT_COMMENT_SUCCESS,
    EDIT_COMMENT_ERROR,
    DELETE_COMMENT_REQUEST,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_ERROR,
    CLEAR_COMMENT_STATE
} from "@/actions"

import {
    commentService
} from "@/services"
import { addNewFilter, isEmpty } from "@/utilities"
const state = {
    comments: [],
    comment: {},
    loading: false,
    loadedOnce: false
}

const getters = {
    allComments: state => state.comments,
    commentLoadedOnce: state => state.loadedOnce,
    commentableFilter: state => uuid => state.comments.filter(comment => comment.commentable_uuid == uuid).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
    commentableBySlugFilter: state => (uuid, commentSlug) => {
        let commentableBySlugFilter = state.comments.filter(comment => comment.commentable_uuid == uuid)
        if(!isEmpty(commentSlug)){
            commentableBySlugFilter = commentableBySlugFilter.filter(comment => comment.comment_type.slug == commentSlug)
        }
        commentableBySlugFilter = commentableBySlugFilter.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        return commentableBySlugFilter
    },
    coachAppComments: state => state.comments.find(comment => comment.comment_type.slug == 'application-notes-applicant'),
    coachComments: (state, getters, rootState, rootGetters) => state.comments.filter(comment => comment.commentable_uuid == rootGetters['coaches/coach']['uuid']).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
    universityComments: state => universityUuid => state.comments.filter(comment => comment.commentable_uuid == universityUuid).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
    commentsBySlug: state => slug => state.comments.filter(comment => comment.comment_type.slug == slug),
}

const actions = {
    initListener({ commit, state }) {
        window.Echo.channel('comment').listen(".created", (e) => {
            commit("CREATE_COMMENT_SUCCESS", e)
        })
    },
    async getComments({ commit }, params) {
        commit("GET_COMMENTS_REQUEST")
        const response = await commentService.getComments(params);
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("GET_COMMENTS_SUCCESS", data) : commit("GET_COMMENTS_ERROR", data)
                break
            default:
                commit("GET_COMMENTS_ERROR", data)
        }
        return response
    },
    async getComment({ commit }, params) {
        commit("GET_COMMENT_REQUEST")
        const response = await commentService.getComment(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COMMENT_SUCCESS", data)
                break
            default:
                commit("GET_COMMENT_ERROR", data)
                break
        }
        return response
    },
    async createComment({ commit, dispatch }, params) {
        commit("CREATE_COMMENT_REQUEST")
        const response = await commentService.createComment(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_COMMENT_SUCCESS", data) : commit("CREATE_COMMENT_ERROR", data)
                data.success ? dispatch('alert/createAlertSuccess', "New Comment created.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
                break
            default:
                commit("CREATE_COMMENT_ERROR", data)
        }
        return response
    },
    async editComment({ commit }, params) {
        commit("EDIT_COMMENT_REQUEST")
        const uuid = params.uuid
        const response = await commentService.editComment(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COMMENT_SUCCESS", data)
                break
            default:
                commit("EDIT_COMMENT_ERROR", data)
        }
        return response
    },
    async deleteComment({ commit }, params) {
        commit("DELETE_COMMENT_REQUEST")
        const response = await commentService.deleteComment(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COMMENT_SUCCESS", data)
                break
            default:
                commit("DELETE_COMMENT_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COMMENTS_REQUEST]: state => {
        state.loading = true
    },
    [GET_COMMENTS_SUCCESS]: (state, data) => {
        const comments = data.comments
        state.comments = addNewFilter(state.comments, comments)
        state.loading = false,
        state.loadedOnce = true
    },
    [GET_COMMENTS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COMMENT_REQUEST]: state => {
        state.loading = true
    },
    [GET_COMMENT_SUCCESS]: (state, data) => {
        const comment = { ...data.comment }
        state.comment = comment
        state.loading = false
    },
    [GET_COMMENT_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COMMENT_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COMMENT_SUCCESS]: (state, data) => {
        const comment = { ...data.comment }
        state.comments = addNewFilter(state.comments, [comment])
        state.loading = false
    },
    [CREATE_COMMENT_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COMMENT_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COMMENT_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COMMENT_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COMMENT_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COMMENT_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_COMMENT_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COMMENT_STATE]: (state) => {
        state.comments = []
        state.comment = {}
        state.loading = false
        state.loadedOnce = false
    }
}

export const comments = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}