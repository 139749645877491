export const GET_COURSES_REQUEST = "GET_COURSES_REQUEST"
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS"
export const GET_COURSES_ERROR = "GET_COURSES_ERROR"
export const GET_COURSE_REQUEST = "GET_COURSE_REQUEST"
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS"
export const GET_COURSE_ERROR = "GET_COURSE_ERROR"
export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST"
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS"
export const CREATE_COURSE_ERROR = "CREATE_COURSE_ERROR"
export const EDIT_COURSE_REQUEST = "EDIT_COURSE_REQUEST"
export const EDIT_COURSE_SUCCESS = "EDIT_COURSE_SUCCESS"
export const EDIT_COURSE_ERROR = "EDIT_COURSE_ERROR"
export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST"
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS"
export const DELETE_COURSE_ERROR = "DELETE_COURSE_ERROR"