export const GET_COACH_EDUCATIONS_REQUEST = "GET_COACH_EDUCATIONS_REQUEST"
export const GET_COACH_EDUCATIONS_SUCCESS = "GET_COACH_EDUCATIONS_SUCCESS"
export const GET_COACH_EDUCATIONS_ERROR = "GET_COACH_EDUCATIONS_ERROR"
export const GET_COACH_EDUCATION_REQUEST = "GET_COACH_EDUCATION_REQUEST"
export const GET_COACH_EDUCATION_SUCCESS = "GET_COACH_EDUCATION_SUCCESS"
export const GET_COACH_EDUCATION_ERROR = "GET_COACH_EDUCATION_ERROR"
export const CREATE_COACH_EDUCATION_REQUEST = "CREATE_COACH_EDUCATION_REQUEST"
export const CREATE_COACH_EDUCATION_SUCCESS = "CREATE_COACH_EDUCATION_SUCCESS"
export const CREATE_COACH_EDUCATION_ERROR = "CREATE_COACH_EDUCATION_ERROR"
export const EDIT_COACH_EDUCATION_REQUEST = "EDIT_COACH_EDUCATION_REQUEST"
export const EDIT_COACH_EDUCATION_SUCCESS = "EDIT_COACH_EDUCATION_SUCCESS"
export const EDIT_COACH_EDUCATION_ERROR = "EDIT_COACH_EDUCATION_ERROR"
export const DELETE_COACH_EDUCATION_REQUEST = "DELETE_COACH_EDUCATION_REQUEST"
export const DELETE_COACH_EDUCATION_SUCCESS = "DELETE_COACH_EDUCATION_SUCCESS"
export const DELETE_COACH_EDUCATION_ERROR = "DELETE_COACH_EDUCATION_ERROR"
export const CLEAR_COACH_EDUCATION_STATE = "CLEAR_COACH_EDUCATION_STATE"