import {
	GET_EMAIL_TYPES_REQUEST,
	GET_EMAIL_TYPES_SUCCESS,
	GET_EMAIL_TYPES_ERROR,
	GET_EMAIL_TYPE_REQUEST,
	GET_EMAIL_TYPE_SUCCESS,
	GET_EMAIL_TYPE_ERROR,
	CREATE_EMAIL_TYPE_REQUEST,
	CREATE_EMAIL_TYPE_SUCCESS,
	CREATE_EMAIL_TYPE_ERROR,
	EDIT_EMAIL_TYPE_REQUEST,
	EDIT_EMAIL_TYPE_SUCCESS,
	EDIT_EMAIL_TYPE_ERROR,
	DELETE_EMAIL_TYPE_REQUEST,
	DELETE_EMAIL_TYPE_SUCCESS,
	DELETE_EMAIL_TYPE_ERROR
} from "@/actions"

import {
	emailTypeService
} from "@/services"

const state = {
	emailTypes: [],
	emailType: {},
	loading: false
}

import { isEmpty } from "@/utilities"

const getters = {
	emailTypes: state => state.emailTypes,
	hasEmailTypes: state => !isEmpty(state.emailTypes),
	emailTypeSelectOptions: state => state.emailTypes.map(et => ({ value: et.uuid, label: et.label })),
	secondaryEmailType: state => state.emailTypes.find(type => type.slug == 'secondary-email'),
	icEmailType: state => state.emailTypes.find(t => t.slug == 'ic')
}

const actions = {
	async getEmailTypes({ commit }) {
		commit("GET_EMAIL_TYPES_REQUEST")
		const response = await emailTypeService.getEmailTypes();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_EMAIL_TYPES_SUCCESS", data)
				break
			default:
				commit("GET_EMAIL_TYPES_ERROR", data)
		}
		return response
	},
	async getEmailType({ commit }, uuid) {
		commit("GET_EMAIL_TYPE_REQUEST")
		const response = await emailTypeService.getEmailType(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_EMAIL_TYPE_SUCCESS", data)
				break
			default:
				commit("GET_EMAIL_TYPE_ERROR", data)
				break
		}
		return response
	},
	async createEmailType({ commit }, params) {
		commit("CREATE_EMAIL_TYPE_REQUEST")
		const response = await emailTypeService.createEmailType(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_EMAIL_TYPE_SUCCESS", data)
				break
			default:
				commit("CREATE_EMAIL_TYPE_ERROR", data)
		}
		return response
	},
	async editEmailType({ commit }, params, uuid) {
		commit("EDIT_EMAIL_TYPE_REQUEST")
		const response = await emailTypeService.editEmailType(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_EMAIL_TYPE_SUCCESS", data)
				break
			default:
				commit("EDIT_EMAIL_TYPE_ERROR", data)
		}
		return response
	},
	async deleteEmailType({ commit }, uuid) {
		commit("DELETE_EMAIL_TYPE_REQUEST")
		const response = await emailTypeService.deleteEmailType(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_EMAIL_TYPE_SUCCESS", data)
				break
			default:
				commit("DELETE_EMAIL_TYPE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_EMAIL_TYPES_REQUEST]: state => {
		state.loading = true
	},
	[GET_EMAIL_TYPES_SUCCESS]: (state, data) => {
		const emailTypes = [...data.email_types]
		state.emailTypes = emailTypes
		state.loading = false
	},
	[GET_EMAIL_TYPES_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_EMAIL_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[GET_EMAIL_TYPE_SUCCESS]: (state, data) => {
		const emailType = { ...data.email_type }
		state.emailType = emailType
		state.loading = false
	},
	[GET_EMAIL_TYPE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_EMAIL_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_EMAIL_TYPE_SUCCESS]: (state, data) => {
		const newEmail = { ...data.email }
		state.emails.push(newEmail);
		state.loading = false
	},
	[CREATE_EMAIL_TYPE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_EMAIL_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_EMAIL_TYPE_SUCCESS]: state => {
		state.loading = false
	},
	[EDIT_EMAIL_TYPE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_EMAIL_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_EMAIL_TYPE_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_EMAIL_TYPE_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const emailType = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}