export const GET_COACHES_REQUEST = "GET_COACHES_REQUEST"
export const GET_COACHES_SUCCESS = "GET_COACHES_SUCCESS"
export const GET_LIMITED_COACHES_SUCCESS = "GET_LIMITED_COACHES_SUCCESS"
export const GET_COACHES_ERROR = "GET_COACHES_ERROR"
export const GET_COACH_REQUEST = "GET_COACH_REQUEST"
export const GET_COACH_SUCCESS = "GET_COACH_SUCCESS"
export const GET_COACH_ERROR = "GET_COACH_ERROR"
export const CREATE_COACH_REQUEST = "CREATE_COACH_REQUEST"
export const CREATE_COACH_SUCCESS = "CREATE_COACH_SUCCESS"
export const CREATE_COACH_ERROR = "CREATE_COACH_ERROR"
export const EDIT_COACH_REQUEST = "EDIT_COACH_REQUEST"
export const EDIT_COACH_SUCCESS = "EDIT_COACH_SUCCESS"
export const EDIT_COACH_ERROR = "EDIT_COACH_ERROR"
export const CUSTOM_COACH_REQUEST = "CUSTOM_COACH_REQUEST"
export const CUSTOM_COACH_SUCCESS = "CUSTOM_COACH_SUCCESS"
export const CUSTOM_COACH_ERROR = "CUSTOM_COACH_ERROR"
export const DELETE_COACH_REQUEST = "DELETE_COACH_REQUEST"
export const DELETE_COACH_SUCCESS = "DELETE_COACH_SUCCESS"
export const DELETE_COACH_ERROR = "DELETE_COACH_ERROR"
export const CLEAR_CURRENT_COACH = "CLEAR_CURRENT_COACH"
export const BROADCAST_DNUA_UPDATED = "BROADCAST_DNUA_UPDATED"
