import {
    apiRequest
} from "@/utilities"

export const roleService = {
    async getRoles() {
        const getRolesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/roles", getRolesRequest)
    },
}
