export function url() {
  const url =
    process.env.APP_ENV === 'production'
      ? process.env.PUBLIC_URL
      : process.env.VUE_APP_URL;
  return url;
}

export function origin() {
  // const origin = process.env.APP_ENV === 'production' ? process.env.PUBLIC_URL : process.env.MIX_APP_URL;
  // return origin;
  return process.env.VUE_APP_URL
}
