import {
  isEmpty,
  strToLower
} from "@/utilities"

const state = {
  multipleAssigns: {},
  tmpltKey: Number(new Date()),
}

const getters = {
  coachFinder: (state, getters, rootState, rootGetters) => cUuid => {
    return rootGetters['coaches/coachByUuid'](cUuid);
  },
  workingAssignmentSheet: (state, getters, rootState, rootGetters) => {
    return rootGetters['workingAssignmentSheet/workingAssignmentSheet']
  },
  wasReconciliation: (state, getters, rootState, rootGetters) => {
    let wasRecFindByWasUuid = rootGetters['wasReconciliation/wasRecFindByWasUuid']
    let wasr = wasRecFindByWasUuid(getters.workingAssignmentSheet.uuid)
    return wasr
  },
  // Get ReconciliationItem by 'reconciliation_key'
  reconciliationItems: (state, getters, rootState, rootGetters) => {
    let reconciliationItemsByRecKey = rootGetters['reconciliationItem/reconciliationItemsByRecKey']
    return reconciliationItemsByRecKey(getters.wasReconciliation?.reconciliation_key)
  },

  isCoordinatingCoach: (state, getters, rootState, rootGetters) => coachesId => {
    let wasData = rootGetters['workingAssignmentSheet/workingAssignmentSheetMapped']
    let { course_starts } = wasData
    let CC = {}
    if (!isEmpty(course_starts)) {
      course_starts.forEach(cs => {
        if (isEmpty(CC)) {
          if (!isEmpty(cs.json_data)) {
            if (!isEmpty(cs.json_data.coordinating_coach)) {
              CC = cs.json_data.coordinating_coach
            }
          }
        }
      })
    }
    if (!isEmpty(CC)) {
      // eslint-disable-next-line no-prototype-builtins
      if (CC.hasOwnProperty(coachesId)) {
        return true
      }
    }
    return false

  },

  wasExcelRecords: (state, getters, rootState, rootGetters) => {
    return !isEmpty(getters.wasReconciliation) ? rootGetters['students/wasrStudents'](getters.wasReconciliation.uuid) : []
  },

  coachExcelRecordsAll: (state, getters, rootState, rootGetters) => {
    return rootGetters['coachAssignmentReconciliation/CARecByKey'](getters.wasReconciliation?.reconciliation_key).map(car => {
      car.students = rootGetters['students/carStudents'](car.uuid);
      return car;
    });
  },

  facultyExcelRecordsAll: (state, getters, rootState, rootGetters) => {
    return rootGetters['courseStartReconciliation/CSRecByKey'](getters.wasReconciliation?.reconciliation_key).map(csr => {
      csr.students = rootGetters['students/csrStudents'](csr.uuid);
      return csr;
    });
  },

  misMatchedItemsFacultyAll: (state, getters) => {
    return getters.reconciliationItems.filter(i =>
      i.reconciliation_itemable_type == "App\\Models\\CourseStartReconciliation"
    );
  },

  misMatchedItemsCoachAll: (state, getters) => {
    return getters.reconciliationItems.filter(i =>
      i.reconciliation_itemable_type == "App\\Models\\CoachAssignmentReconciliation"
    );
  },

  misMatchedItemsAdminAll: (state, getters) => {
    return getters.reconciliationItems.filter(i =>
      i.reconciliation_itemable_type == "App\\Models\\WorkingAssignmentSheetReconciliation"
    );
  },

  misMatchedItemsFaculty: (state, getters) => {
    return getters.misMatchedItemsFacultyAll.filter(i =>
      isEmpty(i.assigned_to_uuid)
      && isEmpty(i.assigned_to_faculty_uuid)
      && isEmpty(i.sibling_assigned)
    )
  },

  misMatchedItemsCoach: (state, getters) => {
    return getters.misMatchedItemsCoachAll.filter(i =>
      isEmpty(i.assigned_to_uuid)
      && isEmpty(i.assigned_to_faculty_uuid)
      && isEmpty(i.sibling_assigned)
    )
  },

  misMatchedItemsAdmin: (state, getters) => {
    return getters.misMatchedItemsAdminAll.filter(i =>
      isEmpty(i.assigned_to_uuid)
      && isEmpty(i.assigned_to_faculty_uuid)
      && isEmpty(i.sibling_assigned) &&
      i?.exclude !== true
    )
  },

  assignedAdminStudents: (state, getters) => {
    return getters.misMatchedItemsAdminAll.filter(i =>
      !isEmpty(i.assigned_to_uuid)
      || !isEmpty(i.assigned_to_faculty_uuid)
      && isEmpty(i.sibling_assigned)
    )
  },

  assignedCoachStudents: (state, getters) => {
    return getters.misMatchedItemsCoachAll.filter(i =>
      !isEmpty(i.assigned_to_uuid)
      || !isEmpty(i.assigned_to_faculty_uuid)
      && isEmpty(i.sibling_assigned)
    )
  },

  assignedFacultyStudents: (state, getters) => {
    return getters.misMatchedItemsFacultyAll.filter(i =>
      !isEmpty(i.assigned_to_uuid)
      || !isEmpty(i.assigned_to_faculty_uuid)
      && isEmpty(i.sibling_assigned)
    )
  },

  matchedRecords: (state, getters, rootState, rootGetters) => {

    let workingAssignmentSheet = rootGetters['workingAssignmentSheet/workingAssignmentSheet']
    let courseFilterArray = rootGetters['course/courseFilterArray']
    let wasCourses = courseFilterArray(workingAssignmentSheet.university_programs_uuids)

    wasCourses = wasCourses.sort((f, s) => f.number.localeCompare(s.number))

    let misMatchedItemsAdminSlugArray = getters.misMatchedItemsAdminAll.map(a => a?.item?.cfl_slug)
    let misMatchedItemsCoachSlugArray = getters.misMatchedItemsCoachAll.map(a => a?.item?.cfl_slug)
    let misMatchedItemsFacultySlugArray = getters.misMatchedItemsFacultyAll.map(a => a?.item?.cfl_slug)

    let matchedRecords = {}

    getters.coachExcelRecordsAll.forEach((c) => {
      let coachAssignment = rootGetters["coachAssignment/coachAssignmentsByUuid"](c.coach_assignments_uuid);

      c?.students?.filter(s => {
        return isEmpty(misMatchedItemsCoachSlugArray) ? s : !misMatchedItemsCoachSlugArray.includes(s?.cfl_slug)
      }).forEach(s => {
        if (isEmpty(matchedRecords[c?.course_uuid])) {
          matchedRecords[c?.course_uuid] = {
            courseName: c?.course_name,
            // csSection: cs_section,
            coachData: {},
            facultyData: {},
            course_starts_uuid: coachAssignment?.course_starts_uuid,
            course_uuid: c?.course_uuid
          }
        }

        if (isEmpty(matchedRecords[c?.course_uuid].coachData[c?.coaches_uuid])) {

          let coachName = c?.coaches_display_name ?? "Unknown Coach";
          let coachLastName = c?.coaches_last_name ?? "Coach";

          let isCC = getters.isCoordinatingCoach(c?.coaches_uuid)

          matchedRecords[c?.course_uuid].coachData[c?.coaches_uuid] = {
            coachName,
            coachLastName,
            isCC,
            students: [],
            car_uuid: c?.uuid,
            ca_uuid: c?.coach_assignments_uuid,
            isFaculty: false,
            isAssigned: false
          }
        }

        matchedRecords[c?.course_uuid].coachData[c?.coaches_uuid].students.push(s)
      });
    });

    getters.facultyExcelRecordsAll.forEach((f) => {
      f?.students.filter(b => (
        !misMatchedItemsFacultySlugArray.includes(b.cfl_slug)
      )).forEach(d => {
        if (isEmpty(matchedRecords[f?.course_uuid])) {
          matchedRecords[f?.course_uuid] = {
            courseName: f?.course_name ?? "Unknown Course",
            coachData: {},
            facultyData: {},
          }
        }

        if (isEmpty(matchedRecords[f?.course_uuid].facultyData[f.faculty_uuid])) {
          let coachName = f?.faculty_name;
          let coachLastName = f?.faculty_last_name;
          matchedRecords[f?.course_uuid].facultyData[f.faculty_uuid] = {
            coachName,
            coachLastName,
            students: [],
            isFaculty: true,
            isAssigned: false,
            csf_uuid: f.faculty_uuid
          }
        }

        matchedRecords[f?.course_uuid].facultyData[f.faculty_uuid].students.push(d)
      });

    });

    let admin_assigned = getters.assignedAdminStudents
    admin_assigned = admin_assigned.map(i => ({ ...i, byAdmin: 1 }))

    let coach_faculty_assigned = getters.assignedCoachStudents.concat(getters.assignedFacultyStudents)
    coach_faculty_assigned = coach_faculty_assigned.map(i => ({ ...i, byAdmin: 0 }))

    let assigned_students = admin_assigned.concat(coach_faculty_assigned)

    assigned_students.forEach((c) => {
      if (!isEmpty(c.assigned_to_uuid)) {
        let ca = rootGetters["coachAssignment/coachAssignmentsByUuid"](c?.assigned_to_uuid);
        let cs = rootGetters["courseStart/csByUuid"](ca?.course_starts_uuid);

        if (isEmpty(matchedRecords[cs?.courses_uuid])) {
          matchedRecords[cs?.courses_uuid] = {
            courseName: c.item.course_number,
            coachData: {},
            facultyData: {},
          }
        }

        if (isEmpty(matchedRecords[cs?.courses_uuid].coachData[ca?.coaches_uuid])) {

          let coachName = c?.assigned_to_name;
          let isCC = getters.isCoordinatingCoach(ca?.coaches_uuid)
          matchedRecords[cs?.courses_uuid].coachData[ca?.coaches_uuid] = {
            coachName,
            isCC,
            students: [],
          }
        }

        matchedRecords[cs?.courses_uuid].coachData[ca?.coaches_uuid].students.push({
          ...c.item,
          uuid: c.uuid,
          isAssigned: true,
          isFaculty: false,
          history: c.history ?? [],
          unknown: c.unknown ?? false
        })

      }

      if (!isEmpty(c.assigned_to_faculty_uuid)) {
        let csf = rootGetters["courseStartFaculty/csFacultyByUuid"](c.assigned_to_faculty_uuid);
        let cs = rootGetters["courseStart/csByUuid"](csf?.course_starts_uuid);

        if (isEmpty(matchedRecords[cs?.courses_uuid])) {
          matchedRecords[cs?.courses_uuid] = {
            courseName: c?.item?.course,
            coachData: {},
            facultyData: {},
          }
        }

        if (isEmpty(matchedRecords[cs?.courses_uuid].facultyData[c?.assigned_to_faculty_uuid])) {
          let coachName = c?.assigned_to_name;
          matchedRecords[cs?.courses_uuid].facultyData[c?.assigned_to_faculty_uuid] = {
            coachName,
            students: [],
            csr_uuid: c?.assigned_to_faculty_uuid,
          }
        }

        matchedRecords[cs?.courses_uuid].facultyData[c?.assigned_to_faculty_uuid].students.push({
          ...c.item,
          uuid: c.uuid,
          isAssigned: true,
          isFaculty: true,
          csf_uuid: c?.assigned_to_faculty_uuid,
          history: c.history ?? [],
          unknown: c.unknown ?? false
        })
      }

    });

    let sortedRecs = {}

    wasCourses.forEach(c => {
      if (!isEmpty(matchedRecords[c.uuid])) {
        sortedRecs[c.uuid] = matchedRecords[c.uuid]
        let coachData = sortedRecs[c.uuid].coachData
        let coaches = [];
        for (let k in coachData) {
          let coach = coachData[k]
          coaches.push({
            ...coach,
            coach_uuid: k
          })
        }
        coaches = coaches.filter(i => !isEmpty(i.coachLastName)).sort((a, b) => a?.coachLastName.localeCompare(b?.coachLastName));
        let newCoachData = {}
        coaches.forEach(c => {
          newCoachData[c.coach_uuid] = c
        })
        sortedRecs[c.uuid].coachData = newCoachData
      }
    });

    return sortedRecs;
  },

  misMatchedRecordsObj: (state, getters, rootState, rootGetters) => {

    let workingAssignmentSheet = rootGetters['workingAssignmentSheet/workingAssignmentSheet']
    let courseFilterArray = rootGetters['course/courseFilterArray']
    let proCourses = courseFilterArray(workingAssignmentSheet.university_programs_uuids);

    let cs = rootGetters['courseStart/csByWAS'](proCourses.map(c => c.uuid), workingAssignmentSheet)

    let wasCourses = cs.map(cs => rootGetters['course/courseFindUuid'](cs.courses_uuid));

    wasCourses = wasCourses.sort((f, s) => f.number.localeCompare(s.number));

    let canAccess = rootGetters['auth/canAccess']

    let admins = getters.misMatchedItemsAdmin
    let coaches = getters.misMatchedItemsCoach
    let faculty = getters.misMatchedItemsFaculty

    let misMatchedRecords = {}
    let unknownCourse = {}
    let siblings = []

    for (let z in getters.misMatchedItemsCoach) {

      let c = getters.misMatchedItemsCoach[z]

      // Filter out duplicated that have already been matched
      // if (getters.misMatchedItemsAdminAll.some(r => r.assigned_to_uuid !== null && r.cfl_slug == c.cfl_slug)) {
      //   console.log("here",c)
      //   continue;
      // }

      let course = rootGetters['course/courseFindUuid'](c?.item?.courses_uuid)
      let course_starts_uuid = c?.item?.course_starts_uuid ?? "Unknown";
      let ca_uuid = c?.item?.coach_assignments_uuid

      let coachAssignment = rootGetters['coachAssignment/coachAssignmentsByUuid'](c?.item?.coach_assignments_uuid);

      let courses_uuid = c.item?.courses_uuid;
      const coach = rootGetters['coaches/coachByUuid'](coachAssignment?.coaches_uuid)
      const c_name = coach?.display_name ?? "Unknown Coach";
      const c_l_name = coach?.last_name ?? "Unknown Coach";

      // let section = !isEmpty(c.coach_assignment.assigned_students_section) ?
      // 	c.coach_assignment.assigned_students_section.map(a => a.section).toString().replace(',', ', ') :
      // 	""

      if (isEmpty(misMatchedRecords[courses_uuid])) {
        misMatchedRecords[courses_uuid] = {
          course,
          course_starts_uuid,
          admins: {
            coaches: {},
            unknown: [],
            duplicates: []
          },
          coaches: {},
          faculty: {},
          unknown: [],

          notExistCourse: {}
        }
      }

      if (isEmpty(misMatchedRecords[courses_uuid].coaches[ca_uuid])) {
        misMatchedRecords[courses_uuid].coaches[ca_uuid] = {
          // section,
          name: c_name,
          coachLastName: c_l_name,
          students: [],
        }
      }

      misMatchedRecords[courses_uuid].coaches[ca_uuid].students.push(c)
    }

    // for (let n in getters.misMatchedItemsFaculty) {
    //   let s = getters.misMatchedItemsFaculty[n]
    // }

    faculty.forEach((s) => {

      // let course = s?.item?.course;
      let courses_uuid = s?.item?.courses_uuid;
      let course = rootGetters['course/courseFindUuid'](s?.item?.courses_uuid)
      let course_starts_uuid = s?.item?.course_starts_uuid ?? "Unknown";
      let csf = rootGetters['courseStartFaculty/csFacultyByUuid'](s?.item?.course_start_faculities_uuid)

      let fac_name = csf?.display_name;
      let section = csf?.section;

      if (isEmpty(misMatchedRecords[courses_uuid])) {
        misMatchedRecords[courses_uuid] = {
          course,
          course_starts_uuid,
          admins: {
            coaches: {},
            unknown: [],
            duplicates: []
          },
          coaches: {},
          faculty: {},
          unknown: [],
          notExistCourse: {}
        }
      }

      if (isEmpty(misMatchedRecords[courses_uuid].faculty[s?.item?.course_start_faculities_uuid])) {
        misMatchedRecords[courses_uuid].faculty[s?.item?.course_start_faculities_uuid] = {
          name: fac_name,
          section,
          courseStartReconciliationsUuid: s.uuid,
          students: [],
        }
      }

      misMatchedRecords[courses_uuid].faculty[s?.item?.course_start_faculities_uuid].students.push(s)
    })

    for (let w in getters.misMatchedItemsAdmin) {
      let a = getters.misMatchedItemsAdmin[w]
    }

    admins.forEach((a) => {
      let found = false;
      let course = rootGetters['course/courseFindUuid'](a?.item?.courses_uuid)
      let course_starts_uuid = a?.item?.course_starts_uuid ?? null;

      if (isEmpty(course)) {

        if (isEmpty(unknownCourse[a?.item?.course])) {
          unknownCourse[a?.item?.course] = {
            course: a?.item?.course,
            course_starts_uuid,
            admins: {
              coaches: {

              },
              faculty: {

              },
              unknown: [],
              duplicates: []
            },
            coaches: {

            },
            faculty: {

            },
            unknown: [],
            notExistCourse: {}
          }
        }

        unknownCourse[a?.item?.course].admins.unknown.push(a)

      } else {
        if (isEmpty(course_starts_uuid)) {
          if (isEmpty(unknownCourse[course?.uuid])) {
            unknownCourse[course?.uuid] = {
              course,
              course_starts_uuid,
              admins: {
                coaches: {
  
                },
                faculty: {
  
                },
                unknown: [],
                duplicates: []
              },
              coaches: {
  
              },
              faculty: {
  
              },
              unknown: [],
              notExistCourse: {}
            }
          }

          unknownCourse[course?.uuid].admins.unknown.push(a)

        } else {
          if (isEmpty(misMatchedRecords[a?.item?.courses_uuid])) {

            misMatchedRecords[a?.item?.courses_uuid] = {
              course,
              course_starts_uuid,
              admins: {
                coaches: {

                },
                faculty: {

                },
                unknown: [],
                duplicates: []
              },
              coaches: {

              },
              faculty: {

              },
              unknown: [],
              notExistCourse: {}
            }
          }

          // Check if it's a duplicate
          if (a?.meta.duplicate) {
            misMatchedRecords[a?.item?.courses_uuid].admins.duplicates.push(a)
            return
          }

          misMatchedRecords[a?.item?.courses_uuid].admins.unknown.push(a)
        }


      }

    })

    let sortedRecords = {}

    wasCourses.forEach(c => {
      if (!isEmpty(misMatchedRecords[c.uuid])) {
        sortedRecords[c.uuid] = misMatchedRecords[c.uuid];

        let coachData = sortedRecords[c.uuid].coaches
        let coaches = [];

        for (let k in coachData) {
          let coach = coachData[k]
          coaches.push({
            ...coach,
            coach_uuid: k
          })
        }

        coaches = coaches.filter(i => !isEmpty(i.coachLastName)).sort((a, b) => a?.coachLastName.localeCompare(b?.coachLastName));

        let newCoachData = {}

        coaches.forEach(c => {
          newCoachData[c.coach_uuid] = c
        })

        sortedRecords[c.uuid].coaches = newCoachData
      }
    })

    return {
      misMatchedRecords: {
        ...sortedRecords,
        ...unknownCourse
      },
      siblings
    }
  },

  misMatchedCoachOnly: (state, getters, rootState, rootGetters) => {

    // Only DUPICATES that haven't been assigned should be output

    const misMatchedRecords = {};

    for (let x in getters.misMatchedItemsCoachAll) {
      let c = getters.misMatchedItemsCoachAll[x];
      // Filter mismatches that have already been assigned.

      if (
        // Record has already been matched
        c.assigned_to_uuid !== null ||
        // Search other existing mismatched records to see if they have been matched
        getters.misMatchedItemsCoachAll.some(r => r.assigned_to_uuid == null && r?.item?.cfl_slug == c?.item?.cfl_slug && r.sibling_assigned == true) ||
        getters.misMatchedItemsFacultyAll.some(r => ((r.assigned_to_uuid == null && !isEmpty(r.assigned_to_faculty_uuid) || (r.assigned_to_faculty_uuid == null && !isEmpty(r.assigned_to_uuid)))) && r?.item?.cfl_slug == c?.item?.cfl_slug)
      ) {

        continue;
      }

      // let course = c.item.course

      let courses_uuid = c.item.courses_uuid;
      let course_starts_uuid = c?.item?.course_starts_uuid;
      let course = rootGetters['course/courseFindUuid'](c?.item?.courses_uuid)
      let ca = rootGetters["coachAssignment/coachAssignmentsByUuid"](c.item.coach_assignments_uuid);
      let c_name = rootGetters["coaches/coachByUuid"](ca?.coaches_uuid)?.display_name;

      let section = c.item.section;

      let ca_uuid = c.item.coach_assignments_uuid

      if (isEmpty(misMatchedRecords[courses_uuid])) {
        misMatchedRecords[courses_uuid] = {
          course,
          course_starts_uuid,
          coaches: {

          },
          faculty: {

          },
        }
      }

      if (isEmpty(misMatchedRecords[courses_uuid].coaches[ca_uuid])) {
        misMatchedRecords[courses_uuid].coaches[ca_uuid] = {
          section,
          name: c_name,
          students: [],
        }
      }

      misMatchedRecords[courses_uuid].coaches[ca_uuid].students.push(c)
    }

    for (let y in getters.misMatchedItemsFaculty) {

      let s = getters.misMatchedItemsFaculty[y]

      // let course = s.item.course;
      let courses_uuid = s?.item?.courses_uuid;
      let course_starts_uuid = s?.item?.course_starts_uuid;
      let course = rootGetters['course/courseFindUuid'](s?.item?.courses_uuid);
      let fac_name = rootGetters["courseStartFaculty/csFacultyByUuid"](s.item.course_start_faculities_uuid)?.display_name;
      let section = s?.item?.section;
      let fac_uuid = s.item.course_start_faculities_uuid;

      if (isEmpty(misMatchedRecords[courses_uuid])) {
        misMatchedRecords[courses_uuid] = {
          course,
          course_starts_uuid,
          admins: {
            coaches: {},
            unknown: [],
            duplicates: []
          },
          coaches: {},
          faculty: {},
          unknown: [],
          notExistCourse: {}
        }
      }

      if (isEmpty(misMatchedRecords[courses_uuid].faculty[s?.item?.course_start_faculities_uuid])) {
        misMatchedRecords[courses_uuid].faculty[s?.item?.course_start_faculities_uuid] = {
          name: fac_name,
          section,
          courseStartReconciliationsUuid: s.uuid,
          students: [],
        }
      }

      misMatchedRecords[courses_uuid].faculty[s?.item?.course_start_faculities_uuid].students.push(s)
    }

    return misMatchedRecords
  },

  misMatchedRecords: (state, getters) => {
    return getters.misMatchedRecordsObj.misMatchedRecords
  },

  siblings: (state, getters) => {
    return getters.misMatchedRecordsObj.siblings
  },

  summary: (state, getters, rootState, rootGetters) => {

    let was = rootGetters['workingAssignmentSheet/workingAssignmentSheet']
    let canAccess = rootGetters['auth/canAccess']
    const total_university_students = getters.wasExcelRecords.length
    let total_university_coaches = 0
    let total_university_faculties = 0

    getters.coachExcelRecordsAll.forEach(i => {
      total_university_coaches += i?.students?.length
    })
    getters.facultyExcelRecordsAll.forEach(i => {
      total_university_faculties += i?.students?.length
    })

    let misMatchedRecords = getters.misMatchedRecords

    let misMatchedItemsAdminAll = getters.misMatchedItemsAdminAll
    let misMatchedItemsAdmin = getters.misMatchedItemsAdmin

    let misMatchedItemsCoachAll = getters.misMatchedItemsCoachAll
    let misMatchedItemsCoach = getters.misMatchedItemsCoach

    let misMatchedItemsFacultyAll = getters.misMatchedItemsFacultyAll
    let misMatchedItemsFaculty = getters.misMatchedItemsFaculty

    const matched_students_admin = total_university_students - misMatchedItemsAdminAll.length
    const matched_students_coaches = total_university_coaches - misMatchedItemsCoachAll.length
    const matched_students_faculty = total_university_faculties - misMatchedItemsFacultyAll.length
    const dupDetails = (!isEmpty(getters.matchedRecords.dupDetails)) ? getters.matchedRecords.dupDetails : {}

    let assigned_dups_exact = 0;
    let assigned_dups_all = 0;
    let assigned_dups_diff = 0;
    let assigned_admin_dups_exact = 0;
    let assigned_admin_dups_all = 0;
    let assigned_admin_diff = 0;
    let assigned_coach_dups_exact = 0;
    let assigned_coach_dups_all = 0;
    let assigned_coach_diff = 0;

    if (!isEmpty(dupDetails)) {
      assigned_admin_dups_exact = dupDetails.admins.uniqueData.length
      assigned_coach_dups_exact = dupDetails.coaches.uniqueData.length

      assigned_admin_dups_all = dupDetails.admins.allData.length
      assigned_coach_dups_all = dupDetails.coaches.allData.length

      assigned_admin_diff = assigned_admin_dups_all - assigned_admin_dups_exact
      assigned_coach_diff = assigned_coach_dups_all - assigned_coach_dups_exact

      assigned_dups_exact = assigned_admin_dups_exact + assigned_coach_dups_exact
      assigned_dups_all = assigned_admin_dups_all + assigned_coach_dups_all
      assigned_dups_diff = assigned_admin_diff + assigned_coach_diff
    }

    const assigned_admin_students = misMatchedItemsAdminAll.length - misMatchedItemsAdmin.length;
    const assigned_coach_students = misMatchedItemsCoachAll.length - misMatchedItemsCoach.length;
    const assigned_faculty_students = misMatchedItemsFacultyAll.length - misMatchedItemsFaculty.length;
    const assigned_students = assigned_coach_students + assigned_admin_students + assigned_faculty_students;

    let pending_admin_students = misMatchedItemsAdmin.length

    if (canAccess('lead-coach') && was.status == 'lead-coach-reviewing-university') {
      misMatchedItemsAdmin = misMatchedItemsAdmin.filter(i => !(i.meta?.duplicate))
      pending_admin_students = misMatchedItemsAdmin.length
    }

    const pending_coach_students = misMatchedItemsCoach.length
    const pending_faculty_students = misMatchedItemsFaculty.length
    const pending_students = pending_admin_students + pending_coach_students + pending_faculty_students

    let admin_dups = 0

    for (let courses_uuid in misMatchedRecords) {
      let misMatchedRecord = misMatchedRecords[courses_uuid]
      const dups = misMatchedRecord.admins.duplicates.filter(i => i.unknown != 1)
      admin_dups += dups.length
    }

    const courses = []

    for (let courses_uuid in getters.matchedRecords) {
      let { coachData, courseName } = getters.matchedRecords[courses_uuid]
      let coaches = []
      let total = 0
      for (let c_uuid in coachData) {
        let { students, coachName } = coachData[c_uuid]
        if (students.length > 0) {
          total += students.length
          coaches.push({
            name: coachName,
            total: students.length
          })
        }
      }
      courses.push({
        name: courseName,
        coaches,
        total
      })
    }

    return {
      total_university_students,
      total_university_coaches,
      total_university_faculties,
      matched_students_admin: (matched_students_admin + assigned_students),
      matched_students_coaches: (matched_students_coaches + assigned_students),
      assigned_students,
      assigned_admin_students,
      assigned_coach_students,
      assigned_faculty_students,
      pending_students,
      pending_admin_students,
      pending_coach_students,
      pending_faculty_students,
      courses,
      admin_dups,
      assigned_dups: assigned_dups_exact,
    }
  },

  getTotal: state => (data, key) => {
    let a = 0
    for (let i in data) {
      a += data[i][key].length
    }
    return a
  },

  isDisabledWas: (state, getters, rootState, rootGetters) => {
    let canAccess = rootGetters['auth/canAccess']
    if (getters.workingAssignmentSheet.status == 'rosters-finalized') return true
    if (canAccess('lead-coach') && (getters.workingAssignmentSheet.status == 'lead-coach-reviewing-duplicates' || getters.workingAssignmentSheet.status == 'lead-coach-reviewing-university')) {
      return false
    }
    if (canAccess('super-admin')) return false
    return true
  },

  isSingleAssignButtonDisable: () => false,
  disableCoachSide: (state, getters, rootState, rootGetters) => {
    let canAccess = rootGetters['auth/canAccess']
    if (canAccess('lead-coach') && getters.wasReconciliation?.type == 'coach-university' && (
      getters.workingAssignmentSheet.status == 'lead-coach-reviewing-university'
      || getters.workingAssignmentSheet.status == 'lead-coach-reviewing-duplicates'
    )) {
      return true
    }
    return false
  },
  multipleAssigns: state => state.multipleAssigns,
  getTmpltKey: state => state.tmpltKey,

}

const actions = {
  updateMultipleAssigns({ commit }, params) {
    commit('updateMultipleAssigns', params)
  },
  pushMultipleAssigns({ commit }, obj) {
    commit('pushMultipleAssigns', obj)
  },
  removeFromMultipleAssigns({ commit }, uuid) {
    commit('removeFromMultipleAssigns', uuid)
  },
  updateTmpltKey({ commit }) {
    commit('setTmpltKey', Number(new Date()))
  },
}

const mutations = {
  updateMultipleAssigns: (state, params) => {
    state.multipleAssigns = params
  },
  pushMultipleAssigns: (state, obj) => {
    if (isEmpty(obj)) return false
    let ObjKeys = Object.keys(obj)
    if (isEmpty(ObjKeys)) return false
    let uuid = ObjKeys.find((i, index) => index === 0)

    let multipleAssigns = state.multipleAssigns
    let multipleAssignsKeys = Object.keys(multipleAssigns)
    let findIndex = multipleAssignsKeys.findIndex(i => i == uuid)
    if (findIndex < 0) {
      state.multipleAssigns = {
        ...state.multipleAssigns,
        ...obj
      }
    }
  },
  removeFromMultipleAssigns: (state, uuid) => {
    let multipleAssigns = state.multipleAssigns
    let multipleAssignsKeys = Object.keys(multipleAssigns)
    let findIndex = multipleAssignsKeys.findIndex(i => i == uuid)
    if (findIndex >= 0) {
      let tmp = state.multipleAssigns
      delete tmp[uuid]
      state.multipleAssigns = tmp
    }
  },
  setTmpltKey: (state, params) => {
    state.tmpltKey = params
  },
}

export const wasReconciliationData = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
