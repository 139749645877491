export const reportsCoachRoutes = {
  path: "coach",
  name: "reports coach",
  meta: {
    authRequired: true,
    allowedRoles: [
      'super-admin'
    ],
    label: "Coach Reports",
  },
  component: () => import("@/pages/reports/general/ReportsGeneralIndex.vue"),
  children: [
    {
      path: "without-user",
      name: "report coach without-users",
      meta: {
        label: "Coaches Without Assigned Users",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "without-resume",
      name: "report coach without-users",
      meta: {
        label: "Coaches Without Resumes",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "without-education",
      name: "report coach without-education",
      meta: {
        label: "Coaches Without Education Records",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    }
  ]
};