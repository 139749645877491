export const GET_PROGRAM_COURSE_LEVELS_REQUEST = "GET_PROGRAM_COURSE_LEVELS_REQUEST"
export const GET_PROGRAM_COURSE_LEVELS_SUCCESS = "GET_PROGRAM_COURSE_LEVELS_SUCCESS"
export const GET_PROGRAM_COURSE_LEVELS_ERROR = "GET_PROGRAM_COURSE_LEVELS_ERROR"
export const GET_PROGRAM_COURSE_LEVEL_REQUEST = "GET_PROGRAM_COURSE_LEVEL_REQUEST"
export const GET_PROGRAM_COURSE_LEVEL_SUCCESS = "GET_PROGRAM_COURSE_LEVEL_SUCCESS"
export const GET_PROGRAM_COURSE_LEVEL_ERROR = "GET_PROGRAM_COURSE_LEVEL_ERROR"
export const CREATE_PROGRAM_COURSE_LEVEL_REQUEST = "CREATE_PROGRAM_COURSE_LEVEL_REQUEST"
export const CREATE_PROGRAM_COURSE_LEVEL_SUCCESS = "CREATE_PROGRAM_COURSE_LEVEL_SUCCESS"
export const CREATE_PROGRAM_COURSE_LEVEL_ERROR = "CREATE_PROGRAM_COURSE_LEVEL_ERROR"
export const EDIT_PROGRAM_COURSE_LEVEL_REQUEST = "EDIT_PROGRAM_COURSE_LEVEL_REQUEST"
export const EDIT_PROGRAM_COURSE_LEVEL_SUCCESS = "EDIT_PROGRAM_COURSE_LEVEL_SUCCESS"
export const EDIT_PROGRAM_COURSE_LEVEL_ERROR = "EDIT_PROGRAM_COURSE_LEVEL_ERROR"
export const DELETE_PROGRAM_COURSE_LEVEL_REQUEST = "DELETE_PROGRAM_COURSE_LEVEL_REQUEST"
export const DELETE_PROGRAM_COURSE_LEVEL_SUCCESS = "DELETE_PROGRAM_COURSE_LEVEL_SUCCESS"
export const DELETE_PROGRAM_COURSE_LEVEL_ERROR = "DELETE_PROGRAM_COURSE_LEVEL_ERROR"