import {
	apiRequest,
	processInput
} from "@/utilities"

export const universityProceduresService = {
	async getUniversityProcedures() {
		const getuniversityProceduresRequest = {
			method: "GET"
		}
		return await apiRequest("/api/university-procedure", getuniversityProceduresRequest)
	},
	async createUniversityProcedure(params) {
		const createData = processInput(params);
		const postuniversityProcedureRequest = {
			method: "POST",
			data: createData
		}

		return await apiRequest("/api/university-procedure", postuniversityProcedureRequest)
	},
	async getUniversityProcedure(uuid) {

		const getuniversityProcedureRequest = {
			method: "GET"
		}

		return await apiRequest(`/api/university-procedure/${uuid}`, getuniversityProcedureRequest);
	},
	async editUniversityProcedure(data, uuid) {
		let editData = processInput(data)
		editData.append("_method", "PATCH");
		const updateuniversityProcedureRequest = {
			method: "POST",
			data: editData
		}

		return await apiRequest(`/api/university-procedure/${uuid}`, updateuniversityProcedureRequest);
	},
	async deleteUniversityProcedure(uuid) {

		const deleteuniversityProcedureRequest = {
			method: "DELETE"
		}

		return await apiRequest(`/api/university-procedure/${uuid}`, deleteuniversityProcedureRequest);
	},
	async sendPartnershipForm(params) {
		const sendPartnershipFormRequest = {
			method: "POST",
			data: processInput(params)
		}

		return await apiRequest("/api/send-partnership-form", sendPartnershipFormRequest);
	}
}