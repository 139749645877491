import {
    GET_UNIVERSITY_PROGRAM_POCS_REQUEST,
    GET_UNIVERSITY_PROGRAM_POCS_SUCCESS,
    GET_UNIVERSITY_PROGRAM_POCS_ERROR,
    GET_UNIVERSITY_PROGRAM_POC_REQUEST,
    GET_UNIVERSITY_PROGRAM_POC_SUCCESS,
    GET_UNIVERSITY_PROGRAM_POC_ERROR,
    CREATE_UNIVERSITY_PROGRAM_POC_REQUEST,
    CREATE_UNIVERSITY_PROGRAM_POC_SUCCESS,
    CREATE_UNIVERSITY_PROGRAM_POC_ERROR,
    EDIT_UNIVERSITY_PROGRAM_POC_REQUEST,
    EDIT_UNIVERSITY_PROGRAM_POC_SUCCESS,
    EDIT_UNIVERSITY_PROGRAM_POC_ERROR,
    DELETE_UNIVERSITY_PROGRAM_POC_REQUEST,
    DELETE_UNIVERSITY_PROGRAM_POC_SUCCESS,
    DELETE_UNIVERSITY_PROGRAM_POC_ERROR
} from "@/actions"

import {
    universityProgramPocs
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    universityProgramPocs: [],
    universityProgramPoc: {},
    loading: false,
}

const getters = {
    universityProgramPocsByProgramUuid: state => uuid => state.universityProgramPocs.filter(i => i.university_programs_uuid == uuid),
    universityProgramPoc: state => state.universityProgramPoc,
    universityProgramPocs: state => state.universityProgramPocs,
    isLoadingUPLC: state => state.loading,

}

const actions = {
    async getUniversityProgramPocs({ commit }) {
        commit("GET_UNIVERSITY_PROGRAM_POCS_REQUEST")
        const response = await universityProgramPocs.getUniversityProgramPocs();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_PROGRAM_POCS_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_PROGRAM_POCS_ERROR", data)
        }
        return response
    },
    async getUniversityProgramPoc({ commit }, params) {
        commit("GET_UNIVERSITY_PROGRAM_POC_REQUEST")
        const response = await universityProgramPocs.getUniversityProgramPoc(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_PROGRAM_POC_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_PROGRAM_POC_ERROR", data)
                break
        }
        return response
    },
    async createUniversityProgramPoc({ commit }, params) {
        commit("CREATE_UNIVERSITY_PROGRAM_POC_REQUEST")
        const response = await universityProgramPocs.createUniversityProgramPoc(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_UNIVERSITY_PROGRAM_POC_SUCCESS", data)
                break
            default:
                commit("CREATE_UNIVERSITY_PROGRAM_POC_ERROR", data)
        }
        return response
    },
    async editUniversityProgramPoc({ commit }, params) {
        commit("EDIT_UNIVERSITY_PROGRAM_POC_REQUEST")
        const response = await universityProgramPocs.editUniversityProgramPoc(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_UNIVERSITY_PROGRAM_POC_SUCCESS", data)
                break
            default:
                commit("EDIT_UNIVERSITY_PROGRAM_POC_ERROR", data)
        }
        return response
    },
    async deleteUniversityProgramPoc({ commit }, uuid) {
        commit("DELETE_UNIVERSITY_PROGRAM_POC_REQUEST")
        const response = await universityProgramPocs.deleteUniversityProgramPoc(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_UNIVERSITY_PROGRAM_POC_SUCCESS", uuid)
                break
            default:
                commit("DELETE_UNIVERSITY_PROGRAM_POC_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_UNIVERSITY_PROGRAM_POCS_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_PROGRAM_POCS_SUCCESS]: (state, data) => {
        let universityProgramPoc = [...data.university_program_pocs]
        state.universityProgramPocs = addNewFilter(state.universityProgramPocs, universityProgramPoc)
        state.loading = false
    },
    [GET_UNIVERSITY_PROGRAM_POCS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_UNIVERSITY_PROGRAM_POC_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_PROGRAM_POC_SUCCESS]: (state, data) => {
        let mdata = { ...data.university_program_pocs }
        state.universityProgram = mdata
        state.loading = false
    },
    [GET_UNIVERSITY_PROGRAM_POC_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_UNIVERSITY_PROGRAM_POC_REQUEST]: state => {
    },
    [CREATE_UNIVERSITY_PROGRAM_POC_SUCCESS]: (state, data) => {
        state.universityProgramPocs.push(data.university_program_pocs)
    },
    [CREATE_UNIVERSITY_PROGRAM_POC_ERROR]: state => {
        state.loading = false
    },

    [EDIT_UNIVERSITY_PROGRAM_POC_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_UNIVERSITY_PROGRAM_POC_SUCCESS]: (state, data) => {
        if (data.success) {
            state.universityProgramPocs = state.universityProgramPocs.filter(i => i.uuid != data.university_program.uuid)
            state.universityProgramPocs.push(data.university_program_pocs)
        }
        state.loading = false
    },
    [EDIT_UNIVERSITY_PROGRAM_POC_ERROR]: state => {
        state.loading = false
    },

    [DELETE_UNIVERSITY_PROGRAM_POC_REQUEST]: state => {
    },
    [DELETE_UNIVERSITY_PROGRAM_POC_SUCCESS]: (state, uuid) => {
        state.universityProgramPocs = state.universityProgramPocs.filter(i => i.uuid != uuid)
    },
    [DELETE_UNIVERSITY_PROGRAM_POC_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const universityProgramPoc = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


export function unpackUniversityProgramPoc(data, commit){
    if(!isEmpty(data.point_of_contact)){
        const {point_of_contact} = data
        commit("pointOfContact/GET_POINT_OF_CONTACTS_SUCCESS", {point_of_contacts: [point_of_contact]}, {root: true});
        delete data.point_of_contact
    }
    return data;
}