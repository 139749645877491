import {
	apiRequest,
	processInput,
	isEmpty
} from "@/utilities"

const state = {
}


const getters = {
}

const actions = {
	async coachRegistration({ commit }, params) {
		const csrfCookieRequestOptions = {
			method: 'GET'
		}
		await apiRequest("/sanctum/csrf-cookie", csrfCookieRequestOptions);

		const createData = processInput(params);

		const postRequest = {
			method: "POST",
			data: createData
		}

		const response = await apiRequest(`/api/magic-link`, postRequest);

		return response;
	},
	async coachRegistrationResume({ commit }, params) {
		const csrfCookieRequestOptions = {
			method: 'GET'
		}
		await apiRequest("/sanctum/csrf-cookie", csrfCookieRequestOptions);

		const createData = processInput(params);

		const postRequest = {
			method: "POST",
			data: createData
		}

		const response = await apiRequest(`/api/coach-application-resume`, postRequest);

		return response;
	},
	async universityPartnershipLink({ commit }, params) {
		const csrfCookieRequestOptions = {
			method: 'GET'
		}
		await apiRequest("/sanctum/csrf-cookie", csrfCookieRequestOptions);
		const createData = processInput(params);
		
		const postRequest = {
			method: "POST",
			data: createData
		}

		const response = await apiRequest(`/api/university-magic-link`, postRequest);

		return response;
	},
}

const mutations = {

}


export const basic = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
