import {
    apiRequest,
    processInput,
    isEmpty,
    processQueryParams
} from "@/utilities"

export const documentService = {
    async getDocuments(params) {
        let getParams = processQueryParams(params)
        const getDocumentsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/documents", getDocumentsRequest)
    },
    async getDocument(uuid) {
        const getDocumentRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/documents/${uuid}`, getDocumentRequest)
    },
    async createDocument(params) {
        const createData = processInput(params)
        const createDocumentRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/documents", createDocumentRequest)
    },
    async editDocument(params) {
        let uuid = params.uuid
        if(isEmpty(uuid)){
            uuid = params.uuids.find((i,idx) => idx === 0)
        }
        let editData = processInput(params)
        editData.append("_method", "PATCH")
        const editDocumentRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/documents/${uuid}`, editDocumentRequest)
    },
    async deleteDocument(params) {
        let uuid = params.uuid
        if(isEmpty(uuid)){
            if(Array.isArray(params.uuids)){
                uuid = params.uuids.find((i,idx) => idx === 0)
            }
        }
        if(isEmpty(uuid)){
            return false;
        }
        let deleteData = processQueryParams(params)
        const deleteDocumentRequest = {
            method: "DELETE",
            params: deleteData
        }
        return await apiRequest(`/api/documents/${uuid}`, deleteDocumentRequest)
    },
    async sendDocument(params) {
        let sendData = processInput(params)
        const sendDocumentRequest = {
            method: "POST",
            data: sendData
        }
        return await apiRequest(`/api/send-document`, sendDocumentRequest)
    },

    async viewDocument(params) {
        const { uuid } = params;
        delete params.uuid;
        let viewDocumentRequest = {
            method: "GET",
            params: processQueryParams(params)
        }
        return await apiRequest(`/api/documents/${uuid}/view`, viewDocumentRequest)
    }
}