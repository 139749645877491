import {
  GET_CONTRACT_PRICE_TYPES_REQUEST,
  GET_CONTRACT_PRICE_TYPES_SUCCESS,
  GET_CONTRACT_PRICE_TYPES_ERROR,
  GET_CONTRACT_PRICE_TYPE_REQUEST,
  GET_CONTRACT_PRICE_TYPE_SUCCESS,
  GET_CONTRACT_PRICE_TYPE_ERROR,
  CREATE_CONTRACT_PRICE_TYPE_REQUEST,
  CREATE_CONTRACT_PRICE_TYPE_SUCCESS,
  CREATE_CONTRACT_PRICE_TYPE_ERROR,
  EDIT_CONTRACT_PRICE_TYPE_REQUEST,
  EDIT_CONTRACT_PRICE_TYPE_SUCCESS,
  EDIT_CONTRACT_PRICE_TYPE_ERROR,
  DELETE_CONTRACT_PRICE_TYPE_REQUEST,
  DELETE_CONTRACT_PRICE_TYPE_SUCCESS,
  DELETE_CONTRACT_PRICE_TYPE_ERROR
} from "@/actions"

import {
  contractPriceTypesService
} from "@/services"

import { addNewFilter } from "@/utilities"

const state = {
  contractPriceTypes: [],
  contractPriceType: {},
  loading: false
}
const getters = {
  allContractPriceTypes: state => state.contractPriceTypes,
  contractPriceTypeFilter: state => ios_uuid => state.contractPriceTypes.filter(i => {
    return i.contract_ios_uuid == ios_uuid
  }),
  cptFindFilter: state => (ciosUuid, courseLength, pclUuid) => state.contractPriceTypes.find(cpt => cpt.contract_ios_uuid == ciosUuid && cpt.course_length == courseLength && cpt.course_level.uuid == pclUuid)
}

const actions = {
  async getContractPriceTypes({ commit }) {
    commit("GET_CONTRACT_PRICE_TYPES_REQUEST")
    const response = await contractPriceTypesService.getContractPriceTypes();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_CONTRACT_PRICE_TYPES_SUCCESS", data)
        break
      default:
        commit("GET_CONTRACT_PRICE_TYPES_ERROR", data)
    }
    return response
  },
  async getContractPriceType({ commit }, params) {
    commit("GET_CONTRACT_PRICE_TYPE_REQUEST")
    const response = await contractPriceTypesService.getContractPriceType(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_CONTRACT_PRICE_TYPE_SUCCESS", data)
        break
      default:
        commit("GET_CONTRACT_PRICE_TYPE_ERROR", data)
        break
    }
    return response
  },
  async createContractPriceType({ commit }, params) {
    commit("CREATE_CONTRACT_PRICE_TYPE_REQUEST")
    const response = await contractPriceTypesService.createContractPriceType(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("CREATE_CONTRACT_PRICE_TYPE_SUCCESS", data)
        break
      default:
        commit("CREATE_CONTRACT_PRICE_TYPE_ERROR", data)
    }
    return response
  },
  async editContractPriceType({ commit }, params) {
    commit("EDIT_CONTRACT_PRICE_TYPE_REQUEST")
    const response = await contractPriceTypesService.editContractPriceType(params, params.uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_CONTRACT_PRICE_TYPE_SUCCESS", data)
        break
      default:
        commit("EDIT_CONTRACT_PRICE_TYPE_ERROR", data)
    }
    return response
  },
  async deleteContractPriceType({ commit }, uuid) {
    commit("DELETE_CONTRACT_PRICE_TYPE_REQUEST")
    const response = await contractPriceTypesService.deleteContractPriceType(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_CONTRACT_PRICE_TYPE_SUCCESS", uuid)
        break
      default:
        commit("DELETE_CONTRACT_PRICE_TYPE_DELETE", data)
    }
    return response
  }
}

const mutations = {
  [GET_CONTRACT_PRICE_TYPES_REQUEST]: state => {
    state.loading = true
  },
  [GET_CONTRACT_PRICE_TYPES_SUCCESS]: (state, data) => {
    let contract_price_types = data.contract_price_types
    state.contractPriceTypes = addNewFilter(state.contractPriceTypes, contract_price_types)
    state.loading = false
  },
  [GET_CONTRACT_PRICE_TYPES_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_CONTRACT_PRICE_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [GET_CONTRACT_PRICE_TYPE_SUCCESS]: (state, data) => {
    let contract_price_type = { ...data.contract_price_type }
    state.contractPriceType = contract_price_type
    state.loading = false
  },
  [GET_CONTRACT_PRICE_TYPE_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_CONTRACT_PRICE_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_CONTRACT_PRICE_TYPE_SUCCESS]: (state, data) => {
    let contract_price_type = data.contract_price_type
    state.contractPriceTypes.push(contract_price_type)
    state.loading = false
  },
  [CREATE_CONTRACT_PRICE_TYPE_ERROR]: state => {
    state.loading = false
  },

  [EDIT_CONTRACT_PRICE_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_CONTRACT_PRICE_TYPE_SUCCESS]: (state, data) => {
    if (Array.isArray(data.contract_price_type)) {
      let contract_price_type = [...data.contract_price_type]
      state.contractPriceTypes = state.contractPriceTypes.filter(i => !contract_price_type.map(e => e.uuid).includes(i.uuid))
      state.contractPriceTypes = addNewFilter(state.contractPriceTypes, contract_price_type)
    }
  },
  [EDIT_CONTRACT_PRICE_TYPE_ERROR]: state => {
    state.loading = false
  },

  [DELETE_CONTRACT_PRICE_TYPE_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_CONTRACT_PRICE_TYPE_SUCCESS]: (state, uuid) => {
    state.contractPriceTypes = state.contractPriceTypes.filter(cpt => cpt.uuid !== uuid)
    state.loading = false
  },
  [DELETE_CONTRACT_PRICE_TYPE_ERROR]: (state, data) => {
    state.loading = false
  }

}

export const contractPriceType = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
