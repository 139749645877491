import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachTeachingProfileService = {
    async getCoachTeachingProfiles(params) {
        let getParams = processQueryParams(params)
        const getCoachTeachingProfilesRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-teaching-profiles", getCoachTeachingProfilesRequest)
    },
    async getCoachTeachingProfile(uuid) {
        const getCoachTeachingProfileRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-teaching-profiles/${uuid}`, getCoachTeachingProfileRequest)
    },
    async createCoachTeachingProfile(params) {
        const createData = processInput(params)
        const createCoachTeachingProfileRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-teaching-profiles", createCoachTeachingProfileRequest)
    },
    async editCoachTeachingProfile(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachTeachingProfileRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-teaching-profiles/${uuid}`, editCoachTeachingProfileRequest)
    },
    async deleteCoachTeachingProfile(uuid) {
        const deleteCoachTeachingProfileRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-teaching-profiles/${uuid}`, deleteCoachTeachingProfileRequest)
    }
}