import {
    apiRequest,
    processInput
} from "@/utilities"

export const addendumService = {
    async getAddendums() {
        const getAddendumsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/addendums", getAddendumsRequest)
    },
    async createAddendum(params) {
        const createData = processInput(params);
        const postAddendumRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/addendums", postAddendumRequest)
    },
    async getAddendum(uuid) {

        const getAddendumRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/addendums/${uuid}`, getAddendumRequest);
    },
    async editAddendum(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateAddendumRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/addendums/${uuid}`, updateAddendumRequest);
    },
    async deleteAddendum(uuid) {

        const deleteAddendumRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/addendums/${uuid}`, deleteAddendumRequest);
    }
}