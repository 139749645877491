const state = {
	suffixes: [
		{ value: 'Jr', label: "Jr." },
		{ value: 'Sr', label: "Sr." },
		{ value: 'II', label: "II" },
		{ value: 'III', label: "III" },
		{ value: 'IV', label: "IV" },
		{ value: 'V', label: "V" },
	],
	suffix: {},
	loading: false
}

const getters = {
	suffixSelectOptions: state => {
		return state.suffixes.map(suffix => suffix)
	}
}

const actions = {

}

const mutations = {

}

export const suffix = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}