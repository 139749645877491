import {
	GET_COACH_EVALUATIONS_REQUEST,
	GET_COACH_EVALUATIONS_SUCCESS,
	GET_COACH_EVALUATIONS_ERROR,
	GET_COACH_EVALUATION_REQUEST,
	GET_COACH_EVALUATION_SUCCESS,
	GET_COACH_EVALUATION_ERROR,
	GET_COACH_EVALUATION_STATUS_REQUEST,
	GET_COACH_EVALUATION_STATUS_SUCCESS,
	GET_COACH_EVALUATION_STATUS_ERROR,
	CREATE_COACH_EVALUATION_REQUEST,
	CREATE_COACH_EVALUATION_SUCCESS,
	CREATE_COACH_EVALUATION_ERROR,
	EDIT_COACH_EVALUATION_REQUEST,
	EDIT_COACH_EVALUATION_SUCCESS,
	EDIT_COACH_EVALUATION_ERROR,
	DELETE_COACH_EVALUATION_REQUEST,
	DELETE_COACH_EVALUATION_SUCCESS,
	DELETE_COACH_EVALUATION_ERROR,
} from "@/actions"

import {
	coachEvaluationService
} from "@/services"

import { addNewFilter, generateKey, isEmpty } from "@/utilities"

const state = {
	coachEvaluations: [],
	coachEvaluation: {},
	loading: false,
	loadedOnce: false,
	coachEvaluationStatusLoadedCsUuid: [],
	meetOpts: [
		{label: 'Select one', value: null, disabled: true},
		{label: '5 - Attended 90%-100% of Meetings', value: 5},
		{label: '4 - Attended 80%-90% of Meetings', value: 4},
		{label: '3 - Attended 70%-80% of Meetings', value: 3},
		{label: '2 - Attended 60%-70% of Meetings', value: 2},
		{label: '1 - Attended less than 60% of Meetings', value: 1},
	],
	respondOpts: [
		{label: 'Select one', value: null, disabled: true},
		{label: '5 - Responded within 24 hours 90%-100% of the time', value: 5},
		{label: '4 - Responded within 24 hours 80%-90% of the time', value: 4},
		{label: '3 - Responded within 24 hours 70%-80% of the time', value: 3},
		{label: '2 - Responded within 24 hours 60%-70% of the time', value: 2},
		{label: '1 - Responded within 24 hours 60% or less of the time', value: 1},
	],
	reviewOpts: [
		{label: 'Select one', value: null, disabled: true},
		{label: '5 - Submitted 90%-100% of Required Reports', value: 5},
		{label: '4 - Submitted 80%-90% of Required Reports', value: 4},
		{label: '3 - Submitted 70%-80% of Required Reports', value: 3},
		{label: '2 - Submitted 60%-70% of Required Reports', value: 2},
		{label: '1 - Submitted Less than 60% of Required Reports', value: 1},
	],
	evaluationQuestion: {
		meetings_attendance: "Meeting/Conference Call Attendance:",
		document_submission: "Submission of required reports/rosters/other documents in a timely manner:",
		coach_positive_feedback: "What does this Academic Coach do well?",
		responds_to_communications: "Responds to email, messages and other communications with 24 hours:",
		coach_imrovement_feedback: "How could this Academic Coach improve?",
	}
}

const getters = {
	coachEvaluationsLoading: state => state.loading,
	coachEvaluationsLoadedOnce: state => state.loadedOnce,
	evaluationReviewOpts: state => state.reviewOpts,
	evaluationMeetOpts: state => state.meetOpts,
	evaluationRespondOpts: state => state.respondOpts,
	evaluationQuestion: state => title => {
		if(isEmpty(title)) return state.evaluationQuestion;
		const ques = state.evaluationQuestion
		const newQues = {}
		for(let k in ques){
			const que = ques[k]
			newQues[k] = que.replaceAll("Academic Coach", title);
		}
		return newQues;
	},
	coachAssignmentEvaluations: state => state.coachEvaluations,
	findCoachEvaluation: state => uuid => state.coachEvaluations.find(c => c.coach_assignment_uuid == uuid),
}

const actions = {
	async getCoachEvaluations({ state, commit }, params) {
		commit("GET_COACH_EVALUATIONS_REQUEST");
		const response = await coachEvaluationService.getCoachEvaluations(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if(!params?.skipStore){
					commit("GET_COACH_EVALUATIONS_SUCCESS", data);
				}
				break
			default:
				commit("GET_COACH_EVALUATIONS_ERROR", data)
		}
		return response
	},
	async getCoachEvaluation({ commit, state }, params) {
		commit("GET_COACH_EVALUATION_REQUEST")
		const response = await coachEvaluationService.getCoachEvaluation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COACH_EVALUATION_SUCCESS", data)
				break
			default:
				commit("GET_COACH_EVALUATION_ERROR", data)
				break
		}
		return response
	},
	async getCoachEvaluationStatus({ commit }, params) {
		if(state.coachEvaluationStatusLoadedCsUuid.includes(params.course_start_uuid) && !params.force) return false;
		commit("GET_COACH_EVALUATION_REQUEST")
		const response = await coachEvaluationService.getCoachEvaluationStatus(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if(!isEmpty(data?.coach_evaluation_status)){
					unpackCoachEvaluation(data.coach_evaluation_status, commit);
					commit("GET_COACH_EVALUATION_STATUS_SUCCESS", params.course_start_uuid)
				}
				break
			default:
				commit("GET_COACH_EVALUATION_ERROR", data)
				break
		}
		return response
	},
	async createCoachEvaluation({ commit, dispatch }, params) {
		commit("CREATE_COACH_EVALUATION_REQUEST")
		const response = await coachEvaluationService.createCoachEvaluation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_COACH_EVALUATION_SUCCESS", data) : commit("CREATE_COACH_EVALUATION_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Evaluation Submitted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("CREATE_COACH_EVALUATION_ERROR", data)
		}
		return response
	},
	async editCoachEvaluation({ commit, dispatch }, params) {
		commit("EDIT_COACH_EVALUATION_REQUEST")
		const response = await coachEvaluationService.editCoachEvaluation(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data?.success ? commit("EDIT_COACH_EVALUATION_SUCCESS", data) : null;
				data?.success ? dispatch('alert/createAlertSuccess', "Evaluation updated!", { root: true }) : null;
				break
			default:
				commit("EDIT_COACH_EVALUATION_ERROR", data)
		}
		return response
	},
	async deleteCoachEvaluation({ commit, dispatch }, uuid) {
		commit("DELETE_COACH_EVALUATION_REQUEST")
		const response = await coachEvaluationService.deleteCoachEvaluation(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_COACH_EVALUATION_SUCCESS", { uuid }) : commit("DELETE_COACH_EVALUATION_ERROR")
				data.success ? dispatch('alert/createAlertSuccess', "Evaluation deleted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("DELETE_COACH_EVALUATION_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_COACH_EVALUATIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_EVALUATIONS_SUCCESS]: (state, data) => {
		state.coachEvaluations = addNewFilter(state.coachEvaluations, data.coach_evaluations)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_COACH_EVALUATIONS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_COACH_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_EVALUATION_SUCCESS]: (state, data) => {
		const { coach_evaluation }  = data
		state.coachEvaluations = addNewFilter(state.coachEvaluations, [coach_evaluation])
		state.loading = false
	},
	[GET_COACH_EVALUATION_ERROR]: (state, data) => {
		state.loading = false
	},
	[GET_COACH_EVALUATION_STATUS_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_EVALUATION_STATUS_SUCCESS]: (state, course_start_uuid) => {
		if(!isEmpty(course_start_uuid)){
			state.coachEvaluationStatusLoadedCsUuid.push(course_start_uuid)
		}
		state.loading = false
	},
	[GET_COACH_EVALUATION_STATUS_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COACH_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COACH_EVALUATION_SUCCESS]: (state, data) => {
		const { coach_evaluation } = data;
		state.coachEvaluations = addNewFilter(state.coachEvaluations, [coach_evaluation])
		state.loading = false
	},
	[CREATE_COACH_EVALUATION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COACH_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COACH_EVALUATION_SUCCESS]: (state, data) => {
		const { coach_evaluation } = data;
		state.coachEvaluations = addNewFilter(state.coachEvaluations, [coach_evaluation])
		state.loading = false;
	},
	[EDIT_COACH_EVALUATION_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COACH_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COACH_EVALUATION_SUCCESS]: (state, data) => {
		const { uuid } = data;
		state.coachEvaluations = state.coachEvaluations.filter(c => c.uuid !== uuid)
		state.loading = false
	},
	[DELETE_COACH_EVALUATION_ERROR]: (state) => {
		state.loading = false
	},
}

export const coachEvaluation = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}


export function unpackCoachEvaluation(data, commit){
	const coach_evaluations = data.filter(i => !isEmpty(i.coach_evaluation)).map(i => i.coach_evaluation)
	if(!isEmpty(coach_evaluations)){
		commit("GET_COACH_EVALUATIONS_SUCCESS", {coach_evaluations});
	}
	const faculty_evaluations = data.filter(i => !isEmpty(i.faculty_evaluation)).map(i => i.faculty_evaluation)
	if(!isEmpty(faculty_evaluations)){
		commit("facultyEvaluation/GET_FACULTY_EVALUATIONS_SUCCESS", {faculty_evaluations}, {root: true});
	}
	return data

}
