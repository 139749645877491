import {
	GET_PRE_COURSE_MEETING_MINUTES_REQUEST,
	GET_PRE_COURSE_MEETING_MINUTES_SUCCESS,
	GET_PRE_COURSE_MEETING_MINUTES_ERROR,
	GET_PRE_COURSE_MEETING_MINUTE_REQUEST,
	GET_PRE_COURSE_MEETING_MINUTE_SUCCESS,
	GET_PRE_COURSE_MEETING_MINUTE_ERROR,
	CREATE_PRE_COURSE_MEETING_MINUTE_REQUEST,
	CREATE_PRE_COURSE_MEETING_MINUTE_SUCCESS,
	CREATE_PRE_COURSE_MEETING_MINUTE_ERROR,
	EDIT_PRE_COURSE_MEETING_MINUTE_REQUEST,
	EDIT_PRE_COURSE_MEETING_MINUTE_SUCCESS,
	EDIT_PRE_COURSE_MEETING_MINUTE_ERROR,
	DELETE_PRE_COURSE_MEETING_MINUTE_REQUEST,
	DELETE_PRE_COURSE_MEETING_MINUTE_SUCCESS,
	DELETE_PRE_COURSE_MEETING_MINUTE_ERROR,
} from "@/actions"

import {
	preCourseMeetingMinuteService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
	preCourseMeetingMinutes: [],
	preCourseMeetingMinute: {},
	loading: false,
	loadedOnce: false,
	universityProgramsLoaded: []
}

const getters = {
	preCourseMeetingMinutesLoading: state => state.loading,
	preCourseMeetingMinutesLoadedOnce: state => state.loadedOnce,
	preCourseMeetingMinutes: state => state.preCourseMeetingMinutes,
	findPreCourseMeetingMinute: state => uuid => state.preCourseMeetingMinutes.find(c => c.uuid == uuid),
	preCourseMeetingMinutesByCsUuid: state => cuuid => state.preCourseMeetingMinutes.filter(c => c.course_start_uuid == cuuid),
}

const actions = {
	async getPreCourseMeetingMinutes({ state, commit }, params) {
		commit("GET_PRE_COURSE_MEETING_MINUTES_REQUEST");
		const response = await preCourseMeetingMinuteService.getPreCourseMeetingMinutes(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PRE_COURSE_MEETING_MINUTES_SUCCESS", data);
				break
			default:
				commit("GET_PRE_COURSE_MEETING_MINUTES_ERROR", data)
		}
		return response
	},
	async getPreCourseMeetingMinute({ commit }, params) {
		commit("GET_PRE_COURSE_MEETING_MINUTE_REQUEST")
		const response = await preCourseMeetingMinuteService.getPreCourseMeetingMinute(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PRE_COURSE_MEETING_MINUTE_SUCCESS", data)
				break
			default:
				commit("GET_PRE_COURSE_MEETING_MINUTE_ERROR", data)
				break
		}
		return response
	},
	async createPreCourseMeetingMinute({ commit, dispatch }, params) {
		commit("CREATE_PRE_COURSE_MEETING_MINUTE_REQUEST")
		const response = await preCourseMeetingMinuteService.createPreCourseMeetingMinute(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_PRE_COURSE_MEETING_MINUTE_SUCCESS", data) : commit("CREATE_PRE_COURSE_MEETING_MINUTE_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Meeting Minute created!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("CREATE_PRE_COURSE_MEETING_MINUTE_ERROR", data)
		}
		return response
	},
	async editPreCourseMeetingMinute({ commit, dispatch }, params) {
		commit("EDIT_PRE_COURSE_MEETING_MINUTE_REQUEST")
		const response = await preCourseMeetingMinuteService.editPreCourseMeetingMinute(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data?.success ? commit("EDIT_PRE_COURSE_MEETING_MINUTE_SUCCESS", data) : null;
				data?.success ? dispatch('alert/createAlertSuccess', "Meeting Minute updated!", { root: true }) : null;
				break
			default:
				commit("EDIT_PRE_COURSE_MEETING_MINUTE_ERROR", data)
		}
		return response
	},
	async deletePreCourseMeetingMinute({ commit, dispatch }, uuid) {
		commit("DELETE_PRE_COURSE_MEETING_MINUTE_REQUEST")
		const response = await preCourseMeetingMinuteService.deletePreCourseMeetingMinute(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_PRE_COURSE_MEETING_MINUTE_SUCCESS", { uuid }) : commit("DELETE_PRE_COURSE_MEETING_MINUTE_ERROR")
				data.success ? dispatch('alert/createAlertSuccess', "Meeting Minute deleted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("DELETE_PRE_COURSE_MEETING_MINUTE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_PRE_COURSE_MEETING_MINUTES_REQUEST]: state => {
		state.loading = true
	},
	[GET_PRE_COURSE_MEETING_MINUTES_SUCCESS]: (state, data) => {
		state.preCourseMeetingMinutes = addNewFilter(state.preCourseMeetingMinutes, data.pre_course_meeting_minutes)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_PRE_COURSE_MEETING_MINUTES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_PRE_COURSE_MEETING_MINUTE_REQUEST]: state => {
		state.loading = true
	},
	[GET_PRE_COURSE_MEETING_MINUTE_SUCCESS]: (state, data) => {
		const { pre_course_meeting_minute }  = data
		state.preCourseMeetingMinutes = addNewFilter(state.preCourseMeetingMinutes, [pre_course_meeting_minute])
		state.loading = false
	},
	[GET_PRE_COURSE_MEETING_MINUTE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_PRE_COURSE_MEETING_MINUTE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_PRE_COURSE_MEETING_MINUTE_SUCCESS]: (state, data) => {
		const { pre_course_meeting_minute } = data;
		state.preCourseMeetingMinutes = addNewFilter(state.preCourseMeetingMinutes, [pre_course_meeting_minute])
		state.loading = false
	},
	[CREATE_PRE_COURSE_MEETING_MINUTE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_PRE_COURSE_MEETING_MINUTE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_PRE_COURSE_MEETING_MINUTE_SUCCESS]: (state, data) => {
		const { pre_course_meeting_minute } = data;
		state.preCourseMeetingMinutes = addNewFilter(state.preCourseMeetingMinutes, [pre_course_meeting_minute])
		state.loading = false;
	},
	[EDIT_PRE_COURSE_MEETING_MINUTE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_PRE_COURSE_MEETING_MINUTE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_PRE_COURSE_MEETING_MINUTE_SUCCESS]: (state, data) => {
		const { uuid } = data;
		state.preCourseMeetingMinutes = state.preCourseMeetingMinutes.filter(c => c.uuid !== uuid)
		state.loading = false
	},
	[DELETE_PRE_COURSE_MEETING_MINUTE_ERROR]: (state) => {
		state.loading = false
	}
}

export const preCourseMeetingMinute = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
