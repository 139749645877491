export const reportsAssignmentSheetRoutes = {
  path: "assignment-sheet",
  name: "reports assignment-sheet",
  meta: {
    authRequired: true,
    allowedRoles: [
      'super-admin'
    ],
    label: "Assignment Sheet Reports",
  },
  component: () => import("@/pages/reports/general/ReportsGeneralIndex.vue"),
  children: [
    {
      path: "coach-counts",
      name: "report assignment-sheet coach-counts",
      meta: {
        label: "Coach Counts",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "current-active-starts",
      name: "report assignment-sheet current-active-starts",
      meta: {
        label: "Current Active Starts",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "current-active-starts-lead",
      name: "report assignment-sheet current-active-starts-lead",
      meta: {
        label: "Current Active Starts by Lead",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "assignment-sheet-by-university",
      name: "report assignment-sheet assignment-sheet-by-university",
      meta: {
        label: "Assignment Sheet By University",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    }
  ]
};