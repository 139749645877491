import {
	GET_SUB_COURSE_PROGRAMS_REQUEST,
	GET_SUB_COURSE_PROGRAMS_SUCCESS,
	GET_SUB_COURSE_PROGRAMS_ERROR,
	GET_SUB_COURSE_PROGRAM_REQUEST,
	GET_SUB_COURSE_PROGRAM_SUCCESS,
	GET_SUB_COURSE_PROGRAM_ERROR,
	CREATE_SUB_COURSE_PROGRAM_REQUEST,
	CREATE_SUB_COURSE_PROGRAM_SUCCESS,
	CREATE_SUB_COURSE_PROGRAM_ERROR,
	EDIT_SUB_COURSE_PROGRAM_REQUEST,
	EDIT_SUB_COURSE_PROGRAM_SUCCESS,
	EDIT_SUB_COURSE_PROGRAM_ERROR,
	DELETE_SUB_COURSE_PROGRAM_REQUEST,
	DELETE_SUB_COURSE_PROGRAM_SUCCESS,
	DELETE_SUB_COURSE_PROGRAM_ERROR,
	CLEAR_SECTION_SUB_COURSE_PROGRAM_REQUEST,
	CLEAR_SECTION_SUB_COURSE_PROGRAM_SUCCESS,
	CLEAR_SECTION_SUB_COURSE_PROGRAM_ERROR,
} from "@/actions"

import {
	subCourseProgramService
} from "@/services"

import { addNewFilter, isEmpty, decodeLaravelValidationErrors } from "@/utilities"

const state = {
	subCoursePrograms: [],
	subCourseProgram: {},
	loading: false,
}

function mapArray(state, getters, rootState, rootGetters) {
	return state.subCoursePrograms.map(i => {
		let programByUuid = rootGetters['program/programByUuid'];
		let progCourseLevelByUuid = rootGetters['programCourseLevel/progCourseLevelByUuid'];
		let program = programByUuid(i.program_uuid)
		let pc_level = progCourseLevelByUuid(i.program_course_level_uuid)
		return {
			...i,
			program,
			pc_level,
		}
	})
}

const getters = {
	subCourseProgram: state => state.subCourseProgram,
	subCourseProByUuid: state => uuid => state.subCoursePrograms.find(scp => scp.uuid === uuid),
	allSubCoursePrograms: (state, getters, rootState, rootGetters) => mapArray(state, getters, rootState, rootGetters),
	scpByUuidFilter: state => scpUuid => state.subCoursePrograms.find(scp => scp.uuid == scpUuid),
	subCourseProgramUuidsFilter: (state, getters) => uuid => {
		if (isEmpty(uuid)) return []
		let uuidArray = []
		if (typeof uuid === 'string') {
			uuidArray = [uuid]
		} else {
			uuidArray = uuid
		}
		return getters.allSubCoursePrograms.filter(i => uuidArray.includes(i.uuid))
	},
}

const actions = {
	async getSubCoursePrograms({ commit }) {
		commit("GET_SUB_COURSE_PROGRAMS_REQUEST")
		const response = await subCourseProgramService.getSubCoursePrograms();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_SUB_COURSE_PROGRAMS_SUCCESS", data)
				data.sub_course_programs.forEach(p => {
					unpackProgramData(p, commit)
				})
				break
			default:
				commit("GET_SUB_COURSE_PROGRAMS_ERROR", data)
		}
		return response
	},
	async getSubCourseProgram({ commit }, params) {
		commit("GET_SUB_COURSE_PROGRAM_REQUEST")
		const response = await subCourseProgramService.getSubCourseProgram(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_SUB_COURSE_PROGRAM_SUCCESS", data)
				unpackProgramData(data.sub_course_program, commit)
				break
			default:
				commit("GET_SUB_COURSE_PROGRAM_ERROR", data)
				break
		}
		return response
	},
	async createSubCourseProgram({ commit }, params) {
		commit("CREATE_SUB_COURSE_PROGRAM_REQUEST")
		const response = await subCourseProgramService.createSubCourseProgram(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_SUB_COURSE_PROGRAM_SUCCESS", data)
				break
			default:
				commit("CREATE_SUB_COURSE_PROGRAM_ERROR", data)
		}
		return response
	},
	async editSubCourseProgram({ commit }, params) {
		commit("EDIT_SUB_COURSE_PROGRAM_REQUEST")
		const response = await subCourseProgramService.editSubCourseProgram(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_SUB_COURSE_PROGRAM_SUCCESS", data)
				break
			default:
				commit("EDIT_SUB_COURSE_PROGRAM_ERROR", data)
		}
		return response
	},
	async deleteSubCourseProgram({ commit, dispatch }, uuid) {
		commit("DELETE_SUB_COURSE_PROGRAM_REQUEST")
		const response = await subCourseProgramService.deleteSubCourseProgram(uuid)
		const { status, data } = response;
		console.log(response)
		switch (status) {
			case 200:
				if (data?.success) {
					commit("DELETE_SUB_COURSE_PROGRAM_SUCCESS", uuid);
					dispatch('alert/createAlertSuccess', "Program deleted.", { root: true });
				}

				if (data?.error) {
					commit("DELETE_SUB_COURSE_PROGRAM_ERROR", data);
					dispatch('alert/createAlertErrors', data.errors ? decodeLaravelValidationErrors(data.errors) : "Opps! Something went wrong!", { root: true })
				}
				break
			default:

				commit("DELETE_SUB_COURSE_PROGRAM_ERROR", data)
		}
		return response
	},
}

const mutations = {
	[GET_SUB_COURSE_PROGRAMS_REQUEST]: state => {
		state.loading = true
	},
	[GET_SUB_COURSE_PROGRAMS_SUCCESS]: (state, data) => {
		let subCoursePrograms = [...data.sub_course_programs]
		state.subCoursePrograms = addNewFilter(state.subCoursePrograms, subCoursePrograms)
		state.loading = false
	},
	[GET_SUB_COURSE_PROGRAMS_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_SUB_COURSE_PROGRAM_REQUEST]: state => {
		state.loading = true
	},
	[GET_SUB_COURSE_PROGRAM_SUCCESS]: (state, data) => {
		let mdata = { ...data.sub_course_program }
		state.subCourseProgram = mdata
		state.loading = false
	},
	[GET_SUB_COURSE_PROGRAM_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_SUB_COURSE_PROGRAM_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_SUB_COURSE_PROGRAM_SUCCESS]: (state, data) => {
		console.log(data)
		state.loading = false
		const { sub_course_program } = data;
		state.subCoursePrograms = addNewFilter(state.subCoursePrograms, [sub_course_program])
	},
	[CREATE_SUB_COURSE_PROGRAM_ERROR]: state => {
		state.loading = false
	},

	[EDIT_SUB_COURSE_PROGRAM_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_SUB_COURSE_PROGRAM_SUCCESS]: (state, data) => {
		if (data.success) {
			state.subCoursePrograms = state.subCoursePrograms.filter(f => f.uuid != data.sub_course_program.uuid)
			state.subCoursePrograms.push(data.sub_course_program)
		}
		state.loading = false
	},
	[EDIT_SUB_COURSE_PROGRAM_ERROR]: state => {
		state.loading = false
	},

	[DELETE_SUB_COURSE_PROGRAM_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_SUB_COURSE_PROGRAM_SUCCESS]: (state, uuid) => {
		state.subCoursePrograms = state.subCoursePrograms.filter(f => f.uuid != uuid)
		state.loading = false
	},
	[DELETE_SUB_COURSE_PROGRAM_ERROR]: (state, data) => {
		state.loading = false
	}
}

export const subCourseProgram = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}