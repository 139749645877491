import Vue from "vue"
import Vuex from "vuex"

import { containerMutations } from "@/mutations"

import * as modules from "@/modules"

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    asideShow: false,
    darkMode: false,
    currentPath: "",
    toggle: window.localStorage.getItem('toggleMap') !== null ? JSON.parse(window.localStorage.getItem('toggleMap')) : {}
  },
  getters: {
    toggle: (state) => key => state['toggle'][key]
  },
  mutations: {
    ...containerMutations,
  },
  actions: {

  },
  modules
})