import {
	GET_PHONES_REQUEST,
	GET_PHONES_SUCCESS,
	GET_PHONES_ERROR,
	GET_PHONE_REQUEST,
	GET_PHONE_SUCCESS,
	GET_PHONE_ERROR,
	CREATE_PHONE_REQUEST,
	CREATE_PHONE_SUCCESS,
	CREATE_PHONE_ERROR,
	EDIT_PHONE_REQUEST,
	EDIT_PHONE_SUCCESS,
	EDIT_PHONE_ERROR,
	DELETE_PHONE_REQUEST,
	DELETE_PHONE_SUCCESS,
	DELETE_PHONE_ERROR,
	BROADCAST_DELETE_PHONE_SUCCESS,
	CLEAR_PHONE_STATE
} from "@/actions"

import {
	phoneService
} from "@/services"

const state = {
	phones: [],
	phone: {},
	loading: false,
	loadedOnce: false
}

import { addNewFilter, isEmpty, decodeLaravelValidationErrors } from "@/utilities"

const getters = {
	phoneLoadedOnce: state => state.loadedOnce,
	phoneNumbers: state => state.phones,
	phonesByPhoneableUuid: state => uuid => state.phones.filter(p => p.phoneable_uuid == uuid),
	coachPhones: (state, getters, rootState, rootGetters) => state.phones.filter(p => p.phoneable_uuid == rootGetters['coaches/coach']['uuid'])
}

const actions = {
	async getPhones({ commit }, params) {
		commit("GET_PHONES_REQUEST")
		const response = await phoneService.getPhones(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PHONES_SUCCESS", data)
				break
			default:
				commit("GET_PHONES_ERROR", data)
		}
		return response
	},
	async getPhone({ commit }, params) {
		commit("GET_PHONE_REQUEST")
		const response = await phoneService.getPhone(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PHONE_SUCCESS", data)
				break
			default:
				commit("GET_PHONE_ERROR", data)
				break
		}
		return response
	},
	async createPhone({ commit, dispatch }, params) {
		commit("CREATE_PHONE_REQUEST")
		const response = await phoneService.createPhone(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_PHONE_SUCCESS", data) : commit("CREATE_PHONE_ERROR", data);
				data.success ?
					dispatch('alert/createAlertSuccess', "Phone created.", { root: true }) :
					dispatch('alert/createAlertErrors', !isEmpty(data.errors) ? decodeLaravelValidationErrors(data.errors) : "Oops! Something went wrong.", { root: true });
				break
			default:
				commit("CREATE_PHONE_ERROR", data)
		}
		return response
	},
	async editPhone({ commit, dispatch }, params) {
		commit("EDIT_PHONE_REQUEST")
		const uuid = params.uuid
		const response = await phoneService.editPhone(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_PHONE_SUCCESS", data) : commit("EDIT_PHONE_ERROR", data)
				data.success ?
					dispatch('alert/createAlertSuccess', "Phone updated.", { root: true }) :
					dispatch('alert/createAlertErrors', !isEmpty(data.errors) ? decodeLaravelValidationErrors(data.errors) : "Oops! Something went wrong.", { root: true });
				break
			default:
				commit("EDIT_PHONE_ERROR", data)
		}
		return response
	},
	async deletePhone({ commit, dispatch }, uuid) {
		commit("DELETE_PHONE_REQUEST")
		const response = await phoneService.deletePhone(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_PHONE_SUCCESS", uuid) : null;
				data.success ?
					dispatch('alert/createAlertSuccess', "Phone deleted.", { root: true }) :
					dispatch('alert/createAlertErrors', !isEmpty(data.errors) ? decodeLaravelValidationErrors(data.errors) : "Oops! Something went wrong.", { root: true });
				break
			default:
				commit("DELETE_PHONE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_PHONES_REQUEST]: state => {
		state.loading = true
	},
	[GET_PHONES_SUCCESS]: (state, data) => {
		const phones = data.phones
		state.phones = addNewFilter(state.phones, phones)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_PHONES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_PHONE_REQUEST]: state => {
		state.loading = true
	},
	[GET_PHONE_SUCCESS]: (state, data) => {
		const phone = { ...data.phone }
		state.phone = phone
		state.loading = false
	},
	[GET_PHONE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_PHONE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_PHONE_SUCCESS]: (state, data) => {
		let { phone } = data
		state.phones = addNewFilter(state.phones, [phone])
		state.loading = false
	},
	[CREATE_PHONE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_PHONE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_PHONE_SUCCESS]: (state, data) => {
		const { phone } = data
		state.phones = state.phones.map(p => p.uuid == phone.uuid ? phone : p)
		state.loading = false
	},
	[EDIT_PHONE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_PHONE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_PHONE_SUCCESS]: (state, uuid) => {
		state.phones = state.phones.filter(p => p.uuid !== uuid)
		state.loading = false
	},
	[DELETE_PHONE_ERROR]: (state, data) => {
		state.loading = false
	},
	[BROADCAST_DELETE_PHONE_SUCCESS]: (state, data) => {
		const { phone } = data
		state.phones = state.phones.filter(p => p.uuid !== phone.uuid)
	},

	[CLEAR_PHONE_STATE]: (state) => {
		state.phones = []
		state.phone = {}
		state.loading = false
		state.loadeOnce = false
	}
}

export const phone = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}