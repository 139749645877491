export const GET_COMBINED_WASS_REQUEST = "GET_COMBINED_WASS_REQUEST"
export const GET_COMBINED_WASS_SUCCESS = "GET_COMBINED_WASS_SUCCESS"
export const GET_COMBINED_WASS_ERROR = "GET_COMBINED_WASS_ERROR"
export const GET_COMBINED_WAS_REQUEST = "GET_COMBINED_WAS_REQUEST"
export const GET_COMBINED_WAS_SUCCESS = "GET_COMBINED_WAS_SUCCESS"
export const GET_COMBINED_WAS_ERROR = "GET_COMBINED_WAS_ERROR"
export const CREATE_COMBINED_WAS_REQUEST = "CREATE_COMBINED_WAS_REQUEST"
export const CREATE_COMBINED_WAS_SUCCESS = "CREATE_COMBINED_WAS_SUCCESS"
export const CREATE_COMBINED_WAS_ERROR = "CREATE_COMBINED_WAS_ERROR"
export const EDIT_COMBINED_WAS_REQUEST = "EDIT_COMBINED_WAS_REQUEST"
export const EDIT_COMBINED_WAS_SUCCESS = "EDIT_COMBINED_WAS_SUCCESS"
export const EDIT_COMBINED_WAS_ERROR = "EDIT_COMBINED_WAS_ERROR"
export const DELETE_COMBINED_WAS_REQUEST = "DELETE_COMBINED_WAS_REQUEST"
export const DELETE_COMBINED_WAS_SUCCESS = "DELETE_COMBINED_WAS_SUCCESS"
export const DELETE_COMBINED_WAS_ERROR = "DELETE_COMBINED_WAS_ERROR"