import {
    GET_EEO_VETERAN_STATUSES_REQUEST,
    GET_EEO_VETERAN_STATUSES_SUCCESS,
    GET_EEO_VETERAN_STATUSES_ERROR,
    GET_EEO_VETERAN_STATUS_REQUEST,
    GET_EEO_VETERAN_STATUS_SUCCESS,
    GET_EEO_VETERAN_STATUS_ERROR,
    CREATE_EEO_VETERAN_STATUS_REQUEST,
    CREATE_EEO_VETERAN_STATUS_SUCCESS,
    CREATE_EEO_VETERAN_STATUS_ERROR,
    EDIT_EEO_VETERAN_STATUS_REQUEST,
    EDIT_EEO_VETERAN_STATUS_SUCCESS,
    EDIT_EEO_VETERAN_STATUS_ERROR,
    DELETE_EEO_VETERAN_STATUS_REQUEST,
    DELETE_EEO_VETERAN_STATUS_SUCCESS,
    DELETE_EEO_VETERAN_STATUS_ERROR
} from "../../actions"

import {
    eeoVeteranStatusService
} from "../../services"

const state = {
    eeoVeteranStatuses: [],
    eeoVeteranStatus: {},
    loading: false
}

const getters = {
    eeoVeteranStatusSelectOptions: state => state.eeoVeteranStatuses.map((eeoVeteranStatus) => ({ value: eeoVeteranStatus.uuid, label: eeoVeteranStatus.label }))
}

const actions = {
    async getEEOVeteranStatuses({ commit }) {
        commit("GET_EEO_VETERAN_STATUSES_REQUEST")
        const response = await eeoVeteranStatusService.getEEOVeteranStatuses();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_EEO_VETERAN_STATUSES_SUCCESS", data)
                break
            default:
                commit("GET_EEO_VETERAN_STATUSES_ERROR", data)
        }
        return response
    },
    async getEEOVeteranStatus({ commit }, params) {
        commit("GET_EEO_VETERAN_STATUS_REQUEST")
        const response = await eeoVeteranStatusService.getEEOVeteranStatus(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_EEO_VETERAN_STATUS_SUCCESS", data)
                break
            default:
                commit("GET_EEO_VETERAN_STATUS_ERROR", data)
                break
        }
        return response
    },
    async createEEOVeteranStatus({ commit }, params) {
        commit("CREATE_EEO_VETERAN_STATUS_REQUEST")
        const response = await eeoVeteranStatusService.createEEOVeteranStatus(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_EEO_VETERAN_STATUS_SUCCESS", data)
                break
            default:
                commit("CREATE_EEO_VETERAN_STATUS_ERROR", data)
        }
        return response
    },
    async editEEOVeteranStatus({ commit }, params, uuid) {
        commit("EDIT_EEO_VETERAN_STATUS_REQUEST")
        const response = await eeoVeteranStatusService.editEEOVeteranStatus(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_EEO_VETERAN_STATUS_SUCCESS", data)
                break
            default:
                commit("EDIT_EEO_VETERAN_STATUS_ERROR", data)
        }
        return response
    },
    async deleteEEOVeteranStatus({ commit }, params) {
        commit("DELETE_EEO_VETERAN_STATUS_REQUEST")
        const response = await eeoVeteranStatusService.deleteEEOVeteranStatus(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_EEO_VETERAN_STATUS_SUCCESS", data)
                break
            default:
                commit("DELETE_EEO_VETERAN_STATUS_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_EEO_VETERAN_STATUSES_REQUEST]: state => {
        state.loading = true
    },
    [GET_EEO_VETERAN_STATUSES_SUCCESS]: (state, data) => {
        const eeoVeteranStatuses = data.eeo_veteran_statuses
        state.eeoVeteranStatuses = eeoVeteranStatuses
        state.loading = false
    },
    [GET_EEO_VETERAN_STATUSES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_EEO_VETERAN_STATUS_REQUEST]: state => {
        state.loading = true
    },
    [GET_EEO_VETERAN_STATUS_SUCCESS]: (state, data) => {
        const { eeoVeteranStatus } = data.eeo_veteran_status
        state.eeoVeteranStatus = eeoVeteranStatus
        state.loading = false
    },
    [GET_EEO_VETERAN_STATUS_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_EEO_VETERAN_STATUS_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_EEO_VETERAN_STATUS_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [CREATE_EEO_VETERAN_STATUS_ERROR]: state => {
        state.loading = false
    },

    [EDIT_EEO_VETERAN_STATUS_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_EEO_VETERAN_STATUS_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_EEO_VETERAN_STATUS_ERROR]: state => {
        state.loading = false
    },

    [DELETE_EEO_VETERAN_STATUS_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_EEO_VETERAN_STATUS_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_EEO_VETERAN_STATUS_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const eeoVeteranStatus = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}