import {
    GET_COMBINED_WASS_REQUEST,
    GET_COMBINED_WASS_SUCCESS,
    GET_COMBINED_WASS_ERROR,
    GET_COMBINED_WAS_REQUEST,
    GET_COMBINED_WAS_SUCCESS,
    GET_COMBINED_WAS_ERROR,
    CREATE_COMBINED_WAS_REQUEST,
    CREATE_COMBINED_WAS_SUCCESS,
    CREATE_COMBINED_WAS_ERROR,
    EDIT_COMBINED_WAS_REQUEST,
    EDIT_COMBINED_WAS_SUCCESS,
    EDIT_COMBINED_WAS_ERROR,
    DELETE_COMBINED_WAS_REQUEST,
    DELETE_COMBINED_WAS_SUCCESS,
    DELETE_COMBINED_WAS_ERROR
} from "@/actions"

import {
    combinedWASService
} from "@/services"

import {
    addNewFilter
} from "@/utilities"

const state = {
    combinedWASs: [],
    combinedWAS: {},
    loading: false
}

const getters = {
    combinedWASByUnivUuid: state => uUuid => state.combinedWASs.filter(cw => cw.universities_uuid == uUuid),
    cWASByUnivProUuid: state => (uUuid, pUuid) => state.combinedWASs.find(cw => cw.universities_uuid == uUuid && cw.programs_uuid == pUuid)
}

const actions = {
    async getCombinedWASs({ commit }) {
        commit("GET_COMBINED_WASS_REQUEST")
        const response = await combinedWASService.getCombinedWASs();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COMBINED_WASS_SUCCESS", data)
                break
            default:
                commit("GET_COMBINED_WASS_ERROR", data)
        }
        return response
    },
    async getCombinedWAS({ commit }, params) {
        commit("GET_COMBINED_WAS_REQUEST")
        const response = await combinedWASService.getCombinedWAS(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("GET_COMBINED_WAS_SUCCESS", data) : null
                break
            default:
                commit("GET_COMBINED_WAS_ERROR", data)
                break
        }
        return response
    },
    async createCombinedWAS({ commit }, params) {
        commit("CREATE_COMBINED_WAS_REQUEST")
        const response = await combinedWASService.createCombinedWAS(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COMBINED_WAS_SUCCESS", data)
                break
            default:
                commit("CREATE_COMBINED_WAS_ERROR", data)
        }
        return response
    },
    async editCombinedWAS({ commit }, params, uuid) {
        commit("EDIT_COMBINED_WAS_REQUEST")
        const response = await combinedWASService.editCombinedWAS(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COMBINED_WAS_SUCCESS", data)
                break
            default:
                commit("EDIT_COMBINED_WAS_ERROR", data)
        }
        return response
    },
    async deleteCombinedWAS({ commit }, params) {
        commit("DELETE_COMBINED_WAS_REQUEST")
        const response = await combinedWASService.deleteCombinedWAS(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("DELETE_COMBINED_WAS_SUCCESS", params) : null
                break
            default:
                commit("DELETE_COMBINED_WAS_ERROR", data)
        }
        return response
    }
}

const mutations = {
    [GET_COMBINED_WASS_REQUEST]: state => {
        state.loading = true
    },
    [GET_COMBINED_WASS_SUCCESS]: (state, data) => {
        const combined_wass = data.combined_wass
        state.combinedWASs = addNewFilter(state.combinedWASs, combined_wass)
        state.loading = false
    },
    [GET_COMBINED_WASS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COMBINED_WAS_REQUEST]: state => {
        state.loading = true
    },
    [GET_COMBINED_WAS_SUCCESS]: (state, data) => {
        const { combined_was } = data
        state.combinedWAS = combined_was
        state.loading = false
    },
    [GET_COMBINED_WAS_ERROR]: (state, data) => {
        state.loading = false
    },
    [CREATE_COMBINED_WAS_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COMBINED_WAS_SUCCESS]: (state, data) => {
        let { combined_was } = data
        state.combinedWASs = addNewFilter(state.combinedWASs, [combined_was])
        state.loading = false
    },
    [CREATE_COMBINED_WAS_ERROR]: state => {
        state.loading = false
    },
    [EDIT_COMBINED_WAS_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COMBINED_WAS_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COMBINED_WAS_ERROR]: state => {
        state.loading = false
    },
    [DELETE_COMBINED_WAS_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COMBINED_WAS_SUCCESS]: (state, data) => {
        const { uuid } = data
        state.combinedWASs = state.combinedWASs.filter(cw => cw.uuid !== uuid)
        state.loading = false
    },
    [DELETE_COMBINED_WAS_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const combinedWAS = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}