import {
    apiRequest,
    processInput
} from "@/utilities"

export const statusUniversityService = {
    async getStatusUniversities() {
        const getStatusUniversitiesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/status-universities", getStatusUniversitiesRequest)
    },
    async getStatusUniversity(uuid) {
        const getStatusUniversityRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/status-universities/${uuid}`, getStatusUniversityRequest)
    },
    async createStatusUniversity(params) {
        const createData = processInput(params)
        const createStatusUniversityRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/status-universities", createStatusUniversityRequest)
    },
    async editStatusUniversity(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editStatusUniversityRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/status-universities/${uuid}`, editStatusUniversityRequest)
    },
    async deleteStatusUniversity(uuid) {
        const deleteStatusUniversityRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/status-universities/${uuid}`, deleteStatusUniversityRequest)
    }
}