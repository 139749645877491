import {
    apiRequest,
    processInput,
    processQueryParams
  } from "@/utilities"
  
  const moduleUrl = "course-meeting-minutes";

  export const courseMeetingMinuteService = {
    async getCourseMeetingMinutes(params) {
  
      const getCourseMeetingMinutesRequest = {
        method: "GET",
        params: processQueryParams(params)
      }
  
      return await apiRequest(`/api/${moduleUrl}`, getCourseMeetingMinutesRequest)
    },
    async getMeetingMinutes(params) {
      let getParams = processQueryParams(params)
        const getMeetingMinutesRequest = {
            method: "GET",
            params: getParams
        }
      return await apiRequest(`/api/${moduleUrl}`, getMeetingMinutesRequest)
    },
    async createCourseMeetingMinute(params) {
  
      const createData = processInput(params)
      const postCourseMeetingMinuteRequest = {
        method: "POST",
        data: createData
      }
      return await apiRequest(`/api/${moduleUrl}`, postCourseMeetingMinuteRequest)
    },
    async getCourseMeetingMinute(uuid) {
  
      const getCourseMeetingMinuteRequest = {
        method: "GET"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, getCourseMeetingMinuteRequest);
    },
    async editCourseMeetingMinute(data, uuid) {
  
      let editData = processInput(data)
      editData.append("_method", "PATCH")
  
      const updateCourseMeetingMinuteRequest = {
        method: "POST",
        data: editData
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, updateCourseMeetingMinuteRequest);
    },
    async deleteCourseMeetingMinute(uuid) {
  
      const deleteCourseMeetingMinuteRequest = {
        method: "DELETE"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, deleteCourseMeetingMinuteRequest);
    }
  }
  