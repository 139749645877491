import {
    GET_COACH_REFERRALS_REQUEST,
    GET_COACH_REFERRALS_SUCCESS,
    GET_COACH_REFERRALS_ERROR,
    GET_COACH_REFERRAL_REQUEST,
    GET_COACH_REFERRAL_SUCCESS,
    GET_COACH_REFERRAL_ERROR,
    CREATE_COACH_REFERRAL_REQUEST,
    CREATE_COACH_REFERRAL_SUCCESS,
    CREATE_COACH_REFERRAL_ERROR,
    EDIT_COACH_REFERRAL_REQUEST,
    EDIT_COACH_REFERRAL_SUCCESS,
    EDIT_COACH_REFERRAL_ERROR,
    DELETE_COACH_REFERRAL_REQUEST,
    DELETE_COACH_REFERRAL_SUCCESS,
    DELETE_COACH_REFERRAL_ERROR,
    CLEAR_COACH_REFERRAL_STATE
} from "@/actions"

import {
    coachReferralService
} from "@/services"

const state = {
    coachReferrals: [],
    coachReferral: {},
    loading: false
}

const getters = {}

const actions = {
    async getCoachReferrals({ commit }) {
        commit("GET_COACH_REFERRALS_REQUEST")
        const response = await coachReferralService.getCoachReferrals();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_REFERRALS_SUCCESS", data)
                break
            default:
                commit("GET_COACH_REFERRALS_ERROR", data)
        }
        return response
    },
    async getCoachReferral({ commit }, params) {
        commit("GET_COACH_REFERRAL_REQUEST")
        const response = await coachReferralService.getCoachReferral(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_REFERRAL_SUCCESS", data)
                break
            default:
                commit("GET_COACH_REFERRAL_ERROR", data)
                break
        }
        return response
    },
    async createCoachReferral({ commit }, params) {
        commit("CREATE_COACH_REFERRAL_REQUEST")
        const response = await coachReferralService.createCoachReferral(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COACH_REFERRAL_SUCCESS", data)
                break
            default:
                commit("CREATE_COACH_REFERRAL_ERROR", data)
        }
        return response
    },
    async editCoachReferral({ commit }, params, uuid) {
        commit("EDIT_COACH_REFERRAL_REQUEST")
        const response = await coachReferralService.editCoachReferral(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COACH_REFERRAL_SUCCESS", data)
                break
            default:
                commit("EDIT_COACH_REFERRAL_ERROR", data)
        }
        return response
    },
    async deleteCoachReferral({ commit }, params) {
        commit("DELETE_COACH_REFERRAL_REQUEST")
        const response = await coachReferralService.deleteCoachReferral(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COACH_REFERRAL_SUCCESS", data)
                break
            default:
                commit("DELETE_COACH_REFERRAL_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_REFERRALS_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_REFERRALS_SUCCESS]: (state, data) => {
        const coachReferrals = [...data.coach_referrals]
        state.coachReferrals = coachReferrals
        state.loading = false
    },
    [GET_COACH_REFERRALS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COACH_REFERRAL_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_REFERRAL_SUCCESS]: (state, data) => {
        const coachReferral = { ...data.coach_referral }
        state.coachReferral = coachReferral
        state.loading = false
    },
    [GET_COACH_REFERRAL_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_REFERRAL_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_REFERRAL_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [CREATE_COACH_REFERRAL_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_REFERRAL_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_REFERRAL_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COACH_REFERRAL_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_REFERRAL_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_REFERRAL_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_COACH_REFERRAL_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COACH_REFERRAL_STATE]: (state) => {
        state.coachReferrals = [],
        state.coachReferral = {},
        state.loading = false
        state.loadedOnce = false
    }
}

export const coachReferral = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}