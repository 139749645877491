import {
    GET_UNI_FACULTY_SOURCES_REQUEST,
    GET_UNI_FACULTY_SOURCES_SUCCESS,
    GET_UNI_FACULTY_SOURCES_ERROR,
    GET_UNI_FACULTY_SOURCE_REQUEST,
    GET_UNI_FACULTY_SOURCE_SUCCESS,
    GET_UNI_FACULTY_SOURCE_ERROR,
    CREATE_UNI_FACULTY_SOURCE_REQUEST,
    CREATE_UNI_FACULTY_SOURCE_SUCCESS,
    CREATE_UNI_FACULTY_SOURCE_ERROR,
    EDIT_UNI_FACULTY_SOURCE_REQUEST,
    EDIT_UNI_FACULTY_SOURCE_SUCCESS,
    EDIT_UNI_FACULTY_SOURCE_ERROR,
    DELETE_UNI_FACULTY_SOURCE_REQUEST,
    DELETE_UNI_FACULTY_SOURCE_SUCCESS,
    DELETE_UNI_FACULTY_SOURCE_ERROR,
} from "@/actions"

import {
    unpackFSR
} from "@/modules"

import {
    universityFacultySourcesService
} from "@/services"
import { compareDesc, parseISO, isEqual } from "date-fns";
import { addNewFilter, isEmpty } from "@/utilities";

const state = {
    error: null,
    loading: false,
    loadedOnce: false,
    universityFacultySourceLoadedOnce: false,
    universityFacultySources: [],
    universityFacultySource: {}
}
function mapArray(ufss){
    const finalizedUfss = ufss.filter(i => i.is_finalized)
    const expDates = finalizedUfss.map(i => parseISO(i.expiration_date))
    const descArr = expDates.sort(compareDesc)
    const lastUfsExpireOn = descArr.find((i,idx) => idx === 0)
    const lastUfs = finalizedUfss.find(i => isEqual(parseISO(i.expiration_date),lastUfsExpireOn))
    return ufss.map(i => {
        const is_last = !isEmpty(lastUfs) ? lastUfs.uuid == i.uuid : false
        return {
            ...i,
            is_last
        }
    })
}
const getters = {
    ufsLoading: state => state.loading,
    ufsLoadedOnce: state => state.loadedOnce,
    ufSources: state => state.universityFacultySources,
    findUfsDraftByUni: state => uuid => state.universityFacultySources.find(i => i.universities_uuid == uuid && i.is_finalized != 1),
    ufSourceByUniUuid: state => uniUuid => mapArray(state.universityFacultySources.filter(i => i.universities_uuid == uniUuid).sort((a,b) => new Date(a.expiration_date) < new Date(b.expiration_date))),
    ufSourceByUuid: state => uuid => state.universityFacultySources.find(i => i.uuid == uuid),
}

const actions = {
    async getUniversityFacultySources({ commit }, params) {
        commit("GET_UNI_FACULTY_SOURCES_REQUEST")
        const response = await universityFacultySourcesService.getUniversityFacultySources(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNI_FACULTY_SOURCES_SUCCESS", data)
                break
            default:
                commit("GET_UNI_FACULTY_SOURCES_ERROR", data)
        }
        return response
    },
    async renewUfs({ commit }, params) {
        commit("CREATE_UNI_FACULTY_SOURCE_REQUEST")
        const response = await universityFacultySourcesService.renewUfs(params)
        const { status, data } = response
        switch (status) {
            case 200:
                const university_faculty_source = unpackUFS(data.university_faculty_source, commit)
                commit("CREATE_UNI_FACULTY_SOURCE_SUCCESS", {university_faculty_source})
                break
            default:
                commit("CREATE_UNI_FACULTY_SOURCE_ERROR", data)
        }
        return response
    },
    async getUniversityFacultySource({ commit }, params) {
        commit("GET_UNI_FACULTY_SOURCE_REQUEST")
        const response = await universityFacultySourcesService.getUniversityFacultySource(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNI_FACULTY_SOURCE_SUCCESS", data)
                break
            default:
                commit("GET_UNI_FACULTY_SOURCE_ERROR", data)
                break
        }
        return response
    },
    async createUniversityFacultySource({ commit, dispatch }, params) {
        commit("CREATE_UNI_FACULTY_SOURCE_REQUEST")
        const response = await universityFacultySourcesService.createUniversityFacultySource(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_UNI_FACULTY_SOURCE_SUCCESS", data) : commit("CREATE_UNI_FACULTY_SOURCE_ERROR", data)
                data.success ? dispatch('alert/createAlertSuccess', "Draft has been created.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
                break
            default:
                commit("CREATE_UNI_FACULTY_SOURCE_ERROR", data)
        }
        return response
    },
    async editUniversityFacultySource({ commit }, params) {
        commit("EDIT_UNI_FACULTY_SOURCE_REQUEST")
        const response = await universityFacultySourcesService.editUniversityFacultySource(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_UNI_FACULTY_SOURCE_SUCCESS", data)
                break
            default:
                commit("EDIT_UNI_FACULTY_SOURCE_ERROR", data)
        }
        return response
    },
    async deleteUniversityFacultySource({ commit }, params) {
        commit("DELETE_UNI_FACULTY_SOURCE_REQUEST")
        const uuid = params.uuid
        const response = await universityFacultySourcesService.deleteUniversityFacultySource(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit(DELETE_UNI_FACULTY_SOURCE_SUCCESS, uuid) : commit("DELETE_UNI_FACULTY_SOURCE_ERROR", data)
                break
            default:
                commit("DELETE_UNI_FACULTY_SOURCE_ERROR", data)
        }
        return response
    }
}

const mutations = {
    [GET_UNI_FACULTY_SOURCES_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNI_FACULTY_SOURCES_SUCCESS]: (state, data) => {
        state.universityFacultySources = addNewFilter(state.universityFacultySources, data.university_faculty_sources)
        state.universityFacultySourceLoadedOnce = true
        state.loading = false
        state.loadedOnce = true
    },
    [GET_UNI_FACULTY_SOURCES_ERROR]: state => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [GET_UNI_FACULTY_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNI_FACULTY_SOURCE_SUCCESS]: (state, data) => {
        let { university_faculty_source } = data
        state.universityFacultySource = university_faculty_source
        state.loading = false
    },
    [GET_UNI_FACULTY_SOURCE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_UNI_FACULTY_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_UNI_FACULTY_SOURCE_SUCCESS]: (state, data) => {
        let { university_faculty_source } = data
        state.universityFacultySources = addNewFilter(state.universityFacultySources, [university_faculty_source])
        state.loading = false
    },
    [CREATE_UNI_FACULTY_SOURCE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_UNI_FACULTY_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_UNI_FACULTY_SOURCE_SUCCESS]: (state, data) => {
        state.universityFacultySources = state.universityFacultySources.filter(i => i.uuid != data.university_faculty_source.uuid)
        state.universityFacultySources.push(data.university_faculty_source)
        state.loading = false
    },
    [EDIT_UNI_FACULTY_SOURCE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_UNI_FACULTY_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_UNI_FACULTY_SOURCE_SUCCESS]: (state, uuid) => {
        state.universityFacultySources = state.universityFacultySources.filter(i => i.uuid != uuid)
        state.loading = false
    },
    [DELETE_UNI_FACULTY_SOURCE_ERROR]: (state, data) => {
        state.loading = false
    },
}



export function unpackUFS(ufs, commit){
    if (!isEmpty(ufs.documents)) {
        commit('document/GET_DOCUMENTS_SUCCESS', ufs, { root: true })
        delete ufs.documents
    }
    if (!isEmpty(ufs.faculty_source_roles)) {
        let faculty_source_roles = ufs.faculty_source_roles.map(i => unpackFSR(i, commit))
        commit('facultySourceRole/GET_FACULTY_SOURCE_ROLES_SUCCESS', {faculty_source_roles}, { root: true })
    }
    if (!isEmpty(ufs.comments)) {
        commit("comments/GET_COMMENTS_SUCCESS", { comments: ufs.comments }, { root: true })
        delete ufs.comments
      }
    return ufs
}


export const universityFacultySource = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}