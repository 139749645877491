export const GET_INVOICES_REQUEST = "GET_INVOICES_REQUEST"
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS"
export const GET_INVOICES_ERROR = "GET_INVOICES_ERROR"
export const GET_INVOICE_REQUEST = "GET_INVOICE_REQUEST"
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS"
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR"
export const CREATE_INVOICE_REQUEST = "CREATE_INVOICE_REQUEST"
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS"
export const CREATE_INVOICE_ERROR = "CREATE_INVOICE_ERROR"
export const EDIT_INVOICE_REQUEST = "EDIT_INVOICE_REQUEST"
export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS"
export const EDIT_INVOICE_ERROR = "EDIT_INVOICE_ERROR"
export const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST"
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS"
export const DELETE_INVOICE_ERROR = "DELETE_INVOICE_ERROR"
export const GENERATE_INVOICE_REQUEST = "GENERATE_INVOICE_REQUEST"
export const GENERATE_INVOICE_SUCCESS = "GENERATE_INVOICE_SUCCESS"
export const GENERATE_INVOICE_ERROR = "GENERATE_INVOICE_ERROR"
export const CLEAN_INVOICE = "CLEAN_INVOICE"