import {
    apiRequest,
    processInput
} from "@/utilities"

export const coachFacultyRefService = {
    async getCoachFacultyReferral(uuid) {
        const getCoachFacultyReferralRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-faculty-referrals/${uuid}`, getCoachFacultyReferralRequest)
    },
    async createCoachFacultyReferral(params) {
        const createData = processInput(params)
        const createCoachFacultyReferralRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-faculty-referrals", createCoachFacultyReferralRequest)
    },
    async editCoachFacultyReferral(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachFacultyReferralRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-faculty-referrals/${uuid}`, editCoachFacultyReferralRequest)
    },
    async deleteCoachFacultyReferral(uuid) {
        const deleteCoachFacultyReferralRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-faculty-referrals/${uuid}`, deleteCoachFacultyReferralRequest)
    }
}