export const GET_EEO_RACE_ETHNICITIES_REQUEST = "GET_EEO_RACE_ETHNICITIES_REQUEST"
export const GET_EEO_RACE_ETHNICITIES_SUCCESS = "GET_EEO_RACE_ETHNICITIES_SUCCESS"
export const GET_EEO_RACE_ETHNICITIES_ERROR = "GET_EEO_RACE_ETHNICITIES_ERROR"
export const GET_EEO_RACE_ETHNICITY_REQUEST = "GET_EEO_RACE_ETHNICITY_REQUEST"
export const GET_EEO_RACE_ETHNICITY_SUCCESS = "GET_EEO_RACE_ETHNICITY_SUCCESS"
export const GET_EEO_RACE_ETHNICITY_ERROR = "GET_EEO_RACE_ETHNICITY_ERROR"
export const CREATE_EEO_RACE_ETHNICITY_REQUEST = "CREATE_EEO_RACE_ETHNICITY_REQUEST"
export const CREATE_EEO_RACE_ETHNICITY_SUCCESS = "CREATE_EEO_RACE_ETHNICITY_SUCCESS"
export const CREATE_EEO_RACE_ETHNICITY_ERROR = "CREATE_EEO_RACE_ETHNICITY_ERROR"
export const EDIT_EEO_RACE_ETHNICITY_REQUEST = "EDIT_EEO_RACE_ETHNICITY_REQUEST"
export const EDIT_EEO_RACE_ETHNICITY_SUCCESS = "EDIT_EEO_RACE_ETHNICITY_SUCCESS"
export const EDIT_EEO_RACE_ETHNICITY_ERROR = "EDIT_EEO_RACE_ETHNICITY_ERROR"
export const DELETE_EEO_RACE_ETHNICITY_REQUEST = "DELETE_EEO_RACE_ETHNICITY_REQUEST"
export const DELETE_EEO_RACE_ETHNICITY_SUCCESS = "DELETE_EEO_RACE_ETHNICITY_SUCCESS"
export const DELETE_EEO_RACE_ETHNICITY_ERROR = "DELETE_EEO_RACE_ETHNICITY_ERROR"