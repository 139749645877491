import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachService = {
    async getCoaches(params) {
        let getParams = processQueryParams(params)
        const getCoachesRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coaches", getCoachesRequest)
    },
    async getCoach(params) {
        const { uuid } = params
        delete params['uuid']
        const getCoachParams = processQueryParams(params);
        const getCoachRequest = {
            method: "GET",
            params: getCoachParams
        }
        return await apiRequest(`/api/coaches/${uuid}`, getCoachRequest)
    },
    async createCoach(params) {
        const createData = processInput(params)
        const createCoachRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coaches", createCoachRequest)
    },
    async editCoach(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coaches/${uuid}`, editCoachRequest)
    },
    async coachCustomActions(data, uuid) {
        let coachData = processInput(data)
        const coachReq = {
            method: "POST",
            data: coachData
        }
        return await apiRequest(`/api/coach-custom-actions/${uuid}`, coachReq)
    },
    async deleteCoach(uuid) {
        const deleteCoachRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coaches/${uuid}`, deleteCoachRequest)
    },
    async preferredCoachCheck(params) {
        const data = processInput(params)
        const req = {
            method: "POST",
            data
        }
        return await apiRequest("/api/preferred-coach-check", req)
    },
}