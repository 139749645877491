import {
  GET_COURSE_START_SECTIONS_REQUEST,
  GET_COURSE_START_SECTIONS_SUCCESS,
  GET_COURSE_START_SECTIONS_ERROR,
  GET_COURSE_START_SECTION_REQUEST,
  GET_COURSE_START_SECTION_SUCCESS,
  GET_COURSE_START_SECTION_ERROR,
  CREATE_COURSE_START_SECTION_REQUEST,
  CREATE_COURSE_START_SECTION_SUCCESS,
  CREATE_COURSE_START_SECTION_ERROR,
  EDIT_COURSE_START_SECTION_REQUEST,
  EDIT_COURSE_START_SECTION_SUCCESS,
  EDIT_COURSE_START_SECTION_ERROR,
  DELETE_COURSE_START_SECTION_REQUEST,
  DELETE_COURSE_START_SECTION_SUCCESS,
  DELETE_COURSE_START_SECTION_ERROR,
} from "@/actions"

import {
  courseStartSectionService
} from "@/services"

import { addNewFilter, isEmpty, decodeLaravelValidationErrors } from "@/utilities"


const state = {
  courseStartSections: [],
  courseStartSection: {},
  loading: false,
  loadedOnce: false
}

const getters = {
  courseStartSections: (state) => (csUuid) => state.courseStartSections.filter(css => css.course_starts_uuid == csUuid),
  findCourseStartSection: (state) => (csSecUuid) => state.courseStartSections.find(css => css.uuid == csSecUuid),

  courseStartExpectedEnrollment: (state, getters) => (csUuid) => getters.courseStartSections(csUuid).reduce((c, s) => {
    return c += parseInt(s.expected_enrollment);
  }, 0),
  
  courseStartSectionFind: state => sectionUuid => state.courseStartSections.find(css => css.uuid == sectionUuid)
}

const actions = {
  async getCourseStartSections({ commit }, params) {
    commit("GET_COURSE_START_SECTIONS_REQUEST")
    const response = await courseStartSectionService.getCourseStartSections(params);
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_COURSE_START_SECTIONS_SUCCESS", data)
        break
      default:
        commit("GET_COURSE_START_SECTIONS_ERROR", data)
    }
    return response
  },
  async getCourseStartSection({ commit }, params) {
    commit("GET_COURSE_START_SECTION_REQUEST")
    const response = await courseStartSectionService.getCourseStartSection(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_COURSE_START_SECTION_SUCCESS", data)
        break
      default:
        commit("GET_COURSE_START_SECTION_ERROR", data)
        break
    }
    return response
  },
  async createCourseStartSection({ commit, dispatch }, params) {
    commit("CREATE_COURSE_START_SECTION_REQUEST")
    const response = await courseStartSectionService.createCourseStartSection(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_COURSE_START_SECTION_SUCCESS", data) : commit("CREATE_COURSE_START_SECTION_ERROR", data)
        data.success ? dispatch('alert/createAlertSuccess', "Section created!", { root: true }) : dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
        break
      default:
        commit("CREATE_COURSE_START_SECTION_ERROR", data)
    }
    return response
  },
  async editCourseStartSection({ commit, dispatch }, params) {
    commit("EDIT_COURSE_START_SECTION_REQUEST")
    const response = await courseStartSectionService.editCourseStartSection(params, params.uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_COURSE_START_SECTION_SUCCESS", data) : null
        data.success ? dispatch('alert/createAlertSuccess', "Section updated!", { root: true }) : dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
        break
      default:
        commit("EDIT_COURSE_START_SECTION_ERROR", data)
    }
    return response
  },
  async deleteCourseStartSection({ commit, dispatch }, params) {
    const { uuid } = params
    commit("DELETE_COURSE_START_SECTION_REQUEST")
    const response = await courseStartSectionService.deleteCourseStartSection(params)
    const { status, data } = response
    switch (status) {
      case 200:
        if (data.success) {
          commit("DELETE_COURSE_START_SECTION_SUCCESS", { course_start_section: { uuid } })
        }
        data.success ? dispatch('alert/createAlertSuccess', "Section deleted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })

        if (data.error || data.errors) {
          commit("DELETE_COURSE_START_SECTION_ERROR", data)
          dispatch('alert/createAlertErrors', data.errors, { root: true })
        }

        
        break;
      case 403:
        dispatch('alert/createAlertErrors', data?.message ?? "Oops! Something Went Wrong.", { root: true });
        break
      default:
        commit("DELETE_COURSE_START_SECTION_ERROR", data)
    }
    return response
  },
}

const mutations = {
  [GET_COURSE_START_SECTIONS_REQUEST]: state => {
    state.loading = true
  },
  [GET_COURSE_START_SECTIONS_SUCCESS]: (state, data) => {
    const courseStartSections = data.course_start_sections
    state.courseStartSections = addNewFilter(state.courseStartSections, courseStartSections)
    state.loading = false
    state.loadedOnce = true
  },
  [GET_COURSE_START_SECTIONS_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  
  [GET_COURSE_START_SECTION_REQUEST]: state => {
    state.loading = true
  },
  [GET_COURSE_START_SECTION_SUCCESS]: (state, data) => {
    const { course_start_section } = data
    state.courseStartSection = course_start_section
    state.courseStartSections = addNewFilter(state.courseStartSections, [course_start_section])
    state.loading = false
  },
  [GET_COURSE_START_SECTION_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_COURSE_START_SECTION_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_COURSE_START_SECTION_SUCCESS]: (state, data) => {
    const { course_start_section } = data
    state.courseStartSections = addNewFilter(state.courseStartSections, [course_start_section])
    state.loading = false
  },
  [CREATE_COURSE_START_SECTION_ERROR]: state => {
    state.loading = false
  },

  [EDIT_COURSE_START_SECTION_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_COURSE_START_SECTION_SUCCESS]: (state, data) => {
    const { course_start_section } = data;
    state.courseStartSections = addNewFilter(state.courseStartSections, [course_start_section])
    state.courseStartSections = state.courseStartSections.map(css => css.uuid == course_start_section.uuid ? course_start_section : css)
    state.loading = false
  },
  [EDIT_COURSE_START_SECTION_ERROR]: state => {
    state.loading = false
  },

  [DELETE_COURSE_START_SECTION_REQUEST]: state => {
    state.loading = true
  },
  //If full delete the object is not returned, just the success boolean.
  // If success == true then pass uuid to remove from data state
  [DELETE_COURSE_START_SECTION_SUCCESS]: (state, data) => {
    const { course_start_section } = data
    state.courseStartSections = state.courseStartSections.filter(css => css.uuid !== course_start_section.uuid)
    state.loading = false
  },
  [DELETE_COURSE_START_SECTION_ERROR]: (state, data) => {
    state.loading = false
  },

}

export const courseStartSection = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export function unpackCourseStartSection(css, commit) {
  return css
}
