import {
  apiRequest,
  processInput
} from "@/utilities"

export const workingAssignmentsService = {
  async getWorkingAssignments() {
    const getWorkingAssignmentsRequest = {
      method: "GET"
    }
    return await apiRequest("/api/working-assignments", getWorkingAssignmentsRequest)
  },
  async createWorkingAssignment(params) {
    const createData = processInput(params);
    const postWorkingAssignmentRequest = {
      method: "POST",
      data: createData
    }

    return await apiRequest("/api/working-assignments", postWorkingAssignmentRequest)
  },
  async getWorkingAssignment(uuid) {

    const getWorkingAssignmentRequest = {
      method: "GET"
    }

    return await apiRequest(`/api/working-assignments/${uuid}`, getWorkingAssignmentRequest);
  },
  async editWorkingAssignment(data, uuid) {
    let editData = processInput(data)
    editData.append("_method", "PATCH");
    const updateWorkingAssignmentRequest = {
      method: "POST",
      data: editData
    }

    return await apiRequest(`/api/working-assignments/${uuid}`, updateWorkingAssignmentRequest);
  },
  async deleteWorkingAssignment(data, uuid) {
    let deleteData = processInput(data)

    const deleteWorkingAssignmentRequest = {
      method: "DELETE",
      data: deleteData
    }

    return await apiRequest(`/api/working-assignments/${uuid}`, deleteWorkingAssignmentRequest);
  }
}
