export const GET_USER_ROLES_REQUEST = "GET_USER_ROLES_REQUEST"
export const GET_USER_ROLES_SUCCESS = "GET_USER_ROLES_SUCCESS"
export const GET_USER_ROLES_ERROR = "GET_USER_ROLES_ERROR"
export const GET_USER_ROLE_REQUEST = "GET_USER_ROLE_REQUEST"
export const GET_USER_ROLE_SUCCESS = "GET_USER_ROLE_SUCCESS"
export const GET_USER_ROLE_ERROR = "GET_USER_ROLE_ERROR"
export const CREATE_USER_ROLE_REQUEST = "CREATE_USER_ROLE_REQUEST"
export const CREATE_USER_ROLE_SUCCESS = "CREATE_USER_ROLE_SUCCESS"
export const CREATE_USER_ROLE_ERROR = "CREATE_USER_ROLE_ERROR"
export const EDIT_USER_ROLE_REQUEST = "EDIT_USER_ROLE_REQUEST"
export const EDIT_USER_ROLE_SUCCESS = "EDIT_USER_ROLE_SUCCESS"
export const EDIT_USER_ROLE_ERROR = "EDIT_USER_ROLE_ERROR"
export const DELETE_USER_ROLE_REQUEST = "DELETE_USER_ROLE_REQUEST"
export const DELETE_USER_ROLE_SUCCESS = "DELETE_USER_ROLE_SUCCESS"
export const DELETE_USER_ROLE_ERROR = "DELETE_USER_ROLE_ERROR"
