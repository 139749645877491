export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR"
export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR"
export const CREATE_NOTIFICATION_REQUEST = "CREATE_NOTIFICATION_REQUEST"
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS"
export const CREATE_NOTIFICATION_ERROR = "CREATE_NOTIFICATION_ERROR"
export const EDIT_NOTIFICATION_REQUEST = "EDIT_NOTIFICATION_REQUEST"
export const EDIT_NOTIFICATION_SUCCESS = "EDIT_NOTIFICATION_SUCCESS"
export const EDIT_NOTIFICATION_ERROR = "EDIT_NOTIFICATION_ERROR"
export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST"
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS"
export const DELETE_NOTIFICATION_ERROR = "DELETE_NOTIFICATION_ERROR"
export const ADD_PUSHER_NOTIFICATION = "ADD_PUSHER_NOTIFICATION"