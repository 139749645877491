import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachFacilitatingCourseService = {
    async getCoachFacilitatingCourses(params) {
        let getParams = processQueryParams(params)
        const getCoachFacilitatingCoursesRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-facilitating-courses", getCoachFacilitatingCoursesRequest)
    },
    async getCoachFacilitatingCourse(uuid) {
        const getCoachFacilitatingCourseRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-facilitating-courses/${uuid}`, getCoachFacilitatingCourseRequest)
    },
    async createCoachFacilitatingCourse(params) {
        const createData = processInput(params)
        const createCoachFacilitatingCourseRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-facilitating-courses", createCoachFacilitatingCourseRequest)
    },
    async editCoachFacilitatingCourse(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachFacilitatingCourseRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-facilitating-courses/${uuid}`, editCoachFacilitatingCourseRequest)
    },
    async deleteCoachFacilitatingCourse(uuid) {
        const deleteCoachFacilitatingCourseRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-facilitating-courses/${uuid}`, deleteCoachFacilitatingCourseRequest)
    }
}