export const coachPortal = {
    path: '/coach-portal',
    component: () => import("@/layouts/CoachPortal.vue"),
    meta: {
        authRequired: true,
        allowedRoles: [
            'coach'
        ],
        label: 'Coach Portal',
    },
    children: [
        {
            path: '',
            name: 'coach-portal-dashboard',
            meta: {
                label: "Dashboard"
            },
            component: () => import("@/pages/coach/coach-portal/CoachPortalDashboard.vue")
        },
        {
            path: 'my-account',
            name: 'coach-portal-acc',
            meta: {
                label: "Profile"
            },
            component: () => import("@/pages/coach/coach-portal/CoachPortalMyAccount.vue")
        },
        {
            path: 'past-assignments',
            name: 'coach-portal-past-assignments',
            meta: {
                label: "Past Assignments"
            },
            component: () => import("@/pages/coach/coach-portal/CoachPortalPastAssignments.vue")
        },
        {
            path: 'past-payments',
            name: 'coach-portal-past-payments',
            meta: {
                label: "Past Payments"
            },
            component: () => import("@/pages/coach/coach-portal/CoachPortalPastPayments.vue")
        },
        {
            path: 'documents',
            name: 'coach-portal-documents',
            meta: {
                label: "Documents"
            },
            component: () => import("@/pages/coach/coach-portal/CoachPortalDocuments.vue")
        },
        {
            path: 'view-offer/:uuid',
            name: 'coach-portal-view-offer',
            meta: {
                label: "Coach Portal View Offer"
            },
            component: () => import("@/pages/coach/coach-portal/CoachPortalViewOffer.vue")
        },
        {
            path: 'offer-accept',
            name: 'offer accept',
            meta: {
                label: "Accept Offer"
            },
            component: () => import("@/pages/offer/OfferAccept.vue")
        },
        {
            path: 'education',
            component: () => import("@/pages/coach/coach-portal/CoachPortalEducation.vue"),
            children: [
                {
                    path: '',
                    name: 'coach-portal-education',
                    meta: {
                        label: "Education"
                    },
                    component: () => import("@/components/coach/portal/pages/CoachPortalEducation.vue")
                },
                {
                    path: 'in-progress-degree/:uuid',
                    name: 'cpe-in-progress-degree',
                    meta: {
                        label: "Education"
                    },
                    component: () => import("@/components/coach/portal/education/InProgressDegreeStatusUpdate.vue")
                }
            ]
        },
        {
            path: 'coach-evaluations/:csUuid',
            name: 'cp-coach-evaluation',
            component: () => import("@/pages/was/evaluation/CoachEvaluation.vue"),
            meta: {
                label: 'Coach Evaluation'
            }
        }
    ]
}