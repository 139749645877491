import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const emailService = {
    async getEmails(params) {
        let getParams = processQueryParams(params)
        const getEmailsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/emails", getEmailsRequest)
    },
    async getEmail(uuid) {
        const getEmailRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/emails/${uuid}`, getEmailRequest)
    },
    async createEmail(params) {
        const createData = processInput(params)
        const createEmailRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/emails", createEmailRequest)
    },
    async bulkEditEmails(params) {
        let editData = processInput(params)
        editData.append("_method", "PATCH")
        const bulkEditEmailRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/bulk-edit-emails/`, bulkEditEmailRequest)
    },
    async editEmail(data) {
        const uuid = data.uuid
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editEmailRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/emails/${uuid}`, editEmailRequest)
    },
    async deleteEmail(uuid) {
        const deleteEmailRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/emails/${uuid}`, deleteEmailRequest)
    }
}