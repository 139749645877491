import {
    GET_GROUPS_REQUEST,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_ERROR,
    GET_GROUP_REQUEST,
    GET_GROUP_SUCCESS,
    GET_GROUP_ERROR,
    CREATE_GROUP_REQUEST,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_ERROR,
    EDIT_GROUP_REQUEST,
    EDIT_GROUP_SUCCESS,
    EDIT_GROUP_ERROR,
    DELETE_GROUP_REQUEST,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_ERROR,
    BROADCAST_DELETE_GROUP_SUCCESS
} from "@/actions"

import { groupService } from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    loading: false,
    loadedOnce: false,
    group: null,
    groups: []
}

const getters = {
    groupsLoading: state => state.loading,
    groupsLoadedOnce: state => state.loadedOnce,
    groups: state => state.groups,
    groupByUuid: state => uuid => state.groups.find(u => u.uuid == uuid),
}

const actions = {
    async getGroups({ commit }) {
        commit("GET_GROUPS_REQUEST")
        const response = await groupService.getGroups();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_GROUPS_SUCCESS", data)
                break;
            default:
                commit("GET_GROUPS_ERROR", data)
        }
        return response
    },
    async createGroup({ commit, dispatch }, groupData) {
        commit("CREATE_GROUP_REQUEST")
        const response = await groupService.createGroup(groupData)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_GROUP_SUCCESS", data)
                break
            case 403:
                commit("CREATE_GROUP_ERROR", data)
                const { message } = data
                let messages = message ?? "NOT Authorized for this action."
                dispatch('alert/createAlertErrors', messages, { root: true })
                break
            default:
                commit("CREATE_GROUP_ERROR", data)
        }
        return response
    },
    async getGroup({ commit, dispatch }, groupUUID) {
        commit("GET_GROUP_REQUEST")
        const response = await groupService.getGroup(groupUUID)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_GROUP_SUCCESS", data)
                break
            default:
                commit("GET_GROUP_ERROR", data)

        }
        return response
    },
    async editGroup({ commit }, params) {
        commit("EDIT_GROUP_REQUEST");
        const { uuid } = params
        const response = await groupService.editGroup(params, uuid);
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("EDIT_GROUP_SUCCESS", data) : commit("GET_GROUP_ERROR", data)
                break
            default:
                commit("GET_GROUP_ERROR", data);
        }
        return response
    },
    async deleteGroup({ commit }, uuid) {
        commit("DELETE_GROUP_REQUEST");
        const response = await groupService.deleteGroup(uuid);
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("DELETE_GROUP_SUCCESS", uuid) : commit("DELETE_GROUP_ERROR", data)
                break
            default:
                commit("DELETE_GROUP_ERROR", data)
        }
        return response
    }
}

const mutations = {
    [GET_GROUPS_REQUEST]: state => {
        state.loading = true
    },
    [GET_GROUPS_SUCCESS]: (state, data) => {
        const groups = data.groups
        state.groups = groups
        state.loading = false
        state.loadedOnce = true
    },
    [GET_GROUPS_ERROR]: (state) => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [CREATE_GROUP_REQUEST]: (state) => {
        state.loading = true
    },
    [CREATE_GROUP_SUCCESS]: (state, data) => {
        const { group } = data
        state.groups = addNewFilter(state.groups, [group])
        state.loading = false
    },
    [CREATE_GROUP_ERROR]: (state) => {
        state.loading = false
    },
    [GET_GROUP_REQUEST]: state => {
        state.loading = true
    },
    [GET_GROUP_SUCCESS]: (state, data) => {
        const { group } = data
        state.group = { ...group }
        state.loading = false
    },
    [GET_GROUP_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [EDIT_GROUP_REQUEST]: (state) => {
        state.loading = true
    },
    [EDIT_GROUP_SUCCESS]: (state, data) => {
        const { group } = data
        state.groups = state.groups.map(g => g.uuid == group.uuid ? group : g)
        if (!isEmpty(state.group) && state.group.uuid == group.uuid) {
            state.group = group
        }
        state.loading = false
    },
    [EDIT_GROUP_ERROR]: state => {
        state.loading = false
    },
    [DELETE_GROUP_REQUEST]: (state) => {
        state.loading = true
    },
    [DELETE_GROUP_SUCCESS]: (state, uuid) => {
        state.groups = state.groups.filter(g => g.uuid !== uuid)
        state.loading = false
    },
    [DELETE_GROUP_ERROR]: state => {
        state.loading = false
    },
    [BROADCAST_DELETE_GROUP_SUCCESS]: (state, data) => {
        const { group } = data
        state.groups = state.groups.filter(g => g.uuid !== group.uuid)
    }
}

export const group = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}