import {
    GET_CONTRACT_ADDENDUMS_REQUEST,
    GET_CONTRACT_ADDENDUMS_SUCCESS,
    GET_CONTRACT_ADDENDUMS_ERROR,
    GET_CONTRACT_ADDENDUM_REQUEST,
    GET_CONTRACT_ADDENDUM_SUCCESS,
    GET_CONTRACT_ADDENDUM_ERROR,
    CREATE_CONTRACT_ADDENDUM_REQUEST,
    CREATE_CONTRACT_ADDENDUM_SUCCESS,
    CREATE_CONTRACT_ADDENDUM_ERROR,
    EDIT_CONTRACT_ADDENDUM_REQUEST,
    EDIT_CONTRACT_ADDENDUM_SUCCESS,
    EDIT_CONTRACT_ADDENDUM_ERROR,
    DELETE_CONTRACT_ADDENDUM_REQUEST,
    DELETE_CONTRACT_ADDENDUM_SUCCESS,
    DELETE_CONTRACT_ADDENDUM_ERROR
} from "@/actions"

import {
    contractAddendumService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"
import { unpackContract, unpackFSR } from "@/modules"

const state = {
    contract_addendums: [],
    contract_addendum: {},
    loading: false
}

const getters = {
    allContractAddendums: state => state.contract_addendums,
    findContractAddendum: state => uuid => state.contract_addendums.find(i => i.uuid == uuid),
    contractAddendumsByContract: state => uuid => state.contract_addendums.filter(i => i.contract_uuid == uuid),
    contractAddendumsByAddendum: state => (cuuid, auuid) => state.contract_addendums.find(i => i.contract_uuid == cuuid && i.addendum_uuid == auuid),
    contractAddendumByUuid: state => uuid => state.contract_addendums.find(i => i.uuid == uuid),
}

const actions = {
    async getContractAddendums({ commit }) {
        commit("GET_CONTRACT_ADDENDUMS_REQUEST")
        const response = await contractAddendumService.getContractAddendums();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_CONTRACT_ADDENDUMS_SUCCESS", data)
                break
            default:
                commit("GET_CONTRACT_ADDENDUMS_ERROR", data)
        }
        return response
    },
    async getContractAddendum({ commit }, params) {
        commit("GET_CONTRACT_ADDENDUM_REQUEST")
        const response = await contractAddendumService.getContractAddendum(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_CONTRACT_ADDENDUM_SUCCESS", data)
                break
            default:
                commit("GET_CONTRACT_ADDENDUM_ERROR", data)
                break
        }
        return response
    },
    async createContractAddendum({ commit }, params) {
        commit("CREATE_CONTRACT_ADDENDUM_REQUEST")
        const response = await contractAddendumService.createContractAddendum(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_CONTRACT_ADDENDUM_SUCCESS", data)
                break
            default:
                commit("CREATE_CONTRACT_ADDENDUM_ERROR", data)
        }
        return response
    },
    async editContractAddendum({ commit }, params) {
        commit("EDIT_CONTRACT_ADDENDUM_REQUEST")
        const response = await contractAddendumService.editContractAddendum(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_CONTRACT_ADDENDUM_SUCCESS", data)
                break
            default:
                commit("EDIT_CONTRACT_ADDENDUM_ERROR", data)
        }
        return response
    },
    async deleteContractAddendum({ commit }, params) {
        commit("DELETE_CONTRACT_ADDENDUM_REQUEST")
        const response = await contractAddendumService.deleteContractAddendum(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_CONTRACT_ADDENDUM_SUCCESS", data)
                break
            default:
                commit("DELETE_CONTRACT_ADDENDUM_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_CONTRACT_ADDENDUMS_REQUEST]: state => {
        state.loading = true
    },
    [GET_CONTRACT_ADDENDUMS_SUCCESS]: (state, data) => {
        state.contract_addendums = addNewFilter(state.contract_addendums, data.contract_addendums)
        state.loading = false
    },
    [GET_CONTRACT_ADDENDUMS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_CONTRACT_ADDENDUM_REQUEST]: state => {
        state.loading = true
    },
    [GET_CONTRACT_ADDENDUM_SUCCESS]: (state, data) => {
        let mdata = { ...data.contract_addendum }
        state.contract_addendum = mdata
        state.loading = false
    },
    [GET_CONTRACT_ADDENDUM_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_CONTRACT_ADDENDUM_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_CONTRACT_ADDENDUM_SUCCESS]: (state, data) => {
        let contract_addendums = [data.contract_addendum]
        state.contract_addendums = addNewFilter(state.contract_addendums, contract_addendums)
        state.loading = false
    },
    [CREATE_CONTRACT_ADDENDUM_ERROR]: state => {
        state.loading = false
    },

    [EDIT_CONTRACT_ADDENDUM_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_CONTRACT_ADDENDUM_SUCCESS]: (state, data) => {
        if(data.success){
            state.contract_addendums = state.contract_addendums.filter(f => f.uuid != data.contract_addendum.uuid)
            let contract_addendums = [data.contract_addendum]
            state.contract_addendums = addNewFilter(state.contract_addendums, contract_addendums)
            state.loading = false
        }
        state.loading = false
    },
    [EDIT_CONTRACT_ADDENDUM_ERROR]: state => {
        state.loading = false
    },

    [DELETE_CONTRACT_ADDENDUM_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_CONTRACT_ADDENDUM_SUCCESS]: (state, data) => {
        state.loading = false
        const {contract_addendum} = data
        if(!isEmpty(contract_addendum)){
            state.contract_addendums = state.contract_addendums.filter(f => f.uuid != contract_addendum.uuid)
            state.loading = false
        }
    },
    BROADCAST_DELETE_CONTRACT_ADDENDUM_SUCCESS: (state, data) => {
        const {contract_addendum} = data
        state.contract_addendums = state.contract_addendums.filter(f => f.uuid != contract_addendum.uuid)
        state.loading = false
    },
    [DELETE_CONTRACT_ADDENDUM_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const contractAddendum = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


export function unpackContractAddendum(contract_addendum, commit){
    if(!isEmpty(contract_addendum.documents)){
        commit('document/GET_DOCUMENTS_SUCCESS', contract_addendum, { root: true })
        delete contract_addendum.documents
    }
    if (!isEmpty(contract_addendum.contract)) {
        let contract = unpackContract(contract_addendum.contract, commit)
        commit('contract/GET_CONTRACTS_SUCCESS', {contracts: [contract]}, { root: true })
        delete contract_addendum.contract
    }
    if (!isEmpty(contract_addendum.faculty_source_roles)) {
        let faculty_source_roles = contract_addendum.faculty_source_roles.map(i => unpackFSR(i, commit))
        commit('facultySourceRole/GET_FACULTY_SOURCE_ROLES_SUCCESS', {faculty_source_roles}, { root: true })
        delete contract_addendum.faculty_source_roles
    }
    return contract_addendum
}