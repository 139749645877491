export const GET_UNIVERSITY_PROGRAMS_REQUEST = "GET_UNIVERSITY_PROGRAMS_REQUEST"
export const GET_UNIVERSITY_PROGRAMS_SUCCESS = "GET_UNIVERSITY_PROGRAMS_SUCCESS"
export const GET_UNIVERSITY_PROGRAMS_ERROR = "GET_UNIVERSITY_PROGRAMS_ERROR"
export const GET_UNIVERSITY_PROGRAM_REQUEST = "GET_UNIVERSITY_PROGRAM_REQUEST"
export const GET_UNIVERSITY_PROGRAM_SUCCESS = "GET_UNIVERSITY_PROGRAM_SUCCESS"
export const GET_UNIVERSITY_PROGRAM_ERROR = "GET_UNIVERSITY_PROGRAM_ERROR"
export const CREATE_UNIVERSITY_PROGRAM_REQUEST = "CREATE_UNIVERSITY_PROGRAM_REQUEST"
export const CREATE_UNIVERSITY_PROGRAM_SUCCESS = "CREATE_UNIVERSITY_PROGRAM_SUCCESS"
export const CREATE_UNIVERSITY_PROGRAM_ERROR = "CREATE_UNIVERSITY_PROGRAM_ERROR"
export const EDIT_UNIVERSITY_PROGRAM_REQUEST = "EDIT_UNIVERSITY_PROGRAM_REQUEST"
export const EDIT_UNIVERSITY_PROGRAM_SUCCESS = "EDIT_UNIVERSITY_PROGRAM_SUCCESS"
export const EDIT_UNIVERSITY_PROGRAM_ERROR = "EDIT_UNIVERSITY_PROGRAM_ERROR"
export const DELETE_UNIVERSITY_PROGRAM_REQUEST = "DELETE_UNIVERSITY_PROGRAM_REQUEST"
export const DELETE_UNIVERSITY_PROGRAM_SUCCESS = "DELETE_UNIVERSITY_PROGRAM_SUCCESS"
export const DELETE_UNIVERSITY_PROGRAM_ERROR = "DELETE_UNIVERSITY_PROGRAM_ERROR"

export const CLEAR_SECTION_UNIVERSITY_PROGRAM_REQUEST = "CLEAR_SECTION_UNIVERSITY_PROGRAM_REQUEST"
export const CLEAR_SECTION_UNIVERSITY_PROGRAM_SUCCESS = "CLEAR_SECTION_UNIVERSITY_PROGRAM_SUCCESS"
export const CLEAR_SECTION_UNIVERSITY_PROGRAM_ERROR = "CLEAR_SECTION_UNIVERSITY_PROGRAM_ERROR"