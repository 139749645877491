export const GET_PROGRAMS_REQUEST = "GET_PROGRAMS_REQUEST"
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS"
export const GET_PROGRAMS_ERROR = "GET_PROGRAMS_ERROR"
export const GET_PROGRAM_REQUEST = "GET_PROGRAM_REQUEST"
export const GET_PROGRAM_SUCCESS = "GET_PROGRAM_SUCCESS"
export const GET_PROGRAM_ERROR = "GET_PROGRAM_ERROR"
export const CREATE_PROGRAM_REQUEST = "CREATE_PROGRAM_REQUEST"
export const CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS"
export const CREATE_PROGRAM_ERROR = "CREATE_PROGRAM_ERROR"
export const EDIT_PROGRAM_REQUEST = "EDIT_PROGRAM_REQUEST"
export const EDIT_PROGRAM_SUCCESS = "EDIT_PROGRAM_SUCCESS"
export const EDIT_PROGRAM_ERROR = "EDIT_PROGRAM_ERROR"
export const DELETE_PROGRAM_REQUEST = "DELETE_PROGRAM_REQUEST"
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS"
export const DELETE_PROGRAM_ERROR = "DELETE_PROGRAM_ERROR"