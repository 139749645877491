export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const AUTH_USER_REQUEST = "AUTH_USER_REQUEST"
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS"
export const AUTH_USER_ERROR = "AUTH_USER_ERROR"
export const AUTH_USER_UPDATE = "AUTH_USER_UPDATE"

export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_ERROR = "LOGOUT_ERROR"

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR"

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST"
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS"
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR"

export const GET_MAGIC_LINK_REQUEST = "GET_MAGIC_LINK_REQUEST"
export const GET_MAGIC_LINK_SUCCESS = "GET_MAGIC_LINK_SUCCESS" 
export const GET_MAGIC_LINK_ERROR = "GET_MAGIC_LINK_ERROR"

export const UNAUTHORIZED = "UNAUTHORIZED"