import {
    apiRequest,
    processInput
} from "@/utilities"

export const lcpaaService = {
    async getLcpaas() {
        const getLcpaasRequest = {
            method: "GET"
        }

        return await apiRequest("/api/lcpaas", getLcpaasRequest)
    },
    async createLcpaa(params) {
        let createData = processInput(params);
        const postLcpaaRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/lcpaas", postLcpaaRequest)
    },
    async getLcpaa(uuid) {

        const getLcpaaRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/lcpaas/${uuid}`, getLcpaaRequest);
    },
    // async editLcpaa(params, uuid) {
    //     let editData = processInput(params)
    //     editData.append('_method', 'PATCH')
    //     const editLcpaaRequest = {
    //         method: "POST",
    //         data: editData
    //     }

    //     return await apiRequest(`/api/lcpaas/${uuid}`, editLcpaaRequest);
    // },
    async deleteLcpaa(uuid) {

        const deleteLcpaaRequest = {
            method: "DELETE",
        }

        return await apiRequest(`/api/lcpaas/${uuid}`, deleteLcpaaRequest);
    }
}