import {
	GET_COACH_EDUCATIONS_REQUEST,
	GET_COACH_EDUCATIONS_SUCCESS,
	GET_COACH_EDUCATIONS_ERROR,
	GET_COACH_EDUCATION_REQUEST,
	GET_COACH_EDUCATION_SUCCESS,
	GET_COACH_EDUCATION_ERROR,
	CREATE_COACH_EDUCATION_REQUEST,
	CREATE_COACH_EDUCATION_SUCCESS,
	CREATE_COACH_EDUCATION_ERROR,
	EDIT_COACH_EDUCATION_REQUEST,
	EDIT_COACH_EDUCATION_SUCCESS,
	EDIT_COACH_EDUCATION_ERROR,
	DELETE_COACH_EDUCATION_REQUEST,
	DELETE_COACH_EDUCATION_SUCCESS,
	DELETE_COACH_EDUCATION_ERROR,
	CLEAR_COACH_EDUCATION_STATE
} from "@/actions"

import {
	coachEducationService
} from "@/services"

import {
	unpackCoach
} from "@/modules"

import { isEmpty, addNewFilter, onlyUnique } from "@/utilities"

const state = {
	coachEducations: [],
	coachEducation: {},
	loading: false,
	loadedOnce: false
}

const getters = {
	cEduLoadedOnce: state => state.loadedOnce,

	coachEducationLoading: state => state.loading,

	coachEducation: (state, getters, rootState, rootGetters) => state.coachEducations.filter(ce => ce.coaches_uuid == rootGetters['coaches/coach']['uuid'] && isEmpty(ce.deleted_at)),

	coachEducations: state => cUuid => state.coachEducations.filter(ce => ce.coaches_uuid == cUuid && isEmpty(ce.deleted_at)),

	coachEducationByCoachUuid: state => cUuid => state.coachEducations.filter(ce => ce.coaches_uuid == cUuid && isEmpty(ce.deleted_at)),

	highestDegree: (state, getters, rootState, rootGetters) => state.coachEducations.find(ce => ce.highest_degree == true && rootGetters['coaches/coach']['uuid'] == ce.coaches_uuid && isEmpty(ce.deleted_at)),

	additionalDegrees: (state, getters, rootState, rootGetters) => state.coachEducations.filter(ce => ce.additional_degree == true && rootGetters['coaches/coach']['uuid'] == ce.coaches_uuid && isEmpty(ce.deleted_at)),

	inProgressDegrees: (state, getters, rootState, rootGetters) => state.coachEducations.filter(ce => ce.in_progress == true && rootGetters['coaches/coach']['uuid'] == ce.coaches_uuid && isEmpty(ce.deleted_at)),

	abandonedDegrees: (state, getters, rootState, rootGetters) => state.coachEducations.filter(ce => rootGetters['coaches/coach']['uuid'] == ce.coaches_uuid && !isEmpty(ce.deleted_at)),

	coachEduMinReqMet: (state, getters, rootState, rootGetters) => {
		let hasAdvanced = false,
			hasAdditional = false
		let ces = state.coachEducations.filter(ce => ce.coaches_uuid == rootGetters['coaches/coach']['uuid'] && isEmpty(ce.deleted_at));
		ces.forEach(cEdu => {
			if (cEdu.highest_degree == true) hasAdvanced = true
			if (cEdu.additional_degree == true) hasAdditional = true
		})
		return (hasAdvanced && hasAdditional) ? true : false
	},
	
	completedDegreeTranscripts: (state, getters, rootState, rootGetters) => (cUuid) => {
		if(isEmpty(cUuid)){
			cUuid = rootGetters['coaches/coach']['uuid'];
		}
		if(isEmpty(cUuid)) return [];
		const completedDegrees = state.coachEducations.filter(ce => cUuid == ce.coaches_uuid && ce.in_progress != 1 && isEmpty(ce.deleted_at));
		if(isEmpty(completedDegrees)) return [];
		
		const docTypeGetter = rootGetters['documentType/getDocTypeBySlug'];
		const offDoc = docTypeGetter('official-transcript');
		if (isEmpty(offDoc)) return [];

		const docGetter = rootGetters['document/docByOwnerTypeUuid'];
		const findDegree = rootGetters['degree/degreeOptionFilterUuid'];
		return completedDegrees.map(i => {
			const transcript_doc = docGetter(i.uuid, offDoc.uuid)
			const has_transcript = !isEmpty(transcript_doc)
			const has_transcript_ordered = !isEmpty(i.tra_ordered_date)
			const degree = findDegree(i.degrees_uuid)
			return {
				...i,
				transcript_doc,
				has_transcript,
				has_transcript_ordered,
				degree
			}
		})
	},
	inProgressDegreeTranscripts: (state, getters, rootState, rootGetters) => (cUuid) => {
		if(isEmpty(cUuid)){
			cUuid = rootGetters['coaches/coach']['uuid'];
		}
		if(isEmpty(cUuid)) return [];
		const inProgressDegrees = state.coachEducations.filter(ce => cUuid == ce.coaches_uuid && ce.in_progress == 1 && isEmpty(ce.deleted_at));
		if(isEmpty(inProgressDegrees)) return [];
		
		const docTypeGetter = rootGetters['documentType/getDocTypeBySlug'];
		const offDoc = docTypeGetter('official-transcript');
		if (isEmpty(offDoc)) return [];

		const docGetter = rootGetters['document/docByOwnerTypeUuid'];
		const findDegree = rootGetters['degree/degreeOptionFilterUuid'];
		return inProgressDegrees.map(i => {
			const transcript_doc = docGetter(i.uuid, offDoc.uuid)
			const has_transcript = !isEmpty(transcript_doc)
			const has_transcript_ordered = !isEmpty(i.tra_ordered_date)
			const degree = findDegree(i.degrees_uuid)
			return {
				...i,
				transcript_doc,
				has_transcript,
				has_transcript_ordered,
				degree
			}
		})
	},
	inProgressDegreesByCUuid: (state, getters, rootState, rootGetters) => (cUuid) => {
		if(isEmpty(cUuid)){
			cUuid = rootGetters['coaches/coach']['uuid'];
		}
		if(isEmpty(cUuid)) return [];
		return state.coachEducations.filter(ce => cUuid == ce.coaches_uuid && ce.in_progress == 1 && isEmpty(ce.deleted_at));
	},

	allOfficialTranscripts: (state, getters, rootState, rootGetters) => getters.coachEducation.every(ce => {
		let docTypeGetter = rootGetters['documentType/getDocTypeBySlug']
		let offDoc = docTypeGetter('official-transcript')
		if (isEmpty(offDoc)) return false
		let docGetter = rootGetters['document/docByOwnerTypeUuid']
		return docGetter(ce.uuid, offDoc.uuid)
	}),
	allOfficialTranscriptsByCoachUuid: (state, getters, rootState, rootGetters) => cUuid => state.coachEducations.filter(ce => ce.coaches_uuid == cUuid).every(ce => {
		let docTypeGetter = rootGetters['documentType/getDocTypeBySlug']
		let offDoc = docTypeGetter('official-transcript')
		if (isEmpty(offDoc)) return false
		let docGetter = rootGetters['document/docByOwnerTypeUuid']
		return docGetter(ce.uuid, offDoc.uuid)
	}),
	otherMinors: state => state.coachEducations.filter(ce => !isEmpty(ce.other_minor)).sort((a, b) => a.other_minor.localeCompare(a.other_minor)),
	otherDisciplines: state => state.coachEducations.filter(ce => !isEmpty(ce.other_discipline)).sort((a, b) => a.other_discipline.localeCompare(b.other_discipline)),
	otherUniversities: state => state.coachEducations.filter(ce => !isEmpty(ce.other_university)).sort((a, b) => a.other_university.localeCompare(b.other_university)),

	missingTranscripts: (state, getters, rootState, rootGetters) => {
		const docTypeGetter = rootGetters['documentType/getDocTypeBySlug'];
		const offDoc = docTypeGetter('official-transcript');
		if (isEmpty(offDoc)) return [];
		const docGetter = rootGetters['document/docByOwnerTypeUuid'];
		return state.coachEducations.map(i => {
			const transcript_doc = docGetter(i.uuid, offDoc.uuid)
			return {
				...i,
				has_transcript: !isEmpty(transcript_doc)
			}
		}).filter(i => !i.has_transcript || !isEmpty(i.final_trans_date))
	},
}

const actions = {
	async getCoachEducations({ commit }, params) {
		commit("GET_COACH_EDUCATIONS_REQUEST")
		const response = await coachEducationService.getCoachEducations(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					if (params.otherUniversity) {
						data.coach_educations = data.coach_educations.map(ce => {
							let coaches = unpackCoach(ce.coach, commit)
							coaches = [coaches]
							commit('coaches/GET_COACHES_SUCCESS', { coaches }, { root: true })
							delete ce.coach
							return ce
						})
					}
					let coach_educations = []
					if(params?.page){
						coach_educations = data.coach_educations.data.map(ce => unpackCoachEducation(ce, commit))
					}else{
						coach_educations = data.coach_educations.map(ce => unpackCoachEducation(ce, commit))
					}

					commit("GET_COACH_EDUCATIONS_SUCCESS", {coach_educations})
				}
				break
			default:
				commit("GET_COACH_EDUCATIONS_ERROR", data)
		}
		return response;
	},
	async getCoachEducation({ commit }, params) {
		commit("GET_COACH_EDUCATION_REQUEST")
		const response = await coachEducationService.getCoachEducation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("GET_COACH_EDUCATION_SUCCESS", data) : commit("GET_COACH_EDUCATION_ERROR", data)
				break
			default:
				commit("GET_COACH_EDUCATION_ERROR", data)
				break
		}
		return response;
	},
	async createCoachEducation({ commit, dispatch }, params) {
		commit("CREATE_COACH_EDUCATION_REQUEST")
		const response = await coachEducationService.createCoachEducation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_COACH_EDUCATION_SUCCESS", data) : commit("CREATE_COACH_EDUCATION_ERROR", data);
				data.success ? dispatch('alert/createAlertSuccess', "Education Created", { root: true }) : dispatch('alert/createAlertErrors', data.errors ?? "Oops! Something went wrong.", { root: true })
				break
			default:
				commit("CREATE_COACH_EDUCATION_ERROR", data)
		}
		return response;
	},
	async editCoachEducation({ commit, dispatch }, params) {
		commit("EDIT_COACH_EDUCATION_REQUEST")
		const uuid = params.uuid
		const response = await coachEducationService.editCoachEducation(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_COACH_EDUCATION_SUCCESS", data) : commit("EDIT_COACH_EDUCATION_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Education Updated", { root: true }) : dispatch('alert/createAlertErrors', data.errors ?? "Oops! Something went wrong.", { root: true })
				break
			default:
				commit("EDIT_COACH_EDUCATION_ERROR", data)
		}
		return response;
	},
	async deleteCoachEducation({ commit, dispatch }, uuid) {
		commit("DELETE_COACH_EDUCATION_REQUEST")
		const response = await coachEducationService.deleteCoachEducation(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_COACH_EDUCATION_SUCCESS", uuid) : null;
				data.success ? dispatch('alert/createAlertSuccess', "Education Removed", { root: true }) : dispatch('alert/createAlertErrors', data.errors ?? "Oops! Something went wrong.", { root: true })
				break
			default:
				commit("DELETE_COACH_EDUCATION_DELETE", data)
		}
		return response;
	}
}

const mutations = {
	[GET_COACH_EDUCATIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_EDUCATIONS_SUCCESS]: (state, data) => {
		let coachEducations = data.coach_educations
		state.coachEducations = addNewFilter(state.coachEducations, coachEducations)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_COACH_EDUCATIONS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_COACH_EDUCATION_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_EDUCATION_SUCCESS]: (state, data) => {
		let coachEducation = { ...data.coach_education }
		state.coachEducation = coachEducation
		state.loading = false
	},
	[GET_COACH_EDUCATION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COACH_EDUCATION_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COACH_EDUCATION_SUCCESS]: (state, data) => {
		const { coach_education } = data
		state.coachEducations = addNewFilter(state.coachEducations, [coach_education])
		state.loading = false
	},
	[CREATE_COACH_EDUCATION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COACH_EDUCATION_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COACH_EDUCATION_SUCCESS]: (state, data) => {
		const { coach_education } = data
		state.coachEducations = state.coachEducations.map(ce => coach_education.uuid == ce.uuid ? coach_education : ce)
		state.coachEducations = addNewFilter(state.coachEducations, [coach_education])
		state.loading = false
	},
	[EDIT_COACH_EDUCATION_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COACH_EDUCATION_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COACH_EDUCATION_SUCCESS]: (state, uuid) => {
		state.coachEducations = state.coachEducations.filter(ce => ce.uuid !== uuid)
		state.loading = false;
	},
	[DELETE_COACH_EDUCATION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CLEAR_COACH_EDUCATION_STATE]: (state) => {
		state.coachEducations = [],
			state.coachEducation = {},
			state.loading = false
	}
}

export const coachEducation = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

export function unpackCoachEducation(ce, commit) {
	if (!isEmpty(ce.university)) {
		const { university } = ce;
		commit('university/GET_UNIVERSITIES_SUCCESS', { universities: [university] }, { root: true })
	}
	delete ce.university;

	if (!isEmpty(ce.documents)) {
		let documents = ce.documents
		commit('document/GET_DOCUMENTS_SUCCESS', { documents }, { root: true })
		delete ce.documents
	}

	if (!isEmpty(ce.coach)) {
		let _coaches = [ce.coach]
		const coaches  = _coaches.map(c => unpackCoach(c, commit))
        commit('coaches/GET_COACHES_SUCCESS', {coaches}, { root: true })
		delete ce.coach
	}

	if (!isEmpty(ce.coach_limited_data_resource)) {
		let _coaches = [ce.coach_limited_data_resource]
		const coaches  = _coaches.map(c => unpackCoach(c, commit))
        commit('coaches/GET_COACHES_SUCCESS', {coaches}, { root: true })
		delete ce.coach_limited_data_resource
	}

	return ce
}
