import {
	reportService
} from "@/services"

const state = {

}

const getters = {

}

const actions = {
	async getReport({ commit }, params) {
		commit("GET_REPORT_REQUEST")
		const response = await reportService.getReport(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_REPORT_SUCCESS", data)
				break
			default:
				commit("GET_REPORT_ERROR", data)
		}
		return response
	},
}

const mutations = {
	GET_REPORT_REQUEST: (state) => {
		state.loading = true;
	},
	GET_REPORT_SUCCESS: (state) => {
		state.loading = false;
	},
	GET_REPORT_ERROR: (state) => {
		state.loading = false;
	}
}

export const report = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
