import {
	GET_UNIVERSITY_PROSPECTS_REQUEST,
	GET_UNIVERSITY_PROSPECTS_SUCCESS,
	GET_UNIVERSITY_PROSPECTS_ERROR,
	GET_UNIVERSITY_PROSPECT_REQUEST,
	GET_UNIVERSITY_PROSPECT_SUCCESS,
	GET_UNIVERSITY_PROSPECT_ERROR,
	CREATE_UNIVERSITY_PROSPECT_REQUEST,
	CREATE_UNIVERSITY_PROSPECT_SUCCESS,
	CREATE_UNIVERSITY_PROSPECT_ERROR,
	EDIT_UNIVERSITY_PROSPECT_REQUEST,
	EDIT_UNIVERSITY_PROSPECT_SUCCESS,
	EDIT_UNIVERSITY_PROSPECT_ERROR,
	DELETE_UNIVERSITY_PROSPECT_REQUEST,
	BROADCAST_DELETE_UNIVERSITY_PROSPECT_SUCCESS,
	DELETE_UNIVERSITY_PROSPECT_SUCCESS,
	DELETE_UNIVERSITY_PROSPECT_ERROR
} from "@/actions"

import {
	universityProspectsService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
	universityProspects: [],
	universityProspect: {},
	loading: false,
	loadedOnce: false
}

const getters = {
	univProspectLoading: state => state.loading,
	univProspectLoadedOnce: state => state.univProspectLoadedOnce,
	universityProspectFilter: state => univUuid => state.universityProspects.find(up => up.university_uuid == univUuid),
	universityProspect: state => state.universityProspect
}

function unpackUniversity(university, commit) {
	Object.keys(university).forEach(key => {
		let data = {}
		let value = university[key]
		if (!isEmpty(value)) data.success = true
		switch (key) {
			case "documents":
				data['data'] = isEmpty(value) ? [] : value
				commit('universityProspectDocument/GET_UNIVERSITY_PROSPECT_DOCUMENTS_SUCCESS', data, { root: true })
				break
			case "meetings":
				data['university_prospect_meetings'] = isEmpty(value) ? [] : value
				commit('universityProspectMeeting/GET_UNIVERSITY_PROSPECT_MEETINGS_SUCCESS', data, { root: true })
				break
		}
	})
}
const actions = {
	async getUniversityProspects({ commit }) {
		commit("GET_UNIVERSITY_PROSPECTS_REQUEST")
		const response = await universityProspectsService.getUniversityProspects();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_UNIVERSITY_PROSPECTS_SUCCESS", data)
				break
			default:
				commit("GET_UNIVERSITY_PROSPECTS_ERROR", data)
		}
		return response
	},
	async getUniversityProspect({ commit }, params) {
		commit("GET_UNIVERSITY_PROSPECT_REQUEST")
		const response = await universityProspectsService.getUniversityProspect(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("GET_UNIVERSITY_PROSPECT_SUCCESS", data) : commit("GET_UNIVERSITY_PROSPECT_ERROR", data)
				data.success ? unpackUniversity(data.university_prospect, commit) : null
				break
			default:
				commit("GET_UNIVERSITY_PROSPECT_ERROR", data)
				break
		}
		return response
	},
	async createUniversityProspect({ commit }, params) {
		commit("CREATE_UNIVERSITY_PROSPECT_REQUEST")
		const response = await universityProspectsService.createUniversityProspect(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_UNIVERSITY_PROSPECT_SUCCESS", data) : commit("CREATE_UNIVERSITY_PROSPECT_ERROR", data)
				data.success ? unpackUniversity(data.university_prospect, commit) : null
				break
			default:
				commit("CREATE_UNIVERSITY_PROSPECT_ERROR", data)
		}
		return response
	},
	async editUniversityProspect({ commit }, params) {
		commit("EDIT_UNIVERSITY_PROSPECT_REQUEST")
		const response = await universityProspectsService.editUniversityProspect(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_UNIVERSITY_PROSPECT_SUCCESS", data) : commit("EDIT_UNIVERSITY_PROSPECT_ERROR", data)
				data.success ? unpackUniversity(data.university_prospect, commit) : null
				break
			default:
				commit("EDIT_UNIVERSITY_PROSPECT_ERROR", data)
		}
		return response
	},
	async deleteUniversityProspect({ commit }, params) {
		commit("DELETE_UNIVERSITY_PROSPECT_REQUEST")
		const response = await universityProspectsService.deleteUniversityProspect(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_UNIVERSITY_PROSPECT_SUCCESS", data)
				break
			default:
				commit("DELETE_UNIVERSITY_PROSPECT_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_UNIVERSITY_PROSPECTS_REQUEST]: state => {
		state.loading = true
	},
	[GET_UNIVERSITY_PROSPECTS_SUCCESS]: (state, data) => {
		let university_prospects = [...data.university_prospects]
		state.universityProspects = addNewFilter(state.universityProspects, university_prospects)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_UNIVERSITY_PROSPECTS_ERROR]: state => {
		state.loading = false
		state.error = true,
			state.loadedOnce = true
	},
	[GET_UNIVERSITY_PROSPECT_REQUEST]: state => {
		state.loading = true
	},
	[GET_UNIVERSITY_PROSPECT_SUCCESS]: (state, data) => {
		let mdata = { ...data.university_prospect }
		state.universityProspect = mdata
		state.loading = false
		state.loadedOnce = true
	},
	[GET_UNIVERSITY_PROSPECT_ERROR]: (state, data) => {
		state.loading = false,
			state.loadedOnce = true
	},

	[CREATE_UNIVERSITY_PROSPECT_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_UNIVERSITY_PROSPECT_SUCCESS]: (state, data) => {
		state.loading = false
		const { university_prospect } = data
		if (!isEmpty(university_prospect)) {
			state.universityProspects = addNewFilter(state.universityProspects, [university_prospect])
			state.universityProspect = university_prospect
		}
	},
	[CREATE_UNIVERSITY_PROSPECT_ERROR]: state => {
		state.loading = false
	},

	[EDIT_UNIVERSITY_PROSPECT_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_UNIVERSITY_PROSPECT_SUCCESS]: (state, data) => {
		state.loading = false
		const { university_prospect } = data
		if (!isEmpty(university_prospect)) {
			state.universityProspects = state.universityProspects.filter(up => up.uuid != university_prospect.uuid)
			state.universityProspects = addNewFilter(state.universityProspects, [university_prospect])
			state.universityProspect = university_prospect
		}
	},
	[EDIT_UNIVERSITY_PROSPECT_ERROR]: state => {
		state.loading = false
	},

	[DELETE_UNIVERSITY_PROSPECT_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_UNIVERSITY_PROSPECT_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[BROADCAST_DELETE_UNIVERSITY_PROSPECT_SUCCESS]: (state, data) => {
		const { university_prospect } = data
		if (!isEmpty(university_prospect)) {
			state.universityProspects = state.universityProspects.filter(up => up.uuid != university_prospect.uuid)
		}
		state.loading = false
	},
	[DELETE_UNIVERSITY_PROSPECT_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const universityProspect = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}