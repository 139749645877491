export const GET_COACH_EVALUATIONS_REQUEST = "GET_COACH_EVALUATIONS_REQUEST"
export const GET_COACH_EVALUATIONS_SUCCESS = "GET_COACH_EVALUATIONS_SUCCESS"
export const GET_COACH_EVALUATIONS_ERROR = "GET_COACH_EVALUATIONS_ERROR"
export const GET_COACH_EVALUATION_REQUEST = "GET_COACH_EVALUATION_REQUEST"
export const GET_COACH_EVALUATION_SUCCESS = "GET_COACH_EVALUATION_SUCCESS"
export const GET_COACH_EVALUATION_ERROR = "GET_COACH_EVALUATION_ERROR"
export const GET_COACH_EVALUATION_STATUS_REQUEST = "GET_COACH_EVALUATION_STATUS_REQUEST"
export const GET_COACH_EVALUATION_STATUS_SUCCESS = "GET_COACH_EVALUATION_STATUS_SUCCESS"
export const GET_COACH_EVALUATION_STATUS_ERROR = "GET_COACH_EVALUATION_STATUS_ERROR"
export const CREATE_COACH_EVALUATION_REQUEST = "CREATE_COACH_EVALUATION_REQUEST"
export const CREATE_COACH_EVALUATION_SUCCESS = "CREATE_COACH_EVALUATION_SUCCESS"
export const CREATE_COACH_EVALUATION_ERROR = "CREATE_COACH_EVALUATION_ERROR"
export const EDIT_COACH_EVALUATION_REQUEST = "EDIT_COACH_EVALUATION_REQUEST"
export const EDIT_COACH_EVALUATION_SUCCESS = "EDIT_COACH_EVALUATION_SUCCESS"
export const EDIT_COACH_EVALUATION_ERROR = "EDIT_COACH_EVALUATION_ERROR"
export const DELETE_COACH_EVALUATION_REQUEST = "DELETE_COACH_EVALUATION_REQUEST"
export const DELETE_COACH_EVALUATION_SUCCESS = "DELETE_COACH_EVALUATION_SUCCESS"
export const DELETE_COACH_EVALUATION_ERROR = "DELETE_COACH_EVALUATION_ERROR"