export const GET_UNIVERSITY_PROSPECTS_REQUEST = "GET_UNIVERSITY_PROSPECTS_REQUEST"
export const GET_UNIVERSITY_PROSPECTS_SUCCESS = "GET_UNIVERSITY_PROSPECTS_SUCCESS"
export const GET_UNIVERSITY_PROSPECTS_ERROR = "GET_UNIVERSITY_PROSPECTS_ERROR"
export const GET_UNIVERSITY_PROSPECT_REQUEST = "GET_UNIVERSITY_PROSPECT_REQUEST"
export const GET_UNIVERSITY_PROSPECT_SUCCESS = "GET_UNIVERSITY_PROSPECT_SUCCESS"
export const GET_UNIVERSITY_PROSPECT_ERROR = "GET_UNIVERSITY_PROSPECT_ERROR"
export const CREATE_UNIVERSITY_PROSPECT_REQUEST = "CREATE_UNIVERSITY_PROSPECT_REQUEST"
export const CREATE_UNIVERSITY_PROSPECT_SUCCESS = "CREATE_UNIVERSITY_PROSPECT_SUCCESS"
export const CREATE_UNIVERSITY_PROSPECT_ERROR = "CREATE_UNIVERSITY_PROSPECT_ERROR"
export const EDIT_UNIVERSITY_PROSPECT_REQUEST = "EDIT_UNIVERSITY_PROSPECT_REQUEST"
export const EDIT_UNIVERSITY_PROSPECT_SUCCESS = "EDIT_UNIVERSITY_PROSPECT_SUCCESS"
export const EDIT_UNIVERSITY_PROSPECT_ERROR = "EDIT_UNIVERSITY_PROSPECT_ERROR"
export const DELETE_UNIVERSITY_PROSPECT_REQUEST = "DELETE_UNIVERSITY_PROSPECT_REQUEST"
export const DELETE_UNIVERSITY_PROSPECT_SUCCESS = "DELETE_UNIVERSITY_PROSPECT_SUCCESS"
export const BROADCAST_DELETE_UNIVERSITY_PROSPECT_SUCCESS = "BROADCAST_DELETE_UNIVERSITY_PROSPECT_SUCCESS"
export const DELETE_UNIVERSITY_PROSPECT_ERROR = "DELETE_UNIVERSITY_PROSPECT_ERROR"