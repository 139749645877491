import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const invoicePaymentService = {
  async getInvoicePayments(params) {
    let getParams = processQueryParams(params)
    const getInvoicePaymentsRequest = {
      method: "GET",
      params: getParams
    }
    return await apiRequest("/api/invoice-payments", getInvoicePaymentsRequest)
  },
  async getInvoicePayment(uuid) {
    const getInvoicePaymentRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/invoice-payments/${uuid}`, getInvoicePaymentRequest)
  },
  async createInvoicePayment(params) {
    const createData = processInput(params)
    const createInvoicePaymentRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/invoice-payments", createInvoicePaymentRequest)
  },
  async editInvoicePayment(data) {
    const { uuid } = data
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editInvoicePaymentRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/invoice-payments/${uuid}`, editInvoicePaymentRequest)
  },
  async deleteInvoicePayment(uuid) {
    const deleteInvoicePaymentRequest = {
      method: "DELETE"
    }
    return await apiRequest(`/api/invoice-payments/${uuid}`, deleteInvoicePaymentRequest)
  }
}