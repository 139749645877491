export const GET_DOCUMENT_TYPES_REQUEST = "GET_DOCUMENT_TYPES_REQUEST"
export const GET_DOCUMENT_TYPES_SUCCESS = "GET_DOCUMENT_TYPES_SUCCESS"
export const GET_DOCUMENT_TYPES_ERROR = "GET_DOCUMENT_TYPES_ERROR"
export const GET_DOCUMENT_TYPE_REQUEST = "GET_DOCUMENT_TYPE_REQUEST"
export const GET_DOCUMENT_TYPE_SUCCESS = "GET_DOCUMENT_TYPE_SUCCESS"
export const GET_DOCUMENT_TYPE_ERROR = "GET_DOCUMENT_TYPE_ERROR"
export const CREATE_DOCUMENT_TYPE_REQUEST = "CREATE_DOCUMENT_TYPE_REQUEST"
export const CREATE_DOCUMENT_TYPE_SUCCESS = "CREATE_DOCUMENT_TYPE_SUCCESS"
export const CREATE_DOCUMENT_TYPE_ERROR = "CREATE_DOCUMENT_TYPE_ERROR"
export const EDIT_DOCUMENT_TYPE_REQUEST = "EDIT_DOCUMENT_TYPE_REQUEST"
export const EDIT_DOCUMENT_TYPE_SUCCESS = "EDIT_DOCUMENT_TYPE_SUCCESS"
export const EDIT_DOCUMENT_TYPE_ERROR = "EDIT_DOCUMENT_TYPE_ERROR"
export const DELETE_DOCUMENT_TYPE_REQUEST = "DELETE_DOCUMENT_TYPE_REQUEST"
export const DELETE_DOCUMENT_TYPE_SUCCESS = "DELETE_DOCUMENT_TYPE_SUCCESS"
export const DELETE_DOCUMENT_TYPE_ERROR = "DELETE_DOCUMENT_TYPE_ERROR"