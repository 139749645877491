export const GET_PHONES_REQUEST = "GET_PHONES_REQUEST"
export const GET_PHONES_SUCCESS = "GET_PHONES_SUCCESS"
export const GET_PHONES_ERROR = "GET_PHONES_ERROR"
export const GET_PHONE_REQUEST = "GET_PHONE_REQUEST"
export const GET_PHONE_SUCCESS = "GET_PHONE_SUCCESS"
export const GET_PHONE_ERROR = "GET_PHONE_ERROR"
export const CREATE_PHONE_REQUEST = "CREATE_PHONE_REQUEST"
export const CREATE_PHONE_SUCCESS = "CREATE_PHONE_SUCCESS"
export const CREATE_PHONE_ERROR = "CREATE_PHONE_ERROR"
export const EDIT_PHONE_REQUEST = "EDIT_PHONE_REQUEST"
export const EDIT_PHONE_SUCCESS = "EDIT_PHONE_SUCCESS"
export const EDIT_PHONE_ERROR = "EDIT_PHONE_ERROR"
export const DELETE_PHONE_REQUEST = "DELETE_PHONE_REQUEST"
export const DELETE_PHONE_SUCCESS = "DELETE_PHONE_SUCCESS"
export const DELETE_PHONE_ERROR = "DELETE_PHONE_ERROR"
export const CLEAR_PHONE_STATE = "CLEAR_PHONE_STATE"
export const BROADCAST_DELETE_PHONE_SUCCESS = "BROADCAST_DELETE_PHONE_SUCCESS"