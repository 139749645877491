import {
    apiRequest,
    processInput
} from "@/utilities"

export const coachStatusService = {
    async getCoachStatuses() {
        const getCoachStatusesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/coach-statuses", getCoachStatusesRequest)
    },
    async getCoachStatus(uuid) {
        const getCoachStatusRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-statuses/${uuid}`, getCoachStatusRequest)
    },
    async createCoachStatus(params) {
        const createData = processInput(params)
        const createCoachStatusRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-statuses", createCoachStatusRequest)
    },
    async editCoachStatus(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachStatusRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-statuses/${uuid}`, editCoachStatusRequest)
    },
    async deleteCoachStatus(uuid) {
        const deleteCoachStatusRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-statuses/${uuid}`, deleteCoachStatusRequest)
    }
}