export const GET_UNIVERSITY_RFPS_REQUEST = "GET_UNIVERSITY_RFPS_REQUEST"
export const GET_UNIVERSITY_RFPS_SUCCESS = "GET_UNIVERSITY_RFPS_SUCCESS"
export const GET_UNIVERSITY_RFPS_ERROR = "GET_UNIVERSITY_RFPS_ERROR"
export const GET_UNIVERSITY_RFP_REQUEST = "GET_UNIVERSITY_RFP_REQUEST"
export const GET_UNIVERSITY_RFP_SUCCESS = "GET_UNIVERSITY_RFP_SUCCESS"
export const GET_UNIVERSITY_RFP_ERROR = "GET_UNIVERSITY_RFP_ERROR"
export const CREATE_UNIVERSITY_RFP_REQUEST = "CREATE_UNIVERSITY_RFP_REQUEST"
export const CREATE_UNIVERSITY_RFP_SUCCESS = "CREATE_UNIVERSITY_RFP_SUCCESS"
export const CREATE_UNIVERSITY_RFP_ERROR = "CREATE_UNIVERSITY_RFP_ERROR"
export const EDIT_UNIVERSITY_RFP_REQUEST = "EDIT_UNIVERSITY_RFP_REQUEST"
export const EDIT_UNIVERSITY_RFP_SUCCESS = "EDIT_UNIVERSITY_RFP_SUCCESS"
export const EDIT_UNIVERSITY_RFP_ERROR = "EDIT_UNIVERSITY_RFP_ERROR"
export const DELETE_UNIVERSITY_RFP_REQUEST = "DELETE_UNIVERSITY_RFP_REQUEST"
export const DELETE_UNIVERSITY_RFP_SUCCESS = "DELETE_UNIVERSITY_RFP_SUCCESS"
export const BROADCAST_DELETE_UNIVERSITY_RFP_SUCCESS = "BROADCAST_DELETE_UNIVERSITY_RFP_SUCCESS"
export const DELETE_UNIVERSITY_RFP_ERROR = "DELETE_UNIVERSITY_RFP_ERROR"