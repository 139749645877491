export const GET_COACH_SCREENING_SESSIONS_REQUEST = "GET_COACH_SCREENING_SESSIONS_REQUEST"
export const GET_COACH_SCREENING_SESSIONS_SUCCESS = "GET_COACH_SCREENING_SESSIONS_SUCCESS"
export const GET_COACH_SCREENING_SESSIONS_ERROR = "GET_COACH_SCREENING_SESSIONS_ERROR"
export const GET_COACH_SCREENING_SESSION_REQUEST = "GET_COACH_SCREENING_SESSION_REQUEST"
export const GET_COACH_SCREENING_SESSION_SUCCESS = "GET_COACH_SCREENING_SESSION_SUCCESS"
export const GET_COACH_SCREENING_SESSION_ERROR = "GET_COACH_SCREENING_SESSION_ERROR"
export const CREATE_COACH_SCREENING_SESSION_REQUEST = "CREATE_COACH_SCREENING_SESSION_REQUEST"
export const CREATE_COACH_SCREENING_SESSION_SUCCESS = "CREATE_COACH_SCREENING_SESSION_SUCCESS"
export const CREATE_COACH_SCREENING_SESSION_ERROR = "CREATE_COACH_SCREENING_SESSION_ERROR"
export const EDIT_COACH_SCREENING_SESSION_REQUEST = "EDIT_COACH_SCREENING_SESSION_REQUEST"
export const EDIT_COACH_SCREENING_SESSION_SUCCESS = "EDIT_COACH_SCREENING_SESSION_SUCCESS"
export const EDIT_COACH_SCREENING_SESSION_ERROR = "EDIT_COACH_SCREENING_SESSION_ERROR"
export const DELETE_COACH_SCREENING_SESSION_REQUEST = "DELETE_COACH_SCREENING_SESSION_REQUEST"
export const DELETE_COACH_SCREENING_SESSION_SUCCESS = "DELETE_COACH_SCREENING_SESSION_SUCCESS"
export const BROADCAST_DELETE_COACH_SCREENING_SESSION_SUCCESS = "BROADCAST_DELETE_COACH_SCREENING_SESSION_SUCCESS"
export const DELETE_COACH_SCREENING_SESSION_ERROR = "DELETE_COACH_SCREENING_SESSION_ERROR"
export const CLEAR_COACH_SCREENING_SESSION_STATE = "CLEAR_COACH_SCREENING_SESSION_STATE"