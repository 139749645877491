import {
	GET_STATUSES_REQUEST,
	GET_STATUSES_SUCCESS,
	GET_STATUSES_ERROR,
	GET_STATUS_REQUEST,
	GET_STATUS_SUCCESS,
	GET_STATUS_ERROR,
	CREATE_STATUS_REQUEST,
	CREATE_STATUS_SUCCESS,
	CREATE_STATUS_ERROR,
	EDIT_STATUS_REQUEST,
	EDIT_STATUS_SUCCESS,
	EDIT_STATUS_ERROR,
	DELETE_STATUS_REQUEST,
	DELETE_STATUS_SUCCESS,
	DELETE_STATUS_ERROR
} from "@/actions"

import {
	statusService
} from "@/services"

const state = {
	statuses: [],
	status: {},
	loading: true,
	loadedOnce: false
}
const statusOrders = [
	"applicant",
	"candidate",
	"contracted",
	"pending",
	"active",
	"separated",
	"resigned",
	"dnua",
  ]
import { isEmpty, addNewFilter } from "@/utilities";

const getters = {
	statusesLoading: state => state.loading,

	statusesLoadedOnce: state => state.loadedOnce,

	/**
	 *
	 */
	statuses: state => {
		const orderedStatuses = statusOrders.map(i =>  state.statuses.find(s => s.slug == i)).filter(i => !isEmpty(i))
        return orderedStatuses
	},

	/**
	 *
	 */
	applicant: state => state.statuses.find(status => status.slug == 'applicant'),

	/**
	 *
	 */
	candidate: state => state.statuses.find(status => status.slug == 'candidate'),

	/**
	 *
	 *
	 */
	applicantCandidate: state => {
		let searchable = [
			'applicant',
			'candidate'
		];
		
		return state.statuses.filter(status => {
      return searchable.includes(status.slug);
		})
	},

	/**
	 *
	 *
	 */
	active: state => {
		let searchable = [
			"contracted",
			"pending",
			"active",
			"separated",
			"resigned"
		];
		
		return state.statuses.filter(status => {
			return searchable.includes(status.slug)
		})
	},

	/**
	 *
	 *
	 */
	activeUuids: (state, getters) => {
		return getters.active.map(s => s.uuid)
	},

	/**
	 *
	 *
	 */
	statusOptions: (state, getters) => {
		let exportArray = []
		getters.statuses.map(s => {

			if (!isEmpty(s.status_explanations)) {
				s.status_explanations.forEach(se => {
					exportArray.push({
						label: `${s.label} - ${se.label}`,
						value: `${s.uuid}-${se.uuid}`,
						slug: s.slug,
						attrs: {
							'data-statuses-uuid': s.uuid,
							'data-statuses-slug': s.slug,
							'data-status-explanations-uuid': se.uuid,
							'data-status-explanations-slug': se.slug,
						}
					})
				})
				return
			}

			exportArray.push({
				label: `${s.label}`,
				slug: s.slug,
				value: s.uuid,
				attrs: {
					'data-statuses-uuid': s.uuid,
					'data-statuses-slug': s.slug
				}
			})
		})
		return exportArray
	},

	statusSelectOptions: (state, getters) => getters.statuses.map(s => ({
		value: s.uuid,
		label: s.label,
		attrs: {
			'data-statuses-uuid': s.uuid,
			'data-statuses-slug': s.slug
		}
	})),
	
	statusMultiSelectOptions: (state, getters) => getters.statuses.map(s => {
		return {
			value: s.uuid,
			text: s.label
		}
	}),

	/**
	 *
	 *
	 */
	statusFilterByUuid: (state, getters, rootState, rootGetters) => uuid => {
		let status = getters.statuses.find(s => s.uuid == uuid)
		if(!isEmpty(status)){
			const expFind = rootGetters['statusExplanation/statusExpFilterByStatusUuid']
			status.status_explanations = expFind(status.uuid)
		}
		return status
	},

	statusFind: (state) => uuid => state.statuses.find(s => s.uuid == uuid),

	/**
	 *
	 *
	 */
	statusFilterBySlug: state => slug => state.statuses.find(s => s.slug == slug),

	/**
	 *
	 * @param {*} state
	 * @returns String
	 */
	statusBadgeColor: state => slug => {
		switch (slug) {
			case "contracted":
				return "secondary";
			case "pending":
				return "info";
			case "active":
				return "success";
			case "separated":
				return "warning";
			case "resigned":
				return "dark";
			case "dnua":
				return "danger"
			default:
				return "primary";
		}
	}
}

const actions = {
	async getStatuses({ commit }) {
		commit("GET_STATUSES_REQUEST")
		const response = await statusService.getStatuses();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_STATUSES_SUCCESS", data)
				break
			default:
				commit("GET_STATUSES_ERROR", data)
		}
		return response
	},
	async getStatus({ commit }, params) {
		commit("GET_STATUS_REQUEST")
		const response = await statusService.getStatus(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_STATUS_SUCCESS", data)
				break
			default:
				commit("GET_STATUS_ERROR", data)
				break
		}
		return response
	},
	async createStatus({ commit }, params) {
		commit("CREATE_STATUS_REQUEST")
		const response = await statusService.createStatus(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_STATUS_SUCCESS", data)
				break
			default:
				commit("CREATE_STATUS_ERROR", data)
		}
		return response
	},
	async editStatus({ commit }, params, uuid) {
		commit("EDIT_STATUS_REQUEST")
		const response = await statusService.editStatus(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_STATUS_SUCCESS", data)
				break
			default:
				commit("EDIT_STATUS_ERROR", data)
		}
		return response
	},
	async deleteStatus({ commit }, params) {
		commit("DELETE_STATUS_REQUEST")
		const response = await statusService.deleteStatus(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_STATUS_SUCCESS", data)
				break
			default:
				commit("DELETE_STATUS_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_STATUSES_REQUEST]: state => {
		state.loading = true
	},
	[GET_STATUSES_SUCCESS]: (state, data) => {
		const statuses = data.statuses
		state.statuses = statuses
		state.loading = false;
		state.loadedOnce = true;
	},
	[GET_STATUSES_ERROR]: state => {
		state.loading = false;
		state.error = true;
		state.loadedOnce = true;
	},
	[GET_STATUS_REQUEST]: state => {
		state.loading = true
	},
	[GET_STATUS_SUCCESS]: (state, data) => {
		const { status } = data
		state.status = status
		state.loading = false
	},
	[GET_STATUS_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_STATUS_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_STATUS_SUCCESS]: (state, data) => {
		const { status } = data
		state.statues = addNewFilter(state.statuses, [status])
		state.loading = false
	},
	[CREATE_STATUS_ERROR]: state => {
		state.loading = false
	},

	[EDIT_STATUS_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_STATUS_SUCCESS]: state => {
		state.loading = false
	},
	[EDIT_STATUS_ERROR]: state => {
		state.loading = false
	},
	[DELETE_STATUS_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_STATUS_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_STATUS_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const status = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
