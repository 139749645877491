export const GET_PREFIXES_REQUEST = "GET_PREFIXES_REQUEST"
export const GET_PREFIXES_SUCCESS = "GET_PREFIXES_SUCCESS"
export const GET_PREFIXES_ERROR = "GET_PREFIXES_ERROR"
export const GET_PREFIX_REQUEST = "GET_PREFIX_REQUEST"
export const GET_PREFIX_SUCCESS = "GET_PREFIX_SUCCESS"
export const GET_PREFIX_ERROR = "GET_PREFIX_ERROR"
export const CREATE_PREFIX_REQUEST = "CREATE_PREFIX_REQUEST"
export const CREATE_PREFIX_SUCCESS = "CREATE_PREFIX_SUCCESS"
export const CREATE_PREFIX_ERROR = "CREATE_PREFIX_ERROR"
export const EDIT_PREFIX_REQUEST = "EDIT_PREFIX_REQUEST"
export const EDIT_PREFIX_SUCCESS = "EDIT_PREFIX_SUCCESS"
export const EDIT_PREFIX_ERROR = "EDIT_PREFIX_ERROR"
export const DELETE_PREFIX_REQUEST = "DELETE_PREFIX_REQUEST"
export const DELETE_PREFIX_SUCCESS = "DELETE_PREFIX_SUCCESS"
export const DELETE_PREFIX_ERROR = "DELETE_PREFIX_ERROR"