import {
    apiRequest,
    processInput
} from "@/utilities"

export const certificationService = {
    async getCertifications() {
        const getCertificationsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/certifications", getCertificationsRequest)
    },
    async getCertification(uuid) {
        const getCertificationRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/certifications/${uuid}`, getCertificationRequest)
    },
    async createCertification(params) {
        const createData = processInput(params)
        const createCertificationRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/certifications", createCertificationRequest)
    },
    async editCertification(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCertificationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/certifications/${uuid}`, editCertificationRequest)
    },
    async deleteCertification(uuid) {
        const deleteCertificationRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/certifications/${uuid}`, deleteCertificationRequest)
    }
}