export const GET_UNIVERSITY_PROGRAM_POCS_REQUEST = "GET_UNIVERSITY_PROGRAM_POCS_REQUEST"
export const GET_UNIVERSITY_PROGRAM_POCS_SUCCESS = "GET_UNIVERSITY_PROGRAM_POCS_SUCCESS"
export const GET_UNIVERSITY_PROGRAM_POCS_ERROR = "GET_UNIVERSITY_PROGRAM_POCS_ERROR"
export const GET_UNIVERSITY_PROGRAM_POC_REQUEST = "GET_UNIVERSITY_PROGRAM_POC_REQUEST"
export const GET_UNIVERSITY_PROGRAM_POC_SUCCESS = "GET_UNIVERSITY_PROGRAM_POC_SUCCESS"
export const GET_UNIVERSITY_PROGRAM_POC_ERROR = "GET_UNIVERSITY_PROGRAM_POC_ERROR"
export const CREATE_UNIVERSITY_PROGRAM_POC_REQUEST = "CREATE_UNIVERSITY_PROGRAM_POC_REQUEST"
export const CREATE_UNIVERSITY_PROGRAM_POC_SUCCESS = "CREATE_UNIVERSITY_PROGRAM_POC_SUCCESS"
export const CREATE_UNIVERSITY_PROGRAM_POC_ERROR = "CREATE_UNIVERSITY_PROGRAM_POC_ERROR"
export const EDIT_UNIVERSITY_PROGRAM_POC_REQUEST = "EDIT_UNIVERSITY_PROGRAM_POC_REQUEST"
export const EDIT_UNIVERSITY_PROGRAM_POC_SUCCESS = "EDIT_UNIVERSITY_PROGRAM_POC_SUCCESS"
export const EDIT_UNIVERSITY_PROGRAM_POC_ERROR = "EDIT_UNIVERSITY_PROGRAM_POC_ERROR"
export const DELETE_UNIVERSITY_PROGRAM_POC_REQUEST = "DELETE_UNIVERSITY_PROGRAM_POC_REQUEST"
export const DELETE_UNIVERSITY_PROGRAM_POC_SUCCESS = "DELETE_UNIVERSITY_PROGRAM_POC_SUCCESS"
export const DELETE_UNIVERSITY_PROGRAM_POC_ERROR = "DELETE_UNIVERSITY_PROGRAM_POC_ERROR"