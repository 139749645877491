import {
    apiRequest,
    processInput
} from "@/utilities"

export const contractAddendumService = {
    async getContractAddendums() {
        const getContractAddendumsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/contract-addendums", getContractAddendumsRequest)
    },
    async createContractAddendum(params) {
        const createData = processInput(params);
        const postContractAddendumRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/contract-addendums", postContractAddendumRequest)
    },
    async getContractAddendum(uuid) {

        const getContractAddendumRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/contract-addendums/${uuid}`, getContractAddendumRequest);
    },
    async editContractAddendum(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateContractAddendumRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/contract-addendums/${uuid}`, updateContractAddendumRequest);
    },
    async deleteContractAddendum(uuid) {

        const deleteContractAddendumRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/contract-addendums/${uuid}`, deleteContractAddendumRequest);
    }
}