import {
  GET_PAYMENT_SCHEDULES_REQUEST,
  GET_PAYMENT_SCHEDULES_SUCCESS,
  GET_PAYMENT_SCHEDULES_ERROR,
  GET_PAYMENT_SCHEDULE_REQUEST,
  GET_PAYMENT_SCHEDULE_SUCCESS,
  GET_PAYMENT_SCHEDULE_ERROR,
  CREATE_PAYMENT_SCHEDULE_REQUEST,
  CREATE_PAYMENT_SCHEDULE_SUCCESS,
  CREATE_PAYMENT_SCHEDULE_ERROR,
  EDIT_PAYMENT_SCHEDULE_REQUEST,
  EDIT_PAYMENT_SCHEDULE_SUCCESS,
  EDIT_PAYMENT_SCHEDULE_ERROR,
  DELETE_PAYMENT_SCHEDULE_REQUEST,
  DELETE_PAYMENT_SCHEDULE_SUCCESS,
  DELETE_PAYMENT_SCHEDULE_ERROR,
} from "@/actions"

import {
  paymentScheduleService
} from "@/services"

import { addNewFilter } from "@/utilities"

const state = {
  loading: false,
  loadedOnce: false,
  payment_schedules: []
}

const getters = {
  paymentSchedules: (state) => state.payment_schedules,
  paymentSchedulesByUserUuid: (state) => uuid => state.payment_schedules.filter(ps => ps.users_uuid == uuid)
}

const actions = {
  async getPaymentSchedules({ commit }, params) {
    commit("GET_PAYMENT_SCHEDULES_REQUEST")
    const response = await paymentScheduleService.getPaymentSchedules(params);
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_PAYMENT_SCHEDULES_SUCCESS", data)
        break
      default:
        commit("GET_PAYMENT_SCHEDULES_ERROR", data)
    }
    return response
  },
  async getPaymentSchedule({ commit }, params) {
    commit("GET_PAYMENT_SCHEDULE_REQUEST")
    const response = await paymentScheduleService.getPaymentSchedule(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_PAYMENT_SCHEDULE_SUCCESS", data)
        break
      default:
        commit("GET_PAYMENT_SCHEDULE_ERROR", data)
        break
    }
    return response
  },
  async createPaymentSchedule({ commit, dispatch }, params) {
    commit("CREATE_PAYMENT_SCHEDULE_REQUEST")
    const response = await paymentScheduleService.createPaymentSchedule(params)
    const { status, data } = response
    switch (status) {
      case 200:
        // data.success ? commit("CREATE_PAYMENT_SCHEDULE_SUCCESS", data) : null
        data.success ? dispatch("alert/createAlertSuccess", "Payment schedule created.", { root: true }): null
        break
      default:
        commit("CREATE_PAYMENT_SCHEDULE_ERROR")
    }
    return response
  },
  async editPaymentSchedule({ commit, dispatch }, params) {
    commit("EDIT_PAYMENT_SCHEDULE_REQUEST")
    const response = await paymentScheduleService.editPaymentSchedule(params)
    const { status, data } = response
    switch (status) {
      case 200:
        // data.success ? commit("EDIT_PAYMENT_SCHEDULE_SUCCESS", data) : null;
        data.success ? dispatch("alert/createAlertSuccess", "Payment schedule updated.", { root: true }) : null;
        break
      default:
        commit("EDIT_PAYMENT_SCHEDULE_ERROR", data)
    }
    return response
  },
  async deletePaymentSchedule({ commit, dispatch }, params) {
    commit("DELETE_PAYMENT_SCHEDULE_REQUEST")
    const { uuid } = params;
    const response = await paymentScheduleService.deletePaymentSchedule(params)
    const { status, data } = response
    switch (status) {
      case 200:
        // data.success ? commit("DELETE_PAYMENT_SCHEDULE_SUCCESS", { payment_schedule: { uuid } }) : null
        data.success ? dispatch("alert/createAlertSuccess", "Payment schedule deleted.", { root: true }) : null
        break
      default:
        commit("DELETE_PAYMENT_SCHEDULE_ERROR")
    }
    return response
  }
}

const mutations = {
  [GET_PAYMENT_SCHEDULES_REQUEST]: state => {
    state.loading = true
  },
  [GET_PAYMENT_SCHEDULES_SUCCESS]: (state, data) => {
    const payment_schedules = data.payment_schedules;
    state.payment_schedules = addNewFilter(state.payment_schedules, payment_schedules);
    state.loading = false
    state.loadedOnce = true
  },
  [GET_PAYMENT_SCHEDULES_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },

  [GET_PAYMENT_SCHEDULE_REQUEST]: state => {
    state.loading = true
  },
  [GET_PAYMENT_SCHEDULE_SUCCESS]: (state) => {
    state.loading = false
  },
  [GET_PAYMENT_SCHEDULE_ERROR]: (state) => {
    state.loading = false
  },

  [CREATE_PAYMENT_SCHEDULE_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_PAYMENT_SCHEDULE_SUCCESS]: (state, data) => {
    const { payment_schedule } = data;
    state.payment_schedules = addNewFilter(state.payment_schedules, [payment_schedule]);
    state.loading = false;
  },

  [CREATE_PAYMENT_SCHEDULE_ERROR]: state => {
    state.loading = false;
  },

  [EDIT_PAYMENT_SCHEDULE_REQUEST]: state => {
    state.loading = true;
  },
  [EDIT_PAYMENT_SCHEDULE_SUCCESS]: (state, data) => {
    const { payment_schedule } = data;
    state.payment_schedules = addNewFilter(state.payment_schedules, [payment_schedule]);
    state.loading = false;
  },
  [EDIT_PAYMENT_SCHEDULE_ERROR]: state => {
    state.loading = false
  },

  TRASHED_PAYMENT_SCHEDULE_SUCCESS: (state, data) => {
    const { payment_schedule } = data;
    state.payment_schedules = addNewFilter(state.payment_schedules, [payment_schedule]);
  },

  [DELETE_PAYMENT_SCHEDULE_REQUEST]: (state) => {
    state.loading = true
  },
  [DELETE_PAYMENT_SCHEDULE_SUCCESS]: (state, data) => {
    const { payment_schedule } = data;
    state.payment_schedules = state.payment_schedules.filter(ps => ps.uuid !== payment_schedule?.uuid)
    state.loading = false
  },
  [DELETE_PAYMENT_SCHEDULE_ERROR]: (state) => {
    state.loading = false
  },
}

export const paymentSchedule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}