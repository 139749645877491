import {
    GET_OPMS_REQUEST,
    GET_OPMS_SUCCESS,
    GET_OPMS_ERROR,
    GET_OPM_REQUEST,
    GET_OPM_SUCCESS,
    GET_OPM_ERROR,
    CREATE_OPM_REQUEST,
    CREATE_OPM_SUCCESS,
    CREATE_OPM_ERROR,
    EDIT_OPM_REQUEST,
    EDIT_OPM_SUCCESS,
    EDIT_OPM_ERROR,
    DELETE_OPM_REQUEST,
    DELETE_OPM_SUCCESS,
    DELETE_OPM_ERROR
} from "@/actions"

import {
    opmsService
} from "@/services"

const state = {
    opms: [],
    opm: {},
    loading: false,
    loadedOnce: false,
}

const getters = {
    opmLoadedOnce: state => state.loadedOnce,
    allOpm: state => state.opms,
    opmsSelectOptions: state => {
        return state.opms.map((opm) => {
            return { text: opm.name, value: opm.uuid }
        })
    },
    opmFilter: state => uuid => state.opms.find(upm => upm.uuid == uuid)
}

const actions = {
    async getOpms({ commit }) {
        commit("GET_OPMS_REQUEST")
        const response = await opmsService.getOpms();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_OPMS_SUCCESS", data)
                break
            default:
                commit("GET_OPMS_ERROR", data)
        }
        return response
    },
    async getOpm({ commit }, params) {
        commit("GET_OPM_REQUEST")
        const response = await opmsService.getOpm(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_OPM_SUCCESS", data)
                break
            default:
                commit("GET_OPM_ERROR", data)
                break
        }
        return response
    },
    async createOpm({ commit }, params) {
        commit("CREATE_OPM_REQUEST")
        const response = await opmsService.createOpm(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_OPM_SUCCESS", data)
                break
            default:
                commit("CREATE_OPM_ERROR", data)
        }
        return response
    },
    async editOpm({ commit }, params) {
        commit("EDIT_OPM_REQUEST")
        const response = await opmsService.editOpm(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_OPM_SUCCESS", data)
                break
            default:
                commit("EDIT_OPM_ERROR", data)
        }
        return response
    },
    async deleteOpm({ commit }, params) {
        commit("DELETE_OPM_REQUEST")
        const response = await opmsService.deleteOpm(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_OPM_SUCCESS", data)
                break
            default:
                commit("DELETE_OPM_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_OPMS_REQUEST]: state => {
        state.loading = true
    },
    [GET_OPMS_SUCCESS]: (state, data) => {
        let mdata = data.data
        state.opms = mdata
        state.loading = false
        state.loadedOnce = true
    },
    [GET_OPMS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_OPM_REQUEST]: state => {
        state.loading = true
    },
    [GET_OPM_SUCCESS]: (state, data) => {
        let mdata = { ...data.data }
        state.opm = mdata
        state.loading = false
    },
    [GET_OPM_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_OPM_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_OPM_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [CREATE_OPM_ERROR]: state => {
        state.loading = false
    },

    [EDIT_OPM_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_OPM_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_OPM_ERROR]: state => {
        state.loading = false
    },

    [DELETE_OPM_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_OPM_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_OPM_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const opm = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}