import { isEmpty } from "./helper.input";

var _ = require('lodash');

export function moneyFormat(number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(number)
}

/**
*
* @param {*} value
* @param {*} makeSlug
* @returns
*/
export function strToLower(value = null, makeSlug = false) {
  if (typeof value !== 'string') {
    return value
  }
  if (isEmpty(value)) {
    return null
  }
  let str = null
  if (makeSlug) {
    str = value.toLowerCase().replace(/\s+/g, '-')
  } else {
    str = value.toLowerCase().replace(/[^\w\s]/gi, '')
  }
  return (!isEmpty(str)) ? str.trim() : null
}

/**
 *
 * @param {*} value
 * @returns
 */
export function strToUpper(value = null) {
  if (typeof value !== 'string') {
    return value
  }
  if (isEmpty(value)) {
    return null
  }
  return value.toUpperCase()
}

/**
 *  @param {string} string
 *  @returns string
 */
export function underscoreToCamel(string) {
  return string.replace(/_\w/g, m => m[1].toUpperCase());
}

/**
 *
 *
 */
export function camelToUnderscore(string) {
  return string.replace(/([A-Z])/g, "_$1").toLowerCase();
}

/**
 *
 * @param {*} string
 * @returns String
 */
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}


/**
 *
 * @returns String
 */
export function randomBootstrapColor() {
  const colors = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'dark'
  ];

  return colors[Math.floor(Math.random() * colors.length)];
}

/**
 *
 * @param {String} string
 * @returns {Boolean}
 */
export function isUuid(string) {
  const isUuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  return isUuidRegex.test(string);
}

/**
 *
 * @param {function} callback
 * @param {integer} wait
 * @returns
 */
export function debounce(fn, wait) {

  let timer;
  
  return function (...args) {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    const context = this; // get the current context

    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, wait);
  }
}

/**
 * Throttle
 * 
 * @param {*} fn 
 * @param {*} wait 
 * @returns 
 */
export function throttle(fn, wait) {
  let throttled = false;
  return function (...args) {
    if (!throttled) {
      fn.apply(this, args);
      throttled = true;
      setTimeout(() => {
        throttled = false;
      }, wait);
    }
  }
}


export const debounceByParam = (targetFunc, resolver, ...debounceParams) => {
  _.wrap(
    _.memoize(
      () => _.debounce(targetFunc, ...debounceParams),
      resolver
    ),
    (getMemoizedFunc, ...params) =>
      getMemoizedFunc(...params)(...params)
  )
}
