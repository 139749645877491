import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const pointOfContactsService = {
    async getPointOfContacts(params) {
        const pocGetQueryParams = processQueryParams(params)
        const getPointOfContactsRequest = {
            method: "GET",
            params: pocGetQueryParams
        }
        return await apiRequest("/api/point-of-contacts", getPointOfContactsRequest)
    },
    async createPointOfContact(params) {
        const createData = processInput(params);
        const postPointOfContactRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/point-of-contacts", postPointOfContactRequest)
    },
    async getPointOfContact(uuid) {

        const getPointOfContactRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/point-of-contacts/${uuid}`, getPointOfContactRequest);
    },
    async editPointOfContact(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const editPointOfContactRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/point-of-contacts/${uuid}`, editPointOfContactRequest)
    },
    async deletePointOfContact(uuid) {

        const deletePointOfContactRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/point-of-contacts/${uuid}`, deletePointOfContactRequest);
    }
}