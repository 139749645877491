export const GET_UNIVERSITY_PROSPECT_MEETINGS_REQUEST = "GET_UNIVERSITY_PROSPECT_MEETINGS_REQUEST"
export const GET_UNIVERSITY_PROSPECT_MEETINGS_SUCCESS = "GET_UNIVERSITY_PROSPECT_MEETINGS_SUCCESS"
export const GET_UNIVERSITY_PROSPECT_MEETINGS_ERROR = "GET_UNIVERSITY_PROSPECT_MEETINGS_ERROR"
export const GET_UNIVERSITY_PROSPECT_MEETING_REQUEST = "GET_UNIVERSITY_PROSPECT_MEETING_REQUEST"
export const GET_UNIVERSITY_PROSPECT_MEETING_SUCCESS = "GET_UNIVERSITY_PROSPECT_MEETING_SUCCESS"
export const GET_UNIVERSITY_PROSPECT_MEETING_ERROR = "GET_UNIVERSITY_PROSPECT_MEETING_ERROR"
export const CREATE_UNIVERSITY_PROSPECT_MEETING_REQUEST = "CREATE_UNIVERSITY_PROSPECT_MEETING_REQUEST"
export const CREATE_UNIVERSITY_PROSPECT_MEETING_SUCCESS = "CREATE_UNIVERSITY_PROSPECT_MEETING_SUCCESS"
export const CREATE_UNIVERSITY_PROSPECT_MEETING_ERROR = "CREATE_UNIVERSITY_PROSPECT_MEETING_ERROR"
export const EDIT_UNIVERSITY_PROSPECT_MEETING_REQUEST = "EDIT_UNIVERSITY_PROSPECT_MEETING_REQUEST"
export const EDIT_UNIVERSITY_PROSPECT_MEETING_SUCCESS = "EDIT_UNIVERSITY_PROSPECT_MEETING_SUCCESS"
export const EDIT_UNIVERSITY_PROSPECT_MEETING_ERROR = "EDIT_UNIVERSITY_PROSPECT_MEETING_ERROR"
export const DELETE_UNIVERSITY_PROSPECT_MEETING_REQUEST = "DELETE_UNIVERSITY_PROSPECT_MEETING_REQUEST"
export const DELETE_UNIVERSITY_PROSPECT_MEETING_SUCCESS = "DELETE_UNIVERSITY_PROSPECT_MEETING_SUCCESS"
export const BROADCAST_DELETE_UNIVERSITY_PROSPECT_MEETING_SUCCESS = "BROADCAST_DELETE_UNIVERSITY_PROSPECT_MEETING_SUCCESS"
export const DELETE_UNIVERSITY_PROSPECT_MEETING_ERROR = "DELETE_UNIVERSITY_PROSPECT_MEETING_ERROR"