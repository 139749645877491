import {
	GET_TASKS_REQUEST,
	GET_TASKS_SUCCESS,
	GET_TASKS_ERROR,
	GET_TASK_REQUEST,
	GET_TASK_SUCCESS,
	GET_TASK_ERROR,
	CREATE_TASK_REQUEST,
	CREATE_TASK_SUCCESS,
	CREATE_TASK_ERROR,
	EDIT_TASK_REQUEST,
	EDIT_TASK_SUCCESS,
	EDIT_TASK_ERROR,
	DELETE_TASK_REQUEST,
	DELETE_TASK_SUCCESS,
	DELETE_TASK_ERROR
} from "@/actions"

import {
	taskService
} from "@/services"

import { addNewFilter } from "@/utilities";

const taskDefaultState = () => ({
	tasks: [],
	task: {},
	loading: false,
	loadedOnce: false
});

const state = taskDefaultState();

// const state = {
// 	tasks: [],
// 	task: {},
// 	loading: false,
// 	loadedOnce: false
// }

const getters = {
	tasks: state => state.tasks,
	tasksByOwner: state => uuid => state.tasks.filter(t => t.taskable_uuid == uuid)
}

const actions = {
	async getTasks({ commit }) {
		commit("GET_TASKS_REQUEST")
		const response = await taskService.getTasks();
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("GET_TASKS_SUCCESS", data) : commit("GET_TASKS_ERROR", data)
				break
			default:
				commit("GET_TASKS_ERROR", data)
		}
		return response
	},
	async getTaskByUserUuid({ commit }, params) {
		commit("GET_TASK_REQUEST")
		const response = await taskService.getTaskByUserUuid(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("GET_TASKS_SUCCESS", data) : commit("GET_TASKS_ERROR", data)
				break
			default:
				commit("GET_TASK_ERROR", data)
				break
		}
		return response
	},
	async createTask({ commit }, params) {
		commit("CREATE_TASK_REQUEST")
		const response = await taskService.createTask(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_TASK_SUCCESS", data) : commit("CREATE_TASK_ERROR", data)
				break
			default:
				commit("CREATE_TASK_ERROR", data)
		}
		return response
	},
	async editTask({ commit }, params) {
		commit("EDIT_TASK_REQUEST")
		const { uuid } = params
		const response = await taskService.editTask(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_TASK_SUCCESS", data) : commit("EDIT_TASK_ERROR", data)
				break
			default:
				commit("EDIT_TASK_ERROR", data)
		}
		return response
	},
	async deleteTask({ commit }, params) {
		commit("DELETE_TASK_REQUEST")
		const response = await taskService.deleteTask(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_TASK_SUCCESS", data) : commit("DELETE_TASK_DELETE", data)
				break
			default:
				commit("DELETE_TASK_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_TASKS_REQUEST]: state => {
		state.loading = true
	},
	[GET_TASKS_SUCCESS]: (state, data) => {
		const tasks = data.tasks
		state.tasks = addNewFilter(state.tasks, tasks)
		state.loading = false
	},
	[GET_TASKS_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_TASK_REQUEST]: state => {
		state.loading = true
	},
	[GET_TASK_SUCCESS]: (state, data) => {
		const task = { ...data.task }
		state.task = task
		state.loading = false
	},
	[GET_TASK_ERROR]: (state, data) => {
		state.loading = false
	},
	[CREATE_TASK_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_TASK_SUCCESS]: (state, data) => {
		let { task } = data
		state.tasks = addNewFilter(state.tasks, [task])
		state.loading = false
	},
	[CREATE_TASK_ERROR]: state => {
		state.loading = false
	},
	[EDIT_TASK_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_TASK_SUCCESS]: (state, data) => {
		state.tasks = state.tasks.map(t => t.uuid == data.task.uuid ? data.task : t)
		state.loading = false
	},
	[EDIT_TASK_ERROR]: state => {
		state.loading = false
	},
	[DELETE_TASK_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_TASK_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_TASK_ERROR]: (state, data) => {
		state.loading = false
	},

	RESET_TASK_STATE: state => {
		Object.assign(state, taskDefaultState());
	}
}

export const task = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}