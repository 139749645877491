import {
    GET_COACH_EEOS_REQUEST,
    GET_COACH_EEOS_SUCCESS,
    GET_COACH_EEOS_ERROR,
    GET_COACH_EEO_REQUEST,
    GET_COACH_EEO_SUCCESS,
    GET_COACH_EEO_ERROR,
    CREATE_COACH_EEO_REQUEST,
    CREATE_COACH_EEO_SUCCESS,
    CREATE_COACH_EEO_ERROR,
    EDIT_COACH_EEO_REQUEST,
    EDIT_COACH_EEO_SUCCESS,
    EDIT_COACH_EEO_ERROR,
    DELETE_COACH_EEO_REQUEST,
    DELETE_COACH_EEO_SUCCESS,
    DELETE_COACH_EEO_ERROR,
    CLEAR_COACH_EEO_STATE
} from "@/actions"

import {
    coachEEOService
} from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

const state = {
    coachEEOs: [],
    coachEEO: {},
    loading: false
}

const getters = {
    coachEEO: (state, getters, rootState, rootGetters) => state.coachEEOs.find(eeo => eeo.coaches_uuid == rootGetters['coaches/coach']['uuid'] ),
    eeoGender: (state, getters) => {
        let eeoRecord = getters.coachEEO
        return !isEmpty(eeoRecord) ? eeoRecord.eeo_genders_uuid : ""
    },
    eeoVetStatus: (state, getters) => {
        let eeoRecord = getters.coachEEO
        return !isEmpty(eeoRecord) ? eeoRecord.eeo_veteran_statuses_uuid : ""
    },
    eeoRaceEth: (state, getters) => {
        let eeoRecord = getters.coachEEO
        return !isEmpty(eeoRecord) ? eeoRecord.eeo_race_ethnicities_uuid : ""
    }
}

const actions = {
    async getCoachEEOs({ commit }, params) {
        commit("GET_COACH_EEOS_REQUEST")
        const response = await coachEEOService.getCoachEEOs(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_EEOS_SUCCESS", data)
                break
            default:
                commit("GET_COACH_EEOS_ERROR", data)
        }
        return response
    },
    async getCoachEEO({ commit }, params) {
        commit("GET_COACH_EEO_REQUEST")
        const response = await coachEEOService.getCoachEEO(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("GET_COACH_EEO_SUCCESS", data) : commit("GET_COACH_EEO_ERROR", data)
                break
            default:
                commit("GET_COACH_EEO_ERROR", data)
                break
        }
        return response
    },
    async createCoachEEO({ commit }, params) {
        commit("CREATE_COACH_EEO_REQUEST")
        const response = await coachEEOService.createCoachEEO(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_COACH_EEO_SUCCESS", data) : commit("CREATE_COACH_EEO_ERROR", data)
                break
            default:
                commit("CREATE_COACH_EEO_ERROR", data)
        }
        return response
    },
    async editCoachEEO({ commit }, params) {
        commit("EDIT_COACH_EEO_REQUEST")
        const uuid = params.uuid
        const response = await coachEEOService.editCoachEEO(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("EDIT_COACH_EEO_SUCCESS", data) : commit("EDIT_COACH_EEO_ERROR", data)
                break
            default:
                commit("EDIT_COACH_EEO_ERROR", data)
        }
        return response
    },
    async deleteCoachEEO({ commit }, params) {
        commit("DELETE_COACH_EEO_REQUEST")
        const response = await coachEEOService.deleteCoachEEO(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COACH_EEO_SUCCESS", data)
                break
            default:
                commit("DELETE_COACH_EEO_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_EEOS_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_EEOS_SUCCESS]: (state, data) => {
        let coachEEOs = data.coach_eeos
        state.coachEEOs = addNewFilter(state.coachEEOs, coachEEOs)
        state.loading = false
    },
    [GET_COACH_EEOS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COACH_EEO_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_EEO_SUCCESS]: (state, data) => {
        let coachEEO = { ...data.coach_eeo }
        state.coachEEO = state.coachEEO.concat(coachEEO)
        state.loading = false
    },
    [GET_COACH_EEO_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_EEO_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_EEO_SUCCESS]: (state, data) => {
        state.coachEEOs.push({ ...data.coach_eeo })
        state.loading = false
    },
    [CREATE_COACH_EEO_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_EEO_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_EEO_SUCCESS]: (state, data) => {
        state.coachEEOs = state.coachEEOs.filter(eeco => eeco.coach_uuid != data.coach_eeo.coach_uuid)
        state.coachEEOs.push(data.coach_eeo)
        state.loading = false
    },
    [EDIT_COACH_EEO_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_EEO_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_EEO_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_COACH_EEO_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COACH_EEO_STATE]: (state) => {
        state.coachEEOs = []
        state.coachEEO = {}
        state.loading = false
        state.loadedOnce = false
    }
}

export const coachEEO = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}