import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const combinedWASGroupItemService = {
  async getCombinedGroupItems() {
    const getCombinedGroupItemsRequest = {
      method: "GET"
    }
    return await apiRequest("/api/combined-was-group-items", getCombinedGroupItemsRequest)
  },
  async getCombinedGroupItem(uuid) {
    const getCombinedGroupItemRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/combined-was-group-items/${uuid}`, getCombinedGroupItemRequest)
  },
  async createCombinedGroupItem(params) {
    const createData = processInput(params)
    const createCombinedGroupItemRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/combined-was-group-items", createCombinedGroupItemRequest)
  },
  async editCombinedGroupItem(data, uuid) {
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editCombinedGroupItemRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/combined-was-group-items/${uuid}`, editCombinedGroupItemRequest)
  },
  async deleteCombinedGroupItem(params) {
    const { uuid } = params;
    delete params.uuid
    let deleteData = processQueryParams(params)
    const deleteCombinedGroupItemRequest = {
      method: "DELETE",
      query: deleteData
    }
    return await apiRequest(`/api/combined-was-group-items/${uuid}`, deleteCombinedGroupItemRequest)
  }
}
