import {
    apiRequest,
    processInput
} from "@/utilities"

export const courseLengthsService = {
    async getCourseLengths() {
        const getCourseLengthsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/course-length", getCourseLengthsRequest)
    },
    async createCourseLength(params) {
        const createData = processInput(params);
        const postCourseLengthRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/course-length", postCourseLengthRequest)
    },
    async getCourseLength(uuid) {

        const getCourseLengthRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/course-length/${uuid}`, getCourseLengthRequest);
    },
    async editCourseLength(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateCourseLengthRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/course-length/${uuid}`, updateCourseLengthRequest);
    },
    async deleteCourseLength(uuid) {

        const deleteCourseLengthRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/course-length/${uuid}`, deleteCourseLengthRequest);
    }
}