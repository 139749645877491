import {
	apiRequest,
	processInput,
	processQueryParams
} from "@/utilities"

export const courseStartFacultyService = {
	async getCourseStartFacultys() {
		const getCourseStartFacultysRequest = {
			method: "GET"
		}
		return await apiRequest("/api/course-start-faculty", getCourseStartFacultysRequest)
	},
	async createCourseStartFaculty(params) {
		const createData = processInput(params);
		const postCourseStartFacultyRequest = {
			method: "POST",
			data: createData
		}
		return await apiRequest("/api/course-start-faculty", postCourseStartFacultyRequest)
	},
	async getCourseStartFaculty(uuid) {
		const getCourseStartFacultyRequest = {
			method: "GET"
		}
		return await apiRequest(`/api/course-start-faculty/${uuid}`, getCourseStartFacultyRequest);
	},
	async editCourseStartFaculty(data, uuid) {
		let editData = processInput(data)
		editData.append("_method", "PATCH");
		const updateCourseStartFacultyRequest = {
			method: "POST",
			data: editData
		}

		return await apiRequest(`/api/course-start-faculty/${uuid}`, updateCourseStartFacultyRequest);
	},
	async deleteCourseStartFaculty(uuid) {
		const deleteCourseStartFacultyRequest = {
			method: "DELETE"
		}
		return await apiRequest(`/api/course-start-faculty/${uuid}`, deleteCourseStartFacultyRequest);
	},

	async sendEvaluationLink(params) {
		const { uuid } = params;
		delete params.uuid;
		const sendEvaluationLinkRequest = {
			method: "GET",
			params: processQueryParams(params)
		}
		return await apiRequest(`/api/course-start-faculty/${uuid}/send-evaluation-link`, sendEvaluationLinkRequest)
	}
}