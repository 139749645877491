import {
    apiRequest,
    processInput,
    addNewFilter,
    processQueryParams,
    isEmpty
} from "@/utilities"

const state = {
    loading: false,
    userSettings: [],
}

const getters = {
    userSettings: state => state.userSettings,
    userSettingByUserUuid: state => userUuid => state.userSettings.find(us => us.user_uuid == userUuid)
}

const actions = {
    async editUserSetting({ commit }, params) {
        commit("EDIT_USER_SETTING_REQUEST")
        let editData = processInput(params)
        editData.append("_method", "PATCH");
        const updateRequest = {
            method: "POST",
            data: editData
        }
        let uuid = params.uuid
        const response = await apiRequest(`/api/user-settings/${uuid}`, updateRequest);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_USER_SETTING_SUCCESS", data)
                break
            default:
                commit("EDIT_USER_SETTING_ERROR", data)
        }
        return response
    },
}

const mutations = {
    EDIT_USER_SETTING_REQUEST: state => {
        state.loading = true
    },
    GET_USER_SETTINGS_SUCCESS: (state, data) => {
        const user_setting = data.user_setting
        state.userSettings = addNewFilter(state.userSettings, [user_setting])
        state.loading = false
    },
    CREATE_USER_SETTING_SUCCESS: (state, data) => {
        const user_setting = data.user_setting
        state.userSettings = addNewFilter(state.userSettings, [user_setting])
        state.loading = false
    },
    EDIT_USER_SETTING_SUCCESS: (state, data) => {
        const user_setting = data.user_setting
        state.userSettings = addNewFilter(state.userSettings, [user_setting])
        state.loading = false
    },
    EDIT_USER_SETTING_ERROR: (state, data) => {
        state.loading = false
    },
}

export const userSetting = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
