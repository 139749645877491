import {
    apiRequest,
    processInput
} from "@/utilities"

export const phoneTypeService = {
    async getPhoneTypes() {
        const getPhoneTypesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/phone-types", getPhoneTypesRequest)
    },
    async getPhoneType(uuid) {
        const getPhoneTypeRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/phone-types/${uuid}`, getPhoneTypeRequest)
    },
    async createPhoneType(params) {
        const createData = processInput(params)
        const createPhoneTypeRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/phone-types", createPhoneTypeRequest)
    },
    async editPhoneType(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editPhoneTypeRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/phone-types/${uuid}`, editPhoneTypeRequest)
    },
    async deletePhoneType(uuid) {
        const deletePhoneTypeRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/phone-types/${uuid}`, deletePhoneTypeRequest)
    }
}