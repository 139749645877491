import {
    GET_LANGUAGE_TYPES_REQUEST,
    GET_LANGUAGE_TYPES_SUCCESS,
    GET_LANGUAGE_TYPES_ERROR,
    GET_LANGUAGE_TYPE_REQUEST,
    GET_LANGUAGE_TYPE_SUCCESS,
    GET_LANGUAGE_TYPE_ERROR,
    CREATE_LANGUAGE_TYPE_REQUEST,
    CREATE_LANGUAGE_TYPE_SUCCESS,
    CREATE_LANGUAGE_TYPE_ERROR,
    EDIT_LANGUAGE_TYPE_REQUEST,
    EDIT_LANGUAGE_TYPE_SUCCESS,
    EDIT_LANGUAGE_TYPE_ERROR,
    DELETE_LANGUAGE_TYPE_REQUEST,
    DELETE_LANGUAGE_TYPE_SUCCESS,
    DELETE_LANGUAGE_TYPE_ERROR
} from "../../actions"

import {
    languageTypeService
} from "../../services"

const state = {
    languageTypes: [],
    languageType: {},
    loading: false
}

const getters = {}

const actions = {
    async getLanguageTypes({ commit }) {
        commit("GET_LANGUAGE_TYPES_REQUEST")
        const response = await languageTypeService.getLanguageTypes();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_LANGUAGE_TYPES_SUCCESS", data)
                break
            default:
                commit("GET_LANGUAGE_TYPES_ERROR", data)
        }
        return response
    },
    async getLanguageType({ commit }, params) {
        commit("GET_LANGUAGE_TYPE_REQUEST")
        const response = await languageTypeService.getLanguageType(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_LANGUAGE_TYPE_SUCCESS", data)
                break
            default:
                commit("GET_LANGUAGE_TYPE_ERROR", data)
                break
        }
        return response
    },
    async createLanguageType({ commit }, params) {
        commit("CREATE_LANGUAGE_TYPE_REQUEST")
        const response = await languageTypeService.createLanguageType(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_LANGUAGE_TYPE_SUCCESS", data)
                break
            default:
                commit("CREATE_LANGUAGE_TYPE_ERROR", data)
        }
        return response
    },
    async editLanguageType({ commit }, params, uuid) {
        commit("EDIT_LANGUAGE_TYPE_REQUEST")
        const response = await languageTypeService.editLanguageType(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_LANGUAGE_TYPE_SUCCESS", data)
                break
            default:
                commit("EDIT_LANGUAGE_TYPE_ERROR", data)
        }
        return response
    },
    async deleteLanguageType({ commit }, params) {
        commit("DELETE_LANGUAGE_TYPE_REQUEST")
        const response = await languageTypeService.deleteLanguageType(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_LANGUAGE_TYPE_SUCCESS", data)
                break
            default:
                commit("DELETE_LANGUAGE_TYPE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_LANGUAGE_TYPES_REQUEST]: state => {
        state.loading = true
    },
    [GET_LANGUAGE_TYPES_SUCCESS]: (state, data) => {
        const languageTypes = [...data.languageTypes]
        state.languageTypes = languageTypes
        state.loading = false
    },
    [GET_LANGUAGE_TYPES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_LANGUAGE_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [GET_LANGUAGE_TYPE_SUCCESS]: (state, data) => {
        const languageType = { ...data.languageType }
        state.languageType = languageType
        state.loading = false
    },
    [GET_LANGUAGE_TYPE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_LANGUAGE_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_LANGUAGE_TYPE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [CREATE_LANGUAGE_TYPE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_LANGUAGE_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_LANGUAGE_TYPE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_LANGUAGE_TYPE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_LANGUAGE_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_LANGUAGE_TYPE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_LANGUAGE_TYPE_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const languageType = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}