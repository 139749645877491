export const GET_REGIONAL_ACCREDITORS_REQUEST = "GET_REGIONAL_ACCREDITORS_REQUEST"
export const GET_REGIONAL_ACCREDITORS_SUCCESS = "GET_REGIONAL_ACCREDITORS_SUCCESS"
export const GET_REGIONAL_ACCREDITORS_ERROR = "GET_REGIONAL_ACCREDITORS_ERROR"
export const GET_REGIONAL_ACCREDITOR_REQUEST = "GET_REGIONAL_ACCREDITOR_REQUEST"
export const GET_REGIONAL_ACCREDITOR_SUCCESS = "GET_REGIONAL_ACCREDITOR_SUCCESS"
export const GET_REGIONAL_ACCREDITOR_ERROR = "GET_REGIONAL_ACCREDITOR_ERROR" 
export const CREATE_REGIONAL_ACCREDITOR_REQUEST = "CREATE_REGIONAL_ACCREDITOR_REQUEST"
export const CREATE_REGIONAL_ACCREDITOR_SUCCESS = "CREATE_REGIONAL_ACCREDITOR_SUCCESS"
export const CREATE_REGIONAL_ACCREDITOR_ERROR = "CREATE_REGIONAL_ACCREDITOR_ERROR"
export const EDIT_REGIONAL_ACCREDITOR_REQUEST = "EDIT_REGIONAL_ACCREDITOR_REQUEST"
export const EDIT_REGIONAL_ACCREDITOR_SUCCESS = "EDIT_REGIONAL_ACCREDITOR_SUCCESS"
export const EDIT_REGIONAL_ACCREDITOR_ERROR = "EDIT_REGIONAL_ACCREDITOR_ERROR"
export const DELETE_REGIONAL_ACCREDITOR_REQUEST = "DELETE_REGIONAL_ACCREDITOR_REQUEST"
export const DELETE_REGIONAL_ACCREDITOR_SUCCESS = "DELETE_REGIONAL_ACCREDITOR_SUCCESS"
export const DELETE_REGIONAL_ACCREDITOR_ERROR = "DELETE_REGIONAL_ACCREDITOR_ERROR"