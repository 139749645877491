import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const wasReconciliationService = {
    async getWasReconciliations(params) {
        let getParams = processQueryParams(params)
        const getWasReconciliationsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/was-reconciliations", getWasReconciliationsRequest)
    },
    async getWasReconciliation(uuid) {
        const getWasReconciliationRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/was-reconciliations/${uuid}`, getWasReconciliationRequest)
    },
    async createWasReconciliation(params) {
        const createData = processInput(params)
        const createWasReconciliationRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/was-reconciliations", createWasReconciliationRequest)
    },
    async editWasReconciliation(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editWasReconciliationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/was-reconciliations/${uuid}`, editWasReconciliationRequest)
    },
    async deleteWasReconciliation(uuid) {
        const deleteWasReconciliationRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/was-reconciliations/${uuid}`, deleteWasReconciliationRequest)
    }
}