export const GET_LCPAAS_REQUEST = "GET_LCPAAS_REQUEST"
export const GET_LCPAAS_SUCCESS = "GET_LCPAAS_SUCCESS"
export const GET_LCPAAS_ERROR = "GET_LCPAAS_ERROR"
export const GET_LCPAA_REQUEST = "GET_LCPAA_REQUEST"
export const GET_LCPAA_SUCCESS = "GET_LCPAA_SUCCESS"
export const GET_LCPAA_ERROR = "GET_LCPAA_ERROR"
export const CREATE_LCPAA_REQUEST = "CREATE_LCPAA_REQUEST"
export const CREATE_LCPAA_SUCCESS = "CREATE_LCPAA_SUCCESS"
export const CREATE_LCPAA_ERROR = "CREATE_LCPAA_ERROR"
export const EDIT_LCPAA_REQUEST = "EDIT_LCPAA_REQUEST"
export const EDIT_LCPAA_SUCCESS = "EDIT_LCPAA_SUCCESS"
export const EDIT_LCPAA_ERROR = "EDIT_LCPAA_ERROR"
export const DELETE_LCPAA_REQUEST = "DELETE_LCPAA_REQUEST"
export const DELETE_LCPAA_SUCCESS = "DELETE_LCPAA_SUCCESS"
export const DELETE_LCPAA_ERROR = "DELETE_LCPAA_ERROR"
export const BROADCAST_DELETE_LCPAA_SUCCESS = "BROADCAST_DELETE_LCPAA_SUCCESS"