import {
    GET_UNIVERSITY_REGIONAL_ACCREDITORS_REQUEST,
    GET_UNIVERSITY_REGIONAL_ACCREDITORS_SUCCESS,
    GET_UNIVERSITY_REGIONAL_ACCREDITORS_ERROR,
    GET_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST,
    GET_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS,
    GET_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR,
    CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST,
    CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS,
    CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR,
    EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST,
    EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS,
    EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR,
    DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST,
    DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS,
    DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR
} from "@/actions"

import {
    universityRegionalAccreditorsService
} from "@/services"


import { addNewFilter } from "@/utilities"


const state = {
    universityRegionalAccreditors: [],
    universityRegionalAccreditor: {},
    loading: false
}

const getters = {
    universityRAFilterByUniversity: state => uuid => state.universityRegionalAccreditors.filter(upm => upm.university.uuid == uuid),
    universityRAFilterByUniversityUuid: state => uuid => state.universityRegionalAccreditors.filter(upm => upm.university.uuid == uuid).map(uo => uo.regional_accreditor.uuid),
    universityRAFilterByUniversityLabel: state => uuid => state.universityRegionalAccreditors.filter(upm => upm.university.uuid == uuid).map(uo => uo.regional_accreditor.slug),
    universityRAFilter: state => uuid => state.universityRegionalAccreditors.filter(upm => uuid.includes(upm.uuid))
}

const actions = {
    async getUniversityRegionalAccreditors({ commit }) {
        commit("GET_UNIVERSITY_REGIONAL_ACCREDITORS_REQUEST")
        const response = await universityRegionalAccreditorsService.getUniversityRegionalAccreditors();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_REGIONAL_ACCREDITORS_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_REGIONAL_ACCREDITORS_ERROR", data)
        }
        return response
    },
    async getUniversityRegionalAccreditor({ commit }, params) {
        commit("GET_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST")
        const response = await universityRegionalAccreditorsService.getUniversityRegionalAccreditor(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR", data)
                break
        }
        return response
    },
    async createUniversityRegionalAccreditor({ commit }, params) {
        commit("CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST")
        const response = await universityRegionalAccreditorsService.createUniversityRegionalAccreditor(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS", data)
                break
            default:
                commit("CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR", data)
        }
        return response
    },
    async editUniversityRegionalAccreditor({ commit }, params) {
        commit("EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST")
        const response = await universityRegionalAccreditorsService.editUniversityRegionalAccreditor(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS", data)
                break
            default:
                commit("EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR", data)
        }
        return response
    },
    async deleteUniversityRegionalAccreditor({ commit }, params) {
        commit("DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST")
        const response = await universityRegionalAccreditorsService.deleteUniversityRegionalAccreditor(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS", data)
                break
            default:
                commit("DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_UNIVERSITY_REGIONAL_ACCREDITORS_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_REGIONAL_ACCREDITORS_SUCCESS]: (state, data) => {
        let mdata = [...data.data]
        state.universityRegionalAccreditors = addNewFilter(state.universityRegionalAccreditors, mdata)
        state.loading = false
    },
    [GET_UNIVERSITY_REGIONAL_ACCREDITORS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS]: (state, data) => {
        let mdata = [...data.data]
        state.universityRegionalAccreditor = addNewFilter(state.universityRegionalAccreditor, mdata)
        state.loading = false

    },
    [GET_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS]: (state, data) => {
        state.universityRegionalAccreditors.push(data.data)
        state.loading = false
    },
    [CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR]: state => {
        state.loading = false
    },

    [EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR]: state => {
        state.loading = false
    },

    [DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS]: (state, data) => {
        let deletedUuid = data.success
        state.universityRegionalAccreditors = state.universityRegionalAccreditors.filter(d => d.uuid != deletedUuid)
        state.loading = false
    },
    [DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const universityRegionalAccreditor = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}