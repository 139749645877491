import {
    apiRequest,
    processInput
} from "@/utilities"

export const documentTypeService = {
    async getDocumentTypes() {
        const getDocumentTypesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/document-types", getDocumentTypesRequest)
    },
    async getDocumentType(uuid) {
        const getDocumentTypeRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/document-types/${uuid}`, getDocumentTypeRequest)
    },
    async createDocumentType(params) {
        const createData = processInput(params)
        const createDocumentTypeRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/document-types", createDocumentTypeRequest)
    },
    async editDocumentType(params) {
        let editData = processInput(params)
        editData.append('_METHOD', 'PATCH')
        const editDocumentTypeRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/document-types/${params.uuid}`, editDocumentTypeRequest)
    },
    async deleteDocumentType(uuid) {
        const deleteDocumentTypeRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/document-types/${uuid}`, deleteDocumentTypeRequest)
    }
}