import {
	GET_UNIVERSITY_PROCEDURES_REQUEST,
	GET_UNIVERSITY_PROCEDURES_SUCCESS,
	GET_UNIVERSITY_PROCEDURES_ERROR,
	GET_UNIVERSITY_PROCEDURE_REQUEST,
	GET_UNIVERSITY_PROCEDURE_SUCCESS,
	GET_UNIVERSITY_PROCEDURE_ERROR,
	CREATE_UNIVERSITY_PROCEDURE_REQUEST,
	CREATE_UNIVERSITY_PROCEDURE_SUCCESS,
	CREATE_UNIVERSITY_PROCEDURE_ERROR,
	EDIT_UNIVERSITY_PROCEDURE_REQUEST,
	EDIT_UNIVERSITY_PROCEDURE_SUCCESS,
	EDIT_UNIVERSITY_PROCEDURE_ERROR,
	DELETE_UNIVERSITY_PROCEDURE_REQUEST,
	DELETE_UNIVERSITY_PROCEDURE_SUCCESS,
	DELETE_UNIVERSITY_PROCEDURE_ERROR,
	UNIVERSITY_PROCEDURE_PROGRESS_CHECKED
} from "@/actions";

import {
	universityProceduresService
} from "@/services";

import { addNewFilter, isEmpty } from "@/utilities";

const state = {
	universityProcedures: [],
	universityProcedure: {},
	loading: false,
	loadedOnce: false,
	progressChecked: false,
	partnershipMenu: [
		{
			order: 1,
			disabled: false,
			slug: "link",
			name: "Account Details",
			href: "account-details",
			progressSlug: "up-account-details"
		},
		{
			order: 2,
			disabled: false,
			slug: "link",
			name: "LMS Access",
			href: "lms-access",
			progressSlug: "up-lms-access"
		},
		{
			order: 3,
			disabled: false,
			slug: "link",
			name: "Coach/Couse Procedure",
			href: "coach-course-procedure",
			progressSlug: "up-coach-course-procedure"
		},
		{
			order: 4,
			disabled: false,
			slug: "link",
			name: "Additional POC",
			href: "additional-poc",
			progressSlug: "up-additional-poc"
		},
	]
}

const getters = {
	proceduresLoadedOnce: state => state.loadedOnce,
	progressChecked: state => state.progressChecked = false,
	universityPartnershipMenu: (state, getters, rootState, rootGetters) => {
		let p = state.universityProcedures.find(up => up.university_uuid == rootGetters['university/university']['uuid'])
		if (!isEmpty(p) && !isEmpty(p.form_submitted)) {
			return state.partnershipMenu.map(item => {
				item.disabled = true
				return item
			})
		}
		return state.partnershipMenu
	},
	upLoading: state => state.loading,
	// procedure: state => state.universityProcedure,
	universityProcedure: state => univUuid => state.universityProcedures.find(up => up.university_uuid == univUuid),
	allUniversityProcedures: state => state.universityProcedures,
	universityProcedureFilter: state => uuid => state.universityProcedures.find(upm => upm.university_uuid == uuid),
}

const actions = {
	async getUniversityProcedures({ commit }) {
		commit("GET_UNIVERSITY_PROCEDURES_REQUEST")
		const response = await universityProceduresService.getUniversityProcedures();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_UNIVERSITY_PROCEDURES_SUCCESS", data)
				break
			default:
				commit("GET_UNIVERSITY_PROCEDURES_ERROR", data)
		}
		return response
	},

	async getUniversityProcedure({ commit }, params) {
		commit("GET_UNIVERSITY_PROCEDURE_REQUEST")
		const response = await universityProceduresService.getUniversityProcedure(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_UNIVERSITY_PROCEDURE_SUCCESS", data)
				break
			default:
				commit("GET_UNIVERSITY_PROCEDURE_ERROR", data)
				break
		}
		return response
	},
	async createUniversityProcedure({ commit }, params) {
		commit("CREATE_UNIVERSITY_PROCEDURE_REQUEST")
		const response = await universityProceduresService.createUniversityProcedure(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_UNIVERSITY_PROCEDURE_SUCCESS", data)
				break
			default:
				commit("CREATE_UNIVERSITY_PROCEDURE_ERROR", data)
		}
		return response
	},
	async editUniversityProcedure({ commit }, params) {
		commit("EDIT_UNIVERSITY_PROCEDURE_REQUEST")
		const response = await universityProceduresService.editUniversityProcedure(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_UNIVERSITY_PROCEDURE_SUCCESS", data) : commit("EDIT_UNIVERSITY_PROCEDURE_ERROR", data)
				break
			default:
				commit("EDIT_UNIVERSITY_PROCEDURE_ERROR", data)
		}
		return response
	},
	async deleteUniversityProcedure({ commit }, params) {
		commit("DELETE_UNIVERSITY_PROCEDURE_REQUEST")
		const response = await universityProceduresService.deleteUniversityProcedure(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_UNIVERSITY_PROCEDURE_SUCCESS", data)
				break
			default:
				commit("DELETE_UNIVERSITY_PROCEDURE_DELETE", data)
		}
		return response
	},
	async sendPartnershipForm({ commit, dispatch }, params) {
		commit("SEND_PARTNERSHIP_FORM_REQUEST")
		const response = await universityProceduresService.sendPartnershipForm(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data?.success) {
					commit("SEND_PARTNERSHIP_FORM_SUCCESS")
					dispatch("alert/createAlertSuccess", "Partnership form sent.", { root: true })
				}
				
				if (data?.error) {
					commit("SEND_PARTNERSHIP_FORM_ERROR")
					dispatch('alert/createAlertErrors', !isEmpty(data.errors) ? data.errors : "Error sending form.", { root: true })
				}
				
				break
			default:
				commit("SEND_PARTNERSHIP_FORM_ERROR")
		}
		return response
	},
	updateProgressChecked({ commit }) {
		commit("UNIVERSITY_PROCEDURE_PROGRESS_CHECKED")
	}
}

const mutations = {
	[GET_UNIVERSITY_PROCEDURES_REQUEST]: state => {
		state.loading = true
	},
	[GET_UNIVERSITY_PROCEDURES_SUCCESS]: (state, data) => {
		let mdata = [...data.university_procedure]
		state.universityProcedures = addNewFilter(state.universityProcedures, mdata);
		state.loading = false
		state.loadedOnce = true
	},
	[GET_UNIVERSITY_PROCEDURES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_UNIVERSITY_PROCEDURE_REQUEST]: state => {
		state.loading = true
	},
	[GET_UNIVERSITY_PROCEDURE_SUCCESS]: (state, data) => {
		const { university_procedure } = data;
		state.universityProcedures = addNewFilter(state.universityProcedures, [university_procedure]);
		state.loading = false
		state.loadedOnce = true
	},
	[GET_UNIVERSITY_PROCEDURE_ERROR]: (state, data) => {
		state.loading = false
		state.loadedOnce = true
	},

	[CREATE_UNIVERSITY_PROCEDURE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_UNIVERSITY_PROCEDURE_SUCCESS]: (state, data) => {
		const { university_procedure } = data;
		state.universityProcedures = addNewFilter(state.universityProcedures, [university_procedure]);
		state.loading = false
	},
	[CREATE_UNIVERSITY_PROCEDURE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_UNIVERSITY_PROCEDURE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_UNIVERSITY_PROCEDURE_SUCCESS]: (state, data) => {
		const { university_procedure } = data;
		state.universityProcedures = addNewFilter(state.universityProcedures, [university_procedure]);
		state.loading = false
	},
	[EDIT_UNIVERSITY_PROCEDURE_ERROR]: (state, data) => {
		state.loading = false
	},

	[DELETE_UNIVERSITY_PROCEDURE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_UNIVERSITY_PROCEDURE_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_UNIVERSITY_PROCEDURE_ERROR]: (state, data) => {
		state.loading = false
	},
	[UNIVERSITY_PROCEDURE_PROGRESS_CHECKED]: (state) => {
		state.progressChecked = true
	},
	
	SEND_PARTNERSHIP_FORM_REQUEST: (state) => {
		state.loading = true;
	},
	SEND_PARTNERSHIP_FORM_SUCCESS: (state) => {
		state.loading = false;
	},
	SEND_PARTNERSHIP_FORM_ERROR: (state) => {
		state.loading = false;
	}
}

export const universityProcedure = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}