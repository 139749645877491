import {
  GET_AREA_OF_QUALIFICATIONS_REQUEST,
  GET_AREA_OF_QUALIFICATIONS_SUCCESS,
  GET_AREA_OF_QUALIFICATIONS_ERROR,
  GET_AREA_OF_QUALIFICATION_REQUEST,
  GET_AREA_OF_QUALIFICATION_SUCCESS,
  GET_AREA_OF_QUALIFICATION_ERROR,
  CREATE_AREA_OF_QUALIFICATION_REQUEST,
  CREATE_AREA_OF_QUALIFICATION_SUCCESS,
  CREATE_AREA_OF_QUALIFICATION_ERROR,
  EDIT_AREA_OF_QUALIFICATION_REQUEST,
  EDIT_AREA_OF_QUALIFICATION_SUCCESS,
  EDIT_AREA_OF_QUALIFICATION_ERROR,
  DELETE_AREA_OF_QUALIFICATION_REQUEST,
  DELETE_AREA_OF_QUALIFICATION_SUCCESS,
  DELETE_AREA_OF_QUALIFICATION_ERROR
} from "@/actions"

import {
  areaOfQualificationService
} from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

const state = {
  areaOfQualifications: [],
  areaOfQualification: {},
  loading: false
}

const getters = {
  areaOfQualifications: state => state.areaOfQualifications.sort((a, b) => a.label.localeCompare(b.label)),
  aoqSelectOptions: state => state.areaOfQualifications.map(aoq => ({
    value: aoq.uuid,
    label: aoq.label,
    attrs: {
      'data-abbreviation': aoq.abbreviation
    }
  })).sort((a, b) => a.label.localeCompare(b.label)),
  aoqMultiSelectOptions: state => state.areaOfQualifications.map(aoq => ({ value: aoq.uuid, text: aoq.label, abbr: aoq.abbreviation })),
  aoqByUuid: state => uuid => state.areaOfQualifications.find(aoq => aoq.uuid == uuid),
  aoqByUuidArray: state => uuidArray => state.areaOfQualifications.filter(aoq => uuidArray.includes(aoq.uuid)),
  aoqSpecialties: state => {
    let specialties = [];
    state.areaOfQualifications.forEach((aoq, index) => {
      aoq.specialties.forEach((specialty, index) => {
        specialties.push(specialty)
      })
    })

    return specialties
  },
  aoqOptionFilter: state => uuid => state.areaOfQualifications.find(aoq => {
    if (aoq.uuid == uuid) return { value: aoq.uuid, label: aoq.label }
  }),
  aoqSpecFilter: state => uuid => {
    let exportObject = {}
    for (let x = 0; x <= state.areaOfQualifications.length; x++) {
      if (isEmpty(exportObject)) {
        state.areaOfQualifications[x]['specialties'].forEach(spec => {
          if (spec.uuid == uuid) {
            exportObject = spec
            return false
          }
        })
      } else {
        break
      }
    }

    return exportObject
  }
}

const actions = {
  async getAreaOfQualifications({ commit }) {
    commit("GET_AREA_OF_QUALIFICATIONS_REQUEST")
    const response = await areaOfQualificationService.getAreaOfQualifications();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_AREA_OF_QUALIFICATIONS_SUCCESS", data)
        break
      default:
        commit("GET_AREA_OF_QUALIFICATIONS_ERROR", data)
    }
    return response
  },
  async getAreaOfQualification({ commit }, params) {
    commit("GET_AREA_OF_QUALIFICATION_REQUEST")
    const response = await areaOfQualificationService.getAreaOfQualification(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_AREA_OF_QUALIFICATION_SUCCESS", data)
        break
      default:
        commit("GET_AREA_OF_QUALIFICATION_ERROR", data)
        break
    }
    return response
  },
  async createAreaOfQualification({ commit, dispatch }, params) {
    commit("CREATE_AREA_OF_QUALIFICATION_REQUEST")
    const response = await areaOfQualificationService.createAreaOfQualification(params)
    const { status, data } = response
    switch (status) {
      case 200:
        if (data?.success) {
          data?.success ? commit("CREATE_AREA_OF_QUALIFICATION_SUCCESS", data) : null;
          data.success ? dispatch('alert/createAlertSuccess', "Area of qualification created.", { root: true }) : null;

        }

        data?.error ? dispatch('alert/createAlertErrors', !isEmpty(data) ? decodeLaravelValidationErrors(data.errors) : "Oops. Something went wrong.", { root: true }) : null;

        break
      default:
        commit("CREATE_AREA_OF_QUALIFICATION_ERROR", data)
    }
    return response
  },
  async editAreaOfQualification({ commit }, params, uuid) {
    commit("EDIT_AREA_OF_QUALIFICATION_REQUEST")
    const response = await areaOfQualificationService.editAreaOfQualification(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_AREA_OF_QUALIFICATION_SUCCESS", data)
        break
      default:
        commit("EDIT_AREA_OF_QUALIFICATION_ERROR", data)
    }
    return response
  },
  async deleteAreaOfQualification({ commit }, params) {
    commit("DELETE_AREA_OF_QUALIFICATION_REQUEST")
    const response = await areaOfQualificationService.deleteAreaOfQualification(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_AREA_OF_QUALIFICATION_SUCCESS", data)
        break
      default:
        commit("DELETE_AREA_OF_QUALIFICATION_DELETE", data)
    }
    return response
  }
}

const mutations = {
  [GET_AREA_OF_QUALIFICATIONS_REQUEST]: state => {
    state.loading = true
  },
  [GET_AREA_OF_QUALIFICATIONS_SUCCESS]: (state, data) => {
    const areaOfQualifications = [...data.area_of_qualifications]
    state.areaOfQualifications = areaOfQualifications
    state.loading = false
  },
  [GET_AREA_OF_QUALIFICATIONS_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_AREA_OF_QUALIFICATION_REQUEST]: state => {
    state.loading = true
  },
  [GET_AREA_OF_QUALIFICATION_SUCCESS]: (state, data) => {
    const areaOfQualification = { ...data.area_of_qualification }
    state.areaOfQualification = areaOfQualification
    state.loading = false
  },
  [GET_AREA_OF_QUALIFICATION_ERROR]: (state, data) => {
    state.loading = false
  },
  [CREATE_AREA_OF_QUALIFICATION_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_AREA_OF_QUALIFICATION_SUCCESS]: (state, data) => {
    const { area_of_qualification } = data;
    state.areaOfQualifications = addNewFilter(state.areaOfQualifications, [area_of_qualification])
    state.loading = false;
  },
  [CREATE_AREA_OF_QUALIFICATION_ERROR]: state => {
    state.loading = false
  },
  [EDIT_AREA_OF_QUALIFICATION_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_AREA_OF_QUALIFICATION_SUCCESS]: state => {
    state.loading = false
  },
  [EDIT_AREA_OF_QUALIFICATION_ERROR]: state => {
    state.loading = false
  },
  [DELETE_AREA_OF_QUALIFICATION_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_AREA_OF_QUALIFICATION_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [DELETE_AREA_OF_QUALIFICATION_ERROR]: (state, data) => {
    state.loading = false
  }
}

export const areaOfQualification = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
