import {
	settingsService
} from "@/services"

const state = {
  loading: false
}

const getters = {

}

const actions = {
	async masquerade({ commit }, params) {
		commit("MASQUERADE_REQUEST");

		const response = await settingsService.masquerade(params);
		const { status, data } = response;
		
		switch (status) {
      case 200:
        commit("MASQUERADE_SUCCESS");
        commit("task/RESET_TASK_STATE", null, { root: true });
        commit("notification/RESET_NOTIFICATION_STATE", null, { root: true });
        commit("auth/RESET_AUTH_STATE", null, { root: true });
        commit("auth/AUTH_USER_SUCCESS", data, { root: true });
				break
			default:
				commit("MASQUERADE_ERROR")
		}

		return response;
	},
  async masqueradeLeave({ commit }, params) {
		commit("MASQUERADE_LEAVE_REQUEST");
		
		const response = await settingsService.masqueradeLeave(params)
		const { status, data } = response;
		
		switch (status) {
			case 200:
        commit("MASQUERADE_LEAVE_SUCCESS", data);
        commit("task/RESET_TASK_STATE", null, { root: true });
        commit("notification/RESET_NOTIFICATION_STATE", null, { root: true });
        commit("auth/RESET_AUTH_STATE", null, { root: true });
        commit("auth/AUTH_USER_SUCCESS", data, { root: true });
				break
			default:
				commit("MASQUERADE_LEAVE_ERROR")
				break
		}

		return response;
	},
	
}

const mutations = {
	MASQUERADE_REQUEST: state => {
    state.loading = true;
	},
	MASQUERADE_SUCCESS: state => {
    state.loading = false;
	},
	MASQUERADE_ERROR: state => {
    state.loading = false;
  },
  
	MASQUERADE_LEAVE_REQUEST: state => {
    state.loading = true;
	},
  MASQUERADE_LEAVE_SUCCESS: state => {
    state.loading = false;
  },
  MASQUERADE_LEAVE_ERROR: state => {
    state.loading = false;
  },
}

export const masquerade = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}