import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachSpecialtyService = {
    async getCoachSpecialties(params) {
        let getParams = processQueryParams(params)
        const getCoachSpecialtiesRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-specialties", getCoachSpecialtiesRequest)
    },
    async getCoachSpecialty(uuid) {
        const getCoachSpecialtyRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-specialties/${uuid}`, getCoachSpecialtyRequest)
    },
    async createCoachSpecialty(params) {
        const createData = processInput(params)
        const createCoachSpecialtyRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-specialties", createCoachSpecialtyRequest)
    },
    async editCoachSpecialty(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachSpecialtyRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-specialties/${uuid}`, editCoachSpecialtyRequest)
    },
    async deleteCoachSpecialty(uuid) {
        const deleteCoachSpecialtyRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-specialties/${uuid}`, deleteCoachSpecialtyRequest)
    }
}