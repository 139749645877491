export const universityPortalRoutes = {
    path: "/university-portal",
    name: "upl",
    redirect: {
        name: 'upl-dashboard'
    },
    component: () => import("@/layouts/UniversityPortal.vue"),
    meta: {
        authRequired: true,
        allowedRoles: [
            'university-admin'
        ],
        label: "University Portal",
    },
    children: [
        {
            path: "dashboard",
            name: "upl-dashboard",
            meta: {
                label: "Dashboard",
            },
            component: () => import("@/pages/university/portal/UniversityDashboard.vue"),
        },
        {
            path: "meeting-minutes",
            name: "upl-meeting-minutes",
            meta: {
                label: "Meeting Minutes",
            },
            component: () => import("@/pages/university/portal/UPMeetingMinutes.vue"),
        },
        {
            path: "coaches",
            name: "upl-coaches",
            meta: {
                label: "Coaches",
            },
            component: () => import("@/pages/university/portal/UPCoaches.vue"),
        },
        {
            path: "evaluations/:uuid",
            name: "upl-evaluations",
            meta: {
                label: "Evaluation",
            },
            component: () => import("@/pages/university/portal/UPCoachEvaluations.vue"),
        },

        {
            path: 'users/:uuid',
            name: 'upl-user-profile',
            meta: {
              label: 'User Details'
            },
            component: () => import("@/pages/users/UsersDetail.vue"),
        },
    ]
};
