import {
  GET_FACULTY_SOURCES_REQUEST,
  GET_FACULTY_SOURCES_SUCCESS,
  GET_FACULTY_SOURCES_ERROR,
  GET_FACULTY_SOURCE_REQUEST,
  GET_FACULTY_SOURCE_SUCCESS,
  GET_FACULTY_SOURCE_ERROR,
  CREATE_FACULTY_SOURCE_REQUEST,
  CREATE_FACULTY_SOURCE_SUCCESS,
  CREATE_FACULTY_SOURCE_ERROR,
  EDIT_FACULTY_SOURCE_REQUEST,
  EDIT_FACULTY_SOURCE_SUCCESS,
  EDIT_FACULTY_SOURCE_ERROR,
  DELETE_FACULTY_SOURCE_REQUEST,
  DELETE_FACULTY_SOURCE_SUCCESS,
  BROADCAST_DELETE_FACULTY_SOURCE_SUCCESS,
  DELETE_FACULTY_SOURCE_ERROR,
  SEND_RESUME_FACULTY_SOURCE_REQUEST,
  SEND_RESUME_FACULTY_SOURCE_SUCCESS,
  SEND_RESUME_FACULTY_SOURCE_ERROR,
} from "@/actions"

import {
  unpackCoach
} from "@/modules"

import {
  facultySourcesService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities";

const state = {
  error: null,
  loading: false,
  loadedOnce: false,
  facultySourceLoadedOnce: false,
  facultySources: [],
  facultySource: {}
}

const getters = {
  facultysLoading: state => state.loading,
  facultysLoadedOnce: state => state.loadedOnce,
  facultySources: state => state.facultySources,
  facultySourceByFsrUuid: state => ufsUuid => state.facultySources.filter(i => i.faculty_source_role_uuid == ufsUuid),
  facultySourceByUuid: state => uuid => state.facultySources.find(i => i.uuid == uuid),
}

const actions = {
  async getFacultySources({ commit }) {
    commit("GET_FACULTY_SOURCES_REQUEST")
    const response = await facultySourcesService.getFacultySources();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_FACULTY_SOURCES_SUCCESS", data)
        break
      default:
        commit("GET_FACULTY_SOURCES_ERROR", data)
    }
    return response
  },

  async getFacultySource({ commit }, params) {
    commit("GET_FACULTY_SOURCE_REQUEST")
    const response = await facultySourcesService.getFacultySource(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_FACULTY_SOURCE_SUCCESS", data)
        break
      default:
        commit("GET_FACULTY_SOURCE_ERROR", data)
        break
    }
    return response
  },
  async createFacultySource({ commit, dispatch }, params) {
    commit("CREATE_FACULTY_SOURCE_REQUEST")
    const response = await facultySourcesService.createFacultySource(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_FACULTY_SOURCE_SUCCESS", data) : commit("CREATE_FACULTY_SOURCE_ERROR", data)
        break
      default:
        commit("CREATE_FACULTY_SOURCE_ERROR", data)
    }
    return response
  },
  async editFacultySource({ commit }, params) {
    commit("EDIT_FACULTY_SOURCE_REQUEST")
    const response = await facultySourcesService.editFacultySource(params, params.uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_FACULTY_SOURCE_SUCCESS", data)
        break
      default:
        commit("EDIT_FACULTY_SOURCE_ERROR", data)
    }
    return response
  },
  async deleteFacultySource({ commit }, params) {
    commit("DELETE_FACULTY_SOURCE_REQUEST")
    const uuid = params.uuid
    const response = await facultySourcesService.deleteFacultySource(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit(DELETE_FACULTY_SOURCE_SUCCESS, uuid) : commit("DELETE_FACULTY_SOURCE_ERROR", data)
        break
      default:
        commit("DELETE_FACULTY_SOURCE_ERROR", data)
    }
    return response
  },
  async sendResumeFacultySource({ commit, dispatch }, params) {
    commit("SEND_RESUME_FACULTY_SOURCE_REQUEST")
    const response = await facultySourcesService.sendResumeFacultySource(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("SEND_RESUME_FACULTY_SOURCE_SUCCESS", data) : commit("SEND_RESUME_FACULTY_SOURCE_ERROR", data)
        data.success ? dispatch('alert/createAlertSuccess', "Resume(s) Sent to POC", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
        break
      default:
        commit("SEND_RESUME_FACULTY_SOURCE_ERROR", data)
    }
    return response
  },
}

const mutations = {
  [GET_FACULTY_SOURCES_REQUEST]: state => {
    state.loading = true
  },
  [GET_FACULTY_SOURCES_SUCCESS]: (state, data) => {
    state.facultySources = addNewFilter(state.facultySources, data.faculty_sources)
    state.facultySourceLoadedOnce = true
    state.loading = false
    state.loadedOnce = true
  },
  [GET_FACULTY_SOURCES_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_FACULTY_SOURCE_REQUEST]: state => {
    state.loading = true
  },
  [GET_FACULTY_SOURCE_SUCCESS]: (state, data) => {
    let { faculty_source } = data
    state.facultySource = faculty_source
    state.loading = false
  },
  [GET_FACULTY_SOURCE_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_FACULTY_SOURCE_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_FACULTY_SOURCE_SUCCESS]: (state, data) => {
    let { faculty_sources } = data
    state.facultySources = addNewFilter(state.facultySources, faculty_sources)
    state.loading = false
  },
  [CREATE_FACULTY_SOURCE_ERROR]: state => {
    state.loading = false
  },

  [EDIT_FACULTY_SOURCE_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_FACULTY_SOURCE_SUCCESS]: (state, data) => {
    state.facultySources = state.facultySources.filter(i => i.uuid != data.faculty_source.uuid)
    state.facultySources.push(data.faculty_source)
    state.loading = false
  },
  [EDIT_FACULTY_SOURCE_ERROR]: state => {
    state.loading = false
  },

  [DELETE_FACULTY_SOURCE_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_FACULTY_SOURCE_SUCCESS]: (state, uuid) => {
    state.facultySources = state.facultySources.filter(i => i.uuid != uuid)
    state.loading = false
  },
  [BROADCAST_DELETE_FACULTY_SOURCE_SUCCESS]: (state, uuid) => {
    state.facultySources = state.facultySources.filter(i => i.uuid != uuid)
    state.loading = false
  },
  [DELETE_FACULTY_SOURCE_ERROR]: (state, data) => {
    state.loading = false
  },
  [SEND_RESUME_FACULTY_SOURCE_REQUEST]: state => {
    state.loading = true
  },
  [SEND_RESUME_FACULTY_SOURCE_SUCCESS]: (state, data) => {
    let { faculty_sources } = data
    state.facultySources = addNewFilter(state.facultySources, faculty_sources)
    state.loading = false
  },
  [SEND_RESUME_FACULTY_SOURCE_ERROR]: state => {
    state.loading = false
  },
}

export const facultySource = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}




export function unpackFS(fs, commit) {
  if (!isEmpty(fs.coach)) {
    let coach = unpackCoach(fs.coach, commit)
    commit("coaches/GET_COACHES_SUCCESS", { coaches: [coach] }, { root: true })
    delete fs.coach
  }
  if (!isEmpty(fs.comments)) {
		commit('comments/GET_COMMENTS_SUCCESS', { comments: fs.comments }, { root: true })
		delete fs.comments
	}
  return fs
}
