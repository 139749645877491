import {
	GET_COACH_ASSIGNMENTS_REQUEST,
	GET_COACH_ASSIGNMENTS_SUCCESS,
	GET_COACH_ASSIGNMENTS_ERROR,
	GET_COACH_ASSIGNMENT_REQUEST,
	GET_COACH_ASSIGNMENT_SUCCESS,
	GET_COACH_ASSIGNMENT_ERROR,
	CREATE_COACH_ASSIGNMENT_REQUEST,
	CREATE_COACH_ASSIGNMENT_SUCCESS,
	CREATE_COACH_ASSIGNMENT_ERROR,
	CREATE_BULK_COACH_ASSIGNMENT_REQUEST,
	CREATE_BULK_COACH_ASSIGNMENT_SUCCESS,
	CREATE_BULK_COACH_ASSIGNMENT_ERROR,
	EDIT_COACH_ASSIGNMENT_REQUEST,
	EDIT_COACH_ASSIGNMENT_SUCCESS,
	EDIT_COACH_ASSIGNMENT_ERROR,
	DELETE_COACH_ASSIGNMENT_REQUEST,
	DELETE_COACH_ASSIGNMENT_SUCCESS,
	DELETE_COACH_ASSIGNMENT_ERROR,
	BROADCAST_CREATE_CAR_SUCCESS,
	BROADCAST_UPDATED_CAR_SUCCESS,
	BROADCAST_DELETED_CAR_SUCCESS,
	CREATE_ASSIGNMENT_REPLACEMENT_REQUEST,
	CREATE_ASSIGNMENT_REPLACEMENT_SUCCESS,
	CREATE_ASSIGNMENT_REPLACEMENT_ERROR,
	BROADCAST_DELETE_COACH_ASSIGNMENT_SUCCESS
} from "@/actions"

import {
	coachAssignmentsService
} from "@/services"

import { unpackTransferLog, unpackCoachAssignmentReconciliation, unpackCoach, unpackCourseStart } from "@/modules";
import { addNewFilter, isEmpty, dateTimeFormat, onlyUnique } from "@/utilities";

const state = {
	caStatuses: [],
	coachAssignments: [],
	coachAssignment: {},
	loading: false,
	coachAssignmentLoadedOnce: false,
	assignedDates: [],
    isFirstTimeAssignments: [],
    caWasLinks: [],
	csUuidOnCaUuid: [],
	loadedCoachesUuids: []
}

function mapArray(state, getters, rootState, rootGetters, ca) {
	if (isEmpty(ca)) return {}
	let csByUuid = rootGetters["courseStart/csByUuid"]
	let csFacultyByCSUuid = rootGetters["courseStartFaculty/csFacultyByCSUuid"]
	let courseFindUuid = rootGetters["course/courseFindUuid"]
	let courseStarts = rootGetters["courseStart/courseStarts"]
	let wasByStartDateAndLength = rootGetters["workingAssignmentSheet/wasByStartDateAndLength"]
	let uniProUuidFilter = rootGetters["universityProgram/uniProUuidFilter"]
	let universityFilter = rootGetters["university/universityFilter"]

	let cs = {},
		faculties = {},
		related_faculties = {},
		course = {},
		program = {},
		university = {},
		was = {},
		dates = {},
		end_date = null,
		grade_date = null,
		pay_date = null,
		census_date = null;

	delete ca.comments
	cs = csByUuid(ca.course_starts_uuid)
	if (!isEmpty(cs)) {
		faculties = csFacultyByCSUuid(cs.uuid)
		ca.assigned_students_section = eval(ca.assigned_students_section)
		if (!isEmpty(ca.assigned_students_section)) {
			let assigned_students_section = ca.assigned_students_section.map(as => as.section)
			related_faculties = faculties.filter(f => assigned_students_section.some(as => f.section == as))
			if (!isEmpty(related_faculties)) {
				let g_related_faculties = related_faculties.map(f => f.email).filter(onlyUnique)
				if (!isEmpty(g_related_faculties)) {
					related_faculties = g_related_faculties.map(grf => {
						let f = related_faculties.find(rf => rf.email == grf)
						let fs = related_faculties.filter(rf => rf.email == grf).map(rf => rf.section).join(', ')
						return {
							...f,
							section: fs
						}
					})
				}
			}
		}
		course = courseFindUuid(cs.courses_uuid)

		if (!isEmpty(course)) {
			program = uniProUuidFilter(course.university_programs_uuid)

			if (!isEmpty(program)) {
				university = universityFilter(program.university_uuid)
			}

			was = wasByStartDateAndLength(cs.start_date, cs.course_length, course.university_programs_uuid);

			if (!isEmpty(was)) {
				if (!isEmpty(was.dates)) {
					dates = was.dates
					if (!isEmpty(dates.end_date)) {
						end_date = dates.end_date
					}
				} else if (!isEmpty(was.end_date)) {
					end_date = was.end_date
				}
				if (!isEmpty(was?.grade_date)) {
					grade_date = was.grade_date
				}

				if (!isEmpty(was?.pay_date)) {
					pay_date = was.pay_date
				}

				if (!isEmpty(was?.census_date)) {
					census_date = was.census_date
				}
			}

		}
	}

	return {
		...ca,
		faculties,
		related_faculties,
		course,
		program,
		university,
		was,
		cs,
		dates,
		start_date: !isEmpty(cs) ? cs.start_date : null,
		end_date,
		grade_date,
		pay_date,
		census_date,
	}
}

const getters = {
	caStatuses: state => state.caStatuses,
	coachAssignmentLoadedOnce: state => state.coachAssignmentLoadedOnce,
	allCoachAssignments: state => state.coachAssignments,
	coachAssignment: (state, getters, rootState, rootGetters) => {
		return mapArray(state, getters, rootState, rootGetters, state.coachAssignment)
	},
	coachAssignmentByCourseStartUuid: state => courseStartUuid => state.coachAssignments.filter(i => i.course_starts_uuid == courseStartUuid)
		.sort((a, b) => a?.coach_last_name?.localeCompare(b?.coach_last_name)),

	reconCoachAssignmentByCourseStartUuid: state => courseStartUuid => state.coachAssignments.filter(i => i.course_starts_uuid == courseStartUuid && (isEmpty(i.deleted_at) || !isEmpty(i.replacement_week))).sort((a, b) => a?.coach_last_name?.localeCompare(b?.coach_last_name)),
	coachAssignmentsByCoachUuid: state => coachUuid => state.coachAssignments.filter(ca => ca.coaches_uuid == coachUuid && isEmpty(ca.deleted_at)),
	coachAssignmentsByUuid: state => uuid => state.coachAssignments.find(ca => ca.uuid == uuid),
	coachAssignmentsByUuidMapped: (state, getters, rootState, rootGetters) => uuid => mapArray(state, getters, rootState, rootGetters, state.coachAssignments.find(ca => ca.uuid === uuid)),
	coachAssignmentsByCoachUuidMapped: (state, getters, rootState, rootGetters) => coachUuid => {
		let cas = state.coachAssignments.filter(ca => ca.coaches_uuid == coachUuid)
		return cas.map(i => mapArray(state, getters, rootState, rootGetters, i)).sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
	},
	coachHasActiveAssignment: (state, getters) => coachUuid => {
		let coachAssignments = getters.coachAssignmentsByCoachUuidMapped(coachUuid)
		let currentDateTimeStamp = Number(new Date(dateTimeFormat(new Date(), 'MM/DD/YYYY')))
		return coachAssignments.some(i => {
			let endDateTimeStamp = Number(new Date(dateTimeFormat(i.end_date, 'MM/DD/YYYY')))
			return (endDateTimeStamp >= currentDateTimeStamp)
		});
	},
	coachActiveAssignment: (state, getters) => coachUuid => {
		let coachAssignments = getters.coachAssignmentsByCoachUuidMapped(coachUuid)
		let currentDateTimeStamp = Number(new Date(dateTimeFormat(new Date(), 'MM/DD/YYYY')))
		return coachAssignments.find(i => {
			let endDateTimeStamp = Number(new Date(dateTimeFormat(i.end_date, 'MM/DD/YYYY')))
			return (endDateTimeStamp >= currentDateTimeStamp)
		});
	},
	coachFutureAssignments: (state, getters) => coachUuid => {
		let coachAssignments = getters.coachAssignmentsByCoachUuidMapped(coachUuid)
		coachAssignments = coachAssignments.filter(i => !i.cs.is_deleted)
		let currentDateTimeStamp = Number(new Date(dateTimeFormat(new Date(), 'MM/DD/YYYY')))
		return coachAssignments.filter(i => {
			let endDateTimeStamp = Number(new Date(dateTimeFormat(i.end_date, 'MM/DD/YYYY')))
			return (endDateTimeStamp >= currentDateTimeStamp)
		});
	},
	coachActiveAssignments: (state, getters) => coachUuid => {
		let currentDateTimeStamp = Number(new Date(dateTimeFormat(new Date(), 'MM/DD/YYYY')))
		return getters.coachFutureAssignments(coachUuid).filter(i => {
			let startDateTimeStamp = Number(new Date(dateTimeFormat(i.start_date, 'MM/DD/YYYY')))
			return (startDateTimeStamp <= currentDateTimeStamp)
		});
	},
	getWasLink: state => caUuid => state.caWasLinks.find(i => i.uuid == caUuid),
	checkIsFirstTimeAssigned: state => caUuid => state.isFirstTimeAssignments.some(i => i == caUuid),
	getAssignedDate: state => caUuid => state.assignedDates.find(i => i.uuid == caUuid),
	coachStatusOncs: (state, getters) => (coachesUuid, csUuid) => {
		const cas = getters.coachAssignmentsByCoachUuid(coachesUuid)
		if(isEmpty(cas)) return null;
		const ca = cas.find(i => i.course_starts_uuid == csUuid && isEmpty(i.deleted_at))
		if(isEmpty(ca)) return null;
		const ca_status = ca.status
		if(isEmpty(ca_status)) return 'Added to course';
		const assignment_status = state.caStatuses.find(j => j.value == ca_status)
		return `Added to course - ${assignment_status?.label}`
	}
}

const actions = {
	async getCoachAssignments({ commit, state }, params) {
		if(!isEmpty(params.coaches_uuid) && params?.query_type == 'coaches_uuid_evaluations'){
			if(state.loadedCoachesUuids.includes(params.coaches_uuid)) return true;
		}
		commit("GET_COACH_ASSIGNMENTS_REQUEST")
		const response = await coachAssignmentsService.getCoachAssignments(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if(!params?.skipStore){
					data.coach_assignments = data.coach_assignments.map(ca => unpackCoachAssignment(ca, commit))
					if(!isEmpty(params.coaches_uuid) && params?.query_type == 'coaches_uuid_evaluations'){
						commit("COACHES_LOADED_ASSIGNMENTS", params.coaches_uuid)
					}
					commit("GET_COACH_ASSIGNMENTS_SUCCESS", data)
				}
				break
			default:
				commit("GET_COACH_ASSIGNMENTS_ERROR", data)
		}
		return response
	},

	async getCoachAssignment({ commit }, params) {
		commit("GET_COACH_ASSIGNMENT_REQUEST")
		const response = await coachAssignmentsService.getCoachAssignment(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					data.coach_assignment = unpackCoachAssignment(data.coach_assignment, commit)
					commit("GET_COACH_ASSIGNMENT_SUCCESS", data)
				}
				break
			default:
				commit("GET_COACH_ASSIGNMENT_ERROR", data)
				break
		}
		return response
	},
	async sendAvailability({ commit }, params) {
		commit("GET_COACH_ASSIGNMENTS_REQUEST")
		const response = await coachAssignmentsService.sendAvailability(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					data.coach_assignments = data.coach_assignments.map(ca => unpackCoachAssignment(ca, commit))
					commit("GET_COACH_ASSIGNMENTS_SUCCESS", data)
				}
				break
			default:
				commit("GET_COACH_ASSIGNMENTS_ERROR", data)
				break
		}
		return response
	},
	async createCoachAssignment({ commit, dispatch }, params) {
		commit("CREATE_COACH_ASSIGNMENT_REQUEST")
		const response = await coachAssignmentsService.createCoachAssignment(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					commit("CREATE_BULK_COACH_ASSIGNMENT_SUCCESS", data)
					let coaches = data.coaches.map(c => unpackCoach(c, commit))
					commit("coaches/GET_COACHES_SUCCESS", { coaches }, { root: true })
					dispatch("alert/createAlertSuccess", "Coaches added to Course Start", { root: true })
				} else {
					commit("CREATE_BULK_COACH_ASSIGNMENT_ERROR", data)
					let keys = Object.keys(response.data.errors)
					if (!keys.some(k => k == 'CoachMax')) {
						// dispatch('alert/createAlertErrors', data.errors, { root: true })
					}
				}
				break
			default:
				commit("CREATE_COACH_ASSIGNMENT_ERROR", data)
		}
		return response
	},
	// async createBulkCoachAssignment({ commit, dispatch }, params) {
	// 	commit("CREATE_BULK_COACH_ASSIGNMENT_REQUEST")
	// 	const response = await coachAssignmentsService.createBulkCoachAssignment(params)
	// 	const { status, data } = response
	// 	switch (status) {
	// 		case 200:
	// 			if (data.success) {
	// 				commit("CREATE_BULK_COACH_ASSIGNMENT_SUCCESS", data)
	// 				let coaches = data.coaches.map(c => unpackCoach(c, commit))
	// 				commit("coaches/GET_COACHES_SUCCESS", { coaches }, { root: true })
	// 				dispatch("alert/createAlertSuccess", "Coaches added to Course Start", { root: true })
	// 			} else {
	// 				commit("CREATE_BULK_COACH_ASSIGNMENT_ERROR", data)
	// 				let keys = Object.keys(response.data.errors)
	//           		if(!keys.some(k => k == 'CoachMax')){
	// 					dispatch('alert/createAlertErrors', data.errors, { root: true })
	// 				}
	// 			}
	// 			break
	// 		default:
	// 			commit("CREATE_BULK_COACH_ASSIGNMENT_ERROR", data)
	// 	}
	// 	return response
	// },
	async editCoachAssignment({ commit, dispatch }, params) {
		commit("EDIT_COACH_ASSIGNMENT_REQUEST")
		const { uuid } = params
		const response = await coachAssignmentsService.editCoachAssignment(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_COACH_ASSIGNMENT_SUCCESS", data) : commit("EDIT_COACH_ASSIGNMENT_ERROR", data)
				data.success ? dispatch("alert/createAlertSuccess", "Coach assignment updated.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("EDIT_COACH_ASSIGNMENT_ERROR", data)
		}
		return response
	},
	async deleteCoachAssignment({ commit }, params) {
		commit("DELETE_COACH_ASSIGNMENT_REQUEST")
		const response = await coachAssignmentsService.deleteCoachAssignment(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_COACH_ASSIGNMENT_SUCCESS") : null
				break
			default:
				commit("DELETE_COACH_ASSIGNMENT_ERROR", data)
		}
		return response
	},
	async replaceCoachAssignment({ commit, dispatch }, params) {
		commit("CREATE_ASSIGNMENT_REPLACEMENT_REQUEST")
		const response = await coachAssignmentsService.createAssignmentReplacement(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? dispatch("alert/createAlertSuccess", "Assignment Replaced.", { root: true }) : null
				data.success ? commit("CREATE_ASSIGNMENT_REPLACEMENT_SUCCESS", data) : null
				data.error ? dispatch('alert/createAlertErrors', data.errors, { root: true }) : null
				break
			default:
				commit("CREATE_ASSIGNMENT_REPLACEMENT_ERROR")
		}
		return response
	},
	async customActions({ commit, dispatch }, params) {
		const uuid = params.uuid
		const afterActionParams = params.afterActionParams
		commit("CREATE_ASSIGNMENT_REPLACEMENT_REQUEST")
		const response = await coachAssignmentsService.coachCustomActions(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				if (!data.success) {
					dispatch('alert/createAlertErrors', "Something went wrong!", { root: true })
				}
				else if (!isEmpty(afterActionParams)) {
					dispatch('miscellaneous/removeFromCoachWithOverStudents', afterActionParams, { root: true })
				}
				break
			default:
				commit("CREATE_ASSIGNMENT_REPLACEMENT_ERROR", data)
		}
		return response
	},
}

const mutations = {
	[GET_COACH_ASSIGNMENTS_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_ASSIGNMENTS_SUCCESS]: (state, data) => {
		let coach_assignments = data.coach_assignments
		coach_assignments.forEach(ca => {
			state = updateMislData(ca, state)
		})
		state.coachAssignments = addNewFilter(state.coachAssignments, coach_assignments, data.force || false)
		state.coachAssignmentLoadedOnce = true
		state.loading = false
	},
	[GET_COACH_ASSIGNMENTS_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_COACH_ASSIGNMENT_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_ASSIGNMENT_SUCCESS]: (state, data) => {
		let { coach_assignment } = data
		state.coachAssignment = coach_assignment
		state = updateMislData(coach_assignment, state)
		state.coachAssignments = addNewFilter(state.coachAssignments, [coach_assignment])
		state.loading = false
	},
	[GET_COACH_ASSIGNMENT_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COACH_ASSIGNMENT_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COACH_ASSIGNMENT_SUCCESS]: (state, data) => {
		let coach_assignment = data.coach_assignment
		state = updateMislData(coach_assignment, state)
		state.coachAssignments = addNewFilter(state.coachAssignments, [coach_assignment])
		state.loading = false
	},
	[CREATE_COACH_ASSIGNMENT_ERROR]: state => {
		state.loading = false
	},

	[CREATE_BULK_COACH_ASSIGNMENT_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_BULK_COACH_ASSIGNMENT_SUCCESS]: (state, data) => {
		let coach_assignments = data.coach_assignments
		coach_assignments.forEach(ca => {
			state = updateMislData(ca, state)
		})
		state.coachAssignments = addNewFilter(state.coachAssignments, coach_assignments)
		state.loading = false
	},
	[CREATE_BULK_COACH_ASSIGNMENT_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COACH_ASSIGNMENT_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COACH_ASSIGNMENT_SUCCESS]: (state, data) => {
		let { coach_assignment } = data
		state.coachAssignment = coach_assignment
		state = updateMislData(coach_assignment, state)
		state.coachAssignments = state.coachAssignments.map(i => i.uuid == coach_assignment.uuid ? coach_assignment : i)
		state.coachAssignments = addNewFilter(state.coachAssignments, [coach_assignment])
		state.loading = false
	},
	[EDIT_COACH_ASSIGNMENT_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COACH_ASSIGNMENT_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COACH_ASSIGNMENT_SUCCESS]: (state, data) => {
		state.loading = false
		const { coach_assignment } = data
		if(!isEmpty(coach_assignment)){
			state.coachAssignments = state.coachAssignments.map(ca => ca.uuid == coach_assignment.uuid ? coach_assignment : ca)
		}
		state.loading = false
	},
	[BROADCAST_DELETE_COACH_ASSIGNMENT_SUCCESS]: (state, data) => {
		const { coach_assignment } = data
		state.coachAssignments = state.coachAssignments.map(ca => ca.uuid == coach_assignment.uuid ? coach_assignment : ca)
		state.loading = false
	},
	[DELETE_COACH_ASSIGNMENT_ERROR]: (state, data) => {
		state.loading = false
	},
	[BROADCAST_CREATE_CAR_SUCCESS]: (state, data) => {
		const { coach_assignment } = data
		state.coachAssignments = state.coachAssignments.map(ca => ca.uuid == coach_assignment.uuid ? coach_assignment : ca)
	},
	[BROADCAST_UPDATED_CAR_SUCCESS]: (state, data) => {
		const { coach_assignment } = data
		state.coachAssignments = state.coachAssignments.map(ca => ca.uuid == coach_assignment.uuid ? coach_assignment : ca)
	},
	[BROADCAST_DELETED_CAR_SUCCESS]: (state, data) => {
		const { coach_assignment } = data
		state.coachAssignments = state.coachAssignments.map(ca => ca.uuid == coach_assignment.uuid ? coach_assignment : ca)
	},
	[CREATE_ASSIGNMENT_REPLACEMENT_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_ASSIGNMENT_REPLACEMENT_SUCCESS]: (state, data) => {
		let { coach_assignment } = data
		state.coachAssignments = state.coachAssignments.map(i => i.uuid == coach_assignment.uuid ? coach_assignment : i)
		state.loading = false
	},
	[CREATE_ASSIGNMENT_REPLACEMENT_ERROR]: state => {
		state.loading = false
	},
	"SET_CA_STATUSES": (state, ca_statuses) => {
		let keys = Object.keys(ca_statuses)
		keys.forEach(k => {
			state.caStatuses.push({
				value: k,
				label: ca_statuses[k]
			})
		})
	},
	"COACHES_LOADED_ASSIGNMENTS": (state, coachesUuid) => {
		state.loadedCoachesUuids.push(coachesUuid)
	}
}

export const coachAssignment = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

export function unpackCoachAssignment(ca, commit) {

	if (!isEmpty(ca.comments)) {
		commit('comments/GET_COMMENTS_SUCCESS', { comments: ca.comments }, { root: true })
		delete ca.comments
	}

	if (!isEmpty(ca.course_start)) {
		let course_start = unpackCourseStart(ca.course_start, commit)
		commit("courseStart/GET_COURSE_STARTS_SUCCESS", { course_starts: [course_start] }, { root: true })
		delete ca.course_start
	}
	if (!isEmpty(ca.course)) {
		commit("course/GET_COURSE_SUCCESS", { course: ca.course }, { root: true })
		delete ca.course
	}
	if (!isEmpty(ca.working_assignment_sheet)) {
		commit("workingAssignmentSheet/GET_WORKING_ASSIGNMENT_SHEET_SUCCESS", { working_assignment_sheet: ca.working_assignment_sheet }, { root: true })
		delete ca.working_assignment_sheet
	}
	if (!isEmpty(ca.university_program)) {
		commit("universityProgram/GET_UNIVERSITY_PROGRAM_SUCCESS", { university_program: ca.university_program }, { root: true })
		delete ca.university_program
	}
	if (!isEmpty(ca.university)) {
		commit("university/GET_UNIVERSITY_SUCCESS", { university: ca.university }, { root: true })
		delete ca.university
	}

	if (!isEmpty(ca.payment)) {
		commit("assignmentPayment/GET_ASSIGNMENT_PAYMENT_SUCCESS", { assignment_payment: ca.payment }, { root: true });
		delete ca.payment;
	}

	if (!isEmpty(ca.reconciliation)) {
		let car = unpackCoachAssignmentReconciliation(ca.reconciliation, commit)
		commit('coachAssignmentReconciliation/GET_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS', { coach_assignment_reconciliation: car }, { root: true })
		delete ca.reconciliation
	}

	if (!isEmpty(ca.transfer_logs)) {
		let transfer_student_logs = ca.transfer_logs.map(i => unpackTransferLog(i, commit))
		commit("transferStudentLog/GET_TRANSFER_STUDENTS_SUCCESS", { transfer_student_logs }, { root: true })
		delete ca.transfer_logs
	}

	if (!isEmpty(ca.finalized_students)) {
		let finalized_students = ca.finalized_students
		commit("finalizedStudent/GET_FINALIZE_STUDENTS_SUCCESS", { finalized_students }, { root: true })
		delete ca.finalized_students
	}
	if (!isEmpty(ca.coach_evaluation)) {
		const coach_evaluation = ca.coach_evaluation
		commit("coachEvaluation/CREATE_COACH_EVALUATION_SUCCESS", { coach_evaluation }, { root: true })
		delete ca.coach_evaluation
	}
	if (!isEmpty(ca.faculty_evaluation)) {
		const faculty_evaluation = ca.faculty_evaluation
		commit("facultyEvaluation/CREATE_FACULTY_EVALUATION_SUCCESS", { faculty_evaluation }, { root: true })
		delete ca.faculty_evaluation
	}
	return ca
}

function updateMislData(ca, state){
	if(ca?.is_first_time_assigned){
		if(!state.isFirstTimeAssignments.some(ifca => ifca == ca.uuid)){
			state.isFirstTimeAssignments.push(ca.uuid)
		}
	}
	if(ca?.assigned_date){
		if(!state.assignedDates.some(ica => ica.uuid == ca.uuid)){
			state.assignedDates.push({
				uuid: ca.uuid,
				assigned_date: ca.assigned_date
			})
		}
	}
	if(ca?.was_link){
		if(!state.caWasLinks.some(ica => ica.uuid == ca.uuid)){
			state.caWasLinks.push({
				uuid: ca.uuid,
				was_link: ca.was_link
			})
		}
	}
	return state;
}