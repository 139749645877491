export const GET_COURSE_LENGTHS_REQUEST = "GET_COURSE_LENGTHS_REQUEST"
export const GET_COURSE_LENGTHS_SUCCESS = "GET_COURSE_LENGTHS_SUCCESS"
export const GET_COURSE_LENGTHS_ERROR = "GET_COURSE_LENGTHS_ERROR"
export const GET_COURSE_LENGTH_REQUEST = "GET_COURSE_LENGTH_REQUEST"
export const GET_COURSE_LENGTH_SUCCESS = "GET_COURSE_LENGTH_SUCCESS"
export const GET_COURSE_LENGTH_ERROR = "GET_COURSE_LENGTH_ERROR" 
export const CREATE_COURSE_LENGTH_REQUEST = "CREATE_COURSE_LENGTH_REQUEST"
export const CREATE_COURSE_LENGTH_SUCCESS = "CREATE_COURSE_LENGTH_SUCCESS"
export const CREATE_COURSE_LENGTH_ERROR = "CREATE_COURSE_LENGTH_ERROR"
export const EDIT_COURSE_LENGTH_REQUEST = "EDIT_COURSE_LENGTH_REQUEST"
export const EDIT_COURSE_LENGTH_SUCCESS = "EDIT_COURSE_LENGTH_SUCCESS"
export const EDIT_COURSE_LENGTH_ERROR = "EDIT_COURSE_LENGTH_ERROR"
export const DELETE_COURSE_LENGTH_REQUEST = "DELETE_COURSE_LENGTH_REQUEST"
export const DELETE_COURSE_LENGTH_SUCCESS = "DELETE_COURSE_LENGTH_SUCCESS"
export const DELETE_COURSE_LENGTH_ERROR = "DELETE_COURSE_LENGTH_ERROR"