import {
  apiRequest,
  processInput,
} from "@/utilities"

export const backgroundCheckService = {
  // async getCandidate(params) {
  //   let getParams = processQueryParams(params)
  //   const getCandidateRequest = {
  //     method: "GET",
  //     params: getParams
  //   }
  //   return await apiRequest("/api/background-checks/get-candidate", getCandidateRequest);
  // },
  async getPackages() {
    const getPackagesRequest = {
      method: "GET"
    }
    return await apiRequest("/api/background-checks/get-packages", getPackagesRequest);
  },
  async createScreening(params) {
    const createData = processInput(params)
    const createBackgroundCheckRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/background-checks/create-screening", createBackgroundCheckRequest);
  },
  async syncCandidate(params) {
    const syncData = processInput(params);
    const syncCandidateRequest = {
      method: "POST",
      data: syncData
    }
    return await apiRequest("/api/background-checks/sync-candidate", syncCandidateRequest);
  }
}
