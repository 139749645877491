import {
    GET_FACILITATING_COURSES_REQUEST,
    GET_FACILITATING_COURSES_SUCCESS,
    GET_FACILITATING_COURSES_ERROR,
    GET_FACILITATING_COURSE_REQUEST,
    GET_FACILITATING_COURSE_SUCCESS,
    GET_FACILITATING_COURSE_ERROR,
    CREATE_FACILITATING_COURSE_REQUEST,
    CREATE_FACILITATING_COURSE_SUCCESS,
    CREATE_FACILITATING_COURSE_ERROR,
    EDIT_FACILITATING_COURSE_REQUEST,
    EDIT_FACILITATING_COURSE_SUCCESS,
    EDIT_FACILITATING_COURSE_ERROR,
    DELETE_FACILITATING_COURSE_REQUEST,
    DELETE_FACILITATING_COURSE_SUCCESS,
    DELETE_FACILITATING_COURSE_ERROR
} from "../../actions"

import {
    facilitatingCourseService
} from "../../services"

const state = {
    facilitatingCourses: [],
    facilitatingCourse: {},
    loading: false,
    loadedOnce: false
}

const sortMap = {
    "k-12": 1,
    "associates": 2,
    "bachlors": 3,
    "graduate": 4,
    "doctoral": 5,
    "non-credit": 6,
    "workplace": 7,
    "none": 8,
}

const getters = {
    fcLoading: state => loading,
    fcLoadedOnce: state => loadedOnce,
    facilitatingCourseSelectOptions: state => state.facilitatingCourses.map(fc => ({ value: fc.uuid, label: fc.label, sort: sortMap[fc.slug ?? 'none'] })).sort((a, b) => a.sort - b.sort),
    facilitatingCourseMultiSelectOptions: state => state.facilitatingCourses.map(fc => ({ value: fc.uuid, text: fc.label, sort: sortMap[fc.slug ?? 'none'] })).sort((a, b) => a.sort - b.sort)
}

const actions = {
    async getFacilitatingCourses({ commit }) {
        commit("GET_FACILITATING_COURSES_REQUEST")
        const response = await facilitatingCourseService.getFacilitatingCourses();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_FACILITATING_COURSES_SUCCESS", data)
                break
            default:
                commit("GET_FACILITATING_COURSES_ERROR", data)
        }
        return response
    },
    async getFacilitatingCourse({ commit }, params) {
        commit("GET_FACILITATING_COURSE_REQUEST")
        const response = await facilitatingCourseService.getFacilitatingCourse(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_FACILITATING_COURSE_SUCCESS", data)
                break
            default:
                commit("GET_FACILITATING_COURSE_ERROR", data)
                break
        }
        return response
    },
    async createFacilitatingCourse({ commit }, params) {
        commit("CREATE_FACILITATING_COURSE_REQUEST")
        const response = await facilitatingCourseService.createFacilitatingCourse(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_FACILITATING_COURSE_SUCCESS", data)
                break
            default:
                commit("CREATE_FACILITATING_COURSE_ERROR", data)
        }
        return response
    },
    async editFacilitatingCourse({ commit }, params, uuid) {
        commit("EDIT_FACILITATING_COURSE_REQUEST")
        const response = await facilitatingCourseService.editFacilitatingCourse(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_FACILITATING_COURSE_SUCCESS", data)
                break
            default:
                commit("EDIT_FACILITATING_COURSE_ERROR", data)
        }
        return response
    },
    async deleteFacilitatingCourse({ commit }, params) {
        commit("DELETE_FACILITATING_COURSE_REQUEST")
        const response = await facilitatingCourseService.deleteFacilitatingCourse(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_FACILITATING_COURSE_SUCCESS", data)
                break
            default:
                commit("DELETE_FACILITATING_COURSE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_FACILITATING_COURSES_REQUEST]: state => {
        state.loading = true
    },
    [GET_FACILITATING_COURSES_SUCCESS]: (state, data) => {
        const facilitatingCourses = data.facilitating_courses
        state.facilitatingCourses = facilitatingCourses
        state.loading = false
    },
    [GET_FACILITATING_COURSES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_FACILITATING_COURSE_REQUEST]: state => {
        state.loading = true
    },
    [GET_FACILITATING_COURSE_SUCCESS]: (state, data) => {
        const facilitatingCourse = { ...data.facilitating_course }
        state.facilitatingCourse = facilitatingCourse
        state.loading = false
    },
    [GET_FACILITATING_COURSE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_FACILITATING_COURSE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_FACILITATING_COURSE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [CREATE_FACILITATING_COURSE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_FACILITATING_COURSE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_FACILITATING_COURSE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_FACILITATING_COURSE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_FACILITATING_COURSE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_FACILITATING_COURSE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_FACILITATING_COURSE_ERROR]: (state, data) => {
        state.loading = false
    }
}

export const facilitatingCourse = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}