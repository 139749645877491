import {
  GET_INVOICE_ADJUSTMENTS_REQUEST,
  GET_INVOICE_ADJUSTMENTS_SUCCESS,
  GET_INVOICE_ADJUSTMENTS_ERROR,
  GET_INVOICE_ADJUSTMENT_REQUEST,
  GET_INVOICE_ADJUSTMENT_SUCCESS,
  GET_INVOICE_ADJUSTMENT_ERROR,
  CREATE_INVOICE_ADJUSTMENT_REQUEST,
  CREATE_INVOICE_ADJUSTMENT_SUCCESS,
  CREATE_INVOICE_ADJUSTMENT_ERROR,
  BROADCAST_CREATE_INVOICE_ADJUSTMENT_SUCCESS,
  EDIT_INVOICE_ADJUSTMENT_REQUEST,
  EDIT_INVOICE_ADJUSTMENT_SUCCESS,
  EDIT_INVOICE_ADJUSTMENT_ERROR,
  BROADCAST_EDIT_INVOICE_ADJUSTMENT_SUCCESS,
  DELETE_INVOICE_ADJUSTMENT_REQUEST,
  DELETE_INVOICE_ADJUSTMENT_SUCCESS,
  DELETE_INVOICE_ADJUSTMENT_ERROR,
  BROADCAST_DELETE_INVOICE_ADJUSTMENT_SUCCESS,
} from "@/actions"

import {
  invoiceAdjustmentService
} from "@/services"

import { isEmpty, addNewFilter, isNumeric } from "@/utilities"

const state = {
  invoiceAdjustments: [],
  invoiceAdjustment: {},
  loading: false
}

const getters = {
  invoiceAdjustmentLoading: state => state.loading,
  invoiceAdjustment: state => state.invoiceAdjustment,
  invoiceAdjustments: state => state.invoiceAdjustments,
  adjustmentByUuid: state => uuid => state.invoiceAdjustments.find(i => i.uuid == uuid),
  adjustmentsByUnivUuid: state => uuid => state.invoiceAdjustments.filter(ia => ia.universities_uuid == uuid),
  allAdjustmentsByInvoice: state => invoice => {
    if(isEmpty(invoice)) return [];
    const {program_uuids} = invoice?.meta
    if(isEmpty(program_uuids)) return []
    const program_uuid_array = program_uuids.split(',')
    return state.invoiceAdjustments.filter(ia => program_uuid_array.includes(ia.university_programs_uuid))
  },
  adjustmentsByInvoice: (state, getters) => invoice => {
    const allAdjustmentsByInvoice = getters.allAdjustmentsByInvoice(invoice)
    return allAdjustmentsByInvoice.filter(ia => ia.amount_available ?? 0 > 0)
  },
}

const actions = {
  async getInvoiceAdjustments({ commit }, params) {
    commit("GET_INVOICE_ADJUSTMENTS_REQUEST")
    const response = await invoiceAdjustmentService.getInvoiceAdjustments(params);
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_INVOICE_ADJUSTMENTS_SUCCESS", data)
        break
      default:
        commit("GET_INVOICE_ADJUSTMENTS_ERROR", data)
    }
    return response
  },
  async getInvoiceAdjustment({ commit }, params) {
    commit("GET_INVOICE_ADJUSTMENT_REQUEST")
    const response = await invoiceAdjustmentService.getInvoiceAdjustment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_INVOICE_ADJUSTMENT_SUCCESS", data)
        break
      default:
        commit("GET_INVOICE_ADJUSTMENT_ERROR", data)
        break
    }
    return response
  },
  async createInvoiceAdjustment({ commit, dispatch }, params) {
    commit("CREATE_INVOICE_ADJUSTMENT_REQUEST")
    const response = await invoiceAdjustmentService.createInvoiceAdjustment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_INVOICE_ADJUSTMENT_SUCCESS", data) : null
        data.success ? dispatch("alert/createAlertSuccess", "Invoice Adjustment created.", { root: true }): null
        break
      default:
        commit("CREATE_INVOICE_ADJUSTMENT_ERROR", data)
    }
    return response
  },
  async editInvoiceAdjustment({ commit }, params) {
    commit("EDIT_INVOICE_ADJUSTMENT_REQUEST")
    const response = await invoiceAdjustmentService.editInvoiceAdjustment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_INVOICE_ADJUSTMENT_SUCCESS", data) : null
        break
      default:
        commit("EDIT_INVOICE_ADJUSTMENT_ERROR", data)
    }
    return response
  },
  async deleteInvoiceAdjustment({ commit, dispatch }, uuid) {
    commit("DELETE_INVOICE_ADJUSTMENT_REQUEST")
    const response = await invoiceAdjustmentService.deleteInvoiceAdjustment(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_INVOICE_ADJUSTMENT_SUCCESS", uuid) : null
        data.success ? dispatch("alert/createAlertSuccess", "Invoice Adjustment deleted.", { root: true }) : null
        break
      default:
        commit("DELETE_INVOICE_ADJUSTMENT_ERROR", data)
    }
    return response
  },
  cleanStateInvoiceAdjustment({ commit }) {
    commit("CLEAN_INVOICE_ADJUSTMENT")
  }
}

const mutations = {
  [GET_INVOICE_ADJUSTMENTS_REQUEST]: state => {
    state.loading = true
  },
  [GET_INVOICE_ADJUSTMENTS_SUCCESS]: (state, data) => {
    const invoice_adjustments = data.invoice_adjustments
    state.invoiceAdjustments = invoice_adjustments
    state.loading = false
  },
  [GET_INVOICE_ADJUSTMENTS_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_INVOICE_ADJUSTMENT_REQUEST]: state => {
    state.loading = true
  },
  [GET_INVOICE_ADJUSTMENT_SUCCESS]: (state, data) => {
    const { invoice_adjustment } = data
    state.invoiceAdjustment = invoice_adjustment
    state.loading = false
  },
  [GET_INVOICE_ADJUSTMENT_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_INVOICE_ADJUSTMENT_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_INVOICE_ADJUSTMENT_SUCCESS]: (state, data) => {
    const { invoice_adjustment } = data
    state.invoiceAdjustments = addNewFilter(state.invoiceAdjustments, [invoice_adjustment])
    state.invoiceAdjustment = invoice_adjustment
    state.loading = false
  },
  [BROADCAST_CREATE_INVOICE_ADJUSTMENT_SUCCESS]: (state, data) => {
    const { invoice_adjustment } = data
    state.invoiceAdjustments = addNewFilter(state.invoiceAdjustments, [invoice_adjustment])
  },
  [CREATE_INVOICE_ADJUSTMENT_ERROR]: state => {
    state.loading = false
  },

  [EDIT_INVOICE_ADJUSTMENT_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_INVOICE_ADJUSTMENT_SUCCESS]: (state, data) => {
    const { invoice_adjustment } = data
    state.invoiceAdjustments = state.invoiceAdjustments.map(i => i.uuid == invoice_adjustment.uuid ? invoice_adjustment : i)
    if (!isEmpty(state.invoiceAdjustment) && state.invoiceAdjustment.uuid == invoice_adjustment.uuid) {
      state.invoiceAdjustment = invoice_adjustment
    }
    state.loading = false
  },
  [EDIT_INVOICE_ADJUSTMENT_ERROR]: state => {
    state.loading = false
  },
  [BROADCAST_EDIT_INVOICE_ADJUSTMENT_SUCCESS]: (state, data) => {
    const { invoice_adjustment } = data
    state.invoiceAdjustments = state.invoiceAdjustments.map(i => i.uuid == invoice_adjustment.uuid ? invoice_adjustment : i)
    if (!isEmpty(state.invoiceAdjustment) && state.invoiceAdjustment.uuid == invoice_adjustment.uuid) {
      state.invoiceAdjustment = invoice_adjustment
    }
  },
  [DELETE_INVOICE_ADJUSTMENT_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_INVOICE_ADJUSTMENT_SUCCESS]: (state, uuid) => {
    state.invoiceAdjustments = state.invoiceAdjustments.filter(i => i.uuid !== uuid)
    state.loading = false
  },
  [DELETE_INVOICE_ADJUSTMENT_ERROR]: (state, data) => {
    state.loading = false
  },
  [BROADCAST_DELETE_INVOICE_ADJUSTMENT_SUCCESS]: (state, data) => {
    const { invoice_adjustment } = data
    state.invoiceAdjustments = state.invoiceAdjustments.filter(i => i.uuid == invoice_adjustment.uuid ? false : true)
    state.loading = false
  }
}

export const invoiceAdjustment = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}