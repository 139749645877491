export const GET_COACH_FACULTY_REFES_FACULTY_REF_REQUEST = "GET_COACH_FACULTY_REFES_FACULTY_REF_REQUEST"
export const GET_COACH_FACULTY_REFES_FACULTY_REF_SUCCESS = "GET_COACH_FACULTY_REFES_FACULTY_REF_SUCCESS"
export const GET_COACH_FACULTY_REFES_FACULTY_REF_ERROR = "GET_COACH_FACULTY_REFES_FACULTY_REF_ERROR"
export const GET_COACH_FACULTY_REF_REQUEST = "GET_COACH_FACULTY_REF_REQUEST"
export const GET_COACH_FACULTY_REF_SUCCESS = "GET_COACH_FACULTY_REF_SUCCESS"
export const GET_COACH_FACULTY_REF_ERROR = "GET_COACH_FACULTY_REF_ERROR"
export const CREATE_COACH_FACULTY_REF_REQUEST = "CREATE_COACH_FACULTY_REF_REQUEST"
export const CREATE_COACH_FACULTY_REF_SUCCESS = "CREATE_COACH_FACULTY_REF_SUCCESS"
export const CREATE_COACH_FACULTY_REF_ERROR = "CREATE_COACH_FACULTY_REF_ERROR"
export const EDIT_COACH_FACULTY_REF_REQUEST = "EDIT_COACH_FACULTY_REF_REQUEST"
export const EDIT_COACH_FACULTY_REF_SUCCESS = "EDIT_COACH_FACULTY_REF_SUCCESS"
export const EDIT_COACH_FACULTY_REF_ERROR = "EDIT_COACH_FACULTY_REF_ERROR"
export const DELETE_COACH_FACULTY_REF_REQUEST = "DELETE_COACH_FACULTY_REF_REQUEST"
export const DELETE_COACH_FACULTY_REF_SUCCESS = "DELETE_COACH_FACULTY_REF_SUCCESS"
export const DELETE_COACH_FACULTY_REF_ERROR = "DELETE_COACH_FACULTY_REF_ERROR"
export const CLEAR_CURRENT_COACH_FACULTY_REF = "CLEAR_CURRENT_COACH_FACULTY_REF"
export const CLEAR_COACH_FACULTY_REF_STATE = "CLEAR_COACH_FACULTY_REF_STATE"