export const CREATE_RECONCILIATION_REQUEST = "CREATE_RECONCILIATION_REQUEST"
export const CREATE_RECONCILIATION_SUCCESS = "CREATE_RECONCILIATION_SUCCESS"
export const CREATE_RECONCILIATION_ERROR = "CREATE_RECONCILIATION_ERROR"
export const DELETE_RECONCILIATION_REQUEST = "DELETE_RECONCILIATION_REQUEST"
export const DELETE_RECONCILIATION_SUCCESS = "DELETE_RECONCILIATION_SUCCESS"
export const DELETE_RECONCILIATION_ERROR = "DELETE_RECONCILIATION_ERROR"
export const RESET_RECONCILIATION_REQUEST = "RESET_RECONCILIATION_REQUEST"
export const RESET_RECONCILIATION_SUCCESS = "RESET_RECONCILIATION_SUCCESS"
export const RESET_RECONCILIATION_ERROR = "RESET_RECONCILIATION_ERROR"
export const FINALIZE_RECONCILIATION_REQUEST = "FINALIZE_RECONCILIATION_REQUEST"
export const FINALIZE_RECONCILIATION_SUCCESS = "FINALIZE_RECONCILIATION_SUCCESS"
export const FINALIZE_RECONCILIATION_ERROR = "FINALIZE_RECONCILIATION_ERROR"