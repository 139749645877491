import {
    GET_UNIVERSITY_CENSUSES_REQUEST,
    GET_UNIVERSITY_CENSUSES_SUCCESS,
    GET_UNIVERSITY_CENSUSES_ERROR,
    GET_UNIVERSITY_CENSUS_REQUEST,
    GET_UNIVERSITY_CENSUS_SUCCESS,
    GET_UNIVERSITY_CENSUS_ERROR,
    CREATE_UNIVERSITY_CENSUS_REQUEST,
    CREATE_UNIVERSITY_CENSUS_SUCCESS,
    CREATE_UNIVERSITY_CENSUS_ERROR,
    EDIT_UNIVERSITY_CENSUS_REQUEST,
    EDIT_UNIVERSITY_CENSUS_SUCCESS,
    EDIT_UNIVERSITY_CENSUS_ERROR,
    DELETE_UNIVERSITY_CENSUS_REQUEST,
    DELETE_UNIVERSITY_CENSUS_SUCCESS,
    DELETE_UNIVERSITY_CENSUS_ERROR
} from "@/actions"

import {
    universityCensusesService
} from "@/services"

import { addNewFilter } from "@/utilities"

const state = {
    universityCensuses: [],
    universityCensus: {},
    loading: false
}

const getters = {
    allUniversityCensuses: state => state.universityCensuses,
    universityCensusFilter: state => uuid => state.universityCensuses.filter(upm => upm.university_uuid == uuid)
}

const actions = {
    async getUniversityCensuses({ commit }) {
        commit("GET_UNIVERSITY_CENSUSES_REQUEST")
        const response = await universityCensusesService.getUniversityCensuses();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_CENSUSES_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_CENSUSES_ERROR", data)
        }
        return response
    },
    
    async getUniversityCensus({ commit }, params) {
        commit("GET_UNIVERSITY_CENSUS_REQUEST")
        const response = await universityCensusesService.getUniversityCensus(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_CENSUS_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_CENSUS_ERROR", data)
                break
        }
        return response
    },
    async createUniversityCensus({ commit }, params) {
        commit("CREATE_UNIVERSITY_CENSUS_REQUEST")
        const response = await universityCensusesService.createUniversityCensus(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_UNIVERSITY_CENSUS_SUCCESS", data)
                break
            default:
                commit("CREATE_UNIVERSITY_CENSUS_ERROR", data)
        }
        return response
    },
    async editUniversityCensus({ commit }, params) {
        commit("EDIT_UNIVERSITY_CENSUS_REQUEST")
        const response = await universityCensusesService.editUniversityCensus(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_UNIVERSITY_CENSUS_SUCCESS", data)
                break
            default:
                commit("EDIT_UNIVERSITY_CENSUS_ERROR", data)
        }
        return response
    },
    async deleteUniversityCensus({ commit }, uuid) {
        commit("DELETE_UNIVERSITY_CENSUS_REQUEST")
        const response = await universityCensusesService.deleteUniversityCensus(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("DELETE_UNIVERSITY_CENSUS_SUCCESS", uuid) : commit("DELETE_UNIVERSITY_CENSUS_DELETE", data)
                break
            default:
                commit("DELETE_UNIVERSITY_CENSUS_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_UNIVERSITY_CENSUSES_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_CENSUSES_SUCCESS]: (state, data) => {
        let mdata = [...data.university_census]
        state.universityCensuses = mdata
        state.loading = false
    },
    [GET_UNIVERSITY_CENSUSES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_UNIVERSITY_CENSUS_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_CENSUS_SUCCESS]: (state, data) => {
        let mdata = { ...data.university_census }
        state.universityCensus = mdata
        state.loading = false
    },
    [GET_UNIVERSITY_CENSUS_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_UNIVERSITY_CENSUS_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_UNIVERSITY_CENSUS_SUCCESS]: (state, data) => {
        const { university_census } = data
        university_census.course_weeks = parseInt(university_census.course_weeks)
        state.universityCensuses = addNewFilter(state.universityCensuses, [university_census])
        state.loading = false
    },
    [CREATE_UNIVERSITY_CENSUS_ERROR]: state => {
        state.loading = false
    },

    [EDIT_UNIVERSITY_CENSUS_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_UNIVERSITY_CENSUS_SUCCESS]: (state, data) => {
        state.universityCensuses = state.universityCensuses.filter(i => i.uuid != data.university_census.uuid)
        state.universityCensuses.push(data.university_census)
        state.loading = false
    },
    [EDIT_UNIVERSITY_CENSUS_ERROR]: (state, data) => {
        state.loading = false
    },

    [DELETE_UNIVERSITY_CENSUS_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_UNIVERSITY_CENSUS_SUCCESS]: (state, uuid) => {
        state.universityCensuses = state.universityCensuses.filter(i => i.uuid != uuid)
        state.loading = false
    },
    [DELETE_UNIVERSITY_CENSUS_ERROR]: (state, data) => {
        state.loading = false
    },

}

export const universityCensus = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}