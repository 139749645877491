import {
	GET_COACH_EMPLOYMENTS_REQUEST,
	GET_COACH_EMPLOYMENTS_SUCCESS,
	GET_COACH_EMPLOYMENTS_ERROR,
	GET_COACH_EMPLOYMENT_REQUEST,
	GET_COACH_EMPLOYMENT_SUCCESS,
	GET_COACH_EMPLOYMENT_ERROR,
	CREATE_COACH_EMPLOYMENT_REQUEST,
	CREATE_COACH_EMPLOYMENT_SUCCESS,
	CREATE_COACH_EMPLOYMENT_ERROR,
	EDIT_COACH_EMPLOYMENT_REQUEST,
	EDIT_COACH_EMPLOYMENT_SUCCESS,
	EDIT_COACH_EMPLOYMENT_ERROR,
	DELETE_COACH_EMPLOYMENT_REQUEST,
	DELETE_COACH_EMPLOYMENT_SUCCESS,
	DELETE_COACH_EMPLOYMENT_ERROR,
	CLEAR_COACH_EMPLOYMENT_STATE
} from "@/actions"

import {
	coachEmploymentService
} from "@/services"

import {
	unpackCoach
} from "@/modules"

import { isEmpty, addNewFilter, decodeLaravelValidationErrors } from "@/utilities"

const state = {
	coachEmployments: [],
	coachEmployment: {},
	loading: false,
	loadedOnce: false
}

const getters = {
	cEmpExpLoadedOnce: state => state.loadedOnce,
	coachEmploymentExperiences: state => {
		return state.coachEmployments
	},
	otherEmployers: state => state.coachEmployments.filter(ce => (!isEmpty(ce.other_employer) && isEmpty(ce.other_approved)))
}

const actions = {
	async getCoachEmployments({ commit }, params) {
		commit("GET_COACH_EMPLOYMENTS_REQUEST")
		const response = await coachEmploymentService.getCoachEmployments(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					commit("GET_COACH_EMPLOYMENTS_SUCCESS", data)
				} else {
					commit("GET_COACH_EMPLOYMENTS_ERROR", data)
				}
				break
			default:
				commit("GET_COACH_EMPLOYMENTS_ERROR", data)
		}
		return response
	},
	async getCoachEmployment({ commit }, params) {
		commit("GET_COACH_EMPLOYMENT_REQUEST")
		const response = await coachEmploymentService.getCoachEmployment(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COACH_EMPLOYMENT_SUCCESS", data)
				break
			default:
				commit("GET_COACH_EMPLOYMENT_ERROR", data)
				break
		}
		return response
	},
	async createCoachEmployment({ commit, dispatch }, params) {
		commit("CREATE_COACH_EMPLOYMENT_REQUEST")
		const response = await coachEmploymentService.createCoachEmployment(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_COACH_EMPLOYMENT_SUCCESS", data) : commit("CREATE_COACH_EMPLOYMENT_ERROR", data);
				data.success ?
					dispatch('alert/createAlertSuccess', "Employment experience created.", { root: true }) :
					dispatch('alert/createAlertErrors', !isEmpty(data.errors) ? decodeLaravelValidationErrors(data.errors) : "Oops! Something went wrong.", { root: true });
				break
			default:
				commit("CREATE_COACH_EMPLOYMENT_ERROR", data)
		}
		return response
	},
	async editCoachEmployment({ commit, dispatch }, params, uuid) {
		commit("EDIT_COACH_EMPLOYMENT_REQUEST")
		const response = await coachEmploymentService.editCoachEmployment(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_COACH_EMPLOYMENT_SUCCESS", data) : commit("EDIT_COACH_EMPLOYMENT_ERROR", data);
				data.success ?
					dispatch('alert/createAlertSuccess', "Employment experience updated.", { root: true }) :
					dispatch('alert/createAlertErrors', !isEmpty(data.errors) ? decodeLaravelValidationErrors(data.errors) : "Oops! Something went wrong.", { root: true });
				break
			default:
				commit("EDIT_COACH_EMPLOYMENT_ERROR", data)
		}
		return response
	},
	async deleteCoachEmployment({ commit, dispatch }, uuid) {
		commit("DELETE_COACH_EMPLOYMENT_REQUEST")
		const response = await coachEmploymentService.deleteCoachEmployment(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_COACH_EMPLOYMENT_SUCCESS", uuid) : null;
				data.success ?
					dispatch('alert/createAlertSuccess', "Employment experience deleted.", { root: true }) :
					dispatch('alert/createAlertErrors', !isEmpty(data.errors) ? decodeLaravelValidationErrors(data.errors) : "Oops! Something went wrong.", { root: true });
				break
			default:
				commit("DELETE_COACH_EMPLOYMENT_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_COACH_EMPLOYMENTS_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_EMPLOYMENTS_SUCCESS]: (state, data) => {
		const coachEmployments = [...data.coach_employments]
		state.coachEmployments = coachEmployments
		state.loading = false
		state.loadedOnce = true
	},
	[GET_COACH_EMPLOYMENTS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_COACH_EMPLOYMENT_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_EMPLOYMENT_SUCCESS]: (state, data) => {
		const coachEmployment = { ...data.coach_employment }
		state.coachEmployment = coachEmployment
		state.loading = false
	},
	[GET_COACH_EMPLOYMENT_ERROR]: (state, data) => {
		state.loading = false
	},
	[CREATE_COACH_EMPLOYMENT_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COACH_EMPLOYMENT_SUCCESS]: (state, data) => {
		const { coach_employment } = data
		state.coachEmployments = addNewFilter(state.coachEmployments, [coach_employment])
		state.loading = false
	},
	[CREATE_COACH_EMPLOYMENT_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COACH_EMPLOYMENT_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COACH_EMPLOYMENT_SUCCESS]: (state, data) => {
		const { coach_employment } = data
		state.coachEmployments = state.coachEmployments.map(ce => ce.uuid == coach_employment.uuid ? coach_employment : ce)
		if (state.coachEmployment.uuid == coach_employment.uuid) {
			state.coachEmployment = coach_employment
		}
		state.loading = false
	},
	[EDIT_COACH_EMPLOYMENT_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COACH_EMPLOYMENT_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COACH_EMPLOYMENT_SUCCESS]: (state, uuid) => {
		state.coachEmployments = state.coachEmployments.filter(ce => ce.uuid !== uuid)
		state.loading = false
	},
	[DELETE_COACH_EMPLOYMENT_ERROR]: (state, data) => {
		state.loading = false
	},

	[CLEAR_COACH_EMPLOYMENT_STATE]: (state) => {
		state.coachEmployments = []
		state.coachEmployment = {}
		state.loading = false
		state.loadedOnce = false
	}
}

export const coachEmployment = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
