export const GET_COACH_SPECIALTIES_REQUEST = "GET_COACH_SPECIALTIES_REQUEST"
export const GET_COACH_SPECIALTIES_SUCCESS = "GET_COACH_SPECIALTIES_SUCCESS"
export const GET_COACH_SPECIALTIES_ERROR = "GET_COACH_SPECIALTIES_ERROR"
export const GET_COACH_SPECIALTY_REQUEST = "GET_COACH_SPECIALTY_REQUEST"
export const GET_COACH_SPECIALTY_SUCCESS = "GET_COACH_SPECIALTY_SUCCESS"
export const GET_COACH_SPECIALTY_ERROR = "GET_COACH_SPECIALTY_ERROR"
export const CREATE_COACH_SPECIALTY_REQUEST = "CREATE_COACH_SPECIALTY_REQUEST"
export const CREATE_COACH_SPECIALTY_SUCCESS = "CREATE_COACH_SPECIALTY_SUCCESS"
export const CREATE_COACH_SPECIALTY_ERROR = "CREATE_COACH_SPECIALTY_ERROR"
export const EDIT_COACH_SPECIALTY_REQUEST = "EDIT_COACH_SPECIALTY_REQUEST"
export const EDIT_COACH_SPECIALTY_SUCCESS = "EDIT_COACH_SPECIALTY_SUCCESS"
export const EDIT_COACH_SPECIALTY_ERROR = "EDIT_COACH_SPECIALTY_ERROR"
export const DELETE_COACH_SPECIALTY_REQUEST = "DELETE_COACH_SPECIALTY_REQUEST"
export const DELETE_COACH_SPECIALTY_SUCCESS = "DELETE_COACH_SPECIALTY_SUCCESS"
export const DELETE_COACH_SPECIALTY_ERROR = "DELETE_COACH_ERROR"
export const CLEAR_COACH_SPECIALTY_STATE = "CLEAR_COACH_SPECIALTY_STATE"