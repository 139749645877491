export const GET_SCREENING_SESSIONS_REQUEST = "GET_SCREENING_SESSIONS_REQUEST"
export const GET_SCREENING_SESSIONS_SUCCESS = "GET_SCREENING_SESSIONS_SUCCESS"
export const GET_SCREENING_SESSIONS_ERROR = "GET_SCREENING_SESSIONS_ERROR"
export const GET_SCREENING_SESSION_REQUEST = "GET_SCREENING_SESSION_REQUEST"
export const GET_SCREENING_SESSION_SUCCESS = "GET_SCREENING_SESSION_SUCCESS"
export const GET_SCREENING_SESSION_ERROR = "GET_SCREENING_SESSION_ERROR"
export const CREATE_SCREENING_SESSION_REQUEST = "CREATE_SCREENING_SESSION_REQUEST"
export const CREATE_SCREENING_SESSION_SUCCESS = "CREATE_SCREENING_SESSION_SUCCESS"
export const CREATE_SCREENING_SESSION_ERROR = "CREATE_SCREENING_SESSION_ERROR"
export const EDIT_SCREENING_SESSION_REQUEST = "EDIT_SCREENING_SESSION_REQUEST"
export const EDIT_SCREENING_SESSION_SUCCESS = "EDIT_SCREENING_SESSION_SUCCESS"
export const EDIT_SCREENING_SESSION_ERROR = "EDIT_SCREENING_SESSION_ERROR"
export const DELETE_SCREENING_SESSION_REQUEST = "DELETE_SCREENING_SESSION_REQUEST"
export const DELETE_SCREENING_SESSION_SUCCESS = "DELETE_SCREENING_SESSION_SUCCESS"
export const DELETE_SCREENING_SESSION_ERROR = "DELETE_SCREENING_SESSION_ERROR"