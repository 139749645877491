import {
	GET_DEGREES_REQUEST,
	GET_DEGREES_SUCCESS,
	GET_DEGREES_ERROR,
	GET_DEGREE_REQUEST,
	GET_DEGREE_SUCCESS,
	GET_DEGREE_ERROR,
	CREATE_DEGREE_REQUEST,
	CREATE_DEGREE_SUCCESS,
	CREATE_DEGREE_ERROR,
	EDIT_DEGREE_REQUEST,
	EDIT_DEGREE_SUCCESS,
	EDIT_DEGREE_ERROR,
	DELETE_DEGREE_REQUEST,
	DELETE_DEGREE_SUCCESS,
	DELETE_DEGREE_ERROR
} from "@/actions"

import {
	degreeService
} from "@/services"

import { isEmpty } from "@/utilities"

const state = {
	degrees: [],
	degree: {},
	loading: false,
	loadedOnce: true
}

const getters = {
	degreesLoadedOnce: state => state.loadedOnce,
	hasDegrees: state => {
		return !isEmpty(state.degrees)
	},
	degreeSelectOptions: state => state.degrees.map(degree => ({
		value: degree.uuid,
		label: degree.label,
		attrs: {
			'data-abbreviation': degree.abbreviation,
			'data-level': degree.level
		}
	})).sort((a, b) => {
		var nameA = a.label.toUpperCase(); // ignore upper and lowercase
		var nameB = b.label.toUpperCase(); // ignore upper and lowercase
		if (nameA < nameB) {
			return -1; //nameA comes first
		}
		if (nameA > nameB) {
			return 1; // nameB comes first
		}
		return 0;  // names must be equal
	}),
	degreeSelectOptionsLevels: state => noOf => {
		let filteredLevels = state.degrees.filter(i => {
			if (!isEmpty(noOf)) {
				return i.level <= noOf
			}
			return true
		});
		
		let opts = filteredLevels.sort((a, b) => {
			var nameA = a.label.toUpperCase(); // ignore upper and lowercase
			var nameB = b.label.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1; //nameA comes first
			}
			if (nameA > nameB) {
				return 1; // nameB comes first
			}
			return 0;  // names must be equal
		})
			.sort((a, b) => a.level > b.level)
			.map(degree => ({ value: degree.uuid, label: `${degree.label}` }))

		return opts;
	},
	degreeMultiSelectOptions: state => state.degrees.map(d => ({ value: d.uuid, text: d.label })).sort((a, b) => {
		var nameA = a.text.toUpperCase(); // ignore upper and lowercase
		var nameB = b.text.toUpperCase(); // ignore upper and lowercase
		if (nameA < nameB) {
			return -1; //nameA comes first
		}
		if (nameA > nameB) {
			return 1; // nameB comes first
		}
		return 0;  // names must be equal
	}),
	degreeOptionFilter: state => uuid => state.degrees.find(degree => {
		if (degree.uuid == uuid) return { value: degree.uuid, label: degree.label }
	}),
	degreeOptionFilterUuid: (state) => uuid => state.degrees.find(deg => deg.uuid == uuid)
}

const actions = {
	async getDegrees({ commit }) {
		commit("GET_DEGREES_REQUEST")
		const response = await degreeService.getDegrees();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_DEGREES_SUCCESS", data)
				break
			default:
				commit("GET_DEGREES_ERROR", data)
		}
		return response
	},
	async getDegree({ commit }, params) {
		commit("GET_DEGREE_REQUEST")
		const response = await degreeService.getDegree(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_DEGREE_SUCCESS", data)
				break
			default:
				commit("GET_DEGREE_ERROR", data)
				break
		}
		return response
	},
	async createDegree({ commit }, params) {
		commit("CREATE_DEGREE_REQUEST")
		const response = await degreeService.createDegree(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_DEGREE_SUCCESS", data)
				break
			default:
				commit("CREATE_DEGREE_ERROR", data)
		}
		return response
	},
	async editDegree({ commit }, params, uuid) {
		commit("EDIT_DEGREE_REQUEST")
		const response = await degreeService.editDegree(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_DEGREE_SUCCESS", data)
				break
			default:
				commit("EDIT_DEGREE_ERROR", data)
		}
		return response
	},
	async deleteDegree({ commit }, params) {
		commit("DELETE_DEGREE_REQUEST")
		const response = await degreeService.deleteDegree(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_DEGREE_SUCCESS", data)
				break
			default:
				commit("DELETE_DEGREE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_DEGREES_REQUEST]: state => {
		state.loading = true
	},
	[GET_DEGREES_SUCCESS]: (state, data) => {
		const degrees = data.degrees
		state.degrees = degrees
		state.loading = false
		state.loadedOnce = true
	},
	[GET_DEGREES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_DEGREE_REQUEST]: state => {
		state.loading = true
	},
	[GET_DEGREE_SUCCESS]: (state, data) => {
		const degree = { ...data.degree }
		state.degree = degree
		state.loading = false
	},
	[GET_DEGREE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_DEGREE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_DEGREE_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[CREATE_DEGREE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_DEGREE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_DEGREE_SUCCESS]: state => {
		state.loading = false
	},
	[EDIT_DEGREE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_DEGREE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_DEGREE_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_DEGREE_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const degree = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}