import {
    apiRequest,
    processInput,
    processQueryParams
  } from "@/utilities"
  
  const moduleUrl = "program-assistant-evaluations";

  export const paEvaluationService = {
    async getPaEvaluations(params) {
  
      const getPaEvaluationsRequest = {
        method: "GET",
        params: processQueryParams(params)
      }
  
      return await apiRequest(`/api/${moduleUrl}`, getPaEvaluationsRequest)
    },
    async createPaEvaluation(params) {
  
      const createData = processInput(params)
      const postPaEvaluationRequest = {
        method: "POST",
        data: createData
      }
      return await apiRequest(`/api/${moduleUrl}`, postPaEvaluationRequest)
    },
    async getPaEvaluation(uuid) {
  
      const getPaEvaluationRequest = {
        method: "GET"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, getPaEvaluationRequest);
    },
    async editPaEvaluation(data, uuid) {
  
      let editData = processInput(data)
      editData.append("_method", "PATCH")
  
      const updatePaEvaluationRequest = {
        method: "POST",
        data: editData
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, updatePaEvaluationRequest);
    },
    async deletePaEvaluation(uuid) {
  
      const deletePaEvaluationRequest = {
        method: "DELETE"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, deletePaEvaluationRequest);
    }
  }
  