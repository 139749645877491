import {
    apiRequest,
    processInput
} from "@/utilities"

export const universityRegionalAccreditorsService = {
    async getUniversityRegionalAccreditors() {
        const getUniversityRegionalAccreditorsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/university-regional-accreditors", getUniversityRegionalAccreditorsRequest)
    },
    async createUniversityRegionalAccreditor(params) {
        const createData = processInput(params);
        const postUniversityRegionalAccreditorRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/university-regional-accreditors", postUniversityRegionalAccreditorRequest)
    },
    async getUniversityRegionalAccreditor(uuid) {

        const getUniversityRegionalAccreditorRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/university-regional-accreditors/${uuid}`, getUniversityRegionalAccreditorRequest);
    },
    async editUniversityRegionalAccreditor(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityRegionalAccreditorRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/university-regional-accreditors/${uuid}`, updateUniversityRegionalAccreditorRequest);
    },
    async deleteUniversityRegionalAccreditor(uuid) {

        const deleteUniversityRegionalAccreditorRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/university-regional-accreditors/${uuid}`, deleteUniversityRegionalAccreditorRequest);
    }
}