export const GET_UNIVERSITY_PROCEDURES_REQUEST = "GET_UNIVERSITY_PROCEDURES_REQUEST"
export const GET_UNIVERSITY_PROCEDURES_SUCCESS = "GET_UNIVERSITY_PROCEDURES_SUCCESS"
export const GET_UNIVERSITY_PROCEDURES_ERROR = "GET_UNIVERSITY_PROCEDURES_ERROR"
export const GET_UNIVERSITY_PROCEDURE_REQUEST = "GET_UNIVERSITY_PROCEDURE_REQUEST"
export const GET_UNIVERSITY_PROCEDURE_SUCCESS = "GET_UNIVERSITY_PROCEDURE_SUCCESS"
export const GET_UNIVERSITY_PROCEDURE_ERROR = "GET_UNIVERSITY_PROCEDURE_ERROR" 
export const CREATE_UNIVERSITY_PROCEDURE_REQUEST = "CREATE_UNIVERSITY_PROCEDURE_REQUEST"
export const CREATE_UNIVERSITY_PROCEDURE_SUCCESS = "CREATE_UNIVERSITY_PROCEDURE_SUCCESS"
export const CREATE_UNIVERSITY_PROCEDURE_ERROR = "CREATE_UNIVERSITY_PROCEDURE_ERROR"
export const EDIT_UNIVERSITY_PROCEDURE_REQUEST = "EDIT_UNIVERSITY_PROCEDURE_REQUEST"
export const EDIT_UNIVERSITY_PROCEDURE_SUCCESS = "EDIT_UNIVERSITY_PROCEDURE_SUCCESS"
export const EDIT_UNIVERSITY_PROCEDURE_ERROR = "EDIT_UNIVERSITY_PROCEDURE_ERROR"
export const DELETE_UNIVERSITY_PROCEDURE_REQUEST = "DELETE_UNIVERSITY_PROCEDURE_REQUEST"
export const DELETE_UNIVERSITY_PROCEDURE_SUCCESS = "DELETE_UNIVERSITY_PROCEDURE_SUCCESS"
export const DELETE_UNIVERSITY_PROCEDURE_ERROR = "DELETE_UNIVERSITY_PROCEDURE_ERROR"
export const UNIVERSITY_PROCEDURE_PROGRESS_CHECKED = "UNIVERSITY_PROCEDURE_PROGRESS_CHECKED"