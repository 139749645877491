export const GET_POINT_OF_CONTACTS_REQUEST = "GET_POINT_OF_CONTACTS_REQUEST"
export const GET_POINT_OF_CONTACTS_SUCCESS = "GET_POINT_OF_CONTACTS_SUCCESS"
export const GET_POINT_OF_CONTACTS_ERROR = "GET_POINT_OF_CONTACTS_ERROR"
export const GET_POINT_OF_CONTACT_REQUEST = "GET_POINT_OF_CONTACT_REQUEST"
export const GET_POINT_OF_CONTACT_SUCCESS = "GET_POINT_OF_CONTACT_SUCCESS"
export const GET_POINT_OF_CONTACT_ERROR = "GET_POINT_OF_CONTACT_ERROR" 
export const CREATE_POINT_OF_CONTACT_REQUEST = "CREATE_POINT_OF_CONTACT_REQUEST"
export const CREATE_POINT_OF_CONTACT_SUCCESS = "CREATE_POINT_OF_CONTACT_SUCCESS"
export const CREATE_POINT_OF_CONTACT_ERROR = "CREATE_POINT_OF_CONTACT_ERROR"
export const EDIT_POINT_OF_CONTACT_REQUEST = "EDIT_POINT_OF_CONTACT_REQUEST"
export const EDIT_POINT_OF_CONTACT_SUCCESS = "EDIT_POINT_OF_CONTACT_SUCCESS"
export const EDIT_POINT_OF_CONTACT_ERROR = "EDIT_POINT_OF_CONTACT_ERROR"
export const DELETE_POINT_OF_CONTACT_REQUEST = "DELETE_POINT_OF_CONTACT_REQUEST"
export const DELETE_POINT_OF_CONTACT_SUCCESS = "DELETE_POINT_OF_CONTACT_SUCCESS"
export const BROADCAST_DELETE_POINT_OF_CONTACT_SUCCESS = "BROADCAST_DELETE_POINT_OF_CONTACT_SUCCESS"
export const DELETE_POINT_OF_CONTACT_ERROR = "DELETE_POINT_OF_CONTACT_ERROR"