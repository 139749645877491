export const GET_PA_EVALUATIONS_REQUEST = "GET_PA_EVALUATIONS_REQUEST"
export const GET_PA_EVALUATIONS_SUCCESS = "GET_PA_EVALUATIONS_SUCCESS"
export const GET_PA_EVALUATIONS_ERROR = "GET_PA_EVALUATIONS_ERROR"
export const GET_PA_EVALUATION_REQUEST = "GET_PA_EVALUATION_REQUEST"
export const GET_PA_EVALUATION_SUCCESS = "GET_PA_EVALUATION_SUCCESS"
export const GET_PA_EVALUATION_ERROR = "GET_PA_EVALUATION_ERROR"
export const CREATE_PA_EVALUATION_REQUEST = "CREATE_PA_EVALUATION_REQUEST"
export const CREATE_PA_EVALUATION_SUCCESS = "CREATE_PA_EVALUATION_SUCCESS"
export const CREATE_PA_EVALUATION_ERROR = "CREATE_PA_EVALUATION_ERROR"
export const EDIT_PA_EVALUATION_REQUEST = "EDIT_PA_EVALUATION_REQUEST"
export const EDIT_PA_EVALUATION_SUCCESS = "EDIT_PA_EVALUATION_SUCCESS"
export const EDIT_PA_EVALUATION_ERROR = "EDIT_PA_EVALUATION_ERROR"
export const DELETE_PA_EVALUATION_REQUEST = "DELETE_PA_EVALUATION_REQUEST"
export const DELETE_PA_EVALUATION_SUCCESS = "DELETE_PA_EVALUATION_SUCCESS"
export const DELETE_PA_EVALUATION_ERROR = "DELETE_PA_EVALUATION_ERROR"