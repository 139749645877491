import {
    apiRequest,
    processInput,
} from "@/utilities"

export const coachRequestService = {
    // async getCoachRequests(params) {
    //     let getParams = processQueryParams(params)
    //     const getCoachRequestsRequest = {
    //         method: "GET",
    //         params: getParams
    //     }
    //     return await apiRequest("/api/coach-requests", getCoachRequestsRequest)
    // },
    // async getCoachRequest(uuid) {
    //     const getCoachRequestRequest = {
    //         method: "GET"
    //     }
    //     return await apiRequest(`/api/coach-requests/${uuid}`, getCoachRequestRequest)
    // },
    async createCoachRequest(params) {
        const createData = processInput(params)
        const createCoachRequestRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-request", createCoachRequestRequest)
    },
    // async editCoachRequest(data, uuid) {
    //     let editData = processInput(data)
    //     editData.append("_method", "PATCH")
    //     const editCoachRequestRequest = {
    //         method: "POST",
    //         data: editData
    //     }
    //     return await apiRequest(`/api/coach-requests/${uuid}`, editCoachRequestRequest)
    // },
    // async deleteCoachRequest(uuid) {
    //     const deleteCoachRequestRequest = {
    //         method: "DELETE"
    //     }
    //     return await apiRequest(`/api/coach-requests/${uuid}`, deleteCoachRequestRequest)
    // },
    async uploadCoachRequest(params) {
        let uploadData = processInput(params)
        const uploadCoachRequestRequest = {
            method: "POST",
            data: uploadData
        }
        return await apiRequest(`/api/coach-requests-upload`, uploadCoachRequestRequest)
    }
}