import {
	GET_COURSES_REQUEST,
	GET_COURSES_SUCCESS,
	GET_COURSES_ERROR,
	GET_COURSE_REQUEST,
	GET_COURSE_SUCCESS,
	GET_COURSE_ERROR,
	CREATE_COURSE_REQUEST,
	CREATE_COURSE_SUCCESS,
	CREATE_COURSE_ERROR,
	EDIT_COURSE_REQUEST,
	EDIT_COURSE_SUCCESS,
	EDIT_COURSE_ERROR,
	DELETE_COURSE_REQUEST,
	DELETE_COURSE_SUCCESS,
	DELETE_COURSE_ERROR,
} from "@/actions"

import {
	courseService
} from "@/services"

import { unpackCourseStart } from "@/modules"
import { addNewFilter, isEmpty, removeWhiteSpaceSlug } from "@/utilities"

function mapArray(state, getters, rootState, rootGetters, data) {
	let allCourses = data
	if (isEmpty(allCourses)) {
		return []
	}
	return allCourses.map((c) => {
		let programByUuid = rootGetters['program/programByUuid'];
		let university_program = programByUuid(c.university_programs_uuid)
		let university = {}, program = {}, pc_level = {},
			sub_course = {}, program_assistant = {}, lead_coach = {};

		if (isEmpty(university_program)) {
			if (!isEmpty(university_program.university)) {
				university = university_program.university
				delete university_program.university
			}
			if (!isEmpty(university_program.program)) {
				program = university_program.program
				delete university_program.program
			}
			if (!isEmpty(university_program.pc_level)) {
				pc_level = university_program.pc_level
				delete university_program.pc_level
			}
			if (!isEmpty(university_program.sub_course)) {
				sub_course = university_program.sub_course
				delete university_program.sub_course
			}
			if (!isEmpty(university_program.program_assistant)) {
				program_assistant = university_program.program_assistant
				delete university_program.program_assistant
			}
			if (!isEmpty(university_program.lead_coach)) {
				lead_coach = university_program.lead_coach
				delete university_program.lead_coach
			}
		}
		return {
			...c,
			university_program,
			university,
			program,
			pc_level,
			sub_course,
			program_assistant,
			lead_coach,
		}
	});
}

const state = {
	courses: [],
	course: {},
	loading: false,
	loadedOnce: false,
	universityProgramsLoaded: []
}

const getters = {
	coursesLoading: state => state.loading,
	coursesLoadedOnce: state => state.loadedOnce,
	courses: state => state.courses,
	courseByNumber: state => (univProgUuids, course_number) => state.courses.find(c => univProgUuids.includes(c.university_programs_uuid) && removeWhiteSpaceSlug(c.number) == removeWhiteSpaceSlug(course_number)),
	courseFilter: state => univProgUuid => state.courses.filter(c => c.university_programs_uuid == univProgUuid),
	courseFilterArray: state => univProgUuid => {
		if (isEmpty(univProgUuid)) return []
		return state.courses.filter(c => {
			return univProgUuid.includes(c.university_programs_uuid)
		})
	},
	programCourseUuids: state => univProgUuid => state.courses.filter(c => univProgUuid.some(upUuid => upUuid == c.university_programs_uuid)).map(c => c.uuid),
	courseFindUuid: state => uuid => state.courses.find(c => c.uuid == uuid),
}

const actions = {
	async getCourses({ state, commit }, params) {
		let ups = params?.university_programs_uuids ?? [];
		params.university_programs_uuids = ups.filter(uuid => !state.universityProgramsLoaded.includes(uuid));
		commit("GET_COURSES_REQUEST");
		const response = await courseService.getCourses(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COURSES_SUCCESS", data);
				commit("UPDATE_LOADED_PROGRAMS", params.university_programs_uuids);
				break
			default:
				commit("GET_COURSES_ERROR", data)
		}
		return response
	},
	async getCourse({ commit }, params) {
		commit("GET_COURSE_REQUEST")
		const response = await courseService.getCourse(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COURSE_SUCCESS", data)
				break
			default:
				commit("GET_COURSE_ERROR", data)
				break
		}
		return response
	},
	async createCourse({ commit, dispatch }, params) {
		commit("CREATE_COURSE_REQUEST")
		const response = await courseService.createCourse(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_COURSE_SUCCESS", data) : commit("CREATE_COURSE_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Course created!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("CREATE_COURSE_ERROR", data)
		}
		return response
	},
	async editCourse({ commit, dispatch }, params) {
		commit("EDIT_COURSE_REQUEST")
		const response = await courseService.editCourse(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data?.success ? commit("EDIT_COURSE_SUCCESS", data) : null;
				data?.success ? dispatch('alert/createAlertSuccess', "Course updated!", { root: true }) : null;
				break
			default:
				commit("EDIT_COURSE_ERROR", data)
		}
		return response
	},
	async deleteCourse({ commit, dispatch }, uuid) {
		commit("DELETE_COURSE_REQUEST")
		const response = await courseService.deleteCourse(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_COURSE_SUCCESS", { uuid }) : commit("DELETE_COURSE_ERROR")
				data.success ? dispatch('alert/createAlertSuccess', "Course deleted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("DELETE_COURSE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_COURSES_REQUEST]: state => {
		state.loading = true
	},
	[GET_COURSES_SUCCESS]: (state, data) => {
		state.courses = addNewFilter(state.courses, data.courses)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_COURSES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	UPDATE_LOADED_PROGRAMS: (state, proUuids) => {
		state.universityProgramsLoaded = state.universityProgramsLoaded.concat(proUuids);
	},
	[GET_COURSE_REQUEST]: state => {
		state.loading = true
	},
	[GET_COURSE_SUCCESS]: (state, data) => {
		const { course }  = data
		state.courses = addNewFilter(state.courses, [course])
		state.loading = false
	},
	[GET_COURSE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COURSE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COURSE_SUCCESS]: (state, data) => {
		const { course } = data;
		state.courses = addNewFilter(state.courses, [course])
		state.loading = false
	},
	[CREATE_COURSE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COURSE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COURSE_SUCCESS]: (state, data) => {
		const { course } = data;
		state.courses = addNewFilter(state.courses, [course])
		state.loading = false;
	},
	[EDIT_COURSE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COURSE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COURSE_SUCCESS]: (state, data) => {
		const { uuid } = data;
		let course = state.courses.find(i => i.uuid == uuid)
		if(!isEmpty(course)){
			course.deleted_at = new Date().toISOString()
			course.is_deleted = true
			state.courses = state.courses.filter(c => c.uuid !== uuid)
			state.courses.push(course)
		}
		state.loading = false
	},
	[DELETE_COURSE_ERROR]: (state) => {
		state.loading = false
	}
}

export const course = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

export function unpackCourse(c, commit) {
	if (!isEmpty(c.comments)) {
		commit('comments/GET_COMMENTS_SUCCESS', { comments: c.comments }, { root: true })
		delete c.comments
	}
	if (!isEmpty(c.course_starts)) {
		let course_starts = c.course_starts.map(cs => unpackCourseStart(cs, commit))
		commit("courseStart/GET_COURSE_STARTS_SUCCESS", { course_starts }, { root: true })
		delete c.course_starts
	}

	return c
}
