import {
    apiRequest,
    processInput
} from "@/utilities"

export const universityCensusesService = {
    async getUniversityCensuses() {
        const getUniversityCensusesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/university-census", getUniversityCensusesRequest)
    },
    async createUniversityCensus(params) {
        const createData = processInput(params);
        const postUniversityCensusRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/university-census", postUniversityCensusRequest)
    },
    async getUniversityCensus(uuid) {

        const getUniversityCensusRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/university-census/${uuid}`, getUniversityCensusRequest);
    },
    async editUniversityCensus(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityCensusRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/university-census/${uuid}`, updateUniversityCensusRequest);
    },
    async deleteUniversityCensus(uuid) {

        const deleteUniversityCensusRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/university-census/${uuid}`, deleteUniversityCensusRequest);
    }
}