export const coachRegistration = {
    path: '/coach-registration',
    name: 'coach-registration',
    component: () => import("@/pages/basic/CoachRegistration.vue"),
    meta: {
        authRequired: false,
    },
}

export const coachRegistrationResume = {
    path: '/coach-registration-resume',
    name: 'coach-registration-resume',
    component: () => import("@/pages/basic/CoachRegistrationResume.vue"),
    meta: {
        authRequired: false,
    },
}

export const universityPartnershipLink = {
    path: '/university-partnership',
    name: 'university-partnership-link',
    component: () => import("@/pages/basic/UniversityPartnershipLink.vue"),
    meta: {
        authRequired: false,
    },
}