export const GET_ADDRESS_TYPES_REQUEST = "GET_ADDRESS_TYPES_REQUEST"
export const GET_ADDRESS_TYPES_SUCCESS = "GET_ADDRESS_TYPES_SUCCESS"
export const GET_ADDRESS_TYPES_ERROR = "GET_ADDRESS_TYPES_ERROR"
export const GET_ADDRESS_TYPE_REQUEST = "GET_ADDRESS_TYPE_REQUEST"
export const GET_ADDRESS_TYPE_SUCCESS = "GET_ADDRESS_TYPE_SUCCESS"
export const GET_ADDRESS_TYPE_ERROR = "GET_ADDRESS_TYPE_ERROR"
export const CREATE_ADDRESS_TYPE_REQUEST = "CREATE_ADDRESS_TYPE_REQUEST"
export const CREATE_ADDRESS_TYPE_SUCCESS = "CREATE_ADDRESS_TYPE_SUCCESS"
export const CREATE_ADDRESS_TYPE_ERROR = "CREATE_ADDRESS_TYPE_ERROR"
export const EDIT_ADDRESS_TYPE_REQUEST = "EDIT_ADDRESS_TYPE_REQUEST"
export const EDIT_ADDRESS_TYPE_SUCCESS = "EDIT_ADDRESS_TYPE_SUCCESS"
export const EDIT_ADDRESS_TYPE_ERROR = "EDIT_ADDRESS_TYPE_ERROR"
export const DELETE_ADDRESS_TYPE_REQUEST = "DELETE_ADDRESS_TYPE_REQUEST"
export const DELETE_ADDRESS_TYPE_SUCCESS = "DELETE_ADDRESS_TYPE_SUCCESS"
export const DELETE_ADDRESS_TYPE_ERROR = "DELETE_ADDRESS_TYPE_ERROR"