export const GET_COACH_LMS_EXPERIENCES_REQUEST = "GET_COACH_LMS_EXPERIENCES_REQUEST"
export const GET_COACH_LMS_EXPERIENCES_SUCCESS = "GET_COACH_LMS_EXPERIENCES_SUCCESS"
export const GET_COACH_LMS_EXPERIENCES_ERROR = "GET_COACH_LMS_EXPERIENCES_ERROR"
export const GET_COACH_LMS_EXPERIENCE_REQUEST = "GET_COACH_LMS_EXPERIENCE_REQUEST"
export const GET_COACH_LMS_EXPERIENCE_SUCCESS = "GET_COACH_LMS_EXPERIENCE_SUCCESS"
export const GET_COACH_LMS_EXPERIENCE_ERROR = "GET_COACH_LMS_EXPERIENCE_ERROR"
export const CREATE_COACH_LMS_EXPERIENCE_REQUEST = "CREATE_COACH_LMS_EXPERIENCE_REQUEST"
export const CREATE_COACH_LMS_EXPERIENCE_SUCCESS = "CREATE_COACH_LMS_EXPERIENCE_SUCCESS"
export const CREATE_COACH_LMS_EXPERIENCE_ERROR = "CREATE_COACH_LMS_EXPERIENCE_ERROR"
export const EDIT_COACH_LMS_EXPERIENCE_REQUEST = "EDIT_COACH_LMS_EXPERIENCE_REQUEST"
export const EDIT_COACH_LMS_EXPERIENCE_SUCCESS = "EDIT_COACH_LMS_EXPERIENCE_SUCCESS"
export const EDIT_COACH_LMS_EXPERIENCE_ERROR = "EDIT_COACH_LMS_EXPERIENCE_ERROR"
export const DELETE_COACH_LMS_EXPERIENCE_REQUEST = "DELETE_COACH_LMS_EXPERIENCE_REQUEST"
export const DELETE_COACH_LMS_EXPERIENCE_SUCCESS = "DELETE_COACH_LMS_EXPERIENCE_SUCCESS"
export const BROADCAST_DELETE_COACH_LMS_EXPERIENCE_SUCCESS = "BROADCAST_DELETE_COACH_LMS_EXPERIENCE_SUCCESS"
export const DELETE_COACH_LMS_EXPERIENCE_ERROR = "DELETE_COACH_LMS_EXPERIENCE_ERROR"
export const CLEAR_COACH_LMS_EXPERIENCE_STATE = "CLEAR_COACH_LMS_EXPERIENCE_STATE"