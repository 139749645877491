import { render, staticRenderFns } from "./CustomElementCover.vue?vue&type=template&id=1941bfe1&scoped=true"
import script from "./CustomElementCover.vue?vue&type=script&lang=js"
export * from "./CustomElementCover.vue?vue&type=script&lang=js"
import style0 from "./CustomElementCover.vue?vue&type=style&index=0&id=1941bfe1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1941bfe1",
  null
  
)

export default component.exports