import {
    apiRequest,
    processInput
} from "@/utilities"

export const languageTypeService = {
    async getLanguageTypes() {
        const getLanguageTypesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/language-types", getLanguageTypesRequest)
    },
    async getLanguageType(uuid) {
        const getLanguageTypeRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/language-types/${uuid}`, getLanguageTypeRequest)
    },
    async createLanguageType(params) {
        const createData = processInput(params)
        const createLanguageTypeRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/language-types", createLanguageTypeRequest)
    },
    async editLanguageType(data, uuid) {
        let editData = processInput(data)
        data.append("_method", "PATCH")
        const editLanguageTypeRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/language-types/${uuid}`, editLanguageTypeRequest)
    },
    async deleteLanguageType(uuid) {
        const deleteLanguageTypeRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/language-types/${uuid}`, deleteLanguageTypeRequest)
    }
}