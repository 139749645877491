import {
    apiRequest,
    processInput
} from "@/utilities"

export const taskService = {
    async getTasks() {
        const getTasksRequest = {
            method: "GET"
        }
        return await apiRequest("/api/tasks", getTasksRequest)
    },
    async getTaskByUserUuid(uuid) {
        const getTaskRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/tasks/${uuid}`, getTaskRequest)
    },
    async createTask(params) {
        const createData = processInput(params)
        const createTaskRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/tasks", createTaskRequest)
    },
    async editTask(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editTaskRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/tasks/${uuid}`, editTaskRequest)
    },
    async deleteTask(uuid) {
        const deleteTaskRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/tasks/${uuid}`, deleteTaskRequest)
    }
}