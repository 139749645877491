import {
	GET_COACH_SCREENING_SESSIONS_REQUEST,
	GET_COACH_SCREENING_SESSIONS_SUCCESS,
	GET_COACH_SCREENING_SESSIONS_ERROR,
	GET_COACH_SCREENING_SESSION_REQUEST,
	GET_COACH_SCREENING_SESSION_SUCCESS,
	GET_COACH_SCREENING_SESSION_ERROR,
	CREATE_COACH_SCREENING_SESSION_REQUEST,
	CREATE_COACH_SCREENING_SESSION_SUCCESS,
	CREATE_COACH_SCREENING_SESSION_ERROR,
	EDIT_COACH_SCREENING_SESSION_REQUEST,
	EDIT_COACH_SCREENING_SESSION_SUCCESS,
	EDIT_COACH_SCREENING_SESSION_ERROR,
	DELETE_COACH_SCREENING_SESSION_REQUEST,
	DELETE_COACH_SCREENING_SESSION_SUCCESS,
	BROADCAST_DELETE_COACH_SCREENING_SESSION_SUCCESS,
	DELETE_COACH_SCREENING_SESSION_ERROR,
	CLEAR_COACH_SCREENING_SESSION_STATE
} from "@/actions"

import {
	coachScreeningSessionService
} from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

const state = {
	coachScreeningSessions: [],
	coachScreeningSession: {},
	loading: false
}

const getters = {
	coachScreeningSessions: state => state.coachScreeningSessions,
	fillableSession: (state, getters, rootState, rootGetters) => {
		if (isEmpty(state.coachScreeningSessions)) return
		let sortedSessions = state.coachScreeningSessions.sort((a, b) => new Date(a.created_at).toString() - new Date(b.created_at).toString)
		return sortedSessions[sortedSessions.length - 1]
	},
	currentCoachSceeningSessions: (state, getters, rootState, rootGetters) => {
		if (isEmpty(state.coachScreeningSessions)) return state.coachScreeningSessions
		return state.coachScreeningSessions.filter(css => css.coach.uuid == rootGetters["coaches/coach"]['uuid'])
	},
	screeningSessionsByCoachUuid: (state, getters, rootState, rootGetters) => cUuid => {
		return state.coachScreeningSessions.filter(css => css.coach.uuid == cUuid)
			.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
			.map(css => ({ screening_session: rootGetters["screeningSession/screeningSessionByUuid"](css.screening_session_uuid), ...css}))
	},
	respondedScreeningSessions: (state, getters, rootState, rootGetters) => {
		return state.coachScreeningSessions.filter(css => {

			const coachStatusGetter = rootGetters['coachStatus/coachStatusByCoachUuid']
			const coachStatus = coachStatusGetter(css.coach.uuid)
			if(isEmpty(coachStatus)) return false;

			const statusGetter = rootGetters['status/statusFilterByUuid'];
			const status = statusGetter(coachStatus.statuses_uuid)
			if(isEmpty(status)) return false;
			if(status.slug != 'candidate') return false;

			const notAllowedExps = [
				'did-not-pass',
				'candidate-declined',
				'pass-screening',
				'pending-background-check',
				'screening-session-not-accepted',
				'screening-session-reschedule'
			]
			const statusExpGetter = rootGetters['statusExplanation/statusExpFilterByUuid'];
			const statusExp = statusExpGetter(coachStatus.status_explanations_uuid)
			if(!isEmpty(statusExp?.slug)){
				if(notAllowedExps.includes(statusExp.slug)) return false;
			}

			return css.accepted == 1 || css.reschedule_requested == 1;
		}).map(i => {
			let screeningSessionByUuid = rootGetters['screeningSession/screeningSessionByUuid']
			let screening_session = screeningSessionByUuid(i.screening_session_uuid)
			let coach_name = i.coach.first_name + " " + i.coach.last_name
			return {
				...i,
				screening_session,
				coach_name
			}
		})
	},
	rescheduleRequests: (state, getters) => {
		let resheduleRequests = 0
		getters.currentCoachSceeningSessions.forEach(css => {
			if (!isEmpty(css.reschedule_requested) && css.reschedule_requested == 1) resheduleRequests++
		})
		return resheduleRequests
	},

	/**
	 * Get CoachScreeningSessions by ScreeningSession uuid
	 *
	 * @param {*} state
	 * @returns
	 */
	sessionCoaches: state => uuid => state.coachScreeningSessions.filter(css => css.screening_session_uuid == uuid)
}

const actions = {
	async getCoachScreeningSessions({ commit }, params) {

		commit("GET_COACH_SCREENING_SESSIONS_REQUEST")
		const response = await coachScreeningSessionService.getCoachScreeningSessions(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COACH_SCREENING_SESSIONS_SUCCESS", data)
				break
			default:
				commit("GET_COACH_SCREENING_SESSIONS_ERROR", data)
		}
		return response
	},
	async getCoachScreeningSession({ commit }, params) {
		commit("GET_COACH_SCREENING_SESSION_REQUEST")
		const response = await coachScreeningSessionService.getCoachScreeningSession(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COACH_SCREENING_SESSION_SUCCESS", data)
				break
			default:
				commit("GET_COACH_SCREENING_SESSION_ERROR", data)
				break
		}
		return response
	},
	async createCoachScreeningSession({ commit, dispatch }, params) {
		commit("CREATE_COACH_SCREENING_SESSION_REQUEST")
		const response = await coachScreeningSessionService.createCoachScreeningSession(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_COACH_SCREENING_SESSION_SUCCESS", data) : commit("CREATE_COACH_SCREENING_SESSION_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Screening Session Scheduled", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("CREATE_COACH_SCREENING_SESSION_ERROR", data)
		}
		return response
	},
	async editCoachScreeningSession({ commit, dispatch }, params) {
		commit("EDIT_COACH_SCREENING_SESSION_REQUEST")
		const uuid = params.uuid
		const response = await coachScreeningSessionService.editCoachScreeningSession(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_COACH_SCREENING_SESSION_SUCCESS", data) : commit("EDIT_COACH_SCREENING_SESSION_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Success", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("EDIT_COACH_SCREENING_SESSION_ERROR", data)
		}
		return response
	},
	async deleteCoachScreeningSession({ commit }, uuid) {
		commit("DELETE_COACH_SCREENING_SESSION_REQUEST")
		const response = await coachScreeningSessionService.deleteCoachScreeningSession(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_COACH_SCREENING_SESSION_SUCCESS", uuid)
				break
			default:
				commit("DELETE_COACH_SCREENING_SESSION_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_COACH_SCREENING_SESSIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_SCREENING_SESSIONS_SUCCESS]: (state, data) => {
		const coach_screening_sessions = data.coach_screening_sessions
		state.coachScreeningSessions = addNewFilter(state.coachScreeningSessions, coach_screening_sessions)
		state.loading = false
	},
	[GET_COACH_SCREENING_SESSIONS_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_COACH_SCREENING_SESSION_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_SCREENING_SESSION_SUCCESS]: (state, data) => {
    const { coach_screening_session } = data;
    state.coachScreeningSessions = addNewFilter(state.coachScreeningSessions, [coach_screening_session])
		state.loading = false
	},
	[GET_COACH_SCREENING_SESSION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COACH_SCREENING_SESSION_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COACH_SCREENING_SESSION_SUCCESS]: (state, data) => {
		const { coach_screening_session } = data
		state.coachScreeningSessions.push(coach_screening_session)
		state.loading = false
	},
	[CREATE_COACH_SCREENING_SESSION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COACH_SCREENING_SESSION_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COACH_SCREENING_SESSION_SUCCESS]: (state, data) => {
		const { coach_screening_session } = data
		state.coachScreeningSessions = state.coachScreeningSessions.map(css => css.uuid == coach_screening_session.uuid ? coach_screening_session : css)
	},
	[EDIT_COACH_SCREENING_SESSION_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COACH_SCREENING_SESSION_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COACH_SCREENING_SESSION_SUCCESS]: (state, data) => {
		const { coach_screening_session } = data
		if(!isEmpty(coach_screening_session)){
			state.coachScreeningSessions = state.coachScreeningSessions.filter(screening_session => screening_session.uuid !== coach_screening_session.uuid)
		}else if(typeof data == 'string'){
			state.coachScreeningSessions = state.coachScreeningSessions.filter(screening_session => screening_session.uuid !== data)
		}
		state.loading = false
	},
	[BROADCAST_DELETE_COACH_SCREENING_SESSION_SUCCESS]: (state, data) => {
		const { coach_screening_session } = data
		if(!isEmpty(coach_screening_session)){
			state.coachScreeningSessions = state.coachScreeningSessions.filter(screening_session => screening_session.uuid !== coach_screening_session.uuid)
		}
		state.loading = false
	},
	[DELETE_COACH_SCREENING_SESSION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CLEAR_COACH_SCREENING_SESSION_STATE]: (state) => {
		state.coachScreeningSessions = []
		state.coachScreeningSession = {}
		state.loading = false
		state.loadedOnce = false
	}

}

export const coachScreeningSession = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

export function unpackCoachScreeningSession(coachScreeningSession, commit) {

  if (!isEmpty(coachScreeningSession?.screening_session)) {
    const { screening_session } = coachScreeningSession;
    commit("screeningSession/GET_SCREENING_SESSION_SUCCESS", { screening_session }, { root: true });
  }


  delete coachScreeningSession?.screening_session;

  return coachScreeningSession;
}
