import {
    apiRequest,
    processInput
} from "@/utilities"

export const coachReferralSourceService = {
    async getCoachReferralSources() {
        const getCoachReferralSourcesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/coach-referral-sources", getCoachReferralSourcesRequest)
    },
    async getCoachReferralSource(uuid) {
        const getCoachReferralSourceRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-referral-sources/${uuid}`, getCoachReferralSourceRequest)
    },
    async createCoachReferralSource(params) {
        const createData = processInput(params)
        const createCoachReferralSourceRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-referral-sources", createCoachReferralSourceRequest)
    },
    async editCoachReferralSource(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachReferralSourceRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-referral-sources/${uuid}`, editCoachReferralSourceRequest)
    },
    async deleteCoachReferralSource(uuid) {
        const deleteCoachReferralSourceRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-referral-sources/${uuid}`, deleteCoachReferralSourceRequest)
    }
}