import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachEducationService = {
    async getCoachEducations(params) {
        let getParams = processQueryParams(params)
        const getCoachEducationsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-educations", getCoachEducationsRequest)
    },
    async getCoachEducation(uuid) {
        const getCoachEducationRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-educations/${uuid}`, getCoachEducationRequest)
    },
    async createCoachEducation(params) {
        const createData = processInput(params)
        const createCoachEducationRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-educations", createCoachEducationRequest)
    },
    async editCoachEducation(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachEducationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-educations/${uuid}`, editCoachEducationRequest)
    },
    async deleteCoachEducation(uuid) {
        const deleteCoachEducationRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-educations/${uuid}`, deleteCoachEducationRequest)
    }
}