export const GET_COACH_ASSIGNMENT_RECONCILIATIONS_REQUEST = "GET_COACH_ASSIGNMENT_RECONCILIATIONS_REQUEST"
export const GET_COACH_ASSIGNMENT_RECONCILIATIONS_SUCCESS = "GET_COACH_ASSIGNMENT_RECONCILIATIONS_SUCCESS"
export const GET_COACH_ASSIGNMENT_RECONCILIATIONS_ERROR = "GET_COACH_ASSIGNMENT_RECONCILIATIONS_ERROR"
export const GET_COACH_ASSIGNMENT_RECONCILIATION_REQUEST = "GET_COACH_ASSIGNMENT_RECONCILIATION_REQUEST"
export const GET_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS = "GET_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS"
export const GET_COACH_ASSIGNMENT_RECONCILIATION_ERROR = "GET_COACH_ASSIGNMENT_RECONCILIATION_ERROR"
export const CREATE_COACH_ASSIGNMENT_RECONCILIATION_REQUEST = "CREATE_COACH_ASSIGNMENT_RECONCILIATION_REQUEST"
export const CREATE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS = "CREATE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS"
export const CREATE_COACH_ASSIGNMENT_RECONCILIATION_ERROR = "CREATE_COACH_ASSIGNMENT_RECONCILIATION_ERROR"
export const EDIT_COACH_ASSIGNMENT_RECONCILIATION_REQUEST = "EDIT_COACH_ASSIGNMENT_RECONCILIATION_REQUEST"
export const EDIT_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS = "EDIT_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS"
export const EDIT_COACH_ASSIGNMENT_RECONCILIATION_ERROR = "EDIT_COACH_ASSIGNMENT_RECONCILIATION_ERROR"
export const DELETE_COACH_ASSIGNMENT_RECONCILIATION_REQUEST = "DELETE_COACH_ASSIGNMENT_RECONCILIATION_REQUEST"
export const DELETE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS = "DELETE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS"
export const DELETE_COACH_ASSIGNMENT_RECONCILIATION_ERROR = "DELETE_COACH_ASSIGNMENT_RECONCILIATION_ERROR"