import {
	GET_PHONE_TYPES_REQUEST,
	GET_PHONE_TYPES_SUCCESS,
	GET_PHONE_TYPES_ERROR,
	GET_PHONE_TYPE_REQUEST,
	GET_PHONE_TYPE_SUCCESS,
	GET_PHONE_TYPE_ERROR,
	CREATE_PHONE_TYPE_REQUEST,
	CREATE_PHONE_TYPE_SUCCESS,
	CREATE_PHONE_TYPE_ERROR,
	EDIT_PHONE_TYPE_REQUEST,
	EDIT_PHONE_TYPE_SUCCESS,
	EDIT_PHONE_TYPE_ERROR,
	DELETE_PHONE_TYPE_REQUEST,
	DELETE_PHONE_TYPE_SUCCESS,
	DELETE_PHONE_TYPE_ERROR
} from "@/actions"

import {
	phoneTypeService
} from "@/services"

const state = {
	phoneTypes: [],
	phoneType: {},
	loading: false
}

import { isEmpty } from "@/utilities"

const getters = {
	phoneTypes: state => state.phoneTypes,
	hasPhoneTypes: state => {
		return !isEmpty(state.phoneTypes)
	},
	phoneTypesSelectOptions: state => {
		return state.phoneTypes.map(phoneType => {
			return { value: phoneType.uuid, label: phoneType.label }
		})
	},
	phoneTypeByUuid: state => uuid => state.phoneTypes.find(pt => pt.uuid == uuid)
}

const actions = {
	async getPhoneTypes({ commit }) {
		commit("GET_PHONE_TYPES_REQUEST")
		const response = await phoneTypeService.getPhoneTypes()
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PHONE_TYPES_SUCCESS", data)
				break
			default:
				commit("GET_PHONE_TYPES_ERROR", data)
		}
		return response
	},
	async getPhoneType({ commit }, params) {
		commit("GET_PHONE_TYPE_REQUEST")
		const response = await phoneTypeService.getPhoneType(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PHONE_TYPE_SUCCESS", data)
				break
			default:
				commit("GET_PHONE_TYPE_ERROR", data)
				break
		}
		return response
	},
	async createPhoneType({ commit }, params) {
		commit("CREATE_PHONE_TYPE_REQUEST")
		const response = await phoneTypeService.createPhoneType(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_PHONE_TYPE_SUCCESS", data)
				break
			default:
				commit("CREATE_PHONE_TYPE_ERROR", data)
		}
		return response
	},
	async editPhoneType({ commit }, params, uuid) {
		commit("EDIT_PHONE_TYPE_REQUEST")
		const response = await phoneTypeService.editPhoneType(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_PHONE_TYPE_SUCCESS", data)
				break
			default:
				commit("EDIT_PHONE_TYPE_ERROR", data)
		}
		return response
	},
	async deletePhoneType({ commit }, params) {
		commit("DELETE_PHONE_TYPE_REQUEST")
		const response = await phoneTypeService.deletePhoneType(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_PHONE_TYPE_SUCCESS", data)
				break
			default:
				commit("DELETE_PHONE_TYPE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_PHONE_TYPES_REQUEST]: state => {
		state.loading = true
	},
	[GET_PHONE_TYPES_SUCCESS]: (state, data) => {
		let phoneTypes = [...data.phone_types]
		state.phoneTypes = phoneTypes
		state.loading = false
	},
	[GET_PHONE_TYPES_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_PHONE_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[GET_PHONE_TYPE_SUCCESS]: (state, data) => {
		const phoneType = { ...data.phoneType }
		state.phoneType = phoneType
		state.loading = false
	},
	[GET_PHONE_TYPE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_PHONE_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_PHONE_TYPE_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[CREATE_PHONE_TYPE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_PHONE_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_PHONE_TYPE_SUCCESS]: state => {
		state.loading = false
	},
	[EDIT_PHONE_TYPE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_PHONE_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_PHONE_TYPE_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_PHONE_TYPE_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const phoneType = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}