export const GET_DISCIPLINES_REQUEST = "GET_DISCIPLINES_REQUEST"
export const GET_DISCIPLINES_SUCCESS = "GET_DISCIPLINES_SUCCESS"
export const GET_DISCIPLINES_ERROR = "GET_DISCIPLINES_ERROR"
export const GET_DISCIPLINE_REQUEST = "GET_DISCIPLINE_REQUEST"
export const GET_DISCIPLINE_SUCCESS = "GET_DISCIPLINE_SUCCESS"
export const GET_DISCIPLINE_ERROR = "GET_DISCIPLINE_ERROR"
export const CREATE_DISCIPLINE_REQUEST = "CREATE_DISCIPLINE_REQUEST"
export const CREATE_DISCIPLINE_SUCCESS = "CREATE_DISCIPLINE_SUCCESS"
export const CREATE_DISCIPLINE_ERROR = "CREATE_DISCIPLINE_ERROR"
export const EDIT_DISCIPLINE_REQUEST = "EDIT_DISCIPLINE_REQUEST"
export const EDIT_DISCIPLINE_SUCCESS = "EDIT_DISCIPLINE_SUCCESS"
export const EDIT_DISCIPLINE_ERROR = "EDIT_DISCIPLINE_ERROR"
export const DELETE_DISCIPLINE_REQUEST = "DELETE_DISCIPLINE_REQUEST"
export const DELETE_DISCIPLINE_SUCCESS = "DELETE_DISCIPLINE_SUCCESS"
export const DELETE_DISCIPLINE_ERROR = "DELETE_DISCIPLINE_ERROR"