import axios from "axios"

import {
	accessControl,
	common,
	contentTypeForm,
	acceptHeader
} from "./helper.header";

import { store } from '@/store'

const api = axios.create({
	baseURL: `${process.env?.VUE_APP_API_ADDRESS}`,
	withCredentials: true, // required to handle the CSRF token
	headers: {
		...common(),
		...accessControl(),
		...contentTypeForm(),
		...acceptHeader()
	}
});

/**
 * Add a request interceptor
 */
api.interceptors.request.use(function (config) {

	if (store.state?.socketId) {
		config.headers["X-Socket-ID"] = store.state.socketId;
	}
	
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

/*
 * Add a response interceptor
 */
api.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {

		let { response } = error;
		let { data, status } = response || {};

		switch (status) {
			case 419:
				store.dispatch("auth/unauthorized", null, { root: true })
				break;
			case 422:
				return {
					error: true,
					status: status,
					...data
				}
			case 500:
				console.log(response);
				break;
			default:
				return {
					error: true,
					status: status || {},
					...response
				};
		}
	}
);

export async function apiRequest(url, requestOptions) {
	try {
		const response = await api(url, requestOptions);
		return response
	} catch (error) {
		console.log("try/catch error in API request");
		return error
	}
}
