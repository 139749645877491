export const GET_LEAD_EVALUATIONS_REQUEST = "GET_LEAD_EVALUATIONS_REQUEST"
export const GET_LEAD_EVALUATIONS_SUCCESS = "GET_LEAD_EVALUATIONS_SUCCESS"
export const GET_LEAD_EVALUATIONS_ERROR = "GET_LEAD_EVALUATIONS_ERROR"
export const GET_LEAD_EVALUATION_REQUEST = "GET_LEAD_EVALUATION_REQUEST"
export const GET_LEAD_EVALUATION_SUCCESS = "GET_LEAD_EVALUATION_SUCCESS"
export const GET_LEAD_EVALUATION_ERROR = "GET_LEAD_EVALUATION_ERROR"
export const CREATE_LEAD_EVALUATION_REQUEST = "CREATE_LEAD_EVALUATION_REQUEST"
export const CREATE_LEAD_EVALUATION_SUCCESS = "CREATE_LEAD_EVALUATION_SUCCESS"
export const CREATE_LEAD_EVALUATION_ERROR = "CREATE_LEAD_EVALUATION_ERROR"
export const EDIT_LEAD_EVALUATION_REQUEST = "EDIT_LEAD_EVALUATION_REQUEST"
export const EDIT_LEAD_EVALUATION_SUCCESS = "EDIT_LEAD_EVALUATION_SUCCESS"
export const EDIT_LEAD_EVALUATION_ERROR = "EDIT_LEAD_EVALUATION_ERROR"
export const DELETE_LEAD_EVALUATION_REQUEST = "DELETE_LEAD_EVALUATION_REQUEST"
export const DELETE_LEAD_EVALUATION_SUCCESS = "DELETE_LEAD_EVALUATION_SUCCESS"
export const DELETE_LEAD_EVALUATION_ERROR = "DELETE_LEAD_EVALUATION_ERROR"