import ReportQueryFilterYear from "@/components/reports/builder/ReportQueryFilterYear.vue";
import ReportActionColumn from "@/components/reports/builder/ReportActionColumn.vue";

export const reportsStartCalendarRoutes = {
  path: "start-calendar",
  name: "reports start-calendar",
  meta: {
    authRequired: true,
    allowedRoles: [
      'super-admin'
    ],
    label: "Start Calendar Reports",
  },
  component: () => import("@/pages/reports/general/ReportsGeneralIndex.vue"),
  children: [
    {
      path: "start-calendar",
      name: "report start-calendar start-calendar",
      meta: {
        label: "Start Calendar",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
     {
      path: "census-dates",
      name: "report start-calendar census-dates",
      meta: {
        label: "Census by University/Program",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "coach-pay-rates-university-program",
      name: "report start-calendar coach-pay-rates-university-program",
      meta: {
        label: "Coach Pay Rates by University/Program",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "budgeting-report",
      name: "report start-calendar budgeting-report",
      meta: {
        label: "Budgeting Report",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
      props: {
        filterComponent: ReportQueryFilterYear,
        defaultFilterValue: {
          year: new Date().getFullYear()
        },
        exportExcel: true,
        exportPDF: false
      }
    },
    {
      path: "yearly-start-totals",
      name: "report start-calendar yearly-start-totals",
      meta: {
        label: "Yearly Start Totals",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
      props: {
        filterComponent: ReportQueryFilterYear,
        defaultFilterValue: {
          year: new Date().getFullYear()
        },
        exportExcel: true,
        exportPDF: false
      }
    },
    {
      path: "upcoming-due-assignment-sheets-with-rosters",
      name: "report upcoming-due-assignment-sheets-with-rosters",
      meta: {
        label: "Upcoming Due Assignment Sheets with Rosters",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "university-procedures",
      name: "report university-procedures",
      meta: {
        label: "University Procedures",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
      props: {
        actionColumnComponent: ReportActionColumn,
        defaultFilterValue: {
          preDefinedFields: [
            { key: "university_name", label: "University Name", sorter: true },
            { key: "action", label: "View", sorter: true },
          ],
        },
        exportExcel: false,
        exportPdf: false,
      }
    },
    {
      path: "university-program-procedures",
      name: "report university-program-procedures",
      meta: {
        label: "University Program Procedures",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
      props: {
        actionColumnComponent: ReportActionColumn,
        defaultFilterValue: {
          preDefinedFields: [
            { key: "university_name", label: "University Name", sorter: true },
            { key: "program", label: "Program", sorter: true },
            { key: "action", label: "View", sorter: true },
          ],
        },
        exportExcel: false,
        exportPdf: false,
      }
    },
    {
      path: "late-final-rosters",
      name: "report late-final-rosters",
      meta: {
        label: "Late Final Rosters",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "payment-analysis",
      name: "report payment-analysis",
      meta: {
        label: "Payment Analysis",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
  ]
};