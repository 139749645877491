import {
  GET_APP_MODELS_REQUEST,
  GET_APP_MODELS_SUCCESS,
  GET_APP_MODELS_ERROR,
  GET_BASIC_APP_DATA_REQUEST,
  GET_BASIC_APP_DATA_SUCCESS,
  GET_BASIC_APP_DATA_ERROR,
  GET_COACH_APP_DATA_REQUEST,
  GET_COACH_APP_DATA_SUCCESS,
  GET_COACH_APP_DATA_ERROR,
  GET_DASHBOARD_ITEMS_REQUEST,
  GET_DASHBOARD_ITEMS_SUCCESS,
  GET_DASHBOARD_ITEMS_ERROR,
  GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_REQUEST,
  GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_SUCCESS,
  GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_ERROR,
  GET_OPERATIONS_DATA_REQUEST,
  GET_OPERATIONS_DATA_SUCCESS,
  GET_OPERATIONS_DATA_ERROR,
  GET_DASHBOARD_ITEM_REQUEST,
  GET_DASHBOARD_ITEM_SUCCESS,
  GET_DASHBOARD_ITEM_ERROR,
} from "@/actions"

import {
  settingsService
} from "@/services"

import {
  unpackWAS,
  unpackWA,
  unpackCourse,
  unpackCourseStart,
  unpackCoachAssignment,
  unpackProgramData,
  unpackUFS,
  unpackUniversity,
  unpackIcEmailsActivationDeactivation,
  unpackCoach,
  unpackDocument,
  unpackCoachApplication,
  unpackInvoice
} from "@/modules";

import {
  isEmpty,
  processQueryParams,
  processInput,
  apiRequest,
  addNewFilter
} from "@/utilities";
import { cilDescription } from "@coreui/icons";

const defaultSettingsState = () => ({
  settings: [],
  appModels: [],
  loading: false,
  loadingOperationsData: false,
  pastStartsLoaded: false,
  loadingCoachOptions: false,
  loading3: false,
  loadingDashboardItemsCommon: false,
  loadingDashboardItemsCoach: false,
  loadingDashboardItemsUniversity: false,
  loadedOnceAdmin: false,
  loadedOnceOperations: false,
  loadedOnceDashboardItems: false,
  loadedOnceCoach: false,
  loadedOnceDashboardItemsCommon: false,
  loadedOnceDashboardItemsCoach: false,
  loadedOnceDashboardItemsUniversity: false,
  commonAuthCalls: false,
  loadingDashboardItemArr: [],
  loadedDasboardItems: [],
  dashboardTiles: [
    {
        label: "Starts",
        key: "starts",
        defaultFor: "*",
        children: [
          {
            label: "Ready For Recon Tab",
            key: "starts-ready-for-recon-tab",
            defaultFor: ["reconciliation"],
            defaultOnFromParent: false,
          },
          {
            label: "Ready For Invoice Tab",
            description: "(draft invoices, ready to send invoices)",
            key: "starts-ready-for-invoice-tab",
            defaultFor: ["invoice"],
            defaultOnFromParent: false,
          },
          {
            label: "Invoiced Tab",
            description: "(sent invoices)",
            key: "starts-invoiced-tab",
            defaultFor: ["invoice"],
            defaultOnFromParent: false,
          },
          {
            label: "Upcoming Starts Tab",
            key: "starts-upcoming-starts-tab",
            defaultFor: "*",
            defaultOnFromParent: true,
          },
          {
            label: "Current Starts Tab",
            key: "starts-current-starts-tab",
            defaultFor: "*",
            defaultOnFromParent: true,
          },
          {
            label: "Past Starts Tab",
            key: "starts-past-starts-tab",
            defaultFor: "*",
            defaultOnFromParent: true,
          },
        ]
    },
    {
        label: "Payroll",
        key: "payroll",
        defaultFor: ["payroll", "executive"],
    },
    {
        label: "Sheets Ready For Invoice",
        key: "sheets-ready-for-invoice",
        defaultFor: "*",
    },
    {
        label: "Resolved Duplicate Student Requests",
        key: "resolved-duplicate-student-requests",
        defaultFor: ["reconciliation"],
    },
    {
        label: "Resolved Unknown Students Assignments",
        key: "resolved-unknown-students-assignments",
        defaultFor: ["reconciliation"],
    },
    {
        label: "Email Management",
        key: "email-management",
        defaultFor: ["tech"],
    },
    {
        label: "Missing Transcripts",
        key: "missing-transcripts",
        defaultFor: ["cio", "hr"],
    },
    {
        label: "Payroll Documents",
        key: "payroll-documents",
        defaultFor: ["payroll"],
    },
    {
        label: "New Hire Documents",
        key: "new-hire-documents",
        defaultFor: ["hr"],
    },
    {
        label: "Recently Uploaded Coach Documents",
        key: "recently-uploaded-coach-documents",
        defaultFor: ["hr"],
    },
    {
        label: "Email Password Reset Requests",
        key: "email-password-reset-requests",
        defaultFor: ["tech"],
    },
    {
        label: "Draft Invoices",
        key: "draft-invoices",
        defaultFor: "*",
    },
    {
        label: "Ready To Send Invoices",
        key: "ready-to-send-invoices",
        defaultFor: "*",
    },
    {
        label: "Sent Invoices",
        key: "sent-invoices",
        defaultFor: "*",
    },
    {
        label: "Coach Screening Sessions",
        key: "coach-screening-sessions",
        defaultFor: ["hr"],
    },
    {
        label: "Submitted Applications",
        key: "submitted-applications",
        defaultFor: ["hr"],
    },
    {
        label: "Expired Nursing License",
        key: "exp-nursing-license",
        defaultFor: ["hr", "lead-coach"],
    },
    {
        label: "University Contracts",
        key: "university-contracts",
        defaultFor: ["university-contract"],
    },
    {
        label: "Invoice List (for Executive)",
        key: "db-invoice-list-table",
        defaultFor: ["executive"],
    },
    {
      label: "Coach(es) With Students Above Max Threshold (Rosters Finalized)",
      key: "coaches-with-over-students-above-max-threshold-rosters-finalized",
      defaultFor: ["operations"],
    },
  ],
});

const state = defaultSettingsState();

const getters = {
  settings: state => state.settings.sort((b, a) => new Date(b.created_at) - new Date(a.created_at)),
  settingsOperationsDataLoading: state => state.loadingOperationsData,
  settingsLoadedOnce: state => state.loadedOnce,
  settingsLoadedOnce2: state => state.loadedOnce2,
  settingsLoadedOnce3: state => state.loadedOnce3,
  settingsLoading: state => state.loading,
  settingsPastStartsLoaded: state => state.pastStartsLoaded,
  settingsLoadingCoachOptions: state => state.loadingCoachOptions,
  settingsLoadingDashboardItemsCoach: state => state.loadingDashboardItemsCoach,

  keyedModelOptions: state => {
    return state.appModels.filter(model => {
      return { 'value': model.toLowerCase(), 'label': model }
    })
  },
  commonAuthCalls: state => state.commonAuthCalls,
  adminAccess: (state, getters, rootState, rootGetters) => {
    let authUserRoles = rootGetters["auth/userRoles"]
    return {
      allowedToUpdateCensusDates: authUserRoles.includes('super-admin'),
      allowedToAddUniversityProgram: !authUserRoles.includes('lead-coach'),
    }
  },
  dashboardTiles: state => state.dashboardTiles,
  hasTile: (state, getters, rootState, rootGetters) => key => {
      const canAccess = rootGetters['auth/canAccess'];
      
      const authorizedUser = rootGetters['auth/authorizedUser'];
      const getUserSetting = rootGetters['userSetting/userSettingByUserUuid'];
      if(isEmpty(authorizedUser)) return false;
      if(canAccess(['coach'])) return false;

      const _userSetting = getUserSetting(authorizedUser.uuid)
      const hasDbTile = state.dashboardTiles.some(i => i.key == key || (!isEmpty(i.children) && i.children.some(j => j.key == key)))
      if(!hasDbTile) return false;
      let db_tile, child_db_tile = null;
      db_tile = state.dashboardTiles.find(i => i.key == key);
      if(isEmpty(db_tile)){
        db_tile = state.dashboardTiles.find(i => !isEmpty(i.children) && i.children.some(j => j.key == key))
        if(!isEmpty(db_tile.children)){
          child_db_tile = db_tile.children.find(j => j.key == key)
        }
      }
      if(isEmpty(db_tile)) return false;
      if(!isEmpty(db_tile.defaultFor) && db_tile.defaultFor != "*" && Array.isArray(db_tile.defaultFor)){
        if(!isEmpty(child_db_tile)){
          if(!isEmpty(child_db_tile.defaultFor) && child_db_tile.defaultFor != "*" && Array.isArray(child_db_tile.defaultFor)){
            const hasCommonRoles = child_db_tile.defaultFor.some(role => canAccess(role))
            if(hasCommonRoles) return true;
          }
        }else{
          const hasCommonRoles = db_tile.defaultFor.some(role => canAccess(role))
          if(hasCommonRoles) return true;
        }
      }
      const userSetting = !isEmpty(_userSetting) ? _userSetting : authorizedUser.user_setting
      if(!isEmpty(userSetting)){
          const dbTiles = userSetting.db_tiles
          if(!isEmpty(dbTiles)){
              const dbTile = dbTiles.find(j => j.key == db_tile.key)
              if(!isEmpty(dbTile)){
                  if(!isEmpty(child_db_tile)){
                    if(!isEmpty(dbTile.children)){
                      const childDbTile = dbTile.children.find(j => j.key == key)
                      return dbTile.enabled == true && childDbTile?.enabled == true
                    }
                  }else{  
                    return dbTile.enabled == true
                  }
              }
          }
      }
      return false;
  },
  loadedDasboardItems: state => state.loadedDasboardItems
}

function processingStarted(commit) {
  commit("icEmailsActivationDeactivation/ACTIVATION_DEACTIVATION_REQUEST", true, { root: true })
  commit('document/GET_DOCUMENTS_REQUEST', true, { root: true })
  commit('email/GET_EMAILS_REQUEST', true, { root: true })
}

export function unpackBasicData(basicData, commit) {
  Object.keys(basicData).forEach(key => {
    let data = {}
    let value = basicData[key]
    if (!isEmpty(value)) data.success = true
    switch (key) {

      //Specific to the user
      //Only called once
      case "notifications":
        data.notifications = !isEmpty(value) ? value : []
        commit('notification/GET_NOTIFICATIONS_SUCCESS', data, { root: true })
        break
      case "tasks":
        data.tasks = !isEmpty(value) ? value : []
        commit('task/GET_TASKS_SUCCESS', data, { root: true })
        break
      case "documents":
        data.documents = isEmpty(value) ? [] : value
        data.documents = data.documents.map(d => unpackDocument(d, commit))
        commit('document/GET_DOCUMENTS_SUCCESS', data, { root: true })
        break

      // Common Data used by the application
      case "area_of_qualifications":
        data.area_of_qualifications = value
        commit('areaOfQualification/GET_AREA_OF_QUALIFICATIONS_SUCCESS', data, { root: true })
        break
      case "address_types":
        data.address_types = isEmpty(value) ? [] : value
        commit('addressType/GET_ADDRESS_TYPES_SUCCESS', data, { root: true })
        break
      case "coaches":
        data.coaches = isEmpty(value) ? [] : value
        data.coaches = data.coaches.map(c => unpackCoach(c, commit))
        commit('coaches/GET_COACHES_SUCCESS', data, { root: true })
        break
      case "coaches_limited_data":
        let coaches = isEmpty(value) ? [] : value
        commit('coaches/GET_LIMITED_COACHES_SUCCESS', { coaches }, { root: true })
        break
      case "associations":
        data.lcpaas = basicData.associations
        commit('lcpaa/GET_LCPAAS_SUCCESS', data, { root: true })
        delete data['lcpaas']
        delete basicData[key]
        break
      // case "combined_was":
      //   data.combined_wass = basicData.combined_was
      //   commit("combinedWAS/GET_COMBINED_WASS_SUCCESS", data, { root: true })
      //   delete basicData.combined_was
      //   break
      case "course_levels":
        data.course_levels = basicData.course_levels
        commit("courseLevel/GET_COURSE_LEVELS_SUCCESS", data, { root: true })
        delete basicData[key]
        break
      case "comment_types":
        data.comment_types = isEmpty(value) ? [] : value
        commit('commentType/GET_COMMENT_TYPES_SUCCESS', data, { root: true })
        break
      case "certifications":
        data.certifications = basicData.certifications
        commit('certification/GET_CERTIFICATIONS_SUCCESS', data, { root: true })
        delete data[key]
        delete basicData[key]
        break
      case "degrees":
        data.degrees = isEmpty(value) ? [] : value
        commit('degree/GET_DEGREES_SUCCESS', data, { root: true })
        delete basicData[key]
        break;
      case "disciplines":
        data.disciplines = !isEmpty(value) ? value : []
        commit('discipline/GET_DISCIPLINES_SUCCESS', data, { root: true })
        delete basicData[key]
        break;
      case "groups":
        data.groups = !isEmpty(value) ? value : []
        commit('group/GET_GROUPS_SUCCESS', data, { root: true })
        delete basicData[key]
        break;
      case "minors":
        data.minors = !isEmpty(value) ? value : []
        commit('minor/GET_MINORS_SUCCESS', data, { root: true })
        delete basicData[key]
        break;
      case "document_types":
        data.document_types = isEmpty(value) ? [] : value
        commit('documentType/GET_DOCUMENT_TYPES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "eeo_genders":
        data.eeo_genders = basicData.eeo_genders
        commit('eeoGender/GET_EEO_GENDERS_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "eeo_race_ethnicities":
        data.eeo_race_ethnicities = basicData.eeo_race_ethnicities
        commit('eeoRaceEthnicity/GET_EEO_RACE_ETHNICITIES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "eeo_veteran_statuses":
        data.eeo_veteran_statuses = basicData.eeo_veteran_statuses
        commit('eeoVeteranStatus/GET_EEO_VETERAN_STATUSES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "email_types":
        data.email_types = isEmpty(value) ? [] : value
        commit('emailType/GET_EMAIL_TYPES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "employers":
        data.employers = basicData.employers
        commit('employer/GET_EMPLOYERS_SUCCESS', data, { root: true })
        delete basicData[key]
        break;
      case "employment_types":
        data.employment_types = basicData.employment_types
        commit('employmentType/GET_EMPLOYMENT_TYPES_SUCCESS', data, { root: true })
        delete basicData[key]
        break;
      case "facilitating_courses":
        data.facilitating_courses = value
        commit('facilitatingCourse/GET_FACILITATING_COURSES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "issuing_authorities":
        data.issuing_authorities = value
        commit('issuingAuthority/GET_ISSUING_AUTHORITIES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "programs":
        data.programs = value
        commit('program/GET_PROGRAMS_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "program_course_levels":
        data.program_course_levels = isEmpty(value) ? [] : value
        commit('programCourseLevel/GET_PROGRAM_COURSE_LEVELS_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "point_of_contact_types":
        data.point_of_contact_types = isEmpty(value) ? [] : value
        commit('pointOfContactType/GET_POINT_OF_CONTACT_TYPES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "addendums":
        data.addendums = isEmpty(value) ? [] : value
        commit('addendum/GET_ADDENDUMS_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "languages":
        data.languages = basicData.languages
        commit('language/GET_LANGUAGES_SUCCESS', data, { root: true })
        delete basicData[key]
        delete data[key]
        break
      case "lms":
        data.learning_management_systems = isEmpty(value) ? [] : value
        commit('lms/GET_LMSS_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "nurse_licenses":
        data.nurse_licenses = isEmpty(value) ? [] : value
        commit('nurseLicense/GET_NURSE_LICENSES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "referral_sources":
        data.referral_sources = basicData.referral_sources
        commit('referralSource/GET_REFERRAL_SOURCES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "statuses":
        data.statuses = isEmpty(value) ? [] : value
        commit('status/GET_STATUSES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "status_explanations":
        data.status_explanations = isEmpty(value) ? [] : value
        commit('statusExplanation/GET_STATUS_EXPLANATIONS_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "phone_types":
        data.phone_types = isEmpty(value) ? [] : value
        commit('phoneType/GET_PHONE_TYPES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "prefixes":
        data.prefixes = isEmpty(value) ? [] : value
        commit('prefix/GET_PREFIXES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "opms":
        data.data = isEmpty(value) ? [] : value
        commit('opm/GET_OPMS_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "ras":
      case "regional_accreditors":
        data.regional_accreditors = isEmpty(value) ? [] : value
        commit('regionalAccreditor/GET_REGIONAL_ACCREDITORS_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "specialties":
        data.specialties = value
        commit('specialty/GET_SPECIALTIES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "universities":
        data.universities = basicData.universities.map(u => unpackUniversity(u, commit))
        commit('university/GET_UNIVERSITIES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      // case "universities_limited_data":
      // 	data.universities_limited_data = basicData.universities_limited_data
      // 	commit('university/GET_UNIVERSITIES_LIMITED_DATA_SUCCESS', data, { root: true })
      // 	delete basicData[key]
      // 	break
      case "university_programs":
        data.university_programs = basicData.university_programs.map(up => unpackProgramData(up, commit))
        commit("universityProgram/GET_UNIVERSITY_PROGRAMS_SUCCESS", data, { root: true })
        delete basicData[key]
        break
      case "university_statuses":
        data.university_statuses = isEmpty(value) ? [] : value
        commit('universityStatus/GET_UNIVERSITY_STATUSES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "status_universities":
        data.status_universities = isEmpty(value) ? [] : value
        commit('statusUniversity/GET_STATUS_UNIVERSITIES_SUCCESS', data, { root: true })
        delete basicData[key]
        break
      case "course":
      case "courses":
        data.courses = basicData.courses.map(c => unpackCourse(c, commit))
        commit('course/GET_COURSES_SUCCESS', data, { root: true })
        break
      case "working_assignments":
        if (!isEmpty(basicData.working_assignments)) {
          data.working_assignments = basicData.working_assignments.map(wa => unpackWA(wa, commit))
          commit("workingAssignment/GET_WORKING_ASSIGNMENTS_SUCCESS", data, { root: true })
        }
        break
      case "users":
        data.users = isEmpty(value) ? [] : value
        commit("users/GET_USERS_SUCCESS", data, { root: true })
        break
      case "roles":
        data.roles = isEmpty(value) ? [] : value
        commit("role/GET_ROLES_SUCCESS", data, { root: true })
        break
      case "contract_ios":
        data.data = isEmpty(value) ? [] : value
        commit("contractIO/GET_CONTRACT_IOS_SUCCESS", data, { root: true })
        break
      case "contract_prices":
        data.contract_price_types = isEmpty(value) ? [] : value
        commit("contractPriceType/GET_CONTRACT_PRICE_TYPES_SUCCESS", data, { root: true })
        delete basicData[key]
        break
      case "screening_sessions":
        data.screening_sessions = basicData.screening_sessions
        commit("screeningSession/GET_SCREENING_SESSIONS_SUCCESS", data, { root: true })
        delete basicData[key]
        break;
      case "sub_course_programs":
        let sub_course_programs = isEmpty(value) ? [] : value
        commit("subCourseProgram/GET_SUB_COURSE_PROGRAMS_SUCCESS", { sub_course_programs }, { root: true })
        break
      case "working_assignment_sheets":
        if (!isEmpty(basicData.working_assignment_sheets)) {
          data.working_assignment_sheets = basicData.working_assignment_sheets.map(was => unpackWAS(was, commit))
          commit("workingAssignmentSheet/GET_WORKING_ASSIGNMENT_SHEETS_SUCCESS", data, { root: true })
          delete basicData[key]
        }
        break
      case "coach_assignments":
        data.coach_assignments = basicData.coach_assignments.map(ca => unpackCoachAssignment(ca, commit))
        commit("coachAssignment/GET_COACH_ASSIGNMENTS_SUCCESS", data, { root: true })
        commit("miscellaneous/updateNhdAndPdCas", data, { root: true })
        delete basicData[key]
        break
      case "ic_emails_activation_deactivations":
        data.ic_emails_activation_deactivations = basicData.ic_emails_activation_deactivations.map(iead => unpackIcEmailsActivationDeactivation(iead, commit))
        commit("icEmailsActivationDeactivation/GET_ACTIVATION_DEACTIVATIONS_SUCCESS", data, { root: true })
        break
      case "ic_emails_activation_deactivation_date_labels":
        data.ic_emails_activation_deactivation_date_labels = basicData.ic_emails_activation_deactivation_date_labels
        commit("icEmailsActivationDeactivation/GET_ACTIVATION_DEACTIVATION_DATE_LABELS_SUCCESS", data, { root: true })
        break
      case "emails":
        commit("email/GET_EMAILS_SUCCESS", { emails: basicData.emails }, { root: true })
        break
      case "course_starts":
        if (!isEmpty(basicData.course_starts)) {
          data.course_starts = basicData.course_starts.map(cs => unpackCourseStart(cs, commit))
          commit("courseStart/GET_COURSE_STARTS_SUCCESS", data, { root: true })
          delete basicData[key]
        }
        break
      case "coach_with_over_students":
        if (Array.isArray(basicData.coach_with_over_students)) {
          data.coach_with_over_students = basicData.coach_with_over_students
          commit("miscellaneous/GET_COACH_WITH_OVER_STUDENTS_SUCCESS", data, { root: true })
          delete basicData[key]
        }
        break
      case "coach_with_over_students_pre_recon":
        if (Array.isArray(basicData.coach_with_over_students_pre_recon)) {
          data.coach_with_over_students_pre_recon = basicData.coach_with_over_students_pre_recon
          commit("miscellaneous/GET_COACH_WITH_OVER_STUDENTS_PRE_RECON_SUCCESS", data, { root: true })
          delete basicData[key]
        }
        break
      case "ca_list_roster_due":
        if (!isEmpty(basicData.ca_list_roster_due)) {
          data.ca_list_roster_due = basicData.ca_list_roster_due
          commit("miscellaneous/GET_CA_LIST_ROSTER_DUE_SUCCESS", data, { root: true })
          delete basicData[key]
        }
        break
      case "invoices":
        data.invoices = basicData.invoices.map(inv => unpackInvoice(inv, commit));
        commit("invoice/GET_INVOICES_SUCCESS", data, { root: true })
        delete basicData[key]
        break
      case "university_faculty_sources":
        if (!isEmpty(basicData.university_faculty_sources)) {
          data.university_faculty_sources = basicData.university_faculty_sources.map(ufs => unpackUFS(ufs, commit))
          commit("universityFacultySource/GET_UNI_FACULTY_SOURCES_SUCCESS", data, { root: true })
          delete basicData[key]
        }
        break
      case "settings":
        if (!isEmpty(basicData.settings)) {
          data.settings = basicData.settings
          commit("GET_SETTINGS_SUCCESS", data)
          delete basicData[key]
        }
        break
      case "coach_applications":
        if (!isEmpty(basicData.coach_applications)) {
          data.coach_applications = isEmpty(value) ? [] : value
          data.coach_applications = data.coach_applications.map(ca => unpackCoachApplication(ca, commit))
          commit('coachApplication/GET_COACH_APPLICATIONS_SUCCESS', data, { root: true })
          delete basicData[key]
        }
        break
      case "coach_screening_sessions":
        if (!isEmpty(basicData.coach_screening_sessions)) {
          data.coach_screening_sessions = basicData.coach_screening_sessions
          commit("coachScreeningSession/GET_COACH_SCREENING_SESSIONS_SUCCESS", data, { root: true })
          delete basicData[key]
        }
        break
      case "ca_statuses":
        if (!isEmpty(basicData.ca_statuses)) {
          const ca_statuses = basicData.ca_statuses
          commit("coachAssignment/SET_CA_STATUSES", ca_statuses, { root: true })
          delete basicData[key]
        }
        break
    }
  })
}

const actions = {
  async getAppModels({ commit }) {
    commit("GET_APP_MODELS_REQUEST")
    const response = await settingsService.getAppModels();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_APP_MODELS_SUCCESS", data)
        break
      default:
        commit("GET_APP_MODELS_ERROR", data)
    }
    return response
  },
  async getBasicAuthData({ commit }) {
    const response = await settingsService.getBasicAuthData()
    const { status, data } = response
    switch (status) {
      case 200:
        unpackBasicData(data, commit)
        commit('commonAuthCalls', true)
        break
      default:
        break
    }
    return response
  },

  // ADMIN VIEW
  // How the sausage is made
  // We get WAS, University, Program, Coach, Dashboard, Tasks, Notification, etc. Anything we need to make the app run.
  async getBasicAdminData({ commit }) {
    processingStarted(commit)
    if (!state.loadedOnceAdmin) {
      commit("GET_BASIC_APP_DATA_REQUEST");
      const response = await settingsService.getBasicAdminData()
      const { status, data } = response
      switch (status) {
        case 200:
          data.success ? unpackBasicData(data, commit) : null
          data.success ? commit('GET_BASIC_APP_DATA_SUCCESS') : commit('GET_BASIC_APP_DATA_ERROR')
          break
        default:
          break
      }
      return response;
    }
    return true;
  },
  async getBasicCoachData({ commit, state }) {
    if (!state.loadedOnceCoach) {
      commit("GET_COACH_APP_DATA_REQUEST");
      const response = await settingsService.getBasicCoachData()
      const { status, data } = response
      switch (status) {
        case 200:
          data.success ? unpackBasicData(data, commit) : null
          data.success ? commit("GET_COACH_APP_DATA_SUCCESS") : commit("GET_COACH_APP_DATA_ERROR")
          break
        default:
          break
      }
      return response;
    }
    return true;
  },
  async getOperationsData({ commit }, params) {
    if (!state.loadedOnceOperations) {
      commit("GET_OPERATIONS_DATA_REQUEST");
      const response = await settingsService.getOperationsData(params)
      const { status, data } = response
      switch (status) {
        case 200:
          data.success ? unpackBasicData(data, commit) : null
          data.success ? commit('GET_OPERATIONS_DATA_SUCCESS', params) : commit('GET_OPERATIONS_DATA_ERROR')
          break
        default:
          break
      }
      return response;
    }
    return true;
  },
  async getBasicDashboardItemData({ commit }) {
    if (!state.loadedOnceDashboardItems) {
      commit("GET_DASHBOARD_ITEMS_REQUEST");
      const response = await settingsService.getBasicDashboardItemData()
      const { status, data } = response
      switch (status) {
        case 200:
          data.success ? unpackBasicData(data, commit) : null
          data.success ? commit('GET_DASHBOARD_ITEMS_SUCCESS') : commit('GET_DASHBOARD_ITEMS_ERROR')
          break
        default:
          break
      }
      return response;
    }
    return true
  },

  // Coach Application Data Call
  // async getBasicAdminDataCoachesCommonData({ commit }) {
  //   commit("GET_COACH_APP_DATA_REQUEST");
  //   const response = await settingsService.getBasicAdminDataCoachesCommonData()
  //   const { status, data } = response
  //   switch (status) {
  //     case 200:
  //       data.success ? unpackBasicData(data, commit) : null
  //       data.success ? commit('GET_COACH_APP_DATA_SUCCESS') : commit('GET_COACH_APP_DATA_ERROR')
  //       break
  //     default:
  //       break
  //   }
  //   return response
  // },

  // async getBasicDashboardItemsCoach({ commit }, params) {
  //   commit("GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_REQUEST");
  //   const response = await settingsService.getBasicDashboardItemsCoach(params)
  //   const { status, data } = response
  //   switch (status) {
  //     case 200:
  //       data.success ? unpackBasicData(data, commit) : null
  //       data.success ? commit('GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_SUCCESS') : commit('GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_ERROR')
  //       break
  //     default:
  //       break
  //   }
  //   return response
  // },

  async getDashboardItem({ commit, state }, params) {
    let { item, force } = params;
    if(isEmpty(force)){
      force = false
    }
    if ((item != undefined && !state.loadedDasboardItems.includes(item)) || params?.force ) {
      commit("GET_DASHBOARD_ITEM_REQUEST", item);
      const response = await settingsService.getDashboardItem(params)
      const { status, data } = response
      switch (status) {
        case 200:
          data.success ? unpackBasicData(data, commit) : null;
          data.success ? commit('GET_DASHBOARD_ITEM_SUCCESS', item) : commit('GET_DASHBOARD_ITEM_ERROR', item);
          break
        default:
          break
      }
      return response;
    }
    return true;
  },

  // Coach Request Data Call
  async getBasicCoachRequestData({ commit }) {
    const response = await settingsService.getBasicCoachRequestData()
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? unpackBasicData(data, commit) : null
        break
      default:
        break
    }
    return response
  },

  // Meeting Minute Data Call
  async getBasicMeetingMnuteData({ commit }) {
    const response = await settingsService.getBasicMeetingMnuteData()
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? unpackBasicData(data, commit) : null
        break
      default:
        break
    }
    return response
  },

  // University Partnership Data Call
  async getBasicUniversityPartnershipData({ commit }) {
    const response = await settingsService.getBasicUniversityPartnershipData()
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? unpackBasicData(data, commit) : null
        break
      default:
        break
    }
    return response
  },

  async getSettings({ commit }, params) {
    let getSettingsRequest = processQueryParams(params)

    let res = await apiRequest(`/api/settings`, getSettingsRequest)

    if (res?.data.success) {
      commit("GET_SETTINGS_SUCCESS", res.data)
      commit("taskSetting/GET_TASK_SETTINGS_SUCCESS", res.data, { root: true })
    }

    return res
  },

  async createSetting({ commit }, params) {
    let postSettingsRequestData = processInput(params)

    const postSettingsRequest = {
      method: "POST",
      data: postSettingsRequestData
    }

    let res = await apiRequest(`/api/settings`, postSettingsRequest)

    if (res?.data.success) {
      commit("CREATE_SETTING_SUCCESS", res.data)
    }

    return res
  },

  async editSetting({ commit }, params) {
    let editData = processInput(params)
    editData.append("_method", "PATCH");

    const editPrefixRequest = {
      method: "POST",
      data: editData
    }

    const response = await apiRequest(`/api/settings/${params.uuid}`, editPrefixRequest)

    if (response?.data.success) {
      commit("EDIT_SETTING_SUCCESS", response.data)
    }

    return response
  },

  async optimizeApp({ dispatch }) {
    const response = await settingsService.optimizeApp()
    const { status } = response
    switch (status) {
      case 200:
        dispatch('alert/createAlertSuccess', "Server cached data has been cleared and Application has been optimized!", { root: true })
        break
      default:
        dispatch('alert/createAlertErrors', 'Something went wrong while otimizing the application!', { root: true })
        break
    }
    return response
  },

  async sendCaConfirmationLink({ dispatch }) {
    const response = await settingsService.sendCaConfirmationLink()
    const { status } = response
    switch (status) {
      case 200:
        dispatch('alert/createAlertSuccess', "Application Confirmation on coach portal email sending started ", { root: true })
        break
      default:
        dispatch('alert/createAlertErrors', 'Something went wrong while email sending of Application Confirmation on coach portal!', { root: true })
        break
    }
    return response
  },

  async seedDataUpload({ commit }, params) {
    return await settingsService.seedDataUpload(params);
  }
}

const mutations = {

  [GET_APP_MODELS_REQUEST]: state => {
    state.loading = true
  },
  [GET_APP_MODELS_SUCCESS]: (state, data) => {
    const appModels = [...data.models]
    state.appModels = appModels
    state.loading = false
  },
  [GET_APP_MODELS_ERROR]: state => {
    state.loading = false
  },
  commonAuthCalls: (state, val) => {
    state.commonAuthCalls = val
  },
  [GET_BASIC_APP_DATA_REQUEST]: state => {
    state.loading = true;
  },
  [GET_BASIC_APP_DATA_SUCCESS]: state => {
    state.loading = false;
    state.loadedOnceAdmin = true;
  },
  [GET_BASIC_APP_DATA_ERROR]: state => {
    state.loading = false;
    state.loadedOnceAdmin = true;
  },
  [GET_COACH_APP_DATA_REQUEST]: (state) => {
    state.loadingCoachOptions = true;
  },
  [GET_COACH_APP_DATA_SUCCESS]: state => {
    state.loadingCoachOptions = false;
    state.loadedOnceCoach = true;
  },
  [GET_COACH_APP_DATA_ERROR]: state => {
    state.loadingCoachOptions = false;
    state.loadedOnceCoach = true;
  },
  [GET_DASHBOARD_ITEMS_REQUEST]: state => {
    state.loadingDashboardItems = true
  },
  [GET_DASHBOARD_ITEMS_SUCCESS]: state => {
    state.loadingDashboardItems = false
    state.loadedOnceDashboardItems = true
  },
  [GET_DASHBOARD_ITEMS_ERROR]: state => {
    state.loadingDashboardItems = false
    state.loadedOnceDashboardItems = true
  },
  [GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_REQUEST]: state => {
    state.loadingDashboardItemsCoach = true
  },
  [GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_SUCCESS]: state => {
    state.loadingDashboardItemsCoach = false
    state.loadedOnceDashboardItemsCoach = true
  },
  [GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_ERROR]: state => {
    state.loadingDashboardItemsCoach = false
    state.loadedOnceDashboardItemsCoach = true
  },
  [GET_OPERATIONS_DATA_REQUEST]: state => {
    state.loadingOperationsData = true
  },
  [GET_OPERATIONS_DATA_SUCCESS]: (state, params) => {
    state.loadedOnceOperations = true;
    state.loadingOperationsData = false;
  },
  [GET_OPERATIONS_DATA_ERROR]: state => {
    state.loadedOnceOperations = true;
    state.loadingOperationsData = false;
  },
  [GET_DASHBOARD_ITEM_REQUEST]: (state, item) => {
    state.loadingDashboardItemArr.push(item);
    state.loadingDashboardItem = true;
  },
  [GET_DASHBOARD_ITEM_SUCCESS]: (state, item) => {
    state.loadingDashboardItemArr = state.loadingDashboardItemArr.filter(i => i != item);
    state.loadingDashboardItem = false;
    state.loadedDasboardItems.push(item)
  },
  [GET_DASHBOARD_ITEM_ERROR]: (state, item) => {
    state.loadingDashboardItemArr = state.loadingDashboardItemArr.filter(i => i != item);
    state.loadingDashboardItem = false;
  },
  GET_SETTINGS_SUCCESS: (state, data) => {
    const settings = data.settings
    state.settings = addNewFilter(state.settings, settings)
  },
  CREATE_SETTING_SUCCESS: (state, data) => {
    const { setting } = data;
    state.settings = addNewFilter(state.settings, [setting]);

  },
  EDIT_SETTING_SUCCESS: (state, data) => {
    const { setting } = data;
    state.settings = addNewFilter(state.settings, [setting]);
    state.loading = false;
  },

  RESET_SETTINGS_STATE: state => {
    Object.assign(state, defaultSettingsState)
  }
}

export const settings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
