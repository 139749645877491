export const GET_REFERRAL_SOURCES_REQUEST = "GET_REFERRAL_SOURCES_REQUEST"
export const GET_REFERRAL_SOURCES_SUCCESS = "GET_REFERRAL_SOURCES_SUCCESS"
export const GET_REFERRAL_SOURCES_ERROR = "GET_REFERRAL_SOURCES_ERROR"
export const GET_REFERRAL_SOURCE_REQUEST = "GET_REFERRAL_SOURCE_REQUEST"
export const GET_REFERRAL_SOURCE_SUCCESS = "GET_REFERRAL_SOURCE_SUCCESS"
export const GET_REFERRAL_SOURCE_ERROR = "GET_REFERRAL_SOURCE_ERROR"
export const CREATE_REFERRAL_SOURCE_REQUEST = "CREATE_REFERRAL_SOURCE_REQUEST"
export const CREATE_REFERRAL_SOURCE_SUCCESS = "CREATE_REFERRAL_SOURCE_SUCCESS"
export const CREATE_REFERRAL_SOURCE_ERROR = "CREATE_REFERRAL_SOURCE_ERROR"
export const EDIT_REFERRAL_SOURCE_REQUEST = "EDIT_REFERRAL_SOURCE_REQUEST"
export const EDIT_REFERRAL_SOURCE_SUCCESS = "EDIT_REFERRAL_SOURCE_SUCCESS"
export const EDIT_REFERRAL_SOURCE_ERROR = "EDIT_REFERRAL_SOURCE_ERROR"
export const DELETE_REFERRAL_SOURCE_REQUEST = "DELETE_REFERRAL_SOURCE_REQUEST"
export const DELETE_REFERRAL_SOURCE_SUCCESS = "DELETE_REFERRAL_SOURCE_SUCCESS"
export const DELETE_REFERRAL_SOURCE_ERROR = "DELETE_REFERRAL_SOURCE_ERROR"