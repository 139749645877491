export const GET_ADDENDUMS_REQUEST = "GET_ADDENDUMS_REQUEST"
export const GET_ADDENDUMS_SUCCESS = "GET_ADDENDUMS_SUCCESS"
export const GET_ADDENDUMS_ERROR = "GET_ADDENDUMS_ERROR"
export const GET_ADDENDUM_REQUEST = "GET_ADDENDUM_REQUEST"
export const GET_ADDENDUM_SUCCESS = "GET_ADDENDUM_SUCCESS"
export const GET_ADDENDUM_ERROR = "GET_ADDENDUM_ERROR" 
export const CREATE_ADDENDUM_REQUEST = "CREATE_ADDENDUM_REQUEST"
export const CREATE_ADDENDUM_SUCCESS = "CREATE_ADDENDUM_SUCCESS"
export const CREATE_ADDENDUM_ERROR = "CREATE_ADDENDUM_ERROR"
export const EDIT_ADDENDUM_REQUEST = "EDIT_ADDENDUM_REQUEST"
export const EDIT_ADDENDUM_SUCCESS = "EDIT_ADDENDUM_SUCCESS"
export const EDIT_ADDENDUM_ERROR = "EDIT_ADDENDUM_ERROR"
export const DELETE_ADDENDUM_REQUEST = "DELETE_ADDENDUM_REQUEST"
export const DELETE_ADDENDUM_SUCCESS = "DELETE_ADDENDUM_SUCCESS"
export const DELETE_ADDENDUM_ERROR = "DELETE_ADDENDUM_ERROR"