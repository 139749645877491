export const GET_FACULTY_EVALUATIONS_REQUEST = "GET_FACULTY_EVALUATIONS_REQUEST"
export const GET_FACULTY_EVALUATIONS_SUCCESS = "GET_FACULTY_EVALUATIONS_SUCCESS"
export const GET_FACULTY_EVALUATIONS_ERROR = "GET_FACULTY_EVALUATIONS_ERROR"
export const GET_FACULTY_EVALUATION_REQUEST = "GET_FACULTY_EVALUATION_REQUEST"
export const GET_FACULTY_EVALUATION_SUCCESS = "GET_FACULTY_EVALUATION_SUCCESS"
export const GET_FACULTY_EVALUATION_ERROR = "GET_FACULTY_EVALUATION_ERROR"
export const GET_FACULTY_EVALUATION_STATUS_REQUEST = "GET_FACULTY_EVALUATION_STATUS_REQUEST"
export const GET_FACULTY_EVALUATION_STATUS_SUCCESS = "GET_FACULTY_EVALUATION_STATUS_SUCCESS"
export const GET_FACULTY_EVALUATION_STATUS_ERROR = "GET_FACULTY_EVALUATION_STATUS_ERROR"
export const CREATE_FACULTY_EVALUATION_REQUEST = "CREATE_FACULTY_EVALUATION_REQUEST"
export const CREATE_FACULTY_EVALUATION_SUCCESS = "CREATE_FACULTY_EVALUATION_SUCCESS"
export const CREATE_FACULTY_EVALUATION_ERROR = "CREATE_FACULTY_EVALUATION_ERROR"
export const EDIT_FACULTY_EVALUATION_REQUEST = "EDIT_FACULTY_EVALUATION_REQUEST"
export const EDIT_FACULTY_EVALUATION_SUCCESS = "EDIT_FACULTY_EVALUATION_SUCCESS"
export const EDIT_FACULTY_EVALUATION_ERROR = "EDIT_FACULTY_EVALUATION_ERROR"
export const DELETE_FACULTY_EVALUATION_REQUEST = "DELETE_FACULTY_EVALUATION_REQUEST"
export const DELETE_FACULTY_EVALUATION_SUCCESS = "DELETE_FACULTY_EVALUATION_SUCCESS"
export const DELETE_FACULTY_EVALUATION_ERROR = "DELETE_FACULTY_EVALUATION_ERROR"