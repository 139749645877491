export const GET_COACH_REFERENCES_REQUEST = "GET_COACH_REFERENCES_REQUEST"
export const GET_COACH_REFERENCES_SUCCESS = "GET_COACH_REFERENCES_SUCCESS"
export const GET_COACH_REFERENCES_ERROR = "GET_COACH_REFERENCES_ERROR"
export const GET_COACH_REFERENCE_REQUEST = "GET_COACH_REFERENCE_REQUEST"
export const GET_COACH_REFERENCE_SUCCESS = "GET_COACH_REFERENCE_SUCCESS"
export const GET_COACH_REFERENCE_ERROR = "GET_COACH_REFERENCE_ERROR"
export const CREATE_COACH_REFERENCE_REQUEST = "CREATE_COACH_REFERENCE_REQUEST"
export const CREATE_COACH_REFERENCE_SUCCESS = "CREATE_COACH_REFERENCE_SUCCESS"
export const CREATE_COACH_REFERENCE_ERROR = "CREATE_COACH_REFERENCE_ERROR"
export const EDIT_COACH_REFERENCE_REQUEST = "EDIT_COACH_REFERENCE_REQUEST"
export const EDIT_COACH_REFERENCE_SUCCESS = "EDIT_COACH_REFERENCE_SUCCESS"
export const EDIT_COACH_REFERENCE_ERROR = "EDIT_COACH_REFERENCE_ERROR"
export const DELETE_COACH_REFERENCE_REQUEST = "DELETE_COACH_REFERENCE_REQUEST"
export const DELETE_COACH_REFERENCE_SUCCESS = "DELETE_COACH_REFERENCE_SUCCESS"
export const DELETE_COACH_REFERENCE_ERROR = "DELETE_COACH_REFERENCE_ERROR"
export const CLEAR_COACH_REFERENCE_STATE = "CLEAR_COACH_REFERENCE_STATE"