export const GET_LMSS_REQUEST = "GET_LMSS_REQUEST"
export const GET_LMSS_SUCCESS = "GET_LMSS_SUCCESS"
export const GET_LMSS_ERROR = "GET_LMSS_ERROR"
export const GET_LMS_REQUEST = "GET_LMS_REQUEST"
export const GET_LMS_SUCCESS = "GET_LMS_SUCCESS"
export const GET_LMS_ERROR = "GET_LMS_ERROR"
export const CREATE_LMS_REQUEST = "CREATE_LMS_REQUEST"
export const CREATE_LMS_SUCCESS = "CREATE_LMS_SUCCESS"
export const CREATE_LMS_ERROR = "CREATE_LMS_ERROR"
export const EDIT_LMS_REQUEST = "EDIT_LMS_REQUEST"
export const EDIT_LMS_SUCCESS = "EDIT_LMS_SUCCESS"
export const EDIT_LMS_ERROR = "EDIT_LMS_ERROR"
export const DELETE_LMS_REQUEST = "DELETE_LMS_REQUEST"
export const DELETE_LMS_SUCCESS = "DELETE_LMS_SUCCESS"
export const DELETE_LMS_ERROR = "DELETE_LMS_ERROR"