export const GET_COACH_EMPLOYMENTS_REQUEST = "GET_COACH_EMPLOYMENTS_REQUEST"
export const GET_COACH_EMPLOYMENTS_SUCCESS = "GET_COACH_EMPLOYMENTS_SUCCESS"
export const GET_COACH_EMPLOYMENTS_ERROR = "GET_COACH_EMPLOYMENTS_ERROR"
export const GET_COACH_EMPLOYMENT_REQUEST = "GET_COACH_EMPLOYMENT_REQUEST"
export const GET_COACH_EMPLOYMENT_SUCCESS = "GET_COACH_EMPLOYMENT_SUCCESS"
export const GET_COACH_EMPLOYMENT_ERROR = "GET_COACH_EMPLOYMENT_ERROR"
export const CREATE_COACH_EMPLOYMENT_REQUEST = "CREATE_COACH_EMPLOYMENT_REQUEST"
export const CREATE_COACH_EMPLOYMENT_SUCCESS = "CREATE_COACH_EMPLOYMENT_SUCCESS"
export const CREATE_COACH_EMPLOYMENT_ERROR = "CREATE_COACH_EMPLOYMENT_ERROR"
export const EDIT_COACH_EMPLOYMENT_REQUEST = "EDIT_COACH_EMPLOYMENT_REQUEST"
export const EDIT_COACH_EMPLOYMENT_SUCCESS = "EDIT_COACH_EMPLOYMENT_SUCCESS"
export const EDIT_COACH_EMPLOYMENT_ERROR = "EDIT_COACH_EMPLOYMENT_ERROR"
export const DELETE_COACH_EMPLOYMENT_REQUEST = "DELETE_COACH_EMPLOYMENT_REQUEST"
export const DELETE_COACH_EMPLOYMENT_SUCCESS = "DELETE_COACH_EMPLOYMENT_SUCCESS"
export const DELETE_COACH_EMPLOYMENT_ERROR = "DELETE_COACH_EMPLOYMENT_ERROR"
export const CLEAR_COACH_EMPLOYMENT_STATE = "CLEAR_COACH_EMPLOYMENT_STATE"