import { isEmpty } from "@/utilities";

const state = {
  students: []
}

const getters = {
  carStudents: state => carUuid => state.students.filter(s => !isEmpty(carUuid) ? s?.coach_assignment_reconciliations_uuid == carUuid : false),
  csrStudents: state => csrUuid => state.students.filter(s => !isEmpty(csrUuid) ? s?.course_start_reconciliations_uuid == csrUuid : false),
  wasrStudents: state => wasrUuid => state.students.filter(s => s.was_reconciliations_uuid == wasrUuid)
}

const actions = {

}

const mutations = {
  ADD_STUDENTS: (state, data) => {

    //Filter out
    let uniqueStudents = state.students.filter(s => {
      return !data.students.find(ds => {
        if ('course_start_reconciliations_uuid' in ds && 'course_start_reconciliations_uuid' in s) {
          return ds.course_start_reconciliations_uuid == s.course_start_reconciliations_uuid && ds.cfl_slug == s.cfl_slug;
        }

        return ds.coach_assignment_reconciliations_uuid == s.coach_assignment_reconciliations_uuid && ds.cfl_slug == s.cfl_slug;
      })
    });

    state.students = uniqueStudents.concat(data.students);
  },
  removeCarStudents: (state, carUuid) => {
    state.students  = state.students.filter(s => s?.coach_assignment_reconciliations_uuid != carUuid)
  }
}

export const students = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
