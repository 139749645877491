import {
  reportsGeneralRoutes,
} from "@/router/routes/reports/routes.reports.general";

import {
  reportsMetricRoutes,
} from "@/router/routes/reports/routes.reports.metric";

import {
  reportsInvoiceRoutes,
} from "@/router/routes/reports/routes.reports.invoice";

import {
  reportsAssignmentSheetRoutes,
} from "@/router/routes/reports/routes.reports.assignment.sheet";

import {
  reportsStartCalendarRoutes
} from "@/router/routes/reports/routes.reports.start.calendar";

import {
  reportsCoachRoutes
} from "@/router/routes/reports/routes.reports.coach";


export const reportsRoutes = {
  path: "reports",
  component: () => import("@/pages/reports/ReportsContainer.vue"),
  children: [
    {
      ...reportsGeneralRoutes,
    },
    {
      ...reportsMetricRoutes
    },
    {
      ...reportsInvoiceRoutes
    },
    {
      ...reportsAssignmentSheetRoutes
    },
    {
      ...reportsStartCalendarRoutes
    },
    {
      ...reportsCoachRoutes
    },
    {
      path: "query-builder",
      name: "query builder",
      meta: {
        label: "Query Builder",
      },
      component: () => import("@/pages/query/QueryBuilderContainer.vue")
    },
  ]
}