import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachEmploymentService = {
    async getCoachEmployments(params) {
        let getParams = processQueryParams(params)
        const getCoachEmploymentsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-employments", getCoachEmploymentsRequest)
    },
    async getCoachEmployment(uuid) {
        const getCoachEmploymentRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-employments/${uuid}`, getCoachEmploymentRequest)
    },
    async createCoachEmployment(params) {
        const createData = processInput(params)
        const createCoachEmploymentRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-employments", createCoachEmploymentRequest)
    },
    async editCoachEmployment(data, uuid) {
        uuid = data.uuid
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachEmploymentRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-employments/${uuid}`, editCoachEmploymentRequest)
    },
    async deleteCoachEmployment(uuid) {
        const deleteCoachEmploymentRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-employments/${uuid}`, deleteCoachEmploymentRequest)
    }
}