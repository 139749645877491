export const GET_EMPLOYERS_REQUEST = "GET_EMPLOYERS_REQUEST"
export const GET_EMPLOYERS_SUCCESS = "GET_EMPLOYERS_SUCCESS"
export const GET_EMPLOYERS_ERROR = "GET_EMPLOYERS_ERROR"
export const GET_EMPLOYER_REQUEST = "GET_EMPLOYER_REQUEST"
export const GET_EMPLOYER_SUCCESS = "GET_EMPLOYER_SUCCESS"
export const GET_EMPLOYER_ERROR = "GET_EMPLOYER_ERROR"
export const CREATE_EMPLOYER_REQUEST = "CREATE_EMPLOYER_REQUEST"
export const CREATE_EMPLOYER_SUCCESS = "CREATE_EMPLOYER_SUCCESS"
export const CREATE_EMPLOYER_ERROR = "CREATE_EMPLOYER_ERROR"
export const EDIT_EMPLOYER_REQUEST = "EDIT_EMPLOYER_REQUEST"
export const EDIT_EMPLOYER_SUCCESS = "EDIT_EMPLOYER_SUCCESS"
export const EDIT_EMPLOYER_ERROR = "EDIT_EMPLOYER_ERROR"
export const DELETE_EMPLOYER_REQUEST = "DELETE_EMPLOYER_REQUEST"
export const DELETE_EMPLOYER_SUCCESS = "DELETE_EMPLOYER_SUCCESS"
export const DELETE_EMPLOYER_ERROR = "DELETE_EMPLOYER_ERROR"