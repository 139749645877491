export const GET_PAYROLLS_REQUEST = "GET_PAYROLLS_REQUEST"
export const GET_PAYROLLS_SUCCESS = "GET_PAYROLLS_SUCCESS"
export const GET_PAYROLLS_ERROR = "GET_PAYROLLS_ERROR"
export const GET_PAYROLL_REQUEST = "GET_PAYROLL_REQUEST"
export const GET_PAYROLL_SUCCESS = "GET_PAYROLL_SUCCESS"
export const GET_PAYROLL_ERROR = "GET_PAYROLL_ERROR"
export const CREATE_PAYROLL_REQUEST = "CREATE_PAYROLL_REQUEST"
export const CREATE_PAYROLL_SUCCESS = "CREATE_PAYROLL_SUCCESS"
export const CREATE_PAYROLL_ERROR = "CREATE_PAYROLL_ERROR"
export const EDIT_PAYROLL_REQUEST = "EDIT_PAYROLL_REQUEST"
export const EDIT_PAYROLL_SUCCESS = "EDIT_PAYROLL_SUCCESS"
export const EDIT_PAYROLL_ERROR = "EDIT_PAYROLL_ERROR"
export const DELETE_PAYROLL_REQUEST = "DELETE_PAYROLL_REQUEST"
export const DELETE_PAYROLL_SUCCESS = "DELETE_PAYROLL_SUCCESS"
export const DELETE_PAYROLL_ERROR = "DELETE_PAYROLL_ERROR"
export const GENERATE_PAYROLL_REQUEST = "GENERATE_PAYROLL_REQUEST";
export const GENERATE_PAYROLL_SUCCESS = "GENERATE_PAYROLL_SUCCESS";
export const GENERATE_PAYROLL_ERROR = "GENERATE_PAYROLL_ERROR";