import {
    GET_UNIVERSITY_PROSPECT_DOCUMENTS_REQUEST,
    GET_UNIVERSITY_PROSPECT_DOCUMENTS_SUCCESS,
    GET_UNIVERSITY_PROSPECT_DOCUMENTS_ERROR,
    GET_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST,
    GET_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS,
    GET_UNIVERSITY_PROSPECT_DOCUMENT_ERROR,
    CREATE_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST,
    CREATE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS,
    CREATE_UNIVERSITY_PROSPECT_DOCUMENT_ERROR,
    EDIT_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST,
    EDIT_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS,
    EDIT_UNIVERSITY_PROSPECT_DOCUMENT_ERROR,
    DELETE_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST,
    DELETE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS,
    BROADCAST_DELETE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS,
    DELETE_UNIVERSITY_PROSPECT_DOCUMENT_ERROR
} from "@/actions"

import {
    universityProspectDocumentsService
} from "@/services"

import { addNewFilter, decodeLaravelValidationErrors, isEmpty } from "@/utilities"

const state = {
    universityProspectDocuments: [],
    universityProspectDocument: {},
    loading: false
}

const getters = {
    universityProspectDocumentFilter: state => cUuid => state.universityProspectDocuments.filter(upd => {
        return upd.contract_uuid == cUuid
    })
}

const actions = {
    async getUniversityProspectDocuments({ commit }) {
        commit("GET_UNIVERSITY_PROSPECT_DOCUMENTS_REQUEST")
        const response = await universityProspectDocumentsService.getUniversityProspectDocuments();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_PROSPECT_DOCUMENTS_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_PROSPECT_DOCUMENTS_ERROR", data)
        }
        return response
    },
    async getUniversityProspectDocument({ commit }, params) {
        commit("GET_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST")
        const response = await universityProspectDocumentsService.getUniversityProspectDocument(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_PROSPECT_DOCUMENT_ERROR", data)
                break
        }
        return response
    },
    async createUniversityProspectDocument({ commit, dispatch }, params) {
        commit("CREATE_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST")
        const response = await universityProspectDocumentsService.createUniversityProspectDocument(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS", data) : dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
                break
            default:
                commit("CREATE_UNIVERSITY_PROSPECT_DOCUMENT_ERROR", data)
        }
        return response
    },
    async editUniversityProspectDocument({ commit, dispatch }, params) {
        commit("EDIT_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST")
        const response = await universityProspectDocumentsService.editUniversityProspectDocument(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("EDIT_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS", data) : dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
                break
            default:
                commit("EDIT_UNIVERSITY_PROSPECT_DOCUMENT_ERROR", data)
        }
        return response
    },
    async deleteUniversityProspectDocument({ commit }, params) {
        commit("DELETE_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST")
        const response = await universityProspectDocumentsService.deleteUniversityProspectDocument(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS", data)
                break
            default:
                commit("DELETE_UNIVERSITY_PROSPECT_DOCUMENT_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_UNIVERSITY_PROSPECT_DOCUMENTS_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_PROSPECT_DOCUMENTS_SUCCESS]: (state, data) => {
        let university_prospect_documents = data.university_prospect_documents
        state.universityProspectDocuments = addNewFilter(state.universityProspectDocuments, university_prospect_documents)
        state.loading = false
    },
    [GET_UNIVERSITY_PROSPECT_DOCUMENTS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS]: (state, data) => {
        const {university_prospect_document} = data
        if(!isEmpty(university_prospect_document)){
            state.universityProspectDocuments = addNewFilter(state.universityProspectDocuments, [university_prospect_document])
            state.universityProspectDocument = university_prospect_document
        }
        state.loading = false
    },
    [GET_UNIVERSITY_PROSPECT_DOCUMENT_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS]: (state, data) => {
        state.loading = false
        const {university_prospect_document} = data
        if(!isEmpty(university_prospect_document)){
            state.universityProspectDocuments = addNewFilter(state.universityProspectDocuments, [university_prospect_document])
        }
    },
    [CREATE_UNIVERSITY_PROSPECT_DOCUMENT_ERROR]: state => {
        state.loading = false
    },

    [EDIT_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS]: (state, data) => {
        const {university_prospect_document} = data
        if(!isEmpty(university_prospect_document)){
            state.universityProspectDocuments = state.universityProspectDocuments.filter(up => up.uuid != university_prospect_document.uuid)
            state.universityProspectDocuments = addNewFilter(state.universityProspectDocuments, [university_prospect_document])
        }
    },
    [EDIT_UNIVERSITY_PROSPECT_DOCUMENT_ERROR]: state => {
        state.loading = false
    },

    [DELETE_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [BROADCAST_DELETE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS]: (state, data) => {
        const { university_prospect_document } = data
        if(!isEmpty(university_prospect_document)){
            state.universityProspectDocuments = state.universityProspectDocuments.filter(up => up.uuid != university_prospect_document.uuid)
        }
        state.loading = false
    },
    [DELETE_UNIVERSITY_PROSPECT_DOCUMENT_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const universityProspectDocument = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}