import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const workingAssignmentSheetsService = {
    async getWorkingAssignmentSheets(params) {
        const wasGetQueryParams = processQueryParams(params)
        const getWorkingAssignmentSheetsRequest = {
            method: "GET",
            params: wasGetQueryParams
        }
        return await apiRequest("/api/working-assignment-sheets", getWorkingAssignmentSheetsRequest)
    },
    async createWorkingAssignmentSheet(params) {
        const createData = processInput(params);
        const postWorkingAssignmentSheetRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/working-assignment-sheets", postWorkingAssignmentSheetRequest)
    },
    async getWorkingAssignmentSheet(params) {
        const { uuid } = params
        delete params['uuid']
        const wasGetByIdParams = processQueryParams(params)
        const getWorkingAssignmentSheetRequest = {
            method: "GET",
            params: wasGetByIdParams
        }

        return await apiRequest(`/api/working-assignment-sheets/${uuid}`, getWorkingAssignmentSheetRequest);
    },
    async editWorkingAssignmentSheet(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateWorkingAssignmentSheetRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/working-assignment-sheets/${uuid}`, updateWorkingAssignmentSheetRequest);
    },
    async deleteWorkingAssignmentSheet(uuid) {

        const deleteWorkingAssignmentSheetRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/working-assignment-sheets/${uuid}`, deleteWorkingAssignmentSheetRequest);
    },
    async sendMessageToAdminOnWas(data) {
        let editData = processInput(data)
        const updateWorkingAssignmentSheetRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/was-send-admin-message`, updateWorkingAssignmentSheetRequest);
    },
    async sendMessageToLeadOnWas(data) {
        let editData = processInput(data)
        const updateWorkingAssignmentSheetRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/was-send-lead-message`, updateWorkingAssignmentSheetRequest);
    },
}
