
import { isEmpty, strToLower, removeWhiteSpaceSlug } from "@/utilities"
import { defaultsDeep } from "lodash"


function makeSlug(item, course = null, fn = null, ln = null) {

	if (isEmpty(course)) {
		course = item.course
	}

	if (isEmpty(fn)) {
		fn = item.first_name
	}

	if (isEmpty(ln)) {
		ln = item.last_name
	}

	if (isEmpty(fn) && isEmpty(ln)) return null

	let l_course = isEmpty(course) ? null : removeWhiteSpaceSlug(course)
	let l_fn = isEmpty(fn) ? null : fn
	let l_ln = isEmpty(ln) ? null : ln
	let slug = l_course + " " + l_fn + " " + l_ln

	return strToLower(slug, true)
}

const state = {
	multipleAssigns: {},
	tmpltKey: Number(new Date()),
}

const getters = {
	workingAssignmentSheet: (state, getters, rootState, rootGetters) => {
		return rootGetters['workingAssignmentSheet/workingAssignmentSheet']
	},
	// Get ReconciliationItem by 'reconciliation_key'
	reconciliationItems: (state, getters, rootState, rootGetters) => {
		let wasRecFindByWasUuid = rootGetters['wasReconciliation/wasRecFindByWasUuid']
		let wasr = wasRecFindByWasUuid(getters.workingAssignmentSheet.uuid)
		let reconciliationItemsByRecKey = rootGetters['reconciliationItem/reconciliationItemsByRecKey']
		return reconciliationItemsByRecKey(wasr?.reconciliation_key)
	},
	misMatchedItemsFacultyAll: (state, getters) => {
		return getters.reconciliationItems.filter(i =>
			i.reconciliation_itemable_type == "App\\Models\\CourseStartReconciliation"
		).map(s => {
			let cfl_slug = makeSlug(s.item, s.course_number)
			return {
				...s,
				cfl_slug
			}
		});
	},
	misMatchedItemsCoachAll: (state, getters) => {
		return getters.reconciliationItems.filter(i =>
			i.reconciliation_itemable_type == "App\\Models\\CoachAssignmentReconciliation"
		).map(s => {
			let cfl_slug = makeSlug(s.item, s.course_number)
			return {
				...s,
				cfl_slug
			}
		});
	},
	misMatchedItemsAdminAll: (state, getters) => {
		return getters.reconciliationItems.filter(i =>
			i.reconciliation_itemable_type == "App\\Models\\WorkingAssignmentSheetReconciliation"
		).map(s => {
			let cfl_slug = makeSlug(s.item, s.meta.course_number)
			return {
				...s,
				cfl_slug
			}
		})
	},
	misMatchedItemsFaculty: (state, getters) => {
		return getters.misMatchedItemsFacultyAll.filter(i =>
			isEmpty(i.assigned_to_uuid)
			&& isEmpty(i.assigned_to_faculty_uuid)
			&& isEmpty(i.sibling_assigned)
		)
	},
	misMatchedItemsCoach: (state, getters) => {
		return getters.misMatchedItemsCoachAll.filter(i =>
			isEmpty(i.assigned_to_uuid)
			&& isEmpty(i.assigned_to_faculty_uuid)
			&& isEmpty(i.sibling_assigned)
		)
	},

	misMatchedItemsAdmin: (state, getters) => {
		return getters.misMatchedItemsAdminAll.filter(i =>
			isEmpty(i.assigned_to_uuid)
			&& isEmpty(i.assigned_to_faculty_uuid)
			&& isEmpty(i.sibling_assigned)
		)
	},
	assignedAdminStudents: (state, getters) => {
		return getters.misMatchedItemsAdminAll.filter(i =>
			!isEmpty(i.assigned_to_uuid)
			|| !isEmpty(i.assigned_to_faculty_uuid)
			&& isEmpty(i.sibling_assigned)
		)
	},
	assignedCoachStudents: (state, getters) => {
		return getters.misMatchedItemsCoachAll.filter(i =>
			!isEmpty(i.assigned_to_uuid)
			|| !isEmpty(i.assigned_to_faculty_uuid)
			&& isEmpty(i.sibling_assigned)
		)
	},
	assignedFacultyStudents: (state, getters) => {
		return getters.misMatchedItemsFacultyAll.filter(i =>
			!isEmpty(i.assigned_to_uuid)
			|| !isEmpty(i.assigned_to_faculty_uuid)
			&& isEmpty(i.sibling_assigned)
		)
	},
	matchedRecords: (state, getters, rootState, rootGetters) => ca_uuid => {

		let misMatchedItemsAdminSlugArray = getters.misMatchedItemsAdminAll.map(a => a.cfl_slug)
		let misMatchedItemsCoachSlugArray = getters.misMatchedItemsCoachAll.map(a => a.cfl_slug)
		let misMatchedItemsFacultySlugArray = getters.misMatchedItemsFacultyAll.map(a => a.cfl_slug)

		let matchedStuds = []
		// let caRoster = rootGetters['coachAssignmentReconciliation/coachAssignmentReconciliation']
		let caRoster = rootGetters['coachAssignmentReconciliation/CARecByCaUuid'](ca_uuid)
		
		if (caRoster.coach_assignments_uuid == ca_uuid) {
			if (!isEmpty(caRoster?.students)) {
				caRoster.students.filter(b => (
					!misMatchedItemsAdminSlugArray.includes(b.cfl_slug)
					&& !misMatchedItemsCoachSlugArray.includes(b.cfl_slug)
					&& !misMatchedItemsFacultySlugArray.includes(b.cfl_slug)
				)).forEach(d => {
					matchedStuds.push({
						...d,
						uuid: d.cfl_slug,
						isAssigned: false,
						isDuplicate: false,
						isMisMatch: false
					})
				});
			}
		}


		let admin_assigned = getters.assignedAdminStudents
		admin_assigned = admin_assigned.map(i => ({ ...i, byAdmin: 1 }))

		let coach_faculty_assigned = getters.assignedCoachStudents.concat(getters.assignedFacultyStudents)
		coach_faculty_assigned = coach_faculty_assigned.map(i => ({ ...i, byAdmin: 0 }))

		let assigned_students = admin_assigned.concat(coach_faculty_assigned)
		// assigned_students = []
		assigned_students.forEach((c) => {
			if (!isEmpty(c.coach_assignment)) {
				if (c.coach_assignment.ca_uuid == ca_uuid) {
					let isDuplicate = c?.meta.duplicate ? true : false
					matchedStuds.push({
						...c.item,
						uuid: c.uuid,
						isAssigned: true,
						isDuplicate,
						isMisMatch: false
					})
				}
			}
		});
		return matchedStuds
	},
	misMatchedRecords: (state, getters) => ca_uuid => {
		return getters.misMatchedItemsCoach
			.filter(i => !isEmpty(i.coach_assignment))
			.filter(i => i.coach_assignment.ca_uuid == ca_uuid)
			.map(i => {
				let isDuplicate = i?.meta?.duplicate ? true : false
				return {
					...i.item,
					uuid: i.uuid,
					isAssigned: false,
					isDuplicate,
					isMisMatch: true
				}
			})
	},
}

export const coachReplacementReconciliationData = {
	namespaced: true,
	state,
	getters,
}
