import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const paymentScheduleService = {
  async getPaymentSchedules(params) {
    let getParams = processQueryParams(params)
    const getPaymentSchedulesRequest = {
      method: "GET",
      params: getParams
    }
    return await apiRequest("/api/payment-schedules", getPaymentSchedulesRequest)
  },
  async getPaymentSchedule(uuid) {
    const getPaymentScheduleRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/payment-schedules/${uuid}`, getPaymentScheduleRequest)
  },
  async createPaymentSchedule(params) {
    const createData = processInput(params)
    const createPaymentScheduleRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/payment-schedules", createPaymentScheduleRequest)
  },
  async editPaymentSchedule(data) {
    const { uuid } = data
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editPaymentScheduleRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/payment-schedules/${uuid}`, editPaymentScheduleRequest)
  },
  async deletePaymentSchedule(params) {
    const { uuid } = params;
    delete params['uuid'];
    const deletePaymentScheduleRequest = {
      method: "DELETE",
      params: processQueryParams(params)
    }
    return await apiRequest(`/api/payment-schedules/${uuid}`, deletePaymentScheduleRequest)
  },
}