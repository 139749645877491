import {
  GET_COURSE_START_FACULTYS_REQUEST,
  GET_COURSE_START_FACULTYS_SUCCESS,
  GET_COURSE_START_FACULTYS_ERROR,
  GET_COURSE_START_FACULTY_REQUEST,
  GET_COURSE_START_FACULTY_SUCCESS,
  GET_COURSE_START_FACULTY_ERROR,
  CREATE_COURSE_START_FACULTY_REQUEST,
  CREATE_COURSE_START_FACULTY_SUCCESS,
  CREATE_COURSE_START_FACULTY_ERROR,
  EDIT_COURSE_START_FACULTY_REQUEST,
  EDIT_COURSE_START_FACULTY_SUCCESS,
  EDIT_COURSE_START_FACULTY_ERROR,
  DELETE_COURSE_START_FACULTY_REQUEST,
  DELETE_COURSE_START_FACULTY_SUCCESS,
  DELETE_COURSE_START_FACULTY_ERROR,
  BROADCAST_DELETE_COURSE_START_FACULTY_SUCCESS
} from "@/actions"

import {
  courseStartFacultyService
} from "@/services"
import { unpackTransferLog } from "@/modules"
import { addNewFilter, isEmpty } from "@/utilities";

const state = {
  error: null,
  loading: false,
  loadedOnce: false,
  courseStartFacultyLoadedOnce: false,
  courseStartFacultys: [],
  courseStartFaculty: {}
}

const getters = {
  faculty: state => uuid => state.courseStartFacultys.filter(csf => csf.course_starts_uuid == uuid),
  sectionFaculty: state => (csUuid, section) => state.courseStartFacultys.filter(csf => csf.course_starts_uuid == csUuid && csf.section == section),
  csFacultyByUuid: state => uuid => state.courseStartFacultys.find(csf => csf.uuid == uuid),
  csFacultyByCSUuid: state => uuid => state.courseStartFacultys.filter(csf => csf.course_starts_uuid == uuid)
}

const actions = {
  async getCourseStartFacultys({ commit }) {
    commit("GET_COURSE_START_FACULTYS_REQUEST")
    const response = await courseStartFacultyService.getCourseStartFacultys();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_COURSE_START_FACULTYS_SUCCESS", data)
        break
      default:
        commit("GET_COURSE_START_FACULTYS_ERROR", data)
    }
    return response
  },

  async getCourseStartFaculty({ commit }, params) {
    commit("GET_COURSE_START_FACULTY_REQUEST")
    const response = await courseStartFacultyService.getCourseStartFaculty(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_COURSE_START_FACULTY_SUCCESS", data)
        break
      default:
        commit("GET_COURSE_START_FACULTY_ERROR", data)
        break
    }
    return response
  },
  async createCourseStartFaculty({ commit, dispatch }, params) {
    commit("CREATE_COURSE_START_FACULTY_REQUEST")
    const response = await courseStartFacultyService.createCourseStartFaculty(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_COURSE_START_FACULTY_SUCCESS", data) : commit("CREATE_COURSE_START_FACULTY_ERROR", data)
        data.success ? dispatch('alert/createAlertSuccess', "Faculty has been created.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
        break
      default:
        commit("CREATE_COURSE_START_FACULTY_ERROR", data)
    }
    return response
  },
  async editCourseStartFaculty({ commit, dispatch }, params) {
    commit("EDIT_COURSE_START_FACULTY_REQUEST")
    const response = await courseStartFacultyService.editCourseStartFaculty(params, params.uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_COURSE_START_FACULTY_SUCCESS", data)
        break
      default:
        commit("EDIT_COURSE_START_FACULTY_ERROR", data)
    }
    return response
  },
  async deleteCourseStartFaculty({ commit, dispatch }, uuid) {
    commit("DELETE_COURSE_START_FACULTY_REQUEST")
    const response = await courseStartFacultyService.deleteCourseStartFaculty(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit(DELETE_COURSE_START_FACULTY_SUCCESS, uuid) : commit("DELETE_COURSE_START_FACULTY_ERROR", data);
        data.success ? dispatch('alert/createAlertSuccess', "Faculty removed.", { root: true }) : null;
        data.error ? dispatch('alert/createAlertErrors', data?.errors ?? "Oops. Something went wrong.", { root: true }) : null;
        break
      default:
        commit("DELETE_COURSE_START_FACULTY_ERROR", data)
    }
    return response
  },

  async sendEvaluationLink({ commit, dispatch }, params) {
    const response = await courseStartFacultyService.sendEvaluationLink(params)
    const { status, data } = response;
    switch (status) {
      case 200:
        data.success ? dispatch('alert/createAlertSuccess', "Evaluation link sent.", { root: true }) : null;
        data.error ? dispatch('alert/createAlertErrors', data?.errors ?? "Oops. Something went wrong.", { root: true }) : null;
        break
      default:
        dispatch('alert/createAlertErrors', "Oops. Something went wrong.", { root: true });
        if (!isEmpty(data?.errors)) {
          dispatch('alert/createAlertErrors', data?.errors, { root: true });
        }
    }
    return response
  }
}

const mutations = {
  [GET_COURSE_START_FACULTYS_REQUEST]: state => {
    state.loading = true
  },
  [GET_COURSE_START_FACULTYS_SUCCESS]: (state, data) => {
    state.courseStartFacultys = addNewFilter(state.courseStartFacultys, data.course_start_facultys, data.force || false)
    state.courseStartFacultyLoadedOnce = true
    state.loading = false
    state.loadedOnce = true
  },
  [GET_COURSE_START_FACULTYS_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_COURSE_START_FACULTY_REQUEST]: state => {
    state.loading = true
  },
  [GET_COURSE_START_FACULTY_SUCCESS]: (state, data) => {
    let { course_start_faculty } = data
    state.courseStartFaculty = course_start_faculty
    state.loading = false
  },
  [GET_COURSE_START_FACULTY_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_COURSE_START_FACULTY_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_COURSE_START_FACULTY_SUCCESS]: (state, data) => {
    let { course_start_faculty } = data
    state.courseStartFacultys = addNewFilter(state.courseStartFacultys, [course_start_faculty])
    state.loading = false
  },
  [CREATE_COURSE_START_FACULTY_ERROR]: state => {
    state.loading = false
  },

  [EDIT_COURSE_START_FACULTY_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_COURSE_START_FACULTY_SUCCESS]: (state, data) => {
    let { course_start_faculty } = data
    state.courseStartFacultys = state.courseStartFacultys.map(i => i.uuid == course_start_faculty.uuid ? course_start_faculty : i)
    state.loading = false
  },
  [EDIT_COURSE_START_FACULTY_ERROR]: state => {
    state.loading = false
  },

  [DELETE_COURSE_START_FACULTY_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_COURSE_START_FACULTY_SUCCESS]: (state, uuid) => {
    state.courseStartFacultys = state.courseStartFacultys.filter(i => i.uuid != uuid)
    state.loading = false
  },
  [DELETE_COURSE_START_FACULTY_ERROR]: (state, data) => {
    state.loading = false
  },
  [BROADCAST_DELETE_COURSE_START_FACULTY_SUCCESS]: (state, data) => {
    const { course_start_faculty } = data
    state.courseStartFacultys = state.courseStartFacultys.filter(wa => wa.uuid !== course_start_faculty.uuid)
    state.loading = false
  },
}

export function unpackCourseStartFaculty(csf, commit) {

	if (!isEmpty(csf.transfer_logs)) {
    let transfer_student_logs = csf.transfer_logs.map(i => unpackTransferLog(i, commit))
    commit("transferStudentLog/GET_TRANSFER_STUDENTS_SUCCESS", { transfer_student_logs }, { root: true })
    delete csf.transfer_logs
	}
	if (!isEmpty(csf.finalized_students)) {
    let finalized_students = csf.finalized_students
    commit("finalizedStudent/GET_FINALIZE_STUDENTS_SUCCESS", { finalized_students }, { root: true })
    delete csf.finalized_students
	}

	return csf
}
export const courseStartFaculty = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
