export const GET_COACH_APPLICATIONS_REQUEST = "GET_COACH_APPLICATIONS_REQUEST"
export const GET_COACH_APPLICATIONS_SUCCESS = "GET_COACH_APPLICATIONS_SUCCESS"
export const GET_COACH_APPLICATIONS_ERROR = "GET_COACH_APPLICATIONS_ERROR"
export const GET_COACH_APPLICATION_REQUEST = "GET_COACH_APPLICATION_REQUEST"
export const GET_COACH_APPLICATION_SUCCESS = "GET_COACH_APPLICATION_SUCCESS"
export const GET_COACH_APPLICATION_ERROR = "GET_COACH_APPLICATION_ERROR"
export const CREATE_COACH_APPLICATION_REQUEST = "CREATE_COACH_APPLICATION_REQUEST"
export const CREATE_COACH_APPLICATION_SUCCESS = "CREATE_COACH_APPLICATION_SUCCESS"
export const CREATE_COACH_APPLICATION_ERROR = "CREATE_COACH_APPLICATION_ERROR"
export const EDIT_COACH_APPLICATION_REQUEST = "EDIT_COACH_APPLICATION_REQUEST"
export const EDIT_COACH_APPLICATION_SUCCESS = "EDIT_COACH_APPLICATION_SUCCESS"
export const EDIT_COACH_APPLICATION_ERROR = "EDIT_COACH_APPLICATION_ERROR"
export const DELETE_COACH_APPLICATION_REQUEST = "DELETE_COACH_APPLICATION_REQUEST"
export const DELETE_COACH_APPLICATION_SUCCESS = "DELETE_COACH_APPLICATION_SUCCESS"
export const DELETE_COACH_APPLICATION_ERROR = "DELETE_COACH_ERROR"
export const CLEAR_COACH_APPLICATION_STATE = "CLEAR_COACH_APPLICATION_STATE"
export const RESOLVE_SUBMISSION_ERROR = "RESOLVE_SUBMISSION_ERROR"