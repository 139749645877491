import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const universityProgramService = {
    async getUniversityPrograms(params) {
        const queryParams = processQueryParams(params)
        const getUniversityProgramsRequest = {
            method: "GET",
            params: queryParams
        }

        return await apiRequest("/api/university-programs", getUniversityProgramsRequest)
    },
    async createUniversityProgram(params) {
        const createData = processInput(params)
        const postUniversityProgramRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/university-programs", postUniversityProgramRequest)
    },
    async getUniversityProgram(params) {
        const { uuid } = params
        delete params['uuid']
        const getUnivProParams = processQueryParams(params) 
        const getUniversityProgramRequest = {
            method: "GET",
            params: getUnivProParams
        }

        return await apiRequest(`/api/university-programs/${uuid}`, getUniversityProgramRequest);
    },
    async editUniversityProgram(data, uuid) {

        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityProgramRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/university-programs/${uuid}`, updateUniversityProgramRequest);
    },
    async deleteUniversityProgram(params) {
        const { uuid } = params
        const deleteUniversityProgramRequest = {
            method: "DELETE",
            params: processQueryParams(params)
        }

        return await apiRequest(`/api/university-programs/${uuid}`, deleteUniversityProgramRequest);
    },
    async editUniversityPrograms(data, uuid) {

        let editData = processInput(data)
        const updateUniversityProgramRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/university-programs-bulk-update`, updateUniversityProgramRequest);
    },
}