<template>
  <CElementCover
    :boundaries="[{ sides: ['top', 'left'], query: '.media-body' }]"
    :opacity="opacity"
    :center="true"
    class="custom-element-cover"
  >
    <h1 class="d-inline">{{ loadingText }}</h1>
    <CSpinner
      :size="spinnerSize"
      :color="spinnerColor"
    />
  </CElementCover>
</template>

<script>
export default {
  name: "CustomElementCover",
  props: {
    opacity: {
      type: Number,
      default: 0.6,
      required: false
    },
    loadingText: {
      type: String,
      default: "Loading...",
      required: false
    },
    spinnerColor: {
      type: String,
      default: "success",
      required: false
    },
    spinnerSize: {
      type: String,
      default: "5xl",
      required: false
    }
  }
};
</script>
<style scoped>
.custom-element-cover {
  position: fixed !important;
  height: 100vh;
  width: 100vw;
  z-index: 999999;
  pointer-events: none;
}</style>
