import {
  GET_WORKING_ASSIGNMENTS_REQUEST,
  GET_WORKING_ASSIGNMENTS_SUCCESS,
  GET_WORKING_ASSIGNMENTS_ERROR,
  GET_WORKING_ASSIGNMENT_REQUEST,
  GET_WORKING_ASSIGNMENT_SUCCESS,
  GET_WORKING_ASSIGNMENT_ERROR,
  CREATE_WORKING_ASSIGNMENT_REQUEST,
  CREATE_WORKING_ASSIGNMENT_SUCCESS,
  CREATE_WORKING_ASSIGNMENT_ERROR,
  EDIT_WORKING_ASSIGNMENT_REQUEST,
  EDIT_WORKING_ASSIGNMENT_SUCCESS,
  EDIT_WORKING_ASSIGNMENT_ERROR,
  DELETE_WORKING_ASSIGNMENT_REQUEST,
  DELETE_WORKING_ASSIGNMENT_SUCCESS,
  DELETE_WORKING_ASSIGNMENT_ERROR,
  BROADCAST_DELETE_WORKING_ASSIGNMENT_SUCCESS
} from "@/actions"

import {
  workingAssignmentsService
} from "@/services"

import { unpackCoach } from "@/modules";
import { addNewFilter, isEmpty, isNumeric } from "@/utilities";
import { addDays, isAfter, format, parseISO } from 'date-fns'

const state = {
  error: null,
  loading: false,
  loadedOnce: false,
  workingAssignmentLoadedOnce: false,
  workingAssignments: [],
  workingAssignment: {}
}

const getters = {
  waLoading: state => state.loading,
  waLoadedOnce: state => state.loadedOnce,
  workingAssignments: state => state.workingAssignments,
  waByCoachUuid: (state) => cUuid => state.workingAssignments.find(wa => wa.coaches_uuid == cUuid),
  walcFilter: (state, getters, rootState, rootGetters) => lcUuid => {
    return state.workingAssignments.filter(wa => wa.lead_coach_uuid == lcUuid).map(wa => {
      let coachGetter = rootGetters["coaches/coachByUuid"]
      let educationGetter = rootGetters["coachEducation/coachEducationByCoachUuid"]
      let specialtiesGetter = rootGetters["coachSpecialties/coachSpecialtiesByCoachUuid"]
      let coachFutureAssignments = rootGetters["coachAssignment/coachFutureAssignments"]
      let coachActiveAssignments = rootGetters["coachAssignment/coachActiveAssignments"]

      // let waByCoachUuid = getters.waByCoachUuid
      // let wa = waByCoachUuid(c.uuid)

      let coach = coachGetter(wa.coaches_uuid)

      let checkStudents = {
        active: [],
        pending: [],
        future: []
      }

      let students = {
        active: 0,
        pending: 0,
        future: 0
      }

      let coachFutureAssignmentsData = coachFutureAssignments(wa.coaches_uuid)
      let coachActiveAssignmentsData = coachActiveAssignments(wa.coaches_uuid)

      coachActiveAssignmentsData.forEach(ica => {

        if (ica.status == 'assigned') {
          if (!checkStudents.active.includes(ica.uuid) && !checkStudents.pending.includes(ica.uuid) && !checkStudents.future.includes(ica.uuid)) {
            checkStudents.active.push(ica.uuid)
            students.active += ica.actual_students
          }
        } else if (ica.status != 'assigned') {
          if (!checkStudents.active.includes(ica.uuid) && !checkStudents.pending.includes(ica.uuid) && !checkStudents.future.includes(ica.uuid)) {
            checkStudents.pending.push(ica.uuid)
            students.pending += ica.actual_students
          }
        }
      })

      coachFutureAssignmentsData.forEach(ica => {
        if (ica.status == 'assigned') {
          if (!checkStudents.active.includes(ica.uuid) && !checkStudents.pending.includes(ica.uuid) && !checkStudents.future.includes(ica.uuid)) {
            checkStudents.future.push(ica.uuid)
            students.future += ica.actual_students
          }
        } else if (ica.status != 'assigned') {
          if (!checkStudents.active.includes(ica.uuid) && !checkStudents.pending.includes(ica.uuid) && !checkStudents.future.includes(ica.uuid)) {
            checkStudents.pending.push(ica.uuid)
            students.pending += ica.actual_students
          }
        }
      })

      return {
        ...wa,
        coach: coach,
        students,
        education: educationGetter(wa.coaches_uuid),
        specialties: specialtiesGetter(wa.coaches_uuid)
      }
    })
  },
  myCoaches: (state, getters, rootState, rootGetters) => lUuid => {

    let findCoach = rootGetters["coaches/coachByUuid"];
    let findCoachAssignments = rootGetters["coachAssignment/coachAssignmentsByCoachUuid"];
    let findCS = rootGetters["courseStart/csByUuid"];
    let findCourse = rootGetters["course/courseFindUuid"];
    let findUP = rootGetters["universityProgram/uniProUuidFilter"];
    let findU = rootGetters["university/universityFilter"];
    let findWAS = rootGetters["workingAssignmentSheet/wasByStartDateAndLength"];

    let leadCoachUuid = lUuid;

    if (isEmpty(leadCoachUuid)) {
      if (rootGetters["auth/canAccess"]('lead-coach')) {
        leadCoachUuid = rootGetters["auth/authorizedUserUuid"]
      } else {
        return []
      }
    }
    return state.workingAssignments.filter(wa => wa.lead_coach_uuid == leadCoachUuid || wa.shareable?.includes(leadCoachUuid)).map(wa => {
      let coach = findCoach(wa.coaches_uuid)
      let cas = findCoachAssignments(wa.coaches_uuid).filter(i => isEmpty(i.deleted_at))

      let total_active_students = 0,
        total_future_students = 0,
        links = [];
      if (!isEmpty(cas)) {
        cas.forEach(ca => {
          let cs = findCS(ca.course_starts_uuid)
          if (!isEmpty(cs)) {
            let course = findCourse(cs.courses_uuid)
            if (!isEmpty(course)) {
              let up = findUP(course.university_programs_uuid)
              if (!isEmpty(up)) {
                let u = findU(up.university_uuid)
                if (!isEmpty(u)) {
                  let was = findWAS(cs.start_date, cs.course_length, up.uuid)
                  if (!isEmpty(was)) {
                    let startDateParsed = parseISO(was.start_date)
                    let endDateParsed = !isEmpty(was.end_date_override) ? parseISO(was.end_date_override) : addDays(startDateParsed, (was.course_length * 7))

                    if (isAfter(endDateParsed, new Date())) {

                      if (isAfter(startDateParsed, new Date())) {
                        total_future_students += isNumeric(ca.actual_students) ? parseInt(ca.actual_students) : 0;
                      } else {
                        total_active_students += isNumeric(ca.actual_students) ? parseInt(ca.actual_students) : 0;
                      }

                      links.push({
                        was_label: `${u.abbreviation} - ${format(startDateParsed, 'MMM dd, yyyy')} (${was.course_length} Week) ${course.number}`,
                        was_uuid: was.uuid,
                        cs_uuid: cs.uuid,
                        start_date: was.start_date,
                      })
                    }
                  }
                }
              }
            }
          }
        })
      }

      let coachStatus = rootGetters['coachStatus/coachStatusByCoachUuid'](wa.coaches_uuid)
      let status = {}

      if (!isEmpty(coachStatus)) {
        status = rootGetters['status/statusFilterByUuid'](coachStatus.statuses_uuid)
      }

      if (!isEmpty(links)) {
        links = links.sort((b, a) => new Date(b.start_date) - new Date(a.start_date))
      }

      return {
        ...wa,
        coach,
        total_active_students,
        total_future_students,
        links,
        status
      }
    })

  },
  mySharedCoaches: (state) => lUuid => {
    // return state.workingAssignments.filter(wa => wa?.shareable.includes(lUuid));
    return [];
  }
}

const actions = {
  async getWorkingAssignments({ commit }) {
    commit("GET_WORKING_ASSIGNMENTS_REQUEST")
    const response = await workingAssignmentsService.getWorkingAssignments();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_WORKING_ASSIGNMENTS_SUCCESS", data)
        break
      default:
        commit("GET_WORKING_ASSIGNMENTS_ERROR", data)
    }
    return response
  },

  async getWorkingAssignment({ commit }, params) {
    commit("GET_WORKING_ASSIGNMENT_REQUEST")
    const response = await workingAssignmentsService.getWorkingAssignment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_WORKING_ASSIGNMENT_SUCCESS", data)
        break
      default:
        commit("GET_WORKING_ASSIGNMENT_ERROR", data)
        break
    }
    return response
  },
  async createWorkingAssignment({ commit, dispatch }, params) {
    commit("CREATE_WORKING_ASSIGNMENT_REQUEST")
    const response = await workingAssignmentsService.createWorkingAssignment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_WORKING_ASSIGNMENT_SUCCESS", data) : commit("CREATE_WORKING_ASSIGNMENT_ERROR", data)
        data.success ? dispatch('alert/createAlertSuccess', "Coach is ready for assignment.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
        break
      default:
        commit("CREATE_WORKING_ASSIGNMENT_ERROR", data)
    }
    return response
  },
  async editWorkingAssignment({ commit }, params) {
    commit("EDIT_WORKING_ASSIGNMENT_REQUEST")
    const response = await workingAssignmentsService.editWorkingAssignment(params, params.uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_WORKING_ASSIGNMENT_SUCCESS", data)
        break
      default:
        commit("EDIT_WORKING_ASSIGNMENT_ERROR", data)
    }
    return response
  },
  async deleteWorkingAssignment({ commit }, params) {
    commit("DELETE_WORKING_ASSIGNMENT_REQUEST")
    const uuid = params.uuid
    const response = await workingAssignmentsService.deleteWorkingAssignment(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit(DELETE_WORKING_ASSIGNMENT_SUCCESS, uuid) : commit("DELETE_WORKING_ASSIGNMENT_ERROR", data)
        break
      default:
        commit("DELETE_WORKING_ASSIGNMENT_ERROR", data)
    }
    return response
  }
}

const mutations = {
  [GET_WORKING_ASSIGNMENTS_REQUEST]: state => {
    state.loading = true
  },
  [GET_WORKING_ASSIGNMENTS_SUCCESS]: (state, data) => {
    state.workingAssignments = addNewFilter(state.workingAssignments, data.working_assignments)
    state.workingAssignmentLoadedOnce = true
    state.loading = false
    state.loadedOnce = true
  },
  [GET_WORKING_ASSIGNMENTS_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_WORKING_ASSIGNMENT_REQUEST]: state => {
    state.loading = true
  },
  [GET_WORKING_ASSIGNMENT_SUCCESS]: (state, data) => {
    let { working_assignment } = data
    state.workingAssignment = working_assignment
    state.loading = false
  },
  [GET_WORKING_ASSIGNMENT_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_WORKING_ASSIGNMENT_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_WORKING_ASSIGNMENT_SUCCESS]: (state, data) => {
    let { working_assignment } = data
    state.workingAssignments = addNewFilter(state.workingAssignments, [working_assignment])
    state.loading = false
  },
  [CREATE_WORKING_ASSIGNMENT_ERROR]: state => {
    state.loading = false
  },

  [EDIT_WORKING_ASSIGNMENT_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_WORKING_ASSIGNMENT_SUCCESS]: (state, data) => {
    state.workingAssignments = state.workingAssignments.filter(i => i.uuid != data.working_assignments.uuid)
    state.workingAssignments.push(data.working_assignments)
    state.loading = false
  },
  [EDIT_WORKING_ASSIGNMENT_ERROR]: state => {
    state.loading = false
  },

  [DELETE_WORKING_ASSIGNMENT_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_WORKING_ASSIGNMENT_SUCCESS]: (state, uuid) => {
    state.workingAssignments = state.workingAssignments.filter(i => i.uuid != uuid)
    state.loading = false
  },
  [DELETE_WORKING_ASSIGNMENT_ERROR]: (state, data) => {
    state.loading = false
  },
  [BROADCAST_DELETE_WORKING_ASSIGNMENT_SUCCESS]: (state, data) => {
    const { working_assignment } = data
    state.workingAssignments = state.workingAssignments.filter(wa => wa.uuid != working_assignment.uuid)
    state.loading = false
  },
}

export function unpackWA(wa, commit) {
  if (!isEmpty(wa.comments)) {
    commit("comments/GET_COMMENTS_SUCCESS", { comments: wa.comments }, { root: true })
    delete wa.comments
  }
  if (!isEmpty(wa.coach)) {
    let coach = unpackCoach(wa.coach, commit)
    commit("coaches/GET_COACHES_SUCCESS", { coaches: [coach] }, { root: true })
  }
  return wa
}
export const workingAssignment = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
