export const GET_UNIVERSITY_PROSPECT_DOCUMENTS_REQUEST = "GET_UNIVERSITY_PROSPECT_DOCUMENTS_REQUEST"
export const GET_UNIVERSITY_PROSPECT_DOCUMENTS_SUCCESS = "GET_UNIVERSITY_PROSPECT_DOCUMENTS_SUCCESS"
export const GET_UNIVERSITY_PROSPECT_DOCUMENTS_ERROR = "GET_UNIVERSITY_PROSPECT_DOCUMENTS_ERROR"
export const GET_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST = "GET_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST"
export const GET_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS = "GET_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS"
export const GET_UNIVERSITY_PROSPECT_DOCUMENT_ERROR = "GET_UNIVERSITY_PROSPECT_DOCUMENT_ERROR"
export const CREATE_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST = "CREATE_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST"
export const CREATE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS = "CREATE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS"
export const CREATE_UNIVERSITY_PROSPECT_DOCUMENT_ERROR = "CREATE_UNIVERSITY_PROSPECT_DOCUMENT_ERROR"
export const EDIT_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST = "EDIT_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST"
export const EDIT_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS = "EDIT_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS"
export const EDIT_UNIVERSITY_PROSPECT_DOCUMENT_ERROR = "EDIT_UNIVERSITY_PROSPECT_DOCUMENT_ERROR"
export const DELETE_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST = "DELETE_UNIVERSITY_PROSPECT_DOCUMENT_REQUEST"
export const DELETE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS = "DELETE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS"
export const BROADCAST_DELETE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS = "BROADCAST_DELETE_UNIVERSITY_PROSPECT_DOCUMENT_SUCCESS"
export const DELETE_UNIVERSITY_PROSPECT_DOCUMENT_ERROR = "DELETE_UNIVERSITY_PROSPECT_DOCUMENT_ERROR"