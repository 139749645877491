import {
  apiRequest,
  processInput
} from "@/utilities"

export const wasUniversityProgramService = {
  async updateUpcomingSheetStructure(params) {
    const updateUpcomingStructureRequest = {
      method: "POST",
      data: processInput(params)
    }
    return await apiRequest("/api/was-university-programs/update-upcoming-sheet-structure", updateUpcomingStructureRequest)
  },
  // async createWorkingAssignment(params) {
  //   const createData = processInput(params);
  //   const postWorkingAssignmentRequest = {
  //     method: "POST",
  //     data: createData
  //   }

  //   return await apiRequest("/api/working-assignments", postWorkingAssignmentRequest)
  // },
  // async getWorkingAssignment(uuid) {

  //   const getWorkingAssignmentRequest = {
  //     method: "GET"
  //   }

  //   return await apiRequest(`/api/working-assignments/${uuid}`, getWorkingAssignmentRequest);
  // },

  async editWasUniversityProgram(data) {
    const { uuid } = data;
    data._method = "PATCH"
    const editWasUniversityProgramRequest = {
      method: "POST",
      data: processInput(data)
    }

    return await apiRequest(`/api/was-university-programs/${uuid}`, editWasUniversityProgramRequest);
  },

  // async deleteWorkingAssignment(data, uuid) {
  //   let deleteData = processInput(data)

  //   const deleteWorkingAssignmentRequest = {
  //     method: "DELETE",
  //     data: deleteData
  //   }

  //   return await apiRequest(`/api/working-assignments/${uuid}`, deleteWorkingAssignmentRequest);
  // }
}
