import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachLmsExperienceService = {
    async getCoachLmsExperiences(params) {
        let getParams = processQueryParams(params)
        const getCoachLmsExperiencesRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-lms-experiences", getCoachLmsExperiencesRequest)
    },
    async getCoachLmsExperience(uuid) {
        const getCoachLmsExperienceRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-lms-experiences/${uuid}`, getCoachLmsExperienceRequest)
    },
    async createCoachLmsExperience(params) {
        const createData = processInput(params)
        const createCoachLmsExperienceRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-lms-experiences", createCoachLmsExperienceRequest)
    },
    async editCoachLmsExperience(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachLmsExperienceRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-lms-experiences/${uuid}`, editCoachLmsExperienceRequest)
    },
    async deleteCoachLmsExperience(uuid) {
        const deleteCoachLmsExperienceRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-lms-experiences/${uuid}`, deleteCoachLmsExperienceRequest)
    }
}