import {
    apiRequest,
    processInput
} from "@/utilities"

export const employmentTypeService = {
    async getEmploymentTypes() {
        const getEmploymentTypesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/employment-types", getEmploymentTypesRequest)
    },
    async getEmploymentType(uuid) {
        const getEmploymentTypeRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/employment-types/${uuid}`, getEmploymentTypeRequest)
    },
    async createEmploymentType(params) {
        const createData = processInput(params)
        const createEmploymentTypeRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/employment-types", createEmploymentTypeRequest)
    },
    async editEmploymentType(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editEmploymentTypeRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/employment-types/${uuid}`, editEmploymentTypeRequest)
    },
    async deleteEmploymentType(uuid) {
        const deleteEmploymentTypeRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/employment-types/${uuid}`, deleteEmploymentTypeRequest)
    }
}