import {
	GET_USERS_REQUEST,
	GET_USERS_SUCCESS,
	GET_USERS_ERROR,
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_USER_ERROR,
	CREATE_USER_REQUEST,
	CREATE_USER_SUCCESS,
	CREATE_USER_ERROR,
	EDIT_USER_REQUEST,
	EDIT_USER_SUCCESS,
	EDIT_USER_ERROR,
	DELETE_USER_REQUEST,
	DELETE_USER_SUCCESS,
	DELETE_USER_ERROR,
	CREATE_USER_GROUPS_SUCCESS,
	DELETE_USER_GROUPS_SUCCESS
} from "@/actions"

import { usersService } from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
	loading: false,
	loadedOnce: false,
	user: null,
	users: []
}

const getters = {
	usersLoading: state => state.loading,
	usersLoadedOnce: state => state.loadedOnce,
	userByUuid: state => uuid => state.users.find(u => u.uuid == uuid),
	usersByRoleSlug: state => roleSlug => state.users.filter(u => u.roles.includes(roleSlug)),
	universityAdminUsersByUniversityUuid: state => uuid => state.users.filter(u => u.roles.includes('university-admin') && u.meta?.university_uuid == uuid),
	adminUsers: state => state.users.filter(u => !u.roles.some(j => j == 'coach')),
	usersByNotRoleSlugNonCoach: state => roleSlug => state.users.filter(u => !u.roles.includes(roleSlug) && !u.roles.includes('coach')),
	user: state => state.user,
	users: state => state.users,
}

const actions = {
	async getUsers({ commit }) {
		commit("GET_USERS_REQUEST")
		const response = await usersService.getUsers();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_USERS_SUCCESS", data)
				break;
			default:
				commit("GET_USERS_ERROR", data)
		}
		return response
	},
	async createUser({ commit, dispatch }, userData) {
		commit("CREATE_USER_REQUEST")
		const response = await usersService.createUser(userData)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data?.success) {
					commit("CREATE_USER_SUCCESS", data);
					dispatch('alert/createAlertSuccess', "User created.", { root: true })
				}

				break
			case 403:
				commit("CREATE_USER_ERROR", data)
				const { message } = data
				let messages = message ?? "NOT Authorized for this action."
				dispatch('alert/createAlertErrors', messages, { root: true })
				break
			default:
				commit("CREATE_USER_ERROR", data)
		}
		return response
	},
	async getUser({ commit, dispatch }, params) {
		commit("GET_USER_REQUEST")
		const response = await usersService.getUser(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					data.user = unpackUser(data.user, commit)
					commit("GET_USER_SUCCESS", data)
				}
				break
			default:
				commit("GET_USER_ERROR", data)

		}
		return response
	},
	async editUser({ commit, dispatch }, params) {
		commit("EDIT_USER_REQUEST");
		const { uuid } = params
		const response = await usersService.editUser(params, uuid);
		const { status, data } = response
		switch (status) {
			case 200:

				if (data.success) {
					commit("EDIT_USER_SUCCESS", data);
					dispatch("alert/createAlertSuccess", "User updated.", { root: true })
				} else {
					commit("GET_USER_ERROR", data)
				}

				break
			default:
				commit("GET_USER_ERROR", data);
		}

		return response;
	},
	async deleteUser({ commit, dispatch }, params) {
		commit("DELETE_USER_REQUEST");
		const response = await usersService.deleteUser(params);
		const { status, data } = response;
		switch (status) {
			case 200:
				commit("DELETE_USER_SUCCESS", data);
				dispatch("alert/createAlertSuccess", "User deleted.", { root: true });
				break
			default:
				commit("DELETE_USER_ERROR", data)
		}
	},
	async userRestore({ commit, dispatch }, params) {
		commit("DELETE_USER_REQUEST");
		const response = await usersService.userRestore(params);
		const { status, data } = response;
		switch (status) {
			case 200:
				commit("DELETE_USER_SUCCESS", data);
				dispatch("alert/createAlertSuccess", "User restored.", { root: true })
				break
			default:
				commit("DELETE_USER_ERROR", data)
		}
	}
}

const mutations = {
	[GET_USERS_REQUEST]: state => {
		state.loading = true
	},
	[GET_USERS_SUCCESS]: (state, data) => {
		const users = data.users
		state.users = addNewFilter(state.users, users, true)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_USERS_ERROR]: (state) => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},

	[CREATE_USER_REQUEST]: (state) => {
		state.loading = true
	},
	[CREATE_USER_SUCCESS]: (state, data) => {
		const { user } = data
		state.users = addNewFilter(state.users, [user])
		state.loading = false
	},
	[CREATE_USER_ERROR]: (state) => {
		state.loading = false
	},

	[GET_USER_REQUEST]: state => {
		state.loading = true
	},
	[GET_USER_SUCCESS]: (state, data) => {
		const { user } = data
		state.users = addNewFilter(state.users, [user])
		// state.user = user;
		state.loading = false
	},
	[GET_USER_ERROR]: state => {
		state.loading = false
		state.error = true
	},

	[EDIT_USER_REQUEST]: (state) => {
		state.loading = true
	},
	[EDIT_USER_SUCCESS]: (state, data) => {
		const { user } = data
		state.users = addNewFilter(state.users, [user])
		state.loading = false
	},
	[EDIT_USER_ERROR]: state => {
		state.loading = false
	},
	[DELETE_USER_REQUEST]: (state, userID) => {

	},
	[DELETE_USER_SUCCESS]: (state, data) => {
		const { user } = data
		state.users = addNewFilter(state.users, [user])
	},
	[DELETE_USER_ERROR]: state => {

	},

	[CREATE_USER_GROUPS_SUCCESS]: (state, data) => {
		const { user } = data
		state.users = state.users.map(u => u.uuid == user.uuid ? user : u)
		if (!isEmpty(state.user) && state.user.uuid == user.uuid) {
			state.user = user
		}
	},
	[DELETE_USER_GROUPS_SUCCESS]: (state, data) => {
		const { user } = data
		state.users = state.users.map(u => u.uuid == user.uuid ? user : u)
		if (!isEmpty(state.user) && state.user.uuid == user.uuid) {
			state.user = user
		}
	}
}

export function unpackUser(user, commit) {
	Object.keys(user).forEach(k => {
		if (!isEmpty(user[k])) {
			let data = {}
			switch (k) {
				case 'addresses':
					data.addresses = user[k] ?? []
					commit('address/GET_ADDRESSES_SUCCESS', data, { root: true })
					delete user[k]
					break;
				case "payment_schedules":
					data.payment_schedules = user[k] ?? []
					commit('paymentSchedule/GET_PAYMENT_SCHEDULES_SUCCESS', data, { root: true })
					delete user[k]
					break;
				case 'phones':
					data.phones = user[k] ?? []
					commit('phone/GET_PHONES_SUCCESS', data, { root: true })
					delete user[k]
					break;
				case 'user_setting':
					data.user_setting = user[k] ?? []
					commit('userSetting/GET_USER_SETTINGS_SUCCESS', data, { root: true })
					delete user[k]
					break;
				case 'lead_evaluations':
					data.lead_evaluations = user[k] ?? []
					commit('leadEvaluation/GET_LEAD_EVALUATIONS_SUCCESS', data, { root: true })
					delete user[k]
					break;
				case 'pa_evaluations':
					data.pa_evaluations = user[k] ?? []
					commit('paEvaluation/GET_PA_EVALUATIONS_SUCCESS', data, { root: true })
					delete user[k]
					break;
				case 'perfomance_notes':
					data.perfomance_notes = user[k] ?? []
					commit('perfomanceNote/GET_PERFORMANCE_NOTES_SUCCESS', data, { root: true })
					delete user[k]
					break;
			}
		}
	})

	return user
}

export const users = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
