export const GET_COACH_TEACHING_COURSE_LEVELS_REQUEST = "GET_COACH_TEACHING_COURSE_LEVELS_REQUEST"
export const GET_COACH_TEACHING_COURSE_LEVELS_SUCCESS = "GET_COACH_TEACHING_COURSE_LEVELS_SUCCESS"
export const GET_COACH_TEACHING_COURSE_LEVELS_ERROR = "GET_COACH_TEACHING_COURSE_LEVELS_ERROR"
export const GET_COACH_TEACHING_COURSE_LEVEL_REQUEST = "GET_COACH_TEACHING_COURSE_LEVEL_REQUEST"
export const GET_COACH_TEACHING_COURSE_LEVEL_SUCCESS = "GET_COACH_TEACHING_COURSE_LEVEL_SUCCESS"
export const GET_COACH_TEACHING_COURSE_LEVEL_ERROR = "GET_COACH_TEACHING_COURSE_LEVEL_ERROR"
export const CREATE_COACH_TEACHING_COURSE_LEVEL_REQUEST = "CREATE_COACH_TEACHING_COURSE_LEVEL_REQUEST"
export const CREATE_COACH_TEACHING_COURSE_LEVEL_SUCCESS = "CREATE_COACH_TEACHING_COURSE_LEVEL_SUCCESS"
export const CREATE_COACH_TEACHING_COURSE_LEVEL_ERROR = "CREATE_COACH_TEACHING_COURSE_LEVEL_ERROR"
export const EDIT_COACH_TEACHING_COURSE_LEVEL_REQUEST = "EDIT_COACH_TEACHING_COURSE_LEVEL_REQUEST"
export const EDIT_COACH_TEACHING_COURSE_LEVEL_SUCCESS = "EDIT_COACH_TEACHING_COURSE_LEVEL_SUCCESS"
export const EDIT_COACH_TEACHING_COURSE_LEVEL_ERROR = "EDIT_COACH_TEACHING_COURSE_LEVEL_ERROR"
export const DELETE_COACH_TEACHING_COURSE_LEVEL_REQUEST = "DELETE_COACH_TEACHING_COURSE_LEVEL_REQUEST"
export const DELETE_COACH_TEACHING_COURSE_LEVEL_SUCCESS = "DELETE_COACH_TEACHING_COURSE_LEVEL_SUCCESS"
export const DELETE_COACH_TEACHING_COURSE_LEVEL_ERROR = "DELETE_COACH_TEACHING_COURSE_LEVEL_ERROR"
export const CLEAR_COACH_TEACHING_COURSE_LEVEL_STATE = "CLEAR_COACH_TEACHING_COURSE_LEVEL_STATE"