import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachTeachingCourseLevelService = {
    async getCoachTeachingCourseLevels(params) {
        let getParams = processQueryParams(params)
        const getCoachTeachingCourseLevelsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-teaching-course-levels", getCoachTeachingCourseLevelsRequest)
    },
    async getCoachTeachingCourseLevel(uuid) {
        const getCoachTeachingCourseLevelRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-teaching-course-levels/${uuid}`, getCoachTeachingCourseLevelRequest)
    },
    async createCoachTeachingCourseLevel(params) {
        const createData = processInput(params)
        const createCoachTeachingCourseLevelRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-teaching-course-levels", createCoachTeachingCourseLevelRequest)
    },
    async editCoachTeachingCourseLevel(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachTeachingCourseLevelRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-teaching-course-levels/${uuid}`, editCoachTeachingCourseLevelRequest)
    },
    async deleteCoachTeachingCourseLevel(uuid) {
        const deleteCoachTeachingCourseLevelRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-teaching-course-levels/${uuid}`, deleteCoachTeachingCourseLevelRequest)
    }
}