import {
	GET_EMPLOYMENT_TYPES_REQUEST,
	GET_EMPLOYMENT_TYPES_SUCCESS,
	GET_EMPLOYMENT_TYPES_ERROR,
	GET_EMPLOYMENT_TYPE_REQUEST,
	GET_EMPLOYMENT_TYPE_SUCCESS,
	GET_EMPLOYMENT_TYPE_ERROR,
	CREATE_EMPLOYMENT_TYPE_REQUEST,
	CREATE_EMPLOYMENT_TYPE_SUCCESS,
	CREATE_EMPLOYMENT_TYPE_ERROR,
	EDIT_EMPLOYMENT_TYPE_REQUEST,
	EDIT_EMPLOYMENT_TYPE_SUCCESS,
	EDIT_EMPLOYMENT_TYPE_ERROR,
	DELETE_EMPLOYMENT_TYPE_REQUEST,
	DELETE_EMPLOYMENT_TYPE_SUCCESS,
	DELETE_EMPLOYMENT_TYPE_ERROR
} from "../../actions"

import {
	employmentTypeService
} from "../../services"

const state = {
	employmentTypes: [],
	employmentType: {},
	loading: false
}

const sortMap = {
	"full-time-still-employed": 1,
	"full-time-no-longer-employed": 2,
	"part-time-still-employed": 3,
	"part-time-no-longer-employed": 4,
	"prn-still-employed": 5,
	"prn-no-longer-employed": 6,
	"contractor-still-employed": 7,
	"contractor-no-longer-employed": 8,
	"retired": 9,

}

const getters = {
	employmentTypes: state => state.employmentTypes.sort((a, b) => sortMap[a.slug] - sortMap[b.slug]),
	employmentTypeSelectOptions: state => {
		return state.employmentTypes.map(employmentType => {
			return {
				value: employmentType.uuid,
				label: employmentType.label,
				attrs: {
					'data-slug': employmentType.slug
				},
				sort: sortMap[employmentType.slug ?? "retired"]
			}
		}).sort((a, b) => a.sort - b.sort);
	}
}

const actions = {
	async getEmploymentTypes({ commit }) {
		commit("GET_EMPLOYMENT_TYPES_REQUEST")
		const response = await employmentTypeService.getEmploymentTypes();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_EMPLOYMENT_TYPES_SUCCESS", data)
				break
			default:
				commit("GET_EMPLOYMENT_TYPES_ERROR", data)
		}
		return response
	},
	async getEmploymentType({ commit }, params) {
		commit("GET_EMPLOYMENT_TYPE_REQUEST")
		const response = await employmentTypeService.getEmploymentType(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_EMPLOYMENT_TYPE_SUCCESS", data)
				break
			default:
				commit("GET_EMPLOYMENT_TYPE_ERROR", data)
				break
		}
		return response
	},
	async createEmploymentType({ commit }, params) {
		commit("CREATE_EMPLOYMENT_TYPE_REQUEST")
		const response = await employmentTypeService.createEmploymentType(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_EMPLOYMENT_TYPE_SUCCESS", data) : commit("CREATE_EMPLOYMENT_TYPE_ERROR", data)
				break
			default:
				commit("CREATE_EMPLOYMENT_TYPE_ERROR", data)
		}
		return response
	},
	async editEmploymentType({ commit }, params, uuid) {
		commit("EDIT_EMPLOYMENT_TYPE_REQUEST")
		const response = await employmentTypeService.editEmploymentType(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_EMPLOYMENT_TYPE_SUCCESS", data)
				break
			default:
				commit("EDIT_EMPLOYMENT_TYPE_ERROR", data)
		}
		return response
	},
	async deleteEmploymentType({ commit }, params) {
		commit("DELETE_EMPLOYMENT_TYPE_REQUEST")
		const response = await employmentTypeService.deleteEmploymentType(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_EMPLOYMENT_TYPE_SUCCESS", data)
				break
			default:
				commit("DELETE_EMPLOYMENT_TYPE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_EMPLOYMENT_TYPES_REQUEST]: state => {
		state.loading = true
	},
	[GET_EMPLOYMENT_TYPES_SUCCESS]: (state, data) => {
		const employmentTypes = data.employment_types
		state.employmentTypes = employmentTypes
		state.loading = false
	},
	[GET_EMPLOYMENT_TYPES_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_EMPLOYMENT_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[GET_EMPLOYMENT_TYPE_SUCCESS]: (state, data) => {
		const employmentType = { ...data.employment_type }
		state.employmentType = employmentType
		state.loading = false
	},
	[GET_EMPLOYMENT_TYPE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_EMPLOYMENT_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_EMPLOYMENT_TYPE_SUCCESS]: (state, data) => {
		let employmentType = { ...data.employment_type }
		state.employmentTypes.push(employmentType)
		state.loading = false
	},
	[CREATE_EMPLOYMENT_TYPE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_EMPLOYMENT_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_EMPLOYMENT_TYPE_SUCCESS]: state => {
		state.loading = false
	},
	[EDIT_EMPLOYMENT_TYPE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_EMPLOYMENT_TYPE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_EMPLOYMENT_TYPE_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_EMPLOYMENT_TYPE_ERROR]: (state, data) => {
		state.loading = false
	}
}

export const employmentType = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}