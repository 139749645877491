import {
	GET_NOTIFICATIONS_REQUEST,
	GET_NOTIFICATIONS_SUCCESS,
	GET_NOTIFICATIONS_ERROR,
	GET_NOTIFICATION_REQUEST,
	GET_NOTIFICATION_SUCCESS,
	GET_NOTIFICATION_ERROR,
	CREATE_NOTIFICATION_REQUEST,
	CREATE_NOTIFICATION_SUCCESS,
	CREATE_NOTIFICATION_ERROR,
	EDIT_NOTIFICATION_REQUEST,
	EDIT_NOTIFICATION_SUCCESS,
	EDIT_NOTIFICATION_ERROR,
	DELETE_NOTIFICATION_REQUEST,
	DELETE_NOTIFICATION_SUCCESS,
	DELETE_NOTIFICATION_ERROR,
	ADD_PUSHER_NOTIFICATION,
} from "@/actions"

import {
	notificationService
} from "@/services"

const notificationDefaultState = () => ({
	notifications: [],
	notification: {},
	loading: false,
	loadedOnce: false
});

const state = notificationDefaultState();

// const state = {
// 	notifications: [],
// 	notification: {},
// 	loading: false,
// 	loadedOnce: false
// }

import { addNewFilter, isEmpty } from "@/utilities"

const getters = {
	notificationsLoadedOnce: state => state.loadedOnce,
	notifications: state => state.notifications.sort((b, a) => new Date(a.created_at) - new Date(b.created_at)),
}

const actions = {
	async getNotifications({ commit }, params) {
		commit("GET_NOTIFICATIONS_REQUEST")
		const response = await notificationService.getNotifications(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_NOTIFICATIONS_SUCCESS", data)
				break
			default:
				commit("GET_NOTIFICATIONS_ERROR", data)
		}
		return response
	},
	// async getNotification({ commit }, params) {
	//     commit("GET_NOTIFICATION_REQUEST")
	//     const response = await notificationService.getNotification(params)
	//     const { status, data } = response
	//     switch (status) {
	//         case 200:
	//             commit("GET_NOTIFICATION_SUCCESS", data)
	//             break
	//         default:
	//             commit("GET_NOTIFICATION_ERROR", data)
	//             break
	//     }
	//     return response
	// },
	// async createNotification({ commit }, message) {
	//     commit("CREATE_NOTIFICATION_REQUEST")
	//     // const response = await notificationService.createNotification(params)
	//     commit("CREATE_NOTIFICATION_SUCCESS", message)
	//     return true
	// },
	async editNotification({ commit }, params) {
		commit("EDIT_NOTIFICATION_REQUEST")
		const uuid = params.uuid
		const response = await notificationService.editNotification(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:

				commit("EDIT_NOTIFICATION_SUCCESS", { data, uuid, params })
				break
			default:
				commit("EDIT_NOTIFICATION_ERROR", data)
		}
		return response
	},
	async deleteNotification({ commit }, params) {
		commit("DELETE_NOTIFICATION_REQUEST")
		const response = await notificationService.deleteNotification(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_NOTIFICATION_SUCCESS", params)
				break
			default:
				commit("DELETE_NOTIFICATION_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_NOTIFICATIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_NOTIFICATIONS_SUCCESS]: (state, data) => {
		const notifications = data.notifications
		state.notifications = addNewFilter(state.notifications, notifications)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_NOTIFICATIONS_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_NOTIFICATION_REQUEST]: state => {
		state.loading = true
	},
	[GET_NOTIFICATION_SUCCESS]: (state, data) => {
		const { notification } = data.notification
		state.notification = notification
		state.notifications = addNewFilter(state.notifications, [notification])
		state.notifications = state.notifications.sort((b, a) => new Date(a.created_at) - new Date(b.created_at))
		state.loading = false
	},
	[GET_NOTIFICATION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_NOTIFICATION_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_NOTIFICATION_SUCCESS]: (state, data) => {
		let { notification } = data
		notification.data = [notification[0]]
		delete notification[0]
		notification.read_at = null
		notification.created_at = new Date().toISOString()
		state.notification = notification
		state.notifications.push(notification)
		state.loading = false
	},
	[CREATE_NOTIFICATION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_NOTIFICATION_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_NOTIFICATION_SUCCESS]: (state, responseData) => {
		let { uuid, params } = responseData
		if (!isEmpty(params.additional_ids)) {
			state.notifications = state.notifications.map(i => {
				if (params.additional_ids.some(j => j == i.id)) {
					return {
						...i,
						read_at: new Date().toISOString
					}
				} else {
					return i
				}
			})
		} else {
			const notification = state.notifications.find(i => i.id == uuid)
			notification.read_at = new Date().toISOString
			state.notification = notification
			state.notifications = state.notifications.filter(i => i.id != uuid)
			state.notifications.push(notification)
		}
		state.loading = false
	},
	[EDIT_NOTIFICATION_ERROR]: state => {
		state.loading = false
	},

	[DELETE_NOTIFICATION_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_NOTIFICATION_SUCCESS]: (state, data) => {
		state.notifications = state.notifications.filter(n => !data.additional_ids.some(i => i == n.id))
		state.loading = false
	},
	[DELETE_NOTIFICATION_ERROR]: (state, data) => {
		state.loading = false
	},

	RESET_NOTIFICATION_STATE: state => {
		Object.assign(state, notificationDefaultState());
	}
}

export const notification = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
