import {
	GET_PA_EVALUATIONS_REQUEST,
	GET_PA_EVALUATIONS_SUCCESS,
	GET_PA_EVALUATIONS_ERROR,
	GET_PA_EVALUATION_REQUEST,
	GET_PA_EVALUATION_SUCCESS,
	GET_PA_EVALUATION_ERROR,
	CREATE_PA_EVALUATION_REQUEST,
	CREATE_PA_EVALUATION_SUCCESS,
	CREATE_PA_EVALUATION_ERROR,
	EDIT_PA_EVALUATION_REQUEST,
	EDIT_PA_EVALUATION_SUCCESS,
	EDIT_PA_EVALUATION_ERROR,
	DELETE_PA_EVALUATION_REQUEST,
	DELETE_PA_EVALUATION_SUCCESS,
	DELETE_PA_EVALUATION_ERROR,
} from "@/actions"

import {
	paEvaluationService
} from "@/services"

import { addNewFilter, generateKey, isEmpty } from "@/utilities"

const state = {
	paEvaluations: [],
	paEvaluation: {},
	loading: false,
	loadedOnce: false,
	ontimeOpts: [
		{label: 'Select one', value: null, disabled: true},
		{label: '5 - 90%-100% on time', value: 5},
		{label: '4 - 80%-90% on time', value: 4},
		{label: '3 - 70%-80% on time', value: 3},
		{label: '2 - 60%-70% on time', value: 2},
		{label: '1 - Less than 60% on time', value: 1},
		{label: 'N/A', value: 0},
	],
	reviewOpts: [
		{label: 'Select one', value: null, disabled: true},
		{label: '5 - 90%-100%', value: 5},
		{label: '4 - 80%-90%', value: 4},
		{label: '3 - 70%-80%', value: 3},
		{label: '2 - 60%-70%', value: 2},
		{label: '1 - Less than 60%', value: 1},
		{label: 'N/A', value: 0},
	],
	respondOpts: [
		{label: 'Select one', value: null, disabled: true},
		{label: '5 - Responded within 24 hours 90%-100% of the time', value: 5},
		{label: '4 - Responded within 24 hours 80%-90% of the time', value: 4},
		{label: '3 - Responded within 24 hours 70%-80% of the time', value: 3},
		{label: '2 - Responded within 24 hours 60%-70% of the time', value: 2},
		{label: '1 - Responded within 24 hours 60% or less of the time', value: 1},
		{label: 'N/A', value: 0},
	],
	evaluationQuestion: {
		recon_submission: "Reconciliation Submitted On Time",
		recon_min_errors: "Reconciliation Correct with Minimal Errors",
		communications_response: "Responds to email, messages and other communications with 24 hours",
		send_resume: "Sends Resumes as requested within 48 Hours",
		sends_appropriate_resume: "Sends Appropriate Resumes",
		meeting_minutes_submissions: "Ensures Meeting Minutes are submitted with Proper Naming Conventions.",
		regular_communication: "Communicates regularly with Lead Coach and updates when issues arises",
		improvement_feedback: "Comments, feedback, or area(s) for improvement?",
	}
}

const getters = {
	paEvaluationsLoading: state => state.loading,
	paEvaluationsLoadedOnce: state => state.loadedOnce,
	evaluationReviewOpts: state => state.reviewOpts,
	evaluationRespondOpts: state => state.respondOpts,
	evaluationOntimeOpts: state => state.ontimeOpts,
	evaluationQuestion: state => state.evaluationQuestion,
	paAssignmentEvaluations: state => state.paEvaluations,
	paEvaluationsByUser: state => uuid => state.paEvaluations.filter(c => c.user_uuid == uuid),
}

const actions = {
	async getPaEvaluations({ state, commit }, params) {
		commit("GET_PA_EVALUATIONS_REQUEST");
		const response = await paEvaluationService.getPaEvaluations(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if(!params?.skipStore){
					commit("GET_PA_EVALUATIONS_SUCCESS", data);
				}
				break
			default:
				commit("GET_PA_EVALUATIONS_ERROR", data)
		}
		return response
	},
	async getPaEvaluation({ commit, state }, params) {
		commit("GET_PA_EVALUATION_REQUEST")
		const response = await paEvaluationService.getPaEvaluation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PA_EVALUATION_SUCCESS", data)
				break
			default:
				commit("GET_PA_EVALUATION_ERROR", data)
				break
		}
		return response
	},
	async createPaEvaluation({ commit, dispatch }, params) {
		commit("CREATE_PA_EVALUATION_REQUEST")
		const response = await paEvaluationService.createPaEvaluation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_PA_EVALUATION_SUCCESS", data) : commit("CREATE_PA_EVALUATION_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Evaluation Submitted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("CREATE_PA_EVALUATION_ERROR", data)
		}
		return response
	},
	async editPaEvaluation({ commit, dispatch }, params) {
		commit("EDIT_PA_EVALUATION_REQUEST")
		const response = await paEvaluationService.editPaEvaluation(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data?.success ? commit("EDIT_PA_EVALUATION_SUCCESS", data) : null;
				data?.success ? dispatch('alert/createAlertSuccess', "Evaluation updated!", { root: true }) : null;
				break
			default:
				commit("EDIT_PA_EVALUATION_ERROR", data)
		}
		return response
	},
	async deletePaEvaluation({ commit, dispatch }, uuid) {
		commit("DELETE_PA_EVALUATION_REQUEST")
		const response = await paEvaluationService.deletePaEvaluation(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_PA_EVALUATION_SUCCESS", { uuid }) : commit("DELETE_PA_EVALUATION_ERROR")
				data.success ? dispatch('alert/createAlertSuccess', "Evaluation deleted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("DELETE_PA_EVALUATION_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_PA_EVALUATIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_PA_EVALUATIONS_SUCCESS]: (state, data) => {
		state.paEvaluations = addNewFilter(state.paEvaluations, data.pa_evaluations)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_PA_EVALUATIONS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_PA_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[GET_PA_EVALUATION_SUCCESS]: (state, data) => {
		const { pa_evaluation }  = data
		state.paEvaluations = addNewFilter(state.paEvaluations, [pa_evaluation])
		state.loading = false
	},
	[GET_PA_EVALUATION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_PA_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_PA_EVALUATION_SUCCESS]: (state, data) => {
		const { pa_evaluation } = data;
		state.paEvaluations = addNewFilter(state.paEvaluations, [pa_evaluation])
		state.loading = false
	},
	[CREATE_PA_EVALUATION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_PA_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_PA_EVALUATION_SUCCESS]: (state, data) => {
		const { pa_evaluation } = data;
		state.paEvaluations = addNewFilter(state.paEvaluations, [pa_evaluation])
		state.loading = false;
	},
	[EDIT_PA_EVALUATION_ERROR]: state => {
		state.loading = false
	},

	[DELETE_PA_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_PA_EVALUATION_SUCCESS]: (state, data) => {
		const { uuid } = data;
		state.paEvaluations = state.paEvaluations.filter(c => c.uuid !== uuid)
		state.loading = false
	},
	[DELETE_PA_EVALUATION_ERROR]: (state) => {
		state.loading = false
	},
}

export const paEvaluation = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
