import {
    apiRequest,
    processInput
} from "@/utilities"

export const facultySourcesService = {
    async getFacultySources() {
        const getFacultySourcesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/faculty-sources", getFacultySourcesRequest)
    },

    async createFacultySource(params) {
        const createData = processInput(params);
        const postFacultySourceRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/faculty-sources", postFacultySourceRequest)
    },

    async getFacultySource(uuid) {
        const getFacultySourceRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/faculty-sources/${uuid}`, getFacultySourceRequest);
    },

    async editFacultySource(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateFacultySourceRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/faculty-sources/${uuid}`, updateFacultySourceRequest);
    },

    async deleteFacultySource(data, uuid) {
        let deleteData = processInput(data)
        const deleteFacultySourceRequest = {
            method: "DELETE",
            data: deleteData
        }
        return await apiRequest(`/api/faculty-sources/${uuid}`, deleteFacultySourceRequest);
    },

    async sendResumeFacultySource(params) {
        const createData = processInput(params);
        const postFacultySourceRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/send-resumes-faculty-sources", postFacultySourceRequest)
    },
}