import {
  GET_PAYROLLS_REQUEST,
  GET_PAYROLLS_SUCCESS,
  GET_PAYROLLS_ERROR,
  GET_PAYROLL_REQUEST,
  GET_PAYROLL_SUCCESS,
  GET_PAYROLL_ERROR,
  CREATE_PAYROLL_REQUEST,
  CREATE_PAYROLL_SUCCESS,
  CREATE_PAYROLL_ERROR,
  EDIT_PAYROLL_REQUEST,
  EDIT_PAYROLL_SUCCESS,
  EDIT_PAYROLL_ERROR,
  DELETE_PAYROLL_REQUEST,
  DELETE_PAYROLL_SUCCESS,
  DELETE_PAYROLL_ERROR,
  GENERATE_PAYROLL_REQUEST,
  GENERATE_PAYROLL_SUCCESS,
  GENERATE_PAYROLL_ERROR,
} from "@/actions"

import {
  payrollService
} from "@/services";

import {
  tableMap
} from "@/modules";

import {
  isEmpty,
  addNewFilter
} from "@/utilities";

import { isSameDay } from "date-fns";

const state = {
  loading: false,
  payroll: {},
  payrolls: [],
  data: {},
  statusMap: [
    { label: 'Estimated', value: 'estimated', color: 'danger' },
    { label: 'Processing', value: 'processing', color: 'danger' },
    { label: 'Pending', value: 'pending', color: 'warning' },
    { label: 'Verified', value: 'verified', color: 'info' },
    { label: 'Finalized', value: 'finalized', color: 'success' },
  ]
}

const getters = {
  loadingPayDateData: state => state.loading,
  payrollStatusMap: state => state.statusMap,
  payrollInState: state => date => state.payrolls.find(p => isSameDay(new Date(p.pay_date), new Date(date))),
  payDateData: (state, getters, rootState, rootGetters) => date => {
    let payDate = state.payrolls.find(p => isSameDay(new Date(p.pay_date), new Date(date))) ?? {};

    if (isEmpty(payDate)) {
      return payDate;
    }

    payDate.working_assignment_sheets = payDate?.working_assignment_sheets?.map(s => tableMap(s, rootGetters)) ?? [];

    return payDate;
  }
}

const actions = {
  async getPayrolls({ commit, getters }, params) {
    if (!getters.payrollInState(params.pay_date) || params?.store == true) {
      commit("GET_PAYROLLS_REQUEST")
      const response = await payrollService.getPayrolls(params);
      const { status, data } = response
      switch (status) {
        case 200:
          if (data.success) {
            data.payroll = unpackPayroll(data.payroll, commit)
            commit("GET_PAYROLLS_SUCCESS", data)
          }
          break
        default:
          commit("GET_PAYROLLS_ERROR", data)
      }
      return response
    }

    return true
  },
  async getPayroll({ commit }, params) {
    commit("GET_PAYROLL_REQUEST")
    const response = await payrollService.getPayroll(params);
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_PAYROLL_SUCCESS", data)
        break
      default:
        commit("GET_PAYROLL_ERROR", data)
        break
    }
    return response
  },
  async createPayroll({ commit, dispatch }, params) {
    commit("CREATE_PAYROLL_REQUEST")
    const response = await payrollService.createPayroll(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_PAYROLL_SUCCESS", data) : null
        data.success ? dispatch("alert/createAlertSuccess", "Payroll created.", { root: true }) : null
        break
      default:
        commit("CREATE_PAYROLL_ERROR", data)
    }
    return response
  },
  async editPayroll({ commit }, params) {
    commit("EDIT_PAYROLL_REQUEST")
    const response = await payrollService.editPayroll(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_PAYROLL_SUCCESS", data) : null
        break
      default:
        commit("EDIT_PAYROLL_ERROR", data)
    }
    return response
  },
  async deletePayroll({ commit, dispatch }, uuid) {
    commit("DELETE_PAYROLL_REQUEST")
    const response = await payrollService.deletePayroll(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_PAYROLL_SUCCESS", { uuid }) : null
        data.success ? dispatch("alert/createAlertSuccess", "Payroll deleted.", { root: true }) : null
        break
      default:
        commit("DELETE_PAYROLL_ERROR", data)
    }
    return response
  },
  async generatePayroll({ commit, dispatch }, params) {
    commit("GENERATE_PAYROLL_REQUEST")
    const response = await payrollService.generatePayroll(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("GENERATE_PAYROLL_SUCCESS") : null
        data.success ? dispatch("alert/createAlertSuccess", "Generating payroll documents. You will be notified when completed.", { root: true }) : null
        data.error ? commit("GENERATE_PAYROLL_ERROR") : null
        data.error ? dispatch('alert/createAlertErrors', data.errors, { root: true }) : null
        break
      default:
        commit("GENERATE_PAYROLL_ERROR", data)
    }
    return response
  },
  getPaydateData({ commit }, params) {
    commit("GET_PAYDATE_DATA_REQUEST");

    let res = payrollService.getPayroll(params)

    const { status, data } = res
    switch (status) {
      case 200:
        commit("GET_PAYDATE_DATA_SUCCESS", data)

        break
      default:
        commit("GET_PAYDATE_DATA_ERROR", data)
        break
    }
    return res
  }
}

const mutations = {
  [GET_PAYROLLS_REQUEST]: state => {
    state.loading = true
  },
  [GET_PAYROLLS_SUCCESS]: (state, data) => {
    const { payroll } = data
    state.payrolls = addNewFilter(state.payrolls, [payroll])
    state.loading = false
    state.loadedOnce = true
  },
  [GET_PAYROLLS_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_PAYROLL_REQUEST]: state => {
    state.loading = true
  },
  [GET_PAYROLL_SUCCESS]: (state, data) => {
    const { payroll } = data;
    state.payroll = payroll
    state.payrolls = addNewFilter(state.payrolls, [payroll]);
    state.loading = false
  },
  [GET_PAYROLL_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_PAYROLL_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_PAYROLL_SUCCESS]: (state, data) => {
    const { payroll } = data
    state.payrolls = addNewFilter(state.payrolls, [payroll])
    if (!isEmpty(state.payroll) && state.payroll.uuid == payroll.uuid) {
      state.payroll = payroll
    }
    state.loading = false
  },
  [CREATE_PAYROLL_ERROR]: state => {
    state.loading = false
  },

  [EDIT_PAYROLL_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_PAYROLL_SUCCESS]: (state, data) => {
    const { payroll } = data;
    state.payrolls = state.payrolls.map(i => i.uuid == payroll.uuid ? Object.assign({}, i, { status: payroll.status }) : i);

    if (!isEmpty(state.payroll) && state.payroll.uuid == payroll.uuid) {
      state.payroll.status = payroll.status;
    }

    state.loading = false;
  },
  [EDIT_PAYROLL_ERROR]: state => {
    state.loading = false
  },

  [DELETE_PAYROLL_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_PAYROLL_SUCCESS]: (state, uuid) => {
    state.payrolls = state.payrolls.filter(i => i.uuid !== uuid)
    state.loading = false
  },
  [DELETE_PAYROLL_ERROR]: (state, data) => {
    state.loading = false
  },

  [GENERATE_PAYROLL_REQUEST]: state => {
    state.loading = true
  },
  [GENERATE_PAYROLL_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [GENERATE_PAYROLL_ERROR]: (state, data) => {
    state.loading = false
  },
}

export const payroll = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

const unpackPayroll = (payroll, commit) => {

  if (payroll?.documents.length > 0) {
    const documents = payroll.documents
    commit('document/GET_DOCUMENTS_SUCCESS', { documents }, { root: true })
  }

  delete payroll?.documents

  return payroll
}
