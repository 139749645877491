import {
    GET_ROLES_REQUEST,
    GET_ROLES_SUCCESS,
    GET_ROLES_ERROR
} from "@/actions"

import {
    roleService
} from "@/services"

const state = {
    roles: [],
    role: {},
    loading: false,
    loadedOnce: false
}

const getters = {
    roleLoadedOnce: state => state.loadedOnce,
    roleSelectOptions: state => {
        return state.roles.map(role => {
            return { value: role.name, label: role.name }
        })
    },
    roleMultiSelectOptions: state => {
        return state.roles.map(role => {
            return { value: role.uuid, text: role.name }
        })
    },
    allRoles: state => state.roles
}

const actions = {
    async getRoles({ commit }) {
        commit("GET_ROLES_REQUEST")
        const response = await roleService.getRoles();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_ROLES_SUCCESS", data)
                break
            default:
                commit("GET_ROLES_ERROR", data)
        }
        return response
    }
}

const mutations = {
    [GET_ROLES_REQUEST]: state => {
        state.loading = true
    },
    [GET_ROLES_SUCCESS]: (state, data) => {
        const roles = data.roles
        state.roles = roles
        state.loading = false
        state.loadedOnce = true
    },
    [GET_ROLES_ERROR]: state => {
        state.loading = false
        state.error = true
    }
}

export const role = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
