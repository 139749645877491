import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const invoiceService = {
  async getInvoices(params) {
    const getInvoicesRequest = {
      method: "GET",
      params: processQueryParams(params)
    }
    return await apiRequest("/api/invoices", getInvoicesRequest)
  },

  /**
   *
   * @param {*} params
   * @returns
   */
  async getInvoice(params) {
    const { uuid } = params;
    delete params['uuid'];
    const getInvoiceRequest = {
      method: "GET",
      params: processQueryParams(params)
    }
    return await apiRequest(`/api/invoices/${uuid}`, getInvoiceRequest)
  },
  async createInvoice(params) {
    const createData = processInput(params)
    const createInvoiceRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/invoices", createInvoiceRequest)
  },
  async editInvoice(data) {
    const { uuid } = data
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editInvoiceRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/invoices/${uuid}`, editInvoiceRequest)
  },
  async deleteInvoice(uuid) {
    const deleteInvoiceRequest = {
      method: "DELETE"
    }
    return await apiRequest(`/api/invoices/${uuid}`, deleteInvoiceRequest)
  },
  async generateInvoice(params) {
    const generateData = processInput(params)
    const generateInvoiceRequest = {
      method: "POST",
      data: generateData
    }
    return await apiRequest("/api/invoice-generate", generateInvoiceRequest)
  }
}
