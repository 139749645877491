import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const universityService = {
    async getUniversities() {
        const getUniversitysRequest = {
            method: "GET"
        }
        return await apiRequest("/api/universities", getUniversitysRequest)
    },
    async createUniversity(params) {
        const createData = processInput(params);
        const postUniversityRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/universities", postUniversityRequest)
    },
    async getUniversity(params, uuid) {
        const getQueryParams = processQueryParams(params)
        const getUniversityRequest = {
            method: "GET",
            params: getQueryParams
        }

        return await apiRequest(`/api/universities/${uuid}`, getUniversityRequest);
    },
    async editUniversity(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/universities/${uuid}`, updateUniversityRequest);
    },
    async deleteUniversity(uuid) {

        const deleteUniversityRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/universities/${uuid}`, deleteUniversityRequest);
    },
    // async universityPartnershipCommon() {

    //     const Request = {
    //         method: "GET"
    //     }

    //     return await apiRequest(`/api/university-partnership-common`, Request);
    // }
}