import {
    apiRequest,
    processInput
} from "@/utilities"

export const eeoVeteranStatusService = {
    async getEEOVeteranStatuses() {
        const getEEOVeteranStatusesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/eeo-veteran-statuses", getEEOVeteranStatusesRequest)
    },
    async getEEOVeteranStatus(uuid) {
        const getEEOVeteranStatusRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/eeo-veteran-statuses/${uuid}`, getEEOVeteranStatusRequest)
    },
    async createEEOVeteranStatus(params) {
        const createData = processInput(params)
        const createEEOVeteranStatusRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/eeo-veteran-statuses", createEEOVeteranStatusRequest)
    },
    async editEEOVeteranStatus(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editEEOVeteranStatusRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/eeo-veteran-statuses/${uuid}`, editEEOVeteranStatusRequest)
    },
    async deleteEEOVeteranStatus(uuid) {
        const deleteEEOVeteranStatusRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/eeo-veteran-statuses/${uuid}`, deleteEEOVeteranStatusRequest)
    }
}