export const GET_FACULTY_SOURCE_ROLES_REQUEST = "GET_FACULTY_SOURCE_ROLES_REQUEST"
export const GET_FACULTY_SOURCE_ROLES_SUCCESS = "GET_FACULTY_SOURCE_ROLES_SUCCESS"
export const GET_FACULTY_SOURCE_ROLES_ERROR = "GET_FACULTY_SOURCE_ROLES_ERROR"
export const GET_FACULTY_SOURCE_ROLE_REQUEST = "GET_FACULTY_SOURCE_ROLE_REQUEST"
export const GET_FACULTY_SOURCE_ROLE_SUCCESS = "GET_FACULTY_SOURCE_ROLE_SUCCESS"
export const GET_FACULTY_SOURCE_ROLE_ERROR = "GET_FACULTY_SOURCE_ROLE_ERROR" 
export const CREATE_FACULTY_SOURCE_ROLE_REQUEST = "CREATE_FACULTY_SOURCE_ROLE_REQUEST"
export const CREATE_FACULTY_SOURCE_ROLE_SUCCESS = "CREATE_FACULTY_SOURCE_ROLE_SUCCESS"
export const CREATE_FACULTY_SOURCE_ROLE_ERROR = "CREATE_FACULTY_SOURCE_ROLE_ERROR"
export const EDIT_FACULTY_SOURCE_ROLE_REQUEST = "EDIT_FACULTY_SOURCE_ROLE_REQUEST"
export const EDIT_FACULTY_SOURCE_ROLE_SUCCESS = "EDIT_FACULTY_SOURCE_ROLE_SUCCESS"
export const EDIT_FACULTY_SOURCE_ROLE_ERROR = "EDIT_FACULTY_SOURCE_ROLE_ERROR"
export const DELETE_FACULTY_SOURCE_ROLE_REQUEST = "DELETE_FACULTY_SOURCE_ROLE_REQUEST"
export const DELETE_FACULTY_SOURCE_ROLE_SUCCESS = "DELETE_FACULTY_SOURCE_ROLE_SUCCESS"
export const BROADCAST_DELETE_FACULTY_SOURCE_ROLE_SUCCESS = "BROADCAST_DELETE_FACULTY_SOURCE_ROLE_SUCCESS"
export const DELETE_FACULTY_SOURCE_ROLE_ERROR = "DELETE_FACULTY_SOURCE_ROLE_ERROR"
export const SEND_RESUME_FACULTY_SOURCE_ROLE_REQUEST = "SEND_RESUME_FACULTY_SOURCE_ROLE_REQUEST"
export const SEND_RESUME_FACULTY_SOURCE_ROLE_SUCCESS = "SEND_RESUME_FACULTY_SOURCE_ROLE_SUCCESS"
export const SEND_RESUME_FACULTY_SOURCE_ROLE_ERROR = "SEND_RESUME_FACULTY_SOURCE_ROLE_ERROR"