import {
    apiRequest,
    processInput
} from "@/utilities"

export const commentTypeService = {
    async getCommentTypes() {
        const getCommentTypesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/comment-types", getCommentTypesRequest)
    },
    async getCommentType(uuid) {
        const getCommentTypeRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/comment-types/${uuid}`, getCommentTypeRequest)
    },
    async createCommentType(params) {
        const createData = processInput(params)
        const createCommentTypeRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/comment-types", createCommentTypeRequest)
    },
    async editCommentType(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCommentTypeRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/comment-types/${uuid}`, editCommentTypeRequest)
    },
    async deleteCommentType(uuid) {
        const deleteCommentTypeRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/comment-types/${uuid}`, deleteCommentTypeRequest)
    }
}