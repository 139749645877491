export const GET_COMMENT_TYPES_REQUEST = "GET_COMMENT_TYPES_REQUEST"
export const GET_COMMENT_TYPES_SUCCESS = "GET_COMMENT_TYPES_SUCCESS"
export const GET_COMMENT_TYPES_ERROR = "GET_COMMENT_TYPES_ERROR"
export const GET_COMMENT_TYPE_REQUEST = "GET_COMMENT_TYPE_REQUEST"
export const GET_COMMENT_TYPE_SUCCESS = "GET_COMMENT_TYPE_SUCCESS"
export const GET_COMMENT_TYPE_ERROR = "GET_COMMENT_TYPE_ERROR"
export const CREATE_COMMENT_TYPE_REQUEST = "CREATE_COMMENT_TYPE_REQUEST"
export const CREATE_COMMENT_TYPE_SUCCESS = "CREATE_COMMENT_TYPE_SUCCESS"
export const CREATE_COMMENT_TYPE_ERROR = "CREATE_COMMENT_TYPE_ERROR"
export const EDIT_COMMENT_TYPE_REQUEST = "EDIT_COMMENT_TYPE_REQUEST"
export const EDIT_COMMENT_TYPE_SUCCESS = "EDIT_COMMENT_TYPE_SUCCESS"
export const EDIT_COMMENT_TYPE_ERROR = "EDIT_COMMENT_TYPE_ERROR"
export const DELETE_COMMENT_TYPE_REQUEST = "DELETE_COMMENT_TYPE_REQUEST"
export const DELETE_COMMENT_TYPE_SUCCESS = "DELETE_COMMENT_TYPE_SUCCESS"
export const DELETE_COMMENT_TYPE_ERROR = "DELETE_COMMENT_TYPE_ERROR"