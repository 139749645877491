import {
    apiRequest,
    processInput
} from "@/utilities"

export const disciplineService = {
    async getDisciplines() {
        const getDisciplinesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/disciplines", getDisciplinesRequest)
    },
    async getDiscipline(uuid) {
        const getDisciplineRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/disciplines/${uuid}`, getDisciplineRequest)
    },
    async createDiscipline(params) {
        const createData = processInput(params)
        const createDisciplineRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/disciplines", createDisciplineRequest)
    },
    async editDiscipline(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editDisciplineRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/disciplines/${uuid}`, editDisciplineRequest)
    },
    async deleteDiscipline(uuid) {
        const deleteDisciplineRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/disciplines/${uuid}`, deleteDisciplineRequest)
    }
}