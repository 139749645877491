export const payrollRoutes = [
  {
    path: 'payroll',
    name: 'payroll index',
    meta: {
      label: "Payroll"
    },
    component: () => import("@/pages/accounting/PayrollIndex.vue")
  },
  {
    path: 'admin-payroll',
    name: 'admin-payroll',
    meta: {
      label: "Admin Payroll"
    },
    component: () => import("@/pages/accounting/AdminPayrollContainer.vue")
  }
]