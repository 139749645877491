export const GET_TASK_SETTINGS_REQUEST = "GET_TASK_SETTINGS_REQUEST"
export const GET_TASK_SETTINGS_SUCCESS = "GET_TASK_SETTINGS_SUCCESS"
export const GET_TASK_SETTINGS_ERROR = "GET_TASK_SETTINGS_ERROR"

export const GET_TASK_SETTING_REQUEST = "GET_TASK_SETTING_REQUEST"
export const GET_TASK_SETTING_SUCCESS = "GET_TASK_SETTING_SUCCESS"
export const GET_TASK_SETTING_ERROR = "GET_TASK_SETTING_ERROR"

export const CREATE_TASK_SETTING_REQUEST = "CREATE_TASK_SETTING_REQUEST"
export const CREATE_TASK_SETTING_SUCCESS = "CREATE_TASK_SETTING_SUCCESS"
export const CREATE_TASK_SETTING_ERROR = "CREATE_TASK_SETTING_ERROR"

export const EDIT_TASK_SETTING_REQUEST = "EDIT_TASK_SETTING_REQUEST"
export const EDIT_TASK_SETTING_SUCCESS = "EDIT_TASK_SETTING_SUCCESS"
export const EDIT_TASK_SETTING_ERROR = "EDIT_TASK_SETTING_ERROR"

export const BROADCAST_DELETE_TASK_SETTING_SUCCESS = "BROADCAST_DELETE_TASK_SETTING_SUCCESS"
export const DELETE_TASK_SETTING_REQUEST = "DELETE_TASK_SETTING_REQUEST"
export const DELETE_TASK_SETTING_SUCCESS = "DELETE_TASK_SETTING_SUCCESS"
export const DELETE_TASK_SETTING_ERROR = "DELETE_TASK_SETTING_ERROR"