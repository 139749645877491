export const GET_SPECIALTIES_REQUEST = "GET_SPECIALTIES_REQUEST"
export const GET_SPECIALTIES_SUCCESS = "GET_SPECIALTIES_SUCCESS"
export const GET_SPECIALTIES_ERROR = "GET_SPECIALTIES_ERROR"
export const GET_SPECIALTY_REQUEST = "GET_SPECIALTY_REQUEST"
export const GET_SPECIALTY_SUCCESS = "GET_SPECIALTY_SUCCESS"
export const GET_SPECIALTY_ERROR = "GET_SPECIALTY_ERROR"
export const CREATE_SPECIALTY_REQUEST = "CREATE_SPECIALTY_REQUEST"
export const CREATE_SPECIALTY_SUCCESS = "CREATE_SPECIALTY_SUCCESS"
export const CREATE_SPECIALTY_ERROR = "CREATE_SPECIALTY_ERROR"
export const EDIT_SPECIALTY_REQUEST = "EDIT_SPECIALTY_REQUEST"
export const EDIT_SPECIALTY_SUCCESS = "EDIT_SPECIALTY_SUCCESS"
export const EDIT_SPECIALTY_ERROR = "EDIT_SPECIALTY_ERROR"
export const DELETE_SPECIALTY_REQUEST = "DELETE_SPECIALTY_REQUEST"
export const DELETE_SPECIALTY_SUCCESS = "DELETE_SPECIALTY_SUCCESS"
export const DELETE_SPECIALTY_ERROR = "DELETE_SPECIALTY_ERROR"