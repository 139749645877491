import {
	GET_COURSE_MEETING_MINUTES_REQUEST,
	GET_COURSE_MEETING_MINUTES_SUCCESS,
	GET_COURSE_MEETING_MINUTES_ERROR,
	GET_COURSE_MEETING_MINUTE_REQUEST,
	GET_COURSE_MEETING_MINUTE_SUCCESS,
	GET_COURSE_MEETING_MINUTE_ERROR,
	CREATE_COURSE_MEETING_MINUTE_REQUEST,
	CREATE_COURSE_MEETING_MINUTE_SUCCESS,
	CREATE_COURSE_MEETING_MINUTE_ERROR,
	EDIT_COURSE_MEETING_MINUTE_REQUEST,
	EDIT_COURSE_MEETING_MINUTE_SUCCESS,
	EDIT_COURSE_MEETING_MINUTE_ERROR,
	DELETE_COURSE_MEETING_MINUTE_REQUEST,
	DELETE_COURSE_MEETING_MINUTE_SUCCESS,
	DELETE_COURSE_MEETING_MINUTE_ERROR,
} from "@/actions"

import {
	courseMeetingMinuteService
} from "@/services"

import { addNewFilter, generateKey, isEmpty } from "@/utilities"

const state = {
	courseMeetingMinutes: [],
	courseMeetingMinute: {},
	loading: false,
	loadedOnce: false,
	universityProgramsLoaded: []
}

const getters = {
	courseMeetingMinutesLoading: state => state.loading,
	courseMeetingMinutesLoadedOnce: state => state.loadedOnce,
	courseMeetingMinutes: state => state.courseMeetingMinutes,
	findCourseMeetingMinute: state => uuid => state.courseMeetingMinutes.find(c => c.uuid == uuid),
	courseMeetingMinutesByCsUuid: state => cuuid => state.courseMeetingMinutes.filter(c => c.course_start_uuid == cuuid),
}

const actions = {
	async getMeetingMinutes({ state, commit }, params) {
		commit("GET_COURSE_MEETING_MINUTES_REQUEST");
		const response = await courseMeetingMinuteService.getMeetingMinutes(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if(!params?.skipStore){
					commit("GET_COURSE_MEETING_MINUTES_SUCCESS", data);
				}
				break
			default:
				commit("GET_COURSE_MEETING_MINUTES_ERROR", data)
		}
		return response
	},
	async getCourseMeetingMinutes({ state, commit }, params) {
		commit("GET_COURSE_MEETING_MINUTES_REQUEST");
		const response = await courseMeetingMinuteService.getCourseMeetingMinutes(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COURSE_MEETING_MINUTES_SUCCESS", data);
				break
			default:
				commit("GET_COURSE_MEETING_MINUTES_ERROR", data)
		}
		return response
	},
	async getCourseMeetingMinute({ commit }, params) {
		commit("GET_COURSE_MEETING_MINUTE_REQUEST")
		const response = await courseMeetingMinuteService.getCourseMeetingMinute(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COURSE_MEETING_MINUTE_SUCCESS", data)
				break
			default:
				commit("GET_COURSE_MEETING_MINUTE_ERROR", data)
				break
		}
		return response
	},
	async createCourseMeetingMinute({ commit, dispatch }, params) {
		commit("CREATE_COURSE_MEETING_MINUTE_REQUEST")
		const response = await courseMeetingMinuteService.createCourseMeetingMinute(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_COURSE_MEETING_MINUTE_SUCCESS", data) : commit("CREATE_COURSE_MEETING_MINUTE_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Meeting Minute created!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("CREATE_COURSE_MEETING_MINUTE_ERROR", data)
		}
		return response
	},
	async editCourseMeetingMinute({ commit, dispatch }, params) {
		commit("EDIT_COURSE_MEETING_MINUTE_REQUEST")
		const response = await courseMeetingMinuteService.editCourseMeetingMinute(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data?.success ? commit("EDIT_COURSE_MEETING_MINUTE_SUCCESS", data) : null;
				data?.success ? dispatch('alert/createAlertSuccess', "Meeting Minute updated!", { root: true }) : null;
				break
			default:
				commit("EDIT_COURSE_MEETING_MINUTE_ERROR", data)
		}
		return response
	},
	async deleteCourseMeetingMinute({ commit, dispatch }, uuid) {
		commit("DELETE_COURSE_MEETING_MINUTE_REQUEST")
		const response = await courseMeetingMinuteService.deleteCourseMeetingMinute(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_COURSE_MEETING_MINUTE_SUCCESS", { uuid }) : commit("DELETE_COURSE_MEETING_MINUTE_ERROR")
				data.success ? dispatch('alert/createAlertSuccess', "Meeting Minute deleted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("DELETE_COURSE_MEETING_MINUTE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_COURSE_MEETING_MINUTES_REQUEST]: state => {
		state.loading = true
	},
	[GET_COURSE_MEETING_MINUTES_SUCCESS]: (state, data) => {
		state.courseMeetingMinutes = addNewFilter(state.courseMeetingMinutes, data.course_meeting_minutes)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_COURSE_MEETING_MINUTES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_COURSE_MEETING_MINUTE_REQUEST]: state => {
		state.loading = true
	},
	[GET_COURSE_MEETING_MINUTE_SUCCESS]: (state, data) => {
		const { course_meeting_minute }  = data
		state.courseMeetingMinutes = addNewFilter(state.courseMeetingMinutes, [course_meeting_minute])
		state.loading = false
	},
	[GET_COURSE_MEETING_MINUTE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COURSE_MEETING_MINUTE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COURSE_MEETING_MINUTE_SUCCESS]: (state, data) => {
		const { course_meeting_minute } = data;
		state.courseMeetingMinutes = addNewFilter(state.courseMeetingMinutes, [course_meeting_minute])
		state.loading = false
	},
	[CREATE_COURSE_MEETING_MINUTE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COURSE_MEETING_MINUTE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COURSE_MEETING_MINUTE_SUCCESS]: (state, data) => {
		const { course_meeting_minute } = data;
		state.courseMeetingMinutes = addNewFilter(state.courseMeetingMinutes, [course_meeting_minute])
		state.loading = false;
	},
	[EDIT_COURSE_MEETING_MINUTE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COURSE_MEETING_MINUTE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COURSE_MEETING_MINUTE_SUCCESS]: (state, data) => {
		const { uuid } = data;
		state.courseMeetingMinutes = state.courseMeetingMinutes.filter(c => c.uuid !== uuid)
		state.loading = false
	},
	[DELETE_COURSE_MEETING_MINUTE_ERROR]: (state) => {
		state.loading = false
	},
}

export const courseMeetingMinute = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
