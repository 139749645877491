import {
    apiRequest,
    processInput
} from "@/utilities"

export const degreeService = {
    async getDegrees() {
        const getDegreesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/degrees", getDegreesRequest)
    },
    async getDegree(uuid) {
        const getDegreeRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/degrees/${uuid}`, getDegreeRequest)
    },
    async createDegree(params) {
        const createData = processInput(params)
        const createDegreeRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/degrees", createDegreeRequest)
    },
    async editDegree(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editDegreeRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/degrees/${uuid}`, editDegreeRequest)
    },
    async deleteDegree(uuid) {
        const deleteDegreeRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/degrees/${uuid}`, deleteDegreeRequest)
    }
}