export const GET_ISSUING_AUTHORITIES_REQUEST = "GET_ISSUING_AUTHORITIES_REQUEST"
export const GET_ISSUING_AUTHORITIES_SUCCESS = "GET_ISSUING_AUTHORITIES_SUCCESS"
export const GET_ISSUING_AUTHORITIES_ERROR = "GET_ISSUING_AUTHORITIES_ERROR"
export const GET_ISSUING_AUTHORITY_REQUEST = "GET_ISSUING_AUTHORITY_REQUEST"
export const GET_ISSUING_AUTHORITY_SUCCESS = "GET_ISSUING_AUTHORITY_SUCCESS"
export const GET_ISSUING_AUTHORITY_ERROR = "GET_ISSUING_AUTHORITY_ERROR"
export const CREATE_ISSUING_AUTHORITY_REQUEST = "CREATE_ISSUING_AUTHORITY_REQUEST"
export const CREATE_ISSUING_AUTHORITY_SUCCESS = "CREATE_ISSUING_AUTHORITY_SUCCESS"
export const CREATE_ISSUING_AUTHORITY_ERROR = "CREATE_ISSUING_AUTHORITY_ERROR"
export const EDIT_ISSUING_AUTHORITY_REQUEST = "EDIT_ISSUING_AUTHORITY_REQUEST"
export const EDIT_ISSUING_AUTHORITY_SUCCESS = "EDIT_ISSUING_AUTHORITY_SUCCESS"
export const EDIT_ISSUING_AUTHORITY_ERROR = "EDIT_ISSUING_AUTHORITY_ERROR"
export const DELETE_ISSUING_AUTHORITY_REQUEST = "DELETE_ISSUING_AUTHORITY_REQUEST"
export const DELETE_ISSUING_AUTHORITY_SUCCESS = "DELETE_ISSUING_AUTHORITY_SUCCESS"
export const DELETE_ISSUING_AUTHORITY_ERROR = "DELETE_ISSUING_AUTHORITY_ERROR"