import {
    apiRequest,
    processInput
} from "@/utilities"

export const universityStatusService = {
    async getUniversityStatuses() {
        const getUniversityStatusesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/university-statuses", getUniversityStatusesRequest)
    },
    async createUniversityStatus(params) {
        const createData = processInput(params);
        const postUniversityStatusRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/university-statuses", postUniversityStatusRequest)
    },
    async getUniversityStatus(uuid) {

        const getUniversityStatusRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/university-statuses/${uuid}`, getUniversityStatusRequest);
    },
    async editUniversityStatus(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityStatusRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/university-statuses/${uuid}`, updateUniversityStatusRequest);
    },
    async deleteUniversityStatus(uuid) {

        const deleteUniversityStatusRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/university-statuses/${uuid}`, deleteUniversityStatusRequest);
    }
}