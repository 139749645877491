import {
	GET_COACH_APPLICATIONS_REQUEST,
	GET_COACH_APPLICATIONS_SUCCESS,
	GET_COACH_APPLICATIONS_ERROR,
	GET_COACH_APPLICATION_REQUEST,
	GET_COACH_APPLICATION_SUCCESS,
	GET_COACH_APPLICATION_ERROR,
	CREATE_COACH_APPLICATION_REQUEST,
	CREATE_COACH_APPLICATION_SUCCESS,
	CREATE_COACH_APPLICATION_ERROR,
	EDIT_COACH_APPLICATION_REQUEST,
	EDIT_COACH_APPLICATION_SUCCESS,
	EDIT_COACH_APPLICATION_ERROR,
	DELETE_COACH_APPLICATION_REQUEST,
	DELETE_COACH_APPLICATION_SUCCESS,
	DELETE_COACH_APPLICATION_ERROR,
	CLEAR_COACH_APPLICATION_STATE,
	RESOLVE_SUBMISSION_ERROR
} from "@/actions"

import {
	coachApplicationService
} from "@/services"

import Vue from 'vue'

import { isEmpty, addNewFilter } from "@/utilities"

import { router } from "@/router/routes"

import _, { map } from 'underscore'
import { unpackCoach } from "@/modules"

const state = {
	coachApplications: [],
	coachApplication: {},
	loading: false,
	loadedOnce: false,
	applicationMenu: [
		{
			order: 0,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Application Home",
			icon: "cil-home",
			to: {
				name: 'coach application home'
			},
			progressSlug: "application-home"
		},
		{
			order: 1,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Profile",
			fontIcon: "bi bi-person-lines-fill",
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application profile'
			},
			progressSlug: "profile"
		},
		{
			order: 2,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Areas of Qualification",
			fontIcon: "bi bi-clipboard-check", 
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application areas-of-qualification'
			},
			progressSlug: "area-of-qualification"
		},
		{
			order: 3,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Education & Specialties",
			fontIcon: "bi bi-mortarboard", 
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application education-specialties'
			},
			progressSlug: "education"
		},
		{
			order: 4,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Licensure & Certifications",
			fontIcon: "bi bi-award", 
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application license-certification'
			},
			progressSlug: "license-certifications"
		},
		{
			order: 5,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Employment Experience",
			fontIcon: "bi bi-building-add", 
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application employment-experience'
			},
			progressSlug: "employment"
		},
		{
			order: 6,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Education Experience",
			fontIcon: "bi bi-card-checklist",
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application education-experience'
			},
			progressSlug: 'education-experience'
		},
		{
			order: 7,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "References",
			fontIcon: "bi bi-people-fill", 
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application references'
			},
			progressSlug: "references"
		},
		{
			order: 8,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Acknowledgement",
			fontIcon: "bi bi-check2-circle", 
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application acknowledgement'
			},
			progressSlug: "acknowledgement"
		},
		{
			order: 9,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Upload Documents",
			fontIcon: "bi bi-cloud-plus", 
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application document-upload'
			},
			progressSlug: "document-upload"
		},
		{
			order: 10,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Submit Application",
			fontIcon: "bi bi-send-check", 
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application submission'
			},
			progressSlug: 'submission'
		},
		{
			order: 11,
			disabled: true,
			viewable: false,
			slug: "link",
			name: "Screen Session",
			fontIcon: "bi-calendar-range",
			addLinkClasses: 'align-items-baseline',
			to: {
				name: 'coach application screening interview'
			},
			progressSlug: "screening-interview"
		}
	],
	coachPortalSteps: [
		{
			name: "Profile",
			disabled: false,
			active: true,
			stepNo: 1,
			completed: false,
			error: false,
			step: 'profile',
			processSlugs: [
				'profile',
				'application-home',
			]
		},
		{
			name: "Specialties",
			disabled: true,
			active: false,
			stepNo: 2,
			completed: false,
			error: false,
			step: 'aoq',
			processSlugs: [
				'area-of-qualification'
			]
		},
		{
			name: "Education",
			disabled: true,
			active: false,
			stepNo: 3,
			completed: false,
			error: false,
			step: 'educations',
			processSlugs: [
				'education'
			]
		},
		{
			name: "Licensure & Certifications",
			disabled: true,
			active: false,
			stepNo: 4,
			completed: false,
			error: false,
			step: 'certifications',
			processSlugs: [
				'license-certifications', 'employment', 'education-experience', 'references'
			]
		},
		{
			name: "Documents",
			disabled: true,
			active: false,
			stepNo: 5,
			completed: false,
			error: false,
			step: 'documents',
			processSlugs: [
				'document-upload'
			]
		},
		// {
		// 	name: "Acknowledgement",
		// 	disabled: true,
		// 	active: false,
		// 	stepNo: 6,
		// 	completed: false,
		// 	error: false,
		// 	step: 'acknowledgement',
		// 	processSlugs: [
		// 		'acknowledgement', 'submission', 'screening-interview'
		// 	]
		// },
	],
	cpConfirmStep: "profile",
	submissionErrors: {},
	cpCADataLoading: false,
}

const getters = {
	cAppLoading: state => state.loading,
	cAppLoadedOnce: state => state.loadedOnce,
	coachApplication: (state, getters, rootState, rootGetters) => state.coachApplications.find(cApps => cApps.coaches_uuid == rootGetters["coaches/coach"]['uuid']),
	coachApplicationByCoachUuid: state => uuid => state.coachApplications.find(cApp => cApp.coaches_uuid == uuid),
	coachApplicationMenu: (state, getters, rootState, rootGetters) => {

		if (isEmpty(getters.coachApplication)) return []

		let output = state.applicationMenu.map(menuItem => {
			if (menuItem.order == 11 && getters.coachApplication?.application_progress == 'screening-interview') {
				menuItem.disabled = false
				menuItem.viewable = true
			}

			return menuItem
		})

		if (!state.loadedOnce) {
			let lastMenuItem = output.find(i => i.progressSlug == getters.coachApplication.last_page_visited)
			let  appProgress = output.find(i => i.progressSlug == getters.coachApplication.application_progress)
			if (!isEmpty(lastMenuItem) && !isEmpty(appProgress) && lastMenuItem.to.name !== rootState['currentPath']['name'] && appProgress.progressSlug !== 'screening-interview') {
				router.push(lastMenuItem.to)
			}
		}

		state.loadedOnce = true

		return output
	},
	currentAppProgress: (state, getters) => {
		getters.coachApplication.findWhere()
	},
	submissionErrors: state => state.submissionErrors,
	// eslint-disable-next-line no-prototype-builtins
	pageSubmissionErrors: state => pageSlug => state.submissionErrors.hasOwnProperty(pageSlug) ? state.submissionErrors[pageSlug] : {},
	applicationItem: state => pageSlug => state.applicationMenu.find(i => i.progressSlug == pageSlug),
	coachPortalSteps: state => state.coachPortalSteps,
	cpConfirmStep: state => state.cpConfirmStep,
	cpCADataLoading: state => state.cpCADataLoading,
}

const actions = {
	async getCoachApplications({ commit }, params) {
		commit("GET_COACH_APPLICATIONS_REQUEST")
		const response = await coachApplicationService.getCoachApplications(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COACH_APPLICATIONS_SUCCESS", data)
				break
			default:
				commit("GET_COACH_APPLICATIONS_ERROR", data)
		}
		return response
	},
	async getCoachApplication({ commit }, params) {
		commit("GET_COACH_APPLICATION_REQUEST")
		const response = await coachApplicationService.getCoachApplication(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("GET_COACH_APPLICATION_SUCCESS", data) : commit("GET_COACH_APPLICATION_ERROR", data)
				break
			default:
				commit("GET_COACH_APPLICATION_ERROR", data)
				break
		}
		return response
	},
	async createCoachApplication({ commit }, params) {
		commit("CREATE_COACH_APPLICATION_REQUEST")
		const response = await coachApplicationService.createCoachApplication(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_COACH_APPLICATION_SUCCESS", data) : commit("CREATE_COACH_APPLICATION_ERROR", data)
				break
			default:
				commit("CREATE_COACH_APPLICATION_ERROR", data)
		}
		return response
	},
	async editCoachApplication({ commit }, params) {
		commit("EDIT_COACH_APPLICATION_REQUEST")
		const uuid = params.uuid
		const response = await coachApplicationService.editCoachApplication(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_COACH_APPLICATION_SUCCESS", data) : commit("EDIT_COACH_APPLICATION_ERROR", data)
				break
			default:
				commit("EDIT_COACH_APPLICATION_ERROR", params, data)
		}
		return response
	},
	async editStepCoachApplication({ commit }, params) {
		commit("EDIT_COACH_APPLICATION_REQUEST")
		const uuid = params.uuid
		const response = await coachApplicationService.editStepCoachApplication(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_COACH_APPLICATION_SUCCESS", data) : commit("EDIT_COACH_APPLICATION_ERROR", data)
				break
			default:
				commit("EDIT_COACH_APPLICATION_ERROR", params, data)
		}
		return response
	},
	async deleteCoachApplication({ commit }, params) {
		commit("DELETE_COACH_APPLICATION_REQUEST")
		const response = await coachApplicationService.deleteCoachApplication(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_COACH_APPLICATION_SUCCESS", data)
				break
			default:
				commit("DELETE_COACH_APPLICATION_DELETE", data)
		}
		return response
	},
	updateAppProgress({ dispatch, commit }, progressObject) {
		if (!isEmpty(progressObject.coachApplication.application_submitted)) return false;

		let appProgress = _.findWhere(state.applicationMenu, { progressSlug: progressObject.coachApplication?.application_progress ?? "application-home" })
		let nextSection = _.findWhere(state.applicationMenu, { progressSlug: progressObject.appProgress });

		if (nextSection.order > appProgress.order) {
			return dispatch('editCoachApplication', { uuid: progressObject.coachApplication.uuid, applicationProgress: nextSection.progressSlug })
		}
	},
	resolveSubmissionError({ commit }, errorKey) {
		commit("RESOLVE_SUBMISSION_ERROR", errorKey)
		return
	},
	setCpConfirmStep(ctx, val){
		ctx.commit("setCpConfirmStep", val)
	}
}

const mutations = {
	[GET_COACH_APPLICATIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_APPLICATIONS_SUCCESS]: (state, data) => {
		const coach_applications = data.coach_applications
		state.coachApplications = addNewFilter(state.coachApplications, coach_applications)
		state.loading = false
	},
	[GET_COACH_APPLICATIONS_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_COACH_APPLICATION_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_APPLICATION_SUCCESS]: (state, data) => {
		const coachApplication = { ...data.coach_application }
		state.coachApplication = coachApplication
		state.loading = false
	},
	[GET_COACH_APPLICATION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COACH_APPLICATION_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COACH_APPLICATION_SUCCESS]: (state, data) => {
		let coachApplication = { ...data.coach_application }
		state.coachApplications.push(coachApplication)
		state.loading = false
	},
	[CREATE_COACH_APPLICATION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COACH_APPLICATION_REQUEST]: state => {
		state.cpCADataLoading = true
		state.loading = true
	},
	[EDIT_COACH_APPLICATION_SUCCESS]: (state, data) => {
		let { coach_application } = data
		state.coachApplications = state.coachApplications.map(a => a.uuid == coach_application.uuid ? coach_application : a)
		if (!isEmpty(state.coachApplication) && state.coachApplication.uuid == coach_application.uuid) {
			state.coachApplication = coach_application
		}
		state.loading = false
		state.cpCADataLoading = false
	},
	[EDIT_COACH_APPLICATION_ERROR]: (state, data) => {
		const errors = data.errors
		if (Object.prototype.toString.call(errors) === '[object Object]') {
			state.submissionErrors = errors
		}

		state.loading = false
		state.cpCADataLoading = false
	},

	[DELETE_COACH_APPLICATION_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COACH_APPLICATION_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_COACH_APPLICATION_ERROR]: (state, data) => {
		state.loading = false
	},

	[RESOLVE_SUBMISSION_ERROR]: (state, errorKey) => {
		state.loading = true

		Object.keys(state.submissionErrors).forEach(pageKey => {
			if (pageKey == errorKey) {
				// delete state.submissionErrors[pageKey]
				Vue.delete(state.submissionErrors, pageKey)
			} else {
				state.submissionErrors[pageKey] = state.submissionErrors[pageKey].filter((v,i, arr) => {
					// eslint-disable-next-line no-prototype-builtins
					if (Object.prototype.toString.call(v) == '[object Object]' && v.hasOwnProperty(errorKey)) {
						delete arr[i][errorKey]
						return isEmpty(arr[i]) ? false : true
					}
					return true
				})

				if (isEmpty(state.submissionErrors[pageKey])) delete state.submissionErrors[pageKey]
			}
		})

		state.loading = false
	},

	[CLEAR_COACH_APPLICATION_STATE]: state => {
		state.coachApplications = []
		state.coachApplication = {}
		state.loading = false
		state.loadedOnce = false
	},

	setCpConfirmStep: (state, val) => {
		state.cpConfirmStep = val
	}

}


export function unpackCoachApplication(ca, commit) {
    if (!isEmpty(ca.coach)) {
		const coach = unpackCoach(ca.coach, commit)
        commit('coaches/GET_COACHES_SUCCESS', { coaches: [coach] }, { root: true })
    }
    delete ca.coach
    return ca
}


export const coachApplication = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
