export const GET_LANGUAGE_TYPES_REQUEST = "GET_LANGUAGE_TYPES_REQUEST"
export const GET_LANGUAGE_TYPES_SUCCESS = "GET_LANGUAGE_TYPES_SUCCESS"
export const GET_LANGUAGE_TYPES_ERROR = "GET_LANGUAGE_TYPES_ERROR"
export const GET_LANGUAGE_TYPE_REQUEST = "GET_LANGUAGE_TYPE_REQUEST"
export const GET_LANGUAGE_TYPE_SUCCESS = "GET_LANGUAGE_TYPE_SUCCESS"
export const GET_LANGUAGE_TYPE_ERROR = "GET_LANGUAGE_TYPE_ERROR"
export const CREATE_LANGUAGE_TYPE_REQUEST = "CREATE_LANGUAGE_TYPE_REQUEST"
export const CREATE_LANGUAGE_TYPE_SUCCESS = "CREATE_LANGUAGE_TYPE_SUCCESS"
export const CREATE_LANGUAGE_TYPE_ERROR = "CREATE_LANGUAGE_TYPE_ERROR"
export const EDIT_LANGUAGE_TYPE_REQUEST = "EDIT_LANGUAGE_TYPE_REQUEST"
export const EDIT_LANGUAGE_TYPE_SUCCESS = "EDIT_LANGUAGE_TYPE_SUCCESS"
export const EDIT_LANGUAGE_TYPE_ERROR = "EDIT_LANGUAGE_TYPE_ERROR"
export const DELETE_LANGUAGE_TYPE_REQUEST = "DELETE_LANGUAGE_TYPE_REQUEST"
export const DELETE_LANGUAGE_TYPE_SUCCESS = "DELETE_LANGUAGE_TYPE_SUCCESS"
export const DELETE_LANGUAGE_TYPE_ERROR = "DELETE_LANGUAGE_TYPE_ERROR"