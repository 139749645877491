import {
  GET_IA_APPLICATIONS_REQUEST,
  GET_IA_APPLICATIONS_SUCCESS,
  GET_IA_APPLICATIONS_ERROR,
  GET_IA_APPLICATION_REQUEST,
  GET_IA_APPLICATION_SUCCESS,
  GET_IA_APPLICATION_ERROR,
  CREATE_IA_APPLICATION_REQUEST,
  CREATE_IA_APPLICATION_SUCCESS,
  CREATE_IA_APPLICATION_ERROR,
  BROADCAST_CREATE_IA_APPLICATION_SUCCESS,
  EDIT_IA_APPLICATION_REQUEST,
  EDIT_IA_APPLICATION_SUCCESS,
  EDIT_IA_APPLICATION_ERROR,
  BROADCAST_EDIT_IA_APPLICATION_SUCCESS,
  DELETE_IA_APPLICATION_REQUEST,
  DELETE_IA_APPLICATION_SUCCESS,
  DELETE_IA_APPLICATION_ERROR,
  BROADCAST_DELETE_IA_APPLICATION_SUCCESS,
} from "@/actions"

import {
  iaApplicationService
} from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

const state = {
  iaApplications: [],
  iaApplication: {},
  loading: false
}

const getters = {
  iaApplicationLoading: state => state.loading,
  iaApplication: state => state.iaApplication,
  iaApplications: state => state.iaApplications,
  adjustmentByUuid: state => uuid => state.iaApplications.find(i => i.uuid == uuid),
  adjustmentsByUnivUuid: state => uuid => state.iaApplications.filter(ia => ia.universities_uuid == uuid),
  adjustmentsByInvoiceUuid: state => invoices_uuid => state.iaApplications.filter(ia => ia.invoices_uuid == invoices_uuid)
}

const actions = {
  async getIAApplications({ commit }, params) {
    commit("GET_IA_APPLICATIONS_REQUEST")
    const response = await iaApplicationService.getIAApplications(params);
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_IA_APPLICATIONS_SUCCESS", data)
        break
      default:
        commit("GET_IA_APPLICATIONS_ERROR", data)
    }
    return response
  },
  async getIAApplication({ commit }, params) {
    commit("GET_IA_APPLICATION_REQUEST")
    const response = await iaApplicationService.getIAApplication(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_IA_APPLICATION_SUCCESS", data)
        break
      default:
        commit("GET_IA_APPLICATION_ERROR", data)
        break
    }
    return response
  },
  async createIAApplication({ commit, dispatch }, params) {
    commit("CREATE_IA_APPLICATION_REQUEST")
    const response = await iaApplicationService.createIAApplication(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_IA_APPLICATION_SUCCESS", data) : null
        data.success ? dispatch("alert/createAlertSuccess", "Invoice Adjustment created.", { root: true }): null
        break
      default:
        commit("CREATE_IA_APPLICATION_ERROR", data)
    }
    return response
  },
  async editIAApplication({ commit }, params) {
    commit("EDIT_IA_APPLICATION_REQUEST")
    const response = await iaApplicationService.editIAApplication(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_IA_APPLICATION_SUCCESS", data) : null
        break
      default:
        commit("EDIT_IA_APPLICATION_ERROR", data)
    }
    return response
  },
  async deleteIAApplication({ commit, dispatch }, uuid) {
    commit("DELETE_IA_APPLICATION_REQUEST")
    const response = await iaApplicationService.deleteIAApplication(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_IA_APPLICATION_SUCCESS", uuid) : null
        data.success ? dispatch("alert/createAlertSuccess", "Invoice Adjustment deleted.", { root: true }) : null
        break
      default:
        commit("DELETE_IA_APPLICATION_ERROR", data)
    }
    return response
  },
  cleanStateIAApplication({ commit }) {
    commit("CLEAN_IA_APPLICATION")
  }
}

const mutations = {
  [GET_IA_APPLICATIONS_REQUEST]: state => {
    state.loading = true
  },
  [GET_IA_APPLICATIONS_SUCCESS]: (state, data) => {
    const ia_applications = data.ia_applications
    state.iaApplications = ia_applications
    state.loading = false
  },
  [GET_IA_APPLICATIONS_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_IA_APPLICATION_REQUEST]: state => {
    state.loading = true
  },
  [GET_IA_APPLICATION_SUCCESS]: (state, data) => {
    const { ia_application } = data
    state.iaApplication = ia_application
    state.loading = false
  },
  [GET_IA_APPLICATION_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_IA_APPLICATION_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_IA_APPLICATION_SUCCESS]: (state, data) => {
    const { ia_application } = data
    state.iaApplications = addNewFilter(state.iaApplications, [ia_application])
    state.iaApplication = ia_application
    state.loading = false
  },
  [BROADCAST_CREATE_IA_APPLICATION_SUCCESS]: (state, data) => {
    const { ia_application } = data
    state.iaApplications = addNewFilter(state.iaApplications, [ia_application])
  },
  [CREATE_IA_APPLICATION_ERROR]: state => {
    state.loading = false
  },

  [EDIT_IA_APPLICATION_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_IA_APPLICATION_SUCCESS]: (state, data) => {
    const { ia_application } = data
    state.iaApplications = state.iaApplications.map(i => i.uuid == ia_application.uuid ? ia_application : i)
    if (!isEmpty(state.iaApplication) && state.iaApplication.uuid == ia_application.uuid) {
      state.iaApplication = ia_application
    }
    state.loading = false
  },
  [EDIT_IA_APPLICATION_ERROR]: state => {
    state.loading = false
  },
  [BROADCAST_EDIT_IA_APPLICATION_SUCCESS]: (state, data) => {
    const { ia_application } = data
    state.iaApplications = state.iaApplications.map(i => i.uuid == ia_application.uuid ? ia_application : i)
    if (!isEmpty(state.iaApplication) && state.iaApplication.uuid == ia_application.uuid) {
      state.iaApplication = ia_application
    }
  },
  [DELETE_IA_APPLICATION_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_IA_APPLICATION_SUCCESS]: (state, uuid) => {
    state.iaApplications = state.iaApplications.filter(i => i.uuid !== uuid)
    state.loading = false
  },
  [DELETE_IA_APPLICATION_ERROR]: (state, data) => {
    state.loading = false
  },
  [BROADCAST_DELETE_IA_APPLICATION_SUCCESS]: (state, data) => {
    const { ia_application } = data
    state.iaApplications = state.iaApplications.filter(i => i.uuid != ia_application.uuid)
    state.loading = false
  }
}

export const iaApplication = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}