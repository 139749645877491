import {
  combinedWASGroupService
} from "@/services"

import {
  addNewFilter
} from "@/utilities"

const state = {
  combinedGroups: [],
  loading: false
}

const getters = {
  combinedGroupsByUniv: state => univUuid => state.combinedGroups.filter(g => g.universities_uuid == univUuid),
  findCombinedGroup: state => uuid => state.combinedGroups.find(g => g.uuid == uuid)
}

const actions = {
  async getCombinedGroups({ commit }) {
    commit("GET_COMBINED_WAS_GROUPS_REQUEST")
    const response = await combinedWASGroupService.getCombinedGroups();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_COMBINED_WAS_GROUPS_SUCCESS", data)
        break
      default:
        commit("GET_COMBINED_WAS_GROUPS_ERROR", data)
    }
    return response
  },
  async getCombinedGroup({ commit }, params) {
    commit("GET_COMBINED_WAS_GROUP_REQUEST")
    const response = await combinedWASGroupService.getCombinedGroup(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("GET_COMBINED_WAS_GROUP_SUCCESS", data) : null
        break
      default:
        commit("GET_COMBINED_WAS_GROUP_ERROR", data)
        break
    }
    return response
  },
  async createCombinedGroup({ commit, dispatch }, params) {
    commit("CREATE_COMBINED_WAS_GROUP_REQUEST")
    const response = await combinedWASGroupService.createCombinedGroup(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data?.success ? commit("CREATE_COMBINED_WAS_GROUP_SUCCESS", data) : null;
        data?.success ? dispatch("alert/createAlertSuccess", "Combined group created.", { root: true }) : null
        break
      default:
        commit("CREATE_COMBINED_WAS_GROUP_ERROR", data)
    }
    return response
  },
  async editCombinedGroup({ commit, dispatch }, params) {
    commit("EDIT_COMBINED_WAS_GROUP_REQUEST")
    const response = await combinedWASGroupService.editCombinedGroup(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data?.success ? commit("EDIT_COMBINED_WAS_GROUP_SUCCESS", data) : null;
        data?.success ? dispatch("alert/createAlertSuccess", "Combined group updated.", { root: true }) : null
        break
      default:
        commit("EDIT_COMBINED_WAS_GROUP_ERROR", data)
    }
    return response
  },
  async deleteCombinedGroup({ commit }, params) {
    const { uuid } = params;
    commit("DELETE_COMBINED_WAS_GROUP_REQUEST");
    const response = await combinedWASGroupService.deleteCombinedGroup(params);
    const { status, data } = response;
    switch (status) {
      case 200:
        data.success ? commit("DELETE_COMBINED_WAS_GROUP_SUCCESS", { combined_group: { uuid } }) : null;
        break
      default:
        commit("DELETE_COMBINED_WAS_GROUP_ERROR", data);
    }
    return response;
  }
}

const mutations = {
  GET_COMBINED_WAS_GROUPS_REQUEST: state => {
    state.loading = true
  },
  GET_COMBINED_WAS_GROUPS_SUCCESS: (state, data) => {
    state.combinedGroups = addNewFilter(state.combinedGroups, data.combined_groups ?? []);
    state.loading = false
  },
  GET_COMBINED_WAS_GROUPS_ERROR: state => {
    state.loading = false
    state.error = true
  },
  GET_COMBINED_WAS_GROUP_REQUEST: state => {
    state.loading = true
  },
  GET_COMBINED_WAS_GROUP_SUCCESS: (state, data) => {
    state.loading = false
  },
  GET_COMBINED_WAS_GROUP_ERROR: (state, data) => {
    state.loading = false
  },
  CREATE_COMBINED_WAS_GROUP_REQUEST: state => {
    state.loading = true
  },
  CREATE_COMBINED_WAS_GROUP_SUCCESS: (state, data) => {
    const { combined_group } = data;
    state.combinedGroups = addNewFilter(state.combinedGroups, [combined_group]);
    state.loading = false
  },
  CREATE_COMBINED_WAS_GROUP_ERROR: state => {
    state.loading = false
  },
  EDIT_COMBINED_WAS_GROUP_REQUEST: state => {
    state.loading = true
  },
  EDIT_COMBINED_WAS_GROUP_SUCCESS: (state, data) => {
    const { combined_group } = data;
    state.combinedGroups = state.combinedGroups.map(g => g.uuid == combined_group?.uuid ? combined_group : g);
    state.loading = false;
  },
  EDIT_COMBINED_WAS_GROUP_ERROR: state => {
    state.loading = false
  },
  DELETE_COMBINED_WAS_GROUP_REQUEST: state => {
    state.loading = true
  },
  DELETE_COMBINED_WAS_GROUP_SUCCESS: (state, data) => {
    const { combined_group } = data;
    state.combinedGroups = state.combinedGroups.filter(g => g.uuid != combined_group?.uuid);
    state.loading = false
  },
  DELETE_COMBINED_WAS_GROUP_ERROR: (state, data) => {
    state.loading = false
  }
}

export const combinedWASGroup = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
