export const GET_STATUSES_REQUEST = "GET_STATUSES_REQUEST"
export const GET_STATUSES_SUCCESS = "GET_STATUSES_SUCCESS"
export const GET_STATUSES_ERROR = "GET_STATUSES_ERROR"
export const GET_STATUS_REQUEST = "GET_STATUS_REQUEST"
export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS"
export const GET_STATUS_ERROR = "GET_STATUS_ERROR"
export const CREATE_STATUS_REQUEST = "CREATE_STATUS_REQUEST"
export const CREATE_STATUS_SUCCESS = "CREATE_STATUS_SUCCESS"
export const CREATE_STATUS_ERROR = "CREATE_STATUS_ERROR"
export const EDIT_STATUS_REQUEST = "EDIT_STATUS_REQUEST"
export const EDIT_STATUS_SUCCESS = "EDIT_STATUS_SUCCESS"
export const EDIT_STATUS_ERROR = "EDIT_STATUS_ERROR"
export const DELETE_STATUS_REQUEST = "DELETE_STATUS_REQUEST"
export const DELETE_STATUS_SUCCESS = "DELETE_STATUS_SUCCESS"
export const DELETE_STATUS_ERROR = "DELETE_STATUS_ERROR"