export const GET_UNIVERSITY_CENSUSES_REQUEST = "GET_UNIVERSITY_CENSUSES_REQUEST"
export const GET_UNIVERSITY_CENSUSES_SUCCESS = "GET_UNIVERSITY_CENSUSES_SUCCESS"
export const GET_UNIVERSITY_CENSUSES_ERROR = "GET_UNIVERSITY_CENSUSES_ERROR"
export const GET_UNIVERSITY_CENSUS_REQUEST = "GET_UNIVERSITY_CENSUS_REQUEST"
export const GET_UNIVERSITY_CENSUS_SUCCESS = "GET_UNIVERSITY_CENSUS_SUCCESS"
export const GET_UNIVERSITY_CENSUS_ERROR = "GET_UNIVERSITY_CENSUS_ERROR" 
export const CREATE_UNIVERSITY_CENSUS_REQUEST = "CREATE_UNIVERSITY_CENSUS_REQUEST"
export const CREATE_UNIVERSITY_CENSUS_SUCCESS = "CREATE_UNIVERSITY_CENSUS_SUCCESS"
export const CREATE_UNIVERSITY_CENSUS_ERROR = "CREATE_UNIVERSITY_CENSUS_ERROR"
export const EDIT_UNIVERSITY_CENSUS_REQUEST = "EDIT_UNIVERSITY_CENSUS_REQUEST"
export const EDIT_UNIVERSITY_CENSUS_SUCCESS = "EDIT_UNIVERSITY_CENSUS_SUCCESS"
export const EDIT_UNIVERSITY_CENSUS_ERROR = "EDIT_UNIVERSITY_CENSUS_ERROR"
export const DELETE_UNIVERSITY_CENSUS_REQUEST = "DELETE_UNIVERSITY_CENSUS_REQUEST"
export const DELETE_UNIVERSITY_CENSUS_SUCCESS = "DELETE_UNIVERSITY_CENSUS_SUCCESS"
export const DELETE_UNIVERSITY_CENSUS_ERROR = "DELETE_UNIVERSITY_CENSUS_ERROR"