import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const assignmentPaymentService = {
  async getAssignmentPayments(params) {
    let getParams = processQueryParams(params)
    const getAssignmentPaymentsRequest = {
      method: "GET",
      params: getParams
    }
    return await apiRequest("/api/assignment-payments", getAssignmentPaymentsRequest)
  },
  async getAssignmentPayment(uuid) {
    const getAssignmentPaymentRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/assignment-payments/${uuid}`, getAssignmentPaymentRequest)
  },
  async createAssignmentPayment(params) {
    const createData = processInput(params)
    const createAssignmentPaymentRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/assignment-payments", createAssignmentPaymentRequest)
  },
  async editAssignmentPayment(data) {
    const { uuid } = data
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editAssignmentPaymentRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/assignment-payments/${uuid}`, editAssignmentPaymentRequest)
  },
  async deleteAssignmentPayment(uuid) {
    const deleteAssignmentPaymentRequest = {
      method: "DELETE"
    }
    return await apiRequest(`/api/assignment-payments/${uuid}`, deleteAssignmentPaymentRequest)
  },
}