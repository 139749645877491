export const GET_EEO_VETERAN_STATUSES_REQUEST = "GET_EEO_VETERAN_STATUSES_REQUEST"
export const GET_EEO_VETERAN_STATUSES_SUCCESS = "GET_EEO_VETERAN_STATUSES_SUCCESS"
export const GET_EEO_VETERAN_STATUSES_ERROR = "GET_EEO_VETERAN_STATUSES_ERROR"
export const GET_EEO_VETERAN_STATUS_REQUEST = "GET_EEO_VETERAN_STATUS_REQUEST"
export const GET_EEO_VETERAN_STATUS_SUCCESS = "GET_EEO_VETERAN_STATUS_SUCCESS"
export const GET_EEO_VETERAN_STATUS_ERROR = "GET_EEO_VETERAN_STATUS_ERROR"
export const CREATE_EEO_VETERAN_STATUS_REQUEST = "CREATE_EEO_VETERAN_STATUS_REQUEST"
export const CREATE_EEO_VETERAN_STATUS_SUCCESS = "CREATE_EEO_VETERAN_STATUS_SUCCESS"
export const CREATE_EEO_VETERAN_STATUS_ERROR = "CREATE_EEO_VETERAN_STATUS_ERROR"
export const EDIT_EEO_VETERAN_STATUS_REQUEST = "EDIT_EEO_VETERAN_STATUS_REQUEST"
export const EDIT_EEO_VETERAN_STATUS_SUCCESS = "EDIT_EEO_VETERAN_STATUS_SUCCESS"
export const EDIT_EEO_VETERAN_STATUS_ERROR = "EDIT_EEO_VETERAN_STATUS_ERROR"
export const DELETE_EEO_VETERAN_STATUS_REQUEST = "DELETE_EEO_VETERAN_STATUS_REQUEST"
export const DELETE_EEO_VETERAN_STATUS_SUCCESS = "DELETE_EEO_VETERAN_STATUS_SUCCESS"
export const DELETE_EEO_VETERAN_STATUS_ERROR = "DELETE_EEO_VETERAN_STATUS_ERROR"