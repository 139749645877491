import {
    apiRequest,
    processInput
} from "@/utilities"

export const eeoRaceEthnicityService = {
    async getEEORaceEthnicities() {
        const getEEORaceEthnicitiesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/eeo-race-ethnicities", getEEORaceEthnicitiesRequest)
    },
    async getEEORaceEthnicity(uuid) {
        const getEEORaceEthnicityRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/eeo-race-ethnicities/${uuid}`, getEEORaceEthnicityRequest)
    },
    async createEEORaceEthnicity(params) {
        const createData = processInput(params)
        const createEEORaceEthnicityRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/eeo-race-ethnicities", createEEORaceEthnicityRequest)
    },
    async editEEORaceEthnicity(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editEEORaceEthnicityRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/eeo-race-ethnicities/${uuid}`, editEEORaceEthnicityRequest)
    },
    async deleteEEORaceEthnicity(uuid) {
        const deleteEEORaceEthnicityRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/eeo-race-ethnicities/${uuid}`, deleteEEORaceEthnicityRequest)
    }
}