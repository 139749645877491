import {
	apiRequest,
	processInput
} from "@/utilities"

export const authService = {
	async login(formInput) {

		const csrfCookieRequestOptions = {
			method: 'GET'
		}

		await apiRequest("/sanctum/csrf-cookie", csrfCookieRequestOptions);

		const loginRequestOptions = {
			method: "POST",
			data: processInput(formInput)
		}

		return await apiRequest("/api/login", loginRequestOptions);
	},

	async forgotPassword(formInput) {

		const csrfCookieRequestOptions = {
			method: 'GET'
		}

		await apiRequest("/sanctum/csrf-cookie", csrfCookieRequestOptions);

		const forgotPasswordRequest = {
			method: "POST",
			data: processInput(formInput)
		}

		return await apiRequest("/api/forgot-password", forgotPasswordRequest);
	},

	async resetPassword(formInput) {

		let data = processInput(formInput);

		const resetPasswordRequest = {
			method: "POST",
			data: data
		}

		return await apiRequest("/api/reset-password", resetPasswordRequest)
	},

	async logout() {

		const logoutRequest = {
			method: "POST"
		}

		return apiRequest("/api/logout", logoutRequest);
	},

	async getAuthUser() {

		const csrfCookieRequestOptions = {
			method: 'GET'
		}

		await apiRequest("/sanctum/csrf-cookie", csrfCookieRequestOptions)

		const getAuthUserRequest = {
			method: "GET"
		}

		return await apiRequest("/api/auth", getAuthUserRequest);
	},

	async getMagicLink(params) {

		const getMagicLinkData = processInput(params);
		
		const getMagicLinkRequest = {
			method: "POST",
			data: getMagicLinkData
		}

		return await apiRequest("/magic-link-coach-portal", getMagicLinkRequest);
	},

	async sendMagicLinkUniversityAdmin(params) {

		const getMagicLinkData = processInput(params);
		
		const getMagicLinkRequest = {
			method: "POST",
			data: getMagicLinkData
		}

		return await apiRequest("/api/university-portal-link", getMagicLinkRequest);
	}
};