export const GET_UNIVERSITY_STATUSES_REQUEST = "GET_UNIVERSITY_STATUSES_REQUEST"
export const GET_UNIVERSITY_STATUSES_SUCCESS = "GET_UNIVERSITY_STATUSES_SUCCESS"
export const GET_UNIVERSITY_STATUSES_ERROR = "GET_UNIVERSITY_STATUSES_ERROR"
export const GET_UNIVERSITY_STATUS_REQUEST = "GET_UNIVERSITY_STATUS_REQUEST"
export const GET_UNIVERSITY_STATUS_SUCCESS = "GET_UNIVERSITY_STATUS_SUCCESS"
export const GET_UNIVERSITY_STATUS_ERROR = "GET_UNIVERSITY_STATUS_ERROR"
export const CREATE_UNIVERSITY_STATUS_REQUEST = "CREATE_UNIVERSITY_STATUS_REQUEST"
export const CREATE_UNIVERSITY_STATUS_SUCCESS = "CREATE_UNIVERSITY_STATUS_SUCCESS"
export const CREATE_UNIVERSITY_STATUS_ERROR = "CREATE_UNIVERSITY_STATUS_ERROR"
export const EDIT_UNIVERSITY_STATUS_REQUEST = "EDIT_UNIVERSITY_STATUS_REQUEST"
export const EDIT_UNIVERSITY_STATUS_SUCCESS = "EDIT_UNIVERSITY_STATUS_SUCCESS"
export const EDIT_UNIVERSITY_STATUS_ERROR = "EDIT_UNIVERSITY_STATUS_ERROR"
export const DELETE_UNIVERSITY_STATUS_REQUEST = "DELETE_UNIVERSITY_STATUS_REQUEST"
export const DELETE_UNIVERSITY_STATUS_SUCCESS = "DELETE_UNIVERSITY_STATUS_SUCCESS"
export const DELETE_UNIVERSITY_STATUS_ERROR = "DELETE_UNIVERSITY_STATUS_ERROR"