import {
	GET_ADDRESSES_REQUEST,
	GET_ADDRESSES_SUCCESS,
	GET_ADDRESSES_ERROR,
	GET_ADDRESS_REQUEST,
	GET_ADDRESS_SUCCESS,
	GET_ADDRESS_ERROR,
	CREATE_ADDRESS_REQUEST,
	CREATE_ADDRESS_SUCCESS,
	CREATE_ADDRESS_ERROR,
	EDIT_ADDRESS_REQUEST,
	EDIT_ADDRESS_SUCCESS,
	EDIT_ADDRESS_ERROR,
	DELETE_ADDRESS_REQUEST,
	DELETE_ADDRESS_SUCCESS,
	DELETE_ADDRESS_ERROR,
	CLEAR_ADDRESS_STATE
} from "@/actions"

import {
	addressService
} from "@/services"

const state = {
	addresses: [],
	address: {},
	loading: false,
	loadedOnce: false
}

import { addNewFilter, isEmpty } from "@/utilities"

const getters = {
	addressLoadedOnce: state => state.loadedOnce,
	addressByAddressableUuid: state => uuid => state.addresses.filter(a => a.addressable_uuid == uuid),
	mailingAddress: state => state.addresses.find(address => address.address_type.slug == 'mailing-address'),
	searchAddresses: state => (addressTypeSlug, addressableUuid) => state.addresses.find(a => a.address_type.slug == addressTypeSlug && a.addressable_uuid == addressableUuid),
	universityAddress: state => univUuid => state.addresses.find(address => address.address_type.slug == 'university-address' && address.addressable_uuid == univUuid),
	coachMailingAddress: (state, getters, rootState, rootGetters) => state.addresses.find(a => a.addressable_uuid == rootGetters['coaches/coach']['uuid'] && a.address_type.slug == 'mailing-address')
}

const actions = {
	async getAddresses({ commit }, params) {
		commit("GET_ADDRESSES_REQUEST")
		const response = await addressService.getAddresses(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_ADDRESSES_SUCCESS", data)
				break
			default:
				commit("GET_ADDRESSES_ERROR", data)
		}
		return response
	},
	async getAddress({ commit }, params) {
		commit("GET_ADDRESS_REQUEST")
		const response = await addressService.getAddress(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_ADDRESS_SUCCESS", data)
				break
			default:
				commit("GET_ADDRESS_ERROR", data)
				break
		}
		return response
	},
	async createAddress({ commit }, params) {
		commit("CREATE_ADDRESS_REQUEST")
		const response = await addressService.createAddress(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_ADDRESS_SUCCESS", data) : commit("CREATE_ADDRESS_ERROR", data)
				break
			default:
				commit("CREATE_ADDRESS_ERROR", data)
		}
		return response
	},
	async editAddress({ commit }, params) {
		commit("EDIT_ADDRESS_REQUEST")
		const uuid = params.uuid
		const response = await addressService.editAddress(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? await commit("EDIT_ADDRESS_SUCCESS", data) : commit("EDIT_ADDRESS_ERROR", data)
				break
			default:
				commit("EDIT_ADDRESS_ERROR", data)
		}
		return response
	},
	async deleteAddress({ commit }, params) {
		commit("DELETE_ADDRESS_REQUEST")
		const response = await addressService.deleteAddress(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_ADDRESS_SUCCESS", data)
				break
			default:
				commit("DELETE_ADDRESS_DELETE", data)
		}
		return response
	},
	async autocompleteAddress({ commit }, params) {
		return await addressService.autocomplete(params);
	},
	async clearState({ commit }) {
		commit("CLEAR_STATE")
		return
	}
}

const mutations = {
	[GET_ADDRESSES_REQUEST]: state => {
		state.loading = true
	},
	[GET_ADDRESSES_SUCCESS]: (state, data) => {
		const addresses = data.addresses
		state.addresses = addNewFilter(state.addresses, addresses)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_ADDRESSES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},

	[GET_ADDRESS_REQUEST]: state => {
		state.loading = true
	},
	[GET_ADDRESS_SUCCESS]: (state, data) => {
		const address = { ...data.address }
		state.address = address
		state.loading = false
	},
	[GET_ADDRESS_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_ADDRESS_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_ADDRESS_SUCCESS]: (state, data) => {
		const { address } = data
		if(!isEmpty(address)){
			state.addresses = addNewFilter(state.addresses, [address])
		}
		state.loading = false
	},
	[CREATE_ADDRESS_ERROR]: state => {
		state.loading = false
	},

	[EDIT_ADDRESS_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_ADDRESS_SUCCESS]: (state, data) => {
		const { address } = data
		if(!isEmpty(address)){
			state.addresses = state.addresses.filter(a => a.uuid != address.uuid)
			state.addresses = addNewFilter(state.addresses, [address])
		}
		state.loading = false
	},
	[EDIT_ADDRESS_ERROR]: state => {
		state.loading = false
	},

	[DELETE_ADDRESS_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_ADDRESS_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_ADDRESS_ERROR]: (state, data) => {
		state.loading = false
		const { address } = data
		if(!isEmpty(address)){
			state.addresses = state.addresses.filter(a => a.uuid != address.uuid)
		}else if(typeof data == 'string'){
			state.addresses = state.addresses.filter(a => a.uuid != data)
		}
	},

	[CLEAR_ADDRESS_STATE]: state => {
		state.addresses = []
		state.address = {}
		state.loading = false
		state.loadedOnce = false
	}
}

export const address = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}