import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachApplicationService = {
    async getCoachApplications(params) {
        let getParams = processQueryParams(params)
        const getCoachApplicationsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-applications", getCoachApplicationsRequest)
    },
    async getCoachApplication(uuid) {
        const getCoachApplicationRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-applications/${uuid}`, getCoachApplicationRequest)
    },
    async createCoachApplication(params) {
        const createData = processInput(params)
        const createCoachApplicationRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-applications", createCoachApplicationRequest)
    },
    async editCoachApplication(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachApplicationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-applications/${uuid}`, editCoachApplicationRequest)
    },
    async editStepCoachApplication(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachApplicationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-application-submit-step/${uuid}`, editCoachApplicationRequest)
    },
    async deleteCoachApplication(uuid) {
        const deleteCoachApplicationRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-applications/${uuid}`, deleteCoachApplicationRequest)
    }
}