export function common() {
    return {
        'X-Requested-With': 'XMLHttpRequest',
    };
}

export function accessControl() {
    return {
        'Access-Control-Allow-Origin': `${process.env.VUE_APP_URL}`
    };
}

export function contentTypeForm() {
    return {
        'Content-Type': 'multipart/form-data; charset=UTF-8'
    };
}

export function acceptHeader() {
    return {
        "Accept": "application/json"
    }
}