import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const invoiceAdjustmentService = {
  async getInvoiceAdjustments(params) {
    let getParams = processQueryParams(params)
    const getInvoiceAdjustmentsRequest = {
      method: "GET",
      params: getParams
    }
    return await apiRequest("/api/invoice-adjustments", getInvoiceAdjustmentsRequest)
  },
  async getInvoiceAdjustment(uuid) {
    const getInvoiceAdjustmentRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/invoice-adjustments/${uuid}`, getInvoiceAdjustmentRequest)
  },
  async createInvoiceAdjustment(params) {
    const createData = processInput(params)
    const createInvoiceAdjustmentRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/invoice-adjustments", createInvoiceAdjustmentRequest)
  },
  async editInvoiceAdjustment(data) {
    const { uuid } = data
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editInvoiceAdjustmentRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/invoice-adjustments/${uuid}`, editInvoiceAdjustmentRequest)
  },
  async deleteInvoiceAdjustment(uuid) {
    const deleteInvoiceAdjustmentRequest = {
      method: "DELETE"
    }
    return await apiRequest(`/api/invoice-adjustments/${uuid}`, deleteInvoiceAdjustmentRequest)
  }
}