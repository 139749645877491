const state = {
    deliveryMethods: [
        { value: 'online', label: "Online" },
        { value: 'face-to-face', label: "Face-to-Face/On-Ground" },
        { value: 'hybrid', label: "Hybrid" },
    ],
    deliveryMethod: {},
    loading: false
}

const getters = {
    deliveryMethodSelectOptions: state => state.deliveryMethods.map(deliveryMethod => deliveryMethod)
}

const actions = {

}

const mutations = {

}

export const deliveryMethod = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}