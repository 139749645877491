export const GET_DOCUMENTS_REQUEST = "GET_DOCUMENTS_REQUEST"
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS"
export const GET_DOCUMENTS_ERROR = "GET_DOCUMENTS_ERROR"
export const GET_DOCUMENT_REQUEST = "GET_DOCUMENT_REQUEST"
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS"
export const GET_DOCUMENT_ERROR = "GET_DOCUMENT_ERROR"
export const CREATE_DOCUMENT_REQUEST = "CREATE_DOCUMENT_REQUEST"
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS"
export const CREATE_DOCUMENT_ERROR = "CREATE_DOCUMENT_ERROR"
export const EDIT_DOCUMENT_REQUEST = "EDIT_DOCUMENT_REQUEST"
export const EDIT_DOCUMENT_SUCCESS = "EDIT_DOCUMENT_SUCCESS"
export const EDIT_DOCUMENT_ERROR = "EDIT_DOCUMENT_ERROR"
export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST"
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS"
export const DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR"
export const SEND_DOCUMENT_REQUEST = "SEND_DOCUMENT_REQUEST"
export const SEND_DOCUMENT_SUCCESS = "SEND_DOCUMENT_SUCCESS"
export const SEND_DOCUMENT_ERROR = "SEND_DOCUMENT_ERROR"
export const CLEAR_DOCUMENT_STATE = "CLEAR_DOCUMENT_STATE"
export const BROADCAST_DELETE_DOCUMENT_SUCCESS = "BROADCAST_DELETE_DOCUMENT_SUCCESS"
