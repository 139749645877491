import {
  apiRequest,
  processInput,
  addNewFilter,
  processQueryParams,
  isEmpty,
} from "@/utilities"
import { unpackCourseStartFaculty } from "@/modules"

const state = {
  finalizedStudents: [],
}
function mapData(data, rootGetters) {
  let weeks = data.map(i => i.week)
  let firstObj = data.find((i, idx) => idx === 0)
  const weekList = []
  if (isEmpty(firstObj)) return []

  if (weeks.length === 1) {
    let finalized_data = firstObj;

    weekList.push({
      label: '',
      value: '0',
      ...finalized_data,
    });
  } else {
    weeks.forEach((w, idx) => {
      let next = !isEmpty(weeks[idx + 1]) ? weeks[idx + 1] : null;
      let currentLabel = `Week ${w}`;
      let nextLabel = !isEmpty(next) ? `Week ${next - 1}` : `Week ${firstObj.course_length}`;
      let label = `${currentLabel} to ${nextLabel}`
      if (currentLabel == nextLabel) {
        label = currentLabel
      }
      let value = !isEmpty(next) ? w : "0";
      let finalized_data = data.find(i => i.week == w)
      weekList.push({
        label: ` (${label})`,
        value: value.toString(),
        ...finalized_data,
      })
    })
  }
  return weekList
}
const getters = {
  finalizedStudents: state => state.finalizedStudents,
  finalizedStudentsByFinalizableUuid: (state, getters, rootState, rootGetters) => uuid => {
    let data = state.finalizedStudents.filter(i => {
      return i.finalizable_uuid == uuid
    }).sort((a, b) => a.week - b.week);
    return mapData(data, rootGetters)
  },
  //.sort((a,b) => a.week - b.week),
}

const actions = {
  async getfinalizedStudents({ commit }, params) {
    let getParams = processQueryParams(params)
    const getAllReq = {
      method: "GET",
      params: getParams
    }
    const response = await apiRequest(`/api/finalized-students`, getAllReq)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_FINALIZE_STUDENTS_SUCCESS", data)
        break
      default:
        break
    }
    return response
  },
}

const mutations = {
  GET_FINALIZE_STUDENTS_SUCCESS: (state, data) => {
    const finalizedStudents = data.finalized_students
    state.finalizedStudents = addNewFilter(state.finalizedStudents, finalizedStudents, data.force || false)
  },
  GET_FINALIZE_STUDENT_SUCCESS: (state, data) => {
    const finalizedStudent = data.finalized_student
    state.finalizedStudents = addNewFilter(state.finalizedStudents, [finalizedStudent], true)
  },
  CREATE_FINALIZE_STUDENT_SUCCESS: (state, data) => {
    const finalizedStudent = data.finalized_student
    state.finalizedStudents = addNewFilter(state.finalizedStudents, [finalizedStudent])
  },
  EDIT_FINALIZE_STUDENT_SUCCESS: (state, data) => {
    state.finalizedStudents = addNewFilter(state.finalizedStudents, [data.finalized_student])
  },
  DELETE_FINALIZE_STUDENT_SUCCESS: (state, data) => {
    const { finalized_student } = data;
    state.finalizedStudents = state.finalizedStudents.filter(fs => fs.uuid !== finalized_student.uuid);
  }
}

export const finalizedStudent = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
