export const GET_AREA_OF_QUALIFICATIONS_REQUEST = "GET_AREA_OF_QUALIFICATIONS_REQUEST"
export const GET_AREA_OF_QUALIFICATIONS_SUCCESS = "GET_AREA_OF_QUALIFICATIONS_SUCCESS"
export const GET_AREA_OF_QUALIFICATIONS_ERROR = "GET_AREA_OF_QUALIFICATIONS_ERROR"
export const GET_AREA_OF_QUALIFICATION_REQUEST = "GET_AREA_OF_QUALIFICATION_REQUEST"
export const GET_AREA_OF_QUALIFICATION_SUCCESS = "GET_AREA_OF_QUALIFICATION_SUCCESS"
export const GET_AREA_OF_QUALIFICATION_ERROR = "GET_AREA_OF_QUALIFICATION_ERROR"
export const CREATE_AREA_OF_QUALIFICATION_REQUEST = "CREATE_AREA_OF_QUALIFICATION_REQUEST"
export const CREATE_AREA_OF_QUALIFICATION_SUCCESS = "CREATE_AREA_OF_QUALIFICATION_SUCCESS"
export const CREATE_AREA_OF_QUALIFICATION_ERROR = "CREATE_AREA_OF_QUALIFICATION_ERROR"
export const EDIT_AREA_OF_QUALIFICATION_REQUEST = "EDIT_AREA_OF_QUALIFICATION_REQUEST"
export const EDIT_AREA_OF_QUALIFICATION_SUCCESS = "EDIT_AREA_OF_QUALIFICATION_SUCCESS"
export const EDIT_AREA_OF_QUALIFICATION_ERROR = "EDIT_AREA_OF_QUALIFICATION_ERROR"
export const DELETE_AREA_OF_QUALIFICATION_REQUEST = "DELETE_AREA_OF_QUALIFICATION_REQUEST"
export const DELETE_AREA_OF_QUALIFICATION_SUCCESS = "DELETE_AREA_OF_QUALIFICATION_SUCCESS"
export const DELETE_AREA_OF_QUALIFICATION_ERROR = "DELETE_AREA_OF_QUALIFICATION_ERROR"