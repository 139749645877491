<template>
    <span :class="{'d-flex': addStyle, 'justify-content-center': addStyle}">
        <template v-if="!hasData">
            <template v-if="!isEmpty(loadingText)">
                {{loadingText}}
            </template>
            <template v-if="hasLoader">
                <CSpinner :color="spinnerColor" />
            </template>
        </template>
        <slot v-else></slot>
    </span>
</template>

<script>

import { isEmpty } from "@/utilities"

export default {
    name: "SkeletonLoader",
    props: {
        hasData: {
            type: Boolean,
            required: true
        },
        hasLoader: {
            type: Boolean,
            default: true,
            required: false
        },
        loadingText: {
            type: String,
            default: 'Loading...',
            required: false
        },
        parentOnCenterWhileLoading: {
            type: Boolean,
            default: false,
            required: false
        },
        spinnerColor: {
            type: String,
            default: "primary",
            required: false
        },
    }, 
    computed: {
        addStyle(){
            return this.parentOnCenterWhileLoading && !this.hasData
        }
    },
    methods: {
        isEmpty
    }
}
</script>