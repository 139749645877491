import {
    GET_COACH_FACULTY_REF_REQUEST,
    GET_COACH_FACULTY_REF_SUCCESS,
    GET_COACH_FACULTY_REF_ERROR,
    CREATE_COACH_FACULTY_REF_REQUEST,
    CREATE_COACH_FACULTY_REF_SUCCESS,
    CREATE_COACH_FACULTY_REF_ERROR,
    EDIT_COACH_FACULTY_REF_REQUEST,
    EDIT_COACH_FACULTY_REF_SUCCESS,
    EDIT_COACH_FACULTY_REF_ERROR,
    DELETE_COACH_FACULTY_REF_REQUEST,
    DELETE_COACH_FACULTY_REF_SUCCESS,
    DELETE_COACH_FACULTY_REF_ERROR,
    CLEAR_CURRENT_COACH_FACULTY_REF
} from "@/actions"

import {
    coachFacultyRefService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"
import { unpackCoach, unpackCoachAssignment } from "@/modules"


const state = {
    facultyRef: {},
    facultyRefs: [],
    
}


const getters = {
    facultyRef: state => state.facultyRef,
    facultyRefs: state => state.facultyRefs,
    facultyRefsByCSUuid: state => csUuid => state.facultyRefs.filter(i => i.course_starts_uuid == csUuid),
    facultyRefsByCoachesUuid: state => cUuid => state.facultyRefs.filter(cfr => cfr.coaches_uuid == cUuid),
    coachFacultyReferrals: (state, getters, rootState, rootGetters) => state.facultyRefs.filter(cfr => cfr.coaches_uuid == rootGetters['coaches/coach']['uuid'])
}

const actions = {
    async getCoachFacultyReferral({ commit }, uuid) {
        commit("GET_COACH_FACULTY_REF_REQUEST")
        const response = await coachFacultyRefService.getCoachFacultyReferral(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_FACULTY_REF_SUCCESS", data)
                break
            default:
                commit("GET_COACH_FACULTY_REF_ERROR", data)
                break
        }
        return response
    },
    async createCoachFacultyReferral({ commit }, params) {
        commit("CREATE_COACH_FACULTY_REF_REQUEST")
        const response = await coachFacultyRefService.createCoachFacultyReferral(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COACH_FACULTY_REF_SUCCESS", data)
                break
            default:
                commit("CREATE_COACH_FACULTY_REF_ERROR", data)
        }
        return response
    },
    async editCoachFacultyReferral({ commit }, params) {
        const uuid = params.uuid
        commit("EDIT_COACH_FACULTY_REF_REQUEST")
        const response = await coachFacultyRefService.editCoachFacultyReferral(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COACH_FACULTY_REF_SUCCESS", data)
                break
            default:
                commit("EDIT_COACH_FACULTY_REF_ERROR", data)
        }
        return response
    },
    async deleteCoachFacultyReferral({ commit }, params) {
        commit("DELETE_COACH_FACULTY_REF_REQUEST")
        const response = await coachFacultyRefService.deleteCoachFacultyReferral(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COACH_FACULTY_REF_SUCCESS", data)
                break
            default:
                commit("DELETE_COACH_FACULTY_REF_DELETE", data)
        }
        return response
    },
    async clearCurrentCoach({ commit }) {
        commit("CLEAR_CURRENT_COACH_FACULTY_REF")
        return
    }
}

const mutations = {
    [GET_COACH_FACULTY_REF_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_FACULTY_REF_SUCCESS]: (state, data) => {
        const facultyRefs = data.coach_faculty_referrals
        state.facultyRefs = addNewFilter(state.facultyRefs, facultyRefs)
        state.loading = false
    },
    [GET_COACH_FACULTY_REF_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_FACULTY_REF_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_FACULTY_REF_SUCCESS]: (state, data) => {
        const facultyRef = data.coach_faculty_referral
        state.facultyRefs = addNewFilter(state.facultyRefs, [facultyRef])
        state.loading = false
    },
    [CREATE_COACH_FACULTY_REF_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_FACULTY_REF_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_FACULTY_REF_SUCCESS]: (state, data) => {
        const facultyRef = data.coach_faculty_referral
        state.facultyRefs = addNewFilter(state.facultyRefs, [facultyRef])
        state.loading = false
    },
    [EDIT_COACH_FACULTY_REF_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_FACULTY_REF_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_FACULTY_REF_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_COACH_FACULTY_REF_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_CURRENT_COACH_FACULTY_REF]: state => {
        state.coach = {}
    }

}

export const coachFacultyReferral = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export function unpackCoachFacultyReferral(cfr, commit) {
    if(!isEmpty(cfr.coach)){
        let coach = unpackCoach(cfr.coach, commit)
        commit("coaches/GET_COACHES_SUCCESS", { coaches: [coach] }, { root: true })
        delete cfr.coach
    }
    if (!isEmpty(cfr.coach_assignments)) {
		let coach_assignments = cfr.coach_assignments.map(ca => unpackCoachAssignment(ca, commit))
		commit('coachAssignment/GET_COACH_ASSIGNMENTS_SUCCESS', { coach_assignments }, { root: true })
        delete cfr.coach_assignments
	}
    return cfr
}