export const universityStatusRoutes = {
    path: 'university-statuses',
    component: () => import("@/pages/university/status/UniversityStatusHome.vue"),
    children: [
        {
            path: '',
            name: 'university-statuses',
            meta: {
                label: 'University Statuses'
            },
            component: () => import("@/pages/university/status/UniversityStatusIndex.vue")
        },
        {
            path: ':uuid',
            name: 'university-status detail',
            meta: {
                label: 'University Details'
            },
            component: () => import("@/pages/university/status/UniversityStatusDetail.vue"),
        },
        {
            path: 'create/new',
            name: 'university-status create',
            meta: {
                label: 'Create University Status'
            },
            component: () => import("@/pages/university/status/UniversityStatusCreate.vue"),
        },
    ]
}