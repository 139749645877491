import {
    GET_MINORS_REQUEST,
    GET_MINORS_SUCCESS,
    GET_MINORS_ERROR,
    GET_MINOR_REQUEST,
    GET_MINOR_SUCCESS,
    GET_MINOR_ERROR,
    CREATE_MINOR_REQUEST,
    CREATE_MINOR_SUCCESS,
    CREATE_MINOR_ERROR,
    EDIT_MINOR_REQUEST,
    EDIT_MINOR_SUCCESS,
    EDIT_MINOR_ERROR,
    DELETE_MINOR_REQUEST,
    DELETE_MINOR_SUCCESS,
    DELETE_MINOR_ERROR
} from "@/actions"

import {
    minorService
} from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

const state = {
    minors: [],
    minor: {},
    loading: false,
    loadedOnce: true
}

const getters = {
    minorsLoadedOnce: state => state.loadedOnce,
    minorSelectOptions: state => state.minors.map(minor => ({ value: minor.uuid, label: minor.label })).sort((a, b) => {
        var nameA = a.label.toUpperCase(); // ignore upper and lowercase
        var nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1; //nameA comes first
        }
        if (nameA > nameB) {
            return 1; // nameB comes first
        }
        return 0;  // names must be equal
    }),
    minorOptionFilterUuid: (state) => uuid => state.minors.find(deg => deg.uuid == uuid)
}

const actions = {
    async getMinors({ commit }) {
        commit("GET_MINORS_REQUEST")
        const response = await minorService.getMinors();
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("GET_MINORS_SUCCESS", data) : null
                break
            default:
                commit("GET_MINORS_ERROR", data)
        }
        return response
    },
    async getMinor({ commit }, params) {
        commit("GET_MINOR_REQUEST")
        const response = await minorService.getMinor(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("GET_MINOR_SUCCESS", data) : null
                break
            default:
                commit("GET_MINOR_ERROR", data)
                break
        }
        return response
    },
    async createMinor({ commit }, params) {
        commit("CREATE_MINOR_REQUEST")
        const response = await minorService.createMinor(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_MINOR_SUCCESS", data) : null
                break
            default:
                commit("CREATE_MINOR_ERROR", data)
        }
        return response
    },
    async editMinor({ commit }, params, uuid) {
        commit("EDIT_MINOR_REQUEST")
        const response = await minorService.editMinor(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("EDIT_MINOR_SUCCESS", data) : null
                break
            default:
                commit("EDIT_MINOR_ERROR", data)
        }
        return response
    },
    async deleteMinor({ commit }, params) {
        commit("DELETE_MINOR_REQUEST")
        const response = await minorService.deleteMinor(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("DELETE_MINOR_SUCCESS", data) : null
                break
            default:
                commit("DELETE_MINOR_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_MINORS_REQUEST]: state => {
        state.loading = true
    },
    [GET_MINORS_SUCCESS]: (state, data) => {
        const minors = data.minors
        state.minors = minors
        state.loading = false
        state.loadedOnce = true
    },
    [GET_MINORS_ERROR]: state => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [GET_MINOR_REQUEST]: state => {
        state.loading = true
    },
    [GET_MINOR_SUCCESS]: (state, data) => {
        const minor = { ...data.minor }
        state.minor = minor
        state.loading = false
    },
    [GET_MINOR_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_MINOR_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_MINOR_SUCCESS]: (state, data) => {
        const { minor } = data
        state.minors = addNewFilter(state.minors, [minor])
        state.loading = false
    },
    [CREATE_MINOR_ERROR]: state => {
        state.loading = false
    },

    [EDIT_MINOR_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_MINOR_SUCCESS]: (state, data) => {
        const { minor } = data
        state.minors = state.minors.map(m => m.uuid == minor.uuid ? minor : m)
        state.loading = false
    },
    [EDIT_MINOR_ERROR]: state => {
        state.loading = false
    },

    [DELETE_MINOR_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_MINOR_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_MINOR_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const minor = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
