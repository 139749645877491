import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const courseStartService = {
    async getCourseStarts(params) {
        const cssParams = processQueryParams(params)
        const getCourseStartsRequest = {
            method: "GET",
            params: cssParams
        }

        return await apiRequest("/api/course-starts", getCourseStartsRequest)
    },
    async createCourseStart(params) {

        const createData = processInput(params)
        const postCourseStartRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/course-starts", postCourseStartRequest)
    },
    async getCourseStart(params) {
        const { uuid } = params
        delete params['uuid']
        const csGetByIdParams = processQueryParams(params)
        const getCourseStartRequest = {
            method: "GET",
            params: csGetByIdParams
        }
        return await apiRequest(`/api/course-starts/${uuid}`, getCourseStartRequest);
    },
    async editCourseStart(data, uuid) {

        let editData = processInput(data)
        editData.append("_method", "PATCH")

        const updateCourseStartRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/course-starts/${uuid}`, updateCourseStartRequest);
    },
    async deleteCourseStart(data) {
        let params = processQueryParams(data)
        const deleteCourseStartRequest = {
            method: "DELETE",
            params
        }
        return await apiRequest(`/api/course-starts/${data.uuid}`, deleteCourseStartRequest);
    },
    async uploadCourseStart(params) {
        let uploadData = processInput(params)
        const uploadCoachRequestRequest = {
            method: "POST",
            data: uploadData
        }
        return await apiRequest(`/api/coach-requests-upload`, uploadCoachRequestRequest)
    },
    async sendFacultyCoachEmail(uuid) {
        const uploadCoachRequestRequest = {
            method: "POST",
        }
        return await apiRequest(`/api/course-starts-faculty-coach-email/${uuid}`, uploadCoachRequestRequest)
    },
    async sendResumes(params) {
        let sendData = processInput(params)
        const sendResumeRequest = {
            method: "POST",
            data: sendData
        }
        return await apiRequest(`/api/send-resume-course-start`, sendResumeRequest)
    },
    async sendAssignmentLetter(params) {
        let sendData = processInput(params)
        const sendResumeRequest = {
            method: "POST",
            data: sendData
        }
        return await apiRequest(`/api/send-assignment-letter`, sendResumeRequest)
    },
    async removeStudentsFromRoster(data) {
        const removeData = processInput(data)
        const postRemoveData = {
            method: "POST",
            data: removeData
        }
        return await apiRequest("/api/remove-students", postRemoveData)
    },
    async sendFacultyEvaluationLink(params) {
        const { uuid } = params;
        delete params['uuid'];
        const sendFacLinkRequest = {
            method: "GET",
            params: processQueryParams(params)
        }
        return await apiRequest(`/api/course-starts/${uuid}/send-faculty-evaluation-link`, sendFacLinkRequest);
    }
}