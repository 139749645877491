import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const specialtyService = {
  async getSpecialties() {
    const getSpecialtiesRequest = {
      method: "GET"
    }
    return await apiRequest("/api/specialties", getSpecialtiesRequest)
  },
  async getSpecialty(uuid) {
    const getSpecialtyRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/specialties/${uuid}`, getSpecialtyRequest)
  },
  async createSpecialty(params) {
    const createData = processInput(params)
    const createSpecialtyRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/specialties", createSpecialtyRequest)
  },
  async editSpecialty(data, uuid) {
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editSpecialtyRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/specialties/${uuid}`, editSpecialtyRequest)
  },
  async deleteSpecialty(params, uuid) {
    const deleteData = processQueryParams(params)
    const deleteSpecialtyRequest = {
      method: "DELETE",
      params: deleteData
    }
    return await apiRequest(`/api/specialties/${uuid}`, deleteSpecialtyRequest)
  }
}
