import {
  CREATE_RECONCILIATION_REQUEST,
  CREATE_RECONCILIATION_SUCCESS,
  CREATE_RECONCILIATION_ERROR,
  DELETE_RECONCILIATION_REQUEST,
  DELETE_RECONCILIATION_SUCCESS,
  DELETE_RECONCILIATION_ERROR,
  RESET_RECONCILIATION_REQUEST,
  RESET_RECONCILIATION_SUCCESS,
  RESET_RECONCILIATION_ERROR,
  FINALIZE_RECONCILIATION_REQUEST,
  FINALIZE_RECONCILIATION_SUCCESS,
  FINALIZE_RECONCILIATION_ERROR,
} from "@/actions"

import {
  reconciliationService
} from "@/services"

import { unpackWAS } from '@/modules'
import { isEmpty } from '@/utilities'

const state = {
  reconciliations: [],
  reconciliation: {},
  loading: false,
  loadedOnce: false
}

const getters = {

}

const actions = {
  async createReconciliation({ commit }, params) {
    commit("CREATE_RECONCILIATION_REQUEST")
    const response = await reconciliationService.createReconciliation(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_RECONCILIATION_SUCCESS", data) : commit("CREATE_RECONCILIATION_ERROR")
        // if(!isEmpty(data.working_assignment_sheet)){
        //     let working_assignment_sheet = unpackWAS(data.working_assignment_sheet, commit)
        //     commit("workingAssignmentSheet/EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS", { working_assignment_sheet }, {root: true})
        // }
        // if(!isEmpty(data.reconciliation_items)){
        //     let reconciliation_items = data.reconciliation_items
        //     commit("reconciliationItem/GET_RECONCILIATION_ITEMS_SUCCESS", {reconciliation_items}, {root: true})
        // }
        break
      default:
        commit("CREATE_RECONCILIATION_ERROR", data)
    }
    return response
  },
  async deleteReconciliation({ commit }, params) {
    commit("DELETE_RECONCILIATION_REQUEST")
    const response = await reconciliationService.deleteReconciliation(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_RECONCILIATION_SUCCESS", data) : commit("DELETE_RECONCILIATION_ERROR")
        if (!isEmpty(data.working_assignment_sheet)) {
          let working_assignment_sheet = unpackWAS(data.working_assignment_sheet, commit)
          commit("workingAssignmentSheet/EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS", { working_assignment_sheet }, { root: true })
        }
        break
      default:
        commit("DELETE_RECONCILIATION_ERROR", data)
    }
    return response
  },
  async resetReconciliation({ commit }, params) {
    commit("RESET_RECONCILIATION_REQUEST")
    const response = await reconciliationService.resetReconciliation(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("RESET_RECONCILIATION_SUCCESS", data) : commit("RESET_RECONCILIATION_ERROR")
        if (!isEmpty(data.working_assignment_sheet)) {
          let working_assignment_sheet = unpackWAS(data.working_assignment_sheet, commit)
          commit("workingAssignmentSheet/EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS", { working_assignment_sheet }, { root: true })
        }
        if (!isEmpty(data.reconciliation_items)) {
          let reconciliation_items = data.reconciliation_items
          commit("reconciliationItem/GET_RECONCILIATION_ITEMS_SUCCESS", { reconciliation_items }, { root: true })
        }
        break
      default:
        commit("RESET_RECONCILIATION_ERROR", data)
    }
    return response
  },
  async finalizeReconciliation({ commit, dispatch }, params) {
    commit("FINALIZE_RECONCILIATION_REQUEST")
    const response = await reconciliationService.finalizeReconciliation(params);
    const { status, data } = response
    switch (status) {
      case 200:
        if (data.success) {
          commit("FINALIZE_RECONCILIATION_SUCCESS")
          dispatch("alert/createAlertSuccess", "Reconciliation Finalization Started", { root: true })
        } else {
          commit("FINALIZE_RECONCILIATION_ERROR")
          dispatch('alert/createAlertErrors', data.errors, { root: true })
        }
        break
      default:
        commit("FINALIZE_RECONCILIATION_ERROR")
    }
    return response
  },
  async overrideReconciliation({ commit, dispatch }, params) {
    commit("OVERRIDE_RECONCILIATION_REQUEST");
    const res = await reconciliationService.overrideReconciliation(params);
    const { status, data } = res;
    switch (status) {
      case 200:
        data?.success ?
          (
            commit("OVERRIDE_RECONCILIATION_SUCCESS"),
            dispatch("alert/createAlertSuccess", "Reconciliation Override Started", { root: true })
          ) :
          commit("OVERRIDE_RECONCILIATION_ERROR");
        break;
      default:
        commit("OVERRIDE_RECONCILIATION_ERROR");
    }
  }
}

const mutations = {
  [CREATE_RECONCILIATION_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_RECONCILIATION_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [CREATE_RECONCILIATION_ERROR]: state => {
    state.loading = false
  },
  [DELETE_RECONCILIATION_REQUEST]: state => {

  },
  [DELETE_RECONCILIATION_SUCCESS]: state => {

  },
  [DELETE_RECONCILIATION_ERROR]: state => {

  },
  [RESET_RECONCILIATION_REQUEST]: state => {

  },
  [RESET_RECONCILIATION_SUCCESS]: state => {

  },
  [RESET_RECONCILIATION_ERROR]: state => {

  },

  [FINALIZE_RECONCILIATION_REQUEST]: state => {
    state.loading = true;
  },
  [FINALIZE_RECONCILIATION_SUCCESS]: state => {
    state.loading = false;
  },
  [FINALIZE_RECONCILIATION_ERROR]: state => {
    state.loading = false;
  },
  OVERRIDE_RECONCILIATION_REQUEST: state => {
    state.loading = true;
  },
  OVERRIDE_RECONCILIATION_SUCCESS: state => {
    state.loading = false;
  },
  OVERRIDE_RECONCILIATION_ERROR: state => {
    state.loading = false;
  }
}

export const reconciliation = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
