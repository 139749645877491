import {
    apiRequest,
    processInput
} from "@/utilities"

export const minorService = {
    async getMinors() {
        const getMinorsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/minors", getMinorsRequest)
    },
    async getMinor(uuid) {
        const getMinorRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/minors/${uuid}`, getMinorRequest)
    },
    async createMinor(params) {
        const createData = processInput(params)
        const createMinorRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/minors", createMinorRequest)
    },
    async editMinor(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editMinorRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/minors/${uuid}`, editMinorRequest)
    },
    async deleteMinor(uuid) {
        const deleteMinorRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/minors/${uuid}`, deleteMinorRequest)
    }
}