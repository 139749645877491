export const GET_COURSE_START_RECONCILIATIONS_REQUEST = "GET_COURSE_START_RECONCILIATIONS_REQUEST"
export const GET_COURSE_START_RECONCILIATIONS_SUCCESS = "GET_COURSE_START_RECONCILIATIONS_SUCCESS"
export const GET_COURSE_START_RECONCILIATIONS_ERROR = "GET_COURSE_START_RECONCILIATIONS_ERROR"
export const GET_COURSE_START_RECONCILIATION_REQUEST = "GET_COURSE_START_RECONCILIATION_REQUEST"
export const GET_COURSE_START_RECONCILIATION_SUCCESS = "GET_COURSE_START_RECONCILIATION_SUCCESS"
export const GET_COURSE_START_RECONCILIATION_ERROR = "GET_COURSE_START_RECONCILIATION_ERROR"
export const CREATE_COURSE_START_RECONCILIATION_REQUEST = "CREATE_COURSE_START_RECONCILIATION_REQUEST"
export const CREATE_COURSE_START_RECONCILIATION_SUCCESS = "CREATE_COURSE_START_RECONCILIATION_SUCCESS"
export const CREATE_COURSE_START_RECONCILIATION_ERROR = "CREATE_COURSE_START_RECONCILIATION_ERROR"
export const EDIT_COURSE_START_RECONCILIATION_REQUEST = "EDIT_COURSE_START_RECONCILIATION_REQUEST"
export const EDIT_COURSE_START_RECONCILIATION_SUCCESS = "EDIT_COURSE_START_RECONCILIATION_SUCCESS"
export const EDIT_COURSE_START_RECONCILIATION_ERROR = "EDIT_COURSE_START_RECONCILIATION_ERROR"
export const DELETE_COURSE_START_RECONCILIATION_REQUEST = "DELETE_COURSE_START_RECONCILIATION_REQUEST"
export const DELETE_COURSE_START_RECONCILIATION_SUCCESS = "DELETE_COURSE_START_RECONCILIATION_SUCCESS"
export const DELETE_COURSE_START_RECONCILIATION_ERROR = "DELETE_COURSE_START_RECONCILIATION_ERROR"