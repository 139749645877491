import {
    apiRequest,
    processInput
} from "@/utilities"

export const regionalAccreditorsService = {
    async getRegionalAccreditors() {
        const getRegionalAccreditorsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/regional-accreditors", getRegionalAccreditorsRequest)
    },
    async createRegionalAccreditor(params) {
        const createData = processInput(params);
        const postRegionalAccreditorRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/regional-accreditors", postRegionalAccreditorRequest)
    },
    async getRegionalAccreditor(uuid) {

        const getRegionalAccreditorRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/regional-accreditors/${uuid}`, getRegionalAccreditorRequest);
    },
    async editRegionalAccreditor(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateRegionalAccreditorRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/regional-accreditors/${uuid}`, updateRegionalAccreditorRequest);
    },
    async deleteRegionalAccreditor(uuid) {

        const deleteRegionalAccreditorRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/regional-accreditors/${uuid}`, deleteRegionalAccreditorRequest);
    }
}