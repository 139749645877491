export const GET_UNIVERSITY_REGIONAL_ACCREDITORS_REQUEST = "GET_UNIVERSITY_REGIONAL_ACCREDITORS_REQUEST"
export const GET_UNIVERSITY_REGIONAL_ACCREDITORS_SUCCESS = "GET_UNIVERSITY_REGIONAL_ACCREDITORS_SUCCESS"
export const GET_UNIVERSITY_REGIONAL_ACCREDITORS_ERROR = "GET_UNIVERSITY_REGIONAL_ACCREDITORS_ERROR"
export const GET_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST = "GET_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST"
export const GET_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS = "GET_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS"
export const GET_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR = "GET_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR" 
export const CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST = "CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST"
export const CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS = "CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS"
export const CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR = "CREATE_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR"
export const EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST = "EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST"
export const EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS = "EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS"
export const EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR = "EDIT_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR"
export const DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST = "DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_REQUEST"
export const DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS = "DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_SUCCESS"
export const DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR = "DELETE_UNIVERSITY_REGIONAL_ACCREDITOR_ERROR"