import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const iaApplicationService = {
  async getIAApplications(params) {
    let getParams = processQueryParams(params)
    const getIAApplicationsRequest = {
      method: "GET",
      params: getParams
    }
    return await apiRequest("/api/ia-applications", getIAApplicationsRequest)
  },
  async getIAApplication(uuid) {
    const getIAApplicationRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/ia-applications/${uuid}`, getIAApplicationRequest)
  },
  async createIAApplication(params) {
    const createData = processInput(params)
    const createIAApplicationRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/ia-applications", createIAApplicationRequest)
  },
  async editIAApplication(data) {
    const { uuid } = data
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editIAApplicationRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/ia-applications/${uuid}`, editIAApplicationRequest)
  },
  async deleteIAApplication(uuid) {
    const deleteIAApplicationRequest = {
      method: "DELETE"
    }
    return await apiRequest(`/api/ia-applications/${uuid}`, deleteIAApplicationRequest)
  },
  async generateIAApplication(params) {
    const generateData = processInput(params)
    const generateIAApplicationRequest = {
      method: "POST",
      data: generateData
    }
    return await apiRequest("/api/ia-application-generate", generateIAApplicationRequest)
  }
}