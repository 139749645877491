import {
    apiRequest,
    processInput,
    processQueryParams
  } from "@/utilities"
  
  const moduleUrl = "faculty-assignment-evaluations";

  export const facultyEvaluationService = {
    async getFacultyEvaluations(params) {
  
      const getFacultyEvaluationsRequest = {
        method: "GET",
        params: processQueryParams(params)
      }
  
      return await apiRequest(`/api/${moduleUrl}`, getFacultyEvaluationsRequest)
    },
    async getFacultyEvaluationStatus(uuid) {
      let getParams = processQueryParams({
        uuid
      })
      const getFacultyEvaluationStatusRequest = {
          method: "GET",
          params: getParams
      }
      return await apiRequest(`/api/faculty-assignment-evaluation-status`, getFacultyEvaluationStatusRequest)
    },
    async createFacultyEvaluation(params) {
  
      const createData = processInput(params)
      const postFacultyEvaluationRequest = {
        method: "POST",
        data: createData
      }
      return await apiRequest(`/api/faculty-assignment-evaluation-post`, postFacultyEvaluationRequest)
    },
    async getFacultyEvaluation(uuid) {
  
      const getFacultyEvaluationRequest = {
        method: "GET"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, getFacultyEvaluationRequest);
    },
    async editFacultyEvaluation(data, uuid) {
  
      let editData = processInput(data)
      editData.append("_method", "PATCH")
  
      const updateFacultyEvaluationRequest = {
        method: "POST",
        data: editData
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, updateFacultyEvaluationRequest);
    },
    async deleteFacultyEvaluation(uuid) {
  
      const deleteFacultyEvaluationRequest = {
        method: "DELETE"
      }
  
      return await apiRequest(`/api/${moduleUrl}/${uuid}`, deleteFacultyEvaluationRequest);
    }
  }
  