import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const sharedQueueService = {
  async getSharedQueues() {
    const getSharedQueuesRequest = {
      method: "GET"
    }
    return await apiRequest("/api/shared-queues", getSharedQueuesRequest)
  },
  async getSharedQueue(uuid) {
    const getSharedQueueRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/shared-queues/${uuid}`, getSharedQueueRequest)
  },
  async createSharedQueue(params) {
    const createData = processInput(params)
    const createSharedQueueRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/shared-queues", createSharedQueueRequest)
  },
  async editSharedQueue(data, uuid) {
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editSharedQueueRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/shared-queues/${uuid}`, editSharedQueueRequest)
  },
  async deleteSharedQueue(params) {
    const { uuid } = params
    delete params.uuid
    let deleteData = processQueryParams(params)
    const deleteSharedQueueRequest = {
      method: "DELETE",
      query: deleteData
    }
    return await apiRequest(`/api/shared-queues/${uuid}`, deleteSharedQueueRequest)
  }
}
