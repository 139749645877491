import {
	GET_RECONCILIATION_ITEMS_REQUEST,
	GET_RECONCILIATION_ITEMS_SUCCESS,
	GET_RECONCILIATION_ITEMS_ERROR,
	GET_RECONCILIATION_ITEM_REQUEST,
	GET_RECONCILIATION_ITEM_SUCCESS,
	GET_RECONCILIATION_ITEM_ERROR,
	CREATE_RECONCILIATION_ITEM_REQUEST,
	CREATE_RECONCILIATION_ITEM_SUCCESS,
	CREATE_RECONCILIATION_ITEM_ERROR,
	EDIT_RECONCILIATION_ITEM_REQUEST,
	EDIT_RECONCILIATION_ITEM_SUCCESS,
	EDIT_RECONCILIATION_ITEM_ERROR,
	DELETE_RECONCILIATION_ITEM_REQUEST,
	DELETE_RECONCILIATION_ITEM_SUCCESS,
	DELETE_RECONCILIATION_ITEM_ERROR
} from "@/actions"

import {
	reconciliationItemService
} from "@/services"
import { unpackWAS, unpackCoachAssignmentReconciliation } from "@/modules"

const state = {
	reconciliationItems: [],
	reconciliationItem: {},
	wasReconLoads: [],
	loading: false,
	loadedOnce: false
}

import { addNewFilter, isEmpty } from "@/utilities"

const getters = {
	allReconciliationItems: state => state.reconciliationItems,

	reconciliationItemsByRecKey: state => recKey => state.reconciliationItems.filter(i => i.key == recKey),

	facultyCoachReviewItems: state => (reconItemsUuids) => state.reconciliationItems.filter(i => reconItemsUuids.includes(i.uuid)),

	reconciliationItemFind: state => uuid => state.reconciliationItems.find(i => i.uuid == uuid)
}

const actions = {
	//This function get ALL ReconciliationItems by 'reconciliation_key' && ALL CoachAssignmentReconciliation by 'reconciliation_key' && WorkingAssignmentSheetReconciliation by 'reconciliation_key'
	async getReconciliationItems({ commit }, params) {
		const { reconciliationKey } = params
		if (params?.wasReconLoad && state.wasReconLoads.includes(reconciliationKey)) {
			return {
				success: true
			}
		}
		commit("GET_RECONCILIATION_ITEMS_REQUEST")
		const response = await reconciliationItemService.getReconciliationItems(params)
		let { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					data.force_update = true
					data.reconciliationKey = reconciliationKey ?? null
					commit("GET_RECONCILIATION_ITEMS_SUCCESS", data)

					if (!isEmpty(data?.students)) {
						let students = data.students;
						commit("students/ADD_STUDENTS", { students }, { root: true });
					}
				}
				break
			default:
				commit("GET_RECONCILIATION_ITEMS_ERROR", data)
		}
		return response;
	},
	async getReconciliationItem({ commit }, params) {
		commit("GET_RECONCILIATION_ITEM_REQUEST")
		const response = await reconciliationItemService.getReconciliationItem(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_RECONCILIATION_ITEM_SUCCESS", data)
				break
			default:
				commit("GET_RECONCILIATION_ITEM_ERROR", data)
				break
		}
		return response
	},
	async createReconciliationItem({ commit }, params) {
		commit("CREATE_RECONCILIATION_ITEM_REQUEST")
		const response = await reconciliationItemService.createReconciliationItem(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_RECONCILIATION_ITEM_SUCCESS", data) : commit("CREATE_RECONCILIATION_ITEM_ERROR", data)
				break
			default:
				commit("CREATE_RECONCILIATION_ITEM_ERROR", data)
		}
		return response
	},
	async editReconciliationItem({ commit }, params) {
		commit("EDIT_RECONCILIATION_ITEM_REQUEST")
		// const uuid = typeof params.uuid != 'string' ? params.uuid.find((i,ix) => ix === 0) : params.uuid
		const { uuid } = params
		const response = await reconciliationItemService.editReconciliationItem(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_RECONCILIATION_ITEM_SUCCESS", data) : commit("EDIT_RECONCILIATION_ITEM_ERROR", data)

				// if(!isEmpty(data.reconciliation_item.was)){
				//     let working_assignment_sheet = unpackWAS(data.reconciliation_item.was, commit)
				//     commit("workingAssignmentSheet/EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS", {working_assignment_sheet}, {root: true})
				// }

				break
			default:
				commit("EDIT_RECONCILIATION_ITEM_ERROR", data)
		}
		return response
	},
	async deleteReconciliationItem({ commit }, params) {
		commit("DELETE_RECONCILIATION_ITEM_REQUEST")
		const response = await reconciliationItemService.deleteReconciliationItem(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_RECONCILIATION_ITEM_SUCCESS", data)
				if (!isEmpty(data.working_assignment_sheet)) {
					let working_assignment_sheet = unpackWAS(data.working_assignment_sheet, commit)
					commit("workingAssignmentSheet/EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS", { working_assignment_sheet }, { root: true })
				}
				if (!isEmpty(data.reconciliation_item.was)) {
					let working_assignment_sheet = unpackWAS(data.reconciliation_item.was, commit)
					commit("workingAssignmentSheet/EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS", { working_assignment_sheet }, { root: true })
				}
				break
			default:
				commit("DELETE_RECONCILIATION_ITEM_ERROR", data)
		}
		return response
	},
	removeReconciliationItemsByKey({ commit }, recnKey) {
		commit('removeReconciliationItemsByKey', recnKey);
	}
}

const mutations = {
	[GET_RECONCILIATION_ITEMS_REQUEST]: state => {
		state.loading = true
	},
	[GET_RECONCILIATION_ITEMS_SUCCESS]: (state, data) => {
		const { reconciliationKey } = data
		if (!isEmpty(reconciliationKey)) {
			state.wasReconLoads.push(reconciliationKey)
		}
		const reconciliationItems = data.reconciliation_items
		state.reconciliationItems = addNewFilter(state.reconciliationItems, reconciliationItems)
		// state.reconciliationItems = reconciliationItems //Need to fix
		state.loading = false
		state.loadedOnce = true
	},
	[GET_RECONCILIATION_ITEMS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_RECONCILIATION_ITEM_REQUEST]: state => {
		state.loading = true
	},
	[GET_RECONCILIATION_ITEM_SUCCESS]: (state, data) => {
		const { reconciliation_item } = data
		state.reconciliationItem = reconciliation_item
		state.reconciliationItems = addNewFilter(state.reconciliationItems, [reconciliation_item])
		state.loading = false
	},
	[GET_RECONCILIATION_ITEM_ERROR]: (state) => {
		state.loading = false
	},

	[CREATE_RECONCILIATION_ITEM_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_RECONCILIATION_ITEM_SUCCESS]: (state, data) => {
		let { reconciliation_item } = data
		state.reconciliationItems = addNewFilter(state.reconciliationItems, [reconciliation_item])
		state.loading = false
	},
	[CREATE_RECONCILIATION_ITEM_ERROR]: state => {
		state.loading = false
	},

	[EDIT_RECONCILIATION_ITEM_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_RECONCILIATION_ITEM_SUCCESS]: (state, data) => {
		const { reconciliation_item } = data
		if(!isEmpty(reconciliation_item)){
			state.reconciliationItems = addNewFilter(state.reconciliationItems, [reconciliation_item]);
		}
		state.loading = false
	},
	[EDIT_RECONCILIATION_ITEM_ERROR]: state => {
		state.loading = false
	},

	[DELETE_RECONCILIATION_ITEM_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_RECONCILIATION_ITEM_SUCCESS]: (state, data) => {
		state.loading = false
		if (!isEmpty(data.uuid)) {
			state.reconciliationItems = state.reconciliationItems.filter(p => p.uuid !== data.uuid)
		}
	},
	[DELETE_RECONCILIATION_ITEM_ERROR]: (state) => {
		state.loading = false
	},
	removeReconciliationItemsByKey: (state, recnKey) => {
		state.reconciliationItems = state.reconciliationItems.filter(i => i.key != recnKey)
	}
}

export const reconciliationItem = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
