import {
  GET_PROGRAMS_REQUEST,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_ERROR,
  GET_PROGRAM_REQUEST,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_ERROR,
  CREATE_PROGRAM_REQUEST,
  CREATE_PROGRAM_SUCCESS,
  CREATE_PROGRAM_ERROR,
  EDIT_PROGRAM_REQUEST,
  EDIT_PROGRAM_SUCCESS,
  EDIT_PROGRAM_ERROR,
  DELETE_PROGRAM_REQUEST,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_ERROR
} from "@/actions"

import {
  programService
} from "@/services"

import { addNewFilter } from "@/utilities"

const state = {
  programs: [],
  program: {},
  loading: false,
  loadedOnce: false,
}

const getters = {
  programsLoading: state => state.loading,
  programsLoadedOnce: state => state.loadedOnce,
  programs: state => state.programs,
  programByUuid: state => uuid => state.programs.find(program => program.uuid == uuid),
  programSelectOptions: state => state.programs.map(program => ({ value: program.uuid, key: program.uuid, label: program.label })).sort((a, b) => a.label.localeCompare(b.label)),

}

const actions = {
  async getPrograms({ commit }) {
    commit("GET_PROGRAMS_REQUEST")
    const response = await programService.getPrograms();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_PROGRAMS_SUCCESS", data)
        break
      default:
        commit("GET_PROGRAMS_ERROR", data)
    }
    return response
  },
  async getProgram({ commit }, params) {
    commit("GET_PROGRAM_REQUEST")
    const response = await programService.getProgram(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_PROGRAM_SUCCESS", data)
        break
      default:
        commit("GET_PROGRAM_ERROR", data)
        break
    }
    return response
  },
  async createProgram({ commit }, params) {
    commit("CREATE_PROGRAM_REQUEST")
    const response = await programService.createProgram(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("CREATE_PROGRAM_SUCCESS", data)
        break
      default:
        commit("CREATE_PROGRAM_ERROR", data)
    }
    return response
  },
  async editProgram({ commit }, params) {
    commit("EDIT_PROGRAM_REQUEST")
    const response = await programService.editProgram(params, params.uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_PROGRAM_SUCCESS", data)
        break
      default:
        commit("EDIT_PROGRAM_ERROR", data)
    }
    return response
  },
  async deleteProgram({ commit }, params) {
    commit("DELETE_PROGRAM_REQUEST")
    const response = await programService.deleteProgram(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_PROGRAM_SUCCESS", data)
        break
      default:
        commit("DELETE_PROGRAM_DELETE", data)
    }
    return response
  }
}

const mutations = {
  [GET_PROGRAMS_REQUEST]: state => {
    state.loading = true
  },
  [GET_PROGRAMS_SUCCESS]: (state, data) => {
    let programs = [...data.programs]
    state.programs = addNewFilter(state.programs, programs)
    state.loading = false
    state.loadedOnce = true
  },
  [GET_PROGRAMS_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_PROGRAM_REQUEST]: state => {
    state.loading = true
  },
  [GET_PROGRAM_SUCCESS]: (state, data) => {
    let mdata = { ...data.program }
    state.program = mdata
    state.loading = false
  },
  [GET_PROGRAM_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_PROGRAM_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_PROGRAM_SUCCESS]: (state, data) => {
    state.loading = false
    state.programs.push(data.program)
  },
  [CREATE_PROGRAM_ERROR]: state => {
    state.loading = false
  },

  [EDIT_PROGRAM_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_PROGRAM_SUCCESS]: (state, data) => {
    if (data.success) {
      state.programs = state.programs.filter(f => f.uuid != data.program.uuid)
      state.programs.push(data.program)
    }
    state.loading = false
  },
  [EDIT_PROGRAM_ERROR]: state => {
    state.loading = false
  },

  [DELETE_PROGRAM_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_PROGRAM_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [DELETE_PROGRAM_ERROR]: (state, data) => {
    state.loading = false
  }
}

export const program = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
