export const GET_WORKING_ASSIGNMENTS_REQUEST = "GET_WORKING_ASSIGNMENTS_REQUEST"
export const GET_WORKING_ASSIGNMENTS_SUCCESS = "GET_WORKING_ASSIGNMENTS_SUCCESS"
export const GET_WORKING_ASSIGNMENTS_ERROR = "GET_WORKING_ASSIGNMENTS_ERROR"
export const GET_WORKING_ASSIGNMENT_REQUEST = "GET_WORKING_ASSIGNMENT_REQUEST"
export const GET_WORKING_ASSIGNMENT_SUCCESS = "GET_WORKING_ASSIGNMENT_SUCCESS"
export const GET_WORKING_ASSIGNMENT_ERROR = "GET_WORKING_ASSIGNMENT_ERROR" 
export const CREATE_WORKING_ASSIGNMENT_REQUEST = "CREATE_WORKING_ASSIGNMENT_REQUEST"
export const CREATE_WORKING_ASSIGNMENT_SUCCESS = "CREATE_WORKING_ASSIGNMENT_SUCCESS"
export const CREATE_WORKING_ASSIGNMENT_ERROR = "CREATE_WORKING_ASSIGNMENT_ERROR"
export const EDIT_WORKING_ASSIGNMENT_REQUEST = "EDIT_WORKING_ASSIGNMENT_REQUEST"
export const EDIT_WORKING_ASSIGNMENT_SUCCESS = "EDIT_WORKING_ASSIGNMENT_SUCCESS"
export const EDIT_WORKING_ASSIGNMENT_ERROR = "EDIT_WORKING_ASSIGNMENT_ERROR"
export const DELETE_WORKING_ASSIGNMENT_REQUEST = "DELETE_WORKING_ASSIGNMENT_REQUEST"
export const DELETE_WORKING_ASSIGNMENT_SUCCESS = "DELETE_WORKING_ASSIGNMENT_SUCCESS"
export const DELETE_WORKING_ASSIGNMENT_ERROR = "DELETE_WORKING_ASSIGNMENT_ERROR"
export const BROADCAST_DELETE_WORKING_ASSIGNMENT_SUCCESS = "BROADCAST_DELETE_WORKING_ASSIGNMENT_SUCCESS"