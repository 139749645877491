import {
  GET_PACKAGES_REQUEST,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_ERROR,
  CREATE_SCREENING_REQUEST,
  CREATE_SCREENING_SUCCESS,
  CREATE_SCREENING_ERROR,
  GET_CANDIDATE_REQUEST,
  GET_CANDIDATE_SUCCESS,
  GET_CANDIDATE_ERROR
} from "@/actions"

import {
  backgroundCheckService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
  backgroundChecks: [],
  packages: [],
  backgroundCheck: {},
  loading: false,
  loadedOnce: false
}

const getters = {
  hasPackages: state => (!isEmpty(state.packages)),
  loading: state => state.loading,
  availablePackageOptions: state => state.packages.map(pack => {
    return { value: pack.id, label: pack.title }
  }),
  coachBackgroundChecks: state => uuid => state.backgroundChecks.filter(bc => bc.coaches_uuid == uuid)
}

const actions = {
  async getPackages({ commit, dispatch }, params) {
    commit("GET_PACKAGES_REQUEST")
    const response = await backgroundCheckService.getPackages()
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("GET_PACKAGES_SUCCESS", data) : commit("GET_PACKAGES_ERROR", data)
        break
      default:
        commit("GET_PACKAGES_ERROR", data)
        dispatch('alert/createAlertErrors', "Error getting packages", { root: true })
    }
    return response
  },
  async createScreening({ commit, dispatch }, params) {
    commit("CREATE_SCREENING_REQUEST")
    const response = await backgroundCheckService.createScreening(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_SCREENING_SUCCESS", data) : commit("CREATE_SCREENING_ERROR", data)
        data.success ? dispatch('alert/createAlertSuccess', "Coach Screening Created.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
        break
      default:
        commit("CREATE_SCREENING_ERROR", data)
        dispatch('alert/createAlertErrors', "Error creating screening", { root: true })
    }
    return response
  },
  async syncCandidate({ commit, dispatch }, params) {
    commit("SYNC_CANDIDATE_REQUEST");
    const response = await backgroundCheckService.syncCandidate(params);
    const { status, data } = response
    switch (status) {
      case 200:
        data?.success ? commit("SYNC_CANDIDATE_SUCCESS", data) : commit("SYNC_CANDIDATE_ERROR", data);
        data?.success ? dispatch('alert/createAlertSuccess', "Background check data synced with Coach", { root: true }) : dispatch('alert/createAlertErrors', "Error syncing Coach", { root: true });
        break
      default:
        commit("SYNC_CANDIDATE_ERROR", data)
        dispatch('alert/createAlertErrors', "Error syncing Coach", { root: true })
    }
    return response;
  },
  // async getCandidate({ commit, dispatch }, params) {
  //   commit("GET_CANDIDATE_REQUEST")
  //   const response = await backgroundCheckService.getCandidate(params)
  //   const { status, data } = response
  //   switch (status) {
  //     case 200:
  //       data.success ? commit("GET_CANDIDATE_SUCCESS", data) : commit("GET_CANDIDATE_ERROR", data)
  //       break
  //     default:
  //       commit("GET_CANDIDATE_ERROR", data)
  //       dispatch('alert/createAlertErrors', "Error getting coach", { root: true })
  //   }
  //   return response
  // }
}

const mutations = {
  [GET_PACKAGES_REQUEST]: state => {
    state.loading = true
  },
  [GET_PACKAGES_SUCCESS]: (state, data) => {
    if (!isEmpty(data.packages)) {
      const packages = [...data.packages]
      state.packages = addNewFilter(state.packages, packages)
    }
    state.loading = false
  },
  [GET_PACKAGES_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_SCREENING_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_SCREENING_SUCCESS]: (state, data) => {
    state.loading = false;
  },
  [CREATE_SCREENING_ERROR]: (state, data) => {
    state.loading = false
  },

  [GET_CANDIDATE_REQUEST]: state => {
    state.loading = true
  },
  [GET_CANDIDATE_SUCCESS]: (state, data) => {
    if (!isEmpty(data.candidate)) {
      state.backgroundCheck = data.candidate
    }

    state.loading = false
  },
  [GET_CANDIDATE_ERROR]: (state, data) => {
    state.loading = false
  },

  SYNC_CANDIDATE_REQUEST: () => {

  },
  SYNC_CANDIDATE_SUCCESS: (state, data) => {

  },
  SYNC_CANDIDATE_ERROR: () => {

  },

  GET_COACH_BACKGROUND_CHECKS_SUCCESS: (state, data) => {
    const background_checks = data.background_checks;
    state.backgroundChecks = addNewFilter(state.backgroundChecks, background_checks);
  },

  CREATE_COACH_BACKGROUND_CHECK_SUCCESS: (state, data) => {
    console.log(data)
    const { background_check } = data;
    state.backgroundChecks = addNewFilter(state.backgroundChecks, [background_check]);
  },

  EDIT_COACH_BACKGROUND_CHECK_SUCCESS: (state, data) => {
    const { background_check } = data;
    state.backgroundChecks = addNewFilter(state.backgroundChecks, [background_check]);
  } 
}

export const backgroundCheck = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
