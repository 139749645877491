import {
    apiRequest,
    processInput
} from "@/utilities"

export const emailTypeService = {
    async getEmailTypes() {
        const getEmailTypesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/email-types", getEmailTypesRequest)
    },
    async getEmailType(uuid) {
        const getEmailTypeRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/email-types/${uuid}`, getEmailTypeRequest)
    },
    async createEmailType(params) {
        const createData = processInput(params)
        const createEmailTypeRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/email-types", createEmailTypeRequest)
    },
    async editEmailType(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editEmailTypeRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/email-types/${uuid}`, editEmailTypeRequest)
    },
    async deleteEmailType(uuid) {
        const deleteEmailTypeRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/email-types/${uuid}`, deleteEmailTypeRequest)
    }
}