export const coachApplication = {
    path: "/coach-application",
    component: () => import("@/layouts/CoachApplication.vue"),
    meta: {
        authRequired: true,
        allowedRoles: [
            'coach'
        ],
        label: "Coach Application",
    },
    children: [
        {
            path: "/",
            name: "coach application home",
            meta: {
                label: "Application Home",
            },
            component: () => import("@/pages/coach/application/CoachApplicationHome.vue"),
        },
        {
            path: "profile",
            name: "coach application profile",
            meta: {
                label: "Profile",
                updateLastVisited: true
            },
            component: () => import("@/pages/coach/application/CoachProfile.vue")
        },
        {
            path: "areas-of-qualification",
            name: "coach application areas-of-qualification",
            meta: {
                label: "Areas of Qualification",
                updateLastVisited: true
            },
            component: () => import("@/pages/coach/application/CoachAreaQualification.vue")
        },
        {
            path: "education",
            name: "coach application education-specialties",
            meta: {
                label: "Education & Specialties",
                updateLastVisited: true
            },
            component: () => import("@/pages/coach/application/CoachEducation.vue")
        },
        {
            path: "license-certification",
            name: "coach application license-certification",
            meta: {
                label: "Licensure & Certifications",
                updateLastVisited: true
            },
            component: () => import("@/pages/coach/application/CoachCertification.vue")
        },
        {
            path: "employment-experience",
            name: "coach application employment-experience",
            meta: {
                label: "Employment Experience"
            },
            component: () => import("@/pages/coach/application/CoachEmploymentExperiencePage.vue")
        },
        {
            path: "education-experience",
            name: "coach application education-experience",
            meta: {
                label: "Education Experience"
            },
            component: () => import("@/pages/coach/application/CoachEducationExperience.vue")
        },
        {
            path: "references",
            name: "coach application references",
            meta: {
                label: "References"
            },
            component: () => import("@/pages/coach/application/CoachReference.vue")
        },
        {
            path: "acknowledgement",
            name: "coach application acknowledgement",
            meta: {
                label: "Acknowledgement"
            },
            component: () => import("@/pages/coach/application/CoachAcknowledgement.vue")
        },
        {
            path: "document-upload",
            name: "coach application document-upload",
            meta: {
                label: "Upload Documents"
            },
            component: () => import("@/pages/coach/application/CoachDocumentUpload.vue")
        },
        {
            path: "submission",
            name: "coach application submission",
            meta: {
                label: "Submit Application"
            },
            component: () => import("@/pages/coach/application/CoachSubmission.vue")
        },
        {
            path: "screening-interview",
            name: "coach application screening interview",
            meta: {
                label: "Screen Session"
            },
            component: () => import("@/pages/coach/application/CoachScreeningInterview.vue")
        },
        // Disabled 5-24-2022 -BE
        // Background Check no
        // {
        //     path: "background-check",
        //     name: "coach application background check",
        //     meta: {
        //         label: "Background Check"
        //     },
        //     component: () => import("@/pages/coach/application/CoachBackgroundCheck.vue")
        // },
        // {
        //     path: "contracting-stage",
        //     name: "coach application contracting-stage",
        //     meta: {
        //         label: "Contracting Stage"
        //     },
        //     component: () => import("@/pages/coach/application/CoachContractingStage.vue")
        // }
    ]
}