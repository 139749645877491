export const GET_PAYMENT_SCHEDULES_REQUEST = "GET_PAYMENT_SCHEDULES_REQUEST"
export const GET_PAYMENT_SCHEDULES_SUCCESS = "GET_PAYMENT_SCHEDULES_SUCCESS"
export const GET_PAYMENT_SCHEDULES_ERROR = "GET_PAYMENT_SCHEDULES_ERROR"
export const GET_PAYMENT_SCHEDULE_REQUEST = "GET_PAYMENT_SCHEDULE_REQUEST"
export const GET_PAYMENT_SCHEDULE_SUCCESS = "GET_PAYMENT_SCHEDULE_SUCCESS"
export const GET_PAYMENT_SCHEDULE_ERROR = "GET_PAYMENT_SCHEDULE_ERROR"
export const CREATE_PAYMENT_SCHEDULE_REQUEST = "CREATE_PAYMENT_SCHEDULE_REQUEST"
export const CREATE_PAYMENT_SCHEDULE_SUCCESS = "CREATE_PAYMENT_SCHEDULE_SUCCESS"
export const CREATE_PAYMENT_SCHEDULE_ERROR = "CREATE_PAYMENT_SCHEDULE_ERROR"
export const BROADCAST_CREATE_PAYMENT_SCHEDULE_SUCCESS = "BROADCAST_CREATE_PAYMENT_SCHEDULE_SUCCESS"
export const EDIT_PAYMENT_SCHEDULE_REQUEST = "EDIT_PAYMENT_SCHEDULE_REQUEST"
export const EDIT_PAYMENT_SCHEDULE_SUCCESS = "EDIT_PAYMENT_SCHEDULE_SUCCESS"
export const EDIT_PAYMENT_SCHEDULE_ERROR = "EDIT_PAYMENT_SCHEDULE_ERROR"
export const BROADCAST_EDIT_PAYMENT_SCHEDULE_SUCCESS = "BROADCAST_EDIT_PAYMENT_SCHEDULE_SUCCESS"
export const DELETE_PAYMENT_SCHEDULE_REQUEST = "DELETE_PAYMENT_SCHEDULE_REQUEST"
export const DELETE_PAYMENT_SCHEDULE_SUCCESS = "DELETE_PAYMENT_SCHEDULE_SUCCESS"
export const DELETE_PAYMENT_SCHEDULE_ERROR = "DELETE_PAYMENT_SCHEDULE_ERROR"
export const BROADCAST_DELETE_PAYMENT_SCHEDULE_SUCCESS = "BROADCAST_DELETE_PAYMENT_SCHEDULE_SUCCESS"