import {
  GET_FACULTY_SOURCE_ROLES_REQUEST,
  GET_FACULTY_SOURCE_ROLES_SUCCESS,
  GET_FACULTY_SOURCE_ROLES_ERROR,
  GET_FACULTY_SOURCE_ROLE_REQUEST,
  GET_FACULTY_SOURCE_ROLE_SUCCESS,
  GET_FACULTY_SOURCE_ROLE_ERROR,
  CREATE_FACULTY_SOURCE_ROLE_REQUEST,
  CREATE_FACULTY_SOURCE_ROLE_SUCCESS,
  CREATE_FACULTY_SOURCE_ROLE_ERROR,
  EDIT_FACULTY_SOURCE_ROLE_REQUEST,
  EDIT_FACULTY_SOURCE_ROLE_SUCCESS,
  EDIT_FACULTY_SOURCE_ROLE_ERROR,
  DELETE_FACULTY_SOURCE_ROLE_REQUEST,
  DELETE_FACULTY_SOURCE_ROLE_SUCCESS,
  BROADCAST_DELETE_FACULTY_SOURCE_ROLE_SUCCESS,
  DELETE_FACULTY_SOURCE_ROLE_ERROR,
  SEND_RESUME_FACULTY_SOURCE_ROLE_REQUEST,
  SEND_RESUME_FACULTY_SOURCE_ROLE_SUCCESS,
  SEND_RESUME_FACULTY_SOURCE_ROLE_ERROR,
} from "@/actions"

import {
  unpackFS
} from "@/modules"

import {
  facultySourceRolesService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities";

const state = {
  error: null,
  loading: false,
  loadedOnce: false,
  facultySourceRoleLoadedOnce: false,
  facultySourceRoles: [],
  facultySource: {}
}

const getters = {
  facultysLoading: state => state.loading,
  facultySourceRoleLoadedOnce: state => state.facultySourceRoleLoadedOnce,
  facultySourceRoles: state => state.facultySourceRoles,
  facultySourceRolesByUfsUuid: state => ufsUuid => state.facultySourceRoles.filter(i => {
    if (typeof ufsUuid === 'string') {
      return i.university_faculty_source_uuid == ufsUuid
    }
    return ufsUuid.some(u => u == i.university_faculty_source_uuid)
  }).sort((b, a) => new Date(b.created_at) - new Date(a.created_at)),
  facultySourceRolesByCAdUuid: state => cadUuid => state.facultySourceRoles.filter(i => {
    if (typeof cadUuid === 'string') {
      return i.contract_addendum_uuid == cadUuid
    }
    return cadUuid.some(u => u == i.contract_addendum_uuid)
  }).sort((b, a) => new Date(b.created_at) - new Date(a.created_at)),
  facultySourceRoleByUuid: state => uuid => state.facultySourceRoles.find(i => i.uuid == uuid),
}

const actions = {
  async getFacultySourceRoleRoles({ commit }) {
    commit("GET_FACULTY_SOURCE_ROLES_REQUEST")
    const response = await facultySourceRolesService.getFacultySourceRoleRoles();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_FACULTY_SOURCE_ROLES_SUCCESS", data)
        break
      default:
        commit("GET_FACULTY_SOURCE_ROLES_ERROR", data)
    }
    return response
  },

  async getFacultySourceRole({ commit }, params) {
    commit("GET_FACULTY_SOURCE_ROLE_REQUEST")
    const response = await facultySourceRolesService.getFacultySourceRole(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_FACULTY_SOURCE_ROLE_SUCCESS", data)
        break
      default:
        commit("GET_FACULTY_SOURCE_ROLE_ERROR", data)
        break
    }
    return response
  },
  async createFacultySourceRole({ commit, dispatch }, params) {
    commit("CREATE_FACULTY_SOURCE_ROLE_REQUEST")
    const response = await facultySourceRolesService.createFacultySourceRole(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_FACULTY_SOURCE_ROLE_SUCCESS", data) : commit("CREATE_FACULTY_SOURCE_ROLE_ERROR", data)
        break
      default:
        commit("CREATE_FACULTY_SOURCE_ROLE_ERROR", data)
    }
    return response
  },
  async editFacultySourceRole({ commit }, params) {
    commit("EDIT_FACULTY_SOURCE_ROLE_REQUEST")
    const response = await facultySourceRolesService.editFacultySourceRole(params, params.uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_FACULTY_SOURCE_ROLE_SUCCESS", data)
        break
      default:
        commit("EDIT_FACULTY_SOURCE_ROLE_ERROR", data)
    }
    return response
  },
  async deleteFacultySourceRole({ commit }, params) {
    commit("DELETE_FACULTY_SOURCE_ROLE_REQUEST")
    const uuid = params.uuid
    const response = await facultySourceRolesService.deleteFacultySourceRole(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit(DELETE_FACULTY_SOURCE_ROLE_SUCCESS, uuid) : commit("DELETE_FACULTY_SOURCE_ROLE_ERROR", data)
        break
      default:
        commit("DELETE_FACULTY_SOURCE_ROLE_ERROR", data)
    }
    return response
  },
}

const mutations = {
  [GET_FACULTY_SOURCE_ROLES_REQUEST]: state => {
    state.loading = true
  },
  [GET_FACULTY_SOURCE_ROLES_SUCCESS]: (state, data) => {
    state.facultySourceRoles = addNewFilter(state.facultySourceRoles, data.faculty_source_roles)
    state.facultySourceRoleLoadedOnce = true
    state.loading = false
    state.loadedOnce = true
  },
  [GET_FACULTY_SOURCE_ROLES_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_FACULTY_SOURCE_ROLE_REQUEST]: state => {
    state.loading = true
  },
  [GET_FACULTY_SOURCE_ROLE_SUCCESS]: (state, data) => {
    let { faculty_source_role } = data
    state.facultySource = faculty_source_role
    state.loading = false
  },
  [GET_FACULTY_SOURCE_ROLE_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_FACULTY_SOURCE_ROLE_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_FACULTY_SOURCE_ROLE_SUCCESS]: (state, data) => {
    let { faculty_source_role } = data
    state.facultySourceRoles = addNewFilter(state.facultySourceRoles, [faculty_source_role])
    state.loading = false
  },
  [CREATE_FACULTY_SOURCE_ROLE_ERROR]: state => {
    state.loading = false
  },

  [EDIT_FACULTY_SOURCE_ROLE_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_FACULTY_SOURCE_ROLE_SUCCESS]: (state, data) => {
    state.facultySourceRoles = state.facultySourceRoles.filter(i => i.uuid != data.faculty_source_role.uuid)
    state.facultySourceRoles.push(data.faculty_source_role)
    state.loading = false
  },
  [EDIT_FACULTY_SOURCE_ROLE_ERROR]: state => {
    state.loading = false
  },

  [DELETE_FACULTY_SOURCE_ROLE_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_FACULTY_SOURCE_ROLE_SUCCESS]: (state, uuid) => {
    state.facultySourceRoles = state.facultySourceRoles.filter(i => i.uuid != uuid)
    state.loading = false
  },
  [BROADCAST_DELETE_FACULTY_SOURCE_ROLE_SUCCESS]: (state, uuid) => {
    state.facultySourceRoles = state.facultySourceRoles.filter(i => i.uuid != uuid)
    state.loading = false
  },
  [DELETE_FACULTY_SOURCE_ROLE_ERROR]: (state, data) => {
    state.loading = false
  },
  [SEND_RESUME_FACULTY_SOURCE_ROLE_REQUEST]: state => {
    state.loading = true
  },
  [SEND_RESUME_FACULTY_SOURCE_ROLE_SUCCESS]: (state, data) => {
    let { faculty_source_role } = data
    state.facultySourceRoles = addNewFilter(state.facultySourceRoles, [faculty_source_role])
    state.loading = false
  },
  [SEND_RESUME_FACULTY_SOURCE_ROLE_ERROR]: state => {
    state.loading = false
  },
}

export const facultySourceRole = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}




export function unpackFSR(fs, commit) {
  if (!isEmpty(fs.faculty_sources)) {
    let faculty_sources = fs.faculty_sources.map(i => unpackFS(i, commit))
    commit('facultySource/GET_FACULTY_SOURCES_SUCCESS', { faculty_sources }, { root: true })
    delete fs.faculty_sources
  }
  if (!isEmpty(fs.comments)) {
    commit("comments/GET_COMMENTS_SUCCESS", { comments: fs.comments }, { root: true })
    delete fs.comments
  }
  return fs
}
