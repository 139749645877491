import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const adminPaymentService = {
  async getAdminPayments(params) {
    let getParams = processQueryParams(params)
    const getAdminPaymentsRequest = {
      method: "GET",
      params: getParams
    }
    return await apiRequest("/api/admin-payments", getAdminPaymentsRequest)
  },
  async getAdminPayment(uuid) {
    const getAdminPaymentRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/admin-payments/${uuid}`, getAdminPaymentRequest)
  },
  async createAdminPayment(params) {
    const createData = processInput(params)
    const createAdminPaymentRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/admin-payments", createAdminPaymentRequest)
  },
  async editAdminPayment(data) {
    const { uuid } = data
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editAdminPaymentRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/admin-payments/${uuid}`, editAdminPaymentRequest)
  },
  async deleteAdminPayment(uuid) {
    const deleteAdminPaymentRequest = {
      method: "DELETE"
    }
    return await apiRequest(`/api/admin-payments/${uuid}`, deleteAdminPaymentRequest)
  },
}