import {
	GET_LEAD_EVALUATIONS_REQUEST,
	GET_LEAD_EVALUATIONS_SUCCESS,
	GET_LEAD_EVALUATIONS_ERROR,
	GET_LEAD_EVALUATION_REQUEST,
	GET_LEAD_EVALUATION_SUCCESS,
	GET_LEAD_EVALUATION_ERROR,
	CREATE_LEAD_EVALUATION_REQUEST,
	CREATE_LEAD_EVALUATION_SUCCESS,
	CREATE_LEAD_EVALUATION_ERROR,
	EDIT_LEAD_EVALUATION_REQUEST,
	EDIT_LEAD_EVALUATION_SUCCESS,
	EDIT_LEAD_EVALUATION_ERROR,
	DELETE_LEAD_EVALUATION_REQUEST,
	DELETE_LEAD_EVALUATION_SUCCESS,
	DELETE_LEAD_EVALUATION_ERROR,
} from "@/actions"

import {
	leadEvaluationService
} from "@/services"

import { addNewFilter, generateKey, isEmpty } from "@/utilities"

const state = {
	leadEvaluations: [],
	leadEvaluation: {},
	loading: false,
	loadedOnce: false,
	reviewOpts: [
		{label: 'Select one', value: null, disabled: true},
		{label: '10', value: 10},
		{label: '9', value: 9},
		{label: '8', value: 8},
		{label: '7', value: 7},
		{label: '6', value: 6},
		{label: '5', value: 5},
		{label: '4', value: 4},
		{label: '3', value: 3},
		{label: '2', value: 2},
		{label: '1', value: 1},
	],
	evaluationQuestion: {
		professionalism_communication: "Professionalism and manner in communication?",
		response_to_issues: "Rapid response and timely resolution to issues",
		essential_communication: "Ability to provide all essential information and details in communications",
		coach_imrovement_feedback: "Comments, suggestions, or area(s) for improvement",
		adherence_policies_guidelines: "Adherence to IC policies and guidelines",
	}
}

const getters = {
	leadEvaluationsLoading: state => state.loading,
	leadEvaluationsLoadedOnce: state => state.loadedOnce,
	evaluationReviewOpts: state => state.reviewOpts,
	evaluationQuestion: state => state.evaluationQuestion,
	leadAssignmentEvaluations: state => state.leadEvaluations,
	leadEvaluationsByUser: state => uuid => state.leadEvaluations.filter(c => c.user_uuid == uuid),
}

const actions = {
	async getLeadEvaluations({ state, commit }, params) {
		commit("GET_LEAD_EVALUATIONS_REQUEST");
		const response = await leadEvaluationService.getLeadEvaluations(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if(!params?.skipStore){
					commit("GET_LEAD_EVALUATIONS_SUCCESS", data);
				}
				break
			default:
				commit("GET_LEAD_EVALUATIONS_ERROR", data)
		}
		return response
	},
	async getLeadEvaluation({ commit, state }, params) {
		commit("GET_LEAD_EVALUATION_REQUEST")
		const response = await leadEvaluationService.getLeadEvaluation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_LEAD_EVALUATION_SUCCESS", data)
				break
			default:
				commit("GET_LEAD_EVALUATION_ERROR", data)
				break
		}
		return response
	},
	async createLeadEvaluation({ commit, dispatch }, params) {
		commit("CREATE_LEAD_EVALUATION_REQUEST")
		const response = await leadEvaluationService.createLeadEvaluation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_LEAD_EVALUATION_SUCCESS", data) : commit("CREATE_LEAD_EVALUATION_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Evaluation Submitted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("CREATE_LEAD_EVALUATION_ERROR", data)
		}
		return response
	},
	async editLeadEvaluation({ commit, dispatch }, params) {
		commit("EDIT_LEAD_EVALUATION_REQUEST")
		const response = await leadEvaluationService.editLeadEvaluation(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data?.success ? commit("EDIT_LEAD_EVALUATION_SUCCESS", data) : null;
				data?.success ? dispatch('alert/createAlertSuccess', "Evaluation updated!", { root: true }) : null;
				break
			default:
				commit("EDIT_LEAD_EVALUATION_ERROR", data)
		}
		return response
	},
	async deleteLeadEvaluation({ commit, dispatch }, uuid) {
		commit("DELETE_LEAD_EVALUATION_REQUEST")
		const response = await leadEvaluationService.deleteLeadEvaluation(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_LEAD_EVALUATION_SUCCESS", { uuid }) : commit("DELETE_LEAD_EVALUATION_ERROR")
				data.success ? dispatch('alert/createAlertSuccess', "Evaluation deleted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("DELETE_LEAD_EVALUATION_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_LEAD_EVALUATIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_LEAD_EVALUATIONS_SUCCESS]: (state, data) => {
		state.leadEvaluations = addNewFilter(state.leadEvaluations, data.lead_evaluations)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_LEAD_EVALUATIONS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_LEAD_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[GET_LEAD_EVALUATION_SUCCESS]: (state, data) => {
		const { lead_evaluation }  = data
		state.leadEvaluations = addNewFilter(state.leadEvaluations, [lead_evaluation])
		state.loading = false
	},
	[GET_LEAD_EVALUATION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_LEAD_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_LEAD_EVALUATION_SUCCESS]: (state, data) => {
		const { lead_evaluation } = data;
		state.leadEvaluations = addNewFilter(state.leadEvaluations, [lead_evaluation])
		state.loading = false
	},
	[CREATE_LEAD_EVALUATION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_LEAD_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_LEAD_EVALUATION_SUCCESS]: (state, data) => {
		const { lead_evaluation } = data;
		state.leadEvaluations = addNewFilter(state.leadEvaluations, [lead_evaluation])
		state.loading = false;
	},
	[EDIT_LEAD_EVALUATION_ERROR]: state => {
		state.loading = false
	},

	[DELETE_LEAD_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_LEAD_EVALUATION_SUCCESS]: (state, data) => {
		const { uuid } = data;
		state.leadEvaluations = state.leadEvaluations.filter(c => c.uuid !== uuid)
		state.loading = false
	},
	[DELETE_LEAD_EVALUATION_ERROR]: (state) => {
		state.loading = false
	},
}

export const leadEvaluation = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
