export const GET_NURSE_LICENSES_REQUEST = "GET_NURSE_LICENSES_REQUEST"
export const GET_NURSE_LICENSES_SUCCESS = "GET_NURSE_LICENSES_SUCCESS"
export const GET_NURSE_LICENSES_ERROR = "GET_NURSE_LICENSES_ERROR"
export const GET_NURSE_LICENSE_REQUEST = "GET_NURSE_LICENSE_REQUEST"
export const GET_NURSE_LICENSE_SUCCESS = "GET_NURSE_LICENSE_SUCCESS"
export const GET_NURSE_LICENSE_ERROR = "GET_NURSE_LICENSE_ERROR"
export const CREATE_NURSE_LICENSE_REQUEST = "CREATE_NURSE_LICENSE_REQUEST"
export const CREATE_NURSE_LICENSE_SUCCESS = "CREATE_NURSE_LICENSE_SUCCESS"
export const CREATE_NURSE_LICENSE_ERROR = "CREATE_NURSE_LICENSE_ERROR"
export const EDIT_NURSE_LICENSE_REQUEST = "EDIT_NURSE_LICENSE_REQUEST"
export const EDIT_NURSE_LICENSE_SUCCESS = "EDIT_NURSE_LICENSE_SUCCESS"
export const EDIT_NURSE_LICENSE_ERROR = "EDIT_NURSE_LICENSE_ERROR"
export const DELETE_NURSE_LICENSE_REQUEST = "DELETE_NURSE_LICENSE_REQUEST"
export const DELETE_NURSE_LICENSE_SUCCESS = "DELETE_NURSE_LICENSE_SUCCESS"
export const DELETE_NURSE_LICENSE_ERROR = "DELETE_NURSE_LICENSE_ERROR"