import {
	GET_CERTIFICATIONS_REQUEST,
	GET_CERTIFICATIONS_SUCCESS,
	GET_CERTIFICATIONS_ERROR,
	GET_CERTIFICATION_REQUEST,
	GET_CERTIFICATION_SUCCESS,
	GET_CERTIFICATION_ERROR,
	CREATE_CERTIFICATION_REQUEST,
	CREATE_CERTIFICATION_SUCCESS,
	CREATE_CERTIFICATION_ERROR,
	EDIT_CERTIFICATION_REQUEST,
	EDIT_CERTIFICATION_SUCCESS,
	EDIT_CERTIFICATION_ERROR,
	DELETE_CERTIFICATION_REQUEST,
	DELETE_CERTIFICATION_SUCCESS,
	DELETE_CERTIFICATION_ERROR
} from "@/actions"

import {
	certificationService
} from "@/services"

import _, { map } from 'underscore'
import { isEmpty, addNewFilter } from '@/utilities'

const state = {
	certifications: [],
	certification: {},
	loading: false,
	loadedOnce: false
}

const getters = {
	certsLoading: state => state.loading,
	certsLoadedOnce: state => state.loadedOnce,
	certifications: state => state.certifications,
	certificationByUuid: state => uuid => state.certifications.find(i => i.uuid == uuid),
	certficiationSelectOptions: state => state.certifications.map(c => {
		let meta = Object.keys(c?.meta ?? {}).reduce((obj, key) => {
			let newDataAttrs = Object.keys(c.meta[key]).reduce((o, k) => {
				let dataKey = `data-${key.replaceAll('_', '-')}-uuid`;
				if (!(dataKey in o)) {
					o[dataKey] = []
				}
				o[dataKey].push(k)
				return o;
			}, {})
			return {
				...newDataAttrs,
				...obj
			};
		}, {})
		return {
			value: c.uuid,
			label: c.name,
			attrs: {
				'data-abbreviation': c.abbreviation,
				...meta
			}
		}
	}).sort((a, b) => a.label.localeCompare(b.label)),
}

const actions = {
	async getCertifications({ commit }) {
		commit("GET_CERTIFICATIONS_REQUEST")
		const response = await certificationService.getCertifications()
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_CERTIFICATIONS_SUCCESS", data)
				break
			default:
				commit("GET_CERTIFICATIONS_ERROR", data)
		}
		return response
	},
	async getCertification({ commit }, params) {
		commit("GET_CERTIFICATION_REQUEST")
		const response = await certificationService.getCertification(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_CERTIFICATION_SUCCESS", data)
				break
			default:
				commit("GET_CERTIFICATION_ERROR", data)
				break
		}
		return response
	},
	async createCertification({ commit, dispatch }, params) {
		commit("CREATE_CERTIFICATION_REQUEST")
		const response = await certificationService.createCertification(params)
		const { status, data } = response
		switch (status) {
      case 200:
        data?.success ? commit("CREATE_CERTIFICATION_SUCCESS", data) : null;
        data.success ? dispatch('alert/createAlertSuccess', "Certification created.", { root: true }) : null;
        data?.error ? dispatch('alert/createAlertErrors', !isEmpty(data) ? decodeLaravelValidationErrors(data.errors) : "Oops. Something went wrong.", { root: true }) : null;
        break;
			default:
				commit("CREATE_CERTIFICATION_ERROR", data)
		}
		return response
	},
	async editCertification({ commit, dispatch }, params) {
		commit("EDIT_CERTIFICATION_REQUEST")
		const uuid = params.uuid
		const response = await certificationService.editCertification(params, uuid)
		const { status, data } = response
		switch (status) {
      case 200:
        data?.success ? commit("EDIT_CERTIFICATION_SUCCESS", data) : null;
        data?.success ? dispatch('alert/createAlertSuccess', "Certification updated.", { root: true }) : null;
        data?.error ? dispatch('alert/createAlertErrors', !isEmpty(data) ? decodeLaravelValidationErrors(data.errors) : "Oops. Something went wrong.", { root: true }) : null;
        break;
			default:
				commit("EDIT_CERTIFICATION_ERROR", data)
		}
		return response
	},
	async deleteCertification({ commit }, params) {
		commit("DELETE_CERTIFICATION_REQUEST")
		const response = await certificationService.deleteCertification(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_CERTIFICATION_SUCCESS", data)
				break
			default:
				commit("DELETE_CERTIFICATION_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_CERTIFICATIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_CERTIFICATIONS_SUCCESS]: (state, data) => {
		const certifications = [...data.certifications]
		state.loadedOnce = true
		state.certifications = certifications.map(i => {
			const meta = typeof i.meta === 'string' ? JSON.parse(i.meta) : i.meta
			return {
				...i,
				meta
			}
		})
		state.loading = false
	},
	[GET_CERTIFICATIONS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_CERTIFICATION_REQUEST]: state => {
		state.loading = true
	},
	[GET_CERTIFICATION_SUCCESS]: (state, data) => {
		const certification = { ...data.certification }
		state.certification = certification
		state.loading = false
	},
	[GET_CERTIFICATION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_CERTIFICATION_REQUEST]: state => {
		state.loading = true
	},
  [CREATE_CERTIFICATION_SUCCESS]: (state, data) => {
    const { certification } = data;
    state.certifications = addNewFilter(state.certifications, [certification]);
		state.loading = false
	},
	[CREATE_CERTIFICATION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_CERTIFICATION_REQUEST]: state => {
		state.loading = true
	},
  [EDIT_CERTIFICATION_SUCCESS]: (state, data) => {
    const { certification } = data;
    state.certifications = addNewFilter(state.certifications, [certification]);
    state.loading = false;
	},
	[EDIT_CERTIFICATION_ERROR]: state => {
		state.loading = false
	},

	[DELETE_CERTIFICATION_REQUEST]: state => {
		state.loading = true
	},
  [DELETE_CERTIFICATION_SUCCESS]: (state, data) => {
    const { certification } = data;
    state.certifications = state.certifications.filter(c => c?.uuid == certification?.uuid )
		state.loading = false
	},
	[DELETE_CERTIFICATION_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const certification = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
