import {
    apiRequest,
    processInput
} from "@/utilities"

export const programService = {
    async getPrograms() {

        const getProgramsRequest = {
            method: "GET"
        }

        return await apiRequest("/api/programs", getProgramsRequest)
    },
    async createProgram(params) {

        const createData = processInput(params);
        const postProgramRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/programs", postProgramRequest)
    },
    async getProgram(uuid) {

        const getProgramRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/programs/${uuid}`, getProgramRequest);
    },
    async editProgram(data, uuid) {
        
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateProgramRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/programs/${uuid}`, updateProgramRequest);
    },
    async deleteProgram(uuid) {

        const deleteProgramRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/programs/${uuid}`, deleteProgramRequest);
    }
}