export const GET_COURSE_MEETING_MINUTES_REQUEST = "GET_COURSE_MEETING_MINUTES_REQUEST"
export const GET_COURSE_MEETING_MINUTES_SUCCESS = "GET_COURSE_MEETING_MINUTES_SUCCESS"
export const GET_COURSE_MEETING_MINUTES_ERROR = "GET_COURSE_MEETING_MINUTES_ERROR"
export const GET_COURSE_MEETING_MINUTE_REQUEST = "GET_COURSE_MEETING_MINUTE_REQUEST"
export const GET_COURSE_MEETING_MINUTE_SUCCESS = "GET_COURSE_MEETING_MINUTE_SUCCESS"
export const GET_COURSE_MEETING_MINUTE_ERROR = "GET_COURSE_MEETING_MINUTE_ERROR"
export const CREATE_COURSE_MEETING_MINUTE_REQUEST = "CREATE_COURSE_MEETING_MINUTE_REQUEST"
export const CREATE_COURSE_MEETING_MINUTE_SUCCESS = "CREATE_COURSE_MEETING_MINUTE_SUCCESS"
export const CREATE_COURSE_MEETING_MINUTE_ERROR = "CREATE_COURSE_MEETING_MINUTE_ERROR"
export const EDIT_COURSE_MEETING_MINUTE_REQUEST = "EDIT_COURSE_MEETING_MINUTE_REQUEST"
export const EDIT_COURSE_MEETING_MINUTE_SUCCESS = "EDIT_COURSE_MEETING_MINUTE_SUCCESS"
export const EDIT_COURSE_MEETING_MINUTE_ERROR = "EDIT_COURSE_MEETING_MINUTE_ERROR"
export const DELETE_COURSE_MEETING_MINUTE_REQUEST = "DELETE_COURSE_MEETING_MINUTE_REQUEST"
export const DELETE_COURSE_MEETING_MINUTE_SUCCESS = "DELETE_COURSE_MEETING_MINUTE_SUCCESS"
export const DELETE_COURSE_MEETING_MINUTE_ERROR = "DELETE_COURSE_MEETING_MINUTE_ERROR"