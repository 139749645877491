<template>
  <CRow>
    <CCol col="auto">
      <div
        class="form-group"
        role="group"
      >
        <label for="yearFilter" class="font-weight-bold">
          Year:
        </label>
        <Datepicker 
          id="yearFilter" 
          name="year" 
          bootstrap-styling 
          input-class="form-control"
          :value="filterValue" 
          format="yyyy"
          minimum-view="year" 
          @input="setYear"
        >
        </Datepicker>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { format } from "date-fns";
export default {
  name: "ReportQueryFilterYear",
  components: {
    Datepicker
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({}),
  computed: {
    filterValue() {
      let year = this.value?.year ?? new Date().getFullYear()
      return new Date(year, 0, 1)
    }
  },
  methods: {
    setYear(v){
      const year = format(v, 'yyyy');
      this.$emit('setFilterYear', year)
    }
  }
}
</script>

<style></style>