import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const combinedWASGroupService = {
  async getCombinedGroups() {
    const getCombinedGroupsRequest = {
      method: "GET"
    }
    return await apiRequest("/api/combined-was-groups", getCombinedGroupsRequest)
  },
  async getCombinedGroup(uuid) {
    const getCombinedGroupRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/combined-was-groups/${uuid}`, getCombinedGroupRequest)
  },
  async createCombinedGroup(params) {
    const createData = processInput(params)
    const createCombinedGroupRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/combined-was-groups", createCombinedGroupRequest)
  },
  async editCombinedGroup(data) {
    const { uuid } = data;
    delete data.uuid;
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editCombinedGroupRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/combined-was-groups/${uuid}`, editCombinedGroupRequest)
  },
  async deleteCombinedGroup(params) {
    const { uuid } = params
    delete params.uuid
    let deleteData = processQueryParams(params)
    const deleteCombinedGroupRequest = {
      method: "DELETE",
      query: deleteData
    }
    return await apiRequest(`/api/combined-was-groups/${uuid}`, deleteCombinedGroupRequest)
  }
}
