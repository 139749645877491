import {
	apiRequest,
	processInput,
	processQueryParams
} from "@/utilities"

export const courseStartSectionService = {
	async getCourseStartSections() {
		const getCourseStartSectionsRequest = {
			method: "GET"
		}
		return await apiRequest("/api/course-start-sections", getCourseStartSectionsRequest)
	},
	async createCourseStartSection(params) {
		const createData = processInput(params)

		const postCourseStartSectionRequest = {
			method: "POST",
			data: createData
		}
		
		return await apiRequest("/api/course-start-sections", postCourseStartSectionRequest)
	},
	async getCourseStartSection(uuid) {

		const getCourseStartSectionRequest = {
			method: "GET"
		}

		return await apiRequest(`/api/course-start-sections/${uuid}`, getCourseStartSectionRequest);
	},
	async editCourseStartSection(data, uuid) {
		let editData = processInput(data)
		editData.append("_method", "PATCH")

		const updateCourseStartSectionRequest = {
			method: "POST",
			data: editData
		}

		return await apiRequest(`/api/course-start-sections/${uuid}`, updateCourseStartSectionRequest);
	},
	async deleteCourseStartSection(data) {
		let params = processQueryParams(data)

		const deleteCourseStartSectionRequest = {
			method: "DELETE",
			params
		}

		return await apiRequest(`/api/course-start-sections/${data.uuid}`, deleteCourseStartSectionRequest);
	}
}