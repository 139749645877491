import {
    apiRequest,
    processInput
} from "@/utilities"

export const areaOfQualificationService = {
    async getAreaOfQualifications() {
        const getAreaOfQualificationsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/area-of-qualifications", getAreaOfQualificationsRequest)
    },
    async getAreaOfQualification(uuid) {
        const getAreaOfQualificationRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/area-of-qualifications/${uuid}`, getAreaOfQualificationRequest)
    },
    async createAreaOfQualification(params) {
        const createData = processInput(params)
        const createAreaOfQualificationRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/area-of-qualifications", createAreaOfQualificationRequest)
    },
    async editAreaOfQualification(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editAreaOfQualificationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/area-of-qualifications/${uuid}`, editAreaOfQualificationRequest)
    },
    async deleteAreaOfQualification(uuid) {
        const deleteAreaOfQualificationRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/area-of-qualifications/${uuid}`, deleteAreaOfQualificationRequest)
    }
}