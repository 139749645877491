import { isEmpty, addNewFilter } from "@/utilities";

const state = {
    loading: true,
    loadedOnce: false,
    coachWithOverStudents: [],
    coachWithOverStudentsPreRecon: [],
    caListRosterDue: [],
    nhdCoachUuids: [],
    pdCoachUuids: [],
    nhdCaUuids: [],
    pdCaUuids: [],
    caAssignedDates: {},
}

const getters = {
    isLoaded: state => state.loadedOnce,
    isLoading: state => state.loading,

    coachWithOverStudents: state => state.coachWithOverStudents,
    coachWithOverStudentsPreRecon: state => state.coachWithOverStudentsPreRecon,
    caListRosterDue: state => state.caListRosterDue,
    nhdCoachUuids: state => state.nhdCoachUuids,
    pdCoachUuids: state => state.pdCoachUuids,
    nhdCaUuids: state => state.nhdCaUuids,
    pdCaUuids: state => state.pdCaUuids,
    caAssignedDates: state => state.caAssignedDates,
}

const actions = {
    async removeFromCoachWithOverStudents({ commit }, params) {
        commit("REMOVE_DATA", params)
    }
}

const mutations = {
    GET_COACH_WITH_OVER_STUDENTS_SUCCESS: (state, data) => {
        state.coachWithOverStudents = data.coach_with_over_students
        state.loading = false
        state.loadedOnce = false
    },
    GET_COACH_WITH_OVER_STUDENTS_PRE_RECON_SUCCESS: (state, data) => {
        state.coachWithOverStudentsPreRecon = data.coach_with_over_students_pre_recon
        state.loading = false
        state.loadedOnce = false
    },
    GET_CA_LIST_ROSTER_DUE_SUCCESS: (state, data) => {
        state.caListRosterDue = addNewFilter(state.caListRosterDue, data.ca_list_roster_due)
        state.loading = false
        state.loadedOnce = false
    },
    REMOVE_DATA: (state, data) => {
        const {uuid} = data
        if(!isEmpty(uuid)){
            state.coachWithOverStudents = state.coachWithOverStudents.filter(co => co.uuid != uuid)
        }
    },
    updateNhdAndPdCas: (state, data) => {
        const coach_assignments = data.coach_assignments
        const missingDocsCas = coach_assignments.filter(i => i?.missing_req_docs == 1)
        if(!isEmpty(missingDocsCas)){
            const nhdCas = missingDocsCas.filter(i => i?.missing_doc_type == 'nhd')
            if(!isEmpty(nhdCas)){
                const nhdCoachUuids = nhdCas.map(ca => ca.coaches_uuid)
                state.nhdCoachUuids = addNewFilter(state.nhdCoachUuids, nhdCoachUuids)

                const nhdCaUuids = nhdCas.map(ca => ca.uuid)
                state.nhdCaUuids = addNewFilter(state.nhdCoachUuids, nhdCaUuids)

                nhdCas.forEach(i => {
                    if(!isEmpty(i.assigned_date)){
                        state.caAssignedDates[i.uuid] = i.assigned_date
                    }
                })
            }

            const pdCas = missingDocsCas.filter(i => i?.missing_doc_type == 'pd')
            if(!isEmpty(pdCas)){
                const pdCoachUuids = pdCas.map(ca => ca.coaches_uuid)
                state.pdCoachUuids = addNewFilter(state.pdCoachUuids, pdCoachUuids)

                const pdCaUuids = pdCas.map(ca => ca.uuid)
                state.pdCaUuids = addNewFilter(state.pdCaUuids, pdCaUuids)

                pdCas.forEach(i => {
                    if(!isEmpty(i.assigned_date)){
                        state.caAssignedDates[i.uuid] = i.assigned_date
                    }
                })
            }
        }
    },
}

export const miscellaneous = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
