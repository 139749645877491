import {
    apiRequest,
    processInput
} from "@/utilities"

export const referralSourceService = {
    async getReferralSources() {
        const getReferralSourcesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/referral-sources", getReferralSourcesRequest)
    },
    async getReferralSource(uuid) {
        const getReferralSourceRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/referral-sources/${uuid}`, getReferralSourceRequest)
    },
    async createReferralSource(params) {
        const createData = processInput(params)
        const createReferralSourceRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/referral-sources", createReferralSourceRequest)
    },
    async editReferralSource(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editReferralSourceRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/referral-sources/${uuid}`, editReferralSourceRequest)
    },
    async deleteReferralSource(uuid) {
        const deleteReferralSourceRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/referral-sources/${uuid}`, deleteReferralSourceRequest)
    }
}