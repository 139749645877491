export const GET_UNIVERSITY_OPMS_REQUEST = "GET_UNIVERSITY_OPMS_REQUEST"
export const GET_UNIVERSITY_OPMS_SUCCESS = "GET_UNIVERSITY_OPMS_SUCCESS"
export const GET_UNIVERSITY_OPMS_ERROR = "GET_UNIVERSITY_OPMS_ERROR"
export const GET_UNIVERSITY_OPM_REQUEST = "GET_UNIVERSITY_OPM_REQUEST"
export const GET_UNIVERSITY_OPM_SUCCESS = "GET_UNIVERSITY_OPM_SUCCESS"
export const GET_UNIVERSITY_OPM_ERROR = "GET_UNIVERSITY_OPM_ERROR"
export const CREATE_UNIVERSITY_OPM_REQUEST = "CREATE_UNIVERSITY_OPM_REQUEST"
export const CREATE_UNIVERSITY_OPM_SUCCESS = "CREATE_UNIVERSITY_OPM_SUCCESS"
export const CREATE_UNIVERSITY_OPM_ERROR = "CREATE_UNIVERSITY_OPM_ERROR"
export const EDIT_UNIVERSITY_OPM_REQUEST = "EDIT_UNIVERSITY_OPM_REQUEST"
export const EDIT_UNIVERSITY_OPM_SUCCESS = "EDIT_UNIVERSITY_OPM_SUCCESS"
export const EDIT_UNIVERSITY_OPM_ERROR = "EDIT_UNIVERSITY_OPM_ERROR"
export const DELETE_UNIVERSITY_OPM_REQUEST = "DELETE_UNIVERSITY_OPM_REQUEST"
export const DELETE_UNIVERSITY_OPM_SUCCESS = "DELETE_UNIVERSITY_OPM_SUCCESS"
export const DELETE_UNIVERSITY_OPM_ERROR = "DELETE_UNIVERSITY_OPM_ERROR"