import {
	GET_COACH_NURSE_LICENSES_REQUEST,
	GET_COACH_NURSE_LICENSES_SUCCESS,
	GET_COACH_NURSE_LICENSES_ERROR,
	GET_COACH_NURSE_LICENSE_REQUEST,
	GET_COACH_NURSE_LICENSE_SUCCESS,
	GET_COACH_NURSE_LICENSE_ERROR,
	CREATE_COACH_NURSE_LICENSE_REQUEST,
	CREATE_COACH_NURSE_LICENSE_SUCCESS,
	CREATE_COACH_NURSE_LICENSE_ERROR,
	EDIT_COACH_NURSE_LICENSE_REQUEST,
	EDIT_COACH_NURSE_LICENSE_SUCCESS,
	EDIT_COACH_NURSE_LICENSE_ERROR,
	DELETE_COACH_NURSE_LICENSE_REQUEST,
	DELETE_COACH_NURSE_LICENSE_SUCCESS,
	DELETE_COACH_NURSE_LICENSE_ERROR
} from "@/actions"

import {
	coachNurseLicenseService
} from "@/services"

import { isEmpty, addNewFilter, decodeLaravelValidationErrors } from "@/utilities"

const state = {
	coachNurseLicenses: [],
	coachNurseLicense: {},
	loading: false,
	loadedOnce: false,
	nursingLicenseDetails: {
		mainOpts: [
			{
				value: "RN",
				text: "RN"
			},
			{
				value: "APRN",
				text: "APRN"
			}
		],
		rnCompactOpts: [
			{
				value: 1,
				label: 'Compact'
			},
			{
				value: 2,
				label: 'Non Compact'
			}
		]
	}
}

const getters = {
	clnLoadedOnce: state => state.loadedOnce,
	nursingLicenseDetails: state => { return state.nursingLicenseDetails },
	coachNurseLicenses: (state, getters, rootState, rootGetters) => state.coachNurseLicenses.filter(license => license.coaches_uuid == rootGetters['coaches/coach']['uuid'] && isEmpty(license.deleted_at)).sort((a, b) => new Date(b.expiration_date) - new Date(a.expiration_date)),
	allCoachNurseLicenses: (state, getters, rootState, rootGetters) => state.coachNurseLicenses.filter(license => license.coaches_uuid == rootGetters['coaches/coach']['uuid']).sort((a, b) => new Date(b.expiration_date) - new Date(a.expiration_date)),
	findcoachNurseLicense: state => uuid => state.coachNurseLicenses.find(license => license.uuid == uuid),
}

const actions = {
	async getCoachNurseLicenses({ commit }, params) {
		commit("GET_COACH_NURSE_LICENSES_REQUEST")
		const response = await coachNurseLicenseService.getCoachNurseLicenses(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COACH_NURSE_LICENSES_SUCCESS", data)
				break
			default:
				commit("GET_COACH_NURSE_LICENSES_ERROR", data)
		}
		return response
	},
	async getCoachNurseLicense({ commit }, params) {
		commit("GET_COACH_NURSE_LICENSE_REQUEST")
		const response = await coachNurseLicenseService.getCoachNurseLicense(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("GET_COACH_NURSE_LICENSE_SUCCESS", data) : commit("GET_COACH_NURSE_LICENSE_ERROR", data)
				break
			default:
				commit("GET_COACH_NURSE_LICENSE_ERROR", data)
				break
		}
		return response
	},
	async createCoachNurseLicense({ commit, dispatch }, params) {
		commit("CREATE_COACH_NURSE_LICENSE_REQUEST")
		const response = await coachNurseLicenseService.createCoachNurseLicense(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_COACH_NURSE_LICENSE_SUCCESS", data) : commit("CREATE_COACH_NURSE_LICENSE_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "License created!", { root: true }) : dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
				break
			default:
				commit("CREATE_COACH_NURSE_LICENSE_ERROR", data)
		}
		return response
	},
	async editCoachNurseLicense({ commit, dispatch }, params) {
		commit("EDIT_COACH_NURSE_LICENSE_REQUEST")
		const uuid = params.uuid
		const response = await coachNurseLicenseService.editCoachNurseLicense(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_COACH_NURSE_LICENSE_SUCCESS", data) : commit("EDIT_COACH_NURSE_LICENSE_ERROR", data);
				data.success ? dispatch('alert/createAlertSuccess', "License updated!", { root: true }) : dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true });
				break
			default:
				commit("EDIT_COACH_NURSE_LICENSE_ERROR", data)
		}
		return response
	},
	async deleteCoachNurseLicense({ commit, dispatch }, params) {
		commit("DELETE_COACH_NURSE_LICENSE_REQUEST")
		const response = await coachNurseLicenseService.deleteCoachNurseLicense(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_COACH_NURSE_LICENSE_SUCCESS", params) : null;
				data.success ? dispatch('alert/createAlertSuccess', "License deleted!", { root: true }) : null;
				if (data?.error) {
					if (data.errors.includes("No query results for model")) {
						commit("DELETE_COACH_NURSE_LICENSE_SUCCESS", params);
						dispatch('alert/createAlertSuccess', "License deleted!", { root: true });
					} else {
						dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
					}
				}
				break
			default:
				commit("DELETE_COACH_NURSE_LICENSE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_COACH_NURSE_LICENSES_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_NURSE_LICENSES_SUCCESS]: (state, data) => {
		const coachNurseLicenses = [...data.coach_nurse_licenses]
		state.coachNurseLicenses = addNewFilter(state.coachNurseLicenses, coachNurseLicenses)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_COACH_NURSE_LICENSES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_COACH_NURSE_LICENSE_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_NURSE_LICENSE_SUCCESS]: (state, data) => {
		const coachNurseLicense = { ...data.coach_nurse_license }
		state.coachNurseLicense = coachNurseLicense
		state.coachNurseLicenses = addNewFilter(state.coachNurseLicenses, [coachNurseLicense])
		state.loading = false
	},
	[GET_COACH_NURSE_LICENSE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COACH_NURSE_LICENSE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COACH_NURSE_LICENSE_SUCCESS]: (state, data) => {
		let coachNurseLicense = { ...data.coach_nurse_license }
		state.coachNurseLicenses = addNewFilter(state.coachNurseLicenses, [coachNurseLicense])
		state.loading = false
	},
	[CREATE_COACH_NURSE_LICENSE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COACH_NURSE_LICENSE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COACH_NURSE_LICENSE_SUCCESS]: (state, data) => {
		let coachNurseLicense = { ...data.coach_nurse_license }
		let licenses = state.coachNurseLicenses.map(license => {
			if (license.uuid == coachNurseLicense.uuid) return coachNurseLicense
			return license
		})
		state.coachNurseLicenses = licenses
		state.loading = false
	},
	[EDIT_COACH_NURSE_LICENSE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COACH_NURSE_LICENSE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COACH_NURSE_LICENSE_SUCCESS]: (state, uuid) => {
		state.coachNurseLicenses = state.coachNurseLicenses.filter(i => i.uuid !== uuid)
		state.loading = false
	},
	[DELETE_COACH_NURSE_LICENSE_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const coachNurseLicense = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}