import {
  apiRequest,
  processQueryParams
} from "@/utilities"

export const queryBuilderService = {
  async getTables(params) {
    const getTablesRequest = {
      method: "GET",
      params: processQueryParams(params)
    }
    return await apiRequest("/api/query-builder/tables", getTablesRequest);
  },
  async getSelectOptions(params) {
    const getSelectOptionsRequest = {
      method: "GET",
      params: processQueryParams(params)
    }
    return await apiRequest("/api/query-builder/select-options", getSelectOptionsRequest);
  },
  async queryDatabase(params) {
    const queryDatabaseRequest = {
      method: "GET",
      params: processQueryParams(params)
    }
    return await apiRequest("/api/query-database", queryDatabaseRequest)
  }
}