export const GET_ASSIGNMENT_PAYMENTS_REQUEST = "GET_ASSIGNMENT_PAYMENTS_REQUEST"
export const GET_ASSIGNMENT_PAYMENTS_SUCCESS = "GET_ASSIGNMENT_PAYMENTS_SUCCESS"
export const GET_ASSIGNMENT_PAYMENTS_ERROR = "GET_ASSIGNMENT_PAYMENTS_ERROR"
export const GET_ASSIGNMENT_PAYMENT_REQUEST = "GET_ASSIGNMENT_PAYMENT_REQUEST"
export const GET_ASSIGNMENT_PAYMENT_SUCCESS = "GET_ASSIGNMENT_PAYMENT_SUCCESS"
export const GET_ASSIGNMENT_PAYMENT_ERROR = "GET_ASSIGNMENT_PAYMENT_ERROR"
export const CREATE_ASSIGNMENT_PAYMENT_REQUEST = "CREATE_ASSIGNMENT_PAYMENT_REQUEST"
export const CREATE_ASSIGNMENT_PAYMENT_SUCCESS = "CREATE_ASSIGNMENT_PAYMENT_SUCCESS"
export const CREATE_ASSIGNMENT_PAYMENT_ERROR = "CREATE_ASSIGNMENT_PAYMENT_ERROR"
export const BROADCAST_CREATE_ASSIGNMENT_PAYMENT_SUCCESS = "BROADCAST_CREATE_ASSIGNMENT_PAYMENT_SUCCESS"
export const EDIT_ASSIGNMENT_PAYMENT_REQUEST = "EDIT_ASSIGNMENT_PAYMENT_REQUEST"
export const EDIT_ASSIGNMENT_PAYMENT_SUCCESS = "EDIT_ASSIGNMENT_PAYMENT_SUCCESS"
export const EDIT_ASSIGNMENT_PAYMENT_ERROR = "EDIT_ASSIGNMENT_PAYMENT_ERROR"
export const BROADCAST_EDIT_ASSIGNMENT_PAYMENT_SUCCESS = "BROADCAST_EDIT_ASSIGNMENT_PAYMENT_SUCCESS"
export const DELETE_ASSIGNMENT_PAYMENT_REQUEST = "DELETE_ASSIGNMENT_PAYMENT_REQUEST"
export const DELETE_ASSIGNMENT_PAYMENT_SUCCESS = "DELETE_ASSIGNMENT_PAYMENT_SUCCESS"
export const DELETE_ASSIGNMENT_PAYMENT_ERROR = "DELETE_ASSIGNMENT_PAYMENT_ERROR"
export const GENERATE_ASSIGNMENT_PAYMENT_REQUEST = "GENERATE_ASSIGNMENT_PAYMENT_REQUEST"
export const GENERATE_ASSIGNMENT_PAYMENT_SUCCESS = "GENERATE_ASSIGNMENT_PAYMENT_SUCCESS"
export const GENERATE_ASSIGNMENT_PAYMENT_ERROR = "GENERATE_ASSIGNMENT_PAYMENT_ERROR"
export const BROADCAST_DELETE_ASSIGNMENT_PAYMENT_SUCCESS = "BROADCAST_DELETE_ASSIGNMENT_PAYMENT_SUCCESS"
export const CLEAN_ASSIGNMENT_PAYMENT = "CLEAN_ASSIGNMENT_PAYMENT"