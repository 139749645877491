export const GET_WORKING_ASSIGNMENT_SHEETS_REQUEST = "GET_WORKING_ASSIGNMENT_SHEETS_REQUEST"
export const GET_WORKING_ASSIGNMENT_SHEETS_SUCCESS = "GET_WORKING_ASSIGNMENT_SHEETS_SUCCESS"
export const GET_WORKING_ASSIGNMENT_SHEETS_ERROR = "GET_WORKING_ASSIGNMENT_SHEETS_ERROR"
export const GET_WORKING_ASSIGNMENT_SHEET_REQUEST = "GET_WORKING_ASSIGNMENT_SHEET_REQUEST"
export const GET_WORKING_ASSIGNMENT_SHEET_SUCCESS = "GET_WORKING_ASSIGNMENT_SHEET_SUCCESS"
export const GET_WORKING_ASSIGNMENT_SHEET_ERROR = "GET_WORKING_ASSIGNMENT_SHEET_ERROR"
export const GET_WORKING_ASSIGNMENT_SHEETS_DATES_REQUEST = "GET_WORKING_ASSIGNMENT_SHEETS_DATES_REQUEST"
export const GET_WORKING_ASSIGNMENT_SHEETS_DATES_SUCCESS = "GET_WORKING_ASSIGNMENT_SHEETS_DATES_SUCCESS"
export const GET_WORKING_ASSIGNMENT_SHEETS_DATES_ERROR = "GET_WORKING_ASSIGNMENT_SHEETS_DATES_ERROR"
export const CREATE_WORKING_ASSIGNMENT_SHEET_REQUEST = "CREATE_WORKING_ASSIGNMENT_SHEET_REQUEST"
export const CREATE_WORKING_ASSIGNMENT_SHEET_SUCCESS = "CREATE_WORKING_ASSIGNMENT_SHEET_SUCCESS"
export const CREATE_WORKING_ASSIGNMENT_SHEET_ERROR = "CREATE_WORKING_ASSIGNMENT_SHEET_ERROR"
export const EDIT_WORKING_ASSIGNMENT_SHEET_REQUEST = "EDIT_WORKING_ASSIGNMENT_SHEET_REQUEST"
export const EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS = "EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS"
export const EDIT_WORKING_ASSIGNMENT_SHEET_ERROR = "EDIT_WORKING_ASSIGNMENT_SHEET_ERROR"
export const DELETE_WORKING_ASSIGNMENT_SHEET_REQUEST = "DELETE_WORKING_ASSIGNMENT_SHEET_REQUEST"
export const DELETE_WORKING_ASSIGNMENT_SHEET_SUCCESS = "DELETE_WORKING_ASSIGNMENT_SHEET_SUCCESS"
export const DELETE_WORKING_ASSIGNMENT_SHEET_ERROR = "DELETE_WORKING_ASSIGNMENT_SHEET_ERROR"
export const BROADCAST_CREATE_WASR_SUCCESS = "BROADCAST_CREATE_WASR_SUCCESS"
export const BROADCAST_EDIT_WASR_SUCCESS = "BROADCAST_EDIT_WASR_SUCCESS"
export const BROADCAST_DELETE_WASR_SUCCESS = "BROADCAST_DELETE_WASR_SUCCESS"
export const UPDATE_STATE = "UPDATE_STATE"

