import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const contractsService = {
    async getContracts(params) {
        const getQueryParams = processQueryParams(params)
        const getContractsRequest = {
            method: "GET",
            params: getQueryParams
        }
        return await apiRequest("/api/contracts", getContractsRequest)
    },
    async createContract(params) {
        const createData = processInput(params);
        const postContractRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/contracts", postContractRequest)
    },
    async renewContract(params) {
        const createData = processInput(params);
        const postContractRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/renew-contract", postContractRequest)
    },
    async getContract(uuid) {

        const getContractRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/contracts/${uuid}`, getContractRequest);
    },
    async editContract(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateContractRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/contracts/${uuid}`, updateContractRequest);
    },
    async deleteContract(uuid) {

        const deleteContractRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/contracts/${uuid}`, deleteContractRequest);
    }
}