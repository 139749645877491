export const reportsMetricRoutes = {
  path: "metric",
  name: "reports metric",
  meta: {
    authRequired: true,
    allowedRoles: [
      'super-admin'
    ],
    label: "General Reports",
  },
  component: () => import("@/pages/reports/general/ReportsGeneralIndex.vue"),
  children: [
    {
      path: "coaches-average-age-company",
      name: "report metric coaches-average-age-company",
      meta: {
        label: "Coach Average Age By University",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "coaches-average-age-university",
      name: "report metric coaches-average-age-university",
      meta: {
        label: "Coach Average Age By University",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "coaches-average-age-university-program",
      name: "report metric coaches-average-age-university-program",
      meta: {
        label: "Coach Average Age By University Program",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },

    {
      path: "location-coaches-company",
      name: "report metric location-coaches-company",
      meta: {
        label: "Location of Coaches for Company",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "location-coaches-university",
      name: "report metric location-coaches-university",
      meta: {
        label: "Location of Coaches by University",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "location-coaches-university-program",
      name: "report metric location-coaches-university-program",
      meta: {
        label: "Location of Coaches for Univeresity/Program",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },

    {
      path: "active-coaches-company",
      name: "report metric active-coaches-company",
      meta: {
        label: "Active Coaches",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },

    {
      path: "coaches-university-program",
      name: "report metric coaches-university-program",
      meta: {
        label: "Coach Count by University/Program",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },

    {
      path: "university-program-lead",
      name: "report metric university-program-lead",
      meta: {
        label: "University/Program with Lead",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },

    {
      path: "cc-university-program",
      name: "report metric cc-university-program",
      meta: {
        label: "Coordinating Coaches by University/Program",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },

    {
      path: "student-coach-ratio-university",
      name: "report metric student-coach-ratio-university",
      meta: {
        label: "Student/Coach Ratio by University",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "student-coach-ratio-university-program",
      name: "report metric student-coach-ratio-university-program",
      meta: {
        label: "Student/Coach Ratio by Program",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "student-coach-ratio-company",
      name: "report metric student-coach-ratio-company",
      meta: {
        label: "Student/Coach Ratio by Company",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "coaches-terminal-degrees",
      name: "report metric coaches-terminal-degrees",
      meta: {
        label: "Coaches with Terminal Degrees",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "coaches-masters-degrees",
      name: "report metric coaches-masters-degrees",
      meta: {
        label: "Coaches with Master Degree",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "coaches-contract-activation",
      name: "report metric coaches-contract-length",
      meta: {
        label: "Length of Contract/Activation",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "student-numbers-date-range",
      name: "report metric student-numbers-date-range",
      meta: {
        label: "Student Numbers by Date Range",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "coach-count-date-range",
      name: "report metric coach-count-date-range",
      meta: {
        label: "Coach Count by Date Range",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "course-count-date-range",
      name: "report metric course-count-date-range",
      meta: {
        label: "Course Counts by Date Range",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "coach-retention-year",
      name: "report metric coach-retention-year",
      meta: {
        label: "Coach Retention Year over Year",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "separated-coaches-university-program",
      name: "report metric separated-coaches-university-program",
      meta: {
        label: "Separated Coaches by University/Program",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "separated-coaches-year",
      name: "report metric separated-coaches-year",
      meta: {
        label: "Separated Coaches by Year",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "courses-coach-date-range",
      name: "report metric courses-coach-date-range",
      meta: {
        label: "Courses by Coach by Date Range",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "coach-count-start-date-range",
      name: "report metric coach-count-start-date-range",
      meta: {
        label: "Coach Count by Start for Date Range",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "course-count-start-date-range",
      name: "report metric course-count-start-date-range",
      meta: {
        label: "Course Count by Start for Date Range",
      },
      component: () => import("@/pages/reports/ReportDefaultOutput.vue"),
    },
    {
      path: "coaches-with-terminal-degrees",
      name: "report metric coaches-with-terminal-degrees",
      meta: {
        label: "Coaches with Terminal Degrees",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "coaches-length-of-contract",
      name: "report metric coaches-length-of-contract",
      meta: {
        label: "Coaches Length of Contract",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
  ]
};