import {
    GET_COMMENT_TYPES_REQUEST,
    GET_COMMENT_TYPES_SUCCESS,
    GET_COMMENT_TYPES_ERROR,
    GET_COMMENT_TYPE_REQUEST,
    GET_COMMENT_TYPE_SUCCESS,
    GET_COMMENT_TYPE_ERROR,
    CREATE_COMMENT_TYPE_REQUEST,
    CREATE_COMMENT_TYPE_SUCCESS,
    CREATE_COMMENT_TYPE_ERROR,
    EDIT_COMMENT_TYPE_REQUEST,
    EDIT_COMMENT_TYPE_SUCCESS,
    EDIT_COMMENT_TYPE_ERROR,
    DELETE_COMMENT_TYPE_REQUEST,
    DELETE_COMMENT_TYPE_SUCCESS,
    DELETE_COMMENT_TYPE_ERROR
} from "@/actions"

import {
    commentTypeService
} from "@/services"

const state = {
    commentTypes: [],
    commentType: {},
    loading: false
}

const getters = {
    commentTypes: state => state.commentTypes.sort((a, b) => a.type.localeCompare(b.type)),
    coachAppCommentType: state => state.commentTypes.find(commentType => commentType.slug == "application-notes-applicant"),
    commentTypeSelectOptions: state => state.commentTypes.map(ct => { return { value: ct.uuid, label: ct.label } }),
    commentTypeBySlug: state => slug => state.commentTypes.find(ct => ct.slug == slug)
}

const actions = {
    async getCommentTypes({ commit }) {
        commit("GET_COMMENT_TYPES_REQUEST")
        const response = await commentTypeService.getCommentTypes();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COMMENT_TYPES_SUCCESS", data)
                break
            default:
                commit("GET_COMMENT_TYPES_ERROR", data)
        }
        return response
    },
    async getCommentType({ commit }, params) {
        commit("GET_COMMENT_TYPE_REQUEST")
        const response = await commentTypeService.getCommentType(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COMMENT_TYPE_SUCCESS", data)
                break
            default:
                commit("GET_COMMENT_TYPE_ERROR", data)
                break
        }
        return response
    },
    async createCommentType({ commit, dispatch }, params) {
        commit("CREATE_COMMENT_TYPE_REQUEST")
        const response = await commentTypeService.createCommentType(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_COMMENT_TYPE_SUCCESS", data) : commit("CREATE_COMMENT_TYPE_ERROR", data)
                data.success ? dispatch('alert/createAlertSuccess', "New CommentType created.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
                break
            default:
                commit("CREATE_COMMENT_TYPE_ERROR", data)
        }
        return response
    },
    async editCommentType({ commit }, params, uuid) {
        commit("EDIT_COMMENT_TYPE_REQUEST")
        const response = await commentTypeService.editCommentType(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COMMENT_TYPE_SUCCESS", data)
                break
            default:
                commit("EDIT_COMMENT_TYPE_ERROR", data)
        }
        return response
    },
    async deleteCommentType({ commit }, params) {
        commit("DELETE_COMMENT_TYPE_REQUEST")
        const response = await commentTypeService.deleteCommentType(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COMMENT_TYPE_SUCCESS", data)
                break
            default:
                commit("DELETE_COMMENT_TYPE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COMMENT_TYPES_REQUEST]: state => {
        state.loading = true
    },
    [GET_COMMENT_TYPES_SUCCESS]: (state, data) => {
        const commentTypes = [...data.comment_types]
        state.commentTypes = commentTypes
        state.loading = false
    },
    [GET_COMMENT_TYPES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COMMENT_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [GET_COMMENT_TYPE_SUCCESS]: (state, data) => {
        const commentType = { ...data.comment_type }
        state.commentType = commentType
        state.loading = false
    },
    [GET_COMMENT_TYPE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COMMENT_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COMMENT_TYPE_SUCCESS]: (state, data) => {
        state.commentTypes.push({ ...data.comment_type })
        state.loading = false
    },
    [CREATE_COMMENT_TYPE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COMMENT_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COMMENT_TYPE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COMMENT_TYPE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COMMENT_TYPE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COMMENT_TYPE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_COMMENT_TYPE_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const commentType = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}