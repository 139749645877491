import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const settingsService = {
  async getAppModels() {
    const getAppModelsRequest = {
      method: "GET"
    }
    return await apiRequest("/api/settings/models", getAppModelsRequest)
  },

  async getBasicAuthData() {
    const Request = {
      method: "GET"
    }
    return await apiRequest(`/api/basic-auth-call`, Request);
  },


  async getBasicDashboardItemData() {
    const basicAdminRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/basic-dashboard-items`, basicAdminRequest);
  },

  async getOperationsData(params) {
    const getQueryParams = processQueryParams(params)
    const basicAdminRequest = {
      method: "GET",
      params: getQueryParams
    }
    return await apiRequest(`/api/basic-operations-data`, basicAdminRequest);
  },

  async getDashboardItem(params) {

    const dashboardItemRequest = {
      method: "GET",
      params: processQueryParams(params)
    };

    return await apiRequest(`/api/dashboard-item`, dashboardItemRequest);
  },

  async getBasicCoachData() {
    const basicCoachRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/basic-coach`, basicCoachRequest);
  },

  async getBasicCoachRequestData() {
    const basicCoachRequestData = {
      method: "GET"
    }
    return await apiRequest('/api/basic-coach-request', basicCoachRequestData)
  },

  async getBasicMeetingMnuteData() {
    const basicMeetingMnuteData = {
      method: "GET"
    }
    return await apiRequest('/api/basic-meeting-minutes', basicMeetingMnuteData)
  },

  async getBasicUniversityPartnershipData() {
    const basicUniversityPartnershipData = {
      method: "GET"
    }
    return await apiRequest('/api/basic-university-partnership', basicUniversityPartnershipData)
  },

  async optimizeApp() {
    const postData = {
      method: "POST"
    }
    return await apiRequest('/api/optimize-app', postData)
  },

  async sendCaConfirmationLink() {
    const postData = {
      method: "POST"
    }
    return await apiRequest('/api/send-ca-confirmation-link', postData)
  },

  async masquerade(params) {
    const masqueradePostData = {
      method: "POST",
      data: processInput(params)
    }
    return await apiRequest('/api/masquerade', masqueradePostData)
  },

  async masqueradeLeave(params) {
    const masqueradeLeavePostData = {
      method: "POST",
      data: processInput(params)
    }
    return await apiRequest('/api/masquerade-leave', masqueradeLeavePostData)
  },

  async seedDataUpload(params) {
    const uploadFilePostData = {
      method: "POST",
      data: processInput(params)
    }

    return await apiRequest("/api/seed-data-upload", uploadFilePostData);
  }
}
