import {
    apiRequest,
    processInput
} from "@/utilities"

export const screeningSessionService = {
    async getScreeningSessions() {
        const getScreeningSessionsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/screening-sessions", getScreeningSessionsRequest)
    },
    async getScreeningSession(uuid) {
        const getScreeningSessionRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/screening-sessions/${uuid}`, getScreeningSessionRequest)
    },
    async createScreeningSession(params) {
        const createData = processInput(params)
        const createScreeningSessionRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/screening-sessions", createScreeningSessionRequest)
    },
    async editScreeningSession(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editScreeningSessionRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/screening-sessions/${uuid}`, editScreeningSessionRequest)
    },
    async deleteScreeningSession(uuid) {
        const deleteScreeningSessionRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/screening-sessions/${uuid}`, deleteScreeningSessionRequest)
    }
}