export const roleRoutes = {
    path: 'roles',
    component: () => import("@/pages/role/RoleHome.vue"),
    children: [
        {
            path: '',
            name: 'roles-index',
            meta: {
                label: 'Roles'
            },
            component: () => import("@/pages/role/RoleIndex.vue"),
        },
        {
            path: ':name',
            name: 'role-users',
            meta: {
                label: 'Role Assignee'
            },
            component: () => import("@/pages/role/RoleAssignee.vue"),
        },
    ]
}
