import {
	GET_COACH_REFERENCES_REQUEST,
	GET_COACH_REFERENCES_SUCCESS,
	GET_COACH_REFERENCES_ERROR,
	GET_COACH_REFERENCE_REQUEST,
	GET_COACH_REFERENCE_SUCCESS,
	GET_COACH_REFERENCE_ERROR,
	CREATE_COACH_REFERENCE_REQUEST,
	CREATE_COACH_REFERENCE_SUCCESS,
	CREATE_COACH_REFERENCE_ERROR,
	EDIT_COACH_REFERENCE_REQUEST,
	EDIT_COACH_REFERENCE_SUCCESS,
	EDIT_COACH_REFERENCE_ERROR,
	DELETE_COACH_REFERENCE_REQUEST,
	DELETE_COACH_REFERENCE_SUCCESS,
	DELETE_COACH_REFERENCE_ERROR,
	CLEAR_COACH_REFERENCE_STATE
} from "@/actions"

import {
	coachReferenceService
} from "@/services"

const state = {
	coachReferences: [],
	coachReference: {},
	loading: false,
	loadedOnce: false
}

import { addNewFilter } from "@/utilities"

const getters = {
	cRefLoadedOnce: state => state.loadedOnce,
	coachReferences: (state, getters, rootState, rootGetters) => state.coachReferences.filter(cr => cr.coaches_uuid == rootGetters['coaches/coach']['uuid'])
}

const actions = {
	async getCoachReferences({ commit }, params) {
		commit("GET_COACH_REFERENCES_REQUEST")
		const response = await coachReferenceService.getCoachReferences(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COACH_REFERENCES_SUCCESS", data)
				break
			default:
				commit("GET_COACH_REFERENCES_ERROR", data)
		}
		return response
	},
	async getCoachReference({ commit }, params) {
		commit("GET_COACH_REFERENCE_REQUEST")
		const response = await coachReferenceService.getCoachReference(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_COACH_REFERENCE_SUCCESS", data)
				break
			default:
				commit("GET_COACH_REFERENCE_ERROR", data)
				break
		}
		return response
	},
	async createCoachReference({ commit, dispatch }, params) {
		commit("CREATE_COACH_REFERENCE_REQUEST")
		const response = await coachReferenceService.createCoachReference(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_COACH_REFERENCE_SUCCESS", data) : commit("CREATE_COACH_REFERENCE_ERROR", data)
				data.success ? dispatch("alert/createAlertSuccess", "Reference created.", { root: true }) : null
				data.error ? dispatch('alert/createAlertErrors', data.errors ?? 'Oops! Looks like something went wrong.', { root: true }) : null
				break
			default:
				commit("CREATE_COACH_REFERENCE_ERROR", data)
		}
		return response
	},
	async editCoachReference({ commit, dispatch }, params) {
		commit("EDIT_COACH_REFERENCE_REQUEST")
		const uuid = params.uuid
		const response = await coachReferenceService.editCoachReference(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_COACH_REFERENCE_SUCCESS", data) : null;
				data.success ? dispatch("alert/createAlertSuccess", "Reference updated.", { root: true }) : null;
				data.error ? dispatch('alert/createAlertErrors', data.errors ?? 'Oops! Looks like something went wrong.', { root: true }) : null;
				data.error ? commit("EDIT_COACH_REFERENCE_ERROR", data) : null;
				break
			default:
				commit("EDIT_COACH_REFERENCE_ERROR", data)
		}
		return response
	},
	async deleteCoachReference({ commit, dispatch }, uuid) {
		commit("DELETE_COACH_REFERENCE_REQUEST")
		const response = await coachReferenceService.deleteCoachReference(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_COACH_REFERENCE_SUCCESS", uuid)
				data.success ? dispatch("alert/createAlertSuccess", "Reference deleted.", { root: true }) : null
				break
			default:
				commit("DELETE_COACH_REFERENCE_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_COACH_REFERENCES_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_REFERENCES_SUCCESS]: (state, data) => {
		const coach_references = data.coach_references
		state.coachReferences = addNewFilter(state.coachReferences, coach_references)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_COACH_REFERENCES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_COACH_REFERENCE_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_REFERENCE_SUCCESS]: (state, data) => {
		const { coach_reference } = data
		state.coachReference = coach_reference
		state.loading = false
	},
	[GET_COACH_REFERENCE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COACH_REFERENCE_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COACH_REFERENCE_SUCCESS]: (state, data) => {
		const { coach_reference } = data
		state.coachReferences = addNewFilter(state.coachReferences, [coach_reference])
		state.loading = false
	},
	[CREATE_COACH_REFERENCE_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COACH_REFERENCE_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COACH_REFERENCE_SUCCESS]: (state, data) => {
		const { coach_reference } = data
		state.coachReferences = state.coachReferences.map(cr => cr.uuid == coach_reference.uuid ? coach_reference : cr)
		state.loading = false
	},
	[EDIT_COACH_REFERENCE_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COACH_REFERENCE_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COACH_REFERENCE_SUCCESS]: (state, uuid) => {
		state.coachReferences = state.coachReferences.filter(reference => reference.uuid !== uuid)
		state.loading = false
	},
	[DELETE_COACH_REFERENCE_ERROR]: (state, data) => {
		state.loading = false
	},

	[CLEAR_COACH_REFERENCE_STATE]: (state) => {
		state.coachReferences = []
		state.coachReference = {}
		state.loading = false
		state.loadedOnce = false
	}
}

export const coachReference = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}