import {
    apiRequest,
    processInput
} from "@/utilities"

export const statusService = {
    async getStatuses() {
        const getStatusesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/statuses", getStatusesRequest)
    },
    async getStatus(uuid) {
        const getStatusRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/statuses/${uuid}`, getStatusRequest)
    },
    async createStatus(params) {
        const createData = processInput(params)
        const createStatusRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/statuses", createStatusRequest)
    },
    async editStatus(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editStatusRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/statuses/${uuid}`, editStatusRequest)
    },
    async deleteStatus(uuid) {
        const deleteStatusRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/statuses/${uuid}`, deleteStatusRequest)
    }
}