import {
    apiRequest,
    processInput
} from "@/utilities"

export const pointOfContactTypesService = {
    async getPointOfContactTypes() {
        const getPointOfContactTypesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/poc-types", getPointOfContactTypesRequest)
    },
    async createPointOfContactType(params) {
        const createData = processInput(params);
        const postPointOfContactTypeRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/poc-types", postPointOfContactTypeRequest)
    },
    async getPointOfContactType(uuid) {

        const getPointOfContactTypeRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/poc-types/${uuid}`, getPointOfContactTypeRequest);
    },
    async editContractPriceType(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updatePointOfContactTypeRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/poc-types/${uuid}`, updatePointOfContactTypeRequest);
    },
    async deletePointOfContactType(uuid) {

        const deletePointOfContactTypeRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/poc-types/${uuid}`, deletePointOfContactTypeRequest);
    }
}