import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const payrollService = {
  async getPayrolls(params) {
    let getParams = processQueryParams(params)
    const getPayrollsRequest = {
      method: "GET",
      params: getParams
    }
    return await apiRequest("/api/payrolls", getPayrollsRequest)
  },
  async getPayroll(uuid) {
    const getPayrollRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/payrolls/${uuid}`, getPayrollRequest)
  },
  async createPayroll(params) {
    const createData = processInput(params)
    const createPayrollRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/payrolls", createPayrollRequest)
  },
  async editPayroll(data) {
    const { uuid } = data
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editPayrollRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/payrolls/${uuid}`, editPayrollRequest)
  },
  async deletePayroll(uuid) {
    const deletePayrollRequest = {
      method: "DELETE"
    }
    return await apiRequest(`/api/payrolls/${uuid}`, deletePayrollRequest)
  },
  async generatePayroll(params) {
    const generatePayrollsRequest = {
      method: "POST",
    }
    return await apiRequest(`/api/payroll/${params.date}/generate`, generatePayrollsRequest)
  },
}