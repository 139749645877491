import {
  GET_SHARED_QUEUES_REQUEST,
  GET_SHARED_QUEUES_SUCCESS,
  GET_SHARED_QUEUES_ERROR,
  GET_SHARED_QUEUE_REQUEST,
  GET_SHARED_QUEUE_SUCCESS,
  GET_SHARED_QUEUE_ERROR,
  CREATE_SHARED_QUEUE_REQUEST,
  CREATE_SHARED_QUEUE_SUCCESS,
  CREATE_SHARED_QUEUE_ERROR,
  EDIT_SHARED_QUEUE_REQUEST,
  EDIT_SHARED_QUEUE_SUCCESS,
  EDIT_SHARED_QUEUE_ERROR,
  DELETE_SHARED_QUEUE_REQUEST,
  DELETE_SHARED_QUEUE_SUCCESS,
  DELETE_SHARED_QUEUE_ERROR
} from "@/actions"

import {
  sharedQueueService
} from "@/services"

import {
  addNewFilter
} from "@/utilities"

const state = {
  sharedQueues: [],
  loading: false
}

const getters = {
  sharedQueueByUnivUuid: state => uUuid => state.sharedQueues.filter(cw => cw.universities_uuid == uUuid),
  findSharedQueue: state => uuid => state.sharedQueues.find(q => q.uuid == uuid)
}

const actions = {
  async getSharedQueues({ commit }) {
    commit("GET_SHARED_QUEUES_REQUEST")
    const response = await sharedQueueService.getSharedQueues();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_SHARED_QUEUES_SUCCESS", data)
        break
      default:
        commit("GET_SHARED_QUEUES_ERROR", data)
    }
    return response
  },
  async getSharedQueue({ commit }, params) {
    commit("GET_SHARED_QUEUE_REQUEST")
    const response = await sharedQueueService.getSharedQueue(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("GET_SHARED_QUEUE_SUCCESS", data) : null
        break
      default:
        commit("GET_SHARED_QUEUE_ERROR", data)
        break
    }
    return response
  },
  async createSharedQueue({ commit }, params) {
    commit("CREATE_SHARED_QUEUE_REQUEST")
    const response = await sharedQueueService.createSharedQueue(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("CREATE_SHARED_QUEUE_SUCCESS", data)
        break
      default:
        commit("CREATE_SHARED_QUEUE_ERROR", data)
    }
    return response
  },
  async editSharedQueue({ commit }, params, uuid) {
    commit("EDIT_SHARED_QUEUE_REQUEST")
    const response = await sharedQueueService.editSharedQueue(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_SHARED_QUEUE_SUCCESS", data)
        break
      default:
        commit("EDIT_SHARED_QUEUE_ERROR", data)
    }
    return response
  },
  async deleteSharedQueue({ commit }, params) {
    const { uuid } = params;
    commit("DELETE_SHARED_QUEUE_REQUEST")
    const response = await sharedQueueService.deleteSharedQueue(params);
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_SHARED_QUEUE_SUCCESS", { uuid }) : null
        break
      default:
        commit("DELETE_SHARED_QUEUE_ERROR", data)
    }
    return response
  }
}

const mutations = {
  GET_SHARED_QUEUES_REQUEST: state => {
    state.loading = true
  },
  GET_SHARED_QUEUES_SUCCESS: (state, data) => {
    const shared_queues = data.shared_queues;
    state.sharedQueues = addNewFilter(state.sharedQueues, shared_queues)
    state.loading = false
  },
  GET_SHARED_QUEUES_ERROR: state => {
    state.loading = false
    state.error = true
  },
  GET_SHARED_QUEUE_REQUEST: state => {
    state.loading = true
  },
  GET_SHARED_QUEUE_SUCCESS: (state, data) => {
    const { shared_queue } = data
    state.sharedQueue = shared_queue
    state.loading = false
  },
  GET_SHARED_QUEUE_ERROR: (state, data) => {
    state.loading = false
  },
  CREATE_SHARED_QUEUE_REQUEST: state => {
    state.loading = true
  },
  CREATE_SHARED_QUEUE_SUCCESS: (state, data) => {
    const { shared_queue } = data;
    state.sharedQueues = addNewFilter(state.sharedQueues, [shared_queue])
    state.loading = false
  },
  CREATE_SHARED_QUEUE_ERROR: state => {
    state.loading = false
  },
  EDIT_SHARED_QUEUE_REQUEST: state => {
    state.loading = true
  },
  EDIT_SHARED_QUEUE_SUCCESS: state => {
    state.loading = false
  },
  EDIT_SHARED_QUEUE_ERROR: state => {
    state.loading = false
  },
  DELETE_SHARED_QUEUE_REQUEST: state => {
    state.loading = true
  },
  DELETE_SHARED_QUEUE_SUCCESS: (state, data) => {
    const { uuid } = data
    state.sharedQueues = state.sharedQueues.filter(sq => sq.uuid !== uuid)
    state.loading = false
  },
  DELETE_SHARED_QUEUE_ERROR: (state, data) => {
    state.loading = false
  }

}

export const sharedQueue = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
