import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const reconciliationService = {
  async createReconciliation(params) {
    const createData = processInput(params)
    const createReconciliationRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/reconciliation", createReconciliationRequest)
  },
  async resetReconciliation(data) {
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/reconciliation/${data.uuid}`, editRequest)
  },
  async deleteReconciliation(data) {
    let params = processQueryParams(data)
    const deleteReconciliationRequest = {
      method: "DELETE",
      params
    }
    return await apiRequest(`/api/reconciliation/${data.uuid}`, deleteReconciliationRequest)
  },
  async finalizeReconciliation(data) {
    let finalizeData = processInput(data)
    const finalizeReconciliationRequest = {
      method: "POST",
      data: finalizeData
    }
    return await apiRequest(`/api/reconciliation-finalize`, finalizeReconciliationRequest)
  },
  async overrideReconciliation(params) {
    const { uuid } = params;
    const overrideRequest = {
      method: 'POST',
      data: processInput(params)
    }
    return await apiRequest(`/api/reconciliation/${uuid}/override`, overrideRequest)
  }
}
