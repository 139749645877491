import {
  combinedWASGroupItemService
} from "@/services"

import {
  addNewFilter
} from "@/utilities"

const state = {
  groupItems: [],
  loading: false
}

const getters = {
  groupItems: state => gUuid => state.groupItems.filter(g => g.groups_uuid == gUuid)
}

const actions = {
  async getCombinedGroupItems({ commit }) {
    commit("GET_COMBINED_WAS_GROUP_ITEMS_REQUEST")
    const response = await combinedWASGroupItemService.getCombinedGroupItems();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_COMBINED_WAS_GROUP_ITEMS_SUCCESS", data)
        break
      default:
        commit("GET_COMBINED_WAS_GROUP_ITEMS_ERROR", data)
    }
    return response
  },
  async getCombinedGroupItem({ commit }, params) {
    commit("GET_COMBINED_WAS_GROUP_ITEM_REQUEST")
    const response = await combinedWASGroupItemService.getCombinedGroupItem(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("GET_COMBINED_WAS_GROUP_ITEM_SUCCESS", data) : null
        break
      default:
        commit("GET_COMBINED_WAS_GROUP_ITEM_ERROR", data)
        break
    }
    return response
  },
  async createCombinedGroupItem({ commit, dispatch }, params) {
    commit("CREATE_COMBINED_WAS_GROUP_ITEM_REQUEST")
    const response = await combinedWASGroupItemService.createCombinedGroupItem(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data?.success ? commit("CREATE_COMBINED_WAS_GROUP_ITEM_SUCCESS", data) : null;
        data?.success ? dispatch("alert/createAlertSuccess", "Program added to group.", { root: true }) : null
        break
      default:
        commit("CREATE_COMBINED_WAS_GROUP_ITEM_ERROR", data)
    }
    return response
  },
  async editCombinedGroupItem({ commit, dispatch }, params, uuid) {
    commit("EDIT_COMBINED_WAS_GROUP_ITEM_REQUEST")
    const response = await combinedWASGroupItemService.editCombinedGroupItem(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data?.success ? commit("EDIT_COMBINED_WAS_GROUP_ITEM_SUCCESS", data) : null;
        data?.success ? dispatch("alert/createAlertSuccess", "Combined group updated.", { root: true }) : null
        break
      default:
        commit("EDIT_COMBINED_WAS_GROUP_ITEM_ERROR", data)
    }
    return response
  },
  async deleteCombinedGroupItem({ commit, dispatch }, params) {
    const { uuid } = params;
    commit("DELETE_COMBINED_WAS_GROUP_ITEM_REQUEST")
    const response = await combinedWASGroupItemService.deleteCombinedGroupItem(params)
    const { status, data } = response;
    switch (status) {
      case 200:
        data?.success ? commit("DELETE_COMBINED_WAS_GROUP_ITEM_SUCCESS", { item: { uuid } }) : null;
        data?.success ? dispatch("alert/createAlertSuccess", "Program deleted from group.", { root: true }) : null;
        break
      default:
        commit("DELETE_COMBINED_WAS_GROUP_ITEM_ERROR", data)
    }
    return response
  }
}

const mutations = {
  GET_COMBINED_WAS_GROUP_ITEMS_REQUEST: state => {
    state.loading = true
  },
  GET_COMBINED_WAS_GROUP_ITEMS_SUCCESS: (state, data) => {
    state.groupItems = addNewFilter(state.groupItems, data?.items ?? []);
    state.loading = false
  },
  GET_COMBINED_WAS_GROUP_ITEMS_ERROR: state => {
    state.loading = false
    state.error = true
  },
  GET_COMBINED_WAS_GROUP_ITEM_REQUEST: state => {
    state.loading = true
  },
  GET_COMBINED_WAS_GROUP_ITEM_SUCCESS: (state, data) => {
    state.loading = false
  },
  GET_COMBINED_WAS_GROUP_ITEM_ERROR: (state, data) => {
    state.loading = false
  },
  CREATE_COMBINED_WAS_GROUP_ITEM_REQUEST: state => {
    state.loading = true
  },
  CREATE_COMBINED_WAS_GROUP_ITEM_SUCCESS: (state, data) => {
    const { item } = data;
    state.groupItems = addNewFilter(state.groupItems, [item])
    state.loading = false;
  },
  CREATE_COMBINED_WAS_GROUP_ITEM_ERROR: state => {
    state.loading = false
  },
  EDIT_COMBINED_WAS_GROUP_ITEM_REQUEST: state => {
    state.loading = true
  },
  EDIT_COMBINED_WAS_GROUP_ITEM_SUCCESS: state => {
    state.loading = false
  },
  EDIT_COMBINED_WAS_GROUP_ITEM_ERROR: state => {
    state.loading = false
  },
  DELETE_COMBINED_WAS_GROUP_ITEM_REQUEST: state => {
    state.loading = true
  },
  DELETE_COMBINED_WAS_GROUP_ITEM_SUCCESS: (state, data) => {
    const { item } = data;
    state.groupItems = state.groupItems.filter(gi => gi.uuid != item?.uuid);
    state.loading = false;
  },
  DELETE_COMBINED_WAS_GROUP_ITEM_ERROR: (state, data) => {
    state.loading = false;
  }
}

export const combinedWASGroupItem = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

