export const GET_UNIVERSITIES_REQUEST = "GET_UNIVERSITIES_REQUEST"
export const GET_UNIVERSITIES_SUCCESS = "GET_UNIVERSITIES_SUCCESS"
export const GET_UNIVERSITIES_LIMITED_DATA_SUCCESS = "GET_UNIVERSITIES_LIMITED_DATA_SUCCESS"
export const GET_UNIVERSITIES_ERROR = "GET_UNIVERSITIES_ERROR"
export const GET_UNIVERSITY_REQUEST = "GET_UNIVERSITY_REQUEST"
export const GET_UNIVERSITY_SUCCESS = "GET_UNIVERSITY_SUCCESS"
export const GET_UNIVERSITY_ERROR = "GET_UNIVERSITY_ERROR"
export const CREATE_UNIVERSITY_REQUEST = "CREATE_UNIVERSITY_REQUEST"
export const CREATE_UNIVERSITY_SUCCESS = "CREATE_UNIVERSITY_SUCCESS"
export const CREATE_UNIVERSITY_ERROR = "CREATE_UNIVERSITY_ERROR"
export const EDIT_UNIVERSITY_REQUEST = "EDIT_UNIVERSITY_REQUEST"
export const EDIT_UNIVERSITY_SUCCESS = "EDIT_UNIVERSITY_SUCCESS"
export const EDIT_UNIVERSITY_ERROR = "EDIT_UNIVERSITY_ERROR"
export const DELETE_UNIVERSITY_REQUEST = "DELETE_UNIVERSITY_REQUEST"
export const DELETE_UNIVERSITY_SUCCESS = "DELETE_UNIVERSITY_SUCCESS"
export const DELETE_UNIVERSITY_ERROR = "DELETE_UNIVERSITY_ERROR"

export const UPDATE_UNIVERSITY_STATUS = "UPDATE_UNIVERSITY_STATUS"

export const CLEAR_UNIVERSITY = "CLEAR_UNIVERSITY"
