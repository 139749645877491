export const GET_PRE_COURSE_MEETING_MINUTES_REQUEST = "GET_PRE_COURSE_MEETING_MINUTES_REQUEST"
export const GET_PRE_COURSE_MEETING_MINUTES_SUCCESS = "GET_PRE_COURSE_MEETING_MINUTES_SUCCESS"
export const GET_PRE_COURSE_MEETING_MINUTES_ERROR = "GET_PRE_COURSE_MEETING_MINUTES_ERROR"
export const GET_PRE_COURSE_MEETING_MINUTE_REQUEST = "GET_PRE_COURSE_MEETING_MINUTE_REQUEST"
export const GET_PRE_COURSE_MEETING_MINUTE_SUCCESS = "GET_PRE_COURSE_MEETING_MINUTE_SUCCESS"
export const GET_PRE_COURSE_MEETING_MINUTE_ERROR = "GET_PRE_COURSE_MEETING_MINUTE_ERROR"
export const CREATE_PRE_COURSE_MEETING_MINUTE_REQUEST = "CREATE_PRE_COURSE_MEETING_MINUTE_REQUEST"
export const CREATE_PRE_COURSE_MEETING_MINUTE_SUCCESS = "CREATE_PRE_COURSE_MEETING_MINUTE_SUCCESS"
export const CREATE_PRE_COURSE_MEETING_MINUTE_ERROR = "CREATE_PRE_COURSE_MEETING_MINUTE_ERROR"
export const EDIT_PRE_COURSE_MEETING_MINUTE_REQUEST = "EDIT_PRE_COURSE_MEETING_MINUTE_REQUEST"
export const EDIT_PRE_COURSE_MEETING_MINUTE_SUCCESS = "EDIT_PRE_COURSE_MEETING_MINUTE_SUCCESS"
export const EDIT_PRE_COURSE_MEETING_MINUTE_ERROR = "EDIT_PRE_COURSE_MEETING_MINUTE_ERROR"
export const DELETE_PRE_COURSE_MEETING_MINUTE_REQUEST = "DELETE_PRE_COURSE_MEETING_MINUTE_REQUEST"
export const DELETE_PRE_COURSE_MEETING_MINUTE_SUCCESS = "DELETE_PRE_COURSE_MEETING_MINUTE_SUCCESS"
export const DELETE_PRE_COURSE_MEETING_MINUTE_ERROR = "DELETE_PRE_COURSE_MEETING_MINUTE_ERROR"