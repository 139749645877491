import {
    GET_EMPLOYERS_REQUEST,
    GET_EMPLOYERS_SUCCESS,
    GET_EMPLOYERS_ERROR,
    GET_EMPLOYER_REQUEST,
    GET_EMPLOYER_SUCCESS,
    GET_EMPLOYER_ERROR,
    CREATE_EMPLOYER_REQUEST,
    CREATE_EMPLOYER_SUCCESS,
    CREATE_EMPLOYER_ERROR,
    EDIT_EMPLOYER_REQUEST,
    EDIT_EMPLOYER_SUCCESS,
    EDIT_EMPLOYER_ERROR,
    DELETE_EMPLOYER_REQUEST,
    DELETE_EMPLOYER_SUCCESS,
    DELETE_EMPLOYER_ERROR
} from "@/actions"

import { employerService } from "@/services"

const state = {
    employers: [],
    employer: {},
    loading: false,
    loadedOnce: false
}

const getters = {
    employersLoadedOnce: state => state.loadedOnce,
    employerSelectOptions: state => state.employers.map(e => ({ value: e.uuid, label: e.full_name })),
    employerFilter: state => uuid => state.employers.find(emp => emp.uuid == uuid)
}

const actions = {
    async getEmployers({ commit }) {
        commit("GET_EMPLOYERS_REQUEST")
        const response = await employerService.getEmployers();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_EMPLOYERS_SUCCESS", data)
                break
            default:
                commit("GET_EMPLOYERS_ERROR", data)
        }
        return response
    },
    async getEmployer({ commit }, params) {
        commit("GET_EMPLOYER_REQUEST")
        const response = await employerService.getEmployer(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_EMPLOYER_SUCCESS", data)
                break
            default:
                commit("GET_EMPLOYER_ERROR", data)
                break
        }
        return response
    },
    async createEmployer({ commit }, params) {
        commit("CREATE_EMPLOYER_REQUEST")
        const response = await employerService.createEmployer(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_EMPLOYER_SUCCESS", data) : commit("CREATE_EMPLOYER_ERROR", data)
                break
            default:
                commit("CREATE_EMPLOYER_ERROR", data)
        }
        return response
    },
    async editEmployer({ commit }, params, uuid) {
        commit("EDIT_EMPLOYER_REQUEST")
        const response = await employerService.editEmployer(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_EMPLOYER_SUCCESS", data)
                break
            default:
                commit("EDIT_EMPLOYER_ERROR", data)
        }
        return response
    },
    async deleteEmployer({ commit }, params) {
        commit("DELETE_EMPLOYER_REQUEST")
        const response = await employerService.deleteEmployer(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_EMPLOYER_SUCCESS", data)
                break
            default:
                commit("DELETE_EMPLOYER_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_EMPLOYERS_REQUEST]: state => {
        state.loading = true
    },
    [GET_EMPLOYERS_SUCCESS]: (state, data) => {
        let employers = [...data.employers]
        state.employers = employers
        state.loading = false
        state.loadedOnce = true
    },
    [GET_EMPLOYERS_ERROR]: state => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [GET_EMPLOYER_REQUEST]: state => {
        state.loading = true
    },
    [GET_EMPLOYER_SUCCESS]: (state, data) => {
        const employer = { ...data.employer }
        state.employer = employer
        state.loading = false
    },
    [GET_EMPLOYER_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_EMPLOYER_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_EMPLOYER_SUCCESS]: (state, data) => {
        let employer = { ...data }
        state.employers.push(employer)
        state.loading = false
    },
    [CREATE_EMPLOYER_ERROR]: state => {
        state.loading = false
    },

    [EDIT_EMPLOYER_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_EMPLOYER_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_EMPLOYER_ERROR]: state => {
        state.loading = false
    },

    [DELETE_EMPLOYER_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_EMPLOYER_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_EMPLOYER_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const employer = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}