export const GET_UNIVERSITY_LMSS_REQUEST = "GET_UNIVERSITY_LMSS_REQUEST"
export const GET_UNIVERSITY_LMSS_SUCCESS = "GET_UNIVERSITY_LMSS_SUCCESS"
export const GET_UNIVERSITY_LMSS_ERROR = "GET_UNIVERSITY_LMSS_ERROR"
export const GET_UNIVERSITY_LMS_REQUEST = "GET_UNIVERSITY_LMS_REQUEST"
export const GET_UNIVERSITY_LMS_SUCCESS = "GET_UNIVERSITY_LMS_SUCCESS"
export const GET_UNIVERSITY_LMS_ERROR = "GET_UNIVERSITY_LMS_ERROR" 
export const CREATE_UNIVERSITY_LMS_REQUEST = "CREATE_UNIVERSITY_LMS_REQUEST"
export const CREATE_UNIVERSITY_LMS_SUCCESS = "CREATE_UNIVERSITY_LMS_SUCCESS"
export const CREATE_UNIVERSITY_LMS_ERROR = "CREATE_UNIVERSITY_LMS_ERROR"
export const EDIT_UNIVERSITY_LMS_REQUEST = "EDIT_UNIVERSITY_LMS_REQUEST"
export const EDIT_UNIVERSITY_LMS_SUCCESS = "EDIT_UNIVERSITY_LMS_SUCCESS"
export const EDIT_UNIVERSITY_LMS_ERROR = "EDIT_UNIVERSITY_LMS_ERROR"
export const DELETE_UNIVERSITY_LMS_REQUEST = "DELETE_UNIVERSITY_LMS_REQUEST"
export const DELETE_UNIVERSITY_LMS_SUCCESS = "DELETE_UNIVERSITY_LMS_SUCCESS"
export const BROADCAST_DELETE_UNIVERSITY_LMS_SUCCESS = "BROADCAST_DELETE_UNIVERSITY_LMS_SUCCESS"
export const DELETE_UNIVERSITY_LMS_ERROR = "DELETE_UNIVERSITY_LMS_ERROR"