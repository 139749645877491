/*
import {
    GET_UNIVERSITY_PARTNERSHIPS_REQUEST,
    GET_UNIVERSITY_PARTNERSHIPS_SUCCESS,
    GET_UNIVERSITY_PARTNERSHIPS_ERROR,
    GET_UNIVERSITY_PARTNERSHIP_REQUEST,
    GET_UNIVERSITY_PARTNERSHIP_SUCCESS,
    GET_UNIVERSITY_PARTNERSHIP_ERROR,
    CREATE_UNIVERSITY_PARTNERSHIP_REQUEST,
    CREATE_UNIVERSITY_PARTNERSHIP_SUCCESS,
    CREATE_UNIVERSITY_PARTNERSHIP_ERROR,
    EDIT_UNIVERSITY_PARTNERSHIP_REQUEST,
    EDIT_UNIVERSITY_PARTNERSHIP_SUCCESS,
    EDIT_UNIVERSITY_PARTNERSHIP_ERROR,
    DELETE_UNIVERSITY_PARTNERSHIP_REQUEST,
    DELETE_UNIVERSITY_PARTNERSHIP_SUCCESS,
    DELETE_UNIVERSITY_PARTNERSHIP_ERROR,
    CLEAR_UNIVERSITY_PARTNERSHIP_STATE
} from "@/actions"
import {
    universityPartnershipService
} from "@/services"

import { isEmpty } from "@/utilities"
import validator from "validator"
import _, { map } from 'underscore'
*/

const state = {
    /*
    universityPartnerships: [],
    universityPartnership: {},
    loading: false,
    loadedOnce: false,
    */
    partnershipMenu: [
        {
            order: 1,
            disabled: false,
            slug: "link",
            name: "Account Details",
            href: "account-details",
            progressSlug: "ua-account-details"
        },
        {
            order: 2,
            disabled: false,
            slug: "link",
            name: "LMS Access",
            href: "lms-access",
            progressSlug: "ua-lms-access"
        },
        {
            order: 3,
            disabled: false,
            slug: "link",
            name: "Coach/Couse Procedure",
            href: "coach-course-procedure",
            progressSlug: "ua-coach-course-procedure"
        },
        {
            order: 4,
            disabled: false,
            slug: "link",
            name: "Additional POC",
            href: "additional-poc",
            progressSlug: "ua-additional-poc"
        },
    ]
}

const getters = {
    universityPartnershipMenu: (state) => state.partnershipMenu,
}

const actions = {
    /*
    async getUniversitypplications({ commit }, params) {
        commit("GET_UNIVERSITY_PARTNERSHIPS_REQUEST")
        const response = await universityPartnershipService.getUniversitypplications(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_PARTNERSHIPS_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_PARTNERSHIPS_ERROR", data)
        }
        return response
    },
    async getUniversitypplication({ commit }, params) {
        commit("GET_UNIVERSITY_PARTNERSHIP_REQUEST")
        const response = await universityPartnershipService.getUniversitypplication(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("GET_UNIVERSITY_PARTNERSHIP_SUCCESS", data) : commit("GET_UNIVERSITY_PARTNERSHIP_ERROR", data)
                break
            default:
                commit("GET_UNIVERSITY_PARTNERSHIP_ERROR", data)
                break
        }
        return response
    },
    async createUniversitypplication({ commit }, params) {
        commit("CREATE_UNIVERSITY_PARTNERSHIP_REQUEST")
        const response = await universityPartnershipService.createUniversitypplication(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_UNIVERSITY_PARTNERSHIP_SUCCESS", data) : commit("CREATE_UNIVERSITY_PARTNERSHIP_ERROR", data)
                break
            default:
                commit("CREATE_UNIVERSITY_PARTNERSHIP_ERROR", data)
        }
        return response
    },
    async editUniversitypplication({ commit }, params) {
        commit("EDIT_UNIVERSITY_PARTNERSHIP_REQUEST")
        const uuid = params.uuid
        const response = await universityPartnershipService.editUniversitypplication(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("EDIT_UNIVERSITY_PARTNERSHIP_SUCCESS", data) : commit("EDIT_UNIVERSITY_PARTNERSHIP_ERROR", data)
                break
            default:
                commit("EDIT_UNIVERSITY_PARTNERSHIP_ERROR", data)
        }
        return response
    },
    async deleteUniversitypplication({ commit }, params) {
        commit("DELETE_UNIVERSITY_PARTNERSHIP_REQUEST")
        const response = await universityPartnershipService.deleteUniversitypplication(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_UNIVERSITY_PARTNERSHIP_SUCCESS", data)
                break
            default:
                commit("DELETE_UNIVERSITY_PARTNERSHIP_DELETE", data)
        }
        return response
    },
    updateAppProgress({ dispatch, commit }, progressObject) {
        let appProgress = _.findWhere(state.partnershipMenu, { progressSlug: progressObject.universityPartnership.partnership_progress })
        let nextSection = _.findWhere(state.partnershipMenu, { progressSlug: progressObject.appProgress })
        if (nextSection.order > appProgress.order) {
            return dispatch('editUniversitypplication', { uuid: progressObject.universityPartnership.uuid, partnershipProgress: nextSection.progressSlug })
        }
    }
    */
}

const mutations = {
    /*
    [GET_UNIVERSITY_PARTNERSHIPS_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_PARTNERSHIPS_SUCCESS]: (state, data) => {
        const universityPartnerships = [...data.university_partnerships]
        state.universityPartnerships = state.universityPartnerships.concat(universityPartnerships)
        state.loading = false
    },
    [GET_UNIVERSITY_PARTNERSHIPS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_UNIVERSITY_PARTNERSHIP_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_PARTNERSHIP_SUCCESS]: (state, data) => {
        const universityPartnership = { ...data.university_partnership }
        state.universityPartnership = universityPartnership
        state.loading = false
    },
    [GET_UNIVERSITY_PARTNERSHIP_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_UNIVERSITY_PARTNERSHIP_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_UNIVERSITY_PARTNERSHIP_SUCCESS]: (state, data) => {
        let universityPartnership = { ...data.university_partnership }
        state.universityPartnerships.push(universityPartnership)
        state.loading = false
    },
    [CREATE_UNIVERSITY_PARTNERSHIP_ERROR]: state => {
        state.loading = false
    },

    [EDIT_UNIVERSITY_PARTNERSHIP_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_UNIVERSITY_PARTNERSHIP_SUCCESS]: (state, data) => {
        let universityPartnership = { ...data.university_partnership }
        let apps = state.universityPartnerships.map(app => {
            if (app.uuid == universityPartnership.uuid) return universityPartnership
            return app
        })
        state.universityPartnerships = apps
        state.loading = false
    },
    [EDIT_UNIVERSITY_PARTNERSHIP_ERROR]: state => {
        state.loading = false
    },

    [DELETE_UNIVERSITY_PARTNERSHIP_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_UNIVERSITY_PARTNERSHIP_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_UNIVERSITY_PARTNERSHIP_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_UNIVERSITY_PARTNERSHIP_STATE]: state => {
        state.universityPartnerships = []
        state.universityAplication = { }
        state.loading = false
        state.loadedOnce = false
    }
    */

}

export const universityPartnership = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}