import {
  GET_POINT_OF_CONTACTS_REQUEST,
  GET_POINT_OF_CONTACTS_SUCCESS,
  GET_POINT_OF_CONTACTS_ERROR,
  GET_POINT_OF_CONTACT_REQUEST,
  GET_POINT_OF_CONTACT_SUCCESS,
  GET_POINT_OF_CONTACT_ERROR,
  CREATE_POINT_OF_CONTACT_REQUEST,
  CREATE_POINT_OF_CONTACT_SUCCESS,
  CREATE_POINT_OF_CONTACT_ERROR,
  EDIT_POINT_OF_CONTACT_REQUEST,
  EDIT_POINT_OF_CONTACT_SUCCESS,
  EDIT_POINT_OF_CONTACT_ERROR,
  DELETE_POINT_OF_CONTACT_REQUEST,
  DELETE_POINT_OF_CONTACT_SUCCESS,
  BROADCAST_DELETE_POINT_OF_CONTACT_SUCCESS,
  DELETE_POINT_OF_CONTACT_ERROR
} from "@/actions"

import {
  pointOfContactsService
} from "@/services"

const state = {
  pointOfContacts: [],
  pointOfContact: {},
  loading: false,
  isPOCLoaded: false,
}

import { addNewFilter, isEmpty } from "@/utilities"

const getters = {
  pocsLoading: state => state.loading,
  isPOCLoaded: state => state.isPOCLoaded,
  allPOC: state => state.pointOfContacts,
  pocByUuid: (state, getters, rootState, rootGetters) => uuid => {
    const poc = state.pointOfContacts.find(poc => poc.uuid == uuid)
    if(!isEmpty(poc)){
      const pocTypeByUuidArrayFilter = rootGetters['pointOfContactType/pocTypeByUuidArrayFilter']
      const poc_types = pocTypeByUuidArrayFilter(poc.poc_type_uuids)
      return {
        ...poc,
        poc_types
      }
    }
    return {}
  },
  universityPocs: (state, getters, rootState, rootGetters) => uuid => state.pointOfContacts.filter(poc => poc.universities_uuid == uuid).map(poc => {
    const pocTypeByUuidArrayFilter = rootGetters['pointOfContactType/pocTypeByUuidArrayFilter']
    const poc_types = pocTypeByUuidArrayFilter(poc.poc_type_uuids)
    let program = rootGetters['universityProgram/uniProUuidFilter'](poc.university_programs_uuid)
    return {
      ...poc,
      poc_types,
      program
    }
  }),
  universityPocsBySlug: (state, getters, rootState, rootGetters) => (uuid, typeSlug) => {
    const pocTypeByUuidArrayFilter = rootGetters['pointOfContactType/pocTypeByUuidArrayFilter']
    const pocTypeBySlugArrayFilter = rootGetters['pointOfContactType/pocTypeBySlugArrayFilter']
    const _poc_types = Array.isArray(typeSlug) ? pocTypeBySlugArrayFilter(typeSlug) : pocTypeBySlugArrayFilter([typeSlug])
    if(isEmpty(_poc_types)) return [];
    return state.pointOfContacts.filter(poc => {
      return poc.poc_type_uuids.some((pt_uuid => _poc_types.some(pt => pt.uuid == pt_uuid))) && poc.universities_uuid == uuid
    }).map(poc => {
      const poc_types = pocTypeByUuidArrayFilter(poc.poc_type_uuids)
      let program = rootGetters['universityProgram/uniProUuidFilter'](poc.university_programs_uuid)
      return {
        ...poc,
        poc_types,
        program
      }
    })
  },
  univPOCFilter: (state, getters, rootState, rootGetters) => uuid => state.pointOfContacts.filter(poc => poc.universities_uuid == uuid).map(poc => {
    let type = rootGetters['pointOfContactType/pointOfContactTypeFilter'](poc.type) ?? {}
    let program = rootGetters['universityProgram/uniProUuidFilter'](poc.university_programs_uuid)
    return {
      ...poc,
      type,
      program
    }
  }),
  selectedPoc: state => uuidArray => state.pointOfContacts.filter(i => uuidArray.includes(i.uuid)),
  univTypeArray: state => (uUuid, typeUuids) => state.pointOfContacts.filter(p => typeUuids.includes(p.type) && p.universities_uuid == uUuid)
}

const actions = {
  async getPointOfContacts({ commit }, params) {
    commit("GET_POINT_OF_CONTACTS_REQUEST")
    const response = await pointOfContactsService.getPointOfContacts(params);
    const { status, data } = response
    switch (status) {
      case 200:
        if(data.success){
          const point_of_contacts = data.point_of_contacts.map(i => unpackPoc(i, commit))
          commit("GET_POINT_OF_CONTACTS_SUCCESS", {point_of_contacts})
        }else{
          commit("GET_POINT_OF_CONTACTS_ERROR")
        }
        break
      default:
        commit("GET_POINT_OF_CONTACTS_ERROR", data)
    }
    return response
  },
  async getPointOfContact({ commit }, params) {
    commit("GET_POINT_OF_CONTACT_REQUEST")
    const response = await pointOfContactsService.getPointOfContact(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_POINT_OF_CONTACT_SUCCESS", data)
        break
      default:
        commit("GET_POINT_OF_CONTACT_ERROR", data)
        break
    }
    return response
  },
  async createPointOfContact({ commit, dispatch }, params) {
    commit("CREATE_POINT_OF_CONTACT_REQUEST")
    const response = await pointOfContactsService.createPointOfContact(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_POINT_OF_CONTACT_SUCCESS", data) : commit("CREATE_POINT_OF_CONTACT_ERROR", data)
        data.success ? dispatch('alert/createAlertSuccess', "New Point Of Contact  created.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
        break
      default:
        commit("CREATE_POINT_OF_CONTACT_ERROR", data)
    }
    return response
  },
  async editPointOfContact({ commit, dispatch }, params) {
    commit("EDIT_POINT_OF_CONTACT_REQUEST")
    const { uuid } = params
    const response = await pointOfContactsService.editPointOfContact(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_POINT_OF_CONTACT_SUCCESS", data) : commit("EDIT_POINT_OF_CONTACT_ERROR", data)
        data.success ? dispatch('alert/createAlertSuccess', "Point Of Contact updated.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
        break
      default:
        commit("EDIT_POINT_OF_CONTACT_ERROR", data)
    }
    return response
  },
  async deletePointOfContact({ commit, dispatch }, uuid) {
    commit("DELETE_POINT_OF_CONTACT_REQUEST")
    const response = await pointOfContactsService.deletePointOfContact(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_POINT_OF_CONTACT_SUCCESS", uuid) : commit("DELETE_POINT_OF_CONTACT_SUCCESS", data)
        data.success ? dispatch('alert/createAlertSuccess', "POC archived.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
        break
      default:
        commit("DELETE_POINT_OF_CONTACT_SUCCESS", data)
    }
    return response
  }
}

const mutations = {
  [GET_POINT_OF_CONTACTS_REQUEST]: state => {
    state.loading = true
  },
  [GET_POINT_OF_CONTACTS_SUCCESS]: (state, data) => {
    let point_of_contacts = [...data.point_of_contacts]
    state.pointOfContacts = addNewFilter(state.pointOfContacts, point_of_contacts)
    state.loading = false
    state.isPOCLoaded = true
  },
  [GET_POINT_OF_CONTACTS_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_POINT_OF_CONTACT_REQUEST]: state => {
    state.loading = true
  },
  [GET_POINT_OF_CONTACT_SUCCESS]: (state, data) => {
    let mdata = { ...data.data }
    state.pointOfContact = mdata
    state.loading = false
  },
  [GET_POINT_OF_CONTACT_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_POINT_OF_CONTACT_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_POINT_OF_CONTACT_SUCCESS]: (state, data) => {
    let { point_of_contact } = data
    state.pointOfContacts = addNewFilter(state.pointOfContacts, [point_of_contact])
    state.loading = false
  },
  [CREATE_POINT_OF_CONTACT_ERROR]: state => {
    state.loading = false
  },

  [EDIT_POINT_OF_CONTACT_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_POINT_OF_CONTACT_SUCCESS]: (state, data) => {
    let { point_of_contact } = data
    state.pointOfContacts = state.pointOfContacts.filter(poc => poc.uuid != point_of_contact.uuid)
    state.pointOfContacts = addNewFilter(state.pointOfContacts, [point_of_contact])
    state.loading = false
  },
  [EDIT_POINT_OF_CONTACT_ERROR]: state => {
    state.loading = false
  },

  [DELETE_POINT_OF_CONTACT_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_POINT_OF_CONTACT_SUCCESS]: (state, uuid) => {
    let poc = state.pointOfContacts.find(i => i.uuid == uuid)
    poc.deleted_at = new Date().toISOString()
    state.pointOfContacts = state.pointOfContacts.filter(poc => poc.uuid !== uuid)
    state.pointOfContacts.push(poc)
    state.loading = false
  },
  [BROADCAST_DELETE_POINT_OF_CONTACT_SUCCESS]: (state, data) => {
    let { point_of_contact } = data
    if(!isEmpty(point_of_contact)){
      state.pointOfContacts = state.pointOfContacts.filter(poc => poc.uuid !== point_of_contact.uuid)
      state.pointOfContacts.push(point_of_contact)
    }
    state.loading = false
  },
  [DELETE_POINT_OF_CONTACT_ERROR]: (state, data) => {
    state.loading = false
  }

}

export function unpackPoc(poc, commit){
  if(!isEmpty(poc.poc_type_maps)){
    commit('pocTypeMap/GET_POC_TYPE_MAPS_SUCCESS', poc, {root: true})
    delete poc.poc_type_maps
  }
  return poc
}

export const pointOfContact = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
