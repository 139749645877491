import {
    GET_ISSUING_AUTHORITIES_REQUEST,
    GET_ISSUING_AUTHORITIES_SUCCESS,
    GET_ISSUING_AUTHORITIES_ERROR,
    GET_ISSUING_AUTHORITY_REQUEST,
    GET_ISSUING_AUTHORITY_SUCCESS,
    GET_ISSUING_AUTHORITY_ERROR,
    CREATE_ISSUING_AUTHORITY_REQUEST,
    CREATE_ISSUING_AUTHORITY_SUCCESS,
    CREATE_ISSUING_AUTHORITY_ERROR,
    EDIT_ISSUING_AUTHORITY_REQUEST,
    EDIT_ISSUING_AUTHORITY_SUCCESS,
    EDIT_ISSUING_AUTHORITY_ERROR,
    DELETE_ISSUING_AUTHORITY_REQUEST,
    DELETE_ISSUING_AUTHORITY_SUCCESS,
    DELETE_ISSUING_AUTHORITY_ERROR
} from "@/actions"

import {
    issuingAuthorityService
} from "@/services"

import validator from "validator"
import { isEmpty } from "@/utilities"

const state = {
    issuingAuthorities: [],
    issuingAuthority: {},
    loading: false,
    loadedOnce: false
}

const getters = {
    issuingAuthoritiesSelectOptions: state => {
        return state.issuingAuthorities.map(ia => {
            let abbr = isEmpty(ia.abbreviation) ? "" : `(${ia.abbreviation})`
            let label = (`${ia.name} ${abbr}`).trim()
            return { value: ia.uuid, label: label }
        }).sort((a, b) => a.label.localeCompare(b.label))
    },
    isFilter: state => uuid => state.issuingAuthorities.find(ia => ia.uuid == uuid)
}

const actions = {
    async getIssuingAuthorities({ commit }) {
        commit("GET_ISSUING_AUTHORITIES_REQUEST")
        const response = await issuingAuthorityService.getIssuingAuthorities();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_ISSUING_AUTHORITIES_SUCCESS", data)
                break
            default:
                commit("GET_ISSUING_AUTHORITIES_ERROR", data)
        }
        return response
    },
    async getIssuingAuthority({ commit }, params) {
        commit("GET_ISSUING_AUTHORITY_REQUEST")
        const response = await issuingAuthorityService.getIssuingAuthority(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_ISSUING_AUTHORITY_SUCCESS", data)
                break
            default:
                commit("GET_ISSUING_AUTHORITY_ERROR", data)
                break
        }
        return response
    },
    async createIssuingAuthority({ commit }, params) {
        commit("CREATE_ISSUING_AUTHORITY_REQUEST")
        const response = await issuingAuthorityService.createIssuingAuthority(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_ISSUING_AUTHORITY_SUCCESS", data)
                break
            default:
                commit("CREATE_ISSUING_AUTHORITY_ERROR", data)
        }
        return response
    },
    async editIssuingAuthority({ commit }, params) {
        commit("EDIT_ISSUING_AUTHORITY_REQUEST")
        const uuid = params.uuid
        const response = await issuingAuthorityService.editIssuingAuthority(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_ISSUING_AUTHORITY_SUCCESS", data)
                break
            default:
                commit("EDIT_ISSUING_AUTHORITY_ERROR", data)
        }
        return response
    },
    async deleteIssuingAuthority({ commit }, params) {
        commit("DELETE_ISSUING_AUTHORITY_REQUEST")
        const response = await issuingAuthorityService.deleteIssuingAuthority(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_ISSUING_AUTHORITY_SUCCESS", data)
                break
            default:
                commit("DELETE_ISSUING_AUTHORITY_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_ISSUING_AUTHORITIES_REQUEST]: state => {
        state.loading = true
    },
    [GET_ISSUING_AUTHORITIES_SUCCESS]: (state, data) => {
        const issuingAuthorities = [...data.issuing_authorities]
        state.issuingAuthorities = state.issuingAuthorities.concat(issuingAuthorities)
        state.loading = false
    },
    [GET_ISSUING_AUTHORITIES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_ISSUING_AUTHORITY_REQUEST]: state => {
        state.loading = true
    },
    [GET_ISSUING_AUTHORITY_SUCCESS]: (state, data) => {
        const issuingAuthority = { ...data.issuing_authority }
        state.issuingAuthority = issuingAuthority
        state.loading = false
    },
    [GET_ISSUING_AUTHORITY_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_ISSUING_AUTHORITY_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_ISSUING_AUTHORITY_SUCCESS]: (state, data) => {
        let newIssuingAuthority = { ...data.issuing_authority }
        state.issuingAuthorities.push(newIssuingAuthority)
        state.loading = false
    },
    [CREATE_ISSUING_AUTHORITY_ERROR]: state => {
        state.loading = false
    },

    [EDIT_ISSUING_AUTHORITY_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_ISSUING_AUTHORITY_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_ISSUING_AUTHORITY_ERROR]: state => {
        state.loading = false
    },

    [DELETE_ISSUING_AUTHORITY_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_ISSUING_AUTHORITY_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_ISSUING_AUTHORITY_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const issuingAuthority = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}