import {
    GET_LCPAAS_REQUEST,
    GET_LCPAAS_SUCCESS,
    GET_LCPAAS_ERROR,
    GET_LCPAA_REQUEST,
    GET_LCPAA_SUCCESS,
    GET_LCPAA_ERROR,
    CREATE_LCPAA_REQUEST,
    CREATE_LCPAA_SUCCESS,
    CREATE_LCPAA_ERROR,
    EDIT_LCPAA_REQUEST,
    EDIT_LCPAA_SUCCESS,
    EDIT_LCPAA_ERROR,
    DELETE_LCPAA_REQUEST,
    DELETE_LCPAA_SUCCESS,
    DELETE_LCPAA_ERROR,
    BROADCAST_DELETE_LCPAA_SUCCESS
} from "@/actions"

import { lcpaaService } from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    loading: false,
    loadedOnce: false,
    lcpaa: null,
    lcpaas: []
}

const getters = {
    lcpaasLoading: state => state.loading,
    lcpaasLoadedOnce: state => state.loadedOnce,
    lcpaas: state => state.lcpaas,
    associations: (state, getters, rootState, rootGetters) => lcUuid => state.lcpaas.filter(i => i.lead_coach_uuid === lcUuid).map(i => ({ ...i, pa: rootGetters['users/userByUuid'](i.program_assistant_uuid)}))
}

const actions = {
    async getLcpaas({ commit }) {
        commit("GET_LCPAAS_REQUEST")
        const response = await lcpaaService.getLcpaas();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_LCPAAS_SUCCESS", data)
                break;
            default:
                commit("GET_LCPAAS_ERROR", data)
        }
        return response
    },
    async createLcpaa({ commit, dispatch }, lcpaaData) {
        commit("CREATE_LCPAA_REQUEST")
        const response = await lcpaaService.createLcpaa(lcpaaData)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_LCPAA_SUCCESS", data)
                break
            case 403:
                commit("CREATE_LCPAA_ERROR", data)
                const { message } = data
                let messages = message ?? "NOT Authorized for this action."
                dispatch('alert/createAlertErrors', messages, { root: true })
                break
            default:
                commit("CREATE_LCPAA_ERROR", data)
        }
        return response
    },
    async getLcpaa({ commit, dispatch }, lcpaaUUID) {
        commit("GET_LCPAA_REQUEST")
        const response = await lcpaaService.getLcpaa(lcpaaUUID)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_LCPAA_SUCCESS", data)
                break
            default:
                commit("GET_LCPAA_ERROR", data)

        }
        return response
    },
    async editLcpaa({ commit }, params) {
        commit("EDIT_LCPAA_REQUEST");
        const { uuid } = params
        const response = await lcpaaService.editLcpaa(params, uuid);
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("EDIT_LCPAA_SUCCESS", data) : commit("GET_LCPAA_ERROR", data)
                break
            default:
                commit("GET_LCPAA_ERROR", data);
        }
        return response
    },
    async deleteLcpaa({ commit }, uuid) {
        commit("DELETE_LCPAA_REQUEST");
        const response = await lcpaaService.deleteLcpaa(uuid);
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("DELETE_LCPAA_SUCCESS", uuid) : commit("DELETE_LCPAA_ERROR", data)
                break
            default:
                commit("DELETE_LCPAA_ERROR", data)
        }
        return response
    }
}

const mutations = {
    [GET_LCPAAS_REQUEST]: state => {
        state.loading = true
    },
    [GET_LCPAAS_SUCCESS]: (state, data) => {
        const lcpaas = data.lcpaas
        state.lcpaas = lcpaas
        state.loading = false
        state.loadedOnce = true
    },
    [GET_LCPAAS_ERROR]: (state) => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [CREATE_LCPAA_REQUEST]: (state) => {
        state.loading = true
    },
    [CREATE_LCPAA_SUCCESS]: (state, data) => {
        const { lcpaa } = data
        state.lcpaas = addNewFilter(state.lcpaas, [lcpaa])
        state.loading = false
    },
    [CREATE_LCPAA_ERROR]: (state) => {
        state.loading = false
    },
    [GET_LCPAA_REQUEST]: state => {
        state.loading = true
    },
    [GET_LCPAA_SUCCESS]: (state, data) => {
        const { lcpaa } = data
        state.lcpaa = { ...lcpaa }
        state.loading = false
    },
    [GET_LCPAA_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [EDIT_LCPAA_REQUEST]: (state) => {
        state.loading = true
    },
    [EDIT_LCPAA_SUCCESS]: (state, data) => {

        state.loading = false
    },
    [EDIT_LCPAA_ERROR]: state => {
        state.loading = false
    },
    [DELETE_LCPAA_REQUEST]: (state) => {
        state.loading = true
    },
    [DELETE_LCPAA_SUCCESS]: (state, uuid) => {
        state.lcpaas = state.lcpaas.filter(g => g.uuid !== uuid)
        state.loading = false
    },
    [DELETE_LCPAA_ERROR]: state => {
        state.loading = false
    },
    [BROADCAST_DELETE_LCPAA_SUCCESS]: (state, data) => {
        const { lcpaa } = data
        state.lcpaas = state.lcpaas.filter(l => l.uuid !== lcpaa.uuid)
        state.loading = false
    },
}

export const lcpaa = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}