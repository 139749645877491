import {
    apiRequest,
    processInput
} from "@/utilities"

export const addressTypeService = {
    async getAddressTypes() {
        const getAddressTypesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/address-types", getAddressTypesRequest)
    },
    async getAddressType(uuid) {
        const getAddressTypeRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/address-types/${uuid}`, getAddressTypeRequest)
    },
    async createAddressType(params) {
        const createData = processInput(params)
        const createAddressTypeRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/address-types", createAddressTypeRequest)
    },
    async editAddressType(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editAddressTypeRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/address-types/${uuid}`, editAddressTypeRequest)
    },
    async deleteAddressType(uuid) {
        const deleteAddressTypeRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/address-types/${uuid}`, deleteAddressTypeRequest)
    }
}