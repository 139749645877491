import {
  GET_COACH_STATUSES_REQUEST,
  GET_COACH_STATUSES_SUCCESS,
  GET_COACH_STATUSES_ERROR,
  GET_COACH_STATUS_REQUEST,
  GET_COACH_STATUS_SUCCESS,
  GET_COACH_STATUS_ERROR,
  CREATE_COACH_STATUS_REQUEST,
  CREATE_COACH_STATUS_SUCCESS,
  CREATE_COACH_STATUS_ERROR,
  EDIT_COACH_STATUS_REQUEST,
  EDIT_COACH_STATUS_SUCCESS,
  EDIT_COACH_STATUS_ERROR,
  DELETE_COACH_STATUS_REQUEST,
  DELETE_COACH_STATUS_SUCCESS,
  DELETE_COACH_STATUS_ERROR,
} from "@/actions"

import {
  coachStatusService
} from "@/services"

const state = {
  coachStatuses: [],
  coachStatus: {},
  loading: false,
  loadedOnce: false,
  appCanSlugs: [
    "applicant",
    "candidate"
  ],
  // Coach Status slugs used for CoachSearch
  activeCoachSlugs: [
    "contracted",
    "pending",
    "active",
    "separated",
    "resigned"
  ],
  activeSearchStatuses: []
}

import { addNewFilter, isEmpty } from "@/utilities"

const getters = {
  cStatusLoadedOnce: state => state.loadedOnce,
  coachStatuses: (state) => {
    return state.coachStatuses.map((coachStatus) => {
      return { label: `${coachStatus.status.label} - ${coachStatus.label}`, value: coachStatus.uuid, full_data: coachStatus }
    })
  },
  coachStatus: (state, getters, rootState, rootGetters) => state.coachStatuses.find(s => s.coaches_uuid == rootGetters['coaches/coach']['uuid']),
  coachStatusByCoachUuid: state => coachUuid => state.coachStatuses.find(cs => cs.coaches_uuid == coachUuid),
  coachStatusByUuid: state => uuid => state.coachStatuses.find(cs => cs.uuid == uuid),
  activeCoaches: (state, getters, rootState, rootGetters) => {

    let activeSlugs = rootGetters['status/activeUuids'];
    let statusFilterByUuid = rootGetters['status/statusFilterByUuid'];
    let coachGetter = rootGetters["coaches/coachByUuid"]
    let educationGetter = rootGetters["coachEducation/coachEducationByCoachUuid"]
    let specialtiesGetter = rootGetters["coachSpecialties/coachSpecialtiesByCoachUuid"]

    return state.coachStatuses.filter(cs => {

      if (activeSlugs.includes(cs.statuses_uuid)) {
        let coach = coachGetter(cs.coaches_uuid)
        cs.coach = coach
        cs.coaches_uuid = coach.uuid
        cs.coach_display_name = coach.display_name
        cs.lead_coach_uuid = coach.lead_coach_uuid
        cs.specialties = specialtiesGetter(cs.coaches_uuid)
        cs.education = educationGetter(cs.coaches_uuid)
        cs.status = statusFilterByUuid(cs.statuses_uuid)

        return true
      }
      return false
    })
  },
  applicantCandidateCoaches: (state, getters, rootState, rootGetters) => {

    let canUuids = rootGetters['status/applicantCandidate'].map(s => s.uuid);
    let seInclude = [].concat(rootGetters['statusExplanation/includeApplicant'], rootGetters['statusExplanation/includeCandidate']);
    let applicationGetter = rootGetters["coachApplication/coachApplicationByCoachUuid"];
    let coachGetter = rootGetters["coaches/coachByUuid"];
    let educationGetter = rootGetters["coachEducation/coachEducationByCoachUuid"];
    let specialtiesGetter = rootGetters["coachSpecialties/coachSpecialtiesByCoachUuid"];
    let commentsGetter = rootGetters["comments/commentableFilter"];
    let screeningSessionsByCoachUuid = rootGetters["coachScreeningSession/screeningSessionsByCoachUuid"];

    return state.coachStatuses.filter(cs => canUuids.includes(cs.statuses_uuid) && seInclude.includes(cs.status_explanations_uuid)).map(cs => {
      let screening_sessions = screeningSessionsByCoachUuid(cs.coaches_uuid)
      screening_sessions = screening_sessions.filter(ss => !isEmpty(ss.screening_session))
      let coach = coachGetter(cs.coaches_uuid)
      let application = applicationGetter(cs.coaches_uuid);

      return {
        application: application?.application_submitted,
        specialties: specialtiesGetter(cs.coaches_uuid),
        education: educationGetter(cs.coaches_uuid),
        coach: coach?.display_name,
        dnua: coach?.dnua ?? false,
        coaches_uuid: coach?.uuid,
        status: cs,
        comments: commentsGetter(cs.coaches_uuid),
        screening_sessions
      }
    })
  }
}

const actions = {
  async getCoachStatuses({ commit }) {
    commit("GET_COACH_STATUSES_REQUEST")
    const response = await coachStatusService.getCoachStatuses();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_COACH_STATUSES_SUCCESS", data)
        break
      default:
        commit("GET_COACH_STATUSES_ERROR", data)
    }
    return response
  },
  async getCoachStatus({ commit }, params) {
    commit("GET_COACH_STATUS_REQUEST")
    const response = await coachStatusService.getCoachStatus(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_COACH_STATUS_SUCCESS", data)
        break
      default:
        commit("GET_COACH_STATUS_ERROR", data)
        break
    }
    return response
  },
  async createCoachStatus({ commit }, params) {
    commit("CREATE_COACH_STATUS_REQUEST")
    const response = await coachStatusService.createCoachStatus(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("CREATE_COACH_STATUS_SUCCESS", data)
        break
      default:
        commit("CREATE_COACH_STATUS_ERROR", data)
    }
    return response
  },
  async editCoachStatus({ commit, dispatch }, params) {
    commit("EDIT_COACH_STATUS_REQUEST")
    const uuid = params.uuid
    const response = await coachStatusService.editCoachStatus(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_COACH_STATUS_SUCCESS", data) : commit("EDIT_COACH_STATUS_ERROR", data)
        data.success ? dispatch('alert/createAlertSuccess', "Coach's Status updated!", { root: true }) : null
        break
      default:
        commit("EDIT_COACH_STATUS_ERROR", data)
    }
    return response
  },
  async deleteCoachStatus({ commit }, params) {
    commit("DELETE_COACH_STATUS_REQUEST")
    const response = await coachStatusService.deleteCoachStatus(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_COACH_STATUS_SUCCESS", data)
        break
      default:
        commit("DELETE_COACH_STATUS_DELETE", data)
    }
    return response
  }
}

const mutations = {
  [GET_COACH_STATUSES_REQUEST]: state => {
    state.loading = true
  },
  [GET_COACH_STATUSES_SUCCESS]: (state, data) => {
    const coachStatuses = data.coach_statuses
    state.coachStatuses = addNewFilter(state.coachStatuses, coachStatuses)
    state.loading = false
    state.loadedOnce = true
  },
  [GET_COACH_STATUSES_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_COACH_STATUS_REQUEST]: state => {
    state.loading = true
  },
  [GET_COACH_STATUS_SUCCESS]: (state, data) => {
    const { coach_status } = data
    state.coachStatuses = addNewFilter(state.coachStatuses, [coach_status])
    state.coachStatus = coach_status
    state.loading = false
  },
  [GET_COACH_STATUS_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_COACH_STATUS_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_COACH_STATUS_SUCCESS]: (state, data) => {
    const { coach_status } = data
    if(!isEmpty(coach_status)){
      state.coachStatuses = addNewFilter(state.coachStatuses, [coach_status])
    }
    state.loading = false
  },
  [CREATE_COACH_STATUS_ERROR]: state => {
    state.loading = false
  },

  [EDIT_COACH_STATUS_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_COACH_STATUS_SUCCESS]: (state, data) => {
    const { coach_status } = data
    state.coachStatuses = addNewFilter(state.coachStatuses, [coach_status]);
    // state.coachStatuses = state.coachStatuses.map(cs => cs.uuid == coach_status.uuid ? coach_status : cs)
    state.loading = false
  },
  [EDIT_COACH_STATUS_ERROR]: (state, data) => {
    state.loading = false
  },

  [DELETE_COACH_STATUS_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_COACH_STATUS_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [DELETE_COACH_STATUS_ERROR]: (state, data) => {
    state.loading = false
  }
}



export const coachStatus = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
