export const GET_COURSE_LEVELS_REQUEST = "GET_COURSE_LEVELS_REQUEST"
export const GET_COURSE_LEVELS_SUCCESS = "GET_COURSE_LEVELS_SUCCESS"
export const GET_COURSE_LEVELS_ERROR = "GET_COURSE_LEVELS_ERROR"
export const GET_COURSE_LEVEL_REQUEST = "GET_COURSE_LEVEL_REQUEST"
export const GET_COURSE_LEVEL_SUCCESS = "GET_COURSE_LEVEL_SUCCESS"
export const GET_COURSE_LEVEL_ERROR = "GET_COURSE_LEVEL_ERROR"
export const CREATE_COURSE_LEVEL_REQUEST = "CREATE_COURSE_LEVEL_REQUEST"
export const CREATE_COURSE_LEVEL_SUCCESS = "CREATE_COURSE_LEVEL_SUCCESS"
export const CREATE_COURSE_LEVEL_ERROR = "CREATE_COURSE_LEVEL_ERROR"
export const EDIT_COURSE_LEVEL_REQUEST = "EDIT_COURSE_LEVEL_REQUEST"
export const EDIT_COURSE_LEVEL_SUCCESS = "EDIT_COURSE_LEVEL_SUCCESS"
export const EDIT_COURSE_LEVEL_ERROR = "EDIT_COURSE_LEVEL_ERROR"
export const DELETE_COURSE_LEVEL_REQUEST = "DELETE_COURSE_LEVEL_REQUEST"
export const DELETE_COURSE_LEVEL_SUCCESS = "DELETE_COURSE_LEVEL_SUCCESS"
export const DELETE_COURSE_LEVEL_ERROR = "DELETE_COURSE_LEVEL_ERROR"