import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const commentService = {
    async getComments(params) {
        let getParams = processQueryParams(params)
        const getCommentsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/comments", getCommentsRequest)
    },
    async getComment(uuid) {
        const getCommentRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/comments/${uuid}`, getCommentRequest)
    },
    async createComment(params) {
        const data = processInput(params)
        const createCommentRequest = {
            method: "POST",
            data: data
        }
        return await apiRequest("/api/comments", createCommentRequest)
    },
    async editComment(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCommentRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/comments/${uuid}`, editCommentRequest)
    },
    async deleteComment(uuid) {
        const deleteCommentRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/comments/${uuid}`, deleteCommentRequest)
    }
}