import {
    apiRequest,
    processInput
} from "@/utilities"

export const statusExplanationService = {
    async getStatusExplanations() {
        const getStatusExplanationsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/status-explanations", getStatusExplanationsRequest)
    },
    async getStatusExplanation(uuid) {
        const getStatusExplanationRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/status-explanations/${uuid}`, getStatusExplanationRequest)
    },
    async createStatusExplanation(params) {
        const createData = processInput(params)
        const createStatusExplanationRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/status-explanations", createStatusExplanationRequest)
    },
    async editStatusExplanation(data) {
        const { uuid } = data;
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editStatusExplanationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/status-explanations/${uuid}`, editStatusExplanationRequest)
    },
    async deleteStatusExplanation(uuid) {
        const deleteStatusExplanationRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/status-explanations/${uuid}`, deleteStatusExplanationRequest)
    }
}