import {
    CREATE_USER_GROUP_REQUEST,
    CREATE_USER_GROUP_SUCCESS,
    CREATE_USER_GROUP_ERROR,
    DELETE_USER_GROUP_REQUEST,
    DELETE_USER_GROUP_SUCCESS,
    DELETE_USER_GROUP_ERROR

} from "@/actions"

import { userGroupService } from "@/services"

const state = {
    loading: false
}

const getters = {

}

const actions = {
    async createUserGroup({ commit }, params) {
        commit("CREATE_USER_GROUP_REQUEST")
        const response = await userGroupService.createUserGroup(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_USER_GROUP_SUCCESS") : commit("CREATE_USER_GROUP_ERROR")
                break
            default:
                commit("CREATE_USER_GROUP_ERROR")
        }
        return response
    },

    async deleteUserGroup({ commit }, params) {
        commit("DELETE_USER_GROUP_REQUEST");
        const response = await userGroupService.deleteUserGroup(params);
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("DELETE_USER_GROUP_SUCCESS") : commit("DELETE_USER_GROUP_ERROR")
                break
            default:
                commit("DELETE_USER_GROUP_ERROR")
        }
        return response
    }
}

const mutations = {

    [CREATE_USER_GROUP_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_USER_GROUP_SUCCESS]: state => {
        state.loading = false
    },
    [CREATE_USER_GROUP_ERROR]: state => {
        state.loading = false
    },

    [DELETE_USER_GROUP_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_USER_GROUP_SUCCESS]: state => {
        state.loading = false
    },
    [DELETE_USER_GROUP_ERROR]: state => {
        state.loading = false
    }
}

export const userGroup = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}