import {
    GET_UNIVERSITY_RFPS_REQUEST,
    GET_UNIVERSITY_RFPS_SUCCESS,
    GET_UNIVERSITY_RFPS_ERROR,
    GET_UNIVERSITY_RFP_REQUEST,
    GET_UNIVERSITY_RFP_SUCCESS,
    GET_UNIVERSITY_RFP_ERROR,
    CREATE_UNIVERSITY_RFP_REQUEST,
    CREATE_UNIVERSITY_RFP_SUCCESS,
    CREATE_UNIVERSITY_RFP_ERROR,
    EDIT_UNIVERSITY_RFP_REQUEST,
    EDIT_UNIVERSITY_RFP_SUCCESS,
    EDIT_UNIVERSITY_RFP_ERROR,
    DELETE_UNIVERSITY_RFP_REQUEST,
    DELETE_UNIVERSITY_RFP_SUCCESS,
    BROADCAST_DELETE_UNIVERSITY_RFP_SUCCESS,
    DELETE_UNIVERSITY_RFP_ERROR
} from "@/actions"

import {
    universityRfpsService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    universityRfps: [],
    universityRfp: {},
    loading: false
}

const getters = {
    allUniversityRfp: state => state.universityRfps,
    universityRfpFilter: state => uuid => state.universityRfps.find(upm => upm.contract_uuid == uuid)
}

const actions = {
    async getUniversityRfps({ commit }) {
        commit("GET_UNIVERSITY_RFPS_REQUEST")
        const response = await universityRfpsService.getUniversityRfps();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_RFPS_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_RFPS_ERROR", data)
        }
        return response
    },
    async getUniversityRfp({ commit }, params) {
        commit("GET_UNIVERSITY_RFP_REQUEST")
        const response = await universityRfpsService.getUniversityRfp(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_RFP_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_RFP_ERROR", data)
                break
        }
        return response
    },
    async createUniversityRfp({ commit }, params) {
        commit("CREATE_UNIVERSITY_RFP_REQUEST")
        const response = await universityRfpsService.createUniversityRfp(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_UNIVERSITY_RFP_SUCCESS", data)
                break
            default:
                commit("CREATE_UNIVERSITY_RFP_ERROR", data)
        }
        return response
    },
    async editUniversityRfp({ commit }, params) {
        commit("EDIT_UNIVERSITY_RFP_REQUEST")
        const response = await universityRfpsService.editUniversityRfp(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_UNIVERSITY_RFP_SUCCESS", data)
                break
            default:
                commit("EDIT_UNIVERSITY_RFP_ERROR", data)
        }
        return response
    },
    async deleteUniversityRfp({ commit }, params) {
        commit("DELETE_UNIVERSITY_RFP_REQUEST")
        const response = await universityRfpsService.deleteUniversityRfp(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_UNIVERSITY_RFP_SUCCESS", data)
                break
            default:
                commit("DELETE_UNIVERSITY_RFP_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_UNIVERSITY_RFPS_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_RFPS_SUCCESS]: (state, data) => {
        const { university_rfps } = data
        if(!isEmpty(university_rfps)){
            state.universityRfps = addNewFilter(state.universityRfps, university_rfps)
        }
        state.loading = false

    },
    [GET_UNIVERSITY_RFPS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_UNIVERSITY_RFP_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_RFP_SUCCESS]: (state, data) => {
        const { university_rfps, university_rfp } = data
        if(!isEmpty(university_rfps)){
            state.universityRfps = addNewFilter(state.universityRfps, university_rfps)
        }
        if(!isEmpty(university_rfp)){
            state.universityRfps = addNewFilter(state.universityRfps, [university_rfp])
        }
        state.loading = false
    },
    [GET_UNIVERSITY_RFP_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_UNIVERSITY_RFP_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_UNIVERSITY_RFP_SUCCESS]: (state, data) => {
        const { university_rfp } = data
        if(!isEmpty(university_rfp)){
            state.universityRfps = addNewFilter(state.universityRfps, [university_rfp])
        }
        state.loading = false
    },
    [CREATE_UNIVERSITY_RFP_ERROR]: state => {
        state.loading = false
    },

    [EDIT_UNIVERSITY_RFP_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_UNIVERSITY_RFP_SUCCESS]: (state, data) => {
        const { university_rfp } = data
        if(!isEmpty(university_rfp)){
            state.universityRfps = state.universityRfps.filter(up => up.uuid != university_rfp.uuid)
            state.universityRfps = addNewFilter(state.universityRfps, [university_rfp])
        }
        state.loading = false
    },
    [EDIT_UNIVERSITY_RFP_ERROR]: state => {
        state.loading = false
    },

    [DELETE_UNIVERSITY_RFP_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_UNIVERSITY_RFP_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [BROADCAST_DELETE_UNIVERSITY_RFP_SUCCESS]: (state, data) => {
        const { university_rfp } = data
        if(!isEmpty(university_rfp)){
            state.universityRfps = state.universityRfps.filter(up => up.uuid != university_rfp.uuid)
        }
        state.loading = false
    },
    [DELETE_UNIVERSITY_RFP_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const universityRfp = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}