import {
	apiRequest,
	processInput,
	processQueryParams
} from "@/utilities"


export const addressService = {
	async getAddresses(params) {
		let getParams = processQueryParams(params)
		const getAddresssRequest = {
			method: "GET",
			params: getParams
		}
		return await apiRequest("/api/addresses", getAddresssRequest)
	},
	async getAddress(uuid) {
		const getAddressRequest = {
			method: "GET"
		}
		return await apiRequest(`/api/addresses/${uuid}`, getAddressRequest)
	},
	async createAddress(params) {
		const createData = processInput(params)
		const createAddressRequest = {
			method: "POST",
			data: createData
		}
		return await apiRequest("/api/addresses", createAddressRequest)
	},
	async editAddress(data, uuid) {
		let editData = processInput(data)
		editData.append("_method", "PATCH")
		const editAddressRequest = {
			method: "POST",
			data: editData
		}
		return await apiRequest(`/api/addresses/${uuid}`, editAddressRequest)
	},
	async deleteAddress(uuid) {
		const deleteAddressRequest = {
			method: "DELETE"
		}
		return await apiRequest(`/api/addresses/${uuid}`, deleteAddressRequest)
	},
	async autocomplete(params) {
		const autocompleteRequest = {
			method: "GET",
			params: processQueryParams(params)
		}
		return await apiRequest("/api/address-auto-complete", autocompleteRequest)
	}
}