export const GET_GROUPS_REQUEST = "GET_GROUPS_REQUEST"
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS"
export const GET_GROUPS_ERROR = "GET_GROUPS_ERROR"
export const GET_GROUP_REQUEST = "GET_GROUP_REQUEST"
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS"
export const GET_GROUP_ERROR = "GET_GROUP_ERROR"
export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST"
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS"
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR"
export const EDIT_GROUP_REQUEST = "EDIT_GROUP_REQUEST"
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS"
export const EDIT_GROUP_ERROR = "EDIT_GROUP_ERROR"
export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST"
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS"
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR"
export const BROADCAST_DELETE_GROUP_SUCCESS = "BROADCAST_DELETE_GROUP_SUCCESS"