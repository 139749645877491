export const reportsGeneralRoutes = {
  path: "general",
  name: "reports gereral",
  meta: {
    authRequired: true,
    allowedRoles: [
      'super-admin'
    ],
    label: "General Reports",
  },
  component: () => import("@/pages/reports/general/ReportsGeneralIndex.vue"),
  children: [
    {
      path: "coaches-by-university",
      name: "report general coaches-by-university",
      meta: {
        label: "Coaches By University",
      },
      // component: () => import("@/pages/reports/general/CoachesByUniversity.vue"),
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "courses-by-university-program",
      name: "report general courses-by-university-program",
      meta: {
        label: "Courses By University/Program",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "coaches-status",
      name: "report general coaches-status",
      meta: {
        label: "Coaches Status",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "degrees-coaches",
      name: "report general degrees-coaches",
      meta: {
        label: "Degrees for all Coaches",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "degrees-university-program",
      name: "report general degrees-university-program",
      meta: {
        label: "Degrees by University for Program Documentation",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "expiring-university-contracts",
      name: "report general expiring-university-contracts",
      meta: {
        label: "Expired Certifications",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "missing-official-transcripts",
      name: "report general missing-official-transcripts",
      meta: {
        label: "Coaches Missing Official Transcripts",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "expired-certifications",
      name: "report general expired-certifications",
      meta: {
        label: "Expired Certifications",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
  ]
};