export const GET_PERFORMANCE_NOTES_REQUEST = "GET_PERFORMANCE_NOTES_REQUEST"
export const GET_PERFORMANCE_NOTES_SUCCESS = "GET_PERFORMANCE_NOTES_SUCCESS"
export const GET_PERFORMANCE_NOTES_ERROR = "GET_PERFORMANCE_NOTES_ERROR"
export const GET_PERFORMANCE_NOTE_REQUEST = "GET_PERFORMANCE_NOTE_REQUEST"
export const GET_PERFORMANCE_NOTE_SUCCESS = "GET_PERFORMANCE_NOTE_SUCCESS"
export const GET_PERFORMANCE_NOTE_ERROR = "GET_PERFORMANCE_NOTE_ERROR"
export const CREATE_PERFORMANCE_NOTE_REQUEST = "CREATE_PERFORMANCE_NOTE_REQUEST"
export const CREATE_PERFORMANCE_NOTE_SUCCESS = "CREATE_PERFORMANCE_NOTE_SUCCESS"
export const CREATE_PERFORMANCE_NOTE_ERROR = "CREATE_PERFORMANCE_NOTE_ERROR"
export const EDIT_PERFORMANCE_NOTE_REQUEST = "EDIT_PERFORMANCE_NOTE_REQUEST"
export const EDIT_PERFORMANCE_NOTE_SUCCESS = "EDIT_PERFORMANCE_NOTE_SUCCESS"
export const EDIT_PERFORMANCE_NOTE_ERROR = "EDIT_PERFORMANCE_NOTE_ERROR"
export const DELETE_PERFORMANCE_NOTE_REQUEST = "DELETE_PERFORMANCE_NOTE_REQUEST"
export const DELETE_PERFORMANCE_NOTE_SUCCESS = "DELETE_PERFORMANCE_NOTE_SUCCESS"
export const DELETE_PERFORMANCE_NOTE_ERROR = "DELETE_PERFORMANCE_NOTE_ERROR"