export const GET_ADDRESSES_REQUEST = "GET_ADDRESSES_REQUEST"
export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS"
export const GET_ADDRESSES_ERROR = "GET_ADDRESSES_ERROR"
export const GET_ADDRESS_REQUEST = "GET_ADDRESS_REQUEST"
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS"
export const GET_ADDRESS_ERROR = "GET_ADDRESS_ERROR"
export const CREATE_ADDRESS_REQUEST = "CREATE_ADDRESS_REQUEST"
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS"
export const CREATE_ADDRESS_ERROR = "CREATE_ADDRESS_ERROR"
export const VERIFY_ADDRESS_REQUEST = "VERIFY_ADDRESS_REQUEST"
export const VERIFY_ADDRESS_SUCCESS = "VERIFY_ADDRESS_SUCCESS"
export const VERIFY_ADDRESS_ERROR = "VERIFY_ADDRESS_ERROR"
export const EDIT_ADDRESS_REQUEST = "EDIT_ADDRESS_REQUEST"
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS"
export const EDIT_ADDRESS_ERROR = "EDIT_ADDRESS_ERROR"
export const DELETE_ADDRESS_REQUEST = "DELETE_ADDRESS_REQUEST"
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS"
export const DELETE_ADDRESS_ERROR = "DELETE_ADDRESS_ERROR"
export const CLEAR_ADDRESS_STATE = "CLEAR_ADDRESS_STATE"