import {
    apiRequest,
    processInput,
    addNewFilter,
    processQueryParams,
    isEmpty,
} from "@/utilities"
import { unpackCourseStartFaculty } from "@/modules"

const state = {
    transferStudentLogs: [],
}
function mapLog(state, rootGetters, data, withSibling) {
    if(isEmpty(data)) return {}
    const transferStudentLogs = state.transferStudentLogs
    const caByUuid = rootGetters['coachAssignment/coachAssignmentsByUuid']
    const csFacultyByUuid = rootGetters['courseStartFaculty/csFacultyByUuid']
    const coachByUuid = rootGetters['coaches/coachByUuid']
    let target = {}, targetLabel = null;
    if(data.transferable_type == 'App\\Models\\CourseStartFaculty'){
        targetLabel = 'Faculty'
        let faculty = csFacultyByUuid(data.transferable_uuid)
        if(!isEmpty(faculty)){
            target = {
                uuid: faculty.uuid || null,
                label: faculty.display_name
            }
        }
    }else{
        targetLabel = 'Coach'
        let ca = caByUuid(data.transferable_uuid)
        if(!isEmpty(ca)){
            let coach = coachByUuid(ca.coaches_uuid)
            if(!isEmpty(coach)){
                target = {
                    uuid: coach.uuid || null,
                    label: coach.display_name,
                    is_replacement: !isEmpty(ca.replacement_uuid)
                }
            }
        }else if(!isEmpty(data.coaches_uuid)){
            let coach = coachByUuid(data.coaches_uuid)
            if(!isEmpty(coach)){
                target = {
                    uuid: coach.uuid || null,
                    label: coach.display_name
                }
            }
        }
    }
    let siblings = []
    if(withSibling === true){
        siblings = transferStudentLogs.filter(i => {
            if(data.transfer_key == i.transfer_key && data.uuid != i.uuid){
                let isPositive = data.students > 0
                if(isPositive){
                    return i.students < 0
                }
                let isNegative = data.students < 0
                if(isNegative){
                    return i.students > 0
                }
            }
            return false
        }).map(i => mapLog(state, rootGetters, i))
    }
    return {
        ...data,
        siblings,
        target,
        targetLabel,
    }
}
const getters = {
    transferStudentLogs: state => state.transferStudentLogs,
    transferStudentLogsByTransferableUuid: (state, getters, rootState, rootGetters) => (uuid, withSibling = false, forReplace = false) => state.transferStudentLogs.filter(i => {
        if(forReplace == true){
            return i.transferable_uuid == uuid && i.is_replace == 1 && i.students < 0
        }
        return i.transferable_uuid == uuid
    }).map(i => mapLog(state, rootGetters, i, withSibling)),
    transferStudentLogsByUuid: (state, getters, rootState, rootGetters) => uuid => {
        let tl = state.transferStudentLogs.find(i =>  i.uuid == uuid)
        return mapLog(state, rootGetters, tl, false)
    },
    transferStudentLogsByTransferKey: (state, getters, rootState, rootGetters) => transfer_key => {
        return state.transferStudentLogs.filter(i =>  i.transfer_key == transfer_key).map(i => mapLog(state, rootGetters, i, true))
    },
}

const actions = {
    async getTransferStudentLogs({ commit }, params) {
        let getParams = processQueryParams(params)
        const getAllReq = {
            method: "GET",
            params: getParams
        }
        const response = await apiRequest(`/api/transfer-student-logs`, getAllReq)
        const { status, data } = response
        switch (status) {
            case 200:
                if(data.success){
                    const transfer_student_logs = data.transfer_student_logs.map(log => unpackTransferLog(log, commit))
                    commit("GET_TRANSFER_STUDENTS_SUCCESS", {transfer_student_logs})
                }
                break
            default:
                break
        }
        return response
    },
    async getTransferStudentLog({ commit }, uuid) {
        const getAllReq = {
            method: "GET",
        }
        const response = await apiRequest(`/api/transfer-student-logs/${uuid}`, getAllReq)
        const { status, data } = response
        switch (status) {
            case 200:
                if(data.success){
                    const transfer_student_logs = data.transfer_student_logs.map(log => unpackTransferLog(log, commit))
                    commit("GET_TRANSFER_STUDENTS_SUCCESS", {transfer_student_logs})
                }
                break
            default:
                break
        }
        return response
    },
}

const mutations = {
    GET_TRANSFER_STUDENTS_SUCCESS: (state, data) => {
        const transferStudentLogs = data.transfer_student_logs
        state.transferStudentLogs = addNewFilter(state.transferStudentLogs, transferStudentLogs, data.force || false)
    },
    GET_TRANSFER_STUDENT_SUCCESS: (state, data) => {
        const transferStudentLog = data.transfer_student_log
        state.transferStudentLogs = addNewFilter(state.transferStudentLogs, [transferStudentLog], true)
    },
    CREATE_TRANSFER_STUDENT_SUCCESS: (state, data) => {
        const transferStudentLog = data.transfer_student_log
        state.transferStudentLogs = addNewFilter(state.transferStudentLogs, [transferStudentLog])
    },
}

export function unpackTransferLog(log, commit) {

    if (!isEmpty(log.faculty)) {
        let faculty = unpackCourseStartFaculty(log.faculty, commit)
        commit('courseStartFaculty/GET_COURSE_START_FACULTYS_SUCCESS', { course_start_facultys: [faculty]}, { root: true })
    }
    if (!isEmpty(log.coach)) {
        let coaches = [log.coach]
        commit('coaches/GET_COACHES_SUCCESS', { coaches }, { root: true })
    }

    delete log.faculty
    delete log.coach
    return log
}

export const transferStudentLog = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
