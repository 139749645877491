export const GET_COURSE_START_SECTIONS_REQUEST = "GET_COURSE_START_SECTIONS_REQUEST"
export const GET_COURSE_START_SECTIONS_SUCCESS = "GET_COURSE_START_SECTIONS_SUCCESS"
export const GET_COURSE_START_SECTIONS_ERROR = "GET_COURSE_START_SECTIONS_ERROR"
export const GET_COURSE_START_SECTION_REQUEST = "GET_COURSE_START_SECTION_REQUEST"
export const GET_COURSE_START_SECTION_SUCCESS = "GET_COURSE_START_SECTION_SUCCESS"
export const GET_COURSE_START_SECTION_ERROR = "GET_COURSE_START_SECTION_ERROR"
export const CREATE_COURSE_START_SECTION_REQUEST = "CREATE_COURSE_START_SECTION_REQUEST"
export const CREATE_COURSE_START_SECTION_SUCCESS = "CREATE_COURSE_START_SECTION_SUCCESS"
export const CREATE_COURSE_START_SECTION_ERROR = "CREATE_COURSE_START_SECTION_ERROR"
export const EDIT_COURSE_START_SECTION_REQUEST = "EDIT_COURSE_START_SECTION_REQUEST"
export const EDIT_COURSE_START_SECTION_SUCCESS = "EDIT_COURSE_START_SECTION_SUCCESS"
export const EDIT_COURSE_START_SECTION_ERROR = "EDIT_COURSE_START_SECTION_ERROR"
export const DELETE_COURSE_START_SECTION_REQUEST = "DELETE_COURSE_START_SECTION_REQUEST"
export const DELETE_COURSE_START_SECTION_SUCCESS = "DELETE_COURSE_START_SECTION_SUCCESS"
export const DELETE_COURSE_START_SECTION_ERROR = "DELETE_COURSE_START_SECTION_ERROR"