import {
  GET_UNIVERSITIES_REQUEST,
  GET_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITIES_LIMITED_DATA_SUCCESS,
  GET_UNIVERSITIES_ERROR,
  GET_UNIVERSITY_REQUEST,
  GET_UNIVERSITY_SUCCESS,
  GET_UNIVERSITY_ERROR,
  CREATE_UNIVERSITY_REQUEST,
  CREATE_UNIVERSITY_SUCCESS,
  CREATE_UNIVERSITY_ERROR,
  EDIT_UNIVERSITY_REQUEST,
  EDIT_UNIVERSITY_SUCCESS,
  EDIT_UNIVERSITY_ERROR,
  DELETE_UNIVERSITY_REQUEST,
  DELETE_UNIVERSITY_SUCCESS,
  DELETE_UNIVERSITY_ERROR,
  UPDATE_UNIVERSITY_STATUS,
  CLEAR_UNIVERSITY
} from "@/actions"

import {
  universityService
} from "@/services"

import { isEmpty, addNewFilter, decodeLaravelValidationErrors } from "@/utilities"
import { unpackProgramData, unpackUFS, unpackContract, unpackPoc } from "@/modules"

const state = {
  universities: [],
  university: {},
  loading: false,
  haveAllUniversities: false,
  universityLoading: false,
  loadedOnce: false,
  loadedUniversities: [],
  partnershipCommonDataLoadedOnce: false,
  pastWasLoaded: false
}

const getters = {
  universitiesLoading: state => state.loading,
  universitiesLoadedOnce: state => state.loadedOnce,
  universityLoading: state => state.universityLoading,
  pastWasLoaded: state => state.pastWasLoaded,
  universities: state => state.universities,
  universitiesSorted: state => state.universities.sort((a, b) => a.name.localeCompare(b.name)),
  university: state => state.university,
  universitiesSelectOptions: state => state.universities.map((university) => {
    return {
      label: university.name,
      value: university.uuid,
      attrs: {
        'data-abbreviation': university.abbreviation ?? null
      }
    }
  }).sort((a, b) => a.label.localeCompare(b.label)),

  /**
   *
   * @param {*} state
   * @returns {}
   */
  universityFilter: state => uuid => state.universities.find(univ => univ.uuid == uuid),

  onboardingUniversities: state => state.universities.filter(univ => {
    return isEmpty(univ.status) || ['inquiry', 'negotiation'].includes(univ.status.status_university.slug)
  }),

  partnershipCommonDataLoaded: state => state.partnershipCommonDataLoadedOnce,

  programSelectOptions: (state, getters, rootState, rootGetters) => {
    if (isEmpty(state.university)) return []
    if (isEmpty(rootGetters['universityProgram/universityPrograms'](state.university))) return []
    return rootGetters['universityProgram/universityPrograms'](state.university).map(p => ({ value: p.uuid, label: p.display_name }))
  },

  findProgramSelectOptions: (state, getters, rootState, rootGetters) => univUuid => {
    return rootGetters['universityProgram/uniProByUniUuid'](univUuid).map(p => ({ value: p.uuid, label: p.display_name }))
  }
}

export function unpackUniversity(university, commit) {
  Object.keys(university).forEach(key => {
    let data = {}
    let value = university[key]
    if (!isEmpty(value)) data.success = true
    switch (key) {
      case "status":
        data['university_statuses'] = isEmpty(value) ? [] : [value]
        commit('universityStatus/GET_UNIVERSITY_STATUSES_SUCCESS', data, { root: true })
        break
      case "contracts":
        let contracts = value.map(i => unpackContract(i, commit))
        commit('contract/GET_CONTRACTS_SUCCESS', { contracts }, { root: true })
        break
      case "point_of_contacts":
        data[key] = value
        const point_of_contacts = value.map(i => unpackPoc(i, commit))
        commit('pointOfContact/GET_POINT_OF_CONTACTS_SUCCESS', {point_of_contacts}, { root: true })
        break
      case "contract_ios":
        data['data'] = isEmpty(value) ? [] : value
        commit('contractIO/GET_CONTRACT_IOS_SUCCESS', data, { root: true })
        if (!isEmpty(value)) {
          value.forEach(cio => {
            if (!isEmpty(cio.contract_price_types)) {
              data.contract_price_types = cio.contract_price_types
              commit('contractPriceType/GET_CONTRACT_PRICE_TYPES_SUCCESS', data, { root: true })
            }
          })
        }
        break
      case "combined_was":
        data.combined_wass = value
        commit("combinedWAS/GET_COMBINED_WASS_SUCCESS", data, { root: true })
        delete university[key];
        break
      case "combined_was_groups":
        data.combined_groups = value;

        data.combined_groups = data.combined_groups.map(g => {
          let items = g.items ?? [];
          commit("combinedWASGroupItem/GET_COMBINED_WAS_GROUP_ITEMS_SUCCESS", { items }, { root: true });
          delete g.items;
          return g;
        });

        commit("combinedWASGroup/GET_COMBINED_WAS_GROUPS_SUCCESS", data, { root: true });
        delete university[key];
        break
      case "regional_accreditors":
        data['data'] = isEmpty(value) ? [] : value
        commit('universityRegionalAccreditor/GET_UNIVERSITY_REGIONAL_ACCREDITORS_SUCCESS', data, { root: true })
        delete university[key]
        break
      case "opms":
        data['data'] = isEmpty(value) ? [] : value
        commit('universityOpm/GET_UNIVERSITY_OPMS_SUCCESS', data, { root: true })
        break
      case "past_was":
        data['working_assignment_sheets'] = value;
        commit('workingAssignmentSheet/GET_WORKING_ASSIGNMENT_SHEETS_SUCCESS', data, { root: true })
        break;
      case "rfps":
        data['university_rfps'] = isEmpty(value) ? [] : [value]
        commit('universityRfp/GET_UNIVERSITY_RFPS_SUCCESS', data, { root: true })
        break
      case "documents":
        data.documents = value
        commit('document/GET_DOCUMENTS_SUCCESS', data, { root: true })
        delete university[key]
        break
      case "comments":
        data.comments = value
        commit('comments/GET_COMMENTS_SUCCESS', data, { root: true })
        delete university[key]
        break
      case "prospect":
        data.university_prospects = isEmpty(value) ? [] : [value]
        commit('universityProspect/GET_UNIVERSITY_PROSPECTS_SUCCESS', data, { root: true })

        if (!isEmpty(value) && !isEmpty(value.meetings)) {
          data.university_prospect_meetings = value.meetings
          commit('universityProspectMeeting/GET_UNIVERSITY_PROSPECT_MEETINGS_SUCCESS', data, { root: true })
        }

        if (!isEmpty(value) && !isEmpty(value.documents)) {
          data.university_prospect_documents = value.documents
          commit('universityProspectDocument/GET_UNIVERSITY_PROSPECT_DOCUMENTS_SUCCESS', data, { root: true })
        }

        break
      case "programs":
        data.university_programs = isEmpty(value) ? [] : value
        data.university_programs = data.university_programs.map(up => {
          return unpackProgramData(up, commit)
        })
        commit("universityProgram/GET_UNIVERSITY_PROGRAMS_SUCCESS", data, { root: true })
        delete university[key]
        break
      case "shared_queues":
        data.shared_queues = value;

        data.shared_queues = data.shared_queues.map((sq) => {
          let items = sq.items ?? [];
          commit("sharedQueueItem/GET_SHARED_QUEUE_ITEMS_SUCCESS", { items }, { root: true });
          delete sq.items;
          return sq;
        });

        commit('sharedQueue/GET_SHARED_QUEUES_SUCCESS', data, { root: true })
        delete university[key]
        break;
      case "faculty_contracts":
        data.faculty_contracts = isEmpty(value) ? [] : value
        const university_faculty_sources = data.faculty_contracts.map(ufs => {
          return unpackUFS(ufs, commit)
        })
        commit("universityFacultySource/GET_UNI_FACULTY_SOURCES_SUCCESS", {university_faculty_sources}, { root: true })
        delete university[key]
        break
      case "lms":
        data.university_lms = isEmpty(value) ? [] : value
        commit('universityLMS/GET_UNIVERSITY_LMSS_SUCCESS', data, { root: true })
        break
      case "census":
        data.university_census = isEmpty(value) ? [] : value
        commit('universityCensus/GET_UNIVERSITY_CENSUSES_SUCCESS', data, { root: true })
        break
      case "procedure":
        data.university_procedure = isEmpty(value) ? [] : [value]
        commit('universityProcedure/GET_UNIVERSITY_PROCEDURES_SUCCESS', data, { root: true })
        break;
      case "users":
        data.users = isEmpty(value) ? [] : value
        commit("users/GET_USERS_SUCCESS", data, { root: true })
        break
    }
  })
  return university
}

const actions = {
  async getUniversities({ state, commit }, params) {
    if (params?.all && !state.haveAllUniversities) {
      commit("GET_UNIVERSITIES_REQUEST")
      const response = await universityService.getUniversities(params);
      const { status, data } = response
      switch (status) {
        case 200:
          if (data.success) {
            data.universities = data.universities.map(u => unpackUniversity(u, commit));
            commit("GET_UNIVERSITIES_SUCCESS", { ...data, ...params })
          }

          if (data.error) {
            commit("GET_UNIVERSITIES_ERROR")
          }
          break
        default:
          commit("GET_UNIVERSITIES_ERROR", data)
      }
      return response;
    }

    return Promise.resolve({
      success: true
    })
  },
  async getUniversity({ state, commit }, params) {
    const { uuid, _forceGet } = params;
    let uniLoaded = state.loadedUniversities.find(u => u == uuid);
    if (!isEmpty(_forceGet)) {
      if (_forceGet) {
        uniLoaded = false
      }
    }
    uniLoaded = false
    if (!uniLoaded && !state.loading) {
      commit("GET_UNIVERSITY_REQUEST")
      const response = await universityService.getUniversity(params, uuid)
      const { status, data } = response
      switch (status) {
        case 200:
          data.success ? commit("GET_UNIVERSITY_SUCCESS", data) : commit("GET_UNIVERSITY_ERROR", data)
          data.success ? unpackUniversity(data.university, commit) : null
          break
        default:
          commit("GET_UNIVERSITY_ERROR", data)
          break
      }
      return response
    }

    let uni = state.universities.find(u => u.uuid == uuid)
    if (!isEmpty(uni)) {
      commit("GET_UNIVERSITY_SUCCESS", { university: uni })
    }

    return uni
  },
  async createUniversity({ commit }, params) {
    commit("CREATE_UNIVERSITY_REQUEST")
    const response = await universityService.createUniversity(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("CREATE_UNIVERSITY_SUCCESS", data)
        break
      default:
        commit("CREATE_UNIVERSITY_ERROR", data)
    }
    return response
  },
  async editUniversity({ commit, dispatch }, params) {
    commit("EDIT_UNIVERSITY_REQUEST")
    const { uuid } = params
    const response = await universityService.editUniversity(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_UNIVERSITY_SUCCESS", data) : commit("EDIT_UNIVERSITY_ERROR", data)
        data.error ? dispatch('alert/createAlertErrors', !isEmpty(data.errors) ? decodeLaravelValidationErrors(data.errors) : "Oops! Something went wrong.", { root: true }) : null;
        break
      default:
        commit("EDIT_UNIVERSITY_ERROR", data)
    }
    return response
  },
  async deleteUniversity({ commit }, params) {
    commit("DELETE_UNIVERSITY_REQUEST")
    const response = await universityService.deleteUniversity(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_UNIVERSITY_SUCCESS", data)
        break
      default:
        commit("DELETE_UNIVERSITY_DELETE", data)
    }
    return response
  },
  async clearUniversity({ commit }) {
    commit("CLEAR_UNIVERSITY")
  },
  async setPastWasLoaded({ commit }, data) {
    commit("setPastWasLoaded", data)
  },
}

const mutations = {
  [GET_UNIVERSITIES_REQUEST]: state => {
    state.loading = true
  },
  [GET_UNIVERSITIES_LIMITED_DATA_SUCCESS]: (state, data) => {
    state.universities = addNewFilter(state.universities, data.universities);
    state.loading = false
    state.loadedOnce = true
  },
  [GET_UNIVERSITIES_SUCCESS]: (state, data) => {
    state.universities = addNewFilter(state.universities, data.universities, true)
    state.loading = false;
    state.loadedOnce = true;
    if (data?.all) {
      state.haveAllUniversities = true;
    }
  },
  [GET_UNIVERSITIES_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },


  [GET_UNIVERSITY_REQUEST]: state => {
    state.loading = true
  },
  [GET_UNIVERSITY_SUCCESS]: (state, data) => {
    const { university } = data;
    state.universities = addNewFilter(state.universities, [university]);
    state.university = university;
    state.loading = false;
    if (!state.loadedUniversities.includes(university.uuid)) {
      state.loadedUniversities.push(university.uuid)
    }
  },
  [GET_UNIVERSITY_ERROR]: (state, data) => {
    state.loading = false
  },


  [CREATE_UNIVERSITY_REQUEST]: state => {

    state.loading = true
  },
  [CREATE_UNIVERSITY_SUCCESS]: (state, data) => {
    state.loading = false
    state.universities = addNewFilter(state.universities, [data.university])
  },
  [CREATE_UNIVERSITY_ERROR]: state => {
    state.loading = false
  },


  [EDIT_UNIVERSITY_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_UNIVERSITY_SUCCESS]: (state, data) => {
    const { university } = data
    state.universities = state.universities.map(u => u.uuid == university.uuid ? university : u)
    if (!isEmpty(state.university) && state.university.uuid == university.uuid) {
      state.university = university
    }
    state.loading = false
  },
  [EDIT_UNIVERSITY_ERROR]: state => {
    state.loading = false
  },


  [DELETE_UNIVERSITY_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_UNIVERSITY_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [DELETE_UNIVERSITY_ERROR]: (state, data) => {
    state.loading = false
  },


  [CLEAR_UNIVERSITY]: (state) => {
    state.university = {}
  },
  setPastWasLoaded: (state, data) => {
    state.pastWasLoaded = data
  }
}

export const university = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
