export const groupRoutes = {
    path: 'groups',
    name: 'groups index',
    meta: {
        label: 'Groups'
    },
    component: () => import("@/pages/group/GroupIndex.vue"),
    // children: [
    //     {
    //         path: ':uuid',
    //         name: 'group detail',
    //         meta: {
    //             label: 'Group Details'
    //         },
    //         component: () => import("@/pages/groups/GroupDetail.vue"),
    //     },
    // ]
}