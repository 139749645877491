import moment from "moment"

import {
	GET_SCREENING_SESSIONS_REQUEST,
	GET_SCREENING_SESSIONS_SUCCESS,
	GET_SCREENING_SESSIONS_ERROR,
	GET_SCREENING_SESSION_REQUEST,
	GET_SCREENING_SESSION_SUCCESS,
	GET_SCREENING_SESSION_ERROR,
	CREATE_SCREENING_SESSION_REQUEST,
	CREATE_SCREENING_SESSION_SUCCESS,
	CREATE_SCREENING_SESSION_ERROR,
	EDIT_SCREENING_SESSION_REQUEST,
	EDIT_SCREENING_SESSION_SUCCESS,
	EDIT_SCREENING_SESSION_ERROR,
	DELETE_SCREENING_SESSION_REQUEST,
	DELETE_SCREENING_SESSION_SUCCESS,
	DELETE_SCREENING_SESSION_ERROR
} from "@/actions"

import {
	screeningSessionService
} from "@/services"

import { isEmpty, addNewFilter, dateTimeFormat } from "@/utilities"
import { parseISO, isAfter, sub } from "date-fns";

const state = {
	screeningSessions: [],
	screeningSession: {},
	loading: false,
	loadedOnce: false
}

const getters = {
	ssLoadedOnce: state => state.loadedOnce,
	screeningSessions: state => state.screeningSessions,

	pastScreeningSessions: state => state.screeningSessions.filter(screeningSession => {
		let sceeningDate = parseISO(screeningSession.screening_date)
		return !isAfter(sceeningDate, new Date())
	}).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),

	pastMonthScreeningSessions: (state, getters) => getters.pastScreeningSessions.filter(ss => {
		let sceeningDate = parseISO(ss.screening_date)
		let past30Days = sub(new Date(), {
			months: 1,
		})
		return !isAfter(past30Days, sceeningDate)
	}).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),

	futureScreeningSessions: state => state.screeningSessions.filter(screeningSession => {
		let sceeningDate = parseISO(screeningSession.screening_date)
		return !isAfter(new Date(), sceeningDate)
	}).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),

	futureScreeningSessionSelectOptions: (state, getters) => {
		let data = getters.futureScreeningSessions.map(ss => {
			let label = dateTimeFormat(ss.screening_date, "MMMM Do, YYYY")
			return {
				value: ss.uuid,
				label: label
			}
		}).sort((a, b) => new Date(b.screening_date) - new Date(a.screening_date))

		return data
	},
	screeningSessionByUuid: state => uuid => state.screeningSessions.find(ss => ss.uuid == uuid),

	acceptedBySession: state => uuid => {
		if (!isEmpty(state.screeningSession) && !isEmpty(state.screeningSession.uuid) && state.screeningSession.uuid == uuid) {
			return state.screeningSession.coach_screening_sessions.filter(css => css.accepted == 1)
		}
		return []
	},

	declinedBySession: state => uuid => {
		if (!isEmpty(state.screeningSession) && !isEmpty(state.screeningSession.uuid) && state.screeningSession.uuid == uuid) {
			return state.screeningSession.coach_screening_sessions.filter(css => css.declined == 1)
		}
		return []
	},

	rescheduleRequestBySession: state => uuid => {
		if (!isEmpty(state.screeningSession) && !isEmpty(state.screeningSession.uuid) && state.screeningSession.uuid == uuid) {
			return state.screeningSession?.coach_screening_sessions.filter(css => {
				return css.reschedule_requested == 1
			})
		}
		return []
	},
	
	noResponseBySession: state => uuid => {
		if (!isEmpty(state.screeningSession) && !isEmpty(state.screeningSession.uuid) && state.screeningSession.uuid == uuid) {
			return state.screeningSession.coach_screening_sessions.filter(css => {
				return (isEmpty(css.accepted) && isEmpty(css.declined) && isEmpty(css.reschedule_requested))
			})
		}
		return []
	},
}

const actions = {
	async getScreeningSessions({ commit }, params) {
		commit("GET_SCREENING_SESSIONS_REQUEST")
		const response = await screeningSessionService.getScreeningSessions(params);
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_SCREENING_SESSIONS_SUCCESS", data)
				break
			default:
				commit("GET_SCREENING_SESSIONS_ERROR", data)
		}
		return response
	},
	async getScreeningSession({ commit }, params) {
		commit("GET_SCREENING_SESSION_REQUEST")
		const response = await screeningSessionService.getScreeningSession(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.screening_session = unpackScreeningSession(commit, data.screening_session)
				commit("GET_SCREENING_SESSION_SUCCESS", data)
				break
			default:
				commit("GET_SCREENING_SESSION_ERROR", data)
				break
		}
		return response
	},
	async createScreeningSession({ commit }, params) {
		commit("CREATE_SCREENING_SESSION_REQUEST")
		const response = await screeningSessionService.createScreeningSession(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_SCREENING_SESSION_SUCCESS", data)
				break
			default:
				commit("CREATE_SCREENING_SESSION_ERROR", data)
		}
		return response
	},
	async editScreeningSession({ commit }, params) {
		commit("EDIT_SCREENING_SESSION_REQUEST")
		const uuid = params.uuid
		const response = await screeningSessionService.editScreeningSession(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_SCREENING_SESSION_SUCCESS", data)
				break
			default:
				commit("EDIT_SCREENING_SESSION_ERROR", data)
		}
		return response
	},
	async deleteScreeningSession({ commit }, uuid) {
		commit("DELETE_SCREENING_SESSION_REQUEST")
		const response = await screeningSessionService.deleteScreeningSession(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_SCREENING_SESSION_SUCCESS", uuid)
				break
			default:
				commit("DELETE_SCREENING_SESSION_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_SCREENING_SESSIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_SCREENING_SESSIONS_SUCCESS]: (state, data) => {
		state.screeningSessions = addNewFilter(state.screeningSessions, data.screening_sessions)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_SCREENING_SESSIONS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_SCREENING_SESSION_REQUEST]: state => {
		state.loading = true
	},
  [GET_SCREENING_SESSION_SUCCESS]: (state, data) => {
    const { screening_session } = data;
		state.screeningSessions = addNewFilter(state.screeningSessions, [screening_session])
		state.loading = false;
	},
	[GET_SCREENING_SESSION_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_SCREENING_SESSION_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_SCREENING_SESSION_SUCCESS]: (state, data) => {
		state.screeningSessions = addNewFilter(state.screeningSessions, [data.screening_session])
		state.loading = false
	},
	[CREATE_SCREENING_SESSION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_SCREENING_SESSION_REQUEST]: state => {
		state.loading = true
	},
  [EDIT_SCREENING_SESSION_SUCCESS]: (state, data) => {
    const { screening_session } = data;
		state.screeningSessions = addNewFilter(state.screeningSessions, [screening_session])
		state.loading = false
	},
	[EDIT_SCREENING_SESSION_ERROR]: state => {
		state.loading = false
	},

	[DELETE_SCREENING_SESSION_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_SCREENING_SESSION_SUCCESS]: (state, uuid) => {
		state.screeningSessions = state.screeningSessions.filter(screening_session => screening_session.uuid !== uuid)
		state.loading = false
	},
	[DELETE_SCREENING_SESSION_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const screeningSession = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

/**
 *
 * @param {*} ss
 * @returns
 */
const unpackScreeningSession = (commit, ss) => {
	if (!isEmpty(ss.coach_screening_sessions)) {
		let coach_screening_sessions = ss.coach_screening_sessions
		commit("coachScreeningSession/GET_COACH_SCREENING_SESSIONS_SUCCESS", { coach_screening_sessions }, { root: true })
	}
	delete ss.coach_screening_sessions;

	return ss;
}
