export const reportsInvoiceRoutes = {
  path: "invoice",
  name: "reports invoice",
  meta: {
    authRequired: true,
    allowedRoles: [
      'super-admin'
    ],
    label: "Invoice Reports",
  },
  component: () => import("@/pages/reports/general/ReportsGeneralIndex.vue"),
  children: [
    {
      path: "open-invoices",
      name: "report invoice open-invoices",
      meta: {
        label: "Open Invoices",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "open-invoices-past-due",
      name: "report invoice open-invoices-past-due",
      meta: {
        label: "Open Invoices with Past Due",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    },
    {
      path: "past-due-invoices",
      name: "report invoice past-due-invoices",
      meta: {
        label: "Past Due Invoices",
      },
      component: () => import("@/pages/reports/ReportDefaultOutputPaginated.vue"),
    }
  ]
};