import {
    GET_COACH_LANGUAGES_REQUEST,
    GET_COACH_LANGUAGES_SUCCESS,
    GET_COACH_LANGUAGES_ERROR,
    GET_COACH_LANGUAGE_REQUEST,
    GET_COACH_LANGUAGE_SUCCESS,
    GET_COACH_LANGUAGE_ERROR,
    CREATE_COACH_LANGUAGE_REQUEST,
    CREATE_COACH_LANGUAGE_SUCCESS,
    CREATE_COACH_LANGUAGE_ERROR,
    EDIT_COACH_LANGUAGE_REQUEST,
    EDIT_COACH_LANGUAGE_SUCCESS,
    EDIT_COACH_LANGUAGE_ERROR,
    DELETE_COACH_LANGUAGE_REQUEST,
    DELETE_COACH_LANGUAGE_SUCCESS,
    BROADCAST_DELETE_COACH_LANGUAGE_SUCCESS,
    DELETE_COACH_LANGUAGE_ERROR,
    CLEAR_COACH_LANGUAGE_STATE
} from "@/actions"

import {
    coachLanguageService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    coachLanguages: [],
    coachLanguage: {},
    loading: false
}

const getters = {
    coachLanguages: ( state, getters, rootState, rootGetters ) => state.coachLanguages.filter(cl => cl.coaches_uuid == rootGetters['coaches/coach']['uuid']),
    coachLanguageByLangUuid: ( state, getters ) => lUuid => getters.coachLanguages.find(cl => cl.languages_uuid == lUuid)
}

const actions = {
    async getCoachLanguages({ commit }) {
        commit("GET_COACH_LANGUAGES_REQUEST")
        const response = await coachLanguageService.getCoachLanguages();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_LANGUAGES_SUCCESS", data)
                break
            default:
                commit("GET_COACH_LANGUAGES_ERROR", data)
        }
        return response
    },
    async getCoachLanguage({ commit }, params) {
        commit("GET_COACH_LANGUAGE_REQUEST")
        const response = await coachLanguageService.getCoachLanguage(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_LANGUAGE_SUCCESS", data)
                break
            default:
                commit("GET_COACH_LANGUAGE_ERROR", data)
                break
        }
        return response
    },
    async createCoachLanguage({ commit }, params) {
        commit("CREATE_COACH_LANGUAGE_REQUEST")
        const response = await coachLanguageService.createCoachLanguage(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COACH_LANGUAGE_SUCCESS", data)
                break
            default:
                commit("CREATE_COACH_LANGUAGE_ERROR", data)
        }
        return response
    },
    async editCoachLanguage({ commit }, params, uuid) {
        commit("EDIT_COACH_LANGUAGE_REQUEST")
        const response = await coachLanguageService.editCoachLanguage(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COACH_LANGUAGE_SUCCESS", data)
                break
            default:
                commit("EDIT_COACH_LANGUAGE_ERROR", data)
        }
        return response
    },
    async deleteCoachLanguage({ commit }, uuid) {
        commit("DELETE_COACH_LANGUAGE_REQUEST")
        const response = await coachLanguageService.deleteCoachLanguage(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COACH_LANGUAGE_SUCCESS", uuid)
                break
            default:
                commit("DELETE_COACH_LANGUAGE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_LANGUAGES_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_LANGUAGES_SUCCESS]: (state, data) => {
        const coachLanguages = [...data.coach_languages]
        state.coachLanguages = coachLanguages
        state.loading = false
    },
    [GET_COACH_LANGUAGES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COACH_LANGUAGE_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_LANGUAGE_SUCCESS]: (state, data) => {
        const coachLanguage = { ...data.coachLanguage }
        state.coachLanguage = coachLanguage
        state.loading = false
    },
    [GET_COACH_LANGUAGE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_LANGUAGE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_LANGUAGE_SUCCESS]: (state, data) => {
        const { coach_language } = data
        state.coachLanguages = addNewFilter(state.coachLanguages, [coach_language])
        state.loading = false
    },
    [CREATE_COACH_LANGUAGE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_LANGUAGE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_LANGUAGE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COACH_LANGUAGE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_LANGUAGE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_LANGUAGE_SUCCESS]: (state, uuid) => {
        state.coachLanguages = state.coachLanguages.filter(cl => cl.uuid !== uuid)
        state.loading = false
    },
    [BROADCAST_DELETE_COACH_LANGUAGE_SUCCESS]: (state, data) => {
        const { coach_language } = data
        const { uuid } = coach_language
        if(!isEmpty(uuid)){
            state.coachLanguages = state.coachLanguages.filter(ctp => ctp.uuid !== uuid)
        }
        state.loading = false
    },
    [DELETE_COACH_LANGUAGE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COACH_LANGUAGE_STATE]: (state) => {
        state.coachLanguages = []
        state.coachLanguage = {}
        state.loading = false
        state.loadedOnce = false
    }
}

export const coachLanguage = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}