import {
    apiRequest,
    processInput
} from "@/utilities"

export const universityProspectsService = {
    async getUniversityProspects() {
        const getUniversityProspectsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/university-prospects", getUniversityProspectsRequest)
    },
    async createUniversityProspect(params) {
        const createData = processInput(params);
        const postUniversityProspectRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/university-prospects", postUniversityProspectRequest)
    },
    async getUniversityProspect(uuid) {

        const getUniversityProspectRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/university-prospects/${uuid}`, getUniversityProspectRequest);
    },
    async editUniversityProspect(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityProspectRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/university-prospects/${uuid}`, updateUniversityProspectRequest);
    },
    async deleteUniversityProspect(uuid) {

        const deleteUniversityProspectRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/university-prospects/${uuid}`, deleteUniversityProspectRequest);
    }
}