import {
  apiRequest,
  processQueryParams
} from "@/utilities"

export const reportService = {
  async getReport(params) {
    let getParams = processQueryParams(params)
    const getReportRequest = {
      method: "GET",
      params: getParams
    }
    return await apiRequest("/api/report", getReportRequest)
  }
}