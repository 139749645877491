import {
    apiRequest,
    processInput,
    addNewFilter,
    processQueryParams,
    isEmpty
} from "@/utilities"
import { unpackCoach, unpackCourseStart, unpackEmails } from "@/modules"

const state = {
    loading: false,
    icEmailsActivationDeactivations: [],
    icEmailsActivationDeactivationDateLabels: {
        activation: [],
        de_activation: [],
    },
    loadedActualData: false,
}

function mapData(rootGetters, data) {
    if (isEmpty(data)) return {}
    if (!isEmpty(data.coaches_uuid)) {
        let coachByUuid = rootGetters['coaches/coachByUuid']
        let universityFilter = rootGetters['university/universityFilter']
        let coach = coachByUuid(data.coaches_uuid)
        let university = universityFilter(data.university_uuid)
        data.coach = null
        data.abbreviation = null
        data.university = null
        data.coach_name = null
        if (!isEmpty(coach)) {
            data.coach = coach
            data.coach_name = coach.display_name
        }
        if (!isEmpty(university)) {
            data.university = university
            data.uni_dname = university?.display_name
            data.uni_name = university?.name
            data.abbreviation = university?.abbreviation
        }
    }
    return data
}

const getters = {
    icEmailsActivationDeactivations: (state, getters, rootState, rootGetters) => {
        return state.icEmailsActivationDeactivations.filter(i => isEmpty(i.is_activated_or_deactivated)).map(i => mapData(rootGetters, i))
    },
    icEmailsActivationDeactivationsDatewise: (state, getters, rootState, rootGetters) => (start_date, activated_or_deactivated) => {
        let data = state.icEmailsActivationDeactivations
            .filter(i => {
                return (
                    isEmpty(i.is_activated_or_deactivated)
                    && i.activated_or_deactivated == activated_or_deactivated
                    && Number(new Date(i.start_date)) == Number(new Date(start_date))
                )
            }
            )
        return data.map(i => mapData(rootGetters, i))
    },
    icEmailsActivationDeactivationDateLabels: (state, getters, rootState, rootGetters) => {
        return state.icEmailsActivationDeactivationDateLabels
    },
    searchByCoachUuid: state => coachUuid => {
        let data = state.icEmailsActivationDeactivations
            .filter(i => {
                return (
                    i.activated_or_deactivated == 1
                    && i.coaches_uuid == coachUuid
                )
            }
            ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).find((i, idx) => idx === 0)
        return data
    },
    icEADLoading: state => state.loading,
    loadedActualData: state => state.loadedActualData,
}

const actions = {
    async getIcEmailsActivationDeactivations({ commit }, params) {
        commit("ACTIVATION_DEACTIVATION_REQUEST")
        let getParams = processQueryParams(params)
        const getAllReq = {
            method: "GET",
            params: getParams
        }
        const response = await apiRequest(`/api/ieads`, getAllReq)
        const { status, data } = response
        switch (status) {
            case 200:
                if (!isEmpty(data.ic_emails_activation_deactivations)) {
                    let ic_emails_activation_deactivations = data.ic_emails_activation_deactivations.map(i => unpackIcEmailsActivationDeactivation(i, commit))
                    commit("GET_ACTIVATION_DEACTIVATIONS_SUCCESS", { ic_emails_activation_deactivations })
                }
                break
            default:
                commit("GET_ACTIVATION_DEACTIVATIONS_ERROR", data)
                break
        }
        return response
    },
    async editIcEmailsActivationDeactivation({ commit }, params) {
        commit("ACTIVATION_DEACTIVATION_REQUEST")
        let editData = processInput(params)
        editData.append("_method", "PATCH");
        const updateRequest = {
            method: "POST",
            data: editData
        }
        let uuid = params.uuid
        // eslint-disable-next-line valid-typeof
        if (typeof params.uuid == 'array') {
            uuid = uuid.find((i, idx) => idx === 0)
        }
        const response = await apiRequest(`/api/ieads/${uuid}`, updateRequest);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_ACTIVATION_DEACTIVATION_SUCCESS", data)
                break
            default:
                commit("EDIT_ACTIVATION_DEACTIVATION_ERROR", data)
        }
        return response
    },
}

const mutations = {
    ACTIVATION_DEACTIVATION_REQUEST: state => {
        state.loading = true
    },
    GET_ACTIVATION_DEACTIVATIONS_SUCCESS: (state, data) => {
        const icEmailsActivationDeactivations = data.ic_emails_activation_deactivations
        state.icEmailsActivationDeactivations = addNewFilter(state.icEmailsActivationDeactivations, icEmailsActivationDeactivations)
        state.loading = false
        state.loadedActualData = true
    },
    GET_ACTIVATION_DEACTIVATION_DATE_LABELS_SUCCESS: (state, data) => {
        const ic_emails_activation_deactivation_date_labels = data.ic_emails_activation_deactivation_date_labels
        const _o_a_dataSet = new Set(state.icEmailsActivationDeactivationDateLabels.activation);
        const _n_a_dataSet = new Set(ic_emails_activation_deactivation_date_labels.activation);
        const _all_a_dataSet = new Set([..._o_a_dataSet, ..._n_a_dataSet]);
        state.icEmailsActivationDeactivationDateLabels.activation = [..._all_a_dataSet]

        const _o_d_dataSet = new Set(state.icEmailsActivationDeactivationDateLabels.de_activation);
        const _n_d_dataSet = new Set(ic_emails_activation_deactivation_date_labels.de_activation);
        const _all_d_dataSet = new Set([..._o_d_dataSet, ..._n_d_dataSet]);
        state.icEmailsActivationDeactivationDateLabels.de_activation = [..._all_d_dataSet]
        state.loading = false
    },
    GET_ACTIVATION_DEACTIVATIONS_ERROR: (state, data) => {
        state.loading = false
    },
    CREATE_ACTIVATION_DEACTIVATION_SUCCESS: (state, data) => {
        if (!isEmpty(data.ic_emails_activation_deactivation)) {
            const icEmailsActivationDeactivations = [data.ic_emails_activation_deactivation]
            state.icEmailsActivationDeactivations = addNewFilter(state.icEmailsActivationDeactivations, icEmailsActivationDeactivations, true)
            if (Array.isArray(data.ic_emails_activation_deactivation)) {
                data.ic_emails_activation_deactivation.forEach(e => {
                    state.icEmailsActivationDeactivationDateLabels = updateLabels(e, state.icEmailsActivationDeactivationDateLabels, state.icEmailsActivationDeactivations)
                })
            } else {
                let e = data.ic_emails_activation_deactivation
                state.icEmailsActivationDeactivationDateLabels = updateLabels(e, state.icEmailsActivationDeactivationDateLabels, state.icEmailsActivationDeactivations)
            }
        }
        state.loading = false
    },
    EDIT_ACTIVATION_DEACTIVATION_SUCCESS: (state, data) => {
        if (!isEmpty(data.ic_emails_activation_deactivations)) {
            const icEmailsActivationDeactivations = data.ic_emails_activation_deactivations
            state.icEmailsActivationDeactivations = addNewFilter(state.icEmailsActivationDeactivations, icEmailsActivationDeactivations, true)
        }
        if (!isEmpty(data.ic_emails_activation_deactivation)) {
            if (Array.isArray(data.ic_emails_activation_deactivation)) {
                data.ic_emails_activation_deactivation.forEach(e => {
                    state.icEmailsActivationDeactivations = state.icEmailsActivationDeactivations.map(i => i.uuid == e.uuid ? e : i)
                    state.icEmailsActivationDeactivationDateLabels = updateLabels(e, state.icEmailsActivationDeactivationDateLabels, state.icEmailsActivationDeactivations)
                })
            } else {
                let e = data.ic_emails_activation_deactivation
                state.icEmailsActivationDeactivations = state.icEmailsActivationDeactivations.map(i => i.uuid == e.uuid ? e : i)
                state.icEmailsActivationDeactivationDateLabels = updateLabels(e, state.icEmailsActivationDeactivationDateLabels, state.icEmailsActivationDeactivations)
            }
        }
        state.loading = false
    },
    EDIT_ACTIVATION_DEACTIVATION_ERROR: (state, data) => {
        state.loading = false
    },
    DELETE_ACTIVATION_DEACTIVATION_SUCCESS: (state, data) => {
        if (!isEmpty(data.ic_emails_activation_deactivation)) {
            if (Array.isArray(data.ic_emails_activation_deactivation)) {
                data.ic_emails_activation_deactivation.forEach(e => {
                    state.icEmailsActivationDeactivations = state.icEmailsActivationDeactivations.map(i => i.uuid != e.uuid)
                    state.icEmailsActivationDeactivationDateLabels = updateLabels(e, state.icEmailsActivationDeactivationDateLabels, state.icEmailsActivationDeactivations, true)
                })
            } else {
                let e = data.ic_emails_activation_deactivation
                state.icEmailsActivationDeactivations = state.icEmailsActivationDeactivations.map(i => i.uuid != e.uuid)
                state.icEmailsActivationDeactivationDateLabels = updateLabels(e, state.icEmailsActivationDeactivationDateLabels, state.icEmailsActivationDeactivations, true)
            }
        }
    }
}

export function unpackIcEmailsActivationDeactivation(iead, commit) {
    if (!isEmpty(iead.email)) {
        commit('email/GET_EMAILS_SUCCESS', { emails: [iead.email] }, { root: true })
    }
    if (!isEmpty(iead.coach)) {
        commit('coaches/GET_COACHES_SUCCESS', { coaches: [iead.coach] }, { root: true })
    }
    if (!isEmpty(iead.course_start)) {
        let courseStarts = [iead.course_start]
        let course_starts = courseStarts.map(cs => unpackCourseStart(cs, commit))
        commit("courseStart/GET_COURSE_STARTS_SUCCESS", { course_starts }, { root: true })
    }
    return iead
}

function updateLabels(e, source, data, isDeleted = false) {
    let { activation, de_activation } = source
    if (e.activated_or_deactivated == 1) {
        const _o_a_dataSet = new Set(activation);
        if (e.is_activated_or_deactivated == 1 || isDeleted) {
            let checkOther = data.find(d => d.start_date == e.start_date && d.uuid != e.uuid && d.activated_or_deactivated == e.activated_or_deactivated && isEmpty(d.is_activated_or_deactivated))
            if (!isEmpty(checkOther)) {
                _o_a_dataSet.add(e.start_date)
            } else {
                _o_a_dataSet.delete(e.start_date)
            }
        } else {
            _o_a_dataSet.add(e.start_date)
        }
        activation = [..._o_a_dataSet]
    } else if (e.activated_or_deactivated == 2) {
        const _o_a_dataSet = new Set(de_activation);
        if (e.is_activated_or_deactivated == 1 || isDeleted) {
            let checkOther = data.find(d => d.start_date == e.start_date && d.uuid != e.uuid && d.activated_or_deactivated == e.activated_or_deactivated && isEmpty(d.is_activated_or_deactivated))
            if (!isEmpty(checkOther)) {
                _o_a_dataSet.add(e.start_date)
            } else {
                _o_a_dataSet.delete(e.start_date)
            }
        } else {
            _o_a_dataSet.add(e.start_date)
        }
        de_activation = [..._o_a_dataSet]
    }
    return {
        activation,
        de_activation,
    }
}

export const icEmailsActivationDeactivation = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export function unpackIcead(icead, commit){
    if(!isEmpty(icead.coach)){
        const coach = unpackCoach(icead.coach, commit)
        commit("coaches/GET_COACHES_SUCCESS", { coaches: [coach] }, { root: true })
    }
    if(!isEmpty(icead.email)){
        const email = unpackEmails(icead.email, commit)
        commit("email/GET_EMAILS_SUCCESS", { emails: [email] }, { root: true })
    }
    return icead
}