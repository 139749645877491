import {
    apiRequest,
    processInput
} from "@/utilities"

export const coachLanguageService = {
    async getCoachLanguages() {
        const getCoachLanguagesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/coach-languages", getCoachLanguagesRequest)
    },
    async getCoachLanguage(uuid) {
        const getCoachLanguageRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-languages/${uuid}`, getCoachLanguageRequest)
    },
    async createCoachLanguage(params) {
        const createData = processInput(params)
        const createCoachLanguageRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-languages", createCoachLanguageRequest)
    },
    async editCoachLanguage(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachLanguageRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-languages/${uuid}`, editCoachLanguageRequest)
    },
    async deleteCoachLanguage(uuid) {
        const deleteCoachLanguageRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-languages/${uuid}`, deleteCoachLanguageRequest)
    }
}