import {
    GET_COACH_REFERRAL_SOURCES_REQUEST,
    GET_COACH_REFERRAL_SOURCES_SUCCESS,
    GET_COACH_REFERRAL_SOURCES_ERROR,
    GET_COACH_REFERRAL_SOURCE_REQUEST,
    GET_COACH_REFERRAL_SOURCE_SUCCESS,
    GET_COACH_REFERRAL_SOURCE_ERROR,
    CREATE_COACH_REFERRAL_SOURCE_REQUEST,
    CREATE_COACH_REFERRAL_SOURCE_SUCCESS,
    CREATE_COACH_REFERRAL_SOURCE_ERROR,
    EDIT_COACH_REFERRAL_SOURCE_REQUEST,
    EDIT_COACH_REFERRAL_SOURCE_SUCCESS,
    EDIT_COACH_REFERRAL_SOURCE_ERROR,
    DELETE_COACH_REFERRAL_SOURCE_REQUEST,
    DELETE_COACH_REFERRAL_SOURCE_SUCCESS,
    DELETE_COACH_REFERRAL_SOURCE_ERROR,
    CLEAR_COACH_REFERRAL_SOURCE_STATE
} from "@/actions"

import {
    coachReferralSourceService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    coachReferralSources: [],
    coachReferralSource: {},
    loading: false
}

const getters = {
    coachReferralSources: (state, getters, rootState, rootGetters) => state.coachReferralSources.filter(crs => crs.coaches_uuid == rootGetters['coaches/coach']['uuid']).map(crs => {
        let source = rootGetters['referralSource/referralSourceByUuid'](crs.referral_sources_uuid)
        return {
            ...crs,
            source
        }
    })
}

const actions = {
    async getCoachReferralSources({ commit }) {
        commit("GET_COACH_REFERRAL_SOURCES_REQUEST")
        const response = await coachReferralSourceService.getCoachReferralSources();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_REFERRAL_SOURCES_SUCCESS", data)
                break
            default:
                commit("GET_COACH_REFERRAL_SOURCES_ERROR", data)
        }
        return response
    },
    async getCoachReferralSource({ commit }, params) {
        commit("GET_COACH_REFERRAL_SOURCE_REQUEST")
        const response = await coachReferralSourceService.getCoachReferralSource(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_REFERRAL_SOURCE_SUCCESS", data)
                break
            default:
                commit("GET_COACH_REFERRAL_SOURCE_ERROR", data)
                break
        }
        return response
    },
    async createCoachReferralSource({ commit }, params) {
        commit("CREATE_COACH_REFERRAL_SOURCE_REQUEST")
        const response = await coachReferralSourceService.createCoachReferralSource(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COACH_REFERRAL_SOURCE_SUCCESS", data)
                break
            default:
                commit("CREATE_COACH_REFERRAL_SOURCE_ERROR", data)
        }
        return response
    },
    async editCoachReferralSource({ commit }, params, uuid) {
        commit("EDIT_COACH_REFERRAL_SOURCE_REQUEST")
        const response = await coachReferralSourceService.editCoachReferralSource(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COACH_REFERRAL_SOURCE_SUCCESS", data)
                break
            default:
                commit("EDIT_COACH_REFERRAL_SOURCE_ERROR", data)
        }
        return response
    },
    async deleteCoachReferralSource({ commit }, uuid) {
        commit("DELETE_COACH_REFERRAL_SOURCE_REQUEST")
        const response = await coachReferralSourceService.deleteCoachReferralSource(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("DELETE_COACH_REFERRAL_SOURCE_SUCCESS", uuid) : null
                break
            default:
                commit("DELETE_COACH_REFERRAL_SOURCE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_REFERRAL_SOURCES_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_REFERRAL_SOURCES_SUCCESS]: (state, data) => {
        const coachReferralSources = [...data.coach_referral_sources]
        state.coachReferralSources = coachReferralSources
        state.loading = false
    },
    [GET_COACH_REFERRAL_SOURCES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COACH_REFERRAL_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_REFERRAL_SOURCE_SUCCESS]: (state, data) => {
        const coachReferralSource = { ...data.coach_referral_source }
        state.coachReferralSource = coachReferralSource
        state.loading = false
    },
    [GET_COACH_REFERRAL_SOURCE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_REFERRAL_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_REFERRAL_SOURCE_SUCCESS]: (state, data) => {
        const { coach_referral_source } = data
        if(!isEmpty(coach_referral_source?.coaches_uuid)){
            state.coachReferralSources = state.coachReferralSources.filter(i => i.coaches_uuid != coach_referral_source.coaches_uuid)
            state.coachReferralSources = addNewFilter(state.coachReferralSources, [coach_referral_source])
        }
        state.loading = false
    },
    [CREATE_COACH_REFERRAL_SOURCE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_REFERRAL_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_REFERRAL_SOURCE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COACH_REFERRAL_SOURCE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_REFERRAL_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_REFERRAL_SOURCE_SUCCESS]: (state, uuid) => {
        state.coachReferralSources = state.coachReferralSources.filter(crs => crs.uuid !== uuid)
        state.loading = false
    },
    [DELETE_COACH_REFERRAL_SOURCE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COACH_REFERRAL_SOURCE_STATE]: (state) => {
        state.coachReferralSources = [],
            state.coachReferralSource = {},
            state.loading = false
        state.loadedOnce = false
    }
}

export const coachReferralSource = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}