import {
  GET_COACH_ASSIGNMENT_RECONCILIATIONS_REQUEST,
  GET_COACH_ASSIGNMENT_RECONCILIATIONS_SUCCESS,
  GET_COACH_ASSIGNMENT_RECONCILIATIONS_ERROR,
  GET_COACH_ASSIGNMENT_RECONCILIATION_REQUEST,
  GET_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS,
  GET_COACH_ASSIGNMENT_RECONCILIATION_ERROR,
  CREATE_COACH_ASSIGNMENT_RECONCILIATION_REQUEST,
  CREATE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS,
  CREATE_COACH_ASSIGNMENT_RECONCILIATION_ERROR,
  EDIT_COACH_ASSIGNMENT_RECONCILIATION_REQUEST,
  EDIT_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS,
  EDIT_COACH_ASSIGNMENT_RECONCILIATION_ERROR,
  DELETE_COACH_ASSIGNMENT_RECONCILIATION_REQUEST,
  DELETE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS,
  DELETE_COACH_ASSIGNMENT_RECONCILIATION_ERROR
} from "@/actions"

import {
  coachAssignmentReconciliationService
} from "@/services"

const state = {
  coachAssignmentReconciliations: [],
  coachAssignmentReconciliation: {},
  loading: false,
  loadedOnce: false
}
import { unpackTransferLog } from "@/modules";
import { addNewFilter, isEmpty } from "@/utilities"

const getters = {
  allCARec: state => state.coachAssignmentReconciliations,
  coachAssignmentReconciliation: state => state.coachAssignmentReconciliation,
  CARecByCaUuid: state => caUuid => state.coachAssignmentReconciliations.find(i => i.coach_assignments_uuid == caUuid),
  CARecByKey: state => recKey => state.coachAssignmentReconciliations.filter(i => {
    return i.reconciliation_key == recKey
  })
}

const actions = {
  async getCoachAssignmentReconciliations({ commit }, params) {
    commit("GET_COACH_ASSIGNMENT_RECONCILIATIONS_REQUEST")
    const response = await coachAssignmentReconciliationService.getCoachAssignmentReconciliations(params)
    const { status, data } = response
    switch (status) {
      case 200:
        let _data = data
        _data.coach_assignment_reconciliations = _data.coach_assignment_reconciliations.map(i => unpackCoachAssignmentReconciliation(i, commit))
        _data.force_update = true
        commit("GET_COACH_ASSIGNMENT_RECONCILIATIONS_SUCCESS", _data)
        break
      default:
        commit("GET_COACH_ASSIGNMENT_RECONCILIATIONS_ERROR", data)
    }
    return response
  },
  async getCoachAssignmentReconciliation({ commit }, params) {
    commit("GET_COACH_ASSIGNMENT_RECONCILIATION_REQUEST")
    const response = await coachAssignmentReconciliationService.getCoachAssignmentReconciliation(params)
    const { status, data } = response
    switch (status) {
      case 200:
        if (data?.success) {
          data.coach_assignment_reconciliation = unpackCoachAssignmentReconciliation(data.coach_assignment_reconciliation, commit);
          commit("GET_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS", data);
        }

        break
      default:
        commit("GET_COACH_ASSIGNMENT_RECONCILIATION_ERROR", data)
        break
    }
    return response
  },
  async createCoachAssignmentReconciliation({ commit }, params) {
    commit("CREATE_COACH_ASSIGNMENT_RECONCILIATION_REQUEST")
    const response = await coachAssignmentReconciliationService.createCoachAssignmentReconciliation(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS", data) : commit("CREATE_COACH_ASSIGNMENT_RECONCILIATION_ERROR", data)
        break
      default:
        commit("CREATE_COACH_ASSIGNMENT_RECONCILIATION_ERROR", data)
    }
    return response
  },
  async editCoachAssignmentReconciliation({ commit }, params) {
    commit("EDIT_COACH_ASSIGNMENT_RECONCILIATION_REQUEST")
    const uuid = params.uuid
    const response = await coachAssignmentReconciliationService.editCoachAssignmentReconciliation(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS", data) : commit("EDIT_COACH_ASSIGNMENT_RECONCILIATION_ERROR", data)
        break
      default:
        commit("EDIT_COACH_ASSIGNMENT_RECONCILIATION_ERROR", data)
    }
    return response
  },
  async deleteCoachAssignmentReconciliation({ commit }, params) {
    commit("DELETE_COACH_ASSIGNMENT_RECONCILIATION_REQUEST")
    const response = await coachAssignmentReconciliationService.deleteCoachAssignmentReconciliation(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS", data)
        break
      default:
        commit("DELETE_COACH_ASSIGNMENT_RECONCILIATION_ERROR", data)
    }
    return response
  },
  removeCARecByWasUuid({ commit }, wasUuid) {
    // commit('removeCoachAssignmentReconciliationsByWasUuid', wasUuid);
  },
  removeCARecByRecnKey({ commit }, recnKey) {
    commit('removeCARecByRecnKey', recnKey);
  },
}

const mutations = {
  [GET_COACH_ASSIGNMENT_RECONCILIATIONS_REQUEST]: state => {
    state.loading = true
  },
  [GET_COACH_ASSIGNMENT_RECONCILIATIONS_SUCCESS]: (state, data) => {
    const coachAssignmentReconciliations = data.coach_assignment_reconciliations
    state.coachAssignmentReconciliations = addNewFilter(state.coachAssignmentReconciliations, coachAssignmentReconciliations, data?.force_update)
    state.loading = false
    state.loadedOnce = true
  },
  [GET_COACH_ASSIGNMENT_RECONCILIATIONS_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_COACH_ASSIGNMENT_RECONCILIATION_REQUEST]: state => {
    state.loading = true
  },
  [GET_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS]: (state, data) => {
    const coach_assignment_reconciliation = data.coach_assignment_reconciliation
    state.coachAssignmentReconciliation = coach_assignment_reconciliation
    state.coachAssignmentReconciliations = addNewFilter(state.coachAssignmentReconciliations, [coach_assignment_reconciliation], true)
    state.loading = false
  },
  [GET_COACH_ASSIGNMENT_RECONCILIATION_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_COACH_ASSIGNMENT_RECONCILIATION_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS]: (state, data) => {
    let { coach_assignment_reconciliation } = data
    state.coachAssignmentReconciliations = addNewFilter(state.coachAssignmentReconciliations, [coach_assignment_reconciliation])
    state.loading = false
  },
  [CREATE_COACH_ASSIGNMENT_RECONCILIATION_ERROR]: state => {
    state.loading = false
  },

  [EDIT_COACH_ASSIGNMENT_RECONCILIATION_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS]: (state, data) => {
    let coachAssignmentReconciliation = data.coach_assignment_reconciliation
    state.coachAssignmentReconciliations = state.coachAssignmentReconciliations.map(p => p.uuid == coachAssignmentReconciliation.uuid ? coachAssignmentReconciliation : p)
    state.loading = false
  },
  [EDIT_COACH_ASSIGNMENT_RECONCILIATION_ERROR]: state => {
    state.loading = false
  },

  [DELETE_COACH_ASSIGNMENT_RECONCILIATION_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_COACH_ASSIGNMENT_RECONCILIATION_SUCCESS]: (state, data) => {
    let coachAssignmentReconciliation = data.coach_assignment_reconciliation
    state.coachAssignmentReconciliations = state.coachAssignmentReconciliations.filter(p => p.uuid != coachAssignmentReconciliation.uuid)
    state.loading = false
  },
  [DELETE_COACH_ASSIGNMENT_RECONCILIATION_ERROR]: (state, data) => {
    state.loading = false
  },
  removeCoachAssignmentReconciliationsByWasUuid: (state, wasUuid) => {
    state.coachAssignmentReconciliations = state.coachAssignmentReconciliations.filter(i => i.was_uuid != wasUuid)
  },
  removeCARecByRecnKey: (state, recnKey) => {
    state.coachAssignmentReconciliations = state.coachAssignmentReconciliations.filter(i => i.reconciliation_key != recnKey)
  },
}

export function unpackCoachAssignmentReconciliation(car, commit) {

	if (!isEmpty(car.students)) {
    let students = car.students;
		commit("students/ADD_STUDENTS", { students }, { root: true })
	}
	return car
}

export const coachAssignmentReconciliation = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
