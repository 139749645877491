import {
	GET_WORKING_ASSIGNMENT_SHEETS_REQUEST,
	GET_WORKING_ASSIGNMENT_SHEETS_SUCCESS,
	GET_WORKING_ASSIGNMENT_SHEETS_ERROR,
	GET_WORKING_ASSIGNMENT_SHEET_REQUEST,
	GET_WORKING_ASSIGNMENT_SHEET_SUCCESS,
	GET_WORKING_ASSIGNMENT_SHEET_ERROR,
	GET_WORKING_ASSIGNMENT_SHEETS_DATES_REQUEST,
	GET_WORKING_ASSIGNMENT_SHEETS_DATES_SUCCESS,
	GET_WORKING_ASSIGNMENT_SHEETS_DATES_ERROR,
	CREATE_WORKING_ASSIGNMENT_SHEET_REQUEST,
	CREATE_WORKING_ASSIGNMENT_SHEET_SUCCESS,
	CREATE_WORKING_ASSIGNMENT_SHEET_ERROR,
	EDIT_WORKING_ASSIGNMENT_SHEET_REQUEST,
	EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS,
	EDIT_WORKING_ASSIGNMENT_SHEET_ERROR,
	DELETE_WORKING_ASSIGNMENT_SHEET_REQUEST,
	DELETE_WORKING_ASSIGNMENT_SHEET_SUCCESS,
	DELETE_WORKING_ASSIGNMENT_SHEET_ERROR,
	BROADCAST_CREATE_WASR_SUCCESS,
	BROADCAST_EDIT_WASR_SUCCESS,
	BROADCAST_DELETE_WASR_SUCCESS,
	UPDATE_STATE,
} from "@/actions"

import {
	workingAssignmentSheetsService
} from "@/services"

import { parseISO, isAfter, format } from "date-fns"

import { addNewFilter, isEmpty, decodeLaravelValidationErrors } from "@/utilities"

import { unpackCourseStart, unpackProgramData, unpackCoach, unpackWASReconciliation, unpackIcead } from "@/modules"


function mapArray(was, getters, rootState, rootGetters) {
	if (isEmpty(was)) return {}

	if (!rootGetters['coaches/coachesLoadedOnce']) {
		return {}
	}

	let courseUuids = rootGetters['course/programCourseUuids'](was?.university_programs_uuids)

	let courseStarts = rootGetters['courseStart/csByWAS'](courseUuids, was)

	let course_starts = courseStarts.map(cs => {

		let coachAssignmentByCourseStartUuid = rootGetters['coachAssignment/coachAssignmentByCourseStartUuid']
		let csFacultyByCSUuid = rootGetters['courseStartFaculty/csFacultyByCSUuid']
		let cas = coachAssignmentByCourseStartUuid(cs.uuid)
		let faculties = csFacultyByCSUuid(cs.uuid)

		cas = cas.filter(ca => !ca.is_deleted).map(ca => {
			if (rootGetters['coaches/coachesLoadedOnce']) {
				let coachByUuid = rootGetters['coaches/coachByUuid']
				let coach = coachByUuid(ca.coaches_uuid)
				return {
					...ca,
					coach: {
						uuid: coach.uuid,
						display_name: coach.display_name,
					}
				}
			}
		})

		return {
			...cs,
			coach_assignments: cas,
			faculties
		}
	}).sort((f, s) => f.course.number.localeCompare(s.course.number))

	return {
		...was,
		course_starts
	};
}

export function tableMap(was, rootGetters) {
	let university_name_data = `<a href="${process.env.VUE_APP_URL}/universities/${was.universities_uuid}">${rootGetters['university/universityFilter'](was.universities_uuid)?.abbreviation}</a>`;

	let university_name_sortable = rootGetters['university/universityFilter'](was.universities_uuid)?.abbreviation;

	let university_programs = was.university_programs_uuids.map(uuid => {
		let pro = rootGetters['universityProgram/uniProUuidFilter'](uuid) ?? {};

		let lead_coach = rootGetters['users/userByUuid'](pro?.lead_coach_uuid) ?? {};

		return {
			...pro,
			lead_coach
		}
	}, "");

	let reconciliation = rootGetters['wasReconciliation/wasRecFindByWasUuid'](was.uuid);
	let has_university_roster = true;
	const showFor = [
		'pending-rosters',
		'rosters-submitted',
		'rosters-reconciliation-pending',
	]
	if(isEmpty(reconciliation) && showFor.includes(was.status)){
		if(!isEmpty(was.census_date)){
			let today_ymd = format(new Date(), 'yyyyMMdd')
			let census_date = parseISO(was.census_date)
			let census_date_ymd = format(census_date, 'yyyyMMdd')
			if(census_date_ymd < today_ymd){
				has_university_roster = false
			}
		}
	}

	let lead_coach_sortable = university_programs.filter(up => !isEmpty(up.lead_coach)).map(up => up?.lead_coach ?? {}).reduce((u, x) => {
		return u.concat(u.find(lc => lc?.uuid == x.uuid) ? [] : [x])
	}, []).sort((a, b) => a?.display_name.localeCompare(b.display_name)).reduce((s, lc) => {
		let lcn = lc?.display_name

		if (isEmpty(s)) {
			s += lcn;
		} else {
			if (!s.includes(lcn)) {
				s += "/n";
				s += lcn;
			}
		}

		return s;
	}, "") ?? "";

	let university_programs_sortable = university_programs.map(up => up.display_name).toString().replace(",", " ");

	let university_programs_data = university_programs.reduce((s, p) => {
		let newS = `<a href="${process.env.VUE_APP_URL}/university-programs/${p.uuid}">${p.display_name}</a>`;
		if (isEmpty(s)) {
			s += newS;
		} else {
			s += "/n";
			s += newS;
		}

		return s;
	}, "");

	return {
		...was,
		university_programs,
		university_programs_data,
		university_programs_sortable,
		lead_coach_sortable,
		university_name_data,
		university_name_sortable,
		has_university_roster
	};
}

const state = {
	loading: false,
	workingAssignmentSheets: [],
	workingAssignmentSheet: {},
	fullLoad: [],
	wasLoadedOnce: false,
	gotCurrentSheets: false,
	gotFutureSheets: false,
	loadingDates: false,
	wasStatuses: [
		{ label: 'Pending Rosters', value: 'pending-rosters', color: 'danger' },
		{ label: 'Rosters Submitted', value: 'rosters-submitted', color: 'warning' },
		{ label: 'Rosters Reconciliation Pending', value: 'rosters-reconciliation-pending', color: 'primary' },
		{ label: 'Lead Coach Reviewing Duplicates', value: 'lead-coach-reviewing-duplicates', color: 'info' },
		{ label: 'Lead Coach Reviewing Unknowns', value: 'lead-coach-reviewing-university', color: 'info' },
		{ label: 'Admin Reviewing', value: 'admin-reviewing', color: 'info' },
		{ label: 'VP Operations Reviewing', value: 'ops-reviewing', color: 'info' },
		{ label: 'Coach(es) Reviewing', value: 'faculty-coach-reviewing', color: 'info' },
		{ label: 'Rosters Finalized', value: 'rosters-finalized', color: 'success' },
	]
}

const getters = {
	wasLoading: state => state.loading,
	wasLoadedOnce: state => state.wasLoadedOnce,

	wasLoadingDates: state => state.loadingDates,

	gotCurrentSheets: state => state.gotCurrentSheets,
	gotFutureSheets: state => state.gotFutureSheets,

	wasStatuses: state => state.wasStatuses,
	
	workingAssignmentSheet: state => state.workingAssignmentSheet,

	workingAssignmentSheetMapped: (state, getters, rootState, rootGetters) => was => mapArray(was, getters, rootState, rootGetters),

	allWAS: state => state.workingAssignmentSheets.sort((a, b) => parseISO(a.start_date).valueOf() - parseISO(b.start_date).valueOf()),

	wasByUniversity: state => univUuid => state.workingAssignmentSheets.filter(i => i.universities_uuid == univUuid).sort((a, b) => parseISO(a.start_date).valueOf() - parseISO(b.start_date).valueOf()),

	wasUnivProg: state => univProgUuid => state.workingAssignmentSheets.filter(was => was.university_programs_uuids.includes(univProgUuid)).sort((a, b) => parseISO(a.start_date).valueOf() - parseISO(b.start_date).valueOf()),

	wasByUuid: state => uuid => state.workingAssignmentSheets.find(was => was.uuid == uuid),

	wasByStartDateAndLength: state => (date, length, prgUuid) => state.workingAssignmentSheets.find(was => was.university_programs_uuids.includes(prgUuid) && was.start_date == date && was.course_length == length),

	wasByProgramUuids: state => univProgramUuids => state.workingAssignmentSheets.filter(was => {
		return was.university_programs_uuids.some(uuid => univProgramUuids.includes(uuid));
	}).sort((a, b) => parseISO(a.start_date).valueOf() - parseISO(b.start_date).valueOf()),

	wasFutureUnivProgram: (state, getters) => univProgramUuids => getters.wasByProgramUuids(univProgramUuids).filter(was => {
		return parseISO(was.start_date).valueOf() > new Date().valueOf()
	}),

	wasActiveUnivProgram: (state, getters) => univProgramUuids => getters.wasByProgramUuids(univProgramUuids).filter(was => {
		return parseISO(was.start_date).valueOf() <= new Date().valueOf() && _calWasEndDate(was).valueOf() >= new Date().valueOf()
	}),

	wasPastUnivProgram: (state, getters) => univProgramUuids => getters.wasByProgramUuids(univProgramUuids).filter(was => {
		return _calWasEndDate(was).valueOf() < new Date().valueOf();
	}).sort((a, b) => parseISO(a.start_date).valueOf() - parseISO(b.start_date).valueOf()),

	allApprovedFuture: (state, getters, rootState, rootGetters) => state.workingAssignmentSheets.filter(was => parseISO(was.start_date).valueOf() > new Date().valueOf() && was.admin_approved == 1 && was.older_sibling_uuid == null).map(was => {
		return tableMap(was, rootGetters)
	}).sort((a, b) => parseISO(a.start_date).valueOf() - parseISO(b.start_date).valueOf()),

	allApprovedPast: (state, getters, rootState, rootGetters) => state.workingAssignmentSheets.filter(was => parseISO(was.end_date).valueOf() < new Date().valueOf() && was.admin_approved == 1 && was.older_sibling_uuid == null).map(was => {
		return tableMap(was, rootGetters)
	}).sort((a, b) => parseISO(a.start_date).valueOf() - parseISO(b.start_date).valueOf()),

	allApprovedCurrent: (state, getters, rootState, rootGetters) => state.workingAssignmentSheets.filter(was => {
		let end_date = _calWasEndDate(was)
		return parseISO(was.start_date).valueOf() <= new Date().valueOf() &&
			isAfter(end_date, new Date()) &&
			was.admin_approved == 1 &&
			was.older_sibling_uuid == null;
	}).map(was => {
		return tableMap(was, rootGetters)
	}).sort((a, b) => parseISO(a.start_date).valueOf() - parseISO(b.start_date).valueOf()),

	invoiceableSheets: (state, getters, rootState, rootGetters) => state.workingAssignmentSheets.filter(was => was.status == "rosters-finalized" && !was?.invoiced).map(s => tableMap(s, rootGetters)),

	allPast: state => state.workingAssignmentSheets.filter(was => {
		return _calWasEndDate(was).valueOf() < new Date().valueOf()
	}).sort((a, b) => parseISO(a.start_date).valueOf() - parseISO(b.start_date).valueOf()),

	coachesList: (state, getters, rootState, rootGetters) => was => {
		let { course_starts } =  mapArray(was, getters, rootState, rootGetters)

		return course_starts.reduce((arr, cs) => {

			let coaches = cs.coach_assignments?.reduce((a, ca) => {
				a.push({
					...ca,
					course_uuid: cs.course.uuid,
					course_name: cs.course.display_name,
					assigned_to_faculty: false,
					value: ca.uuid,
					isCoach: true,
					label: `${ca.coach.display_name} from ${cs.course.display_name}`,
					attrs: {
						'data-name': ca.coach.display_name
					}
				});

				return a;
			}, []) ?? [];

			let faculty = cs.faculties?.reduce((a, f) => {
				a.push({
					...f,
					course_uuid: cs.course.uuid,
					course_name: cs.course.display_name,
					assigned_to_faculty: true,
					value: f.uuid,
					isCoach: false,
					label: `Faculty of ${cs.course.display_name} (${f.display_name})`,
					attrs: {
						'data-course-start-faculty': true,
						'data-name': f.display_name
					}
				});

				return a;


			}, []) ?? [];

			cs.coach_assignments


			return arr.concat(coaches, faculty);
		}, []);
	},

	wasByCsUuid: (state, getters, rootState, rootGetters) => csUuid => {
		const csByUuid = rootGetters['courseStart/csByUuid']
		const courseFindUuid = rootGetters['course/courseFindUuid']
		const uniProUuidFilter = rootGetters['universityProgram/uniProUuidFilter']
		const userByUuid = rootGetters['users/userByUuid']
		const universityFilter = rootGetters['university/universityFilter']
		let cs = csByUuid(ca.course_starts_uuid)
		if (!isEmpty(cs)) {
			let course = courseFindUuid(cs.courses_uuid)
			if (!isEmpty(course)) {
				let university_program = uniProUuidFilter(course.university_programs_uuid)
				if (!isEmpty(university_program)) {
					let lead_coach = userByUuid(university_program.lead_coach_uuid)
					let university = universityFilter(university_program.university_uuid)
					if (!isEmpty(university)) {
						let was = getters.wasByStartDateAndLength(cs.start_date, cs.course_length, university_program.uuid)
						if (!isEmpty(was)) {
							return {
								...was,
								lead_coach
							}
						}
					}
				}
			}
		}
		return null;
	},

	youngerSiblings: state => uuid => state.workingAssignmentSheets.filter(was => was.older_sibling_uuid == uuid),

	reconciliation: (state, getters, rootState, rootGetters) => wasUuid => rootGetters['wasReconciliation/wasRecFindByWasUuid'](wasUuid),

	singleWasMapped: (state, getters, rootState, rootGetters) => uuid => tableMap(getters.wasByUuid(uuid), rootGetters)
}

const actions = {
	async getWorkingAssignmentSheets({ commit }, params) {
		commit("GET_WORKING_ASSIGNMENT_SHEETS_REQUEST")
		const addToStore = params?.addToStore ?? true
		delete params['addToStore']
		const response = await workingAssignmentSheetsService.getWorkingAssignmentSheets(params);
		const { status, data } = response
		switch (status) {
			case 200:
				const _data = data
				if (data.success && addToStore) {
					_data.working_assignment_sheets = _data.working_assignment_sheets.map(i => unpackWAS(i, commit))
					commit("GET_WORKING_ASSIGNMENT_SHEETS_SUCCESS", _data)
				}

				break
			default:
				commit("GET_WORKING_ASSIGNMENT_SHEETS_ERROR", data)
		}

		if (params.queryType) {
			switch (params.queryType) {
				case "invoiceable":
				case "future-paginated":
				case "current-paginated":
					commit("UPDATE_STATE", { loading: false })
					break
			}
		}

		return response
	},

	async getWorkingAssignmentSheet({ commit, state }, params) {
		const { uuid, forceGet, skipAddForFullLoad } = params
		if (state?.fullLoad.includes(uuid) && isEmpty(forceGet)) {
			const working_assignment_sheet = state.workingAssignmentSheets.find(s => s.uuid == uuid)
			commit("GET_WORKING_ASSIGNMENT_SHEET_SUCCESS", { working_assignment_sheet })
			return {
				success: true
			}
		}
		if (!isEmpty(forceGet)) {
			delete params.forceGet
		}
		if (!isEmpty(skipAddForFullLoad)) {
			delete params.skipAddForFullLoad
		}
		commit("GET_WORKING_ASSIGNMENT_SHEET_REQUEST", uuid)
		const response = await workingAssignmentSheetsService.getWorkingAssignmentSheet(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					data.working_assignment_sheet = unpackWAS(data.working_assignment_sheet, commit)
					if(!isEmpty(skipAddForFullLoad)){
						data.skip_full_load = true
					}
					commit("GET_WORKING_ASSIGNMENT_SHEET_SUCCESS", data)
				}

				break
			default:
				commit("GET_WORKING_ASSIGNMENT_SHEET_ERROR", uuid)
				break
		}
		return response
	},
	async createWorkingAssignmentSheet({ commit, dispatch }, params) {
		commit("CREATE_WORKING_ASSIGNMENT_SHEET_REQUEST")
		const response = await workingAssignmentSheetsService.createWorkingAssignmentSheet(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_WORKING_ASSIGNMENT_SHEET_SUCCESS", data) : commit("CREATE_WORKING_ASSIGNMENT_SHEET_ERROR", data);
				data.success ? dispatch('alert/createAlertSuccess', "Working assignment sheet created!", { root: true }) : dispatch('alert/createAlertErrors', data.errors ? decodeLaravelValidationErrors(data.errors) : "Opps! Something went wrong!", { root: true })
				break
			default:
				commit("CREATE_WORKING_ASSIGNMENT_SHEET_ERROR", data)
		}
		return response
	},
	async editWorkingAssignmentSheet({ commit, dispatch }, params) {
		commit("EDIT_WORKING_ASSIGNMENT_SHEET_REQUEST")
		const response = await workingAssignmentSheetsService.editWorkingAssignmentSheet(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
        data.success ? commit("EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS", data) : null;
        data.success ? dispatch('alert/createAlertSuccess', "Working assignment sheet updated!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true });
        data.error ? commit("EDIT_WORKING_ASSIGNMENT_SHEET_ERROR", data) : null;
				break
			default:
				commit("EDIT_WORKING_ASSIGNMENT_SHEET_ERROR", data)
		}
		return response
	},
	async deleteWorkingAssignmentSheet({ commit }, uuid) {
		commit("DELETE_WORKING_ASSIGNMENT_SHEET_REQUEST")
		const response = await workingAssignmentSheetsService.deleteWorkingAssignmentSheet(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_WORKING_ASSIGNMENT_SHEET_SUCCESS", uuid)
				break
			default:
				commit("DELETE_WORKING_ASSIGNMENT_SHEET_DELETE", data)
		}
		return response
	},
	async sendMessageToAdminOnWas({ commit, dispatch }, params) {
		commit("EDIT_WORKING_ASSIGNMENT_SHEET_REQUEST")
		const response = await workingAssignmentSheetsService.sendMessageToAdminOnWas(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? dispatch('alert/createAlertSuccess', "Message Sent!", { root: true }) : dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
				break
			default:
				dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
				break
		}
		return response
	},
	async sendMessageToLeadOnWas({ commit, dispatch }, params) {
		commit("EDIT_WORKING_ASSIGNMENT_SHEET_REQUEST")
		const response = await workingAssignmentSheetsService.sendMessageToLeadOnWas(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? dispatch('alert/createAlertSuccess', "Message Sent!", { root: true }) : dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
				break
			default:
				dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true })
				break
		}
		return response
	},
	clearWAS({ commit }) {
		commit('CLEAR_WAS')
	},
}

const mutations = {
	[BROADCAST_CREATE_WASR_SUCCESS]: (state, data) => {
		const { working_assignment_sheet } = data

		state.workingAssignmentSheets = state.workingAssignmentSheets.map(was => was.uuid == working_assignment_sheet.uuid ? working_assignment_sheet : was)

		if (working_assignment_sheet.uuid == state.workingAssignmentSheet.uuid) {
			state.workingAssignmentSheet = working_assignment_sheet
		}
	},
	[BROADCAST_EDIT_WASR_SUCCESS]: (state, data) => {
		const { working_assignment_sheet } = data
		state.workingAssignmentSheets = state.workingAssignmentSheets.map(was => was.uuid == working_assignment_sheet.uuid ? working_assignment_sheet : was)
		if (working_assignment_sheet.uuid == state.workingAssignmentSheet.uuid) {
			state.workingAssignmentSheet = working_assignment_sheet
		}
	},
	[BROADCAST_DELETE_WASR_SUCCESS]: (state, data) => {
		const { working_assignment_sheet } = data
		state.workingAssignmentSheets = state.workingAssignmentSheets.map(was => was.uuid == working_assignment_sheet.uuid ? working_assignment_sheet : was)
		if (working_assignment_sheet.uuid == state.workingAssignmentSheet.uuid) {
			state.workingAssignmentSheet = working_assignment_sheet
		}
	},

	[GET_WORKING_ASSIGNMENT_SHEETS_REQUEST]: state => {
		state.loading = true
	},
	[GET_WORKING_ASSIGNMENT_SHEETS_SUCCESS]: (state, data) => {
		let working_assignment_sheets = data.working_assignment_sheets
		state.workingAssignmentSheets = addNewFilter(state.workingAssignmentSheets, working_assignment_sheets)
		state.wasLoadedOnce = true
		state.loading = false
	},
	[GET_WORKING_ASSIGNMENT_SHEETS_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_WORKING_ASSIGNMENT_SHEET_REQUEST]: state => {
		state.loading = true
	},
	[GET_WORKING_ASSIGNMENT_SHEET_SUCCESS]: (state, data) => {
		let { working_assignment_sheet, skip_full_load } = data
		state.workingAssignmentSheet = working_assignment_sheet
		state.workingAssignmentSheets = addNewFilter(state.workingAssignmentSheets, [working_assignment_sheet])
		if(isEmpty(skip_full_load)){
			state.fullLoad = state.fullLoad.filter(i => i != working_assignment_sheet.uuid)
			state.fullLoad.push(working_assignment_sheet.uuid)
		}

		state.loading = false
	},
	[GET_WORKING_ASSIGNMENT_SHEET_ERROR]: (state, data) => {
		state.loading = false
	},
	

	[CREATE_WORKING_ASSIGNMENT_SHEET_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_WORKING_ASSIGNMENT_SHEET_SUCCESS]: (state, data) => {
		const { working_assignment_sheet } = data
		state.workingAssignmentSheets = addNewFilter(state.workingAssignmentSheets, [working_assignment_sheet])
		// state.workingAssignmentSheets = state.workingAssignmentSheets.map(i => i.uuid == working_assignment_sheet.uuid ? working_assignment_sheet : i)
		state.loading = false
	},
	[CREATE_WORKING_ASSIGNMENT_SHEET_ERROR]: state => {
		state.loading = false
	},

	[EDIT_WORKING_ASSIGNMENT_SHEET_REQUEST]: state => {
		state.loading = true
	},
  [EDIT_WORKING_ASSIGNMENT_SHEET_SUCCESS]: (state, data) => {
		const { working_assignment_sheet } = data;

		state.workingAssignmentSheets = addNewFilter(state.workingAssignmentSheets, [working_assignment_sheet]);

		state.loading = false
	},

	[EDIT_WORKING_ASSIGNMENT_SHEET_ERROR]: (state) => {
		state.loading = false
	},

	[DELETE_WORKING_ASSIGNMENT_SHEET_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_WORKING_ASSIGNMENT_SHEET_SUCCESS]: (state, data) => {
		const { working_assignment_sheet } = data;
		state.workingAssignmentSheets = state.workingAssignmentSheets.filter(i => i?.uuid != working_assignment_sheet.uuid);
		state.loading = false
	},
	[DELETE_WORKING_ASSIGNMENT_SHEET_ERROR]: (state, data) => {
		state.loading = false
  },

	BROADCAST_DELETE_WORKING_ASSIGNMENT_SHEET_SUCCESS: (state, data) => {
    const { working_assignment_sheet } = data;
    state.loading = true;
    state.workingAssignmentSheets = state.workingAssignmentSheets.filter(was => was.uuid !== working_assignment_sheet.uuid)
    state.loading = false;
  },

	[UPDATE_STATE]: (state, updateParams) => {
		if (typeof updateParams === 'object' && updateParams !== null) {
			Object.keys(updateParams).forEach(k => {
				state[k] = updateParams[k]
			})
		}
	},

	CLEAR_WAS: state => {
		if (state.fullLoad.some(i => i == state.workingAssignmentSheet.uuid)) {
			state.fullLoad = state.fullLoad.filter(i => i != state.workingAssignmentSheet.uuid)
		}
		if (state.fullLoad.some(i => i == state.workingAssignmentSheet.older_sibling_uuid)) {
			state.fullLoad = state.fullLoad.filter(i => i != state.workingAssignmentSheet.older_sibling_uuid)
		}
		state.workingAssignmentSheet = {}
	},
}

export const workingAssignmentSheet = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

export function unpackWAS(was, commit) {
	if (!isEmpty(was.comments)) {
		commit('comments/GET_COMMENTS_SUCCESS', { comments: was.comments }, { root: true })
		delete was.comments
	}

	if (!isEmpty(was.course_starts)) {
		let course_starts = was.course_starts.map(cs => unpackCourseStart(cs, commit))
		commit("courseStart/GET_COURSE_STARTS_SUCCESS", { course_starts }, { root: true })
		delete was.course_starts
	}

	if (!isEmpty(was.course_starts_resource)) {
		let course_starts = was.course_starts_resource.map(cs => unpackCourseStart(cs, commit))
		commit("courseStart/GET_COURSE_STARTS_SUCCESS", { course_starts }, { root: true })
		delete was.course_starts_resource
	}

	if (!isEmpty(was.reconciliation)) {
		let was_reconciliation = unpackWASReconciliation(was.reconciliation, commit)
		commit("wasReconciliation/GET_WAS_RECONCILIATION_SUCCESS", { was_reconciliation }, { root: true })
		delete was.reconciliation
	}

	if (!isEmpty(was.university)) {
		let university = was.university
		commit("university/GET_UNIVERSITIES_LIMITED_DATA_SUCCESS", { universities: [university] }, { root: true })
		delete was.university
	}

	if (!isEmpty(was.ic_emails_activation_deactivations)) {
		let ic_emails_activation_deactivations = was.ic_emails_activation_deactivations.map(i => unpackIcead(i, commit))
		commit("icEmailsActivationDeactivation/GET_ACTIVATION_DEACTIVATIONS_SUCCESS", { ic_emails_activation_deactivations }, { root: true })
		delete was.ic_emails_activation_deactivations
	}

	if (!isEmpty(was.university_programs)) {

		let university_programs = was.university_programs

		university_programs = university_programs.map(up => {
			return unpackProgramData(up, commit)
		})

		commit("universityProgram/GET_UNIVERSITY_PROGRAMS_SUCCESS", { university_programs }, { root: true })
	}

	return was
}

function _calWasEndDate(was) {
	return was.end_date_override !== null ? parseISO(was.end_date_override) : parseISO(was.end_date);
}
