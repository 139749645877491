import {
  GET_INVOICE_PAYMENTS_REQUEST,
  GET_INVOICE_PAYMENTS_SUCCESS,
  GET_INVOICE_PAYMENTS_ERROR,
  GET_INVOICE_PAYMENT_REQUEST,
  GET_INVOICE_PAYMENT_SUCCESS,
  GET_INVOICE_PAYMENT_ERROR,
  CREATE_INVOICE_PAYMENT_REQUEST,
  CREATE_INVOICE_PAYMENT_SUCCESS,
  CREATE_INVOICE_PAYMENT_ERROR,
  BROADCAST_CREATE_INVOICE_PAYMENT_SUCCESS,
  EDIT_INVOICE_PAYMENT_REQUEST,
  EDIT_INVOICE_PAYMENT_SUCCESS,
  EDIT_INVOICE_PAYMENT_ERROR,
  BROADCAST_EDIT_INVOICE_PAYMENT_SUCCESS,
  DELETE_INVOICE_PAYMENT_REQUEST,
  DELETE_INVOICE_PAYMENT_SUCCESS,
  DELETE_INVOICE_PAYMENT_ERROR,
  BROADCAST_DELETE_INVOICE_PAYMENT_SUCCESS,
} from "@/actions"

import {
  invoicePaymentService
} from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

const state = {
  invoicePayments: [],
  invoicePayment: {},
  loading: false
}

const getters = {
  paymentsByInvoiceUuid: state => invoiceUuid => state.invoicePayments.filter(i => i.invoices_uuid == invoiceUuid),
}

const actions = {
  async getInvoicePayments({ commit }, params) {
    commit("GET_INVOICE_PAYMENTS_REQUEST")
    const response = await invoicePaymentService.getInvoicePayments(params);
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_INVOICE_PAYMENTS_SUCCESS", data)
        break
      default:
        commit("GET_INVOICE_PAYMENTS_ERROR", data)
    }
    return response
  },
  async getInvoicePayment({ commit }, params) {
    commit("GET_INVOICE_PAYMENT_REQUEST")
    const response = await invoicePaymentService.getInvoicePayment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_INVOICE_PAYMENT_SUCCESS", data)
        break
      default:
        commit("GET_INVOICE_PAYMENT_ERROR", data)
        break
    }
    return response
  },
  async createInvoicePayment({ commit, dispatch }, params) {
    commit("CREATE_INVOICE_PAYMENT_REQUEST")
    const response = await invoicePaymentService.createInvoicePayment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_INVOICE_PAYMENT_SUCCESS", data) : null
        data.success ? dispatch("alert/createAlertSuccess", "Invoice Payment created.", { root: true }): null
        break
      default:
        commit("CREATE_INVOICE_PAYMENT_ERROR", data)
    }
    return response
  },
  async editInvoicePayment({ commit }, params) {
    commit("EDIT_INVOICE_PAYMENT_REQUEST")
    const response = await invoicePaymentService.editInvoicePayment(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_INVOICE_PAYMENT_SUCCESS", data) : null
        break
      default:
        commit("EDIT_INVOICE_PAYMENT_ERROR", data)
    }
    return response
  },
  async deleteInvoicePayment({ commit, dispatch }, uuid) {
    commit("DELETE_INVOICE_PAYMENT_REQUEST")
    const response = await invoicePaymentService.deleteInvoicePayment(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_INVOICE_PAYMENT_SUCCESS", uuid) : null
        data.success ? dispatch("alert/createAlertSuccess", "Invoice Payment deleted.", { root: true }) : null
        break
      default:
        commit("DELETE_INVOICE_PAYMENT_ERROR", data)
    }
    return response
  },
  cleanStateInvoicePayment({ commit }) {
    commit("CLEAN_INVOICE_PAYMENT")
  }
}

const mutations = {
  [GET_INVOICE_PAYMENTS_REQUEST]: state => {
    state.loading = true
  },
  [GET_INVOICE_PAYMENTS_SUCCESS]: (state, data) => {
    const invoice_payments = data.invoice_payments
    state.invoicePayments = addNewFilter(state.invoicePayments, invoice_payments)
    state.loading = false
  },
  [GET_INVOICE_PAYMENTS_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_INVOICE_PAYMENT_REQUEST]: state => {
    state.loading = true
  },
  [GET_INVOICE_PAYMENT_SUCCESS]: (state, data) => {
    const { invoice_payment } = data
    state.invoicePayment = invoice_payment
    state.loading = false
  },
  [GET_INVOICE_PAYMENT_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_INVOICE_PAYMENT_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_INVOICE_PAYMENT_SUCCESS]: (state, data) => {
    const { invoice_payment } = data
    state.invoicePayments = addNewFilter(state.invoicePayments, [invoice_payment])
    state.invoicePayment = invoice_payment
    state.loading = false
  },
  [BROADCAST_CREATE_INVOICE_PAYMENT_SUCCESS]: (state, data) => {
    const { invoice_payment } = data
    state.invoicePayments = addNewFilter(state.invoicePayments, [invoice_payment])
  },
  [CREATE_INVOICE_PAYMENT_ERROR]: state => {
    state.loading = false
  },

  [EDIT_INVOICE_PAYMENT_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_INVOICE_PAYMENT_SUCCESS]: (state, data) => {
    const { invoice_payment } = data
    state.invoicePayments = state.invoicePayments.map(i => i.uuid == invoice_payment.uuid ? invoice_payment : i)
    if (!isEmpty(state.invoicePayment) && state.invoicePayment.uuid == invoice_payment.uuid) {
      state.invoicePayment = invoice_payment
    }
    state.loading = false
  },
  [EDIT_INVOICE_PAYMENT_ERROR]: state => {
    state.loading = false
  },
  [BROADCAST_EDIT_INVOICE_PAYMENT_SUCCESS]: (state, data) => {
    const { invoice_payment } = data
    state.invoicePayments = state.invoicePayments.map(i => i.uuid == invoice_payment.uuid ? invoice_payment : i)
    if (!isEmpty(state.invoicePayment) && state.invoicePayment.uuid == invoice_payment.uuid) {
      state.invoicePayment = invoice_payment
    }
  },
  [DELETE_INVOICE_PAYMENT_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_INVOICE_PAYMENT_SUCCESS]: (state, uuid) => {
    state.invoicePayments = state.invoicePayments.filter(i => i.uuid !== uuid)
    state.loading = false
  },
  [DELETE_INVOICE_PAYMENT_ERROR]: (state, data) => {
    state.loading = false
  },
  [BROADCAST_DELETE_INVOICE_PAYMENT_SUCCESS]: (state, data) => {
    const { invoice_payment } = data
    state.invoicePayments = state.invoicePayments.filter(i => i.uuid == invoice_payment.uuid ? false : true)
    state.loading = false
  }
}

export const invoicePayment = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}