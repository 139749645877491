import {
    apiRequest,
    processInput
} from "@/utilities"

export const issuingAuthorityService = {
    async getIssuingAuthorities() {
        const getIssuingAuthoritiesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/issuing-authorities", getIssuingAuthoritiesRequest)
    },
    async getIssuingAuthority(uuid) {
        const getIssuingAuthorityRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/issuing-authorities/${uuid}`, getIssuingAuthorityRequest)
    },
    async createIssuingAuthority(params) {
        const createData = processInput(params)
        const createIssuingAuthorityRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/issuing-authorities", createIssuingAuthorityRequest)
    },
    async editIssuingAuthority(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editIssuingAuthorityRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/issuing-authorities/${uuid}`, editIssuingAuthorityRequest)
    },
    async deleteIssuingAuthority(uuid) {
        const deleteIssuingAuthorityRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/issuing-authorities/${uuid}`, deleteIssuingAuthorityRequest)
    }
}