export const GET_CONTRACT_IOS_REQUEST = "GET_CONTRACT_IOS_REQUEST"
export const GET_CONTRACT_IOS_SUCCESS = "GET_CONTRACT_IOS_SUCCESS"
export const GET_CONTRACT_IOS_ERROR = "GET_CONTRACT_IOS_ERROR"
export const GET_CONTRACT_IO_REQUEST = "GET_CONTRACT_IO_REQUEST"
export const GET_CONTRACT_IO_SUCCESS = "GET_CONTRACT_IO_SUCCESS"
export const GET_CONTRACT_IO_ERROR = "GET_CONTRACT_IO_ERROR" 
export const CREATE_CONTRACT_IO_REQUEST = "CREATE_CONTRACT_IO_REQUEST"
export const CREATE_CONTRACT_IO_SUCCESS = "CREATE_CONTRACT_IO_SUCCESS"
export const CREATE_CONTRACT_IO_ERROR = "CREATE_CONTRACT_IO_ERROR"
export const EDIT_CONTRACT_IO_REQUEST = "EDIT_CONTRACT_IO_REQUEST"
export const EDIT_CONTRACT_IO_SUCCESS = "EDIT_CONTRACT_IO_SUCCESS"
export const EDIT_CONTRACT_IO_ERROR = "EDIT_CONTRACT_IO_ERROR"
export const DELETE_CONTRACT_IO_REQUEST = "DELETE_CONTRACT_IO_REQUEST"
export const DELETE_CONTRACT_IO_SUCCESS = "DELETE_CONTRACT_IO_SUCCESS"
export const DELETE_CONTRACT_IO_ERROR = "DELETE_CONTRACT_IO_ERROR"