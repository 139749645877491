export const GET_CONTRACT_PRICE_TYPES_REQUEST = "GET_CONTRACT_PRICE_TYPES_REQUEST"
export const GET_CONTRACT_PRICE_TYPES_SUCCESS = "GET_CONTRACT_PRICE_TYPES_SUCCESS"
export const GET_CONTRACT_PRICE_TYPES_ERROR = "GET_CONTRACT_PRICE_TYPES_ERROR"
export const GET_CONTRACT_PRICE_TYPE_REQUEST = "GET_CONTRACT_PRICE_TYPE_REQUEST"
export const GET_CONTRACT_PRICE_TYPE_SUCCESS = "GET_CONTRACT_PRICE_TYPE_SUCCESS"
export const GET_CONTRACT_PRICE_TYPE_ERROR = "GET_CONTRACT_PRICE_TYPE_ERROR" 
export const CREATE_CONTRACT_PRICE_TYPE_REQUEST = "CREATE_CONTRACT_PRICE_TYPE_REQUEST"
export const CREATE_CONTRACT_PRICE_TYPE_SUCCESS = "CREATE_CONTRACT_PRICE_TYPE_SUCCESS"
export const CREATE_CONTRACT_PRICE_TYPE_ERROR = "CREATE_CONTRACT_PRICE_TYPE_ERROR"
export const EDIT_CONTRACT_PRICE_TYPE_REQUEST = "EDIT_CONTRACT_PRICE_TYPE_REQUEST"
export const EDIT_CONTRACT_PRICE_TYPE_SUCCESS = "EDIT_CONTRACT_PRICE_TYPE_SUCCESS"
export const EDIT_CONTRACT_PRICE_TYPE_ERROR = "EDIT_CONTRACT_PRICE_TYPE_ERROR"
export const DELETE_CONTRACT_PRICE_TYPE_REQUEST = "DELETE_CONTRACT_PRICE_TYPE_REQUEST"
export const DELETE_CONTRACT_PRICE_TYPE_SUCCESS = "DELETE_CONTRACT_PRICE_TYPE_SUCCESS"
export const DELETE_CONTRACT_PRICE_TYPE_ERROR = "DELETE_CONTRACT_PRICE_TYPE_ERROR"