import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faLayerGroup,
    faUniversity,
    faIdBadge,
    faUserMd,
    faChalkboardTeacher,
    faUsers,
    faUserCheck,
    faCloudUploadAlt,
    faPaperPlane,
    faUserSecret,
    faCheckCircle,
    faCheckSquare,
    faBell,
    faGraduationCap
} from '@fortawesome/free-solid-svg-icons'

// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default library.add(
    faLayerGroup,
    faUniversity,
    faIdBadge,
    faUserMd,
    faChalkboardTeacher,
    faUsers,
    faUserCheck,
    faCloudUploadAlt,
    faPaperPlane,
    faUserSecret,
    faCheckCircle,
    faCheckSquare,
    faBell,
    faGraduationCap
)