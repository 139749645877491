import Vue from 'vue';

export const containerMutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    Vue.set(state, variable, value)

  },
  toggle(state, key) {
    const toggleMap = window.localStorage.getItem('toggleMap') ?
      JSON.parse(window.localStorage.getItem('toggleMap')) :
      {};

    const value = !toggleMap[key];

    toggleMap[key] = value

    window.localStorage.setItem('toggleMap', JSON.stringify(toggleMap))
    Vue.set(state.toggle, key, value)
  },
  updateCurrentPath(state, path) {
    state.currentPath = { ...path }
  },
  updateCurrentUuid(state, uuid) {
    state.currentUuid = uuid
  }
}
