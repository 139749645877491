import {
    GET_USER_ROLES_REQUEST,
    GET_USER_ROLES_SUCCESS,
    GET_USER_ROLES_ERROR,
    GET_USER_ROLE_REQUEST,
    GET_USER_ROLE_SUCCESS,
    GET_USER_ROLE_ERROR,
    CREATE_USER_ROLE_REQUEST,
    CREATE_USER_ROLE_SUCCESS,
    CREATE_USER_ROLE_ERROR,
    EDIT_USER_ROLE_REQUEST,
    EDIT_USER_ROLE_SUCCESS,
    EDIT_USER_ROLE_ERROR,
    DELETE_USER_ROLE_REQUEST,
    DELETE_USER_ROLE_SUCCESS,
    DELETE_USER_ROLE_ERROR
} from "@/actions"

import {
    userRoleService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    userRoles: [],
    userRole: {},
    loading: false,
    loadedOnce: false
}

const getters = {
    userRoleLoadedOnce: state => state.loadedOnce,
    userRoles: state => state.userRoles
}

const actions = {
    async assignUserRole({ commit }, params) {
        commit("CREATE_USER_ROLE_REQUEST")
        const response = await userRoleService.assignUserRole(params)
        const { status, data } = response
        switch (status) {
            case 200:
                // if(data.success){
                //     commit("users/GET_USERS_SUCCESS", data, { root: true })
                //     if(!isEmpty(params.users_uuid)){
                //         if(params.users_uuid.length === 1){
                //             let userData = {
                //                 user: data.users.find((i,idx) => idx === 0)
                //             }
                //             commit("users/GET_USER_SUCCESS", userData, { root: true })
                //         }
                //     }
                // }
                break
            default:
                commit("CREATE_USER_ROLE_ERROR", data)
        }
        return response
    },
    async revokeUserRole({ commit }, params) {
        commit("EDIT_USER_ROLE_REQUEST")
        const response = await userRoleService.revokeUserRole(params)
        const { status, data } = response
        switch (status) {
            case 200:
                if(data.success){
                    // commit("users/GET_USERS_SUCCESS", data, { root: true })
                    // if(!isEmpty(params.users_uuid)){
                    //     if(params.users_uuid.length === 1){
                    //         let userData = {
                    //             user: data.users.find((i,idx) => idx === 0)
                    //         }
                    //         commit("users/GET_USER_SUCCESS", userData, { root: true })
                    //     }
                    // }
                }
                break
            default:
                commit("EDIT_USER_ROLE_ERROR", data)
        }
        return response
    },
}

const mutations = {
    [GET_USER_ROLES_REQUEST]: state => {
        state.loading = true
    },
    [GET_USER_ROLES_SUCCESS]: (state, data) => {
        const userRoles = [...data.learning_management_systems]
        state.userRoles = userRoles
        state.loading = false
        state.loadedOnce = true
    },
    [GET_USER_ROLES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_USER_ROLE_REQUEST]: state => {
        state.loading = true
    },
    [GET_USER_ROLE_SUCCESS]: (state, data) => {
        const userRole = { ...data.userRole }
        state.userRole = userRole
        state.loading = false
    },
    [GET_USER_ROLE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_USER_ROLE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_USER_ROLE_SUCCESS]: (state, data) => {
        let userRole = [data.userRole]
        state.userRoles = addNewFilter(state.userRoles, userRole)
        state.loading = false
    },
    [CREATE_USER_ROLE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_USER_ROLE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_USER_ROLE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_USER_ROLE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_USER_ROLE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_USER_ROLE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_USER_ROLE_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const userRole = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
