import {
    apiRequest,
    processInput
} from "@/utilities"

export const contractIOSService = {
    async getContractIOS() {
        const getContractIOSRequest = {
            method: "GET"
        }
        return await apiRequest("/api/contract-ios", getContractIOSRequest)
    },
    async createContractIO(params) {
        const createData = processInput(params);
        const postContractIoRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/contract-ios", postContractIoRequest)
    },
    async getContractIO(uuid) {

        const getContractIoRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/contract-ios/${uuid}`, getContractIoRequest);
    },
    async editContractIO(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateContractIoRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/contract-ios/${uuid}`, updateContractIoRequest);
    },
    async deleteContractIO(uuid) {

        const deleteContractIoRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/contract-ios/${uuid}`, deleteContractIoRequest);
    }
}