import {
    GET_ADDENDUMS_REQUEST,
    GET_ADDENDUMS_SUCCESS,
    GET_ADDENDUMS_ERROR,
    GET_ADDENDUM_REQUEST,
    GET_ADDENDUM_SUCCESS,
    GET_ADDENDUM_ERROR,
    CREATE_ADDENDUM_REQUEST,
    CREATE_ADDENDUM_SUCCESS,
    CREATE_ADDENDUM_ERROR,
    EDIT_ADDENDUM_REQUEST,
    EDIT_ADDENDUM_SUCCESS,
    EDIT_ADDENDUM_ERROR,
    DELETE_ADDENDUM_REQUEST,
    DELETE_ADDENDUM_SUCCESS,
    DELETE_ADDENDUM_ERROR
} from "@/actions"

import {
    addendumService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
    addendums: [],
    addendum: {},
    loading: false
}

const getters = {
    allAddendums: state => state.addendums,
    findAddendum: state => uuid => state.addendums.find(i => i.uuid == uuid || i.slug == uuid),
}

const actions = {
    async getAddendums({ commit }) {
        commit("GET_ADDENDUMS_REQUEST")
        const response = await addendumService.getAddendums();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_ADDENDUMS_SUCCESS", data)
                break
            default:
                commit("GET_ADDENDUMS_ERROR", data)
        }
        return response
    },
    async getAddendum({ commit }, params) {
        commit("GET_ADDENDUM_REQUEST")
        const response = await addendumService.getAddendum(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_ADDENDUM_SUCCESS", data)
                break
            default:
                commit("GET_ADDENDUM_ERROR", data)
                break
        }
        return response
    },
    async createAddendum({ commit }, params) {
        commit("CREATE_ADDENDUM_REQUEST")
        const response = await addendumService.createAddendum(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_ADDENDUM_SUCCESS", data)
                break
            default:
                commit("CREATE_ADDENDUM_ERROR", data)
        }
        return response
    },
    async editAddendum({ commit }, params) {
        commit("EDIT_ADDENDUM_REQUEST")
        const response = await addendumService.editAddendum(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_ADDENDUM_SUCCESS", data)
                break
            default:
                commit("EDIT_ADDENDUM_ERROR", data)
        }
        return response
    },
    async deleteAddendum({ commit }, params) {
        commit("DELETE_ADDENDUM_REQUEST")
        const response = await addendumService.deleteAddendum(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_ADDENDUM_SUCCESS", data)
                break
            default:
                commit("DELETE_ADDENDUM_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_ADDENDUMS_REQUEST]: state => {
        state.loading = true
    },
    [GET_ADDENDUMS_SUCCESS]: (state, data) => {
        state.addendums = addNewFilter(state.addendums, data.addendums)
        state.loading = false
    },
    [GET_ADDENDUMS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_ADDENDUM_REQUEST]: state => {
        state.loading = true
    },
    [GET_ADDENDUM_SUCCESS]: (state, data) => {
        let mdata = { ...data.addendum }
        state.addendum = mdata
        state.loading = false
    },
    [GET_ADDENDUM_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_ADDENDUM_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_ADDENDUM_SUCCESS]: (state, data) => {
        let addendums = [data.addendum]
        state.addendums = addNewFilter(state.addendums, addendums)
        state.loading = false
    },
    [CREATE_ADDENDUM_ERROR]: state => {
        state.loading = false
    },

    [EDIT_ADDENDUM_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_ADDENDUM_SUCCESS]: (state, data) => {
        if(data.success){
            state.addendums = state.addendums.filter(f => f.uuid != data.addendum.uuid)
            let addendums = [data.addendum]
            state.addendums = addNewFilter(state.addendums, addendums)
            state.loading = false
        }
        state.loading = false
    },
    [EDIT_ADDENDUM_ERROR]: state => {
        state.loading = false
    },

    [DELETE_ADDENDUM_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_ADDENDUM_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_ADDENDUM_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const addendum = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}