import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const courseStartReconciliationService = {
    async getCourseStartReconciliations(params) {
        let getParams = processQueryParams(params)
        const getCourseStartReconciliationsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/course-start-reconciliations", getCourseStartReconciliationsRequest)
    },
    async getCourseStartReconciliation(uuid) {
        const getCourseStartReconciliationRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/course-start-reconciliations/${uuid}`, getCourseStartReconciliationRequest)
    },
    async createCourseStartReconciliation(params) {
        const createData = processInput(params)
        const createCourseStartReconciliationRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/course-start-reconciliations", createCourseStartReconciliationRequest)
    },
    async editCourseStartReconciliation(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCourseStartReconciliationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/course-start-reconciliations/${uuid}`, editCourseStartReconciliationRequest)
    },
    async deleteCourseStartReconciliation(uuid) {
        const deleteCourseStartReconciliationRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/course-start-reconciliations/${uuid}`, deleteCourseStartReconciliationRequest)
    }
}