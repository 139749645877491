import {
    GET_REFERRAL_SOURCES_REQUEST,
    GET_REFERRAL_SOURCES_SUCCESS,
    GET_REFERRAL_SOURCES_ERROR,
    GET_REFERRAL_SOURCE_REQUEST,
    GET_REFERRAL_SOURCE_SUCCESS,
    GET_REFERRAL_SOURCE_ERROR,
    CREATE_REFERRAL_SOURCE_REQUEST,
    CREATE_REFERRAL_SOURCE_SUCCESS,
    CREATE_REFERRAL_SOURCE_ERROR,
    EDIT_REFERRAL_SOURCE_REQUEST,
    EDIT_REFERRAL_SOURCE_SUCCESS,
    EDIT_REFERRAL_SOURCE_ERROR,
    DELETE_REFERRAL_SOURCE_REQUEST,
    DELETE_REFERRAL_SOURCE_SUCCESS,
    DELETE_REFERRAL_SOURCE_ERROR
} from "@/actions"

import {
    referralSourceService
} from "@/services"

const state = {
    referralSources: [],
    referralSource: {},
    loading: false
}

const getters = {
    referralSourceSelectOptions: state => state.referralSources.map(rs => ({ value: rs.uuid, label: rs.name })),
    referralSourceByUuid: state => uuid => state.referralSources.find(rs => rs.uuid == uuid)
}

const actions = {
    async getReferralSources({ commit }) {
        commit("GET_REFERRAL_SOURCES_REQUEST")
        const response = await referralSourceService.getReferralSources();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_REFERRAL_SOURCES_SUCCESS", data)
                break
            default:
                commit("GET_REFERRAL_SOURCES_ERROR", data)
        }
        return response
    },
    async getReferralSource({ commit }, params) {
        commit("GET_REFERRAL_SOURCE_REQUEST")
        const response = await referralSourceService.getReferralSource(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_REFERRAL_SOURCE_SUCCESS", data)
                break
            default:
                commit("GET_REFERRAL_SOURCE_ERROR", data)
                break
        }
        return response
    },
    async createReferralSource({ commit }, params) {
        commit("CREATE_REFERRAL_SOURCE_REQUEST")
        const response = await referralSourceService.createReferralSource(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_REFERRAL_SOURCE_SUCCESS", data)
                break
            default:
                commit("CREATE_REFERRAL_SOURCE_ERROR", data)
        }
        return response
    },
    async editReferralSource({ commit }, params, uuid) {
        commit("EDIT_REFERRAL_SOURCE_REQUEST")
        const response = await referralSourceService.editReferralSource(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_REFERRAL_SOURCE_SUCCESS", data)
                break
            default:
                commit("EDIT_REFERRAL_SOURCE_ERROR", data)
        }
        return response
    },
    async deleteReferralSource({ commit }, params) {
        commit("DELETE_REFERRAL_SOURCE_REQUEST")
        const response = await referralSourceService.deleteReferralSource(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_REFERRAL_SOURCE_SUCCESS", data)
                break
            default:
                commit("DELETE_REFERRAL_SOURCE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_REFERRAL_SOURCES_REQUEST]: state => {
        state.loading = true
    },
    [GET_REFERRAL_SOURCES_SUCCESS]: (state, data) => {
        const referralSources = data.referral_sources
        state.referralSources = referralSources
        state.loading = false
    },
    [GET_REFERRAL_SOURCES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_REFERRAL_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [GET_REFERRAL_SOURCE_SUCCESS]: (state, data) => {
        const { referral_source } = data
        state.referralSource = referral_source
        state.loading = false
    },
    [GET_REFERRAL_SOURCE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_REFERRAL_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_REFERRAL_SOURCE_SUCCESS]: (state, data) => {
        const { referral_source } = data
        state.referralSources = addNewFilter(state.referralSources, [referral_source])
        state.loading = false
    },
    [CREATE_REFERRAL_SOURCE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_REFERRAL_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_REFERRAL_SOURCE_SUCCESS]: (state, data) => {
        const { referral_source } = data
        state.referralSources = state.referralSources.map(rs => rs.uuid == referral_source.uuid ? referral_source : rs)
        if (state.referralSource.uuid == referral_source.uuid) {
            state.referralSource = referral_source
        }
        state.loading = false
    },
    [EDIT_REFERRAL_SOURCE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_REFERRAL_SOURCE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_REFERRAL_SOURCE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_REFERRAL_SOURCE_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const referralSource = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}