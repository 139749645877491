import {
  GET_LMSS_REQUEST,
  GET_LMSS_SUCCESS,
  GET_LMSS_ERROR,
  GET_LMS_REQUEST,
  GET_LMS_SUCCESS,
  GET_LMS_ERROR,
  CREATE_LMS_REQUEST,
  CREATE_LMS_SUCCESS,
  CREATE_LMS_ERROR,
  EDIT_LMS_REQUEST,
  EDIT_LMS_SUCCESS,
  EDIT_LMS_ERROR,
  DELETE_LMS_REQUEST,
  DELETE_LMS_SUCCESS,
  DELETE_LMS_ERROR
} from "@/actions"

import {
  lmsService
} from "@/services"

import { addNewFilter } from "@/utilities"

const state = {
  lmss: [],
  lms: {},
  loading: false,
  loadedOnce: false
}

const getters = {
  lmsLoadedOnce: state => state.loadedOnce,
  lmsSelectOptions: state => {
    return state.lmss.map(lms => {
      return { value: lms.uuid, label: lms.name }
    })
  },
  lmsMultiSelectOptions: state => {
    return state.lmss.map(lms => {
      return { value: lms.uuid, text: lms.name }
    }).sort((a, b) => a.text.localeCompare(b.text))
  },
  lmsNoneOption: state => state.lmss.find(lms => lms.slug == "NONE")
}

const actions = {
  async getLmss({ commit }) {
    commit("GET_LMSS_REQUEST")
    const response = await lmsService.getLmss();
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_LMSS_SUCCESS", data)
        break
      default:
        commit("GET_LMSS_ERROR", data)
    }
    return response
  },
  async getLms({ commit }, params) {
    commit("GET_LMS_REQUEST")
    const response = await lmsService.getLms(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_LMS_SUCCESS", data)
        break
      default:
        commit("GET_LMS_ERROR", data)
        break
    }
    return response
  },
  async createLms({ commit }, params) {
    commit("CREATE_LMS_REQUEST")
    const response = await lmsService.createLms(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("CREATE_LMS_SUCCESS", data)
        break
      default:
        commit("CREATE_LMS_ERROR", data)
    }
    return response
  },
  async editLms({ commit }, params, uuid) {
    commit("EDIT_LMS_REQUEST")
    const response = await lmsService.editLms(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("EDIT_LMS_SUCCESS", data)
        break
      default:
        commit("EDIT_LMS_ERROR", data)
    }
    return response
  },
  async deleteLms({ commit }, params) {
    commit("DELETE_LMS_REQUEST")
    const response = await lmsService.deleteLms(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_LMS_SUCCESS", data)
        break
      default:
        commit("DELETE_LMS_DELETE", data)
    }
    return response
  }
}

const mutations = {
  [GET_LMSS_REQUEST]: state => {
    state.loading = true
  },
  [GET_LMSS_SUCCESS]: (state, data) => {
    const lmss = [...data.learning_management_systems]
    state.lmss = lmss
    state.loading = false
    state.loadedOnce = true
  },
  [GET_LMSS_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_LMS_REQUEST]: state => {
    state.loading = true
  },
  [GET_LMS_SUCCESS]: (state, data) => {
    const lms = { ...data.lms }
    state.lms = lms
    state.loading = false
  },
  [GET_LMS_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_LMS_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_LMS_SUCCESS]: (state, data) => {
    let lms = [data.lms]
    state.lmss = addNewFilter(state.lmss, lms)
    state.loading = false
  },
  [CREATE_LMS_ERROR]: state => {
    state.loading = false
  },

  [EDIT_LMS_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_LMS_SUCCESS]: state => {
    state.loading = false
  },
  [EDIT_LMS_ERROR]: state => {
    state.loading = false
  },

  [DELETE_LMS_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_LMS_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [DELETE_LMS_ERROR]: (state, data) => {
    state.loading = false
  }

}

export const lms = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
