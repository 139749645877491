export const GET_COACH_ASSIGNMENTS_REQUEST = "GET_COACH_ASSIGNMENTS_REQUEST"
export const GET_COACH_ASSIGNMENTS_SUCCESS = "GET_COACH_ASSIGNMENTS_SUCCESS"
export const GET_COACH_ASSIGNMENTS_ERROR = "GET_COACH_ASSIGNMENTS_ERROR"
export const GET_COACH_ASSIGNMENT_REQUEST = "GET_COACH_ASSIGNMENT_REQUEST"
export const GET_COACH_ASSIGNMENT_SUCCESS = "GET_COACH_ASSIGNMENT_SUCCESS"
export const GET_COACH_ASSIGNMENT_ERROR = "GET_COACH_ASSIGNMENT_ERROR" 
export const CREATE_COACH_ASSIGNMENT_REQUEST = "CREATE_COACH_ASSIGNMENT_REQUEST"
export const CREATE_COACH_ASSIGNMENT_SUCCESS = "CREATE_COACH_ASSIGNMENT_SUCCESS"
export const CREATE_COACH_ASSIGNMENT_ERROR = "CREATE_COACH_ASSIGNMENT_ERROR"
export const CREATE_BULK_COACH_ASSIGNMENT_REQUEST = "CREATE_BULK_COACH_ASSIGNMENT_REQUEST"
export const CREATE_BULK_COACH_ASSIGNMENT_SUCCESS = "CREATE_BULK_COACH_ASSIGNMENT_SUCCESS"
export const CREATE_BULK_COACH_ASSIGNMENT_ERROR = "CREATE_BULK_COACH_ASSIGNMENT_ERROR"
export const EDIT_COACH_ASSIGNMENT_REQUEST = "EDIT_COACH_ASSIGNMENT_REQUEST"
export const EDIT_COACH_ASSIGNMENT_SUCCESS = "EDIT_COACH_ASSIGNMENT_SUCCESS"
export const EDIT_COACH_ASSIGNMENT_ERROR = "EDIT_COACH_ASSIGNMENT_ERROR"
export const DELETE_COACH_ASSIGNMENT_REQUEST = "DELETE_COACH_ASSIGNMENT_REQUEST"
export const DELETE_COACH_ASSIGNMENT_SUCCESS = "DELETE_COACH_ASSIGNMENT_SUCCESS"
export const DELETE_COACH_ASSIGNMENT_ERROR = "DELETE_COACH_ASSIGNMENT_ERROR"
export const BROADCAST_CREATE_CAR_SUCCESS = "BROADCAST_CREATE_CAR_SUCCESS"
export const BROADCAST_UPDATED_CAR_SUCCESS = "BROADCAST_UPDATED_CAR_SUCCESS"
export const BROADCAST_DELETED_CAR_SUCCESS = "BROADCAST_DELETED_CAR_SUCCESS"
export const CREATE_ASSIGNMENT_REPLACEMENT_REQUEST = "CREATE_ASSIGNMENT_REPLACEMENT_REQUEST"
export const CREATE_ASSIGNMENT_REPLACEMENT_SUCCESS = "CREATE_ASSIGNMENT_REPLACEMENT_SUCCESS"
export const CREATE_ASSIGNMENT_REPLACEMENT_ERROR = "CREATE_ASSIGNMENT_REPLACEMENT_ERROR"
export const BROADCAST_DELETE_COACH_ASSIGNMENT_SUCCESS = "BROADCAST_DELETE_COACH_ASSIGNMENT_SUCCESS"