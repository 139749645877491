import {
  apiRequest,
  processInput
} from "@/utilities"

export const prefixService = {
  async getPrefixes() {
    const getPrefixesRequest = {
      method: "GET"
    }
    return await apiRequest("/api/prefixes", getPrefixesRequest)
  },
  async getPrefix(uuid) {
    const getPrefixRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/prefixes/${uuid}`, getPrefixRequest)
  },
  async createPrefix(params) {
    const createData = processInput(params)
    const createPrefixRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/prefixes", createPrefixRequest)
  },
  async editPrefix(data, uuid) {
    let editData = processInput(data)
    editData.append("_method", "PATCH")
    const editPrefixRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/prefixes/${uuid}`, editPrefixRequest)
  },
  async deletePrefix(uuid) {
    const deletePrefixRequest = {
      method: "DELETE"
    }
    return await apiRequest(`/api/prefixes/${uuid}`, deletePrefixRequest)
  }
}
