import {
    GET_OFFERS_REQUEST,
    GET_OFFERS_SUCCESS,
    GET_OFFERS_ERROR,
    GET_OFFER_REQUEST,
    GET_OFFER_SUCCESS,
    GET_OFFER_ERROR,
    CREATE_OFFER_REQUEST,
    CREATE_OFFER_SUCCESS,
    CREATE_OFFER_ERROR,
    EDIT_OFFER_REQUEST,
    EDIT_OFFER_SUCCESS,
    EDIT_OFFER_ERROR,
    DELETE_OFFER_REQUEST,
    DELETE_OFFER_SUCCESS,
    DELETE_OFFER_ERROR
} from "@/actions"

import {
    offerService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities";

const state = {
    loading: false,
    offerLoadedOnce: false,
    offers: [],
    offer: {},
}

const getters = {
    offer: state => state.offer
}

const actions = {
    async getOffers({ commit }) {
        commit("GET_OFFERS_REQUEST")
        const response = await offerService.getOffers();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_OFFERS_SUCCESS", data)
                break
            default:
                commit("GET_OFFERS_ERROR", data)
        }
        return response
    },

    async getOffer({ commit }, params) {
        commit("GET_OFFER_REQUEST")
        const response = await offerService.getOffer(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_OFFER_SUCCESS", data)
                break
            default:
                commit("GET_OFFER_ERROR", data)
                break
        }
        return response
    },
    async createOffer({ commit, dispatch }, params) {
        commit("CREATE_OFFER_REQUEST")
        const response = await offerService.createOffer(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_OFFER_SUCCESS", data) : commit("CREATE_OFFER_ERROR", data)
                data.success ? dispatch('alert/createAlertSuccess', "Offer sent.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
                break
            default:
                commit("CREATE_OFFER_ERROR", data)
        }
        return response
    },
    async editOffer({ commit }, params) {
        commit("EDIT_OFFER_REQUEST")
        const response = await offerService.editOffer(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_OFFER_SUCCESS", data)
                break
            default:
                commit("EDIT_OFFER_ERROR", data)
        }
        return response
    },
    async deleteOffer({ commit }, params) {
        commit("DELETE_OFFER_REQUEST")
        const uuid = params.uuid
        const response = await offerService.deleteOffer(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("DELETE_OFFER_SUCCESS", uuid) : commit("DELETE_OFFER_ERROR", data)
                break
            default:
                commit("DELETE_OFFER_ERROR", data)
        }
        return response
    }
}

const mutations = {
    [GET_OFFERS_REQUEST]: state => {
        state.loading = true
    },
    [GET_OFFERS_SUCCESS]: (state, data) => {
        let offers = [...data.offers]
        state.offers = offers
        state.offerLoadedOnce = true
        state.loading = false
    },
    [GET_OFFERS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_OFFER_REQUEST]: state => {
        state.loading = true
    },
    [GET_OFFER_SUCCESS]: (state, data) => {
        let { offer } = data
        state.offer = offer
        state.loading = false
    },
    [GET_OFFER_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_OFFER_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_OFFER_SUCCESS]: (state, data) => {
        if(isEmpty(data.offers)){
            if(!isEmpty(data.offer)){
                let { offer } = data
                state.offers = addNewFilter(state.offers, [offer])
            }
        }else{
            state.offers = addNewFilter(state.offers, data.offers)
        }
        state.loading = false
    },
    [CREATE_OFFER_ERROR]: state => {
        state.loading = false
    },

    [EDIT_OFFER_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_OFFER_SUCCESS]: (state, data) => {
        state.offers = state.offers.map(i => {
            if (i.uuid == data.offer.uuid) return data.offer
            return i
        })
        state.loading = false
    },
    [EDIT_OFFER_ERROR]: state => {
        state.loading = false
    },

    [DELETE_OFFER_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_OFFER_SUCCESS]: (state, uuid) => {
        state.offers = state.offers.filter(i => i.uuid != uuid)
        state.loading = false
    },
    [DELETE_OFFER_ERROR]: (state, data) => {
        state.loading = false
    },

}

export const offer = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}