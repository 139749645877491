import {
    GET_CONTRACTS_REQUEST,
    GET_CONTRACTS_SUCCESS,
    GET_CONTRACTS_ERROR,
    GET_CONTRACT_REQUEST,
    GET_CONTRACT_SUCCESS,
    GET_CONTRACT_ERROR,
    CREATE_CONTRACT_REQUEST,
    CREATE_CONTRACT_SUCCESS,
    CREATE_CONTRACT_ERROR,
    EDIT_CONTRACT_REQUEST,
    EDIT_CONTRACT_SUCCESS,
    EDIT_CONTRACT_ERROR,
    DELETE_CONTRACT_REQUEST,
    DELETE_CONTRACT_SUCCESS,
    DELETE_CONTRACT_ERROR
} from "@/actions"

import {
    contractsService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"
import { unpackContractIo, unpackContractAddendum } from "@/modules"
import { compareDesc, parseISO, isEqual } from "date-fns";

const state = {
    contracts: [],
    contract: {},
    loading: false,
    dataLoaded: false,
}


function mapArray(contracts){
    const finalizedContracts = contracts.filter(i => i.is_finalized)
    if(isEmpty(finalizedContracts)) return [];
    const expDates = finalizedContracts.map(i => parseISO(i.expiration_date))
    const descArr = expDates.sort(compareDesc)
    const lastContractExpireOn = descArr.find((i,idx) => idx === 0)
    const lastContract = finalizedContracts.find(i => isEqual(parseISO(i.expiration_date),lastContractExpireOn))
    if(isEmpty(lastContract)) return [];
    return contracts.map(i => {
        const is_last = !isEmpty(finalizedContracts) ? lastContract.uuid == i.uuid : false
        return {
            ...i,
            is_last
        }
    })

}

const getters = {
    allContracts: state => state.contracts,
    currentContract: state => uuid => {
        const currentContract = state.contracts.find(i => new Date(i.effective_date) <= new Date() && new Date(i.expiration_date) >= new Date() && i.university_uuid == uuid)
        if(isEmpty(currentContract)){
            return state.contracts.sort((a,b) => new Date(a.expiration_date) < new Date(b.expiration_date)).find(upm => upm.university_uuid == uuid)
        }
        return currentContract        
    },
    findContract: state => uuid => state.contracts.find(i => i.uuid == uuid),
    contractsByUniversity: state => uuid => mapArray(state.contracts.filter(i => i.university_uuid == uuid).sort((a,b) => new Date(a.expiration_date) < new Date(b.expiration_date))),
    draftContractsByUniversity: state => uuid => state.contracts.find(i => i.university_uuid == uuid && (isEmpty(i.is_finalized) || i.is_finalized == false || i.is_finalized == 0)),
    findDraftByUni: state => uuid => state.contracts.find(i => i.university_uuid == uuid && i.is_finalized != 1),
    contractsLoaded: state => state.dataLoaded,
}

const actions = {
    async getContracts({ commit }, params) {
        commit("GET_CONTRACTS_REQUEST")
        const response = await contractsService.getContracts(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_CONTRACTS_SUCCESS", data)
                break
            default:
                commit("GET_CONTRACTS_ERROR", data)
        }
        return response
    },
    async getContract({ commit }, params) {
        commit("GET_CONTRACT_REQUEST")
        const response = await contractsService.getContract(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_CONTRACT_SUCCESS", data)
                break
            default:
                commit("GET_CONTRACT_ERROR", data)
                break
        }
        return response
    },
    async createContract({ commit }, params) {
        commit("CREATE_CONTRACT_REQUEST")
        const response = await contractsService.createContract(params)
        const { status, data } = response
        switch (status) {
            case 200:
                const contract = unpackContract(data.contract, commit)
                commit("CREATE_CONTRACT_SUCCESS", {contract})
                break
            default:
                commit("CREATE_CONTRACT_ERROR", data)
        }
        return response
    },
    async renewContract({ commit }, params) {
        commit("CREATE_CONTRACT_REQUEST")
        const response = await contractsService.renewContract(params)
        const { status, data } = response
        switch (status) {
            case 200:
                const contract = unpackContract(data.contract, commit)
                commit("CREATE_CONTRACT_SUCCESS", {contract})
                break
            default:
                commit("CREATE_CONTRACT_ERROR", data)
        }
        return response
    },
    async editContract({ commit }, params) {
        commit("EDIT_CONTRACT_REQUEST")
        const response = await contractsService.editContract(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                if(data?.success){
                    const contract = unpackContract(data.contract, commit)
                    commit("EDIT_CONTRACT_SUCCESS", {contract, success: data.success})
                }
                break
            default:
                commit("EDIT_CONTRACT_ERROR", data)
        }
        return response
    },
    async deleteContract({ commit }, params) {
        commit("DELETE_CONTRACT_REQUEST")
        const response = await contractsService.deleteContract(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_CONTRACT_SUCCESS", data)
                break
            default:
                commit("DELETE_CONTRACT_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_CONTRACTS_REQUEST]: state => {
        state.loading = true
    },
    [GET_CONTRACTS_SUCCESS]: (state, data) => {
        let contracts = [...data.contracts]
        state.contracts = addNewFilter(state.contracts, contracts)
        state.loading = false
        state.dataLoaded = true
    },
    [GET_CONTRACTS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_CONTRACT_REQUEST]: state => {
        state.loading = true
    },
    [GET_CONTRACT_SUCCESS]: (state, data) => {
        let mdata = { ...data.contract }
        state.contract = mdata
        state.loading = false
    },
    [GET_CONTRACT_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_CONTRACT_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_CONTRACT_SUCCESS]: (state, data) => {
        state.loading = false
        let contracts = [data.contract]
        state.contracts = addNewFilter(state.contracts, contracts)
    },
    [CREATE_CONTRACT_ERROR]: state => {
        state.loading = false
    },

    [EDIT_CONTRACT_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_CONTRACT_SUCCESS]: (state, data) => {
        if(data.success){
            state.contracts = state.contracts.filter(f => f.uuid != data.contract.uuid)
            state.contracts.push(data.contract)
        }
        state.loading = false
    },
    [EDIT_CONTRACT_ERROR]: state => {
        state.loading = false
    },

    [DELETE_CONTRACT_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_CONTRACT_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_CONTRACT_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const contract = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


export function unpackContract(contract, commit){
    if(!isEmpty(contract.documents)){
        commit('document/GET_DOCUMENTS_SUCCESS', contract, { root: true })
        delete contract.documents
    }
    if(!isEmpty(contract.contract_addendums)){
        const contract_addendums = contract.contract_addendums.map(i => unpackContractAddendum(i, commit))
        commit('contractAddendum/GET_CONTRACT_ADDENDUMS_SUCCESS', {contract_addendums}, { root: true })
        delete contract.contract_addendums
    }
    if(!isEmpty(contract.rfp)){
        const university_rfps = [contract.rfp]
        commit('universityRfp/GET_UNIVERSITY_RFPS_SUCCESS', {university_rfps}, { root: true })
        delete contract.rfps
    }
    if(!isEmpty(contract.pre_sales)){
        const university_prospect_documents = contract.pre_sales
        commit('universityProspectDocument/GET_UNIVERSITY_PROSPECT_DOCUMENTS_SUCCESS', {university_prospect_documents}, { root: true })
        delete contract.pre_sales
    }
    if(!isEmpty(contract.meetings)){
        const university_prospect_meetings = contract.meetings
        commit('universityProspectMeeting/GET_UNIVERSITY_PROSPECT_MEETINGS_SUCCESS', {university_prospect_meetings}, { root: true })
        delete contract.meetings
    }
    if(!isEmpty(contract.contract_ios)){
        const data = contract.contract_ios.map(i => unpackContractIo(i, commit))
        commit('contractIO/GET_CONTRACT_IOS_SUCCESS', {data}, { root: true })
        delete contract.contract_ios
    }
    return contract
}