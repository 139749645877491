import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachScreeningSessionService = {
    async getCoachScreeningSessions(params) {
        let getParams = processQueryParams(params)
        const getCoachScreeningSessionsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-screening-sessions", getCoachScreeningSessionsRequest)
    },
    async getCoachScreeningSession(uuid) {
        const getCoachScreeningSessionRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-screening-sessions/${uuid}`, getCoachScreeningSessionRequest)
    },
    async createCoachScreeningSession(params) {
        const createData = processInput(params)
        const createCoachScreeningSessionRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-screening-sessions", createCoachScreeningSessionRequest)
    },
    async editCoachScreeningSession(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachScreeningSessionRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-screening-sessions/${uuid}`, editCoachScreeningSessionRequest)
    },
    async deleteCoachScreeningSession(uuid) {
        const deleteCoachScreeningSessionRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-screening-sessions/${uuid}`, deleteCoachScreeningSessionRequest)
    }
}