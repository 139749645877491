import {
    GET_UNIVERSITY_PROSPECT_MEETINGS_REQUEST,
    GET_UNIVERSITY_PROSPECT_MEETINGS_SUCCESS,
    GET_UNIVERSITY_PROSPECT_MEETINGS_ERROR,
    GET_UNIVERSITY_PROSPECT_MEETING_REQUEST,
    GET_UNIVERSITY_PROSPECT_MEETING_SUCCESS,
    GET_UNIVERSITY_PROSPECT_MEETING_ERROR,
    CREATE_UNIVERSITY_PROSPECT_MEETING_REQUEST,
    CREATE_UNIVERSITY_PROSPECT_MEETING_SUCCESS,
    CREATE_UNIVERSITY_PROSPECT_MEETING_ERROR,
    EDIT_UNIVERSITY_PROSPECT_MEETING_REQUEST,
    EDIT_UNIVERSITY_PROSPECT_MEETING_SUCCESS,
    EDIT_UNIVERSITY_PROSPECT_MEETING_ERROR,
    DELETE_UNIVERSITY_PROSPECT_MEETING_REQUEST,
    DELETE_UNIVERSITY_PROSPECT_MEETING_SUCCESS,
    BROADCAST_DELETE_UNIVERSITY_PROSPECT_MEETING_SUCCESS,
    DELETE_UNIVERSITY_PROSPECT_MEETING_ERROR
} from "@/actions"

import {
    universityProspectMeetingsService
} from "@/services"
import { addNewFilter, isEmpty } from "@/utilities"
const state = {
    universityProspectMeetings: [],
    universityProspectMeeting: {},
    loading: false
}

const getters = {
    universityProspectMeetingFilter: state => uuid => state.universityProspectMeetings.filter(upm => upm.contract_uuid == uuid).sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
}

const actions = {
    async getUniversityProspectMeetings({ commit }) {
        commit("GET_UNIVERSITY_PROSPECT_MEETINGS_REQUEST")
        const response = await universityProspectMeetingsService.getUniversityProspectMeetings();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_PROSPECT_MEETINGS_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_PROSPECT_MEETINGS_ERROR", data)
        }
        return response
    },
    async getUniversityProspectMeeting({ commit }, params) {
        commit("GET_UNIVERSITY_PROSPECT_MEETING_REQUEST")
        const response = await universityProspectMeetingsService.getUniversityProspectMeeting(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_UNIVERSITY_PROSPECT_MEETING_SUCCESS", data)
                break
            default:
                commit("GET_UNIVERSITY_PROSPECT_MEETING_ERROR", data)
                break
        }
        return response
    },
    async createUniversityProspectMeeting({ commit }, params) {
        commit("CREATE_UNIVERSITY_PROSPECT_MEETING_REQUEST")
        const response = await universityProspectMeetingsService.createUniversityProspectMeeting(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_UNIVERSITY_PROSPECT_MEETING_SUCCESS", data)
                break
            default:
                commit("CREATE_UNIVERSITY_PROSPECT_MEETING_ERROR", data)
        }
        return response
    },
    async editUniversityProspectMeeting({ commit }, params) {
        commit("EDIT_UNIVERSITY_PROSPECT_MEETING_REQUEST")
        const response = await universityProspectMeetingsService.editUniversityProspectMeeting(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_UNIVERSITY_PROSPECT_MEETING_SUCCESS", data)
                break
            default:
                commit("EDIT_UNIVERSITY_PROSPECT_MEETING_ERROR", data)
        }
        return response
    },
    async deleteUniversityProspectMeeting({ commit }, params) {
        commit("DELETE_UNIVERSITY_PROSPECT_MEETING_REQUEST")
        const response = await universityProspectMeetingsService.deleteUniversityProspectMeeting(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_UNIVERSITY_PROSPECT_MEETING_SUCCESS", data)
                break
            default:
                commit("DELETE_UNIVERSITY_PROSPECT_MEETING_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_UNIVERSITY_PROSPECT_MEETINGS_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_PROSPECT_MEETINGS_SUCCESS]: (state, data) => {
        let universityProspectMeetings = [...data.university_prospect_meetings]
        state.universityProspectMeetings = addNewFilter(state.universityProspectMeetings, universityProspectMeetings)
        state.loading = false
    },
    [GET_UNIVERSITY_PROSPECT_MEETINGS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_UNIVERSITY_PROSPECT_MEETING_REQUEST]: state => {
        state.loading = true
    },
    [GET_UNIVERSITY_PROSPECT_MEETING_SUCCESS]: (state, data) => {
        const {university_prospect_meeting} = data
        if(!isEmpty(university_prospect_meeting)){
            state.universityProspectMeeting = university_prospect_meeting
            state.universityProspectMeetings = addNewFilter(state.universityProspectMeetings, [university_prospect_meeting])
        }
        state.loading = false
    },
    [GET_UNIVERSITY_PROSPECT_MEETING_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_UNIVERSITY_PROSPECT_MEETING_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_UNIVERSITY_PROSPECT_MEETING_SUCCESS]: (state, data) => {
        state.loading = false
        const {university_prospect_meeting} = data
        if(!isEmpty(university_prospect_meeting)){
            state.universityProspectMeetings = addNewFilter(state.universityProspectMeetings, [university_prospect_meeting])
        }
    },
    [CREATE_UNIVERSITY_PROSPECT_MEETING_ERROR]: state => {
        state.loading = false
    },

    [EDIT_UNIVERSITY_PROSPECT_MEETING_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_UNIVERSITY_PROSPECT_MEETING_SUCCESS]: (state,data) => {
        state.loading = false
        const {university_prospect_meeting} = data
        if(!isEmpty(university_prospect_meeting)){
            state.universityProspectMeetings = state.universityProspectMeetings.filter(up => up.uuid != university_prospect_meeting.uuid)
            state.universityProspectMeetings = addNewFilter(state.universityProspectMeetings, [university_prospect_meeting])
        }
    },
    [EDIT_UNIVERSITY_PROSPECT_MEETING_ERROR]: state => {
        state.loading = false
    },

    [DELETE_UNIVERSITY_PROSPECT_MEETING_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_UNIVERSITY_PROSPECT_MEETING_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [BROADCAST_DELETE_UNIVERSITY_PROSPECT_MEETING_SUCCESS]: (state, data) => {
        const { university_prospect_meeting } = data
        if(!isEmpty(university_prospect_meeting)){
            state.universityProspects = state.universityProspects.filter(up => up.uuid != university_prospect_meeting.uuid)
        }
        state.loading = false
    },
    [DELETE_UNIVERSITY_PROSPECT_MEETING_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const universityProspectMeeting = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}