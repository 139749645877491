import {
  GET_UNIVERSITY_STATUSES_REQUEST,
  GET_UNIVERSITY_STATUSES_SUCCESS,
  GET_UNIVERSITY_STATUSES_ERROR,
  GET_UNIVERSITY_STATUS_REQUEST,
  GET_UNIVERSITY_STATUS_SUCCESS,
  GET_UNIVERSITY_STATUS_ERROR,
  CREATE_UNIVERSITY_STATUS_REQUEST,
  CREATE_UNIVERSITY_STATUS_SUCCESS,
  CREATE_UNIVERSITY_STATUS_ERROR,
  EDIT_UNIVERSITY_STATUS_REQUEST,
  EDIT_UNIVERSITY_STATUS_SUCCESS,
  EDIT_UNIVERSITY_STATUS_ERROR,
  DELETE_UNIVERSITY_STATUS_REQUEST,
  DELETE_UNIVERSITY_STATUS_SUCCESS,
  DELETE_UNIVERSITY_STATUS_ERROR
} from "@/actions"

import {
  universityStatusService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
  universityStatuses: [],
  universityStatus: {},
  loading: false,
  loadedOnce: false,
}

const getters = {
  univStatusLoadedOnce: state => state.loadedOnce,
  // univStatusSelectOptions: state => state.universityStatuses.map(univStatus => ({ value: univStatus.uuid, label: univStatus.name })),
  universityStatusFilter: state => univUuid => state.universityStatuses.find(us => us.university_uuid == univUuid),


  contractedUniversities: (state, getters, rootState, rootGetters) => state.universityStatuses.filter(us => rootGetters['statusUniversity/contractedUuids'].includes(us.status_universities_uuid)),
  facultyContractedUniversities: (state, getters, rootState, rootGetters) => state.universityStatuses.filter(us => rootGetters['statusUniversity/facultyContractedUuids'].includes(us.status_universities_uuid)),
  onboardingUniversities: (state, getters, rootState, rootGetters) => state.universityStatuses.filter(us => rootGetters['statusUniversity/onboardingUuids'].includes(us.status_universities_uuid)).map(us => rootGetters['university/universityFilter'](us?.university_uuid)),
  contractedUnivByUuid: (state, getters) => getters.contractedUniversities.map(us => us.university_uuid),
  facultyContractedUnivByUuid: (state, getters) => getters.facultyContractedUniversities.map(us => us.university_uuid),
}

const actions = {
  async getUniversityStatuses({ commit }) {
    commit("GET_UNIVERSITY_STATUSES_REQUEST")
    const response = await universityStatusService.getUniversityStatuses()
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_UNIVERSITY_STATUSES_SUCCESS", data)
        break
      default:
        commit("GET_UNIVERSITY_STATUSES_ERROR", data)
    }
    return response
  },
  async getUniversityStatus({ commit }, params) {
    commit("GET_UNIVERSITY_STATUS_REQUEST")
    const response = await universityStatusService.getUniversityStatus(params)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_UNIVERSITY_STATUS_SUCCESS", data)
        break
      default:
        commit("GET_UNIVERSITY_STATUS_ERROR", data)
        break
    }
    return response
  },
  async createUniversityStatus({ commit, dispatch }, params) {
    commit("CREATE_UNIVERSITY_STATUS_REQUEST")
    const response = await universityStatusService.createUniversityStatus(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_UNIVERSITY_STATUS_SUCCESS", data) : commit("CREATE_UNIVERSITY_STATUS_ERROR", data)
        data.success ? dispatch('alert/createAlertSuccess', "University Status Created", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
        break
      default:
        commit("CREATE_UNIVERSITY_STATUS_ERROR", data)
    }
    return response
  },
  async editUniversityStatus({ commit, dispatch }, params) {
    commit("EDIT_UNIVERSITY_STATUS_REQUEST")
    const { uuid } = params
    const response = await universityStatusService.editUniversityStatus(params, uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_UNIVERSITY_STATUS_SUCCESS", data) : commit("EDIT_UNIVERSITY_STATUS_ERROR", data)
        data.success ? dispatch('alert/createAlertSuccess', "University Status Updated.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
        break
      default:
        commit("EDIT_UNIVERSITY_STATUS_ERROR", data)
    }
    return response
  },
  async deleteUniversityStatus({ commit, dispatch }, uuid) {
    commit("DELETE_UNIVERSITY_STATUS_REQUEST")
    const response = await universityStatusService.deleteUniversityStatus(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        commit("DELETE_UNIVERSITY_STATUS_SUCCESS", data)
        // data.success ? dispatch('university/updateUniversityStatus', { university_status: {status_university: {},}}, { root: true }) : null
        break
      default:
        commit("DELETE_UNIVERSITY_STATUS_SUCCESS", data)
    }
    return response
  }
}

const mutations = {
  [GET_UNIVERSITY_STATUSES_REQUEST]: state => {
    state.loading = true
  },
  [GET_UNIVERSITY_STATUSES_SUCCESS]: (state, data) => {
    let universityStatuses = data.university_statuses
    state.universityStatuses = addNewFilter(state.universityStatuses, universityStatuses)
    state.loading = false
    state.loadedOnce = true
  },
  [GET_UNIVERSITY_STATUSES_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_UNIVERSITY_STATUS_REQUEST]: state => {
    state.loading = true
  },
  [GET_UNIVERSITY_STATUS_SUCCESS]: (state, data) => {
    const { university_status } = data;
    state.universityStatuses = addNewFilter(state.universityStatuses, [university_status])
    state.loading = false
  },
  [GET_UNIVERSITY_STATUS_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_UNIVERSITY_STATUS_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_UNIVERSITY_STATUS_SUCCESS]: (state, data) => {
    const { university_status } = data
    if (!isEmpty(university_status)){
      state.universityStatuses = addNewFilter(state.universityStatuses, [university_status])
    }
    state.loading = false
  },
  [CREATE_UNIVERSITY_STATUS_ERROR]: state => {
    state.loading = false
  },

  [EDIT_UNIVERSITY_STATUS_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_UNIVERSITY_STATUS_SUCCESS]: (state, data) => {
    const { university_status } = data
    if (!isEmpty(university_status)){
      state.universityStatuses = state.universityStatuses.filter(us => us.uuid != university_status.uuid)
      state.universityStatuses = addNewFilter(state.universityStatuses, [university_status])
    }
    state.loading = false
  },
  [EDIT_UNIVERSITY_STATUS_ERROR]: state => {
    state.loading = false
  },

  [DELETE_UNIVERSITY_STATUS_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_UNIVERSITY_STATUS_SUCCESS]: (state, data) => {
    state.loading = false
  },
  [DELETE_UNIVERSITY_STATUS_ERROR]: (state, data) => {
    state.loading = false
  }

}

export const universityStatus = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
