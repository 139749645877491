import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const sharedQueueItemService = {
  async getSharedQueueItems() {
    const getSharedQueueItemsRequest = {
      method: "GET"
    }
    return await apiRequest("/api/shared-queue-items", getSharedQueueItemsRequest)
  },
  async getSharedQueueItem(uuid) {
    const getSharedQueueItemRequest = {
      method: "GET"
    }
    return await apiRequest(`/api/shared-queue-items/${uuid}`, getSharedQueueItemRequest)
  },
  async createSharedQueueItem(params) {
    const createData = processInput(params)
    const createSharedQueueItemRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/shared-queue-items", createSharedQueueItemRequest)
  },
  async editSharedQueueItem(data, uuid) {
    let editData = processInput(data);
    editData.append("_method", "PATCH");
    const editSharedQueueItemRequest = {
      method: "POST",
      data: editData
    }
    return await apiRequest(`/api/shared-queue-items/${uuid}`, editSharedQueueItemRequest)
  },
  async deleteSharedQueueItem(params) {
    const { uuid } = params;
    delete params.uuid
    let deleteData = processQueryParams(params)
    const deleteSharedQueueItemRequest = {
      method: "DELETE",
      query: deleteData
    }
    return await apiRequest(`/api/shared-queue-items/${uuid}`, deleteSharedQueueItemRequest)
  }
}
