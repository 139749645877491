export const GET_POINT_OF_CONTACT_TYPES_REQUEST = "GET_POINT_OF_CONTACT_TYPES_REQUEST"
export const GET_POINT_OF_CONTACT_TYPES_SUCCESS = "GET_POINT_OF_CONTACT_TYPES_SUCCESS"
export const GET_POINT_OF_CONTACT_TYPES_ERROR = "GET_POINT_OF_CONTACT_TYPES_ERROR"
export const GET_POINT_OF_CONTACT_TYPE_REQUEST = "GET_POINT_OF_CONTACT_TYPE_REQUEST"
export const GET_POINT_OF_CONTACT_TYPE_SUCCESS = "GET_POINT_OF_CONTACT_TYPE_SUCCESS"
export const GET_POINT_OF_CONTACT_TYPE_ERROR = "GET_POINT_OF_CONTACT_TYPE_ERROR"
export const CREATE_POINT_OF_CONTACT_TYPE_REQUEST = "CREATE_POINT_OF_CONTACT_TYPE_REQUEST"
export const CREATE_POINT_OF_CONTACT_TYPE_SUCCESS = "CREATE_POINT_OF_CONTACT_TYPE_SUCCESS"
export const CREATE_POINT_OF_CONTACT_TYPE_ERROR = "CREATE_POINT_OF_CONTACT_TYPE_ERROR"
export const EDIT_POINT_OF_CONTACT_TYPE_REQUEST = "EDIT_POINT_OF_CONTACT_TYPE_REQUEST"
export const EDIT_POINT_OF_CONTACT_TYPE_SUCCESS = "EDIT_POINT_OF_CONTACT_TYPE_SUCCESS"
export const EDIT_POINT_OF_CONTACT_TYPE_ERROR = "EDIT_POINT_OF_CONTACT_TYPE_ERROR"
export const DELETE_POINT_OF_CONTACT_TYPE_REQUEST = "DELETE_POINT_OF_CONTACT_TYPE_REQUEST"
export const DELETE_POINT_OF_CONTACT_TYPE_SUCCESS = "DELETE_POINT_OF_CONTACT_TYPE_SUCCESS"
export const DELETE_POINT_OF_CONTACT_TYPE_ERROR = "DELETE_POINT_OF_CONTACT_TYPE_ERROR"
