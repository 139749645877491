import {
    GET_COURSE_LENGTHS_REQUEST,
    GET_COURSE_LENGTHS_SUCCESS,
    GET_COURSE_LENGTHS_ERROR,
    GET_COURSE_LENGTH_REQUEST,
    GET_COURSE_LENGTH_SUCCESS,
    GET_COURSE_LENGTH_ERROR,
    CREATE_COURSE_LENGTH_REQUEST,
    CREATE_COURSE_LENGTH_SUCCESS,
    CREATE_COURSE_LENGTH_ERROR,
    EDIT_COURSE_LENGTH_REQUEST,
    EDIT_COURSE_LENGTH_SUCCESS,
    EDIT_COURSE_LENGTH_ERROR,
    DELETE_COURSE_LENGTH_REQUEST,
    DELETE_COURSE_LENGTH_SUCCESS,
    DELETE_COURSE_LENGTH_ERROR
} from "@/actions"

import {
    courseLengthsService
} from "@/services"

const state = {
    courseLengths: [],
    courseLength: {},
    loading: false
}

const getters = {
    allCourseLengths: state => state.courseLengths,
    allCourseLengthsMap: state => state.courseLengths.map(cl => {
        return {
            value: cl.uuid,
            label: cl.weeks,
        }
    }),
    courseLengthFilter: state => uuid => state.courseLengths.find(upm => upm.uuid == uuid)
}

const actions = {
    async getCourseLengths({ commit }) {
        commit("GET_COURSE_LENGTHS_REQUEST")
        const response = await courseLengthsService.getCourseLengths();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COURSE_LENGTHS_SUCCESS", data)
                break
            default:
                commit("GET_COURSE_LENGTHS_ERROR", data)
        }
        return response
    },
    
    async getCourseLength({ commit }, params) {
        commit("GET_COURSE_LENGTH_REQUEST")
        const response = await courseLengthsService.getCourseLength(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COURSE_LENGTH_SUCCESS", data)
                break
            default:
                commit("GET_COURSE_LENGTH_ERROR", data)
                break
        }
        return response
    },
    async createCourseLength({ commit }, params) {
        commit("CREATE_COURSE_LENGTH_REQUEST")
        const response = await courseLengthsService.createCourseLength(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COURSE_LENGTH_SUCCESS", data)
                break
            default:
                commit("CREATE_COURSE_LENGTH_ERROR", data)
        }
        return response
    },
    async editCourseLength({ commit }, params) {
        commit("EDIT_COURSE_LENGTH_REQUEST")
        const response = await courseLengthsService.editCourseLength(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COURSE_LENGTH_SUCCESS", data)
                break
            default:
                commit("EDIT_COURSE_LENGTH_ERROR", data)
        }
        return response
    },
    async deleteCourseLength({ commit }, params) {
        commit("DELETE_COURSE_LENGTH_REQUEST")
        const response = await courseLengthsService.deleteCourseLength(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COURSE_LENGTH_SUCCESS", data)
                break
            default:
                commit("DELETE_COURSE_LENGTH_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COURSE_LENGTHS_REQUEST]: state => {
        state.loading = true
    },
    [GET_COURSE_LENGTHS_SUCCESS]: (state, data) => {
        let mdata = [...data.data]
        state.courseLengths = mdata
        state.loading = false
    },
    [GET_COURSE_LENGTHS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_COURSE_LENGTH_REQUEST]: state => {
        state.loading = true
    },
    [GET_COURSE_LENGTH_SUCCESS]: (state, data) => {
        let mdata = { ...data.data }
        state.courseLength = mdata
        state.loading = false
    },
    [GET_COURSE_LENGTH_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COURSE_LENGTH_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COURSE_LENGTH_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [CREATE_COURSE_LENGTH_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COURSE_LENGTH_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COURSE_LENGTH_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COURSE_LENGTH_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COURSE_LENGTH_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COURSE_LENGTH_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_COURSE_LENGTH_ERROR]: (state, data) => {
        state.loading = false
    },

}

export const courseLength = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}