import {
    apiRequest,
    processInput
} from "@/utilities"

export const lmsService = {
    async getLmss() {
        const getLmssRequest = {
            method: "GET"
        }
        return await apiRequest("/api/learning-management-systems", getLmssRequest)
    },
    async getLms(uuid) {
        const getLmsRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/learning-management-systems/${uuid}`, getLmsRequest)
    },
    async createLms(params) {
        const createData = processInput(params)
        const createLmsRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/learning-management-systems", createLmsRequest)
    },
    async editLms(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editLmsRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/learning-management-systems/${uuid}`, editLmsRequest)
    },
    async deleteLms(uuid) {
        const deleteLmsRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/learning-management-systems/${uuid}`, deleteLmsRequest)
    }
}