import {
    apiRequest,
    processInput
} from "@/utilities"

export const employerService = {
    async getEmployers() {
        const getEmployersRequest = {
            method: "GET"
        }
        return await apiRequest("/api/employers", getEmployersRequest)
    },
    async getEmployer(uuid) {
        const getEmployerRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/employers/${uuid}`, getEmployerRequest)
    },
    async createEmployer(params) {
        const createData = processInput(params)
        const createEmployerRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/employers", createEmployerRequest)
    },
    async editEmployer(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editEmployerRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/employers/${uuid}`, editEmployerRequest)
    },
    async deleteEmployer(uuid) {
        const deleteEmployerRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/employers/${uuid}`, deleteEmployerRequest)
    }
}