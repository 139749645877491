export const GET_EMAIL_TYPES_REQUEST = "GET_EMAIL_TYPES_REQUEST"
export const GET_EMAIL_TYPES_SUCCESS = "GET_EMAIL_TYPES_SUCCESS"
export const GET_EMAIL_TYPES_ERROR = "GET_EMAIL_TYPES_ERROR"
export const GET_EMAIL_TYPE_REQUEST = "GET_EMAIL_TYPE_REQUEST"
export const GET_EMAIL_TYPE_SUCCESS = "GET_EMAIL_TYPE_SUCCESS"
export const GET_EMAIL_TYPE_ERROR = "GET_EMAIL_TYPE_ERROR"
export const CREATE_EMAIL_TYPE_REQUEST = "CREATE_EMAIL_TYPE_REQUEST"
export const CREATE_EMAIL_TYPE_SUCCESS = "CREATE_EMAIL_TYPE_SUCCESS"
export const CREATE_EMAIL_TYPE_ERROR = "CREATE_EMAIL_TYPE_ERROR"
export const EDIT_EMAIL_TYPE_REQUEST = "EDIT_EMAIL_TYPE_REQUEST"
export const EDIT_EMAIL_TYPE_SUCCESS = "EDIT_EMAIL_TYPE_SUCCESS"
export const EDIT_EMAIL_TYPE_ERROR = "EDIT_EMAIL_TYPE_ERROR"
export const DELETE_EMAIL_TYPE_REQUEST = "DELETE_EMAIL_TYPE_REQUEST"
export const DELETE_EMAIL_TYPE_SUCCESS = "DELETE_EMAIL_TYPE_SUCCESS"
export const DELETE_EMAIL_TYPE_ERROR = "DELETE_EMAIL_TYPE_ERROR"