export const GET_MINORS_REQUEST = "GET_MINORS_REQUEST"
export const GET_MINORS_SUCCESS = "GET_MINORS_SUCCESS"
export const GET_MINORS_ERROR = "GET_MINORS_ERROR"
export const GET_MINOR_REQUEST = "GET_MINOR_REQUEST"
export const GET_MINOR_SUCCESS = "GET_MINOR_SUCCESS"
export const GET_MINOR_ERROR = "GET_MINOR_ERROR"
export const CREATE_MINOR_REQUEST = "CREATE_MINOR_REQUEST"
export const CREATE_MINOR_SUCCESS = "CREATE_MINOR_SUCCESS"
export const CREATE_MINOR_ERROR = "CREATE_MINOR_ERROR"
export const EDIT_MINOR_REQUEST = "EDIT_MINOR_REQUEST"
export const EDIT_MINOR_SUCCESS = "EDIT_MINOR_SUCCESS"
export const EDIT_MINOR_ERROR = "EDIT_MINOR_ERROR"
export const DELETE_MINOR_REQUEST = "DELETE_MINOR_REQUEST"
export const DELETE_MINOR_SUCCESS = "DELETE_MINOR_SUCCESS"
export const DELETE_MINOR_ERROR = "DELETE_MINOR_ERROR"