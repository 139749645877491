export const GET_COACH_REFERRAL_SOURCES_REQUEST = "GET_COACH_REFERRAL_SOURCES_REQUEST"
export const GET_COACH_REFERRAL_SOURCES_SUCCESS = "GET_COACH_REFERRAL_SOURCES_SUCCESS"
export const GET_COACH_REFERRAL_SOURCES_ERROR = "GET_COACH_REFERRAL_SOURCES_ERROR"
export const GET_COACH_REFERRAL_SOURCE_REQUEST = "GET_COACH_REFERRAL_SOURCE_REQUEST"
export const GET_COACH_REFERRAL_SOURCE_SUCCESS = "GET_COACH_REFERRAL_SOURCE_SUCCESS"
export const GET_COACH_REFERRAL_SOURCE_ERROR = "GET_COACH_REFERRAL_SOURCE_ERROR"
export const CREATE_COACH_REFERRAL_SOURCE_REQUEST = "CREATE_COACH_REFERRAL_SOURCE_REQUEST"
export const CREATE_COACH_REFERRAL_SOURCE_SUCCESS = "CREATE_COACH_REFERRAL_SOURCE_SUCCESS"
export const CREATE_COACH_REFERRAL_SOURCE_ERROR = "CREATE_COACH_REFERRAL_SOURCE_ERROR"
export const EDIT_COACH_REFERRAL_SOURCE_REQUEST = "EDIT_COACH_REFERRAL_SOURCE_REQUEST"
export const EDIT_COACH_REFERRAL_SOURCE_SUCCESS = "EDIT_COACH_REFERRAL_SOURCE_SUCCESS"
export const EDIT_COACH_REFERRAL_SOURCE_ERROR = "EDIT_COACH_REFERRAL_SOURCE_ERROR"
export const DELETE_COACH_REFERRAL_SOURCE_REQUEST = "DELETE_COACH_REFERRAL_SOURCE_REQUEST"
export const DELETE_COACH_REFERRAL_SOURCE_SUCCESS = "DELETE_COACH_REFERRAL_SOURCE_SUCCESS"
export const DELETE_COACH_REFERRAL_SOURCE_ERROR = "DELETE_COACH_REFERRAL_SOURCE_ERROR"
export const CLEAR_COACH_REFERRAL_SOURCE_STATE = "CLEAR_COACH_REFERRAL_SOURCE_STATE"