import {
    apiRequest,
    processInput
} from "@/utilities"

export const facilitatingCourseService = {
    async getFacilitatingCourses() {
        const getFacilitatingCoursesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/facilitating-courses", getFacilitatingCoursesRequest)
    },
    async getFacilitatingCourse(uuid) {
        const getFacilitatingCourseRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/facilitating-courses/${uuid}`, getFacilitatingCourseRequest)
    },
    async createFacilitatingCourse(params) {
        const createData = processInput(params)
        const createFacilitatingCourseRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/facilitating-courses", createFacilitatingCourseRequest)
    },
    async editFacilitatingCourse(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editFacilitatingCourseRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/facilitating-courses/${uuid}`, editFacilitatingCourseRequest)
    },
    async deleteFacilitatingCourse(uuid) {
        const deleteFacilitatingCourseRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/facilitating-courses/${uuid}`, deleteFacilitatingCourseRequest)
    }
}