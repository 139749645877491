import {
	GET_PROGRAM_COURSE_LEVELS_REQUEST,
	GET_PROGRAM_COURSE_LEVELS_SUCCESS,
	GET_PROGRAM_COURSE_LEVELS_ERROR,
	GET_PROGRAM_COURSE_LEVEL_REQUEST,
	GET_PROGRAM_COURSE_LEVEL_SUCCESS,
	GET_PROGRAM_COURSE_LEVEL_ERROR,
	CREATE_PROGRAM_COURSE_LEVEL_REQUEST,
	CREATE_PROGRAM_COURSE_LEVEL_SUCCESS,
	CREATE_PROGRAM_COURSE_LEVEL_ERROR,
	EDIT_PROGRAM_COURSE_LEVEL_REQUEST,
	EDIT_PROGRAM_COURSE_LEVEL_SUCCESS,
	EDIT_PROGRAM_COURSE_LEVEL_ERROR,
	DELETE_PROGRAM_COURSE_LEVEL_REQUEST,
	DELETE_PROGRAM_COURSE_LEVEL_SUCCESS,
	DELETE_PROGRAM_COURSE_LEVEL_ERROR
} from "@/actions"

import {
	programCourseLevelService
} from "@/services"

import { addNewFilter } from "@/utilities"

const state = {
	programCourseLevels: [],
	programCourseLevel: {},
	loading: false,
	loadedOnce: false
}

const getters = {
	pclLoading: state => state.loading,

	pclLoadedOnce: state => state.loadedOnce,

	programCourseLevels: state => state.programCourseLevels,

	progCourseLevelSelectOptions: state => state.programCourseLevels.map(pcl => ({
		value: pcl.uuid,
		label: pcl.label,
		attrs: {
			"data-slug": pcl.slug
		}
	})),

	progCourseLevelByUuid: state => uuid => state.programCourseLevels.find(pcl => pcl.uuid == uuid)
}

const actions = {
	async getProgramCourseLevels({ commit }) {
		commit("GET_PROGRAM_COURSE_LEVELS_REQUEST")
		const response = await programCourseLevelService.getProgramCourseLevels();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PROGRAM_COURSE_LEVELS_SUCCESS", data)
				break
			default:
				commit("GET_PROGRAM_COURSE_LEVELS_ERROR", data)
		}
		return response
	},
	async getProgramCourseLevel({ commit }, params) {
		commit("GET_PROGRAM_COURSE_LEVEL_REQUEST")
		const response = await programCourseLevelService.getProgramCourseLevel(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PROGRAM_COURSE_LEVEL_SUCCESS", data)
				break
			default:
				commit("GET_PROGRAM_COURSE_LEVEL_ERROR", data)
				break
		}
		return response
	},
	async createProgramCourseLevel({ commit }, params) {
		commit("CREATE_PROGRAM_COURSE_LEVEL_REQUEST")
		const response = await programCourseLevelService.createProgramCourseLevel(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_PROGRAM_COURSE_LEVEL_SUCCESS", data)
				break
			default:
				commit("CREATE_PROGRAM_COURSE_LEVEL_ERROR", data)
		}
		return response
	},
	async editProgramCourseLevel({ commit }, params) {
		commit("EDIT_PROGRAM_COURSE_LEVEL_REQUEST")
		const response = await programCourseLevelService.editProgramCourseLevel(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_PROGRAM_COURSE_LEVEL_SUCCESS", data)
				break
			default:
				commit("EDIT_PROGRAM_COURSE_LEVEL_ERROR", data)
		}
		return response
	},
	async deleteProgramCourseLevel({ commit }, params) {
		commit("DELETE_PROGRAM_COURSE_LEVEL_REQUEST")
		const response = await programCourseLevelService.deleteProgramCourseLevel(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_PROGRAM_COURSE_LEVEL_SUCCESS", data)
				break
			default:
				commit("DELETE_PROGRAM_COURSE_LEVEL_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_PROGRAM_COURSE_LEVELS_REQUEST]: state => {
		state.loading = true
	},
	[GET_PROGRAM_COURSE_LEVELS_SUCCESS]: (state, data) => {
		let programCourseLevels = data.program_course_levels
		state.programCourseLevels = addNewFilter(state.programCourseLevels, programCourseLevels)
		state.loadedOnce = true
		state.loading = false
	},
	[GET_PROGRAM_COURSE_LEVELS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_PROGRAM_COURSE_LEVEL_REQUEST]: state => {
		state.loading = true
	},
	[GET_PROGRAM_COURSE_LEVEL_SUCCESS]: (state, data) => {
		const { program_course_level } = data;
		state.programCourseLevels = addNewFilter(state.programCourseLevels, [program_course_level])
		state.loading = false
	},
	[GET_PROGRAM_COURSE_LEVEL_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_PROGRAM_COURSE_LEVEL_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_PROGRAM_COURSE_LEVEL_SUCCESS]: (state, data) => {
		state.loading = false
		const { program_course_level } = data;
		state.programCourseLevels = addNewFilter(state.programCourseLevels, [program_course_level])
	},
	[CREATE_PROGRAM_COURSE_LEVEL_ERROR]: state => {
		state.loading = false
	},

	[EDIT_PROGRAM_COURSE_LEVEL_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_PROGRAM_COURSE_LEVEL_SUCCESS]: (state, data) => {
		const { program_course_level } = data;
		state.programCourseLevels = addNewFilter(state.programCourseLevels, [program_course_level])
		state.loading = false
	},
	[EDIT_PROGRAM_COURSE_LEVEL_ERROR]: state => {
		state.loading = false
	},

	[DELETE_PROGRAM_COURSE_LEVEL_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_PROGRAM_COURSE_LEVEL_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_PROGRAM_COURSE_LEVEL_ERROR]: (state, data) => {
		state.loading = false
	}
}

export const programCourseLevel = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}