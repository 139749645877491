// require('./bootstrap');
// require('./mixins/icons/font.awesome')

import 'core-js/stable'
import Vue from 'vue'
import CoreuiVue from '@coreui/vue-pro'
import App from './App.vue'
import './registerServiceWorker'
import { router }  from './router/routes'
import { store } from "@/store"
import { iconsSet as icons } from "@/mixins"
import { apiRequest } from "@/utilities";

// import vuexI18n from 'vuex-i18n';
// import Locales from './locale/vue-i18n-locales.generated.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vSelect from "vue-select";
import VCalendar from 'v-calendar';
import VueTheMask from 'vue-the-mask'
import bsCustomFileInput from 'bs-custom-file-input'
import keyBoardInfo from '@/components/helper/keyBoardInfo.vue'
import CustomElementCover from '@/components/helper/CustomElementCover.vue'
import SkeletonLoader from '@/components/helper/SkeletonLoader.vue'
import wysiwyg from "vue-wysiwyg";
import VueProgressBar from 'vue-progressbar';
import AsyncComputed from 'vue-async-computed'
import { Model } from 'vue-api-query'

// Model.$http = axios;
Model.$http = apiRequest;

Vue.use(VueProgressBar, {
	color: '#321fdb',
	failedColor: 'red',
	height: '6px',
	position: 'relative',
	transition: {
		termination: 1000
	},
	location: 'bottom'
});

Vue.config.productionTip = false;
Vue.prototype.$apiAddress = process.env.VUE_APP_API_ADDRESS;

Vue.use(AsyncComputed);
Vue.use(CoreuiVue);
Vue.use(VueTheMask);
Vue.use(wysiwyg, {
	hideModules: {
		image: true
	}
});
Vue.use(VCalendar);
Vue.use(bsCustomFileInput);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('keyBoardInfo', keyBoardInfo)
Vue.component('CustomElementCover', CustomElementCover)
Vue.component('SkeletonLoader', SkeletonLoader)

vSelect.props.components.default = () => ({
	Deselect: {
		render: createElement => createElement('span', [createElement('i', {
			attrs: {
				class: 'bi bi-x-circle-fill text-danger'
			}
		}, "")]),
	},
	OpenIndicator: {
		render: createElement => createElement('span', [createElement('i', {
			attrs: {
				class: 'bi bi-chevron-down'
			}
		}, "")]),
	},
});

Vue.component(vSelect)

new Vue({
  router,
	store,
	icons,
  render: h => h(App)
}).$mount('#app')
