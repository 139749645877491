import {
    GET_CONTRACT_IOS_REQUEST,
    GET_CONTRACT_IOS_SUCCESS,
    GET_CONTRACT_IOS_ERROR,
    GET_CONTRACT_IO_REQUEST,
    GET_CONTRACT_IO_SUCCESS,
    GET_CONTRACT_IO_ERROR,
    CREATE_CONTRACT_IO_REQUEST,
    CREATE_CONTRACT_IO_SUCCESS,
    CREATE_CONTRACT_IO_ERROR,
    EDIT_CONTRACT_IO_REQUEST,
    EDIT_CONTRACT_IO_SUCCESS,
    EDIT_CONTRACT_IO_ERROR,
    DELETE_CONTRACT_IO_REQUEST,
    DELETE_CONTRACT_IO_SUCCESS,
    DELETE_CONTRACT_IO_ERROR
} from "@/actions"

import {
    contractIOSService
} from "@/services"

import { addNewFilter, isEmpty, decodeLaravelValidationErrors } from "@/utilities"

const state = {
    contractIOS: [],
    contractIO: {},
    loading: false
}

const getters = {
    allContractIO: state => state.contractIOS,
    contractIOFilter: state => (contract_uuid, type) => {
        return state.contractIOS.filter(upm => upm.contract_uuid == contract_uuid && upm.type == type).sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    }
}

const actions = {
    async getContractIOS({ commit }) {
        commit("GET_CONTRACT_IOS_REQUEST")
        const response = await contractIOSService.getContractIOS();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_CONTRACT_IOS_SUCCESS", data)
                break
            default:
                commit("GET_CONTRACT_IOS_ERROR", data)
        }
        return response
    },
    async getContractIO({ commit }, params) {
        commit("GET_CONTRACT_IO_REQUEST")
        const response = await contractIOSService.getContractIO(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_CONTRACT_IO_SUCCESS", data)
                break
            default:
                commit("GET_CONTRACT_IO_ERROR", data)
                break
        }
        return response
    },
    async createContractIO({ commit, dispatch }, params) {
        commit("CREATE_CONTRACT_IO_REQUEST")
        const response = await contractIOSService.createContractIO(params)
        const { status, data } = response
        switch (status) {
            case 200:
                if(data?.success){
                    commit("CREATE_CONTRACT_IO_SUCCESS", data)
                }else if(data?.error && !isEmpty(data.errors)){
                    dispatch('alert/createAlertErrors', decodeLaravelValidationErrors(data.errors), { root: true });
                }
                break
            default:
                commit("CREATE_CONTRACT_IO_ERROR", data)
        }
        return response
    },
    async editContractIO({ commit }, params) {
        commit("EDIT_CONTRACT_IO_REQUEST")
        const response = await contractIOSService.editContractIO(params, params.uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_CONTRACT_IO_SUCCESS", data)
                break
            default:
                commit("EDIT_CONTRACT_IO_ERROR", data)
        }
        return response
    },
    async deleteContractIO({ commit }, uuid) {
        commit("DELETE_CONTRACT_IO_REQUEST")
        const response = await contractIOSService.deleteContractIO(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_CONTRACT_IO_SUCCESS", uuid)
                break
            default:
                commit("DELETE_CONTRACT_IO_ERROR", data)
        }
        return response
    }
}

const mutations = {
    [GET_CONTRACT_IOS_REQUEST]: state => {
        state.loading = true
    },
    [GET_CONTRACT_IOS_SUCCESS]: (state, data) => {
        let mdata = [...data.data]
        state.contractIOS = addNewFilter(state.contractIOS, mdata)
        state.loading = false
    },
    [GET_CONTRACT_IOS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_CONTRACT_IO_REQUEST]: state => {
        state.loading = true
    },
    [GET_CONTRACT_IO_SUCCESS]: (state, data) => {
        let mdata = { ...data.data }
        state.contractIOS = state.contractIOS.filter(f => f.uuid != mdata.uuid)
        state.contractIOS.push(mdata)
        state.loading = false
    },
    [GET_CONTRACT_IO_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_CONTRACT_IO_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_CONTRACT_IO_SUCCESS]: (state, data) => {
        state.contractIOS = state.contractIOS.filter(cio => cio.uuid != data.data.uuid)
        state.contractIOS.push(data.data)
        state.loading = false
    },
    [CREATE_CONTRACT_IO_ERROR]: state => {
        state.loading = false
    },

    [EDIT_CONTRACT_IO_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_CONTRACT_IO_SUCCESS]: (state, data) => {
        state.contractIOS = state.contractIOS.filter(cio => cio.uuid != data.data.uuid)
        state.contractIOS.push(data.data)
        state.loading = false
    },
    [EDIT_CONTRACT_IO_ERROR]: state => {
        state.loading = false
    },

    [DELETE_CONTRACT_IO_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_CONTRACT_IO_SUCCESS]: (state, uuid) => {
        state.contractIOS = state.contractIOS.filter(cio => cio.uuid != uuid)
        state.loading = false
    },
    BROADCAST_DELETE_CONTRACT_IO_SUCCESS: (state, uuid) => {
        state.contractIOS = state.contractIOS.filter(cio => cio.uuid != uuid)
        state.loading = false
    },
    [DELETE_CONTRACT_IO_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const contractIO = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export function unpackContractIo(contractIO, commit){
    if(!isEmpty(contractIO.contract_price_types)){
        const contract_price_types = contractIO.contract_price_types
        commit('contractPriceType/GET_CONTRACT_PRICE_TYPES_SUCCESS', {contract_price_types}, { root: true })
        delete contractIO.contract_price_types
    }
    return contractIO
}