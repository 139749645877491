import {
    GET_LANGUAGES_REQUEST,
    GET_LANGUAGES_SUCCESS,
    GET_LANGUAGES_ERROR,
    GET_LANGUAGE_REQUEST,
    GET_LANGUAGE_SUCCESS,
    GET_LANGUAGE_ERROR,
    CREATE_LANGUAGE_REQUEST,
    CREATE_LANGUAGE_SUCCESS,
    CREATE_LANGUAGE_ERROR,
    EDIT_LANGUAGE_REQUEST,
    EDIT_LANGUAGE_SUCCESS,
    EDIT_LANGUAGE_ERROR,
    DELETE_LANGUAGE_REQUEST,
    DELETE_LANGUAGE_SUCCESS,
    DELETE_LANGUAGE_ERROR
} from "@/actions"

import {
    languageService
} from "@/services"

const state = {
    languages: [],
    language: {},
    loading: false
}

const sortMap = {
    "spanish": 1,
    "french": 2,
    "german": 3,
    "italian": 4,
    "arabic": 5,
    "mandarin": 6,
    "other": 7,
    "na-or-none": 8,
}

import { addNewFilter } from "@/utilities"

const getters = {
    languages: state => state.languages.map(l => ({ ...l, sort: sortMap[l.slug ?? 'other'] })).sort((a, b) => a.sort - b.sort),
    noneLanguageOption: state => state.languages.find(l => l.slug == 'na-or-none')
}

const actions = {
    async getLanguages({ commit }) {
        commit("GET_LANGUAGES_REQUEST")
        const response = await languageService.getLanguages();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_LANGUAGES_SUCCESS", data)
                break
            default:
                commit("GET_LANGUAGES_ERROR", data)
        }
        return response
    },
    async getLanguage({ commit }, params) {
        commit("GET_LANGUAGE_REQUEST")
        const response = await languageService.getLanguage(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_LANGUAGE_SUCCESS", data)
                break
            default:
                commit("GET_LANGUAGE_ERROR", data)
                break
        }
        return response
    },
    async createLanguage({ commit }, params) {
        commit("CREATE_LANGUAGE_REQUEST")
        const response = await languageService.createLanguage(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_LANGUAGE_SUCCESS", data)
                break
            default:
                commit("CREATE_LANGUAGE_ERROR", data)
        }
        return response
    },
    async editLanguage({ commit }, params, uuid) {
        commit("EDIT_LANGUAGE_REQUEST")
        const response = await languageService.editLanguage(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_LANGUAGE_SUCCESS", data)
                break
            default:
                commit("EDIT_LANGUAGE_ERROR", data)
        }
        return response
    },
    async deleteLanguage({ commit }, params) {
        commit("DELETE_LANGUAGE_REQUEST")
        const response = await languageService.deleteLanguage(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_LANGUAGE_SUCCESS", data)
                break
            default:
                commit("DELETE_LANGUAGE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_LANGUAGES_REQUEST]: state => {
        state.loading = true
    },
    [GET_LANGUAGES_SUCCESS]: (state, data) => {
        const languages = data.languages
        state.languages = languages
        state.loading = false
    },
    [GET_LANGUAGES_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_LANGUAGE_REQUEST]: state => {
        state.loading = true
    },
    [GET_LANGUAGE_SUCCESS]: (state, data) => {
        const language = { ...data.language }
        state.language = language
        state.loading = false
    },
    [GET_LANGUAGE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_LANGUAGE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_LANGUAGE_SUCCESS]: (state, data) => {
        const { language } = data
        state.languages = addNewFilter(state.languages, [language])
        state.loading = false
    },
    [CREATE_LANGUAGE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_LANGUAGE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_LANGUAGE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_LANGUAGE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_LANGUAGE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_LANGUAGE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_LANGUAGE_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const language = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}