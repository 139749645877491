import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachAssignmentReconciliationService = {
    async getCoachAssignmentReconciliations(params) {
        let getParams = processQueryParams(params)
        const getCoachAssignmentReconciliationsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-assignment-reconciliations", getCoachAssignmentReconciliationsRequest)
    },
    async getCoachAssignmentReconciliationsByWasUuid(wasUuid) {
        const getCoachAssignmentReconciliationsRequest = {
            method: "GET",
        }
        return await apiRequest(`/api/was-coach-assignment-reconciliations/${wasUuid}`, getCoachAssignmentReconciliationsRequest)
    },
    async getCoachAssignmentReconciliation(uuid) {
        const getCoachAssignmentReconciliationRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-assignment-reconciliations/${uuid}`, getCoachAssignmentReconciliationRequest)
    },
    async createCoachAssignmentReconciliation(params) {
        const createData = processInput(params)
        const createCoachAssignmentReconciliationRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-assignment-reconciliations", createCoachAssignmentReconciliationRequest)
    },
    async editCoachAssignmentReconciliation(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachAssignmentReconciliationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-assignment-reconciliations/${uuid}`, editCoachAssignmentReconciliationRequest)
    },
    async deleteCoachAssignmentReconciliation(uuid) {
        const deleteCoachAssignmentReconciliationRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-assignment-reconciliations/${uuid}`, deleteCoachAssignmentReconciliationRequest)
    }
}