import {
  apiRequest,
  processInput,
  processQueryParams
} from "@/utilities"

export const courseService = {
  async getCourses(params) {

    const getCoursesRequest = {
      method: "GET",
      params: processQueryParams(params)
    }

    return await apiRequest("/api/courses", getCoursesRequest)
  },
  async createCourse(params) {

    const createData = processInput(params)
    const postCourseRequest = {
      method: "POST",
      data: createData
    }
    return await apiRequest("/api/courses", postCourseRequest)
  },
  async getCourse(uuid) {

    const getCourseRequest = {
      method: "GET"
    }

    return await apiRequest(`/api/courses/${uuid}`, getCourseRequest);
  },
  async editCourse(data, uuid) {

    let editData = processInput(data)
    editData.append("_method", "PATCH")

    const updateCourseRequest = {
      method: "POST",
      data: editData
    }

    return await apiRequest(`/api/courses/${uuid}`, updateCourseRequest);
  },
  async deleteCourse(uuid) {

    const deleteCourseRequest = {
      method: "DELETE"
    }

    return await apiRequest(`/api/courses/${uuid}`, deleteCourseRequest);
  }
}
