import {
    apiRequest,
    processInput
} from "@/utilities"

export const eeoGenderService = {
    async getEEOGenders() {
        const getEEOGendersRequest = {
            method: "GET"
        }
        return await apiRequest("/api/eeo-genders", getEEOGendersRequest)
    },
    async getEEOGender(uuid) {
        const getEEOGenderRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/eeo-genders/${uuid}`, getEEOGenderRequest)
    },
    async createEEOGender(params) {
        const createData = processInput(params)
        const createEEOGenderRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/eeo-genders", createEEOGenderRequest)
    },
    async editEEOGender(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editEEOGenderRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/eeo-genders/${uuid}`, editEEOGenderRequest)
    },
    async deleteEEOGender(uuid) {
        const deleteEEOGenderRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/eeo-genders/${uuid}`, deleteEEOGenderRequest)
    }
}