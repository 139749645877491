export const GET_CERTIFICATIONS_REQUEST = "GET_CERTIFICATIONS_REQUEST"
export const GET_CERTIFICATIONS_SUCCESS = "GET_CERTIFICATIONS_SUCCESS"
export const GET_CERTIFICATIONS_ERROR = "GET_CERTIFICATIONS_ERROR"
export const GET_CERTIFICATION_REQUEST = "GET_CERTIFICATION_REQUEST"
export const GET_CERTIFICATION_SUCCESS = "GET_CERTIFICATION_SUCCESS"
export const GET_CERTIFICATION_ERROR = "GET_CERTIFICATION_ERROR"
export const CREATE_CERTIFICATION_REQUEST = "CREATE_CERTIFICATION_REQUEST"
export const CREATE_CERTIFICATION_SUCCESS = "CREATE_CERTIFICATION_SUCCESS"
export const CREATE_CERTIFICATION_ERROR = "CREATE_CERTIFICATION_ERROR"
export const EDIT_CERTIFICATION_REQUEST = "EDIT_CERTIFICATION_REQUEST"
export const EDIT_CERTIFICATION_SUCCESS = "EDIT_CERTIFICATION_SUCCESS"
export const EDIT_CERTIFICATION_ERROR = "EDIT_CERTIFICATION_ERROR"
export const DELETE_CERTIFICATION_REQUEST = "DELETE_CERTIFICATION_REQUEST"
export const DELETE_CERTIFICATION_SUCCESS = "DELETE_CERTIFICATION_SUCCESS"
export const DELETE_CERTIFICATION_ERROR = "DELETE_CERTIFICATION_ERROR"