export const GET_SUB_COURSE_PROGRAMS_REQUEST = "GET_SUB_COURSE_PROGRAMS_REQUEST"
export const GET_SUB_COURSE_PROGRAMS_SUCCESS = "GET_SUB_COURSE_PROGRAMS_SUCCESS"
export const GET_SUB_COURSE_PROGRAMS_ERROR = "GET_SUB_COURSE_PROGRAMS_ERROR"
export const GET_SUB_COURSE_PROGRAM_REQUEST = "GET_SUB_COURSE_PROGRAM_REQUEST"
export const GET_SUB_COURSE_PROGRAM_SUCCESS = "GET_SUB_COURSE_PROGRAM_SUCCESS"
export const GET_SUB_COURSE_PROGRAM_ERROR = "GET_SUB_COURSE_PROGRAM_ERROR"
export const CREATE_SUB_COURSE_PROGRAM_REQUEST = "CREATE_SUB_COURSE_PROGRAM_REQUEST"
export const CREATE_SUB_COURSE_PROGRAM_SUCCESS = "CREATE_SUB_COURSE_PROGRAM_SUCCESS"
export const CREATE_SUB_COURSE_PROGRAM_ERROR = "CREATE_SUB_COURSE_PROGRAM_ERROR"
export const EDIT_SUB_COURSE_PROGRAM_REQUEST = "EDIT_SUB_COURSE_PROGRAM_REQUEST"
export const EDIT_SUB_COURSE_PROGRAM_SUCCESS = "EDIT_SUB_COURSE_PROGRAM_SUCCESS"
export const EDIT_SUB_COURSE_PROGRAM_ERROR = "EDIT_SUB_COURSE_PROGRAM_ERROR"
export const DELETE_SUB_COURSE_PROGRAM_REQUEST = "DELETE_SUB_COURSE_PROGRAM_REQUEST"
export const DELETE_SUB_COURSE_PROGRAM_SUCCESS = "DELETE_SUB_COURSE_PROGRAM_SUCCESS"
export const DELETE_SUB_COURSE_PROGRAM_ERROR = "DELETE_SUB_COURSE_PROGRAM_ERROR"

export const CLEAR_SECTION_SUB_COURSE_PROGRAM_REQUEST = "CLEAR_SECTION_SUB_COURSE_PROGRAM_REQUEST"
export const CLEAR_SECTION_SUB_COURSE_PROGRAM_SUCCESS = "CLEAR_SECTION_SUB_COURSE_PROGRAM_SUCCESS"
export const CLEAR_SECTION_SUB_COURSE_PROGRAM_ERROR = "CLEAR_SECTION_SUB_COURSE_PROGRAM_ERROR"