export const GET_INVOICE_PAYMENTS_REQUEST = "GET_INVOICE_PAYMENTS_REQUEST"
export const GET_INVOICE_PAYMENTS_SUCCESS = "GET_INVOICE_PAYMENTS_SUCCESS"
export const GET_INVOICE_PAYMENTS_ERROR = "GET_INVOICE_PAYMENTS_ERROR"
export const GET_INVOICE_PAYMENT_REQUEST = "GET_INVOICE_PAYMENT_REQUEST"
export const GET_INVOICE_PAYMENT_SUCCESS = "GET_INVOICE_PAYMENT_SUCCESS"
export const GET_INVOICE_PAYMENT_ERROR = "GET_INVOICE_PAYMENT_ERROR"
export const CREATE_INVOICE_PAYMENT_REQUEST = "CREATE_INVOICE_PAYMENT_REQUEST"
export const CREATE_INVOICE_PAYMENT_SUCCESS = "CREATE_INVOICE_PAYMENT_SUCCESS"
export const CREATE_INVOICE_PAYMENT_ERROR = "CREATE_INVOICE_PAYMENT_ERROR"
export const BROADCAST_CREATE_INVOICE_PAYMENT_SUCCESS = "BROADCAST_CREATE_INVOICE_PAYMENT_SUCCESS"
export const EDIT_INVOICE_PAYMENT_REQUEST = "EDIT_INVOICE_PAYMENT_REQUEST"
export const EDIT_INVOICE_PAYMENT_SUCCESS = "EDIT_INVOICE_PAYMENT_SUCCESS"
export const EDIT_INVOICE_PAYMENT_ERROR = "EDIT_INVOICE_PAYMENT_ERROR"
export const BROADCAST_EDIT_INVOICE_PAYMENT_SUCCESS = "BROADCAST_EDIT_INVOICE_PAYMENT_SUCCESS"
export const DELETE_INVOICE_PAYMENT_REQUEST = "DELETE_INVOICE_PAYMENT_REQUEST"
export const DELETE_INVOICE_PAYMENT_SUCCESS = "DELETE_INVOICE_PAYMENT_SUCCESS"
export const DELETE_INVOICE_PAYMENT_ERROR = "DELETE_INVOICE_PAYMENT_ERROR"
export const BROADCAST_DELETE_INVOICE_PAYMENT_SUCCESS = "BROADCAST_DELETE_INVOICE_PAYMENT_SUCCESS"