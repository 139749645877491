import {
	apiRequest,
	processInput,
	processQueryParams 
} from "@/utilities"

export const universityFacultySourcesService = {
	async getUniversityFacultySources(params) {
		const getQueryParams = processQueryParams(params)
        const getUniversityFacultySourcesRequest = {
            method: "GET",
            params: getQueryParams
        }
		return await apiRequest("/api/university-faculty-sources", getUniversityFacultySourcesRequest)
	},
	async renewUfs(params) {
        const createData = processInput(params);
        const postRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/renew-university-faculty-source", postRequest)
    },
	async createUniversityFacultySource(params) {
		const createData = processInput(params);
		const postUniversityFacultySourceRequest = {
			method: "POST",
			data: createData
		}

		return await apiRequest("/api/university-faculty-sources", postUniversityFacultySourceRequest)
	},
	async getUniversityFacultySource(uuid) {

		const getUniversityFacultySourceRequest = {
			method: "GET"
		}

		return await apiRequest(`/api/university-faculty-sources/${uuid}`, getUniversityFacultySourceRequest);
	},
	async editUniversityFacultySource(data, uuid) {
		let editData = processInput(data)
		editData.append("_method", "PATCH");
		const updateUniversityFacultySourceRequest = {
			method: "POST",
			data: editData
		}

		return await apiRequest(`/api/university-faculty-sources/${uuid}`, updateUniversityFacultySourceRequest);
	},
	async deleteUniversityFacultySource(data, uuid) {
		let deleteData = processInput(data)

		const deleteUniversityFacultySourceRequest = {
			method: "DELETE",
			data: deleteData
		}

		return await apiRequest(`/api/university-faculty-sources/${uuid}`, deleteUniversityFacultySourceRequest);
	}
}
