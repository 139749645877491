export const GET_DEGREES_REQUEST = "GET_DEGREES_REQUEST"
export const GET_DEGREES_SUCCESS = "GET_DEGREES_SUCCESS"
export const GET_DEGREES_ERROR = "GET_DEGREES_ERROR"
export const GET_DEGREE_REQUEST = "GET_DEGREE_REQUEST"
export const GET_DEGREE_SUCCESS = "GET_DEGREE_SUCCESS"
export const GET_DEGREE_ERROR = "GET_DEGREE_ERROR"
export const CREATE_DEGREE_REQUEST = "CREATE_DEGREE_REQUEST"
export const CREATE_DEGREE_SUCCESS = "CREATE_DEGREE_SUCCESS"
export const CREATE_DEGREE_ERROR = "CREATE_DEGREE_ERROR"
export const EDIT_DEGREE_REQUEST = "EDIT_DEGREE_REQUEST"
export const EDIT_DEGREE_SUCCESS = "EDIT_DEGREE_SUCCESS"
export const EDIT_DEGREE_ERROR = "EDIT_DEGREE_ERROR"
export const DELETE_DEGREE_REQUEST = "DELETE_DEGREE_REQUEST"
export const DELETE_DEGREE_SUCCESS = "DELETE_DEGREE_SUCCESS"
export const DELETE_DEGREE_ERROR = "DELETE_DEGREE_ERROR"