import {
	GET_PREFIXES_REQUEST,
	GET_PREFIXES_SUCCESS,
	GET_PREFIXES_ERROR,
	GET_PREFIX_REQUEST,
	GET_PREFIX_SUCCESS,
	GET_PREFIX_ERROR,
	CREATE_PREFIX_REQUEST,
	CREATE_PREFIX_SUCCESS,
	CREATE_PREFIX_ERROR,
	EDIT_PREFIX_REQUEST,
	EDIT_PREFIX_SUCCESS,
	EDIT_PREFIX_ERROR,
	DELETE_PREFIX_REQUEST,
	DELETE_PREFIX_SUCCESS,
	DELETE_PREFIX_ERROR
} from "@/actions"

import {
	prefixService
} from "@/services"

const state = {
	prefixes: [],
	prefix: {},
	loading: false
}

const getters = {
	prefixSelectOptions: state => {
		return state.prefixes.map((prefix) => {
			return { label: prefix.label, value: prefix.uuid, }
		});
	},
	prefixByUuid: state => uuid => state.prefixes.find(p => p.uuid == uuid)
}

const actions = {
	async getPrefixes({ commit }) {
		commit("GET_PREFIXES_REQUEST")
		const response = await prefixService.getPrefixes();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PREFIXES_SUCCESS", data)
				break
			default:
				commit("GET_PREFIXES_ERROR", data)
		}
		return response
	},
	async getPrefix({ commit }, params) {
		commit("GET_PREFIX_REQUEST")
		const response = await prefixService.getPrefix(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_PREFIX_SUCCESS", data)
				break
			default:
				commit("GET_PREFIX_ERROR", data)
				break
		}
		return response
	},
	async createPrefix({ commit }, params) {
		commit("CREATE_PREFIX_REQUEST")
		const response = await prefixService.createPrefix(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_PREFIX_SUCCESS", data)
				break
			default:
				commit("CREATE_PREFIX_ERROR", data)
		}
		return response
	},
	async editPrefix({ commit }, params, uuid) {
		commit("EDIT_PREFIX_REQUEST")
		const response = await prefixService.editPrefix(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_PREFIX_SUCCESS", data)
				break
			default:
				commit("EDIT_PREFIX_ERROR", data)
		}
		return response
	},
	async deletePrefix({ commit }, params) {
		commit("DELETE_PREFIX_REQUEST")
		const response = await prefixService.deletePrefix(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_PREFIX_SUCCESS", data)
				break
			default:
				commit("DELETE_PREFIX_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_PREFIXES_REQUEST]: state => {
		state.loading = true
	},
	[GET_PREFIXES_SUCCESS]: (state, data) => {
		const prefixes = [...data.prefixes]
		state.prefixes = prefixes
		state.loading = false
	},
	[GET_PREFIXES_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_PREFIX_REQUEST]: state => {
		state.loading = true
	},
	[GET_PREFIX_SUCCESS]: (state, data) => {
		const prefix = { ...data.prefix }
		state.prefix = prefix
		state.loading = false
	},
	[GET_PREFIX_ERROR]: (state, data) => {
		state.loading = false
	},
	[CREATE_PREFIX_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_PREFIX_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[CREATE_PREFIX_ERROR]: state => {
		state.loading = false
	},
	[EDIT_PREFIX_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_PREFIX_SUCCESS]: state => {
		state.loading = false
	},
	[EDIT_PREFIX_ERROR]: state => {
		state.loading = false
	},
	[DELETE_PREFIX_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_PREFIX_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_PREFIX_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const prefix = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}