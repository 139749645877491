import {
    apiRequest,
    processInput
} from "@/utilities"

export const universityOpmsService = {
    async getUniversityOpms() {
        const getUniversityOpmsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/university-opms", getUniversityOpmsRequest)
    },
    async createUniversityOpm(params) {
        const createData = processInput(params);
        const postUniversityOpmRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/university-opms", postUniversityOpmRequest)
    },
    async getUniversityOpm(uuid) {

        const getUniversityOpmRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/university-opms/${uuid}`, getUniversityOpmRequest);
    },
    async editUniversityOpm(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityOpmRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/university-opms/${uuid}`, updateUniversityOpmRequest);
    },
    async deleteUniversityOpm(uuid) {

        const deleteUniversityOpmRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/university-opms/${uuid}`, deleteUniversityOpmRequest);
    }
}