<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import "vue-select/dist/vue-select.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "App",
  data: () => ({}),
  computed: {},
  methods: {},
  mounted: async function () {},
  created: function () {
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    });

    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish()
    });
  },
};
</script>

<style>

.app_loader {
  position: fixed;
  width: 100vw;
  background: rgba(255, 255, 255, 0.5);
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
