import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const reconciliationItemService = {
    async getReconciliationItems(params) {
        let getParams = processQueryParams(params)
        const getReconciliationItemsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/reconciliation-items", getReconciliationItemsRequest)
    },
    async getReconciliationItem(uuid) {
        const getReconciliationItemRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/reconciliation-items/${uuid}`, getReconciliationItemRequest)
    },
    async createReconciliationItem(params) {
        const createData = processInput(params)
        const createReconciliationItemRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/reconciliation-items", createReconciliationItemRequest)
    },
    async editReconciliationItem(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editReconciliationItemRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/reconciliation-items/${uuid}`, editReconciliationItemRequest)
    },
    async deleteReconciliationItem(uuid) {
        const deleteReconciliationItemRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/reconciliation-items/${uuid}`, deleteReconciliationItemRequest)
    }
}