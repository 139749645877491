export const settingRoutes = {
  path: "settings",
  component: () => import("@/pages/settings/ApplicationSettingsContainer.vue"),
  children: [
    {
      path: "app-settings",
      name: "settings home",
      meta: {
        label: "Application Settings",
      },
      component: () => import("@/pages/settings/ApplicationSettingsHome.vue")
    },
    {
      path: "address-types",
      name: "address type index",
      meta: {
        label: "Address Types",
      },
      component: () => import("@/pages/settings/types/AddressTypeIndex.vue")
    },
    {
      path: "comment-types",
      name: "comment type index",
      meta: {
        label: "Comment Types",
      },
      component: () => import("@/pages/settings/types/CommentTypeIndex.vue")
    },
    {
      path: "document-types",
      name: "document type index",
      meta: {
        label: "Document Types",
      },
      component: () => import("@/pages/settings/types/DocumentTypeIndex.vue")
    },
    {
      path: "email-types",
      name: "email type index",
      meta: {
        label: "Email Types",
      },
      component: () => import("@/pages/settings/types/EmailTypeIndex.vue")
    },
    {
      path: "employment-types",
      name: "employment type index",
      meta: {
        label: "Employment Types",
      },
      component: () => import("@/pages/settings/types/EmploymentTypeIndex.vue")
    },
    {
      path: "point-of-contact-types",
      name: "point of contact type index",
      meta: {
        label: "Point Of Contact Types",
      },
      component: () => import("@/pages/settings/types/PointOfContactTypeIndex.vue")
    },
    {
      path: "phone-types",
      name: "phone type index",
      meta: {
        label: "Phone Types",
      },
      component: () => import("@/pages/settings/types/PhoneTypeIndex.vue")
    },
    {
      path: "task-settings",
      name: "task-settings",
      meta: {
        label: "Task Settings",
      },
      component: () => import("@/pages/settings/TaskSettings.vue")
    },
    {
      path: "areas-of-qualification",
      name: "areas of qualification",
      meta: {
        label: "Areas Of Qualification"
      },
      component: () => import("@/pages/settings/coach/AreasOfQualification.vue")
    },
    {
      path: "specialties",
      name: "specialties",
      meta: {
        label: "Specialties"
      },
      component: () => import("@/pages/settings/coach/SpecialtyIndex.vue")
    },
    {
      path: "status-explanations",
      name: "status explanations",
      meta: {
        label: "Status Explanations"
      },
      component: () => import("@/pages/settings/coach/StatusExplanation.vue")
    },
    {
      path: "certifications",
      name: "certifications",
      meta: {
        label: "Certifications"
      },
      component: () => import("@/pages/settings/coach/CertificationIndex.vue")
    },
    {
      path: "nurse-licenses",
      name: "nurse licenses",
      meta: {
        label: "Nurse Licenses"
      },
      component: () => import("@/pages/settings/coach/NurseLicenses.vue")
    },
    {
      path: "regional-accreditors",
      name: "regional accreditors",
      meta: {
        label: "Regional Accreditors"
      },
      component: () => import("@/pages/settings/university/RegionalAccreditors.vue")
    },
    {
      path: "employers",
      name: "other employers",
      meta: {
        label: "Other Employers",
      },
      component: () => import("@/pages/settings/OtherEmployers.vue"),
    },
    {
      path: "universities",
      name: "other universities",
      meta: {
        label: "Universities",
      },
      component: () => import("@/pages/settings/OtherUniversity.vue"),
    },
    {
      path: "disciplines",
      name: "disciplines",
      meta: {
        label: "Disciplines",
      },
      component: () => import("@/pages/settings/education/DisciplineIndex.vue"),
    },
    {
      path: "minors",
      name: "minors",
      meta: {
        label: "Minors",
      },
      component: () => import("@/pages/settings/education/MinorIndex.vue"),
    },
    {
      path: "sub-course-programs",
      name: "sub-course-programs",
      meta: {
        label: "Program Sub Types",
      },
      component: () => import("@/pages/settings/sub-course-program/SubCourseProgramIndex.vue"),
    },
    {
      path: "masquerade",
      name: "masquerade",
      meta: {
        label: "Masquerade As User",
      },
      component: () => import("@/pages/settings/UserMasquerade.vue"),
    },
    {
      path: "invoice-email-body",
      name: "invoice email body",
      meta: {
        label: "Invoice Email Body",
      },
      component: () => import("@/pages/settings/InvoiceEmailBody.vue"),
    }
  ]
};
