export const GET_IA_APPLICATIONS_REQUEST = "GET_IA_APPLICATIONS_REQUEST"
export const GET_IA_APPLICATIONS_SUCCESS = "GET_IA_APPLICATIONS_SUCCESS"
export const GET_IA_APPLICATIONS_ERROR = "GET_IA_APPLICATIONS_ERROR"
export const GET_IA_APPLICATION_REQUEST = "GET_IA_APPLICATION_REQUEST"
export const GET_IA_APPLICATION_SUCCESS = "GET_IA_APPLICATION_SUCCESS"
export const GET_IA_APPLICATION_ERROR = "GET_IA_APPLICATION_ERROR"
export const CREATE_IA_APPLICATION_REQUEST = "CREATE_IA_APPLICATION_REQUEST"
export const CREATE_IA_APPLICATION_SUCCESS = "CREATE_IA_APPLICATION_SUCCESS"
export const CREATE_IA_APPLICATION_ERROR = "CREATE_IA_APPLICATION_ERROR"
export const BROADCAST_CREATE_IA_APPLICATION_SUCCESS = "BROADCAST_CREATE_IA_APPLICATION_SUCCESS"
export const EDIT_IA_APPLICATION_REQUEST = "EDIT_IA_APPLICATION_REQUEST"
export const EDIT_IA_APPLICATION_SUCCESS = "EDIT_IA_APPLICATION_SUCCESS"
export const EDIT_IA_APPLICATION_ERROR = "EDIT_IA_APPLICATION_ERROR"
export const BROADCAST_EDIT_IA_APPLICATION_SUCCESS = "BROADCAST_EDIT_IA_APPLICATION_SUCCESS"
export const DELETE_IA_APPLICATION_REQUEST = "DELETE_IA_APPLICATION_REQUEST"
export const DELETE_IA_APPLICATION_SUCCESS = "DELETE_IA_APPLICATION_SUCCESS"
export const DELETE_IA_APPLICATION_ERROR = "DELETE_IA_APPLICATION_ERROR"
export const BROADCAST_DELETE_IA_APPLICATION_SUCCESS = "BROADCAST_DELETE_IA_APPLICATION_SUCCESS"