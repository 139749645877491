import {
	GET_COACHES_REQUEST,
	GET_COACHES_SUCCESS,
	GET_LIMITED_COACHES_SUCCESS,
	GET_COACHES_ERROR,
	GET_COACH_REQUEST,
	GET_COACH_SUCCESS,
	GET_COACH_ERROR,
	CREATE_COACH_REQUEST,
	CREATE_COACH_SUCCESS,
	CREATE_COACH_ERROR,
	EDIT_COACH_REQUEST,
	EDIT_COACH_SUCCESS,
	EDIT_COACH_ERROR,
	CUSTOM_COACH_REQUEST,
	CUSTOM_COACH_SUCCESS,
	CUSTOM_COACH_ERROR,
	DELETE_COACH_REQUEST,
	DELETE_COACH_SUCCESS,
	DELETE_COACH_ERROR,
	CLEAR_CURRENT_COACH,
	BROADCAST_DNUA_UPDATED,
} from "@/actions"

import {
	coachService
} from "@/services"

import {
	unpackCoachEducation,
	unpackCoachAssignment,
	unpackProgramData,
	unpackUniversity,
	unpackCoachScreeningSession
} from "@/modules"

import { addNewFilter, isEmpty } from "@/utilities"

import _, { map } from 'underscore'

const state = {
	coaches: [],
	coach: {},
	applicantCenterLoading: false,
	applicantCenterLoaded: false,
	loading: false,
	loadedOnce: false,
	activeCoachSlugs: [
		'active',
		'contracted',
		'pending',
		'inactive'
	],
	searchStatusSelectOptions: [

	],
	nhdPdDocsData: [],
}


const getters = {
	coachesLoading: state => state.loading,
	coachesLoadedOnce: state => state.loadedOnce,
	applicantCenterLoading: state => state.applicantCenterLoading,
	applicantCenterLoaded: state => state.applicantCenterLoaded,
	coach: state => state.coach,
	allCoaches: state => state.coaches,
	coachByUuid: state => cUuid => {
		let coach = state.coaches.find(c => c.uuid == cUuid)
		return coach
	},
	coachesByUuids: state => cUuids => {
		return state.coaches.filter(c => cUuids.some(i => i == c.uuid))
	},
	applicationSubmittedCoaches: (state, getters, rootState, rootGetters) => state.coaches.filter(i => {
		const coachStatusGetter = rootGetters['coachStatus/coachStatusByCoachUuid']
		const coachStatus = coachStatusGetter(i.uuid)
		if (isEmpty(coachStatus)) return false;
		const statusGetter = rootGetters['status/statusFilterByUuid'];
		const status = statusGetter(coachStatus.statuses_uuid)

		const statusExpGetter = rootGetters['statusExplanation/statusExpFilterByUuid'];
		const statusExp = statusExpGetter(coachStatus.status_explanations_uuid)
		if (status?.slug == 'applicant') {
			if (statusExp?.slug == 'application-submitted') {
				const caGetter = rootGetters['coachApplication/coachApplicationByCoachUuid']
				const ca = caGetter(i.uuid)
				if (!isEmpty(ca?.application_submitted)) {
					return true
				}
			}
		}
		return false
	}),
	coachStatusByCoachUuid: (state, getters) => uuid => {
		let thisCoach = getters.coachApplicantsCandidates.find(coach => coach.uuid == uuid)
		return thisCoach.status.status_explanation.uuid
	},
	allCoachOpts: state => state.coaches.map(i => {
		return {
			label: `${i.first_name} ${i.last_name}`,
			value: i.uuid,
		}
	}),
	getMissingDocsData: state => cUuid => {
		if (isEmpty(cUuid)) return state.nhdPdDocsData;
		return state.nhdPdDocsData.find(i => i.uuid == cUuid);
	},
	reqCaConfirmCoaches: (state, getters) => getters.allCoaches.filter(i => i.req_ca_conf == 1),
}

export function unpackCoach(coach, commit) {

	Object.keys(coach).forEach(key => {

		let data = {}
		let value = coach[key]

		if (!isEmpty(value)) {

			data.success = true

			switch (key) {
				case "addresses":
					data[key] = value ?? []
					commit('address/GET_ADDRESSES_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "application":
					data.coach_applications = [value] ?? []
					commit("coachApplication/GET_COACH_APPLICATIONS_SUCCESS", data, { root: true })
					delete coach[key]
					break
				case "background_checks":
					data.background_checks = value ?? [];
					commit("backgroundCheck/GET_COACH_BACKGROUND_CHECKS_SUCCESS", data, { root: true })
					delete coach[key]
					break
				case "certifications":
					data.coach_certifications = value ?? []
					commit("coachCertifications/GET_COACH_CERTIFICATIONS_SUCCESS", data, { root: true })
					delete coach[key]
					break
				case "comments":
					data.comments = value ?? []
					commit("comments/GET_COMMENTS_SUCCESS", data, { root: true })
					delete coach[key]
					break
				case "documents":
					data.documents = value ?? []
					commit('document/GET_DOCUMENTS_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "education":
					data.coach_educations = coach.education.map(ce => unpackCoachEducation(ce, commit))
					commit('coachEducation/GET_COACH_EDUCATIONS_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "education_experience":
					data.coach_education_experiences = value ?? []
					commit('coachEducationExperience/GET_COACH_EDUCATION_EXPERIENCES_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "eeo":
					data.coach_eeos = !isEmpty(value) ? [value] : []
					commit('coachEEO/GET_COACH_EEOS_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "emails":
					data.emails = value ?? []
					commit('email/GET_EMAILS_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "employments":
					data.coach_employments = value ?? []
					commit('coachEmployment/GET_COACH_EMPLOYMENTS_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "facilitating_courses":
					data.coach_facilitating_courses = value ?? []
					commit('coachFacilitatingCourse/GET_COACH_FACILITATING_COURSES_SUCCESS', data, { root: true })
					delete coach[key]
					delete data.coach_facilitating_courses
					break
				case "faculty_referrals":
					data.coach_faculty_referrals = coach.faculty_referrals
					commit('coachFacultyReferral/GET_COACH_FACULTY_REF_SUCCESS', data, { root: true })
					delete data.coach_faculty_referrals
					break
				case "languages":
					data.coach_languages = value ?? []
					commit('coachLanguage/GET_COACH_LANGUAGES_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "lms_experience":
					data.coach_lms_experiences = value ?? []
					commit('coachLmsExperience/GET_COACH_LMS_EXPERIENCES_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "nurse_licenses":
					data.coach_nurse_licenses = value ?? []
					commit('coachNurseLicense/GET_COACH_NURSE_LICENSES_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "references":
					data.coach_references = value ?? []
					commit('coachReference/GET_COACH_REFERENCES_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "referrals":
					data.coach_referrals = value ?? []
					commit('coachReferralSource/GET_COACH_REFERRALS_SUCCESS', data, { root: true })
					delete coach[key]
					break;
				case "referral_sources":
					data.coach_referral_sources = value ?? []
					commit('coachReferralSource/GET_COACH_REFERRAL_SOURCES_SUCCESS', data, { root: true })
					delete coach[key];
					break;
				case "universities":
					data.universities = value.map(u => unpackUniversity(u, commit))
					commit('university/GET_UNIVERSITIES_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "screening_session":
					data.coach_screening_session = value ?? {}
					data.coach_screening_session = unpackCoachScreeningSession(data.coach_screening_session, commit);
					commit('coachScreeningSession/GET_COACH_SCREENING_SESSION_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "screening_sessions":
					data.coach_screening_sessions = value ?? []
					commit('coachScreeningSession/GET_COACH_SCREENING_SESSIONS_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "specialties":
					data.coach_specialties = value ?? []
					commit('coachSpecialties/GET_COACH_SPECIALTIES_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "teaching_course_levels":
					data.coach_teaching_course_levels = value ?? []
					commit('coachTeachingCourseLevel/GET_COACH_TEACHING_COURSE_LEVELS_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "teaching_profile":
					data.coach_teaching_profiles = !isEmpty(value) ? [value] : []
					commit('coachTeachingProfile/GET_COACH_TEACHING_PROFILES_SUCCESS', data, { root: true })
					delete coach[key]
					break

				case "phones":
					data.phones = value ?? []
					commit('phone/GET_PHONES_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "assignments":
				case "coach_assignments":
					data.coach_assignments = coach.assignments.map(ca => unpackCoachAssignment(ca, commit))
					commit('coachAssignment/GET_COACH_ASSIGNMENTS_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "status":
					data.coach_statuses = !isEmpty(value) ? [value] : []
					commit("coachStatus/GET_COACH_STATUSES_SUCCESS", data, { root: true })
					delete coach[key]
					break
				case "tasks":
					data.tasks = isEmpty(value) ? [] : value
					commit('task/GET_TASKS_SUCCESS', data, { root: true })
					delete coach[key]
					break
				case "university_programs":
					data.university_programs = value.map(up => unpackProgramData(up, commit))
					commit("universityProgram/GET_UNIVERSITY_PROGRAMS_SUCCESS", data, { root: true })
					delete data.university_programs
					break
				case 'perfomance_notes':
					data.perfomance_notes = isEmpty(value) ? [] : value
					commit('perfomanceNote/GET_PERFORMANCE_NOTES_SUCCESS', data, { root: true })
					delete data.perfomance_notes
					break;
			}
		}
	})

	return coach;
}

const actions = {
	async getCoaches({ commit }, params) {
		const addToStore = params?.addToStore ?? true
		delete params['addToStore']
		commit("GET_COACHES_REQUEST", params)
		const response = await coachService.getCoaches(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					if (addToStore) {
						if (Array.isArray(data.coaches)) {
							data.coaches = data.coaches.map(c => unpackCoach(c, commit));
							data.params = params;
							data.success ? commit("GET_COACHES_SUCCESS", data, params) : null;
						} else if (Array.isArray(data.coaches.data)) {
							data.coaches.data = data.coaches.data.map(c => unpackCoach(c, commit));
							data.params = params;
							data.success ? commit("GET_COACHES_SUCCESS", { coaches: data.coaches.data }, params) : null;
						}
					}
					// else {
					// 	commit("GET_COACHES_SUCCESS", { coaches: [] })
					// }
				}

				if (data.errors) {
					commit("GET_COACHES_ERROR", data)
				}

				break
			default:
				commit("GET_COACHES_ERROR", data)
		}

		return response
	},
	async getCoach({ commit }, params) {
		commit("GET_COACH_REQUEST")
		const response = await coachService.getCoach(params)
		const { status, data } = response
		switch (status) {
			case 200:
				if (data.success) {
					if (!isEmpty(data.coach.application)) {
						const coach_application = data.coach.application
						commit("coachApplication/GET_COACH_APPLICATION_SUCCESS", { coach_application }, { root: true })
					}
					data.coach = unpackCoach(data.coach, commit)
					commit("GET_COACH_SUCCESS", data)
				}
				break
			default:
				commit("GET_COACH_ERROR", data)
				break
		}

		return response
	},
	async getCoachWithTid({ commit }, uuid) {
		commit("GET_COACH_REQUEST")
		const response = await coachService.getCoach({ uuid, 'view-tin': uuid })
		const { status, data } = response
		switch (status) {
			case 200:
				// if (data.success) {
				// 	data.coach = unpackCoach(data.coach, commit)
				// 	commit("GET_COACH_SUCCESS", data)
				// }
				break
			default:
				commit("GET_COACH_ERROR", data)
				break
		}
		return response
	},
	async createCoach({ commit }, params) {
		commit("CREATE_COACH_REQUEST")
		const response = await coachService.createCoach(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("CREATE_COACH_SUCCESS", data)
				break
			default:
				commit("CREATE_COACH_ERROR", data)
		}
		return response
	},
	async editCoach({ commit }, params) {
		const uuid = params.uuid
		commit("EDIT_COACH_REQUEST")
		const response = await coachService.editCoach(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("EDIT_COACH_SUCCESS", data)
				break
			default:
				commit("EDIT_COACH_ERROR", data)
		}
		return response
	},
	async coachCustomActions({ commit, dispatch }, params) {
		const uuid = params.uuid
		commit("CUSTOM_COACH_REQUEST")
		const response = await coachService.coachCustomActions(params, uuid)
		const { status, data } = response

		switch (status) {
			case 200:
				data.error ? dispatch('alert/createAlertErrors', data.errors ?? "Something went wrong!", { root: true }) : null
				data.success ? dispatch('alert/createAlertSuccess', "Success", { root: true }) : null;
				break
			default:
				commit("CUSTOM_COACH_ERROR", data)
		}

		return response
	},
	async deleteCoach({ commit }, params) {
		commit("DELETE_COACH_REQUEST")
		const response = await coachService.deleteCoach(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_COACH_SUCCESS", data)
				break
			default:
				commit("DELETE_COACH_DELETE", data)
		}
		return response
	},
	async clearCurrentCoach({ commit }) {
		commit("CLEAR_CURRENT_COACH")
		return
	},
	async preferredCoachCheck({ commit }, params) {
		const response = await coachService.preferredCoachCheck(params)
		return response;
	}
}

const mutations = {
	[GET_COACHES_REQUEST]: (state, params) => {
		if (params?.queryType == 'applicantCenter') {
			state.applicantCenterLoading = true
		}
		state.loading = true
	},
	[GET_COACHES_SUCCESS]: (state, data) => {
		const coaches = data.coaches
		coaches.forEach(coach => {
			state = unpackNhdPdData(coach, state)
		})
		state.coaches = addNewFilter(state.coaches, coaches, true)
		if (data?.params?.queryType == 'applicantCenter') {
			state.applicantCenterLoading = false;
			state.applicantCenterLoaded = true;
		}
		state.loading = false
		state.loadedOnce = true
	},
	[GET_LIMITED_COACHES_SUCCESS]: (state, data) => {
		const coaches = data.coaches
		coaches.forEach(coach => {
			state = unpackNhdPdData(coach, state)
		})
		state.coaches = addNewFilter(state.coaches, coaches)
		state.loading = false
		state.loadedOnce = true
	},

	[GET_COACHES_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_COACH_REQUEST]: state => {
		state.loading = true
	},
	[GET_COACH_SUCCESS]: (state, data) => {
		const { coach } = data
		state.coach = coach
		state = unpackNhdPdData(coach, state)
		state.coaches = addNewFilter(state.coaches, [coach])
		state.loading = false
	},
	[GET_COACH_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_COACH_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_COACH_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[CREATE_COACH_ERROR]: state => {
		state.loading = false
	},

	[EDIT_COACH_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_COACH_SUCCESS]: (state, data) => {
		const coach = { ...data.coach }
		state = unpackNhdPdData(coach, state)
		state.coach = coach
		state.loading = false
	},
	[EDIT_COACH_ERROR]: state => {
		state.loading = false
	},

	[CUSTOM_COACH_REQUEST]: state => {
		state.loading = true
	},
	[CUSTOM_COACH_SUCCESS]: (state, data) => {
		const coach = { ...data.coach }
		state.coach = coach
		state.loading = false
	},
	[CUSTOM_COACH_ERROR]: state => {
		state.loading = false
	},

	[DELETE_COACH_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_COACH_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[DELETE_COACH_ERROR]: (state, data) => {
		state.loading = false
	},

	[CLEAR_CURRENT_COACH]: state => {
		state.coach = {}
	},
	[BROADCAST_DNUA_UPDATED]: (state, data) => {
		const {coach} = data
		console.log(state.coach, coach, 'BROADCAST_DNUA_UPDATED');
		if(coach){
			if(state.coach?.uuid == coach.uuid){
				state.coach.dnua = coach.dnua
			}
		}
		state.loading = false
	},

}

export const coaches = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}


function unpackNhdPdData(coach, state) {
	if (coach?.missing_docs) {
		if (isEmpty(coach.missing_docs)) return state;
		if (state.nhdPdDocsData.some(dc => dc.uuid == coach.uuid)) {
			state.nhdPdDocsData = state.nhdPdDocsData.filter(dc => dc.uuid != coach.uuid)
		}
		state.nhdPdDocsData.push({
			uuid: coach.uuid,
			...coach.missing_docs
		})
	}
	return state
}
