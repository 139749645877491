import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const offerService = {
    async getOffers() {
        const getOffersRequest = {
            method: "GET"
        }
        return await apiRequest("/api/offers", getOffersRequest)
    },
    async createOffer(params) {
        const createData = processInput(params);
        const postOfferRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/offers", postOfferRequest)
    },
    async getOffer(uuid) {

        const getOfferRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/offers/${uuid}`, getOfferRequest);
    },
    async editOffer(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateOfferRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/offers/${uuid}`, updateOfferRequest);
    },
    async deleteOffer(data) {

        let params = processQueryParams(data)
        const deleteOfferRequest = {
            method: "DELETE",
            params
        }

        return await apiRequest(`/api/offers/${data.uuid}`, deleteOfferRequest);
    }
}