import {
	GET_FACULTY_EVALUATIONS_REQUEST,
	GET_FACULTY_EVALUATIONS_SUCCESS,
	GET_FACULTY_EVALUATIONS_ERROR,
	GET_FACULTY_EVALUATION_REQUEST,
	GET_FACULTY_EVALUATION_SUCCESS,
	GET_FACULTY_EVALUATION_ERROR,
	GET_FACULTY_EVALUATION_STATUS_REQUEST,
	GET_FACULTY_EVALUATION_STATUS_SUCCESS,
	GET_FACULTY_EVALUATION_STATUS_ERROR,
	CREATE_FACULTY_EVALUATION_REQUEST,
	CREATE_FACULTY_EVALUATION_SUCCESS,
	CREATE_FACULTY_EVALUATION_ERROR,
	EDIT_FACULTY_EVALUATION_REQUEST,
	EDIT_FACULTY_EVALUATION_SUCCESS,
	EDIT_FACULTY_EVALUATION_ERROR,
	DELETE_FACULTY_EVALUATION_REQUEST,
	DELETE_FACULTY_EVALUATION_SUCCESS,
	DELETE_FACULTY_EVALUATION_ERROR,
} from "@/actions"

import {
	facultyEvaluationService
} from "@/services"

import { addNewFilter, isEmpty } from "@/utilities"
import { unpackCoachAssignment, unpackCoach, unpackCourseStart, unpackCourse, unpackProgramData, unpackWAS, unpackCourseStartFaculty } from "@/modules"

const state = {
	facultyEvaluations: [],
	facultyEvaluation: {},
	loading: false,
	loadedOnce: false,
	facultyEvaluationStatusLoadedCsUuid: [],
	meetOpts: [
		{label: 'Select one', value: null, disabled: true},
		{label: '5 - Attended 90%-100% of Meetings', value: 5},
		{label: '4 - Attended 80%-90% of Meetings', value: 4},
		{label: '3 - Attended 70%-80% of Meetings', value: 3},
		{label: '2 - Attended 60%-70% of Meetings', value: 2},
		{label: '1 - Attended Less than 60% of Meetings', value: 1},
	],
	reviewOpts: [
		{label: 'Select one', value: null, disabled: true},
		{label: '5 - 90%-100%', value: 5},
		{label: '4 - 80%-90%', value: 4},
		{label: '3 - 70%-80%', value: 3},
		{label: '2 - 60%-70%', value: 2},
		{label: '1 - Less than 60%', value: 1},
	],
	yesNoOpts: [
		{label: 'Select one', value: null, disabled: true},
		{label: 'Yes', value: 1},
		{label: 'No', value: 0},
	],
	evaluationQuestion: {
		meetings_attendance: "How often did the Teaching Assistant attend Meetings/Conference Calls?",
		uphold_syllabus: "How well did the Teaching Assistant uphold the plan in the syllabus?",
		reflect_grading_standards: "How well did the Teaching Assistant reflect your grading standards on open ended assignments?",
		coach_again: "Would you like to work with this Teaching Assistant again?",
		feedback: "Comments/Feedback:",
	}
}

const getters = {
	facultyEvaluationsLoading: state => state.loading,
	facultyEvaluationsLoadedOnce: state => state.loadedOnce,
	evaluationMeetOpts: state => state.meetOpts,
	evaluationReviewOpts: state => state.reviewOpts,
	evaluationYesNoOpts: state => state.yesNoOpts,
	evaluationQuestion: state => title => {
		if(isEmpty(title)) return state.evaluationQuestion;
		const ques = state.evaluationQuestion
		const newQues = {}
		for(let k in ques){
			const que = ques[k]
			newQues[k] = que.replaceAll("Teaching Assistant", title);
		}
		return newQues;
	},
	facultyAssignmentEvaluations: state => state.facultyEvaluations,
	findFacultyEvaluations: state => uuid => state.facultyEvaluations.filter(c => c.course_start_faculty_uuid == uuid),
	findFacultyCoachEvaluation: state => uuid => state.facultyEvaluations.find(c => c.coach_assignment_uuid == uuid),
}

const actions = {
	async getFacultyEvaluations({ state, commit }, params) {
		commit("GET_FACULTY_EVALUATIONS_REQUEST");
		const response = await facultyEvaluationService.getFacultyEvaluations(params);
		const { status, data } = response
		switch (status) {
			case 200:
				if(!params?.skipStore){
					commit("GET_FACULTY_EVALUATIONS_SUCCESS", data);
				}
				break
			default:
				commit("GET_FACULTY_EVALUATIONS_ERROR", data)
		}
		return response
	},
	async getFacultyEvaluation({ commit, state }, params) {
		commit("GET_FACULTY_EVALUATION_REQUEST")
		const response = await facultyEvaluationService.getFacultyEvaluation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_FACULTY_EVALUATION_SUCCESS", data)
				break
			default:
				commit("GET_FACULTY_EVALUATION_ERROR", data)
				break
		}
		return response
	},
	async getFacultyEvaluationStatus({ commit }, uuid) {
		commit("GET_FACULTY_EVALUATION_REQUEST")
		const response = await facultyEvaluationService.getFacultyEvaluationStatus(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				const faculty_evaluation_status = unpackFacultyEvaluation(data.faculty_evaluation_status, commit)
				commit("GET_FACULTY_EVALUATIONS_SUCCESS", {faculty_evaluations: faculty_evaluation_status.faculty_evaluations})
				break
			default:
				commit("GET_FACULTY_EVALUATION_ERROR", data)
				break
		}
		return response
	},
	async createFacultyEvaluation({ commit, dispatch }, params) {
		commit("CREATE_FACULTY_EVALUATION_REQUEST")
		const response = await facultyEvaluationService.createFacultyEvaluation(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_FACULTY_EVALUATION_SUCCESS", data) : commit("CREATE_FACULTY_EVALUATION_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Evaluation Submitted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("CREATE_FACULTY_EVALUATION_ERROR", data)
		}
		return response
	},
	async editFacultyEvaluation({ commit, dispatch }, params) {
		commit("EDIT_FACULTY_EVALUATION_REQUEST")
		const response = await facultyEvaluationService.editFacultyEvaluation(params, params.uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data?.success ? commit("EDIT_FACULTY_EVALUATION_SUCCESS", data) : null;
				data?.success ? dispatch('alert/createAlertSuccess', "Evaluation updated!", { root: true }) : null;
				break
			default:
				commit("EDIT_FACULTY_EVALUATION_ERROR", data)
		}
		return response
	},
	async deleteFacultyEvaluation({ commit, dispatch }, uuid) {
		commit("DELETE_FACULTY_EVALUATION_REQUEST")
		const response = await facultyEvaluationService.deleteFacultyEvaluation(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("DELETE_FACULTY_EVALUATION_SUCCESS", { uuid }) : commit("DELETE_FACULTY_EVALUATION_ERROR")
				data.success ? dispatch('alert/createAlertSuccess', "Evaluation deleted!", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
				break
			default:
				commit("DELETE_FACULTY_EVALUATION_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_FACULTY_EVALUATIONS_REQUEST]: state => {
		state.loading = true
	},
	[GET_FACULTY_EVALUATIONS_SUCCESS]: (state, data) => {
		state.facultyEvaluations = addNewFilter(state.facultyEvaluations, data.faculty_evaluations)
		state.loading = false
		state.loadedOnce = true
	},
	[GET_FACULTY_EVALUATIONS_ERROR]: state => {
		state.loading = false
		state.error = true
		state.loadedOnce = true
	},
	[GET_FACULTY_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[GET_FACULTY_EVALUATION_SUCCESS]: (state, data) => {
		const { faculty_evaluation }  = data
		state.facultyEvaluations = addNewFilter(state.facultyEvaluations, [faculty_evaluation])
		state.loading = false
	},
	[GET_FACULTY_EVALUATION_ERROR]: (state, data) => {
		state.loading = false
	},


	[GET_FACULTY_EVALUATION_STATUS_REQUEST]: state => {
		state.loading = true
	},
	[GET_FACULTY_EVALUATION_STATUS_SUCCESS]: (state, data) => {
		state.loading = false
	},
	[GET_FACULTY_EVALUATION_STATUS_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_FACULTY_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_FACULTY_EVALUATION_SUCCESS]: (state, data) => {
		const { faculty_evaluation } = data;
		state.facultyEvaluations = addNewFilter(state.facultyEvaluations, [faculty_evaluation])
		state.loading = false
	},
	[CREATE_FACULTY_EVALUATION_ERROR]: state => {
		state.loading = false
	},

	[EDIT_FACULTY_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_FACULTY_EVALUATION_SUCCESS]: (state, data) => {
		const { faculty_evaluation } = data;
		state.facultyEvaluations = addNewFilter(state.facultyEvaluations, [faculty_evaluation])
		state.loading = false;
	},
	[EDIT_FACULTY_EVALUATION_ERROR]: state => {
		state.loading = false
	},

	[DELETE_FACULTY_EVALUATION_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_FACULTY_EVALUATION_SUCCESS]: (state, data) => {
		const { uuid } = data;
		state.facultyEvaluations = state.facultyEvaluations.filter(c => c.uuid !== uuid)
		state.loading = false
	},
	[DELETE_FACULTY_EVALUATION_ERROR]: (state) => {
		state.loading = false
	},
}

export const facultyEvaluation = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

export function unpackFacultyEvaluation(data, commit){
	if(!isEmpty(data.faculty)){
        const faculty = unpackCourseStartFaculty(data.faculty, commit);
        commit("courseStartFaculty/GET_COURSE_START_FACULTYS_SUCCESS", { course_start_facultys: [faculty] }, { root: true })
    }
	if(!isEmpty(data.coaches)){
        const coaches = data.coaches
        commit("coaches/GET_COACHES_SUCCESS", { coaches }, { root: true })
    }
    if (!isEmpty(data.coach_assignments)) {
		const coach_assignments = data.coach_assignments
		commit('coachAssignment/GET_COACH_ASSIGNMENTS_SUCCESS', { coach_assignments }, { root: true })
	}
    if (!isEmpty(data.course_start)) {
		const course_start = data.course_start
		commit('courseStart/GET_COURSE_STARTS_SUCCESS', { course_starts: [course_start] }, { root: true })
	}
    if (!isEmpty(data.course)) {
		const course = data.course
		commit('course/GET_COURSES_SUCCESS', { courses: [course] }, { root: true })
	}
    if (!isEmpty(data.university_program)) {
		const university_program = data.university_program
		commit('universityProgram/GET_UNIVERSITY_PROGRAMS_SUCCESS', { university_programs: [university_program] }, { root: true })
	}
    if (!isEmpty(data.university)) {
		const university = data.university
		commit('university/GET_UNIVERSITY_SUCCESS', { university }, { root: true })
	}
	return data;
}
