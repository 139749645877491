<template>
  <div :class="divClass">
    <div class="d-flex align-items-end justify-content-end w-100">
      <small class="text-muted">
        Use Keyboard:
        <span
          v-if="enter"
          :class="[enterClass, { ' mr-1': esc }]"
        ><b>Enter</b> ({{ enterAction }})</span>
        <span
          v-if="esc"
          :class="escClass"
        ><b>Esc</b> ({{ escAction }})</span>
      </small>
    </div>
  </div>
</template>

<script>

export default {
  name: "keyBoardInfo",
  props: {
    enter: {
      type: Boolean,
      default: true,
      required: false
    },
    esc: {
      type: Boolean,
      default: true,
      required: false
    },
    enterAction: {
      type: String,
      default: 'Submit',
      required: false
    },
    escAction: {
      type: String,
      default: 'Cancel',
      required: false
    },
    enterClass: {
      type: String,
      default: 'text-success',
      required: false
    },
    escClass: {
      type: String,
      default: 'text-danger',
      required: false
    },
    divClass: {
      type: String,
      default: 'd-block w-100',
      required: false
    },
  }
}
</script>
