import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachEEOService = {
    async getCoachEEOs(params) {
        let getParams = processQueryParams(params)
        const getCoachEEOsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-eeos", getCoachEEOsRequest)
    },
    async getCoachEEO(uuid) {
        const getCoachEEORequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-eeos/${uuid}`, getCoachEEORequest)
    },
    async createCoachEEO(params) {
        const createData = processInput(params)
        const createCoachEEORequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-eeos", createCoachEEORequest)
    },
    async editCoachEEO(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachEEORequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-eeos/${uuid}`, editCoachEEORequest)
    },
    async deleteCoachEEO(uuid) {
        const deleteCoachEEORequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-eeos/${uuid}`, deleteCoachEEORequest)
    }
}