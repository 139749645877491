import {
	GET_REGIONAL_ACCREDITORS_REQUEST,
	GET_REGIONAL_ACCREDITORS_SUCCESS,
	GET_REGIONAL_ACCREDITORS_ERROR,
	GET_REGIONAL_ACCREDITOR_REQUEST,
	GET_REGIONAL_ACCREDITOR_SUCCESS,
	GET_REGIONAL_ACCREDITOR_ERROR,
	CREATE_REGIONAL_ACCREDITOR_REQUEST,
	CREATE_REGIONAL_ACCREDITOR_SUCCESS,
	CREATE_REGIONAL_ACCREDITOR_ERROR,
	EDIT_REGIONAL_ACCREDITOR_REQUEST,
	EDIT_REGIONAL_ACCREDITOR_SUCCESS,
	EDIT_REGIONAL_ACCREDITOR_ERROR,
	DELETE_REGIONAL_ACCREDITOR_REQUEST,
	DELETE_REGIONAL_ACCREDITOR_SUCCESS,
	DELETE_REGIONAL_ACCREDITOR_ERROR
} from "@/actions"

import {
	regionalAccreditorsService
} from "@/services"

const state = {
	regionalAccreditors: [],
	regionalAccreditor: {},
	loading: false,
	loadedOnce: false,
}

import { addNewFilter } from "@/utilities"

const getters = {
	regionalAccreditors: state => state.regionalAccreditors.sort((a, b) => a.name.localeCompare(b.name)),
	raLoadedOnce: state => state.loadedOnce,
	allRA: state => state.regionalAccreditors,
	raSelectOptions: state => {
		return state.regionalAccreditors.map((ra) => {
			return { text: ra.slug, value: ra.uuid }
		})
	},
	regionalAccreditorFilter: state => uuid => state.regionalAccreditors.find(ra => uuid == ra.uuid)
}

const actions = {
	async getRegionalAccreditors({ commit }) {
		commit("GET_REGIONAL_ACCREDITORS_REQUEST")
		const response = await regionalAccreditorsService.getRegionalAccreditors();
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_REGIONAL_ACCREDITORS_SUCCESS", data)
				break
			default:
				commit("GET_REGIONAL_ACCREDITORS_ERROR", data)
		}
		return response
	},
	async getRegionalAccreditor({ commit }, params) {
		commit("GET_REGIONAL_ACCREDITOR_REQUEST")
		const response = await regionalAccreditorsService.getRegionalAccreditor(params)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("GET_REGIONAL_ACCREDITOR_SUCCESS", data)
				break
			default:
				commit("GET_REGIONAL_ACCREDITOR_ERROR", data)
				break
		}
		return response
	},
	async createRegionalAccreditor({ commit, dispatch }, params) {
		commit("CREATE_REGIONAL_ACCREDITOR_REQUEST")
		const response = await regionalAccreditorsService.createRegionalAccreditor(params)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("CREATE_REGIONAL_ACCREDITOR_SUCCESS", data) : commit("CREATE_REGIONAL_ACCREDITOR_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Regional accreditor created", { root: true }) : dispatch('alert/createAlertErrors', "Create Error", { root: true })
				break
			default:
				commit("CREATE_REGIONAL_ACCREDITOR_ERROR", data)
		}
		return response
	},
	async editRegionalAccreditor({ commit, dispatch }, params) {
		commit("EDIT_REGIONAL_ACCREDITOR_REQUEST")
		const { uuid } = params
		const response = await regionalAccreditorsService.editRegionalAccreditor(params, uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				data.success ? commit("EDIT_REGIONAL_ACCREDITOR_SUCCESS", data) : commit("EDIT_REGIONAL_ACCREDITOR_ERROR", data)
				data.success ? dispatch('alert/createAlertSuccess', "Regional accreditor updated", { root: true }) : dispatch('alert/createAlertErrors', "Edit Error", { root: true })
				break
			default:
				commit("EDIT_REGIONAL_ACCREDITOR_ERROR", data)
		}
		return response
	},
	async deleteRegionalAccreditor({ commit }, uuid) {
		commit("DELETE_REGIONAL_ACCREDITOR_REQUEST")
		const response = await regionalAccreditorsService.deleteRegionalAccreditor(uuid)
		const { status, data } = response
		switch (status) {
			case 200:
				commit("DELETE_REGIONAL_ACCREDITOR_SUCCESS", uuid)
				break
			default:
				commit("DELETE_REGIONAL_ACCREDITOR_DELETE", data)
		}
		return response
	}
}

const mutations = {
	[GET_REGIONAL_ACCREDITORS_REQUEST]: state => {
		state.loading = true
	},
	[GET_REGIONAL_ACCREDITORS_SUCCESS]: (state, data) => {
		let regional_accreditors = [...data.regional_accreditors]
		state.regionalAccreditors = regional_accreditors
		state.loading = false
		state.loadedOnce = true
	},
	[GET_REGIONAL_ACCREDITORS_ERROR]: state => {
		state.loading = false
		state.error = true
	},
	[GET_REGIONAL_ACCREDITOR_REQUEST]: state => {
		state.loading = true
	},
	[GET_REGIONAL_ACCREDITOR_SUCCESS]: (state, data) => {
		let mdata = { ...data.data }
		state.regionalAccreditor = mdata
		state.loading = false
	},
	[GET_REGIONAL_ACCREDITOR_ERROR]: (state, data) => {
		state.loading = false
	},

	[CREATE_REGIONAL_ACCREDITOR_REQUEST]: state => {
		state.loading = true
	},
	[CREATE_REGIONAL_ACCREDITOR_SUCCESS]: (state, data) => {
		let { regional_accreditor } = data
		state.regionalAccreditors.push(regional_accreditor)
		// state.regionalAccreditors = state.regionalAccreditors.push(regional_accreditor)
		state.loading = false
	},
	[CREATE_REGIONAL_ACCREDITOR_ERROR]: state => {
		state.loading = false
	},

	[EDIT_REGIONAL_ACCREDITOR_REQUEST]: state => {
		state.loading = true
	},
	[EDIT_REGIONAL_ACCREDITOR_SUCCESS]: (state, data) => {
		const { regional_accreditor } = data
		state.regionalAccreditors = state.regionalAccreditors.map(ra => ra.uuid == regional_accreditor.uuid ? regional_accreditor : ra)
		state.loading = false
	},
	[EDIT_REGIONAL_ACCREDITOR_ERROR]: state => {
		state.loading = false
	},

	[DELETE_REGIONAL_ACCREDITOR_REQUEST]: state => {
		state.loading = true
	},
	[DELETE_REGIONAL_ACCREDITOR_SUCCESS]: (state, uuid) => {

		state.regionalAccreditors = state.regionalAccreditors.filter(ra => {
			return ra.uuid != uuid
		})
		state.loading = false
	},
	[DELETE_REGIONAL_ACCREDITOR_ERROR]: (state, data) => {
		state.loading = false
	}

}

export const regionalAccreditor = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}