export const GET_INVOICE_ADJUSTMENTS_REQUEST = "GET_INVOICE_ADJUSTMENTS_REQUEST"
export const GET_INVOICE_ADJUSTMENTS_SUCCESS = "GET_INVOICE_ADJUSTMENTS_SUCCESS"
export const GET_INVOICE_ADJUSTMENTS_ERROR = "GET_INVOICE_ADJUSTMENTS_ERROR"
export const GET_INVOICE_ADJUSTMENT_REQUEST = "GET_INVOICE_ADJUSTMENT_REQUEST"
export const GET_INVOICE_ADJUSTMENT_SUCCESS = "GET_INVOICE_ADJUSTMENT_SUCCESS"
export const GET_INVOICE_ADJUSTMENT_ERROR = "GET_INVOICE_ADJUSTMENT_ERROR"
export const CREATE_INVOICE_ADJUSTMENT_REQUEST = "CREATE_INVOICE_ADJUSTMENT_REQUEST"
export const CREATE_INVOICE_ADJUSTMENT_SUCCESS = "CREATE_INVOICE_ADJUSTMENT_SUCCESS"
export const CREATE_INVOICE_ADJUSTMENT_ERROR = "CREATE_INVOICE_ADJUSTMENT_ERROR"
export const BROADCAST_CREATE_INVOICE_ADJUSTMENT_SUCCESS = "BROADCAST_CREATE_INVOICE_ADJUSTMENT_SUCCESS"
export const EDIT_INVOICE_ADJUSTMENT_REQUEST = "EDIT_INVOICE_ADJUSTMENT_REQUEST"
export const EDIT_INVOICE_ADJUSTMENT_SUCCESS = "EDIT_INVOICE_ADJUSTMENT_SUCCESS"
export const EDIT_INVOICE_ADJUSTMENT_ERROR = "EDIT_INVOICE_ADJUSTMENT_ERROR"
export const BROADCAST_EDIT_INVOICE_ADJUSTMENT_SUCCESS = "BROADCAST_EDIT_INVOICE_ADJUSTMENT_SUCCESS"
export const DELETE_INVOICE_ADJUSTMENT_REQUEST = "DELETE_INVOICE_ADJUSTMENT_REQUEST"
export const DELETE_INVOICE_ADJUSTMENT_SUCCESS = "DELETE_INVOICE_ADJUSTMENT_SUCCESS"
export const DELETE_INVOICE_ADJUSTMENT_ERROR = "DELETE_INVOICE_ADJUSTMENT_ERROR"
export const BROADCAST_DELETE_INVOICE_ADJUSTMENT_SUCCESS = "BROADCAST_DELETE_INVOICE_ADJUSTMENT_SUCCESS"