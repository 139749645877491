export const invoiceRoutes = {
  path: 'invoices',
  component: () => import("@/pages/accounting/InvoiceContainer.vue"),
  children: [
    {
      path: '',
      name: 'invoice index',
      meta: {
        label: 'Invoices'
      },
      component: () => import("@/pages/accounting/InvoiceIndex.vue")
    },
    {
      path: ':uuid',
      name: 'invoice detail',
      meta: {
        label: "Invoice Details"
      },
      component: () => import("@/pages/accounting/InvoiceDetail.vue")
    },
  ]
}