export const GET_STATUS_EXPLANATIONS_REQUEST = "GET_STATUS_EXPLANATIONS_REQUEST"
export const GET_STATUS_EXPLANATIONS_SUCCESS = "GET_STATUS_EXPLANATIONS_SUCCESS"
export const GET_STATUS_EXPLANATIONS_ERROR = "GET_STATUS_EXPLANATIONS_ERROR"
export const GET_STATUS_EXPLANATION_REQUEST = "GET_STATUS_EXPLANATION_REQUEST"
export const GET_STATUS_EXPLANATION_SUCCESS = "GET_STATUS_EXPLANATION_SUCCESS"
export const GET_STATUS_EXPLANATION_ERROR = "GET_STATUS_EXPLANATION_ERROR"
export const CREATE_STATUS_EXPLANATION_REQUEST = "CREATE_STATUS_EXPLANATION_REQUEST"
export const CREATE_STATUS_EXPLANATION_SUCCESS = "CREATE_STATUS_EXPLANATION_SUCCESS"
export const CREATE_STATUS_EXPLANATION_ERROR = "CREATE_STATUS_EXPLANATION_ERROR"
export const EDIT_STATUS_EXPLANATION_REQUEST = "EDIT_STATUS_EXPLANATION_REQUEST"
export const EDIT_STATUS_EXPLANATION_SUCCESS = "EDIT_STATUS_EXPLANATION_SUCCESS"
export const EDIT_STATUS_EXPLANATION_ERROR = "EDIT_STATUS_EXPLANATION_ERROR"
export const DELETE_STATUS_EXPLANATION_REQUEST = "DELETE_STATUS_EXPLANATION_REQUEST"
export const DELETE_STATUS_EXPLANATION_SUCCESS = "DELETE_STATUS_EXPLANATION_SUCCESS"
export const DELETE_STATUS_EXPLANATION_ERROR = "DELETE_STATUS_EXPLANATION_ERROR"