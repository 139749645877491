import {
  GET_UNIVERSITY_PROGRAMS_REQUEST,
  GET_UNIVERSITY_PROGRAMS_SUCCESS,
  GET_UNIVERSITY_PROGRAMS_ERROR,
  GET_UNIVERSITY_PROGRAM_REQUEST,
  GET_UNIVERSITY_PROGRAM_SUCCESS,
  GET_UNIVERSITY_PROGRAM_ERROR,
  CREATE_UNIVERSITY_PROGRAM_REQUEST,
  CREATE_UNIVERSITY_PROGRAM_SUCCESS,
  CREATE_UNIVERSITY_PROGRAM_ERROR,
  EDIT_UNIVERSITY_PROGRAM_REQUEST,
  EDIT_UNIVERSITY_PROGRAM_SUCCESS,
  EDIT_UNIVERSITY_PROGRAM_ERROR,
  DELETE_UNIVERSITY_PROGRAM_REQUEST,
  DELETE_UNIVERSITY_PROGRAM_SUCCESS,
  DELETE_UNIVERSITY_PROGRAM_ERROR,
  CLEAR_SECTION_UNIVERSITY_PROGRAM_REQUEST,
  CLEAR_SECTION_UNIVERSITY_PROGRAM_SUCCESS,
  CLEAR_SECTION_UNIVERSITY_PROGRAM_ERROR,
} from "@/actions"

import {
  universityProgramService
} from "@/services"

import { unpackWAS, unpackCourse, unpackCoach, unpackUniversityProgramPoc } from "@/modules"

import { addNewFilter, isEmpty } from "@/utilities"

const state = {
  universityPrograms: [],
  universityProgram: {},
  loading: false,
  loadedOnce: false,
  loadedPrograms: []
}

export function unpackProgramData(program, commit) {
  Object.keys(program).forEach(key => {
    let data = {}
    let value = program[key]

    if (!isEmpty(value)) data.success = true

    switch (key) {
      case "comments":
        if (!isEmpty(program.comments)) {
          commit('comments/GET_COMMENTS_SUCCESS', { comments: value }, { root: true })
        }
        delete program.comments

        break
      case "courses":
        if (!isEmpty(program.courses)) {
          data.courses = program.courses.map(c => unpackCourse(c, commit))
          commit('course/GET_COURSES_SUCCESS', data, { root: true })
        }
        delete program[key]
        break
      case "past_working_assignment_sheets":
      case "current_working_assignment_sheets":
      case "future_working_assignment_sheets":
      case "working_assignment_sheets":
        data.working_assignment_sheets = !isEmpty(program[key]) ? program[key].map(was => unpackWAS(was, commit)) : []
        commit('workingAssignmentSheet/GET_WORKING_ASSIGNMENT_SHEETS_SUCCESS', data, { root: true })
        delete program[key]
        break
      case "university_program_pocs":
        if (!isEmpty(value)) {
          const university_program_pocs = value.map(i => unpackUniversityProgramPoc(i, commit))
          commit('universityProgramPoc/GET_UNIVERSITY_PROGRAM_POCS_SUCCESS', { university_program_pocs }, { root: true })
        }
        delete program[key]
        break
    }

  })

  return program;
}

function mapArray(state, getters, rootState, rootGetters, up, onlyLabels, fullData) {

  let userByUuid = rootGetters['users/userByUuid'];
  let universityFind = rootGetters['university/universityFilter'];
  let lead_coach = {}
  if (!isEmpty(up.lead_coach_uuid)) {
    lead_coach = userByUuid(up.lead_coach_uuid)
  }
  let program_assistant = {}
  if (!isEmpty(up.program_assistant_uuid)) {
    program_assistant = userByUuid(up.program_assistant_uuid)
  }
  let university = universityFind(up.university_uuid)
  if (onlyLabels === true) {
    if (!isEmpty(university)) {
      university = {
        display_name: isEmpty(university.display_name) ? university.display_name : university.name,
      }
    }
    if (!isEmpty(lead_coach)) {
      lead_coach = {
        name: lead_coach.name,
      }
    }
    if (!isEmpty(program_assistant)) {
      program_assistant = {
        name: program_assistant.name,
      }
    }
  }

  let courses = {}, course_starts = {}, wass = {}

  if (fullData === true) {
    let courseFilter = rootGetters['course/courseFilter']
    courses = courseFilter(up.uuid)

    let wasUnivProg = rootGetters['workingAssignmentSheet/wasUnivProg']
    wass = wasUnivProg(up.uuid)

    let csBycUuidArrayFilter = rootGetters['courseStart/csBycUuidArrayFilter']
    course_starts = csBycUuidArrayFilter(courses.map(i => i.uuid))

    wass = wass.map(w => {
      let course_starts_was = course_starts.filter(cs => cs.start_date == w.start_date && cs.course_length == w.course_length)
      return {
        ...w,
        course_starts: course_starts_was
      }
    })
  }
  const newUp = {
    ...up,
    university,
    program_assistant,
    lead_coach,
    courses,
    wass
  }
  return newUp
}

const getters = {
  upLoadedOnce: state => state.loadedOnce,

  upLoading: state => state.loading,

  universityProgram: state => state.universityProgram,

  universityPrograms: state => univ => state.universityPrograms.filter(up => {
    return up.university_uuid == univ.uuid;
  }),

  allUniversityPrograms: state => state.universityPrograms,

  leadCoachPrograms: state => leadCoachUuid => state.universityPrograms.filter(up => up.lead_coach_uuid == leadCoachUuid),

  myPrograms: (state, getters, rootState, rootGetters) => {
    if (rootGetters["auth/canAccess"]('lead-coach')) {
      let leadCoachUuid = rootGetters["auth/authorizedUserUuid"]
      return state.universityPrograms.filter(up => {
        return up.lead_coach_uuid == leadCoachUuid
      })
    } else {
      return []
    }
  },

  universityProgramFilter: state => (uniUuid, progUuid, pclUuid) => state.universityPrograms.find(univProg => {
    return univProg.university_uuid == uniUuid && univProg.program_uuid == progUuid && univProg?.program_course_level_uuid == pclUuid
  }),

  uniProByUniUuid: state => uniUuid => state.universityPrograms.filter(up => up.university_uuid == uniUuid),

  uniProUuidFilter: state => uuid => state.universityPrograms.find(i => i.uuid == uuid),

  uniProUuidArrFilter: state => uuids => state.universityPrograms.filter(i => uuids?.includes(i.uuid)),

  univeristyProgramsByLeadCoach: (state, getters, rootState, rootGetters) => (uniUuid) => state.universityPrograms.filter(univProg => {
    if (isEmpty(uniUuid)) uniUuid = null
    let canAccessGetter = rootGetters["auth/canAccess"]
    let isLeadCoach = canAccessGetter('lead-coach')
    if (isLeadCoach) {
      let leadCoachUuid = rootGetters["auth/authorizedUserUuid"]
      if (isEmpty(uniUuid)) {
        return univProg.lead_coach_uuid == leadCoachUuid
      }
      return univProg.university_uuid == uniUuid && univProg.lead_coach_uuid == leadCoachUuid
    }
    return []
  }),

  univeristyHasLeadCoach: (state, getters, rootState, rootGetters) => (uniUuid) => state.universityPrograms.some(univProg => {
    let canAccessGetter = rootGetters["auth/canAccess"]
    let isLeadCoach = canAccessGetter('lead-coach')
    if (isLeadCoach) {
      let leadCoachUuid = rootGetters["auth/authorizedUserUuid"]
      return univProg.university_uuid == uniUuid && univProg.lead_coach_uuid == leadCoachUuid
    }
    return true
  }),

  upWithChildDataByUniversityMapped: (state, getters, rootState, rootGetters) => uniUuid => {
    return state.universityPrograms.filter(up => up.university_uuid == uniUuid).map(i => mapArray(state, getters, rootState, rootGetters, i, true, true))
  },

  upWithChildDataMapped: (state, getters, rootState, rootGetters) => (isSingle, uuid, onlyLabels, fullData) => {
    let up = {}
    if (typeof isSingle == typeof undefined) isSingle = true
    if (typeof uuid == typeof undefined) uuid = null
    if (typeof onlyLabels == typeof undefined) onlyLabels = true
    if (typeof fullData == typeof undefined) fullData = false
    if (isSingle) {
      if (isEmpty(state.universityProgram)) return {}
      up = state.universityProgram
    } else {
      if (isEmpty(uuid)) return {}
      up = state.universityPrograms.find(i => i.uuid == uuid)
    }
    return mapArray(state, getters, rootState, rootGetters, up, onlyLabels, fullData)
  },
}

const actions = {
  async getUniversityPrograms({ commit }, params) {
    commit("GET_UNIVERSITY_PROGRAMS_REQUEST")
    const response = await universityProgramService.getUniversityPrograms(params);
    const { status, data } = response
    switch (status) {
      case 200:
        if (data.success) {
          data.university_programs = data.university_programs.map(p => {
            return unpackProgramData(p, commit)
          })
          commit("GET_UNIVERSITY_PROGRAMS_SUCCESS", data)
        }
        break
      default:
        commit("GET_UNIVERSITY_PROGRAMS_ERROR", data)
    }
    return response
  },
  async getUniversityProgram({ state, commit }, params) {
    let up = state.universityPrograms.find(up => up.uuid == params.uuid)
    if (isEmpty(up) && !state.loading) {
      commit("GET_UNIVERSITY_PROGRAM_REQUEST")
      const response = await universityProgramService.getUniversityProgram(params)
      const { status, data } = response
      switch (status) {
        case 200:
          data.university_program = unpackProgramData(data.university_program, commit)
          commit("GET_UNIVERSITY_PROGRAM_SUCCESS", data)
          break
        default:
          commit("GET_UNIVERSITY_PROGRAM_ERROR", data)
          break
      }
      return response
    }
    commit("GET_UNIVERSITY_PROGRAM_SUCCESS", {university_program: up})
    return true;
    // if (!isEmpty(up)) {
    // 	commit("GET_UNIVERSITY_PROGRAM_SUCCESS", { university_program: up })
    // }

    // return up
  },
  async createUniversityProgram({ commit, dispatch }, params) {
    commit("CREATE_UNIVERSITY_PROGRAM_REQUEST")
    const response = await universityProgramService.createUniversityProgram(params)
    const { status, data } = response
    switch (status) {
      case 200:
        if (data?.success) {
          let university_program = unpackProgramData(data.university_program, commit)
          commit("CREATE_UNIVERSITY_PROGRAM_SUCCESS", { university_program });
          dispatch('alert/createAlertSuccess', "Program created.", { root: true })
        } else {
          commit("CREATE_UNIVERSITY_PROGRAM_ERROR", data);
          dispatch('alert/createAlertErrors', data.errors, { root: true });
        }
        
        break
      default:
        commit("CREATE_UNIVERSITY_PROGRAM_ERROR", data)
    }
    return response
  },
  async editUniversityProgram({ commit, dispatch }, params) {
    commit("EDIT_UNIVERSITY_PROGRAM_REQUEST")
    const response = await universityProgramService.editUniversityProgram(params, params.uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        if (isEmpty(data?.errors?.lead_tranfer_error)) {
          data.success ? commit("EDIT_UNIVERSITY_PROGRAM_SUCCESS", data) : commit("EDIT_UNIVERSITY_PROGRAM_ERROR", data)
          data.success ? dispatch('alert/createAlertSuccess', "Program updated.", { root: true }) : dispatch('alert/createAlertErrors', data.errors, { root: true })
        }
        break
      default:
        commit("EDIT_UNIVERSITY_PROGRAM_ERROR", data)
    }
    return response
  },
  async deleteUniversityProgram({ commit }, params) {
    const { uuid } = params;
    commit("DELETE_UNIVERSITY_PROGRAM_REQUEST")
    const response = await universityProgramService.deleteUniversityProgram(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_UNIVERSITY_PROGRAM_SUCCESS", { uuid })  : null
        break
      default:
        commit("DELETE_UNIVERSITY_PROGRAM_DELETE", data)
    }
    return response
  },
  async editUniversityPrograms({ commit }, params) {
    commit("EDIT_UNIVERSITY_PROGRAM_REQUEST")
    const response = await universityProgramService.editUniversityPrograms(params)
    const { status, data } = response
    switch (status) {
      case 200:
        if (data.success) {
          data.university_programs = data.university_programs.map(p => {
            return unpackProgramData(p, commit)
          })
          commit("GET_UNIVERSITY_PROGRAMS_SUCCESS", data)
        }
        break
      default:
        commit("EDIT_UNIVERSITY_PROGRAM_ERROR", data)
    }
    return response
  },
  async clearUniversityProgram({ commit }) {
    commit("CLEAR_UNIVERSITY_PROGRAM")
  },
}

const mutations = {
  [GET_UNIVERSITY_PROGRAMS_REQUEST]: state => {
    state.loading = true
  },
  [GET_UNIVERSITY_PROGRAMS_SUCCESS]: (state, data) => {
    let universityPrograms = data.university_programs
    state.universityPrograms = addNewFilter(state.universityPrograms, universityPrograms)
    state.loading = false
  },
  [GET_UNIVERSITY_PROGRAMS_ERROR]: state => {
    state.loading = false
    state.error = true
  },
  [GET_UNIVERSITY_PROGRAM_REQUEST]: state => {
    state.loading = true
  },
  [GET_UNIVERSITY_PROGRAM_SUCCESS]: (state, data) => {
    let { university_program } = data
    state.universityProgram = university_program
    state.universityPrograms = addNewFilter(state.universityPrograms, [university_program])
    state.loading = false
  },
  [GET_UNIVERSITY_PROGRAM_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_UNIVERSITY_PROGRAM_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_UNIVERSITY_PROGRAM_SUCCESS]: (state, data) => {
    state.loading = false
    let university_program = data.university_program
    state.universityPrograms = addNewFilter(state.universityPrograms, [university_program])
  },
  [CREATE_UNIVERSITY_PROGRAM_ERROR]: state => {
    state.loading = false
  },

  [EDIT_UNIVERSITY_PROGRAM_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_UNIVERSITY_PROGRAM_SUCCESS]: (state, data) => {
    let { university_program } = data
    state.universityPrograms = state.universityPrograms.map(up => up.uuid == university_program.uuid ? university_program : up)
    if (!isEmpty(state.universityProgram)) state.universityProgram = university_program
    state.loading = false
  },
  [EDIT_UNIVERSITY_PROGRAM_ERROR]: state => {
    state.loading = false
  },

  [DELETE_UNIVERSITY_PROGRAM_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_UNIVERSITY_PROGRAM_SUCCESS]: (state, uuid) => {
    state.universityPrograms = state.universityPrograms.filter(f => f.uuid != uuid)
    state.loading = false
  },
  [DELETE_UNIVERSITY_PROGRAM_ERROR]: (state, data) => {
    state.loading = false
  },

  [CLEAR_SECTION_UNIVERSITY_PROGRAM_REQUEST]: state => {
    state.loading = true
  },
  [CLEAR_SECTION_UNIVERSITY_PROGRAM_SUCCESS]: (state, { data, params }) => {
    let universityPrograms = [...data.university_programs]
    state.universityPrograms = state.universityPrograms.filter(i => i.university_uuid != params.universitiesUuid)
    state.universityPrograms = addNewFilter(state.universityPrograms, universityPrograms)
    state.loading = false
  },
  [CLEAR_SECTION_UNIVERSITY_PROGRAM_ERROR]: state => {
    state.loading = false
  },

  CLEAR_UNIVERSITY_PROGRAM: (state) => {
    state.universityProgram = {}
  },

}

export const universityProgram = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
