export const GET_FACULTY_SOURCES_REQUEST = "GET_FACULTY_SOURCES_REQUEST"
export const GET_FACULTY_SOURCES_SUCCESS = "GET_FACULTY_SOURCES_SUCCESS"
export const GET_FACULTY_SOURCES_ERROR = "GET_FACULTY_SOURCES_ERROR"
export const GET_FACULTY_SOURCE_REQUEST = "GET_FACULTY_SOURCE_REQUEST"
export const GET_FACULTY_SOURCE_SUCCESS = "GET_FACULTY_SOURCE_SUCCESS"
export const GET_FACULTY_SOURCE_ERROR = "GET_FACULTY_SOURCE_ERROR" 
export const CREATE_FACULTY_SOURCE_REQUEST = "CREATE_FACULTY_SOURCE_REQUEST"
export const CREATE_FACULTY_SOURCE_SUCCESS = "CREATE_FACULTY_SOURCE_SUCCESS"
export const CREATE_FACULTY_SOURCE_ERROR = "CREATE_FACULTY_SOURCE_ERROR"
export const EDIT_FACULTY_SOURCE_REQUEST = "EDIT_FACULTY_SOURCE_REQUEST"
export const EDIT_FACULTY_SOURCE_SUCCESS = "EDIT_FACULTY_SOURCE_SUCCESS"
export const EDIT_FACULTY_SOURCE_ERROR = "EDIT_FACULTY_SOURCE_ERROR"
export const DELETE_FACULTY_SOURCE_REQUEST = "DELETE_FACULTY_SOURCE_REQUEST"
export const DELETE_FACULTY_SOURCE_SUCCESS = "DELETE_FACULTY_SOURCE_SUCCESS"
export const BROADCAST_DELETE_FACULTY_SOURCE_SUCCESS = "BROADCAST_DELETE_FACULTY_SOURCE_SUCCESS"
export const DELETE_FACULTY_SOURCE_ERROR = "DELETE_FACULTY_SOURCE_ERROR"
export const SEND_RESUME_FACULTY_SOURCE_REQUEST = "SEND_RESUME_FACULTY_SOURCE_REQUEST"
export const SEND_RESUME_FACULTY_SOURCE_SUCCESS = "SEND_RESUME_FACULTY_SOURCE_SUCCESS"
export const SEND_RESUME_FACULTY_SOURCE_ERROR = "SEND_RESUME_FACULTY_SOURCE_ERROR"