export const GET_OFFERS_REQUEST = "GET_OFFERS_REQUEST"
export const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS"
export const GET_OFFERS_ERROR = "GET_OFFERS_ERROR"
export const GET_OFFER_REQUEST = "GET_OFFER_REQUEST"
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS"
export const GET_OFFER_ERROR = "GET_OFFER_ERROR"
export const CREATE_OFFER_REQUEST = "CREATE_OFFER_REQUEST"
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS"
export const CREATE_OFFER_ERROR = "CREATE_OFFER_ERROR"
export const EDIT_OFFER_REQUEST = "EDIT_OFFER_REQUEST"
export const EDIT_OFFER_SUCCESS = "EDIT_OFFER_SUCCESS"
export const EDIT_OFFER_ERROR = "EDIT_OFFER_ERROR"
export const DELETE_OFFER_REQUEST = "DELETE_OFFER_REQUEST"
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS"
export const DELETE_OFFER_ERROR = "DELETE_OFFER_ERROR"