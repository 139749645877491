export const GET_COACH_NURSE_LICENSES_REQUEST = "GET_COACH_NURSE_LICENSES_REQUEST"
export const GET_COACH_NURSE_LICENSES_SUCCESS = "GET_COACH_NURSE_LICENSES_SUCCESS"
export const GET_COACH_NURSE_LICENSES_ERROR = "GET_COACH_NURSE_LICENSES_ERROR"
export const GET_COACH_NURSE_LICENSE_REQUEST = "GET_COACH_NURSE_LICENSE_REQUEST"
export const GET_COACH_NURSE_LICENSE_SUCCESS = "GET_COACH_NURSE_LICENSE_SUCCESS"
export const GET_COACH_NURSE_LICENSE_ERROR = "GET_COACH_NURSE_LICENSE_ERROR"
export const CREATE_COACH_NURSE_LICENSE_REQUEST = "CREATE_COACH_NURSE_LICENSE_REQUEST"
export const CREATE_COACH_NURSE_LICENSE_SUCCESS = "CREATE_COACH_NURSE_LICENSE_SUCCESS"
export const CREATE_COACH_NURSE_LICENSE_ERROR = "CREATE_COACH_NURSE_LICENSE_ERROR"
export const EDIT_COACH_NURSE_LICENSE_REQUEST = "EDIT_COACH_NURSE_LICENSE_REQUEST"
export const EDIT_COACH_NURSE_LICENSE_SUCCESS = "EDIT_COACH_NURSE_LICENSE_SUCCESS"
export const EDIT_COACH_NURSE_LICENSE_ERROR = "EDIT_COACH_NURSE_LICENSE_ERROR"
export const DELETE_COACH_NURSE_LICENSE_REQUEST = "DELETE_COACH_NURSE_LICENSE_REQUEST"
export const DELETE_COACH_NURSE_LICENSE_SUCCESS = "DELETE_COACH_NURSE_LICENSE_SUCCESS"
export const DELETE_COACH_NURSE_LICENSE_ERROR = "DELETE_COACH_ERROR"
export const CLEAR_COACH_NURSE_LICENSE_STATE = "CLEAR_COACH_NURSE_LICENSE_STATE"