export const GET_CONTRACT_ADDENDUMS_REQUEST = "GET_CONTRACT_ADDENDUMS_REQUEST"
export const GET_CONTRACT_ADDENDUMS_SUCCESS = "GET_CONTRACT_ADDENDUMS_SUCCESS"
export const GET_CONTRACT_ADDENDUMS_ERROR = "GET_CONTRACT_ADDENDUMS_ERROR"
export const GET_CONTRACT_ADDENDUM_REQUEST = "GET_CONTRACT_ADDENDUM_REQUEST"
export const GET_CONTRACT_ADDENDUM_SUCCESS = "GET_CONTRACT_ADDENDUM_SUCCESS"
export const GET_CONTRACT_ADDENDUM_ERROR = "GET_CONTRACT_ADDENDUM_ERROR" 
export const CREATE_CONTRACT_ADDENDUM_REQUEST = "CREATE_CONTRACT_ADDENDUM_REQUEST"
export const CREATE_CONTRACT_ADDENDUM_SUCCESS = "CREATE_CONTRACT_ADDENDUM_SUCCESS"
export const CREATE_CONTRACT_ADDENDUM_ERROR = "CREATE_CONTRACT_ADDENDUM_ERROR"
export const EDIT_CONTRACT_ADDENDUM_REQUEST = "EDIT_CONTRACT_ADDENDUM_REQUEST"
export const EDIT_CONTRACT_ADDENDUM_SUCCESS = "EDIT_CONTRACT_ADDENDUM_SUCCESS"
export const EDIT_CONTRACT_ADDENDUM_ERROR = "EDIT_CONTRACT_ADDENDUM_ERROR"
export const DELETE_CONTRACT_ADDENDUM_REQUEST = "DELETE_CONTRACT_ADDENDUM_REQUEST"
export const DELETE_CONTRACT_ADDENDUM_SUCCESS = "DELETE_CONTRACT_ADDENDUM_SUCCESS"
export const DELETE_CONTRACT_ADDENDUM_ERROR = "DELETE_CONTRACT_ADDENDUM_ERROR"