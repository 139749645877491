export const GET_EMAILS_REQUEST = "GET_EMAILS_REQUEST"
export const GET_EMAILS_SUCCESS = "GET_EMAILS_SUCCESS"
export const GET_EMAILS_ERROR = "GET_EMAILS_ERROR"
export const GET_EMAIL_REQUEST = "GET_EMAIL_REQUEST"
export const GET_EMAIL_SUCCESS = "GET_EMAIL_SUCCESS"
export const GET_EMAIL_ERROR = "GET_EMAIL_ERROR"
export const CREATE_EMAIL_REQUEST = "CREATE_EMAIL_REQUEST"
export const CREATE_EMAIL_SUCCESS = "CREATE_EMAIL_SUCCESS"
export const CREATE_EMAIL_ERROR = "CREATE_EMAIL_ERROR"
export const EDIT_EMAIL_REQUEST = "EDIT_EMAIL_REQUEST"
export const EDIT_EMAIL_SUCCESS = "EDIT_EMAIL_SUCCESS"
export const EDIT_EMAIL_ERROR = "EDIT_EMAIL_ERROR"
export const DELETE_EMAIL_REQUEST = "DELETE_EMAIL_REQUEST"
export const DELETE_EMAIL_SUCCESS = "DELETE_EMAIL_SUCCESS"
export const DELETE_EMAIL_ERROR = "DELETE_EMAIL_ERROR"
export const CLEAR_EMAIL_STATE = "CLEAR_EMAIL_STATE"
export const BULK_EDIT_EMAIL_REQUEST = "BULK_EDIT_EMAIL_REQUEST"
export const BULK_EDIT_EMAIL_SUCCESS = "BULK_EDIT_EMAIL_SUCCESS"
export const BULK_EDIT_EMAIL_ERROR = "BULK_EDIT_EMAIL_ERROR"