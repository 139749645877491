import {
    apiRequest,
    processInput
} from "@/utilities"

export const groupService = {
    async getGroups() {
        const getGroupsRequest = {
            method: "GET"
        }

        return await apiRequest("/api/groups", getGroupsRequest)
    },
    async createGroup(params) {
        let createData = processInput(params);
        const postGroupRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/groups", postGroupRequest)
    },
    async getGroup(uuid) {

        const getGroupRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/groups/${uuid}`, getGroupRequest);
    },
    async editGroup(params, uuid) {
        let editData = processInput(params)
        editData.append('_method', 'PATCH')
        const editGroupRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/groups/${uuid}`, editGroupRequest);
    },
    async deleteGroup(uuid) {
        const deleteGroupRequest = {
            method: "DELETE",
        }
        return await apiRequest(`/api/groups/${uuid}`, deleteGroupRequest);
    }
}