import {
    apiRequest,
    processInput
} from "@/utilities"

export const languageService = {
    async getLanguages() {
        const getLanguagesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/languages", getLanguagesRequest)
    },
    async getLanguage(uuid) {
        const getLanguageRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/languages/${uuid}`, getLanguageRequest)
    },
    async createLanguage(params) {
        const createData = processInput(params)
        const createLanguageRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/languages", createLanguageRequest)
    },
    async editLanguage(data, uuid) {
        let editData = processInput(data)
        data.append("_method", "PATCH")
        const editLanguageRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/languages/${uuid}`, editLanguageRequest)
    },
    async deleteLanguage(uuid) {
        const deleteLanguageRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/languages/${uuid}`, deleteLanguageRequest)
    }
}