import {
    GET_COACH_EDUCATION_EXPERIENCES_REQUEST,
    GET_COACH_EDUCATION_EXPERIENCES_SUCCESS,
    GET_COACH_EDUCATION_EXPERIENCES_ERROR,
    GET_COACH_EDUCATION_EXPERIENCE_REQUEST,
    GET_COACH_EDUCATION_EXPERIENCE_SUCCESS,
    GET_COACH_EDUCATION_EXPERIENCE_ERROR,
    CREATE_COACH_EDUCATION_EXPERIENCE_REQUEST,
    CREATE_COACH_EDUCATION_EXPERIENCE_SUCCESS,
    CREATE_COACH_EDUCATION_EXPERIENCE_ERROR,
    EDIT_COACH_EDUCATION_EXPERIENCE_REQUEST,
    EDIT_COACH_EDUCATION_EXPERIENCE_SUCCESS,
    EDIT_COACH_EDUCATION_EXPERIENCE_ERROR,
    DELETE_COACH_EDUCATION_EXPERIENCE_REQUEST,
    DELETE_COACH_EDUCATION_EXPERIENCE_SUCCESS,
    DELETE_COACH_EDUCATION_EXPERIENCE_ERROR,
    CLEAR_COACH_EDUCATION_EXPERIENCE_STATE
} from "@/actions"

import {
    coachEducationExperienceService
} from "@/services"

const state = {
    coachEducationExperiences: [],
    coachEducationExperience: {},
    loading: false,
    loadedOnce: false
}

const getters = {
    ceeLoadedOnce: state => state.loadedOnce,
    coachEducationExperiences: state => {
        return state.coachEducationExperiences
    }
}

const actions = {
    async getCoachEducationExperiences({ commit }, params) {
        commit("GET_COACH_EDUCATION_EXPERIENCES_REQUEST")
        const response = await coachEducationExperienceService.getCoachEducationExperiences(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_EDUCATION_EXPERIENCES_SUCCESS", data)
                break
            default:
                commit("GET_COACH_EDUCATION_EXPERIENCES_ERROR", data)
        }
        return response
    },
    async getCoachEducationExperience({ commit }, params) {
        commit("GET_COACH_EDUCATION_EXPERIENCE_REQUEST")
        const response = await coachEducationExperienceService.getCoachEducationExperience(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_EDUCATION_EXPERIENCE_SUCCESS", data)
                break
            default:
                commit("GET_COACH_EDUCATION_EXPERIENCE_ERROR", data)
                break
        }
        return response
    },
    async createCoachEducationExperience({ commit }, params) {
        commit("CREATE_COACH_EDUCATION_EXPERIENCE_REQUEST")
        const response = await coachEducationExperienceService.createCoachEducationExperience(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COACH_EDUCATION_EXPERIENCE_SUCCESS", data)
                break
            default:
                commit("CREATE_COACH_EDUCATION_EXPERIENCE_ERROR", data)
        }
        return response
    },
    async editCoachEducationExperience({ commit }, params) {
        commit("EDIT_COACH_EDUCATION_EXPERIENCE_REQUEST")
        const uuid = params.uuid
        const response = await coachEducationExperienceService.editCoachEducationExperience(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COACH_EDUCATION_EXPERIENCE_SUCCESS", data)
                break
            default:
                commit("EDIT_COACH_EDUCATION_EXPERIENCE_ERROR", data)
        }
        return response
    },
    async deleteCoachEducationExperience({ commit }, uuid) {
        commit("DELETE_COACH_EDUCATION_EXPERIENCE_REQUEST")
        const response = await coachEducationExperienceService.deleteCoachEducationExperience(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COACH_EDUCATION_EXPERIENCE_SUCCESS", uuid)
                break
            default:
                commit("DELETE_COACH_EDUCATION_EXPERIENCE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_EDUCATION_EXPERIENCES_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_EDUCATION_EXPERIENCES_SUCCESS]: (state, data) => {
        const coachEducationExperiences = [...data.coach_education_experiences ]
        state.coachEducationExperiences = coachEducationExperiences
        state.loading = false
        state.loadedOnce = true
    },
    [GET_COACH_EDUCATION_EXPERIENCES_ERROR]: state => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [GET_COACH_EDUCATION_EXPERIENCE_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_EDUCATION_EXPERIENCE_SUCCESS]: (state, data) => {
        const coachEducationExperience = { ...data.coach_education_experience }
        state.coachEducationExperience = coachEducationExperience
        state.loading = false
    },
    [GET_COACH_EDUCATION_EXPERIENCE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_EDUCATION_EXPERIENCE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_EDUCATION_EXPERIENCE_SUCCESS]: (state, data) => {
        const coachEducationExperience = { ...data.coach_education_experience }
        state.coachEducationExperiences.push(coachEducationExperience)
        state.loading = false
    },
    [CREATE_COACH_EDUCATION_EXPERIENCE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_EDUCATION_EXPERIENCE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_EDUCATION_EXPERIENCE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COACH_EDUCATION_EXPERIENCE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_EDUCATION_EXPERIENCE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_EDUCATION_EXPERIENCE_SUCCESS]: (state, uuid) => {
        state.coachEducationExperiences = state.coachEducationExperiences.filter(cee => cee.uuid !== uuid)
        state.loading = false
    },
    [DELETE_COACH_EDUCATION_EXPERIENCE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COACH_EDUCATION_EXPERIENCE_STATE]: (state) => {
        state.coachEducationExperiences = []
        state.coachEducationExperience = {}
        state.loading = false
        state.loadedOnce = false
    }
}

export const coachEducationExperience = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}