export const GET_UNI_FACULTY_SOURCES_REQUEST = "GET_UNI_FACULTY_SOURCES_REQUEST"
export const GET_UNI_FACULTY_SOURCES_SUCCESS = "GET_UNI_FACULTY_SOURCES_SUCCESS"
export const GET_UNI_FACULTY_SOURCES_ERROR = "GET_UNI_FACULTY_SOURCES_ERROR"
export const GET_UNI_FACULTY_SOURCE_REQUEST = "GET_UNI_FACULTY_SOURCE_REQUEST"
export const GET_UNI_FACULTY_SOURCE_SUCCESS = "GET_UNI_FACULTY_SOURCE_SUCCESS"
export const GET_UNI_FACULTY_SOURCE_ERROR = "GET_UNI_FACULTY_SOURCE_ERROR" 
export const CREATE_UNI_FACULTY_SOURCE_REQUEST = "CREATE_UNI_FACULTY_SOURCE_REQUEST"
export const CREATE_UNI_FACULTY_SOURCE_SUCCESS = "CREATE_UNI_FACULTY_SOURCE_SUCCESS"
export const CREATE_UNI_FACULTY_SOURCE_ERROR = "CREATE_UNI_FACULTY_SOURCE_ERROR"
export const EDIT_UNI_FACULTY_SOURCE_REQUEST = "EDIT_UNI_FACULTY_SOURCE_REQUEST"
export const EDIT_UNI_FACULTY_SOURCE_SUCCESS = "EDIT_UNI_FACULTY_SOURCE_SUCCESS"
export const EDIT_UNI_FACULTY_SOURCE_ERROR = "EDIT_UNI_FACULTY_SOURCE_ERROR"
export const DELETE_UNI_FACULTY_SOURCE_REQUEST = "DELETE_UNI_FACULTY_SOURCE_REQUEST"
export const DELETE_UNI_FACULTY_SOURCE_SUCCESS = "DELETE_UNI_FACULTY_SOURCE_SUCCESS"
export const DELETE_UNI_FACULTY_SOURCE_ERROR = "DELETE_UNI_FACULTY_SOURCE_ERROR"