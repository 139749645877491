import {
    GET_COACH_FACILITATING_COURSES_REQUEST,
    GET_COACH_FACILITATING_COURSES_SUCCESS,
    GET_COACH_FACILITATING_COURSES_ERROR,
    GET_COACH_FACILITATING_COURSE_REQUEST,
    GET_COACH_FACILITATING_COURSE_SUCCESS,
    GET_COACH_FACILITATING_COURSE_ERROR,
    CREATE_COACH_FACILITATING_COURSE_REQUEST,
    CREATE_COACH_FACILITATING_COURSE_SUCCESS,
    CREATE_COACH_FACILITATING_COURSE_ERROR,
    EDIT_COACH_FACILITATING_COURSE_REQUEST,
    EDIT_COACH_FACILITATING_COURSE_SUCCESS,
    EDIT_COACH_FACILITATING_COURSE_ERROR,
    DELETE_COACH_FACILITATING_COURSE_REQUEST,
    DELETE_COACH_FACILITATING_COURSE_SUCCESS,
    BROADCAST_DELETE_COACH_FACILITATING_COURSE_SUCCESS,
    DELETE_COACH_FACILITATING_COURSE_ERROR,
    CLEAR_COACH_FACILITATING_COURSE_STATE
} from "@/actions"

import {
    coachFacilitatingCourseService
} from "@/services"

const state = {
    coachFacilitatingCourses: [],
    coachFacilitatingCourse: {},
    loading: false,
    loadedOnce: false
}

import { addNewFilter, isEmpty } from "@/utilities"

const getters = {
    cfcLoadedOnce: state => state.loadedOnce,
    coachFacilitatingCourses: (state) => state.coachFacilitatingCourses,
    coachTFCByTFCUuid: (state, getters, rootState, rootGetters) => state.coachFacilitatingCourses.filter(cfc => {
        return cfc.coaches_uuid == rootGetters['coaches/coach']['uuid']
    }).map(cfcs => cfcs.facilitating_courses_uuid),
    findCFCByTFCUuid: (state, getters, rootState, rootGetters) => uuid => state.coachFacilitatingCourses.find(cfc => cfc.facilitating_courses_uuid == uuid && cfc.coaches_uuid == rootGetters["coaches/coach"]['uuid'])
}

const actions = {
    async getCoachFacilitatingCourses({ commit }, params) {
        commit("GET_COACH_FACILITATING_COURSES_REQUEST")
        const response = await coachFacilitatingCourseService.getCoachFacilitatingCourses(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_FACILITATING_COURSES_SUCCESS", data)
                break
            default:
                commit("GET_COACH_FACILITATING_COURSES_ERROR", data)
        }
        return response
    },
    async getCoachFacilitatingCourse({ commit }, params) {
        commit("GET_COACH_FACILITATING_COURSE_REQUEST")
        const response = await coachFacilitatingCourseService.getCoachFacilitatingCourse(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_FACILITATING_COURSE_SUCCESS", data)
                break
            default:
                commit("GET_COACH_FACILITATING_COURSE_ERROR", data)
                break
        }
        return response
    },
    async createCoachFacilitatingCourse({ commit }, params) {
        commit("CREATE_COACH_FACILITATING_COURSE_REQUEST")
        const response = await coachFacilitatingCourseService.createCoachFacilitatingCourse(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_COACH_FACILITATING_COURSE_SUCCESS", data)
                break
            default:
                commit("CREATE_COACH_FACILITATING_COURSE_ERROR", data)
        }
        return response
    },
    async editCoachFacilitatingCourse({ commit }, params) {
        commit("EDIT_COACH_FACILITATING_COURSE_REQUEST")
        const uuid = params.uuid
        const response = await coachFacilitatingCourseService.editCoachFacilitatingCourse(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_COACH_FACILITATING_COURSE_SUCCESS", data)
                break
            default:
                commit("EDIT_COACH_FACILITATING_COURSE_ERROR", data)
        }
        return response
    },
    async deleteCoachFacilitatingCourse({ commit }, uuid) {
        commit("DELETE_COACH_FACILITATING_COURSE_REQUEST")
        const response = await coachFacilitatingCourseService.deleteCoachFacilitatingCourse(uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COACH_FACILITATING_COURSE_SUCCESS", uuid)
                break
            default:
                commit("DELETE_COACH_FACILITATING_COURSE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_FACILITATING_COURSES_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_FACILITATING_COURSES_SUCCESS]: (state, data) => {
        const coachFacilitatingCourses = [...data.coach_facilitating_courses]
        state.coachFacilitatingCourses = addNewFilter(state.coachFacilitatingCourses, coachFacilitatingCourses)
        state.loading = false
        state.loadedOnce = true
    },
    [GET_COACH_FACILITATING_COURSES_ERROR]: state => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [GET_COACH_FACILITATING_COURSE_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_FACILITATING_COURSE_SUCCESS]: (state, data) => {
        const coachFacilitatingCourse = { ...data.coach_facilitating_course }
        state.coachFacilitatingCourse = coachFacilitatingCourse
        state.loading = false
    },
    [GET_COACH_FACILITATING_COURSE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_FACILITATING_COURSE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_FACILITATING_COURSE_SUCCESS]: (state, data) => {
        let coachFacilitatingCourse = { ...data.coach_facilitating_course }
        state.coachFacilitatingCourses.push(coachFacilitatingCourse)
        state.loading = false
    },
    [CREATE_COACH_FACILITATING_COURSE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_FACILITATING_COURSE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_FACILITATING_COURSE_SUCCESS]: state => {
        state.loading = false
    },
    [EDIT_COACH_FACILITATING_COURSE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_FACILITATING_COURSE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_FACILITATING_COURSE_SUCCESS]: (state, uuid) => {
        state.coachFacilitatingCourses = state.coachFacilitatingCourses.filter(cfc => cfc.uuid !== uuid)
        state.loading = false
    },
    [BROADCAST_DELETE_COACH_FACILITATING_COURSE_SUCCESS]: (state, data) => {
        const { coach_facilitating_course } = data
        const { uuid } = coach_facilitating_course
        if(!isEmpty(uuid)){
            state.coachFacilitatingCourses = state.coachFacilitatingCourses.filter(cfc => cfc.uuid !== uuid)
        }
        state.loading = false
    },
    [DELETE_COACH_FACILITATING_COURSE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CLEAR_COACH_FACILITATING_COURSE_STATE]: (state) => {
        // state.coachFacilitatingCourses = []
        // state.coachFacilitatingCourse = {}
        // state.loading = false
        // state.loadedOnce = false
    }
}

export const coachFacilitatingCourse = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}