import {
    GET_COACH_TEACHING_PROFILES_REQUEST,
    GET_COACH_TEACHING_PROFILES_SUCCESS,
    GET_COACH_TEACHING_PROFILES_ERROR,
    GET_COACH_TEACHING_PROFILE_REQUEST,
    GET_COACH_TEACHING_PROFILE_SUCCESS,
    GET_COACH_TEACHING_PROFILE_ERROR,
    CREATE_COACH_TEACHING_PROFILE_REQUEST,
    CREATE_COACH_TEACHING_PROFILE_SUCCESS,
    CREATE_COACH_TEACHING_PROFILE_ERROR,
    EDIT_COACH_TEACHING_PROFILE_REQUEST,
    EDIT_COACH_TEACHING_PROFILE_SUCCESS,
    EDIT_COACH_TEACHING_PROFILE_ERROR,
    DELETE_COACH_TEACHING_PROFILE_REQUEST,
    DELETE_COACH_TEACHING_PROFILE_SUCCESS,
    BROADCAST_DELETE_COACH_TEACHING_PROFILE_SUCCESS,
    DELETE_COACH_TEACHING_PROFILE_ERROR,
    CLEAR_COACH_TEACHING_PROFILE_STATE
} from "@/actions"

import {
    coachTeachingProfileService
} from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

const state = {
    coachTeachingProfiles: [],
    coachTeachingProfile: {},
    loading: false,
    loadedOnce: false
}

const getters = {
    ctpLoadedOnce: state => state.loadedOnce,
    coachTeachingProfile: (state, getters, rootState, rootGetters) => state.coachTeachingProfiles.find(ctp => ctp.coaches_uuid == rootGetters['coaches/coach']['uuid'])
}

const actions = {
    async getCoachTeachingProfiles({ commit }, params) {
        commit("GET_COACH_TEACHING_PROFILES_REQUEST")
        const response = await coachTeachingProfileService.getCoachTeachingProfiles(params);
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_COACH_TEACHING_PROFILES_SUCCESS", data)
                break
            default:
                commit("GET_COACH_TEACHING_PROFILES_ERROR", data)
        }
        return response
    },
    async getCoachTeachingProfile({ commit }, params) {
        commit("GET_COACH_TEACHING_PROFILE_REQUEST")
        const response = await coachTeachingProfileService.getCoachTeachingProfile(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("GET_COACH_TEACHING_PROFILE_SUCCESS", data) : commit("GET_COACH_TEACHING_PROFILE_ERROR", data)
                break
            default:
                commit("GET_COACH_TEACHING_PROFILE_ERROR", data)
                break
        }
        return response
    },
    async createCoachTeachingProfile({ commit }, params) {
        commit("CREATE_COACH_TEACHING_PROFILE_REQUEST")
        const response = await coachTeachingProfileService.createCoachTeachingProfile(params)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("CREATE_COACH_TEACHING_PROFILE_SUCCESS", data) : commit("CREATE_COACH_TEACHING_PROFILE_ERROR", data)
                break
            default:
                commit("CREATE_COACH_TEACHING_PROFILE_ERROR", data)
        }
        return response
    },
    async editCoachTeachingProfile({ commit }, params) {
        commit("EDIT_COACH_TEACHING_PROFILE_REQUEST")
        const uuid = params.uuid
        const response = await coachTeachingProfileService.editCoachTeachingProfile(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                data.success ? commit("EDIT_COACH_TEACHING_PROFILE_SUCCESS", data) : commit("EDIT_COACH_TEACHING_PROFILE_ERROR", data)
                break
            default:
                commit("EDIT_COACH_TEACHING_PROFILE_ERROR", data)
        }
        return response
    },
    async deleteCoachTeachingProfile({ commit }, params) {
        commit("DELETE_COACH_TEACHING_PROFILE_REQUEST")
        const response = await coachTeachingProfileService.deleteCoachTeachingProfile(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_COACH_TEACHING_PROFILE_SUCCESS", data)
                break
            default:
                commit("DELETE_COACH_TEACHING_PROFILE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_COACH_TEACHING_PROFILES_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_TEACHING_PROFILES_SUCCESS]: (state, data) => {
        let coachTeachingProfiles = [...data.coach_teaching_profiles]
        state.coachTeachingProfiles = addNewFilter(state.coachTeachingProfiles, coachTeachingProfiles)
        state.loading = false
        state.loadedOnce = true
    },
    [GET_COACH_TEACHING_PROFILES_ERROR]: state => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [GET_COACH_TEACHING_PROFILE_REQUEST]: state => {
        state.loading = true
    },
    [GET_COACH_TEACHING_PROFILE_SUCCESS]: (state, data) => {
        let coachTeachingProfile = { ...data.coach_teaching_profile }
        state.coachTeachingProfile = state.coachTeachingProfile.concat(coachTeachingProfile)
        state.loading = false
    },
    [GET_COACH_TEACHING_PROFILE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_COACH_TEACHING_PROFILE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_COACH_TEACHING_PROFILE_SUCCESS]: (state, data) => {
        state.coachTeachingProfiles.push({ ...data.coach_teaching_profile })
        state.loading = false
    },
    [CREATE_COACH_TEACHING_PROFILE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_COACH_TEACHING_PROFILE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_COACH_TEACHING_PROFILE_SUCCESS]: (state, data) => {
        let coachTeachingProfile = data.coach_teaching_profile
        state.coachTeachingProfiles = state.coachTeachingProfiles.map(ctp => ctp.uuid == coachTeachingProfile.uuid ? coachTeachingProfile : ctp)
        // state.coachTeachingProfiles = state.coachTeachingProfiles.concat(coachTeachingProfile)
        state.loading = false
    },
    [EDIT_COACH_TEACHING_PROFILE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_COACH_TEACHING_PROFILE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_COACH_TEACHING_PROFILE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_COACH_TEACHING_PROFILE_ERROR]: (state, data) => {
        state.loading = false
    },
    [BROADCAST_DELETE_COACH_TEACHING_PROFILE_SUCCESS]: (state, data) => {
        const { coach_teaching_profile } = data
        const { uuid } = coach_teaching_profile
        if(!isEmpty(uuid)){
            state.coachTeachingProfiles = state.coachTeachingProfiles.filter(ctp => ctp.uuid !== uuid)
        }
        state.loading = false
    },

    [CLEAR_COACH_TEACHING_PROFILE_STATE]: (state) => {
        state.coachTeachingProfiles = [],
        state.coachTeachingProfile = {},
        state.loading = false
        state.loadedOnce = false
    }
}

export const coachTeachingProfile = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}