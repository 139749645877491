import {
  apiRequest,
  processQueryParams
} from "@/utilities"

export const indexedDBService = {
  async getTableVersions(params) {
    const getTableVersionsRequest = {
      method: "GET",
      params: processQueryParams(params)
    }
    return await apiRequest("/api/client-database-sync/table-versions", getTableVersionsRequest);
  },
  async getTableData(params) {
    const getTableDataRequest = {
      method: "GET",
      params: processQueryParams(params)
    }
    return await apiRequest("/api/client-database-sync/table-data", getTableDataRequest);
  }
}
