export const GET_COACH_TEACHING_PROFILES_REQUEST = "GET_COACH_TEACHING_PROFILES_REQUEST"
export const GET_COACH_TEACHING_PROFILES_SUCCESS = "GET_COACH_TEACHING_PROFILES_SUCCESS"
export const GET_COACH_TEACHING_PROFILES_ERROR = "GET_COACH_TEACHING_PROFILES_ERROR"
export const GET_COACH_TEACHING_PROFILE_REQUEST = "GET_COACH_TEACHING_PROFILE_REQUEST"
export const GET_COACH_TEACHING_PROFILE_SUCCESS = "GET_COACH_TEACHING_PROFILE_SUCCESS"
export const GET_COACH_TEACHING_PROFILE_ERROR = "GET_COACH_TEACHING_PROFILE_ERROR"
export const CREATE_COACH_TEACHING_PROFILE_REQUEST = "CREATE_COACH_TEACHING_PROFILE_REQUEST"
export const CREATE_COACH_TEACHING_PROFILE_SUCCESS = "CREATE_COACH_TEACHING_PROFILE_SUCCESS"
export const CREATE_COACH_TEACHING_PROFILE_ERROR = "CREATE_COACH_TEACHING_PROFILE_ERROR"
export const EDIT_COACH_TEACHING_PROFILE_REQUEST = "EDIT_COACH_TEACHING_PROFILE_REQUEST"
export const EDIT_COACH_TEACHING_PROFILE_SUCCESS = "EDIT_COACH_TEACHING_PROFILE_SUCCESS"
export const EDIT_COACH_TEACHING_PROFILE_ERROR = "EDIT_COACH_TEACHING_PROFILE_ERROR"
export const DELETE_COACH_TEACHING_PROFILE_REQUEST = "DELETE_COACH_TEACHING_PROFILE_REQUEST"
export const DELETE_COACH_TEACHING_PROFILE_SUCCESS = "DELETE_COACH_TEACHING_PROFILE_SUCCESS"
export const BROADCAST_DELETE_COACH_TEACHING_PROFILE_SUCCESS = "BROADCAST_DELETE_COACH_TEACHING_PROFILE_SUCCESS"
export const DELETE_COACH_TEACHING_PROFILE_ERROR = "DELETE_COACH_TEACHING_PROFILE_ERROR"
export const CLEAR_COACH_TEACHING_PROFILE_STATE = "CLEAR_COACH_TEACHING_PROFILE_STATE"