export const GET_APP_MODELS_REQUEST = "GET_APP_MODELS_REQUEST"
export const GET_APP_MODELS_SUCCESS = "GET_APP_MODELS_SUCCESS"
export const GET_APP_MODELS_ERROR = "GET_APP_MODELS_ERROR"

export const GET_BASIC_APP_DATA_REQUEST = "GET_BASIC_APP_DATA_REQUEST"
export const GET_BASIC_APP_DATA_SUCCESS = "GET_BASIC_APP_DATA_SUCCESS"
export const GET_BASIC_APP_DATA_ERROR = "GET_BASIC_APP_DATA_ERROR"

export const GET_COACH_APP_DATA_REQUEST = "GET_COACH_APP_DATA_REQUEST"
export const GET_COACH_APP_DATA_SUCCESS = "GET_COACH_APP_DATA_SUCCESS"
export const GET_COACH_APP_DATA_ERROR = "GET_COACH_APP_DATA_ERROR"

export const GET_DASHBOARD_ITEMS_REQUEST = "GET_DASHBOARD_ITEMS_REQUEST"
export const GET_DASHBOARD_ITEMS_SUCCESS = "GET_DASHBOARD_ITEMS_SUCCESS"
export const GET_DASHBOARD_ITEMS_ERROR = "GET_DASHBOARD_ITEMS_ERROR"

export const GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_REQUEST = "GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_REQUEST"
export const GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_SUCCESS = "GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_SUCCESS"
export const GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_ERROR = "GET_BASIC_DASHBOARD_ITEMS_COACH_APP_DATA_ERROR"

export const GET_OPERATIONS_DATA_REQUEST = "GET_OPERATIONS_DATA_REQUEST"
export const GET_OPERATIONS_DATA_SUCCESS = "GET_OPERATIONS_DATA_SUCCESS"
export const GET_OPERATIONS_DATA_ERROR = "GET_OPERATIONS_DATA_ERROR"

export const GET_DASHBOARD_ITEM_REQUEST = "GET_DASHBOARD_ITEM_REQUEST"
export const GET_DASHBOARD_ITEM_SUCCESS = "GET_DASHBOARD_ITEM_SUCCESS"
export const GET_DASHBOARD_ITEM_ERROR = "GET_DASHBOARD_ITEM_ERROR"

export const INIT_PUSHER = "INIT_PUSHER"
export const INIT_CHANNELS = "INIT_CHANNELS"
export const INIT_PRIVATE_CHANNEL = "INIT_PRIVATE_CHANNEL"
export const INIT_ADMIN_EVENT_CHANNELS = "INIT_ADMIN_EVENT_CHANNELS"
export const INIT_COACH_PORTAL_EVENT_CHANNELS = "INIT_COACH_PORTAL_EVENT_CHANNELS"
export const INIT_PREFIX_CHANNELS = "INIT_PREFIX_CHANNELS"
