import {
    apiRequest,
    processInput
} from "@/utilities"

export const coachReferralService = {
    async getCoachReferrals() {
        const getCoachReferralsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/coach-referrals", getCoachReferralsRequest)
    },
    async getCoachReferral(uuid) {
        const getCoachReferralRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-referrals/${uuid}`, getCoachReferralRequest)
    },
    async createCoachReferral(params) {
        const createData = processInput(params)
        const createCoachReferralRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-referrals", createCoachReferralRequest)
    },
    async editCoachReferral(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachReferralRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-referrals/${uuid}`, editCoachReferralRequest)
    },
    async deleteCoachReferral(uuid) {
        const deleteCoachReferralRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-referrals/${uuid}`, deleteCoachReferralRequest)
    }
}