import {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  GET_INVOICE_REQUEST,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_ERROR,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  EDIT_INVOICE_REQUEST,
  EDIT_INVOICE_SUCCESS,
  EDIT_INVOICE_ERROR,
  DELETE_INVOICE_REQUEST,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_ERROR,
  GENERATE_INVOICE_REQUEST,
  GENERATE_INVOICE_SUCCESS,
  GENERATE_INVOICE_ERROR,
  CLEAN_INVOICE
} from "@/actions"

import {
  invoiceService
} from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

const state = {
  invoices: [],
  invoice: {},
  loading: false,
  loadedOnce: false,
}

const getters = {
  invoiceLoading: state => state.loading,
  invoicesLoadedOnce: state => state.loadedOnce,
  invoice: state => state.invoice,
  invoices: state => state.invoices,
  invoiceByUuid: state => uuid => state.invoices.find(i => i.uuid == uuid)
}

const actions = {
  async getInvoices({ commit }, params) {
    commit("GET_INVOICES_REQUEST")
    const response = await invoiceService.getInvoices(params);
    const { status, data } = response
    switch (status) {
      case 200:
        commit("GET_INVOICES_SUCCESS", data)
        break
      default:
        commit("GET_INVOICES_ERROR", data)
    }
    return response
  },
  async getInvoice({ commit, state }, params) {
    const { uuid } = params;

    const invoice = state.invoices.find(i => i.uuid == uuid);

    if (isEmpty(invoice)) {
      commit("GET_INVOICE_REQUEST")
      const response = await invoiceService.getInvoice(params)
      const { status, data } = response
      switch (status) {
        case 200:
          commit("GET_INVOICE_SUCCESS", data)
          break
        default:
          commit("GET_INVOICE_ERROR", data)
          break
      }
      return response;
    }

    commit("GET_INVOICE_SUCCESS", { invoice });

    return invoice;
  },
  async createInvoice({ commit, dispatch }, params) {
    commit("CREATE_INVOICE_REQUEST")
    const response = await invoiceService.createInvoice(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("CREATE_INVOICE_SUCCESS", data) : null
        data.success ? dispatch("alert/createAlertSuccess", "Invoice created.", { root: true }) : null
        break
      default:
        commit("CREATE_INVOICE_ERROR", data)
    }
    return response
  },
  async editInvoice({ commit }, params) {
    commit("EDIT_INVOICE_REQUEST")
    const response = await invoiceService.editInvoice(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("EDIT_INVOICE_SUCCESS", data) : null
        break
      default:
        commit("EDIT_INVOICE_ERROR", data)
    }
    return response
  },
  async deleteInvoice({ commit, dispatch }, uuid) {
    commit("DELETE_INVOICE_REQUEST")
    const response = await invoiceService.deleteInvoice(uuid)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("DELETE_INVOICE_SUCCESS", { uuid }) : null
        data.success ? dispatch("alert/createAlertSuccess", "Invoice deleted.", { root: true }) : null
        break
      default:
        commit("DELETE_INVOICE_ERROR", data)
    }
    return response
  },
  async generateInvoice({ commit, dispatch }, params) {
    commit("GENERATE_INVOICE_REQUEST")
    const response = await invoiceService.generateInvoice(params)
    const { status, data } = response
    switch (status) {
      case 200:
        data.success ? commit("GENERATE_INVOICE_SUCCESS") : null
        data.success ? dispatch("alert/createAlertSuccess", "Invoice(s) sent.", { root: true }) : null
        data.error ? commit("GENERATE_INVOICE_ERROR") : null
        data.error ? dispatch('alert/createAlertErrors', data.errors, { root: true }) : null
        break
      default:
        commit("GENERATE_INVOICE_ERROR", data)
    }
    return response
  },
  cleanStateInvoice({ commit }) {
    commit("CLEAN_INVOICE")
  }
}

const mutations = {
  [GET_INVOICES_REQUEST]: state => {
    state.loading = true
  },
  [GET_INVOICES_SUCCESS]: (state, data) => {
    const invoices = data.invoices
    state.invoices = addNewFilter(state.invoices, invoices);
    state.loading = false
    state.loadedOnce = true
  },
  [GET_INVOICES_ERROR]: state => {
    state.loading = false
    state.error = true
    state.loadedOnce = true
  },
  [GET_INVOICE_REQUEST]: state => {
    state.loading = true
  },
  [GET_INVOICE_SUCCESS]: (state, data) => {
    const { invoice } = data
    state.invoices = addNewFilter(state.invoices, [invoice]);
    state.loading = false
  },
  [GET_INVOICE_ERROR]: (state, data) => {
    state.loading = false
  },

  [CREATE_INVOICE_REQUEST]: state => {
    state.loading = true
  },
  [CREATE_INVOICE_SUCCESS]: (state, data) => {
    const { invoice } = data
    if(Array.isArray(invoice)){
      state.invoices = addNewFilter(state.invoices, invoice);
    }else{
      state.invoices = addNewFilter(state.invoices, [invoice]);
    }
    state.loading = false
  },

  [CREATE_INVOICE_ERROR]: state => {
    state.loading = false
  },

  [EDIT_INVOICE_REQUEST]: state => {
    state.loading = true
  },
  [EDIT_INVOICE_SUCCESS]: (state, data) => {
    const { invoice } = data;
    state.invoices = addNewFilter(state.invoices, [invoice]);
    state.loading = false;
  },
  [EDIT_INVOICE_ERROR]: state => {
    state.loading = false
  },

  [DELETE_INVOICE_REQUEST]: state => {
    state.loading = true
  },
  [DELETE_INVOICE_SUCCESS]: (state, data) => {
    const { uuid } = data;
    state.invoices = state.invoices.filter(i => i.uuid !== uuid)
    state.loading = false
  },
  [DELETE_INVOICE_ERROR]: (state, data) => {
    state.loading = false
  },


  [GENERATE_INVOICE_REQUEST]: state => {
    state.loading = true
  },
  [GENERATE_INVOICE_SUCCESS]: (state) => {
    state.loading = false
  },
  [GENERATE_INVOICE_ERROR]: state => {
    state.loading = false
  },
  [CLEAN_INVOICE]: state => {
    state.invoice = {}
  }
}

export const invoice = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}


export function unpackInvoice(data, commit){
  if(!isEmpty(data.payments)){
    commit('invoicePayment/GET_INVOICE_PAYMENTS_SUCCESS', {invoice_payments: data.payments}, {root: true})
    delete data.payments
  }
  return data
}