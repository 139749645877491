import {
    apiRequest,
    processInput
} from "@/utilities"

export const universityLMSsService = {
    async getUniversityLMSs() {
        const getUniversityLMSsRequest = {
            method: "GET"
        }
        return await apiRequest("/api/university-lms", getUniversityLMSsRequest)
    },
    async createUniversityLMS(params) {
        const createData = processInput(params);
        const postUniversityLMSRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/university-lms", postUniversityLMSRequest)
    },
    async getUniversityLMS(uuid) {

        const getUniversityLMSRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/university-lms/${uuid}`, getUniversityLMSRequest);
    },
    async editUniversityLMS(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateUniversityLMSRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/university-lms/${uuid}`, updateUniversityLMSRequest);
    },
    async deleteUniversityLMS(uuid) {

        const deleteUniversityLMSRequest = {
            method: "DELETE"
        }

        return await apiRequest(`/api/university-lms/${uuid}`, deleteUniversityLMSRequest);
    }
}