export const GET_TASKS_REQUEST = "GET_TASKS_REQUEST"
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS"
export const GET_TASKS_ERROR = "GET_TASKS_ERROR"
export const GET_TASK_REQUEST = "GET_TASK_REQUEST"
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS"
export const GET_TASK_ERROR = "GET_TASK_ERROR"
export const CREATE_TASK_REQUEST = "CREATE_TASK_REQUEST"
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS"
export const CREATE_TASK_ERROR = "CREATE_TASK_ERROR"
export const EDIT_TASK_REQUEST = "EDIT_TASK_REQUEST"
export const EDIT_TASK_SUCCESS = "EDIT_TASK_SUCCESS"
export const EDIT_TASK_ERROR = "EDIT_TASK_ERROR"
export const DELETE_TASK_REQUEST = "DELETE_TASK_REQUEST"
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS"
export const DELETE_TASK_ERROR = "DELETE_TASK_ERROR"