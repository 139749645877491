import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const phoneService = {
    async getPhones(params) {
        let getParams = processQueryParams(params)
        const getPhonesRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/phones", getPhonesRequest)
    },
    async getPhone(uuid) {
        const getPhoneRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/phones/${uuid}`, getPhoneRequest)
    },
    async createPhone(params) {
        const createData = processInput(params)
        const createPhoneRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/phones", createPhoneRequest)
    },
    async editPhone(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editPhoneRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/phones/${uuid}`, editPhoneRequest)
    },
    async deletePhone(uuid) {
        const deletePhoneRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/phones/${uuid}`, deletePhoneRequest)
    }
}