export const GET_STATUS_UNIVERSITIES_REQUEST = "GET_STATUS_UNIVERSITIES_REQUEST"
export const GET_STATUS_UNIVERSITIES_SUCCESS = "GET_STATUS_UNIVERSITIES_SUCCESS"
export const GET_STATUS_UNIVERSITIES_ERROR = "GET_STATUS_UNIVERSITIES_ERROR"
export const GET_STATUS_UNIVERSITY_REQUEST = "GET_STATUS_UNIVERSITY_REQUEST"
export const GET_STATUS_UNIVERSITY_SUCCESS = "GET_STATUS_UNIVERSITY_SUCCESS"
export const GET_STATUS_UNIVERSITY_ERROR = "GET_STATUS_UNIVERSITY_ERROR"
export const CREATE_STATUS_UNIVERSITY_REQUEST = "CREATE_STATUS_UNIVERSITY_REQUEST"
export const CREATE_STATUS_UNIVERSITY_SUCCESS = "CREATE_STATUS_UNIVERSITY_SUCCESS"
export const CREATE_STATUS_UNIVERSITY_ERROR = "CREATE_STATUS_UNIVERSITY_ERROR"
export const EDIT_STATUS_UNIVERSITY_REQUEST = "EDIT_STATUS_UNIVERSITY_REQUEST"
export const EDIT_STATUS_UNIVERSITY_SUCCESS = "EDIT_STATUS_UNIVERSITY_SUCCESS"
export const EDIT_STATUS_UNIVERSITY_ERROR = "EDIT_STATUS_UNIVERSITY_ERROR"
export const DELETE_STATUS_UNIVERSITY_REQUEST = "DELETE_STATUS_UNIVERSITY_REQUEST"
export const DELETE_STATUS_UNIVERSITY_SUCCESS = "DELETE_STATUS_UNIVERSITY_SUCCESS"
export const DELETE_STATUS_UNIVERSITY_ERROR = "DELETE_STATUS_UNIVERSITY_ERROR"