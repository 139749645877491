export const GET_COACH_REQUESTS_REQUEST = "GET_COACH_REQUESTS_REQUEST"
export const GET_COACH_REQUESTS_SUCCESS = "GET_COACH_REQUESTS_SUCCESS"
export const GET_COACH_REQUESTS_ERROR = "GET_COACH_REQUESTS_ERROR"
export const GET_COACH_REQUEST_REQUEST = "GET_COACH_REQUEST_REQUEST"
export const GET_COACH_REQUEST_SUCCESS = "GET_COACH_REQUEST_SUCCESS"
export const GET_COACH_REQUEST_ERROR = "GET_COACH_REQUEST_ERROR"
export const CREATE_COACH_REQUEST_REQUEST = "CREATE_COACH_REQUEST_REQUEST"
export const CREATE_COACH_REQUEST_SUCCESS = "CREATE_COACH_REQUEST_SUCCESS"
export const CREATE_COACH_REQUEST_ERROR = "CREATE_COACH_REQUEST_ERROR"
export const EDIT_COACH_REQUEST_REQUEST = "EDIT_COACH_REQUEST_REQUEST"
export const EDIT_COACH_REQUEST_SUCCESS = "EDIT_COACH_REQUEST_SUCCESS"
export const EDIT_COACH_REQUEST_ERROR = "EDIT_COACH_REQUEST_ERROR"
export const DELETE_COACH_REQUEST_REQUEST = "DELETE_COACH_REQUEST_REQUEST"
export const DELETE_COACH_REQUEST_SUCCESS = "DELETE_COACH_REQUEST_SUCCESS"
export const DELETE_COACH_REQUEST_ERROR = "DELETE_COACH_ERROR"
export const CLEAR_COACH_REQUEST_STATE = "CLEAR_COACH_REQUEST_STATE"

export const UPLOAD_COACH_REQUEST_REQUEST = "UPLOAD_COACH_REQUEST_REQUEST"
export const UPLOAD_COACH_REQUEST_SUCCESS = "UPLOAD_COACH_REQUEST_SUCCESS"
export const UPLOAD_COACH_REQUEST_ERROR = "UPLOAD_COACH_ERROR"