import {
	apiRequest,
	processInput,
	processQueryParams
} from "@/utilities"

export const coachAssignmentsService = {
	async getCoachAssignments(params) {
		const getCoachAssignmentParams = processQueryParams(params)
		const getCoachAssignmentsRequest = {
			method: "GET",
			params: getCoachAssignmentParams
		}
		return await apiRequest("/api/coach-assignments", getCoachAssignmentsRequest)
	},
	async createCoachAssignment(params) {
		const createData = processInput(params);
		const postCoachAssignmentRequest = {
			method: "POST",
			data: createData
		}

		return await apiRequest("/api/coach-assignments", postCoachAssignmentRequest)
	},
	// async createBulkCoachAssignment(params) {
	// 	const createData = processInput(params);
	// 	const postCoachAssignmentRequest = {
	// 		method: "POST",
	// 		data: createData
	// 	}

	// 	return await apiRequest("/api/bulk-coach-assignments", postCoachAssignmentRequest)
	// },
	async getCoachAssignment(uuid) {
		let getCoachAssignmentRequest = {
			method: "GET"
		}
		if(typeof uuid != 'string'){
			const getCoachAssignmentParams = processQueryParams(uuid)
			uuid = uuid.uuid
			getCoachAssignmentRequest = {
				method: "GET",
				params: getCoachAssignmentParams
			}
		}
		return await apiRequest(`/api/coach-assignments/${uuid}`, getCoachAssignmentRequest);
	},
	async sendAvailability(params) {

		const createData = processInput(params);
		const postCoachAssignmentRequest = {
			method: "POST",
			data: createData
		}
		return await apiRequest(`/api/send-availability`, postCoachAssignmentRequest);
	},
	async editCoachAssignment(data, uuid) {
		let editData = processInput(data)
		editData.append("_method", "PATCH");
		const updateCoachAssignmentRequest = {
			method: "POST",
			data: editData
		}

		return await apiRequest(`/api/coach-assignments/${uuid}`, updateCoachAssignmentRequest);
	},
	async deleteCoachAssignment(data) {

		let params = processQueryParams(data)
		const deleteCoachAssignmentRequest = {
			method: "DELETE",
			params
		}

		return await apiRequest(`/api/coach-assignments/${data.uuid}`, deleteCoachAssignmentRequest);
	},
	async createAssignmentReplacement(data) {
		const replaceData = processInput(data)
		const postAssignmentReplacement = {
			method: "POST",
			data: replaceData
		}

		return await apiRequest("/api/coach-assignment-replacement", postAssignmentReplacement)
	},
	async coachCustomActions(data, uuid) {
        let caData = processInput(data)
        const careq = {
            method: "POST",
            data: caData
        }
        return await apiRequest(`/api/ca-custom-actions/${uuid}`, careq)
    },
}