import {
    apiRequest,
    processInput
} from "@/utilities"

export const facultySourceRolesService = {
    async getFacultySourceRoles() {
        const getFacultySourceRolesRequest = {
            method: "GET"
        }
        return await apiRequest("/api/faculty-source-roles", getFacultySourceRolesRequest)
    },
    async createFacultySourceRole(params) {
        const createData = processInput(params);
        const postFacultySourceRoleRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/faculty-source-roles", postFacultySourceRoleRequest)
    },
    async getFacultySourceRole(uuid) {

        const getFacultySourceRoleRequest = {
            method: "GET"
        }

        return await apiRequest(`/api/faculty-source-roles/${uuid}`, getFacultySourceRoleRequest);
    },
    async editFacultySourceRole(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const updateFacultySourceRoleRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/faculty-source-roles/${uuid}`, updateFacultySourceRoleRequest);
    },
    async deleteFacultySourceRole(data, uuid) {
        let deleteData = processInput(data)
        
        const deleteFacultySourceRoleRequest = {
            method: "DELETE",
            data: deleteData
        }

        return await apiRequest(`/api/faculty-source-roles/${uuid}`, deleteFacultySourceRoleRequest);
    },
}