import {
    apiRequest,
    processInput
} from "@/utilities"

export const userRoleService = {
    async assignUserRole(params) {
        const createData = processInput(params)
        const createUserRoleRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/user-roles", createUserRoleRequest)
    },
    async revokeUserRole(data) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editUserRoleRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/user-roles/${data.role}`, editUserRoleRequest)
    },
}
