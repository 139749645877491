import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const coachCertificationService = {
    async getCoachCertifications(params) {
        let getParams = processQueryParams(params)
        const getCoachCertificationsRequest = {
            method: "GET",
            params: getParams
        }
        return await apiRequest("/api/coach-certifications", getCoachCertificationsRequest)
    },
    async getCoachCertification(uuid) {
        const getCoachCertificationRequest = {
            method: "GET"
        }
        return await apiRequest(`/api/coach-certifications/${uuid}`, getCoachCertificationRequest)
    },
    async createCoachCertification(params) {
        const createData = processInput(params)
        const createCoachCertificationRequest = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/coach-certifications", createCoachCertificationRequest)
    },
    async editCoachCertification(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH")
        const editCoachCertificationRequest = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/coach-certifications/${uuid}`, editCoachCertificationRequest)
    },
    async deleteCoachCertification(uuid) {
        const deleteCoachCertificationRequest = {
            method: "DELETE"
        }
        return await apiRequest(`/api/coach-certifications/${uuid}`, deleteCoachCertificationRequest)
    }
}