import {
    apiRequest,
    processInput
} from "@/utilities"

export const universityProgramPocs = {

    async getUniversityProgramPocs() {
        const Request = {
            method: "GET"
        }
        return await apiRequest("/api/university-program-pocs", Request)
    },

    async createUniversityProgramPoc(params) {
        const createData = processInput(params)
        const Request = {
            method: "POST",
            data: createData
        }
        return await apiRequest("/api/university-program-pocs", Request)
    },

    async getUniversityProgramsPoc(uuid) {
        const Request = {
            method: "GET"
        }
        return await apiRequest(`/api/university-program-pocs/${uuid}`, Request);
    },

    async editUniversityProgramPoc(data, uuid) {
        let editData = processInput(data)
        editData.append("_method", "PATCH");
        const Request = {
            method: "POST",
            data: editData
        }
        return await apiRequest(`/api/university-program-pocs/${uuid}`, Request);
    },

    async deleteUniversityProgramPoc(uuid) {
        const Request = {
            method: "DELETE"
        }
        return await apiRequest(`/api/university-program-pocs/${uuid}`, Request);
    }
}