import {
    apiRequest,
    processInput,
    processQueryParams
} from "@/utilities"

export const usersService = {
    async getUsers() {
        const getUsersRequest = {
            method: "GET"
        }

        return await apiRequest("/api/users", getUsersRequest)
    },
    async createUser(params) {
        let createData = processInput(params);
        const postUserRequest = {
            method: "POST",
            data: createData
        }

        return await apiRequest("/api/users", postUserRequest)
    },
    async getUser(params) {
        const { uuid } = params
        delete params['uuid']
        const userGetParams = processQueryParams(params)
        const getUserRequest = {
            method: "GET",
            params: userGetParams
        }

        return await apiRequest(`/api/users/${uuid}`, getUserRequest);
    },
    async editUser(params, uuid) {
        let editData = processInput(params)
        editData.append('_method', 'PATCH')
        const editUserRequest = {
            method: "POST",
            data: editData
        }

        return await apiRequest(`/api/users/${uuid}`, editUserRequest);
    },
    async deleteUser(params) {
        const { uuid } = params;
        const deleteUserRequest = {
            method: "DELETE",
        }

        return await apiRequest(`/api/users/${uuid}`, deleteUserRequest);
    },
    async userRestore(params) {
        const { uuid } = params;
        const restoreRequest = {
            method: "POST",
            data: processInput(params)
        }

        return await apiRequest(`/api/users/${uuid}/restore`, restoreRequest);
    }
}